import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as chatTypes from './types';
import * as chatActions from './actions';
import { initFirebaseChat, initFirebase } from '../../utils/fcmConfig';
import * as communityActions from '../community/actions';

import { selectLoggedInUserId } from '../auth/reducers';
import * as bootstrapTypes from '../bootstrap/types';

function* getAllLeaderUsers() {
  try {
    yield put({ type: chatTypes.ALL_LEADER_USERS_LOADING_STATE, state: true });

    const leaderId = yield select(selectLoggedInUserId);
    const result = yield call(communityActions.getLeaderUsers, leaderId);
    const { data } = result;

    yield put({ type: chatTypes.GET_ALL_LEADER_USERS_SUCCESS, users: data });

    yield put({ type: chatTypes.ALL_LEADER_USERS_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: chatTypes.ALL_LEADER_USERS_LOADING_STATE, state: false });
    yield put({
      type: chatTypes.GET_ALL_LEADER_USERS_ERROR,
      errors: {
        key: chatTypes.GET_ALL_LEADER_USERS_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  }
}

/**
 * Initialize firebase using the custom chat token
 */
export function* initializeChatAuth() {
  try {
    // request the token from the API
    const chatToken = yield call(chatActions.requestChatToken);
    const { data: { token } } = chatToken;

    // use the token to init firebase
    yield call(initFirebaseChat, token);

    // set an init state as done
    yield put({ type: chatTypes.CHAT_AUTH_SUCCESS });

    // kick off chat connect 
    const userId = yield select(selectLoggedInUserId);
    yield put({ type: chatTypes.CONNECT_CHAT, userId });

  } catch (error) {
    yield put({ type: chatTypes.CHAT_AUTH_ERROR });
  }
}

export function* initializeFirebaseServices() {
  try {
    yield call(initFirebase);
    yield call(initializeChatAuth);

    yield put({ type: bootstrapTypes.FIREBASE_INIT_SUCCESS });
  } catch (error) {
    console.info('DEBUG: function*initializeFirebaseServices -> error', error);
    yield put({ type: bootstrapTypes.FIREBASE_INIT_ERROR });
  }
}


export default [
  takeLatest(chatTypes.GET_ALL_LEADER_USERS, getAllLeaderUsers),
  takeLatest(chatTypes.CHAT_AUTH, initializeChatAuth),
  takeLatest(bootstrapTypes.FIREBASE_INIT, initializeFirebaseServices),
];
