// Icons
import { ReactComponent as HouseIcon } from '../static/images/icons/icon-home.svg';
import { ReactComponent as MembersIcon } from '../static/images/icons/icon-members.svg';
import { ReactComponent as CommunityIcon } from '../static/images/icons/icon-community-blue.svg';
import { ReactComponent as TeamIcon } from '../static/images/icons/icon-team.svg';
import { ReactComponent as CompassIcon } from '../static/images/icons/icon-compass.svg';
import { ReactComponent as TemplateIcon } from '../static/images/icons/icon-template.svg';
import { ReactComponent as ToolkitIcon } from '../static/images/icons/icon-toolkit.svg';

// Authenticated containers
import CommunityDetails from '../containers/CommunityDetails';
import CommunityMissions from '../containers/CommunityMissions';
import ManageUsers from '../containers/ManageUsers';
import ManageTeams from '../containers/ManageTeams';
import Activity from '../containers/Activity';
import OfficerDashboard from '../containers/OfficerDashboard';
import Authenticated404 from '../containers/Authenticated404';
import UserProfile from '../containers/UserProfile';
import MissionView from '../containers/MissionView';
import CommunityMissionView from '../containers/CommunityMissionView';
import CommunityMissionEdit from '../containers/CommunityMissionEdit';
import CommunityObjectiveView from '../containers/CommunityObjectiveView';

import ObjectiveView from '../containers/ObjectiveView';
import PsychFramework from '../containers/PsychFramework';
import CommunityMissionTemplates from '../containers/CommunityMissionTemplates';
import TemplateCreateUpdate from '../containers/TemplateCreateUpdate';
import TemplateView from '../containers/TemplateView';
import MissionTemplateStepper from '../containers/MissionTemplateStepper';
import MissionCreate from '../containers/MissionCreate';
import MemberStats from '../containers/MemberStats';

import ViewMember from '../containers/ViewMember';
import Toolkit from '../containers/Toolkit';
import CreateToolkit from '../components/CreateToolkit/CreateToolkit';
import EditToolkit from '../components/EditToolkit/EditToolkit';

export default function Routes(communityId = null, isComAdmin = false, isLeader = false, isOfficer = false) {
  return [
    {
      key: 'manage-users',
      path: '/c/:communityId/users',
      to: `/c/${communityId}/users`,
      title: !isComAdmin ? 'Users' : 'Manage users',
      icon: MembersIcon,
      component: ManageUsers,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin']
    },
    {
      key: 'manage-teams',
      path: '/c/:communityId/teams',
      to: `/c/${communityId}/teams`,
      title: !isComAdmin ? 'Teams' : 'Manage teams',
      icon: TeamIcon,
      component: ManageTeams,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin']
    },
    {
      key: 'create-mission-template',
      path: '/c/:communityId/create-mission-template/:templateType',
      title: 'Template',
      icon: TemplateIcon,
      component: TemplateCreateUpdate,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: [],
      child: false,
      showHeader: false
    },
    {
      key: 'view-mission-template',
      path: '/c/:communityId/mission-template/:templateId',
      title: 'Mission Template',
      icon: null,
      component: TemplateView,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin'],
      child: false,
      showHeader: false
    },
    {
      key: 'edit-mission-template',
      path: '/c/:communityId/mission-template/:templateId/edit',
      title: 'Mission Template',
      icon: null,
      component: TemplateCreateUpdate,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: [],
      child: false,
      showHeader: false
    },
    {
      key: 'create-mission',
      path: '/c/:communityId/u/:userId/create-mission',
      title: 'Create Mission',
      icon: null,
      component: MissionCreate,
      authenticated: true,
      navMenu: false,
      group: 'leader',
      roles: ['officer'],
      permissions: ['leader'],
      child: false,
      showHeader: false
    },
    {
      key: 'members',
      path: '/c/:communityId/members/:role',
      to: `/c/${communityId}/members/leader`,
      title: 'Members',
      icon: MembersIcon,
      component: MemberStats,
      authenticated: true,
      navMenu: true,
      group: 'leader',
      roles: ['officer'],
      permissions: [], // Permission routing handled in container! Same route used for two views
      view: ['leader'] // Display if leader - but dont let auth guardian handle re-routes
    },
    {
      key: 'activity',
      path: '/c/:communityId/activity',
      to: `/c/${communityId}/activity`,
      title: 'Activity',
      icon: HouseIcon,
      component: Activity,
      authenticated: true,
      navMenu: true,
      group: 'leader',
      roles: [],
      permissions: ['leader']
    },
    {
      // JUST FOR CREATING TEMPLATES WITHOUT ASSIGNING (CommAdmin only)
      key: 'community-mission-templates',
      path: '/c/:communityId/community-mission-templates',
      to: `/c/${communityId}/community-mission-templates`,
      title: 'Mission templates',
      icon: TemplateIcon,
      component: CommunityMissionTemplates,
      authenticated: true,
      navMenu: !isLeader,
      group: !isLeader ? 'comAdmin' : '', // If user is also a leader, hide menu
      roles: ['officer'],
      permissions: ['admin'],
      child: false,
      view: isComAdmin ? ['admin'] : []
    },
    {
      // FOR ASSIGNING AND CREATING TEMPLATES (CommAdmin and leaders)
      key: 'mission-templates',
      path: '/c/:communityId/mission-templates',
      to: `/c/${communityId}/mission-templates`,
      title: 'Mission templates',
      icon: TemplateIcon,
      // component: CommunityMissionTemplates,
      component: MissionTemplateStepper,
      authenticated: true,
      navMenu: isComAdmin,
      group: isComAdmin && isLeader ? 'comAdmin' : '',
      roles: [],
      permissions: ['admin'],
    },
    {
      key: 'leader-mission-templates',
      path: '/c/:communityId/leader-mission-templates',
      to: `/c/${communityId}/leader-mission-templates`,
      title: 'Mission templates',
      icon: TemplateIcon,
      component: MissionTemplateStepper,
      authenticated: true,
      navMenu: isLeader,
      group: 'leader',
      roles: [],
      permissions: ['leader'],
    },
    {
      key: 'dashboard',
      path: '/c/:communityId/dashboard',
      to: `/c/${communityId}/dashboard`,
      title: 'Dashboard',
      icon: null,
      component: OfficerDashboard,
      authenticated: true,
      navMenu: false,
      roles: ['officer'],
      permissions: []
    },
    {
      key: 'user-profile',
      path: '/c/:communityId/u/:userId/account',
      title: 'Profile details',
      icon: null,
      showHeader: false,
      component: UserProfile,
      authenticated: true,
      navMenu: false,
      roles: [],
      permissions: []
    },
    {
      key: 'objective-view',
      path: '/c/:communityId/u/:userId/m/:missionId/o/:objectiveId',
      title: 'Objective',
      icon: null,
      component: ObjectiveView,
      authenticated: true,
      navMenu: false,
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'mission-view',
      path: '/c/:communityId/u/:userId/m/:missionId',
      title: 'Mission',
      icon: null,
      component: MissionView,
      authenticated: true,
      navMenu: false,
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'mission-edit',
      path: '/c/:communityId/u/:userId/m/:missionId/edit',
      title: 'Mission',
      icon: null,
      component: MissionCreate,
      authenticated: true,
      navMenu: false,
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'view-member',
      path: '/c/:communityId/u/:userId/:role',
      exact: false,
      title: 'User',
      icon: null,
      component: ViewMember,
      authenticated: true,
      navMenu: false,
      roles: ['officer'],
      permissions: [],
      showHeader: false
    },
    {
      key: 'uhoh',
      path: '/c/:communityId/404',
      title: 'Uh Oh',
      icon: null,
      component: Authenticated404,
      authenticated: true,
      navMenu: false,
      roles: [],
      permissions: [],
      showHeader: false
    },
    {
      key: 'leader-community-missions',
      path: '/c/:communityId/leader-missions',
      to: `/c/${communityId}/leader-missions`,
      title: 'Community missions',
      icon: CommunityIcon,
      component: CommunityMissions,
      authenticated: true,
      navMenu: !isLeader && !isComAdmin,
      group: 'leader',
      roles: ['officer'],
      permissions: ['leader']
    },
    {
      key: 'community-missions',
      path: '/c/:communityId/missions',
      to: `/c/${communityId}/missions`,
      title: 'Community missions',
      icon: CommunityIcon,
      component: CommunityMissions,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin']
    },
    {
      key: 'community-mission-edit',
      path: '/c/:communityId/m/create',
      title: 'Create community mission',
      icon: null,
      component: CommunityMissionEdit,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'community-mission-view',
      path: '/c/:communityId/m/:missionId',
      title: 'Community mission',
      icon: null,
      component: CommunityMissionView,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'community-mission-edit',
      path: '/c/:communityId/m/:missionId/edit',
      title: 'Community mission',
      icon: null,
      component: CommunityMissionEdit,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'community-objective-view',
      path: '/c/:communityId/m/:missionId/o/:objectiveId',
      title: 'Community objective',
      icon: null,
      component: CommunityObjectiveView,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['leader', 'admin'],
      child: true,
      showHeader: false
    },
    {
      key: 'community-details',
      path: '/c/:communityId/community-details',
      to: `/c/${communityId}/community-details`,
      title: 'Community details',
      icon: CommunityIcon,
      component: CommunityDetails,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin']
    },
    {
      key: 'psych-framework',
      path: '/c/:communityId/psych-framework',
      to: `/c/${communityId}/psych-framework`,
      title: 'Community code',
      icon: CompassIcon,
      component: PsychFramework,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin'],
      child: false
    },
    {
      key: 'toolkits',
      path: '/c/:communityId/toolkits',
      to: `/c/${communityId}/toolkits`,
      title: 'Tools',
      icon: ToolkitIcon,
      component: Toolkit,
      authenticated: true,
      navMenu: true,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin'],
      child: false
    },
    {
      key: 'toolkits-add',
      path: '/c/:communityId/toolkits/add',
      to: `/c/${communityId}/toolkits/add`,
      title: 'Create new tool',
      component: CreateToolkit,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin'],
      child: false
    },
    {
      key: 'toolkits-edit',
      path: '/c/:communityId/toolkits/:toolkitId/edit',
      to: `/c/${communityId}/toolkits/:toolkitId/edit`,
      title: 'Update tool',
      component: EditToolkit,
      authenticated: true,
      navMenu: false,
      group: 'comAdmin',
      roles: ['officer'],
      permissions: ['admin'],
      child: false
    }
  ];
}
