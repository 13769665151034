import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as communityActions from './actions';
import * as communityTypes from './types';

import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

import { selectLoggedInUser, selectLoggedInUserId } from '../auth/reducers';
import {
  selectSelectedCommunityId,
  selectCommunityUsers,
  selectLeaderUsers,
  selectOfficerActivity,
  selectLeaderActivity,
  selectOfficerMembers
} from './reducers';

import { checkForPermissions, getPermissions } from '../../utils/helpers';

function* getAllCommunities() {
  try {
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: true });

    const result = yield call(communityActions.getAllCommunities);
    const {
      data: { results: communities }
    } = result;
    yield put({ type: communityTypes.GET_ALL_COMMUNITIES_SUCCESS, communities });
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: communityTypes.GET_ALL_COMMUNITIES_ERROR });
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  } finally {
    yield put({ type: communityTypes.GET_ALL_COMMUNITIES_DONE });
  }
}

function* getCommunity({ communityId }) {
  try {
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: true });

    const { data } = yield call(communityActions.getCommunity, communityId);

    // Set the community in localstorage
    localStorage.setItem('community', communityId);

    // Check if the user has permissions for the selected community
    const { memberships } = yield select(selectLoggedInUser);
    const hasPermissions = checkForPermissions(memberships, communityId);
    if (hasPermissions) {
      const permissions = getPermissions(memberships, communityId);
      yield put({ type: communityTypes.SET_COMMUNITY_PERMISSIONS, permissions });
    } else yield put({ type: communityTypes.SET_COMMUNITY_PERMISSIONS, permissions: [] });

    yield put({ type: communityTypes.GET_COMMUNITY_SUCCESS, community: data });
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: communityTypes.GET_COMMUNITY_ERROR });
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  }
}

function* setCommunityPermissions({ permissions }) {
  try {
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: true });

    yield put({
      type: communityTypes.SET_COMMUNITY_PERMISSIONS_SUCCESS,
      permissions
    });

    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: communityTypes.SET_COMMUNITY_PERMISSIONS_ERROR });
    yield put({ type: communityTypes.COMMUNITY_LOADING_STATE, state: false });
  }
}

function* updateCommunity({ communityId, updates }) {
  try {
    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: true });

    const { data } = yield call(communityActions.updateCommunity, communityId, updates);

    yield put({ type: communityTypes.UPDATE_COMMUNITY_SUCCESS, community: data });
    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: false });

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Community successfully updated',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: communityTypes.UPDATE_COMMUNITY_ERROR,
      errors: {
        key: communityTypes.UPDATE_COMMUNITY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: false });
  }
}

function* getCommunityUsers({ communityId, showProgress = false }) {
  try {
    const communityUsers = yield select(selectCommunityUsers);
    if (showProgress || !communityUsers.length) {
      yield put({ type: communityTypes.COMMUNITY_USERS_LOADING_STATE, state: true });
    }

    const { data } = yield call(communityActions.getCommunityUsers, communityId);
    yield put({ type: communityTypes.GET_COMMUNITY_USERS_SUCCESS, users: data });

    yield put({ type: communityTypes.COMMUNITY_USERS_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: communityTypes.COMMUNITY_USERS_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getCommunityLeaders({ communityId }) {
  try {
    yield put({ type: communityTypes.COMMUNITY_LEADERS_LOADING_STATE, state: true });

    const result = yield call(communityActions.getCommunityLeaders, communityId);
    const { data } = result;

    yield put({ type: communityTypes.GET_COMMUNITY_LEADERS_SUCCESS, leaders: data });

    yield put({
      type: communityTypes.COMMUNITY_LEADERS_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({ type: communityTypes.COMMUNITY_LEADERS_LOADING_STATE, state: true });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getLeaderUsers({ userId, communityId }) {
  try {
    // Only show loading if no data exists
    const leaderUsers = yield select(selectLeaderUsers);
    if (!leaderUsers.length) {
      yield put({
        type: communityTypes.LEADER_USERS_LOADING_STATE,
        state: true
      });
    }

    const { data } = yield call(communityActions.getLeaderMembersWithStats, communityId, userId);

    yield put({ type: communityTypes.GET_LEADER_USERS_SUCCESS, leaderUsers: data });

    yield put({
      type: communityTypes.LEADER_USERS_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: communityTypes.GET_LEADER_USERS_ERROR
    });
    yield put({
      type: communityTypes.LEADER_USERS_LOADING_STATE,
      state: false
    });
  }
}

function* batchUploadUsers({ formData }) {
  try {
    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: true });
    const communityId = yield select(selectSelectedCommunityId);
    const result = yield call(communityActions.batchUpload, communityId, formData);
    const { data } = result;

    const currentCommunityUsers = yield select(selectCommunityUsers);
    const userSet = new Set(currentCommunityUsers.map(u => u._id));
    const merged = [
      ...currentCommunityUsers,
      ...data.newUsers,
      ...data.existingUsers.filter(user => !userSet.has(user._id))
    ];

    yield put({
      type: communityTypes.BATCH_UPLOAD_USERS_SUCCESS,
      batchResults: data,
      communityUsers: merged
    });

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Successfully uploaded',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: communityTypes.BATCH_UPLOAD_USERS_ERROR,
      errors: {
        key: communityTypes.BATCH_UPLOAD_USERS_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });

    yield put({ type: communityTypes.COMMUNITY_SAVING_STATE, state: false });
  }
}

function* getLeaderActivity({ page = 0 }) {
  try {
    const limit = 25;
    const skip = page > 0 ? page * 25 : 0;

    // Conditionally show loading state
    const communityId = yield select(selectSelectedCommunityId);
    const authUser = yield select(selectLoggedInUserId);
    const leaderActivity = yield select(selectLeaderActivity, communityId, authUser);

    if (!leaderActivity.activities.some(a => a.page === page)) {
      yield put({ type: communityTypes.LEADER_ACTIVITY_LOADING_STATE, state: true });
    }

    const result = yield call(communityActions.getLeaderActivity, communityId, limit, skip);
    const {
      data: { results, total }
    } = result;

    yield put({
      type: communityTypes.GET_LEADER_ACTIVITY_SUCCESS,
      leaderActivity: {
        userId: authUser,
        communityId,
        total,
        page,
        data: results
      }
    });

    yield put({ type: communityTypes.LEADER_ACTIVITY_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: communityTypes.GET_LEADER_ACTIVITY_ERROR,
      errors: {
        key: communityTypes.GET_LEADER_ACTIVITY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });

    yield put({ type: communityTypes.LEADER_ACTIVITY_LOADING_STATE, state: false });
  }
}

function* getAgnosticLeaderActivity({ page = 0 }) {
  try {
    const limit = 25;
    const skip = page > 0 ? page * 25 : 0;
    const officerActivity = yield select(selectOfficerActivity);
    // Only set loading if no data
    if (!officerActivity.activities) officerActivity.activities = [];
    if (!officerActivity.activities.some(a => a.page === page)) {
      yield put({
        type: communityTypes.OFFICER_ACTIVITY_LOADING_STATE,
        state: true
      });
    }

    const result = yield call(communityActions.getAgnosticLeaderActivity, limit, skip);

    const {
      data: { results, total }
    } = result;
    console.log('officerActivity?.activities', officerActivity?.activities);
    const dataExists = officerActivity?.activities.some(a => a.page === page);
    const updatedActivities = officerActivity.activities.map(a => {
      if (a.page === page) return { page, data: results };
      return a;
    });

    yield put({
      type: communityTypes.GET_AGNOSTIC_LEADER_ACTIVITY_SUCCESSS,
      total,
      activities: dataExists ? updatedActivities : [...officerActivity.activities, { page, data: results }]
    });

    yield put({ type: communityTypes.OFFICER_ACTIVITY_LOADING_STATE, state: false });
  } catch (error) {
    console.log('e', error);
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: communityTypes.GET_AGNOSTIC_LEADER_ACTIVITY_ERROR,
      errors: {
        key: communityTypes.GET_AGNOSTIC_LEADER_ACTIVITY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });

    yield put({ type: communityTypes.OFFICER_ACTIVITY_LOADING_STATE, state: false });
  }
}

function* updatePsychFramework({ key, updates, name }) {
  try {
    yield put({
      type: communityTypes.PSYCH_FRAMEWORK_SAVING_STATE,
      state: true,
      key
    });

    const communityId = yield select(selectSelectedCommunityId);

    const result = yield call(communityActions.updatePsychFramework, communityId, updates, key);
    const { data } = result;

    yield put({
      type: communityTypes.UPDATE_COMMUNITY_PSYCH_FRAMEWORK_SUCCESS,
      community: data
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: `'${name}' has been successfully updated!`,
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: communityTypes.PSYCH_FRAMEWORK_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: communityTypes.UPDATE_COMMUNITY_PSYCH_FRAMEWORK_ERROR,
      errors: {
        key: communityTypes.UPDATE_COMMUNITY_PSYCH_FRAMEWORK_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: communityTypes.PSYCH_FRAMEWORK_SAVING_STATE, state: false });
  }
}

function* getMemberStats({ communityId = null, leaderId }) {
  try {
    yield put({ type: communityTypes.MEMBER_STAT_LOADING_STATE, state: true });
    const { data } = yield call(communityActions.getMemberStats, communityId, leaderId);
    yield put({
      type: communityTypes.GET_MEMBER_STAT_OVERVIEW_SUCCESS,
      memberStats: data
    });
    yield put({ type: communityTypes.MEMBER_STAT_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: communityTypes.MEMBER_STAT_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      message: error.response.data.message
    });
  }
}

function* getOfficerMembers({ leaderId }) {
  try {
    const officerMembers = yield select(selectOfficerMembers);

    if (officerMembers.length === 0) {
      yield put({
        type: communityTypes.OFFICER_MEMBERS_LOADING_STATE,
        state: true
      });
    }

    const { data } = yield call(communityActions.getOfficerMembersWithStats, leaderId);
    yield put({
      type: communityTypes.GET_OFFICER_MEMBERS_SUCCESS,
      officerMembers: data
    });

    yield put({
      type: communityTypes.OFFICER_MEMBERS_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: communityTypes.OFFICER_MEMBERS_LOADING_STATE,
      state: false
    });
    yield put({
      type: modalTypes.ERROR_MODAL,
      message: error.response.data.message
    });
  }
}

export default [
  takeLatest(communityTypes.GET_COMMUNITY, getCommunity),
  takeLatest(communityTypes.GET_ALL_COMMUNITIES, getAllCommunities),
  takeLatest(communityTypes.SET_COMMUNITY_PERMISSIONS, setCommunityPermissions),
  takeLatest(communityTypes.UPDATE_COMMUNITY, updateCommunity),
  takeLatest(communityTypes.GET_COMMUNITY_USERS, getCommunityUsers),
  takeLatest(communityTypes.GET_LEADER_USERS, getLeaderUsers),
  takeLatest(communityTypes.GET_COMMUNITY_LEADERS, getCommunityLeaders),
  takeLatest(communityTypes.BATCH_UPLOAD_USERS, batchUploadUsers),
  takeLatest(communityTypes.GET_LEADER_ACTIVITY, getLeaderActivity),
  takeLatest(communityTypes.GET_AGNOSTIC_LEADER_ACTIVITY, getAgnosticLeaderActivity),
  takeLatest(communityTypes.UPDATE_COMMUNITY_PSYCH_FRAMEWORK, updatePsychFramework),
  takeLatest(communityTypes.GET_MEMBER_STAT_OVERVIEW, getMemberStats),
  takeLatest(communityTypes.GET_OFFICER_MEMBERS, getOfficerMembers)
];
