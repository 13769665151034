export const OPEN_CHAT = 'chat/OPEN_CHAT';
export const CLOSE_CHAT = 'chat/CLOSE_CHAT';

export const UPDATE_CHAT_MESSAGES = 'chat/UPDATE_CHAT_MESSAGES';
export const UPDATE_CHAT_CONVERSATIONS = 'chat/UPDATE_CHAT_CONVERSATIONS';
export const MARK_CONVERSATION_AS_READ = 'chat/MARK_CONVERSATION_AS_READ';
export const SEND_MESSAGE = 'chat/SEND_MESSAGE';

export const GET_ALL_LEADER_USERS = 'chat/GET_ALL_LEADER_USERS';
export const GET_ALL_LEADER_USERS_SUCCESS = 'chat/GET_ALL_LEADER_USERS_SUCCESS';
export const GET_ALL_LEADER_USERS_ERROR = 'chat/GET_ALL_LEADER_USERS_ERROR';

export const ALL_LEADER_USERS_LOADING_STATE = 'chat/ALL_LEADER_USERS_LOADING_STATE';

export const CONNECT_CHAT = 'chat/CONNECT_CHAT';

export const CHAT_AUTH = 'chat/CHAT_AUTH';
export const CHAT_AUTH_ERROR = 'chat/CHAT_AUTH_ERROR';
export const CHAT_AUTH_SUCCESS = 'chat/CHAT_AUTH_SUCCESS';
