import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import CloseIcon from '@material-ui/icons/Close';

import { selectSnackState } from '../../state/snack/reducers';
import * as snackTypes from '../../state/snack/types';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = makeStyles(theme => ({
  snackRoot: {
    borderRadius: 10
  },
  error: {
    backgroundColor: '#de352b'
  },
  success: {
    backgroundColor: '#3ed08a'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  }
}));

const SnackWrapper = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const snackState = useSelector(state => selectSnackState(state));

  const { props: snackProps } = snackState;

  const handleClose = () => {
    dispatch({ type: snackTypes.SET_SNACK_STATE, open: false });
  };

  const Icon = variantIcon[snackProps.variant];

  const action = [
    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  ];

  const Content = (
    <span id="message-id">
      <Icon className={clsx(classes.icon, classes.iconVariant)} />
      {snackState.content}
    </span>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackState.open}
      autoHideDuration={5000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
    >
      <SnackbarContent
        message={Content}
        action={action}
        className={clsx(classes[snackProps.variant], classes.snackRoot)}
      />
    </Snackbar>
  );
};

SnackWrapper.propTypes = {};

export default SnackWrapper;
