/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import * as types from './types';

import {
  updateOrAddMissionObjectives,
  updateObjectiveTasks,
  removeMissionFromUserMissions,
  removeObjectiveFromMissionObjectives,
  updateSavingTasks
} from '../../utils/stateHelpers';

const initialState = {
  missionLoading: false,
  missions: [],
  selectedMission: null,
  objectiveLoading: false,
  missionObjectives: [],
  missionObjectivesPagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1
  },
  selectedObjective: null,
  taskLoading: false,
  tasks: [],
  savingTasks: [],
  commentSaving: false,
  apiErrors: null,
  missionTimeline: [],
  missionTimelineLoading: false,
  systemComments: [],
  systemCommentsLoading: false,
  objectiveSaving: false,
  missionSaving: false,
  userMissions: [],
  profileImages: [],
  officerMissions: [],
  userObjectives: [],
  searchMissionsInCommunity: {
    data: [],
    page: 1,
    limit: 10,
    totalPages: 1
  },
  objectivePracticeLoading: false,
  objectivePracticeSaved: false,
  objectivePracticeDeleted: false,

  // Tasks
  isCreatingTask: false,
  isCreatingTaskSuccess: false,
  isCreatingTaskError: false,


  isLoadingStats: false,
  isLoadingStatsSuccess: true,
  isLoadingStatsError: null,
  updatingTasks: [],
  missionsStats: {},
};

const missionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MISSION_LOADING_STATE:
      return {
        ...state,
        missionLoading: action.state
      };

    case types.MISSION_SAVING_STATE:
      return {
        ...state,
        missionSaving: action.state
      };

    case types.OBJECTIVE_LOADING_STATE:
      return {
        ...state,
        objectiveLoading: action.state
      };

    case types.TASK_LOADING_STATE:
      return {
        ...state,
        taskLoading: action.state
      };

    case types.COMMENT_SAVING_STATE:
      return {
        ...state,
        commentSaving: action.state
      };

    case types.OBJECTIVE_SAVING_STATE:
      return {
        ...state,
        objectiveSaving: action.state
      };

    case types.GET_ALL_COMMUNITY_MISSIONS_SUCCESS:
      return {
        ...state,
        userMissions: action.userMissions
      };

    case types.GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_SUCCESS:
      return {
        ...state,
        searchMissionsInCommunity: {
          data: action.results,
          total: action.total,
          page: action.page,
          totalPages: action.totalPages
        }
      };

    case types.UPDATE_MISSION_SUCCESS:
    case types.CREATE_MISSION_SUCCESS:
    case types.UPDATE_MISSION_STATUS_SUCCESS:
    case types.GET_MISSION_SUCCESS:
      return {
        ...state,
        selectedMission: action.mission,
        userMissions: action.userMissions
      };
    case types.STORE_PROFILE_IMAGE:
      return {
        ...state,
        profileImages: [
          ...state.profileImages.filter(mission => mission.id !== action.missionId),
          { missionId: action.missionId, image: action.image }
        ]
      };
    case types.GET_MISSION_OBJECTIVES_SUCCESS:
      return {
        ...state,
        missionObjectives: action.objectives,
        missionObjectivesPagination: {
          total: action.total,
          page: action.page,
          totalPages: action.totalPages,
          limit: action.limit
        }
      };

    case types.GET_OBJECTIVE_SUCCESS:
      return {
        ...state,
        selectedObjective: action.objective,
        apiErrors: null
      };

    case types.GET_OBJECTIVE_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks
      };

    case types.POST_COMMENT_ERROR:
      return {
        ...state,
        apiErrors: action.errors,
        missionObjectives: updateOrAddMissionObjectives(
          state.missionObjectives,
          state.selectedMission.id,
          action.objective
        ),
        selectedObjective: action.objective,
        selectedMission: {
          ...state.selectedMission,
          objectives: state.selectedMission.objectives.map(o => {
            if (o.id === action.objective.id) return action.objective;
            return o;
          })
        }
      };

    case types.GET_ALL_OBJECTIVES_SUCCESS:
      return {
        ...state,
        objectives: action.objectives
      };

    case types.GET_USER_OBJECTIVES_SUCCESS:
      return {
        ...state,
        userObjectives: action.userObjectives
      };

    case types.GET_MISSION_TIMELINE_SUCCESS:
      return {
        ...state,
        missionTimeline: action.missionTimeline
      };

    case types.MISSION_TIMELINE_LOADING_STATE:
      return {
        ...state,
        missionTimelineLoading: action.state
      };

    case types.ARCHIVE_MISSION_SUCCESS:
      return {
        ...state,
        missions: state.missions.filter(m => m.id !== action.missionId),
        userMissions: removeMissionFromUserMissions(state.userMissions, action.userId, action.missionId),
        selectedMission: null
      };

    case types.CREATE_OBJECTIVE_SUCCESS:
      const mission = action.mission || state.selectedMission;

      return {
        ...state,
        missionObjectives: updateOrAddMissionObjectives(state.missionObjectives, mission.id, action.objective),
        selectedMission: {
          ...mission,
          objectives: [...mission.objectives, action.objective]
        },
        userMissions: state.userMissions.map(userMission => {
          const userId = mission.user?.id || mission.user;
          if (userMission.userId !== userId) {
            return userMission;
          }

          userMission.missions = userMission.missions.map(item => {
            if (item.id === mission.id) {
              item.objectives = [...item.objectives, action.objective];
            }

            return item;
          });

          return userMission;
        })
      };

    case types.UPDATE_OBJECTIVE_SUCCESS:
    case types.UPDATE_OBJECTIVE_STATUS_SUCCESS:
    case types.POST_COMMENT_SUCCESS:
      return {
        ...state,
        missionObjectives: updateOrAddMissionObjectives(
          state.missionObjectives,
          state.selectedMission.id,
          action.objective
        ),
        selectedObjective: action.objective,
        selectedMission: {
          ...state.selectedMission,
          objectives: state.selectedMission.objectives.map(o => {
            if (o.id === action.objective.id) return action.objective;
            return o;
          })
        }
      };

    case types.ARCHIVE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        selectedObjective: null,
        missionObjectives: removeObjectiveFromMissionObjectives(
          state.missionObjectives,
          action.missionId,
          action.objectiveId
        ),
        selectedMission: {
          ...state.selectedMission,
          objectives: state.selectedMission.objectives.filter(o => o.id !== action.objectiveId)
        }
      };

    case types.DELETE_TASK_SUCCESS:
      return {
        ...state,
        missionObjectives: updateObjectiveTasks(
          state.missionObjectives,
          state.selectedMission.id,
          state.selectedObjective.id,
          action.task,
          true
        ),
        selectedObjective: {
          ...state.selectedObjective,
          tasks: state.selectedObjective.tasks.filter(t => t.id !== action.taskId)
        }
      };

    case types.UPDATE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        missionObjectives: updateObjectiveTasks(
          state.missionObjectives,
          state.selectedMission.id,
          state.selectedObjective.id,
          action.task
        ),
        selectedObjective: {
          ...state.selectedObjective,
          tasks: state.selectedObjective.tasks.map(t => {
            if (t.id === action.task.id) return action.task;
            return t;
          })
        }
      };

    case types.OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE: {
      return {
        ...state,
        systemCommentsLoading: action.state
      };
    }

    case types.GET_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS:
      return {
        ...state,
        systemComments: action.systemComments
      };

    case types.CLEAR_SELECTED_MISSION:
      return {
        ...state,
        selectedMission: null
      };

    case types.GET_ALL_OFFICER_MISSIONS_SUCCESS:
      return {
        ...state,
        officerMissions: action.officerMissions
      };

    case types.SET_SELECTED_MISSION:
      return {
        ...state,
        selectedMission: action.mission
      };

    case types.SET_SELECTED_OBJECTIVE:
      return {
        ...state,
        selectedObjective: action.objective
      };

    case types.UPDATE_TASKS_CURRENTLY_SAVING:
      return {
        ...state,
        savingTasks: updateSavingTasks(state.savingTasks, action.taskId)
      };

    case types.OBJECTIVE_PRACTICE_LOADING_STATE:
      return {
        ...state,
        objectivePracticeLoading: action.state
      };

    case types.CREATE_OBJECTIVE_PRACTICE:
    case types.UPDATE_OBJECTIVE_PRACTICE:
      return {
        ...state,
        objectivePracticeSaved: false
      };

    case types.CREATE_OBJECTIVE_PRACTICE_SUCCESS:
      state.selectedObjective.practices.push(action.practice);

      return {
        ...state,
        objectivePracticeSaved: true
      };

    case types.UPDATE_OBJECTIVE_PRACTICE_SUCCESS:
      return {
        ...state,
        selectedObjective: {
          ...state.selectedObjective,
          practices: state.selectedObjective.practices.map(t => {
            if (t.id === action.practice.id) return action.practice;
            return t;
          })
        },
        objectivePracticeSaved: true
      };

    case types.DELETE_OBJECTIVE_PRACTICE:
      return {
        ...state,
        objectivePracticeDeleted: false
      };

    case types.DELETE_OBJECTIVE_PRACTICE_SUCCESS:
      return {
        ...state,
        objectivePracticeDeleted: true,
        selectedObjective: {
          ...state.selectedObjective,
          practices: state.selectedObjective.practices.filter(t => t.id !== action.practiceId)
        }
      };

    // TASKS
    case types.CREATE_TASK:
      return {
        ...state,
        isCreatingTask: true
      };
    case types.CREATE_TASK_SUCCESS:
      return {
        ...state,
        isCreatingTask: false,
        isCreatingTaskSuccess: true,
        isCreatingTaskError: null,
        selectedObjective: {
          ...state.selectedObjective,
          tasks: [...state.selectedObjective.tasks, action.task]
        }
      };
    case types.CREATE_TASK_ERROR:
      return {
        ...state,
        isCreatingTask: false,
        isCreatingTaskSuccess: false,
        isCreatingTaskError: action.payload.error
      };

    case types.UPDATE_TASK:
      return {
        ...state,
        updatingTasks: [...state.updatingTasks, action.task._id]
      };
    case types.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        updatingTasks: state.updatingTasks.filter(id => id !== action.task._id),
        selectedObjective: {
          ...state.selectedObjective,
          tasks: [...state.selectedObjective.tasks.filter(t => t._id !== action.task._id), action.task]
        }
      };
    case types.UPDATE_TASK_ERROR:
      return {
        ...state,
        updatingTasks: state.updatingTasks.filter(id => id !== action.task._id)
      };

    // Statistics section
    case types.GET_MISSIONS_STATS_REQUEST:
      return {
        ...state,
        isLoadingStats: true,
      };
    case types.GET_MISSIONS_STATS_SUCCESS:
      return {
        ...state,
        isLoadingStats: false,
        isLoadingStatsSuccess: true,
        isLoadingStatsError: null,
        missionsStats: {
          ...state.missionsStats,
          [action.userId]: action.state, // filter these
        }
      };
    case types.GET_MISSIONS_STATS_ERROR:
      return {
        ...state,
        isLoadingStats: false,
        isLoadingStatsSuccess: null,
        isLoadingStatsError: action.error,
      };
    

    default:
      return state;
  }
};

export default missionReducer;

export const selectMissionLoading = state => state.mission.missionLoading;
export const selectMissions = state => state.mission.missions;
export const selectSelectedMission = state => state.mission.selectedMission;
export const selectSelectedProfileImagesMission = state => state.mission.profileImages;
export const selectObjectiveLoading = state => state.mission.objectiveLoading;
// export const selectMissionObjectives = state => state.mission.missionObjectives;
export const selectSelectedObjective = state => state.mission.selectedObjective;
export const selectTaskLoading = state => state.mission.taskLoading;
export const selectTasks = state => state.mission.tasks;
export const selectCommentSaving = state => state.mission.commentSaving;
export const selectMissionApiErrors = state => state.mission.apiErrors;
export const selectObjectives = state => state.mission.objectives;
export const selectMissionTimeline = state => state.mission.missionTimeline;
export const selectMissionTimelineLoading = state => state.mission.missionTimelineLoading;
export const selectObjectiveSaving = state => state.mission.objectiveSaving;
export const selectMissionSaving = state => state.mission.missionSaving;

export const selectSavingTasks = state => state.mission.savingTasks;

export const selectSystemCommentsLoading = state => state.mission.systemCommentsLoading;
export const selectSystemComments = state => state.mission.systemComments;
export const selectObjectiveSystemComments = (state, objectiveId) =>
  state.mission.systemComments.find(s => s.objectiveId === objectiveId)?.comments || [];

export const selectUserObjectives = state => state.mission.userObjectives;
export const selectLeaderUserObjectives = (state, userId) => {
  const { objectives } = state.mission.userObjectives.find(o => o.userId === userId) || {};
  return objectives || [];
};

export const selectAllUserMissions = state => state.mission.userMissions;
export const selectUserMissions = (state, userId, communityId) =>
  state.mission.userMissions.find(m => m.userId === userId && m.communityId === communityId)?.missions || [];

export const selectAllOfficerMissions = state => state.mission.officerMissions;
export const selectOfficerUserMissions = (state, userId) =>
  state.mission.officerMissions.find(m => m.userId === userId)?.missions || [];

// Used to get and set 'selectedMission' from existing redux data while the refreshed data is fetching
export const selectMission = (state, missionId) => {
  const missionsInState = [...state.mission.officerMissions, ...state.mission.userMissions];
  const objectWithMission = missionsInState.find(s => s.missions.some(m => m.id === missionId)); // Can be undefined
  if (objectWithMission) {
    return objectWithMission.missions.find(m => m.id === missionId);
  }
  return null;
};

// Get all missions and there objectives currently in redux
export const selectMissionObjectives = state => state.mission.missionObjectives;
export const selectMissionObjectivesPagination = state => state.mission.missionObjectivesPagination;

// Get a specific missions objectives
export const selectSelectedMissionObjectives = (state, missionId) => {
  return state.mission.missionObjectives.find(m => m.missionId === missionId)?.objectives || [];
};

// Used to get and set 'selectedObjective' from existing redux data while the refreshed data is fetching
export const selectObjective = (state, missionId, objectiveId) => {
  let objectives = [];

  // Check if the objective is stored under mission objectives
  const missionObjectives = state.mission.missionObjectives.find(m => m.missionId === missionId)?.objectives;

  if (missionObjectives) {
    objectives = missionObjectives;
  } else {
    // Check if the objective is stored under a mission (wont be as up to date)
    const mission = selectMission(state, missionId);
    objectives = mission ? mission.objectives : [];
  }

  return objectives.length > 0 ? objectives.find(o => o.id === objectiveId) : null;
};

export const selectSearchMissionsInCommunity = state => state.mission.searchMissionsInCommunity;

export const selectObjectivePracticeLoading = state => state.mission.objectivePracticeLoading;
export const selectObjectivePracticeSaved = state => state.mission.objectivePracticeSaved;
export const selectObjectivePracticeDeleted = state => state.mission.objectivePracticeDeleted;
export const selectAllMissionsStats = (state) => {
  const stats = state.mission.missionsStats || {}
  return stats
}
export const selectMissionsStats = (state,userId) => {
  const stats = state.mission.missionsStats || {}
  return stats[userId] || {}
}