import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  iconBox: {
    width: 100,
    height: 100,
    borderRadius: 30,
    marginBottom: 20,
    background: '#dcdcdc',
    animation: '$glow 1.5s ease-in-out infinite'
  },
  backgroundCover: {
    borderRadius: 5,
    color: '#dcdcdc',
    background: '#dcdcdc',
    animation: '$glow 1.5s ease-in-out infinite'
  },
  title: {
    fontSize: 34,
    marginBottom: 10,
    width: 260
  },
  description: {
    fontSize: 14,
    marginBottom: 20,
    width: 680,
    height: 40
  },
  date: {
    textTransform: 'uppercase',
    fontSize: 10,
    marginBottom: 5,
    width: 170
  }
});

export default function MissionHeaderLoading() {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.iconBox}
        />
      </Box>
      <Typography align="center" className={clsx(classes.backgroundCover, classes.title)}>
        Loading
      </Typography>
      <Typography align="center" className={clsx(classes.description, classes.backgroundCover)}>
        Loading
      </Typography>
      <Typography align="center" className={clsx(classes.date, classes.backgroundCover)}>
        Loading
      </Typography>
      <Typography align="center" className={clsx(classes.backgroundCover, classes.date)}>
        Loading
      </Typography>
    </Box>
  );
}
