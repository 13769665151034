import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {selectMissionLoading} from '../../state/mission/reducers';
// import {calculateScores} from '../../utils/accountabilityScoreHelper'
import * as missionTypes from '../../state/mission/types';
import {
  selectLeaderUsers, selectOfficerMembers, selectSelectedCommunityId, selectLeaderUsersLoadingState
} from '../../state/community/reducers';

const useStyles = makeStyles(theme => ({
  score: {
    ...theme.typography.h1,
    fontSize: 40,
    fontWeight: 700,
    color: ({ score }) => {
      switch (true) {
        case score > 94:
          return theme.palette.score.great;
        case score > 70:
          return theme.palette.score.good;
        case score > 50:
          return theme.palette.score.average;
        case score > 0:
          return theme.palette.score.poor;
        default:
          return theme.palette.score.zero;
      }
    }
  },
  practices: {
    ...theme.typography.h1,
    fontSize: 40,
    fontWeight: 700,
    color: ({ practices }) => {
      switch (true) {
        case practices > 94:
          return theme.palette.score.great;
        case practices > 70:
          return theme.palette.score.good;
        case practices > 50:
          return theme.palette.score.average;
        case practices > 0:
          return theme.palette.score.poor;
        default:
          return theme.palette.score.zero;
      }
    }
  },
  actions: {
    ...theme.typography.h1,
    fontSize: 40,
    fontWeight: 700,
    color: ({ actions }) => {
      switch (true) {
        case actions > 94:
          return theme.palette.score.great;
        case actions > 70:
          return theme.palette.score.good;
        case actions > 50:
          return theme.palette.score.average;
        case actions > 0:
          return theme.palette.score.poor;
        default:
          return theme.palette.score.zero;
      }
    }
  },
  heading: {
    fontSize: 10
  },
  empty: {
    fontStyle: 'italic'
  }
}));

const AccountabilityScore = ({score, actions, practices, userId }) => {
  const dispatch = useDispatch();
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const isMissionLoading = useSelector(state => selectMissionLoading(state));
  // const missions = useSelector(state => selectAllUserMissions(state));
  const classes = useStyles({ score, actions, practices });
  // const {
  // tasks, completedTasks, practices,
  //   actionScore,
  //   practiceScore,
  //   performanceScore,
  // } = useSelector((state) => selectMissionsStats(state, userId))
  let actionScore = 0
  let practiceScore = 0
  let performanceScore = 0

  const leaderMembers = useSelector(state => selectLeaderUsers(state));
  const officerMembers = useSelector(state => selectOfficerMembers(state));
  const isLoadingMembers = useSelector(state => selectLeaderUsersLoadingState(state))
  const memberInfo = [...leaderMembers,...officerMembers].filter(m => m.id === userId)[0]

  if(memberInfo?.stats) {
    actionScore = memberInfo?.stats.tasks.score
    practiceScore = memberInfo?.stats.practices.score
    performanceScore = memberInfo?.stats.performanceScore
  }

  useEffect(()=>{
    dispatch({
      type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
      userId,
      communityId,
    })
  }, [])

  // useEffect(() => {
  //   if(userId) {
  //     dispatch({ type: missionTypes.GET_MISSIONS_STATS_REQUEST, userId, communityId })
  //   }
  // }, [userId])

  const getColor = (points) => {
    switch (true) {
      case points > 94:
        return 'rgb(40 162 82)';
      case points > 70:
        return 'rgb(40 162 82)';
      case points > 50:
        return '#fb792b';
      case points > 0:
        return '#f20046';
      default:
        return '#8b8f95';
    }
  }

  if (isMissionLoading || isLoadingMembers) {
    return <CircularProgress />
  }

  // const {practices: calculatedPractices, actions: calculatedActions, performance} = calculateScores(missions)
  return (
    <Box display="inline-flex" flex={1} width={500} marginBottom={1} position="relative">
      <Box display="flex" flex={1} flexDirection="column" marginBottom={1} alignItems="center" position="relative">
        <Typography className={classes.heading}>Performance Score</Typography>

        {performanceScore ? (
          <>
            <Typography
              className={classes.score}
              style={{color: getColor(performanceScore)}}
            >
              {`${(performanceScore || 0).toFixed(0)}%`}
            </Typography>
          </>
        ) : (
          <Typography className={classes.empty}>No score yet</Typography>
        )}
      </Box>

      <Box display="flex" flex={1} flexDirection="column" marginBottom={1} alignItems="center" position="relative">
        <Typography className={classes.heading}>Actions</Typography>

        {actionScore !== 0 ? (
          <>
            <Typography
              className={classes.actions}
              style={{color: getColor(actionScore)}}
            >
              {`${(actionScore || 0).toFixed(0)}%`}
            </Typography>
          </>
        ) : (
          <Typography className={classes.empty}>No score yet</Typography>
        )}
      </Box>
      <Box display="flex" flex={1} flexDirection="column" marginBottom={1} alignItems="center" position="relative">
        <Typography className={classes.heading}>Practices</Typography>

        {practiceScore !== 0 ? (
          <>
            <Typography
              className={classes.practices}
              style={{color: getColor(practiceScore)}}
            >
              {`${(practiceScore || 0).toFixed(0)}%`}
            </Typography>
          </>
        ) : (
          <Typography className={classes.empty}>No score yet</Typography>
        )}
      </Box>
    </Box>

  );
};

AccountabilityScore.propTypes = {
  score: PropTypes.number,
  practices: PropTypes.number,
  actions: PropTypes.number,
  userId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired
};

AccountabilityScore.defaultProps = {
  score: null,
  actions: null,
  practices: null,
};

export default AccountabilityScore;
