import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import TablePaginationActions from './PaginationActions';

import TableLoading from './TableLoading';

const styles = makeStyles({
  paper: {
    borderRadius: 10,
    padding: '18px 15px',
    width: '100%'
  },
  tableCell: {
    padding: 0,
    border: 0,
    marginBottom: 10
  },
  memberCard: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: 20,
    minHeight: 80
  },
  noBorder: {
    border: 0
  },
  noData: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 700,
    fontSize: 24
  }
});

export default function StyledTable({
  data,
  loading,
  menuActions,
  noDataMessage,
  rowCard: RowCard,
  readOnly,
  onClick,
  onCheck,
  elevation,
  minHeight,
  rowsPerPage,
  boxShadow,
  cardHeight,
  totalCount,
  onChangePage,
  dataSelector,
  usePaper,
  showEmptyRows,
  footer,
  isChecked,
  enableCheckbox
}) {
  const classes = styles();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onChangePage) onChangePage(newPage);
  };

  let paginatedTableData = [];
  if (dataSelector) {
    paginatedTableData = dataSelector(page);
  } else paginatedTableData = data ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : null;
  const hasData = paginatedTableData !== null ? paginatedTableData.length > 0 : false;
  const emptyRows = Math.min(rowsPerPage - (hasData ? paginatedTableData.length : 0));

  const Wrapper = usePaper ? Paper : 'div';
  const wrapperProps = usePaper
    ? {
      className: classes.paper,
      elevation,
      style: { minHeight, boxShadow }
    }
    : {};

  return (
    <Wrapper {...wrapperProps}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {paginatedTableData &&
                paginatedTableData.map((row, rowIdx) => (
                  <TableRow key={row._id || `tableRow-${rowIdx}`}>
                    <TableCell scope="row" className={classes.tableCell}>
                      {RowCard && (
                        <RowCard
                          isChecked={isChecked ? isChecked(row) : false}
                          onClick={onClick ? () => onClick(row) : null}
                          onCheck={onCheck ? () => onCheck(row) : null}
                          enableCheckbox={enableCheckbox}
                          {...row}
                          readOnly={readOnly}
                          menuActions={menuActions && menuActions(row)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {showEmptyRows && emptyRows > 0 && hasData && (
                <TableRow style={{ height: cardHeight * emptyRows }}>
                  <TableCell colSpan={6} className={classes.noBorder} />
                </TableRow>
              )}
              {!hasData && (
                <TableRow>
                  <TableCell className={classes.noBorder}>
                    <Box height={50 * emptyRows} alignItems="center" justifyContent="center" display="flex" padding={2}>
                      {
                        typeof noDataMessage === 'string' ?
                          (
                            <Typography align="center" className={classes.noData}>
                              {noDataMessage}
                            </Typography>
                          )
                          :
                          <div>{noDataMessage}</div>
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {hasData && (totalCount || data.length) > rowsPerPage && (
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    colSpan={3}
                    count={totalCount || data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    classes={{
                      root: classes.noBorder
                    }}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              )}
            </>
          )}
        </TableBody>
        {footer && <TableFooter>{footer}</TableFooter>}
      </Table>
    </Wrapper>
  );
}

StyledTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  rowCard: PropTypes.func,
  loading: PropTypes.bool,
  menuActions: PropTypes.func,
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readOnly: PropTypes.bool,
  elevation: PropTypes.number,
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  minHeight: PropTypes.number,
  rowsPerPage: PropTypes.number,
  boxShadow: PropTypes.string,
  cardHeight: PropTypes.number,
  totalCount: PropTypes.number,
  onChangePage: PropTypes.func,
  dataSelector: PropTypes.func,
  usePaper: PropTypes.bool,
  showEmptyRows: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
  footer: PropTypes.node,
  isChecked: PropTypes.func,
};

StyledTable.defaultProps = {
  data: [],
  loading: false,
  menuActions: null,
  noDataMessage: 'Nothing here yet... Check back soon!',
  readOnly: false,
  elevation: 4,
  onClick: null,
  onCheck: null,
  minHeight: null,
  rowsPerPage: 10,
  boxShadow: '',
  rowCard: null,
  cardHeight: 80,
  totalCount: null,
  onChangePage: null,
  dataSelector: null,
  usePaper: true,
  showEmptyRows: true,
  enableCheckbox: false,
  footer: null,
  isChecked: null,
};
