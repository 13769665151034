import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import MissionIcon from '../MissionIcon/MissionIcon';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#dcdcdc',
    borderRadius: 10,
    padding: 23,
    color: '#223f63',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: 34,
    fontWeight: 500,
    color: 'inherit',
    marginBottom: 10,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  description: {
    fontSize: 14,
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif',
    color: 'inherit',
    marginBottom: 20,
    maxWidth: 680
  },
  date: {
    textTransform: 'uppercase',
    fontSize: 10,
    color: '#838b96',
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  }
});

const TemplateDetails = ({ template }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="center" marginBottom={2.5}>
        <MissionIcon large id={template?.id} icon={template?.icon} isTemplate />
      </Box>
      <Typography align="center" className={classes.title}>
        {template?.title}
      </Typography>
      <Typography align="center" className={classes.description}>
        {template?.description}
      </Typography>
      <Typography align="center" className={classes.date}>
        {`Created Date • ${moment(template?.createdAt).format('D MMMM YYYY')}`}
      </Typography>
      <Typography
        align="center"
        className={classes.date}
        style={{ marginBottom: 40 }}
      >
        {`Ends ${template?.startDays} days after assigned`}
      </Typography>
    </Box>
  );
};

TemplateDetails.propTypes = {
  template: PropTypes.object.isRequired
};

export default TemplateDetails;
