import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  wrap: {
    display: 'flex',
    marginBottom: 20
  },
  quarter: {
    flexBasis: '25%',
    '& div': {
      width: '33.33333333333333%'
    }
  },
  half: {
    margin: '0px 10px',
    flexBasis: '50%',
    '& div': {
      width: '25%'
    }
  },
  title: {
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10,
    '& span': {
      color: '#838b96',
      fontStyle: 'italic'
    }
  },
  contentBase: {
    color: theme.palette.common.white,
    padding: '5px 5px 5px 10px',
    display: 'inline-flex',
    flexDirection: 'column',
    minHeight: 40,
    '& p': {
      fontSize: 16,
      fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
      marginBottom: 0
    },
    '& span': {
      textTransform: 'uppercase',
      fontSize: 9,
      fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
    }
  },
  left: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  right: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  },
  overdue: {
    backgroundColor: theme.palette.error.main
  },
  inProgress: {
    backgroundColor: theme.palette.info.main
  },
  needHelp: {
    backgroundColor: theme.palette.warning.main
  },
  completed: {
    backgroundColor: theme.palette.success.main
  },
  circular: {
    height: '20px !important',
    width: '20px !important',
    marginBottom: 4,
    color: theme.palette.common.white
  }
}));

const MissionOverview = ({ loading, totalMembers, overview }) => {
  // Hooks
  const classes = useStyles();

  // Classes
  const overdueClass = clsx(classes.contentBase, classes.left, classes.overdue);
  const inProgressClass = clsx(classes.contentBase, classes.inProgress);
  const completedClass = clsx(classes.contentBase, classes.right, classes.completed);
  const needHelpClass = clsx(classes.contentBase, classes.needHelp);

  // Constants
  const { missions, objectives, tasks } = overview || {};

  return (
    <Box className={classes.wrap}>
      <Box className={classes.quarter}>
        <Typography noWrap className={classes.title}>
          Total Missions {!loading && <span>{`(${totalMembers}) Members`}</span>}
        </Typography>
        <Box className={overdueClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{missions?.overdue}</Typography>
          )}
          <Typography noWrap component="span">
            Overdue
          </Typography>
        </Box>
        <Box className={inProgressClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{missions?.inProgress}</Typography>
          )}
          <Typography noWrap component="span">
            In Progress
          </Typography>
        </Box>
        <Box className={completedClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{missions?.complete}</Typography>
          )}
          <Typography noWrap component="span">
            Completed
          </Typography>
        </Box>
      </Box>
      <Box className={classes.half}>
        <Typography noWrap className={classes.title}>
          Total Objectives {!loading && <span>{`(${totalMembers}) Members`}</span>}
        </Typography>
        <Box className={overdueClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{objectives?.overdue}</Typography>
          )}
          <Typography noWrap component="span">
            Overdue
          </Typography>
        </Box>
        <Box className={needHelpClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{objectives?.needHelp}</Typography>
          )}
          <Typography noWrap component="span">
            Need Help
          </Typography>
        </Box>
        <Box className={inProgressClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{objectives?.inProgress}</Typography>
          )}
          <Typography noWrap component="span">
            In Progress
          </Typography>
        </Box>
        <Box className={completedClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{objectives?.complete}</Typography>
          )}
          <Typography noWrap component="span">
            Completed
          </Typography>
        </Box>
      </Box>
      <Box className={classes.quarter}>
        <Typography noWrap className={classes.title}>
          Total Actions {!loading && <span>{`(${totalMembers}) Members`}</span>}
        </Typography>
        <Box className={overdueClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{tasks?.overdue}</Typography>
          )}
          <Typography noWrap component="span">
            Overdue
          </Typography>
        </Box>
        <Box className={inProgressClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{tasks?.inProgress}</Typography>
          )}
          <Typography noWrap component="span">
            In Progress
          </Typography>
        </Box>
        <Box className={completedClass}>
          {loading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <Typography component="p">{tasks?.complete}</Typography>
          )}
          <Typography noWrap component="span">
            Completed
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MissionOverview.propTypes = {
  loading: PropTypes.bool.isRequired,
  overview: PropTypes.shape({
    missions: PropTypes.shape({
      complete: PropTypes.number,
      inProgress: PropTypes.number,
      overdue: PropTypes.number
    }),
    objectives: PropTypes.shape({
      complete: PropTypes.number,
      inProgress: PropTypes.number,
      overdue: PropTypes.number,
      needHelp: PropTypes.number
    }),
    tasks: PropTypes.shape({
      complete: PropTypes.number,
      overdue: PropTypes.number,
      needHelp: PropTypes.number
    })
  }),
  totalMembers: PropTypes.number
};

MissionOverview.defaultProps = {
  overview: null,
  totalMembers: 0
};

export default MissionOverview;
