import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import ObjectivesTable from '../Objective/ObjectivesTable';
import MissionIcon from '../MissionIcon/MissionIcon';

const useStyles = makeStyles({
  body: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 20,
    backgroundColor: 'white',
    borderColor: '#9facbc',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    '& .MuiCollapse-container': {
      background: '#f5f5f5'
    },
    overflow: 'hidden'
  },
  expanded: {
    minHeight: 0,
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    rowGap: '20px'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  title: {
    fontSize: 20,
    '&:hover': {
      '& *': {
        fontWeight: 'bold'
      }
    }
  }
});

const MissionOverviewAccordion = ({
  mission,
  onCreateObjectiveClick,
  onMissionClick,
  onObjectiveClick,
  defaultExpanded,
  loggedInUser
}) => {
  const classes = useStyles();

  let onMissionTitleProps = {};

  if (onMissionClick) {
    onMissionTitleProps = {
      className: classes.title,
      onClick: () => onMissionClick(mission)
    };
  }

  const leaderBox = () => {
    if (!mission.leader) {
      return <Typography variant="subtitle2">No leader</Typography>;
    }

    return (
      <>
        <Avatar alt={`${mission.leader.firstName} ${mission.leader.lastName}`} src={mission.leader.avatar?.sm} style={{ marginRight: 10 }} />
        <Typography>{mission.leader.firstName} {mission.leader.lastName}</Typography>
      </>
    );
  };

  return (
    <Accordion defaultExpanded={defaultExpanded} classes={{ root: classes.body }}>
      <AccordionSummary
        classes={{
          content: classes.summary,
          root: classes.root,
          expanded: classes.expanded
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box display="flex" flexGrow={1} alignItems="center" {...onMissionTitleProps}>
          <MissionIcon icon={mission.icon} id={mission.id} />

          <Box marginLeft={2.5}>
            <Typography>{mission.title}</Typography>
            {mission.endsAt ? (
              <Typography variant="subtitle2">DUE {moment(mission.endsAt).format('D MMMM YYYY')}</Typography>
            ) : (
              <Typography variant="subtitle2">No due date</Typography>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" marginRight={2}>{leaderBox()}</Box>

        <Box marginRight={2}>
          <Typography variant="subtitle2">Completed Objectives</Typography>
          <Typography>
            {mission.countCompletedObjectives} of {mission.totalObjectives}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {mission.hasObjectives && <ObjectivesTable objectives={mission.objectives} onRowClick={onObjectiveClick} />}
      </AccordionDetails>
    </Accordion>
  );
};

MissionOverviewAccordion.propTypes = {
  mission: PropTypes.object.isRequired,
  canCreateObjective: PropTypes.bool.isRequired,
  onCreateObjectiveClick: PropTypes.func,
  onMissionClick: PropTypes.func,
  onObjectiveClick: PropTypes.func,
  loggedInUser: PropTypes.string
};

MissionOverviewAccordion.defaultProps = {
  onCreateObjectiveClick: null,
  onMissionClick: null,
  onObjectiveClick: null,
  loggedInUser: undefined
};

export default MissionOverviewAccordion;
