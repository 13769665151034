import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import MenuDrawer from '../components/MenuDrawer/MenuDrawer';
import Chat from '../components/Chat/Chat';
import Modal from '../components/Modal/Modal';
import SnackWrapper from '../components/SnackWrapper/SnackWrapper';

import GradientDefinition from '../components/MissionIcon/GradientDefinition';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      // make room for the app bar on mobile devices
      paddingTop: 56
    }
  }
}));

export default function AppShell({ children }) {
  // Check permissions - display chat if leader
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <CssBaseline />
        <MenuDrawer />
        {children}
        <Chat />
      </Box>
      <Modal />
      <SnackWrapper />
      <GradientDefinition />
    </>
  );
}

AppShell.propTypes = {
  children: PropTypes.node.isRequired
};
