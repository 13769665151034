import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import AuthBackground from '../../components/Auth/AuthBackground/AuthBackground';
import AcceptInvitationCard from '../../components/Auth/AcceptInvitationCard/AcceptInvitationCard';
import AuthConfirmationCard from '../../components/Auth/AuthConfirmationCard/AuthConfirmationCard';
import Modal from '../../components/Modal/Modal';

import { validateInvitation, parseApiErrors } from '../../constants/validation';

import * as authTypes from '../../state/auth/types';
import {
  selectInviteAccepted,
  selectAuthSavingState,
  selectAuthApiErrors
} from '../../state/auth/reducers';

import authStyles from './authStyles';

const styles = makeStyles({
  ...authStyles
});

export default function AcceptInvitation() {
  const classes = styles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(true);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: ''
  });
  const [errors, setErrors] = useState(null);

  const { firstName, lastName, password, passwordConfirm } = values;

  // Redux state
  const inviteAccepted = useSelector(state => selectInviteAccepted(state));
  const saving = useSelector(state => selectAuthSavingState(state));
  const apiErrors = useSelector(state => selectAuthApiErrors(state));

  useEffect(() => {
    // Clear redux inviteAccepted state on mount
    dispatch({
      type: authTypes.CLEAR_ACCEPT_INVITE
    });
  }, []);

  // Successful submit - show confirmation instead
  useEffect(() => {
    if (inviteAccepted) setShowForm(false);
    else setShowForm(true);
  }, [inviteAccepted]);

  function handleChange(e) {
    const { name, value } = e.target;

    if (errors !== null) {
      const hasError = validateInvitation({ ...values, [name]: value });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    if (name === 'password' || name === 'passwordConfirm') {
      setValues({
        ...values,
        [name]: value.trim()
      });
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  }

  function handleSubmit() {
    // Check for errors before sending
    const errorMessages = validateInvitation(values);
    if (errorMessages === undefined) {
      setErrors(null);
      // Everything is groovy, submit the payload
      const { invitationId, token } = queryString.parse(location.search);
      dispatch({
        type: authTypes.ACCEPT_INVITATION,
        invitationId,
        payload: {
          ...values,
          token
        }
      });
    } else setErrors(errorMessages);
  }

  const computedErrors =
    errors || parseApiErrors(apiErrors, authTypes.ACCEPT_INVITATION_ERROR);

  return (
    <AuthBackground>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          <Logo className={classes.logo} />
          {showForm ? (
            <AcceptInvitationCard
              firstName={firstName}
              lastName={lastName}
              password={password}
              passwordConfirm={passwordConfirm}
              onChange={handleChange}
              onSubmit={handleSubmit}
              errors={computedErrors}
              loading={saving}
            />
          ) : (
            <AuthConfirmationCard
              title="Mission complete!"
              copy="You have successfully signed up to the platform. Please log in with your new credentials!"
            />
          )}
        </Grid>
      </Container>
      <Modal />
    </AuthBackground>
  );
}
