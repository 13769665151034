import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';
import moment from 'moment';
import Box from '@material-ui/core/Box';

import ListFilter from '../components/ListFilter/ListFilter';
import Table from '../components/Table/Table';
import TeamRowCard from '../components/RowCards/TeamRowCard';
import TeamRowHeader from '../components/RowCards/TeamRowHeader';
import TeamDrawer from '../components/TeamDrawer/TeamDrawer';
import TeamAccountabilityScoreChart from '../components/AccountabilityScore/TeamAccountabilityScoreChart';

import * as teamTypes from '../state/teams/types';
import * as reportTypes from '../state/reports/types';
import { selectTeams, selectTeamLoading } from '../state/teams/reducers';
import { selectSelectedCommunityId } from '../state/community/reducers';

import { selectTeamsAccountabilityScores, selectTeamsAccountabilityScoresLoading } from '../state/reports/reducers';

import * as communityTypes from '../state/community/types';
import { consolidateLeaderActivity } from '../utils/stateHelpers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['title', 'description']
};

class ManageTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filter: 1,
      drawer: false,
      showCreate: false,
      isEdit: false
    };

    const { getTeams, communityId, getCommunityUsers } = props;
    getTeams(communityId);
    getCommunityUsers(communityId);

    this.fetchTeamsAccountabilityScore();
  }

  componentDidUpdate(prevProps) {
    // If the community is changed - refresh the data
    const { communityId: prevCommunityId } = prevProps;
    const { communityId, getTeams } = this.props;
    if (communityId !== prevCommunityId) {
      getTeams(communityId);
    }
  }

  onSearchChange = value => {
    this.setState({
      search: value
    });
  };

  onClose = () => {
    const { clearApiErrors } = this.props;
    clearApiErrors();
    this.setState({
      drawer: false
    });
  };

  onCreate = () => {
    this.setState({
      drawer: true,
      showCreate: true,
      isEdit: false
    });
  };

  openViewTeam = row => {
    const { setTeam } = this.props;
    setTeam(row);
    this.setState({
      drawer: true,
      showCreate: false
    });
  };

  openEditTeam = row => {
    const { setTeam } = this.props;
    setTeam(row);
    this.setState({
      drawer: true,
      showCreate: true,
      isEdit: true
    });
  };

  switchToEditDetails = () => {
    this.setState({
      drawer: true,
      showCreate: true,
      isEdit: true
    });
  };

  generateMenuActions = row => {
    const { readOnly } = this.props;
    const actions = [
      {
        onClick: () => this.openViewTeam(row),
        label: 'View team details'
      }
    ];

    if (!readOnly) {
      actions.push({
        onClick: () => this.openEditTeam(row),
        label: 'Edit team'
      });
    }

    return actions;
  };

  searchTeams = teams => {
    const { search } = this.state;
    const fuseInstance = new Fuse(teams, fuseOpts);
    return fuseInstance.search(search);
  };

  handleTeamAccountabilityScoreChartFilterChange = e => {
    const { value } = e.target;
    this.setState({ filter: value }, this.fetchTeamsAccountabilityScore);
  };

  fetchTeamsAccountabilityScore = () => {
    const { communityId, getTeamsAccountabilityScore } = this.props;
    const { filter } = this.state;

    const now = moment();
    const startDate = moment(now)
      .subtract(filter, 'months')
      .startOf('day')
      .toISOString();
    const endDate = moment(now)
      .startOf('day')
      .toISOString();

    getTeamsAccountabilityScore(communityId, startDate, endDate);
  };

  render() {
    const { readOnly, teams, teamLoading, teamAccountabilityScores, teamsAccountabilityScoresLoading } = this.props;
    const { search, filter, drawer, showCreate, isEdit } = this.state;

    const hasSearch = search.trim().length > 0;
    const computedTeams = hasSearch ? this.searchTeams(teams) : teams;

    let scoresByTeamId = {};
    if (teamAccountabilityScores) {
      scoresByTeamId = teamAccountabilityScores.reduce((all, curr) => {
        all[curr.team] = all[curr.team] || [];
        all[curr.team].push(curr);
        return all;
      }, {});
    }

    const filterOptions = [
      { label: '1 Month', value: 1 },
      { label: '3 Month', value: 3 },
      { label: '6 Month', value: 16 },
      { label: '12 Month', value: 12 }
    ];

    const formattedTeams = computedTeams
      .map(t => ({
        ...t,
        scores: scoresByTeamId[t._id] || []
      }))
      // Average the weeks by month
      // Note: this "average" will never be perfect as weeks can start and end
      // in different month
      .map(team => {
        const scoresByMonth = team.scores.reduce((acc, score) => {
          const date = score.fromDate;
          const month = moment(date).month();

          // Bail if we've already calculated this month
          const previous = acc.find(s => s.month === month);
          if (previous) {
            return acc;
          }

          // workout the average
          const sameMonth = team.scores.filter(s => moment(s.fromDate).isSame(date, 'month'));
          const sum = sameMonth.reduce((a, e) => a + e.score, 0);
          const average = sum / sameMonth.length;

          // set the response
          const monthScores = {
            month,
            average
          };

          acc.push(monthScores);

          return acc;
        }, []);
        return {
          ...team,
          groupBy: filter < 5 ? 'week' : 'month', // pass this through the the table. not great
          scoresByMonth
        };
      });

    const scoreSetSizes = Object.values(formattedTeams).map(arr => arr.scores.length);
    const largestSetWeek = Math.max(...scoreSetSizes);
    const largestMonthSet = Math.max(...formattedTeams.map(t => t.scoresByMonth.length));
    const usedSet = filter < 5 ? largestSetWeek : largestMonthSet;
    const totalSets = isFinite(usedSet) ? usedSet : 0;

    return (
      <>
        <TeamAccountabilityScoreChart
          loading={teamsAccountabilityScoresLoading}
          teamAccountabilityScores={teamAccountabilityScores}
        />
        <div style={{ marginBottom: 50 }} />

        <ListFilter
          search={search}
          onSearchChange={this.onSearchChange}
          filter={filter}
          filterOptions={filterOptions}
          onFilterChange={this.handleTeamAccountabilityScoreChartFilterChange}
          onCreate={this.onCreate}
          createLabel="Create team"
          readOnly={readOnly}
        />
        <div style={{ marginBottom: 50 }} />

        {/* <Box padding={1} style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }}> */}
        <TeamRowHeader numberOfScores={totalSets} grouping={filter < 5 ? 'week' : 'month'} />

        <Table
          loading={teamLoading}
          data={formattedTeams}
          rowCard={TeamRowCard}
          menuActions={this.generateMenuActions}
          onClick={this.openViewTeam}
        />
        {/* </Box> */}

        <TeamDrawer
          open={drawer}
          onClose={this.onClose}
          showCreate={showCreate}
          isEdit={isEdit}
          onEditDetails={this.switchToEditDetails}
          readOnly={readOnly}
        />
      </>
    );
  }
}

ManageTeams.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  communityId: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
  getTeams: PropTypes.func.isRequired,
  setTeam: PropTypes.func.isRequired,
  getCommunityUsers: PropTypes.func.isRequired,
  teamLoading: PropTypes.bool.isRequired,
  clearApiErrors: PropTypes.func.isRequired,
  getTeamsAccountabilityScore: PropTypes.func.isRequired,
  teamAccountabilityScores: PropTypes.array.isRequired,
  teamsAccountabilityScoresLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  communityId: selectSelectedCommunityId(state),
  teamLoading: selectTeamLoading(state),
  teams: selectTeams(state),
  teamAccountabilityScores: selectTeamsAccountabilityScores(state),
  teamsAccountabilityScoresLoading: selectTeamsAccountabilityScoresLoading(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTeams: communityId => ({
        type: teamTypes.GET_ALL_TEAMS,
        communityId
      }),
      setTeam: team => ({
        type: teamTypes.SET_TEAM,
        team
      }),
      getCommunityUsers: communityId => ({
        type: communityTypes.GET_COMMUNITY_USERS,
        communityId
      }),
      clearApiErrors: () => ({
        type: teamTypes.CLEAR_TEAM_API_ERRORS
      }),
      getTeamsAccountabilityScore: (communityId, startDate, endDate) => ({
        type: reportTypes.GET_TEAMS_ACCOUNTABILITY_SCORE,
        communityId,
        startDate,
        endDate
      })
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageTeams);
