import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import {selectToolkits} from '../../state/toolkits/reducers';
import ToolkitCard from '../RowCards/ToolkitCard';
import ToolkitDropdown from '../ToolkitDropdown/ToolkitDropdown';

const styles = makeStyles({
  labelRoot: {
    color: '#838b96',
    zIndex: 1,
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 7,
    pointerEvents: 'none',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  labelShrink: {
    paddingTop: 0
  },
  noLeaders: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600
  }
});

export default function AssignedToolkits({
  readOnly,
  assignedToolkits,
  selectedToolkits,
  onSelect,
  onRemove,
  menuPosition,
  isSingle
}) {
  const classes = styles();

  // Redux
  const toolkits = useSelector(state => selectToolkits(state));

  // Filter out the toolkits already assigned and the current selected toolkits
  const availableToolkits = toolkits
    .filter(t => !assignedToolkits.some(at => at.id === t.id))
    .filter(t => !selectedToolkits.some(st => st.id === t.id));

  const computedAssignedToolkits = [...assignedToolkits, ...selectedToolkits];
  const hasToolkits = computedAssignedToolkits.length > 0;

  const getButtonText = () => {
    if (isSingle) {
      return 'Assign tool';
    }
    if (hasToolkits) {
      return 'Assign another tool';
    }
    return 'Assign tool';
  };

  return (
    <>
      <InputLabel
        shrink
        classes={{
          root: classes.labelRoot,
          shrink: classes.labelShrink
        }}
      >
        Assign tools
      </InputLabel>
      {!readOnly && (
        <div style={{marginBottom: 10}}>
          <ToolkitDropdown
            toolkits={availableToolkits}
            buttonText={getButtonText()}
            placeholder="Search and/or select a tool..."
            noDataMessage="No tools to choose from!"
            onSelect={value => {
              onSelect(value);
            }}
            menuPosition={menuPosition}
          />
        </div>
      )}
      {computedAssignedToolkits.map(({ id, title, description }) => (
        <ToolkitCard
          enableCheckbox={false}
          enableDelete
          key={id}
          readOnly={readOnly}
          title={title}
          description={description}
          onRemove={() => onRemove(id)}
        />
      ))}
      {
        hasToolkits &&
        readOnly &&
        (<Typography className={classes.noLeaders}>This user has no tools</Typography>)
      }
    </>
  );
}

AssignedToolkits.propTypes = {
  readOnly: PropTypes.bool,
  isSingle: PropTypes.bool,
  assignedToolkits: PropTypes.array.isRequired,
  selectedToolkits: PropTypes.array,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  menuPosition: PropTypes.string
};

AssignedToolkits.defaultProps = {
  onRemove: null,
  onSelect: null,
  readOnly: false,
  selectedToolkits: [],
  menuPosition: 'bottom',
  isSingle: false
};