import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../Button/Button';
import { ReactComponent as TrashIcon } from '../../static/images/icons/icon-trash.svg';
import UserDropdown from '../UserDropdown/UserDropdown';
import ProfileImage from '../ProfileImage/ProfileImage';
import PopUpMenu from '../PopUpMenu/PopUpMenu';

import * as teamTypes from '../../state/teams/types';
import * as modalTypes from '../../state/modal/types';

import { selectSelectedTeam, selectTeamSaving, selectUsersPendingChange } from '../../state/teams/reducers';
import { selectCommunityUsers, selectSelectedCommunityId } from '../../state/community/reducers';

const useStyles = makeStyles({
  container: {
    padding: 40
  },
  header: {
    marginBottom: 80
  },
  title: {
    fontSize: 12,
    color: '#838b96',
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  iconButton: {
    cursor: 'pointer',
    backgroundColor: '#dcdcdc',
    borderRadius: 20,
    padding: '12px 18px'
  },
  button: {
    marginLeft: 10,
    width: 150
  },
  teamName: {
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 34,
    color: '#072144'
  },
  teamDescription: {
    margin: '0 auto',
    maxWidth: 433,
    fontSize: 14,
    fontWeight: 300
  },
  members: {
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 24,
    flex: 1
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    padding: 10,
    marginTop: 10
  },
  userName: {
    fontSize: 12,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    flex: 1
  }
});

export default function TeamDetailsView({ readOnly, onEditDetails, onClose }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local
  const [newUsers, setNewUsers] = useState([]);

  // Redux
  const team = useSelector(state => selectSelectedTeam(state));
  const communityUsers = useSelector(state => selectCommunityUsers(state));
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const teamSaving = useSelector(state => selectTeamSaving(state));
  const usersPending = useSelector(state => selectUsersPendingChange(state));

  // Update selected new leaders after successful post
  useEffect(() => {
    if (team) {
      setNewUsers([]);
    }
  }, [team]);

  // Event handlers
  function confirmDelete() {
    dispatch({
      type: teamTypes.DELETE_TEAM,
      communityId,
      teamId: team._id
    });
    onClose();
  }

  function handleDelete() {
    // Confirmation popup
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.CONFIRMATION_MODAL,
      props: {
        title: 'Are you sure you want to delete this team?',
        message: `'${team.title}' will be gone forever...`,
        onSubmit: confirmDelete,
        savingSelector: selectTeamSaving
      }
    });
    // Open modal
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
  }

  function addUser(user) {
    setNewUsers([...newUsers, user]);
  }

  function onSave() {
    dispatch({
      type: teamTypes.ADD_MEMBERS_TO_TEAM,
      communityId,
      teamId: team._id,
      users: newUsers
    });
  }

  function confirmRemoveUser(user) {
    dispatch({
      type: teamTypes.REMOVE_MEMBER_FROM_TEAM,
      communityId,
      teamId: team._id,
      userId: user._id
    });
  }

  function onRemoveUser(user) {
    // Confirmation popup
    const isNewUser = newUsers.some(u => u._id === user._id);
    if (isNewUser) {
      const updated = newUsers.filter(u => u._id !== user._id);
      setNewUsers(updated);
    } else {
      dispatch({
        type: modalTypes.MODAL_SET_COMPONENT,
        component: modalTypes.CONFIRMATION_MODAL,
        props: {
          title: 'Are you sure you want to remove this user?',
          message: `'${user.firstName} ${user.lastName}' will be removed from ${team.title}.`,
          onSubmit: () => confirmRemoveUser(user)
          // savingSelector: selectTeamSaving
        }
      });
      // Open modal
      dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
    }
  }

  // Helper constants
  const computedMembers = communityUsers.filter(
    u => !team.users.some(t => u._id === t._id) && !newUsers.some(nu => nu._id === u._id)
  );
  const teamInitialized = team !== null;

  // Overkill but stops to duplicate key console error after successful post
  const filteredTeamMembers = teamInitialized ? team.users.filter(tu => !newUsers.some(nu => nu._id === tu._id)) : [];
  const computedTeamMembers = teamInitialized ? [...filteredTeamMembers, ...newUsers] : [];

  // Helper functions
  const generateMenu = user => [
    {
      onClick: () => onRemoveUser(user, true),
      label: 'Remove user'
    }
  ];

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.header}>
        <Grid container>
          <Grid item xs={6} className={classes.flexStart}>
            <Typography className={classes.title}>Team details</Typography>
          </Grid>
          {!readOnly && (
            <Grid item xs={6} className={classes.flexEnd}>
              <IconButton onClick={handleDelete} className={classes.iconButton} data-test-id="button-team-delete">
                <TrashIcon />
              </IconButton>
              <Button
                data-test-id="button-team-edit"
                className={classes.button}
                onClick={onEditDetails}
                label="Edit details"
                background="grey"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box marginBottom={8}>
          <Typography align="center" className={classes.teamName}>
            {team.title}
          </Typography>
          <Typography align="center" className={classes.teamDescription}>
            {team.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" marginBottom={2}>
          <Typography className={classes.members}>Members</Typography>
          <Button
            label="Save new members"
            onClick={onSave}
            disabled={!newUsers.length}
            loading={teamSaving}
            data-test-id="button-member-save-new-member"
          />
        </Box>
        {!readOnly && (
          <UserDropdown
            users={computedMembers}
            onSelect={addUser}
            placeholder="Search member"
            buttonText="Add new members"
            noDataMessage="No users to choose from!"
            saving={teamSaving}
            userIdsPendingChange={usersPending}
          />
        )}
        {teamInitialized && computedTeamMembers.length > 0 ? (
          computedTeamMembers.map(user => {
            const pending = usersPending.some(u => u === user._id);
            return (
              <Box key={user._id} className={classes.user} data-test-id="member-assigned-row">
                <ProfileImage userId={user._id} style={{ marginRight: 10 }} />
                <Typography noWrap className={classes.userName}>
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
                {!readOnly && (
                  <>
                    {pending ? (
                      <CircularProgress />
                    ) : (
                      <PopUpMenu data-test-id="button-member-assigned-row" menuActions={generateMenu(user)} />
                    )}
                  </>
                )}
              </Box>
            );
          })
        ) : (
          <Typography align="center" style={{ marginTop: 10 }}>
            There are no users in this team
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

TeamDetailsView.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onEditDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
