import * as types from './types';

const initialState = {
  selectedCommunityMission: null,
  communityMissionLoading: false,
  communityMissions: [],
  communityMissionsPagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1
  },
  communityMissionsLoading: false,
  communityMissionSaved: false,
  communityMissionSaving: false,
  communityMissionCreating: false,
  communityMissionArchiving: false,

  selectedCommunityMissionObjective: null,
  communityMissionObjectiveLoading: false,
  communityMissionObjectiveSaved: false,
  communityMissionObjectiveSaving: false,
  communityMissionObjectivesLoading: false,
  communityMissionObjectives: [],
  communityMissionObjectivesPagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    total: 1
  },

  communityMissionObjectivePracticeLoading: false,
  communityMissionObjectivePracticeSaved: false,
  communityMissionObjectivePracticeDeleted: false,

  // Tasks
  isCreatingTask: false,
  isCreatingTaskSuccess: false,
  isCreatingTaskError: false,

  updatingTasks: [],
  systemComments: [],
  systemCommentsLoading: false
};

const communityMissionReducer = (state = initialState, action) => {
  switch (action.type) {
    // Community Missions
    case types.GET_COMMUNITY_MISSIONS_LOADING:
      return {
        ...state,
        communityMissionsLoading: action.state
      };
    case types.GET_COMMUNITY_MISSIONS_SUCCESS:
      return {
        ...state,
        communityMissions: action.results,
        communityMissionsPagination: {
          total: action.total,
          page: action.page,
          totalPages: action.totalPages,
          limit: action.limit
        }
      };

    // Community Mission
    case types.GET_COMMUNITY_MISSION_LOADING:
      return {
        ...state,
        communityMissionLoading: action.state
      };

    case types.GET_COMMUNITY_MISSION_OBJECTIVE_LOADING:
      return {
        ...state,
        communityMissionObjectiveLoading: action.state
      };

    case types.GET_COMMUNITY_MISSION_SUCCESS:
      return {
        ...state,
        selectedCommunityMission: action.communityMission,
        communityMissionSaved: false
      };

    case types.CREATE_COMMUNITY_MISSION:
      return {
        ...state,
        communityMissionCreating: true,
        communityMissionSaved: false
      };
    case types.CREATE_COMMUNITY_MISSION_SUCCESS:
      return {
        ...state,
        communityMissionCreating: false,
        communityMissionSaved: true,
        selectedCommunityMission: action.communityMission
      };
    case types.CREATE_COMMUNITY_MISSION_ERROR:
      return {
        ...state,
        communityMissionCreating: false
      };

    case types.UPDATE_COMMUNITY_MISSION_LOADING:
      return {
        ...state,
        communityMissionSaving: action.state
      };

    case types.UPDATE_COMMUNITY_MISSION:
    case types.PATCH_COMMUNITY_MISSION_STATUS:
      return {
        ...state,
        communityMissionSaved: false
      };

    case types.UPDATE_COMMUNITY_MISSION_SUCCESS:
      return {
        ...state,
        selectedCommunityMission: action.communityMission,
        communityMissionSaved: true
      };

    case types.ARCHIVE_COMMUNITY_MISSION_LOADING:
      return {
        ...state,
        communityMissionArchiving: action.state
      };

    // Patch Community Mission Status
    case types.PATCH_COMMUNITY_MISSION_STATUS_SUCCESS:
      return {
        ...state,
        selectedCommunityMission: action.communityMission,
        communityMissionSaved: true
      };

    // Objectives
    case types.GET_COMMUNITY_MISSION_OBJECTIVE_SUCCESS:
      return {
        ...state,
        selectedCommunityMissionObjective: action.communityMissionObjective
      };

    case types.GET_COMMUNITY_MISSION_OBJECTIVES_LOADING:
      return {
        ...state,
        communityMissionObjectivesLoading: action.state
      };

    case types.GET_COMMUNITY_MISSION_OBJECTIVES_SUCCESS:
      return {
        ...state,
        communityMissionObjectives: action.objectives,
        communityMissionObjectivesPagination: {
          total: action.total,
          page: action.page,
          totalPages: action.totalPages,
          limit: action.limit
        }
      };

    case types.CREATE_COMMUNITY_MISSION_OBJECTIVE:
      return {
        ...state,
        communityMissionObjectiveSaved: false
      };

    case types.CREATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS:
      return {
        ...state,
        communityMissionObjectiveSaved: true
      };

    case types.UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING:
      return {
        ...state,
        communityMissionObjectiveSaving: action.state
      };

    case types.UPDATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS:
    case types.PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS_SUCCESS:
      return {
        ...state,
        selectedCommunityMissionObjective: action.communityMissionObjective,
        communityMissionObjectiveSaved: true
      };

    // Practices
    case types.COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE:
      return {
        ...state,
        communityMissionObjectivePracticeLoading: action.state
      };

    case types.CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE:
    case types.UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE:
      return {
        ...state,
        communityMissionObjectivePracticeSaved: false
      };

    case types.CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS:
      state.selectedCommunityMissionObjective.practices.push(action.practice);

      return {
        ...state,
        communityMissionObjectivePracticeSaved: true
      };

    case types.UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS:
      return {
        ...state,
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          practices: state.selectedCommunityMissionObjective.practices.map(t => {
            if (t.id === action.practice.id) return action.practice;
            return t;
          })
        },
        communityMissionObjectivePracticeSaved: true
      };

    case types.DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE:
      return {
        ...state,
        communityMissionObjectivePracticeDeleted: false
      };

    case types.DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS:
      return {
        ...state,
        communityMissionObjectivePracticeDeleted: true,
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          practices: state.selectedCommunityMissionObjective.practices.filter(t => t.id !== action.practiceId)
        }
      };

    case types.PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          tasks: state.selectedCommunityMissionObjective.tasks.map(t => {
            if (t.id === action.task.id) return action.task;
            return t;
          })
        }
      };

    case types.DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK_SUCCESS:
      return {
        ...state,
        communityMissionObjectivePracticeDeleted: true,
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          tasks: state.selectedCommunityMissionObjective.tasks.filter(t => t.id !== action.taskId)
        }
      };

    // TASKS

    case types.CREATE_COMMUNITY_TASK:
      return {
        ...state,
        isCreatingTask: true
      };
    case types.CREATE_COMMUNITY_TASK_SUCCESS:
      return {
        ...state,
        isCreatingTask: false,
        isCreatingTaskSuccess: true,
        isCreatingTaskError: null,
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          tasks: [...state.selectedCommunityMissionObjective.tasks, action.task]
        }
      };
    case types.CREATE_COMMUNITY_TASK_ERROR:
      return {
        ...state,
        isCreatingTask: false,
        isCreatingTaskSuccess: false,
        isCreatingTaskError: action.payload.error
      };

    case types.UPDATE_COMMUNITY_TASK:
      return {
        ...state,
        updatingTasks: [...state.updatingTasks, action.task._id]
      };
    case types.UPDATE_COMMUNITY_TASK_SUCCESS:
      return {
        ...state,
        updatingTasks: state.updatingTasks.filter(id => id !== action.task._id),
        selectedCommunityMissionObjective: {
          ...state.selectedCommunityMissionObjective,
          tasks: [...state.selectedCommunityMissionObjective.tasks.filter(t => t._id !== action.task._id), action.task]
        }
      };
    case types.UPDATE_COMMUNITY_TASK_ERROR:
      return {
        ...state,
        updatingTasks: state.updatingTasks.filter(id => id !== action.task._id)
      };

    case types.COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE: {
      return {
        ...state,
        systemCommentsLoading: action.state
      };
    }

    case types.GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS:
      return {
        ...state,
        systemComments: action.systemComments
      };

    default:
      return state;
  }
};

export default communityMissionReducer;

export const selectCommunityMissions = state => state.communityMissions.communityMissions;
export const selectCommunityMissionsLoading = state => state.communityMissions.communityMissionsLoading;
export const selectCommunityMissionSaved = state => state.communityMissions.communityMissionSaved;
export const selectCommunityMissionSaving = state => state.communityMissions.communityMissionSaving;
export const selectCommunityMissionCreating = state => state.communityMissions.communityMissionCreating;
export const selectCommunityMissionsPagination = state => state.communityMissions.communityMissionsPagination;

export const selectCommunityMissionLoading = state => state.communityMissions.communityMissionLoading;

export const selectCommunityMission = state => {
  return state.communityMissions.selectedCommunityMission;
};

export const selectCommunityMissionObjective = state => {
  return state.communityMissions.selectedCommunityMissionObjective;
};

export const selectCommunityMissionObjectives = state => {
  return state.communityMissions.communityMissionObjectives;
};
export const selectCommunityMissionObjectivesPagination = state => {
  return state.communityMissions.communityMissionObjectivesPagination;
};
export const selectCommunityMissionObjectivesLoading = state => {
  return state.communityMissions.communityMissionObjectivesLoading;
};
export const selectCommunityMissionObjectiveLoading = state => {
  return state.communityMissions.communityMissionObjectiveLoading;
};
export const selectCommunityMissionObjectiveSaved = state => {
  return state.communityMissions.communityMissionObjectiveSaved;
};
export const selectCommunityMissionObjectiveSaving = state => {
  return state.communityMissions.communityMissionObjectiveSaving;
};
export const selectCommunityMissionObjectivePracticeLoading = state => {
  return state.communityMissions.communityMissionObjectivePracticeLoading;
};
export const selectCommunityMissionObjectivePracticeSaved = state => {
  return state.communityMissions.communityMissionObjectivePracticeSaved;
};
export const selectCommunityMissionObjectivePracticeDeleted = state => {
  return state.communityMissions.communityMissionObjectivePracticeDeleted;
};
export const selectCommunityMissionArchiving = state => state.communityMissions.communityMissionArchiving;

export const selectSystemCommentsLoading = state => state.communityMissions.systemCommentsLoading;
export const selectSystemComments = state => state.communityMissions.systemComments;
export const selectCommunityMissionObjectiveSystemComments = (state, objectiveId) =>
  state.communityMissions.systemComments.find(s => s.objectiveId === objectiveId)?.comments || [];
