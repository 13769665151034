import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import CommunityDetailsLoading from '../components/CommunityDetailsForm/CommunityDetailsLoading';
import CommunityDetailsForm from '../components/CommunityDetailsForm/CommunityDetailsForm';
import Button from '../components/Button/Button';

import * as communityTypes from '../state/community/types';
import {
  selectSelectedCommunity,
  selectCommunitySavingState,
  selectCommunityLoadingState
} from '../state/community/reducers';

export default function CommunityDetails({ readOnly }) {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    title: '',
    description: '',
    icon: ''
  });

  // Redux state
  const selectedCommunity = useSelector(state => selectSelectedCommunity(state));
  const saveState = useSelector(state => selectCommunitySavingState(state));
  const loadingState = useSelector(state => selectCommunityLoadingState(state));

  const {
    title: existingTitle,
    description: existingDescription,
    icon: existingIcon
  } = selectedCommunity;

  // Change the values if the community changes
  useEffect(() => {
    setValues({
      title: existingTitle,
      description: existingDescription || '',
      icon: existingIcon || ''
    });
  }, [selectedCommunity]);

  // Update local state
  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  }

  // Deconstruct for readability
  const { title, description, icon } = values;

  // Validate and submit
  function handleSubmit() {
    const { _id: communityId } = selectedCommunity;
    dispatch({
      type: communityTypes.UPDATE_COMMUNITY,
      communityId,
      updates: {
        title,
        description,
        icon
      }
    });
  }

  const handleIconChange = icon => {

    setValues({
      ...values,
      icon
    });
  };
  const canSubmit = title.trim().length > 0;

  return loadingState ? (
    <CommunityDetailsLoading />
  ) : (
    <>
      <CommunityDetailsForm
        readOnly={readOnly}
        onChange={handleChange}
        onIconChange={handleIconChange}
        communityName={title}
        icon={icon}
        communityDescription={description}
      />
      {!readOnly && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <Button
            label="Save"
            style={{ width: 150 }}
            onClick={handleSubmit}
            disabled={saveState || !canSubmit}
            loading={saveState}
          />
        </div>
      )}
    </>
  );
}

CommunityDetails.propTypes = {
  readOnly: PropTypes.bool.isRequired
};
