import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../Button/Button';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: '50px 34px',
    '@media (min-width: 768px)': {
      padding: '50px 68px'
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10
  },
  copy: {
    maxWidth: 345,
    margin: '0px auto 40px auto',
    fontSize: 14,
    fontWeight: 300
  },
  sendButton: {
    width: 150
  }
});

export default function AuthConfirmationCard({ title, copy, showBack }) {
  const classes = styles();
  const history = useHistory();

  function handleSubmit() {
    history.push('/');
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.copy}>{copy}</Typography>
        </Grid>
        {showBack && (
          <Grid item xs={12} className={classes.textCenter}>
            <Button
              label="Back to Log in"
              onClick={handleSubmit}
              className={classes.sendButton}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

AuthConfirmationCard.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  showBack: PropTypes.bool
};

AuthConfirmationCard.defaultProps = {
  title: '',
  copy: '',
  showBack: true
};
