import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import UserSnack from './UserSnack';

const useStyles = makeStyles({
  container: {
    padding: 5,
    borderRadius: 10,
    backgroundColor: '#dcdcdc',
    maxHeight: 517,
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f7f7f7 !important'
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      height: 174,
      borderRadius: 15,
      backgroundColor: '#a5a5a5'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
  },
  noUsers: {
    textAlign: 'center',
    margin: 32
  }
});

const UserList = ({ users, hasSearch, loading, onClick, selectedUsers }) => {
  // Hooks
  const classes = useStyles();

  // Event handlers
  const handleClick = user => {
    if (onClick) onClick(user);
  };

  // Constants
  const hasUsers = users.length > 0;

  const containerClass = clsx({
    [classes.container]: true,
    [classes.grid]: hasUsers || loading
  });

  return (
    <Box className={containerClass}>
      {hasUsers &&
        !loading &&
        users.map((user, idx) => {
          const selected = selectedUsers.some(u => u.id === user.id);

          return (
            <UserSnack
              key={`user-${idx}`}
              user={user}
              onClick={() => handleClick(user)}
              selected={selected}
            />
          );
        })}
      {!hasUsers && !loading && (
        <Typography className={classes.noUsers}>
          {hasSearch
            ? 'No results found'
            : 'You are not the leader of any members'}
        </Typography>
      )}
    </Box>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
  hasSearch: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.array
};

UserList.defaultProps = {
  users: [],
  onClick: null,
  selectedUsers: []
};

export default UserList;
