import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '../ProfileImageAvatar/ProfileImageAvatar';
import Button from '../Button/Button';
import SearchInput from '../SearchInput/SearchInput';
import { selectTeams, selectTeamLoading } from '../../state/teams/reducers';

import * as teamTypes from '../../state/teams/types';
import * as communityMissionTypes from '../../state/communityMission/types';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 40
  },
  header: {
    marginBottom: 80
  },
  title: {
    fontSize: 12,
    color: '#838b96',
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  searchInput: {
    // backgroundColor: '#cccccc',
    height: '44px',
    flexShrink: 0
  },
  noResults: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontSize: 12
  }
}));

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['title', 'description']
};

const MissionTeamsSelection = ({ mission }) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local state
  const [search, setSearch] = useState('');
  const [missionTeams, setMissionTeams] = useState(mission.teams || []);

  const communityId = mission.community?._id || mission.community;

  // Redux
  const teams = useSelector(selectTeams);
  const loading = useSelector(selectTeamLoading);

  // Helpers
  const searchTeams = searchValues => {
    const fuseInstance = new Fuse(searchValues, fuseOpts);
    return fuseInstance.search(search);
  };

  const hasSearch = search.trim().length > 0;
  const computedTeams = hasSearch ? searchTeams(teams) : teams;
  const noResults = hasSearch && !computedTeams.length;

  // Event handlers
  const handleSave = () => {
    dispatch({
      type: communityMissionTypes.UPDATE_COMMUNITY_MISSION,
      communityId,
      missionId: mission.id,
      teams: missionTeams
    });
  };
  const handleSearchChange = value => setSearch(value);
  const handleSelectTeam = e => {
    const { name, checked } = e.target;
    const clonedMissionTeams = [...missionTeams];
    const teamIndex = clonedMissionTeams.indexOf(name);

    if (checked) {
      clonedMissionTeams.push(name);
    } else {
      clonedMissionTeams.splice(teamIndex, 1);
    }

    setMissionTeams(clonedMissionTeams);
  };

  const areTeamsInsState =
    teams?.length > 0 && teams?.filter(team => team.community === communityId).length === teams.length;

  // Effects
  useEffect(() => {
    if (!areTeamsInsState) {
      dispatch({
        type: teamTypes.GET_ALL_TEAMS,
        communityId
      });
    }
  }, []);

  const renderListItem = team => {
    const checked = missionTeams.indexOf(team.id) >= 0;

    return (
      <Box
        key={team.id}
        display="flex"
        style={{
          backgroundColor: '#f2f2f2',
          padding: 5,
          paddingRight: 10,
          paddingLeft: 5,
          borderRadius: 5,
          alignItems: 'center'
        }}
      >
        <Checkbox checked={checked} name={team.id} onChange={handleSelectTeam} color="primary" />

        <Typography style={{ flex: 1 }}>{team.title}</Typography>

        <AvatarGroup max={3}>
          {team.users.map(i => (
            <Avatar key={i.id} userId={i.id} />
          ))}
        </AvatarGroup>
      </Box>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} className={classes.flexStart}>
          <Typography className={classes.title}>Add Team to Mission</Typography>
        </Grid>
        <Grid item xs={6} className={classes.flexEnd}>
          <Button className={classes.button} onClick={handleSave} label="Save" background="grey" />
        </Grid>
      </Grid>

      <Box marginTop={2}>
        <Typography style={{ fontSize: 12 }}>
          Each team member will have their own expression on this objective - they will be able to add their own tasks
          to the objective as well as be assigned tasks by the community leader.
        </Typography>
      </Box>

      <Box marginTop={2}>
        <SearchInput
          onChange={handleSearchChange}
          value={search}
          className={classes.searchInput}
          placeholder="Search team..."
        />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" m={8}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {noResults && <Typography className={classes.noResults}>No results found</Typography>}
          <List>{computedTeams.map(renderListItem)}</List>
        </>
      )}
    </>
  );
};

MissionTeamsSelection.propTypes = {
  mission: PropTypes.object.isRequired
};

export default MissionTeamsSelection;
