import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { ReactComponent as CalendarIcon } from '../../static/images/icons/icon-calendar.svg';

const styles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  currentDay: {
    '& .MuiPickersDay-current': {
      backgroundColor: '#f7f7f7',
      '& p': {
        fontWeight: 700
      }
    }
  },
  labelRoot: {
    color: '#838b96',
    zIndex: 1,
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 7,
    paddingLeft: 21,
    pointerEvents: 'none'
  },
  labelShrink: {
    fontFamily: 'SFUIText-Semibold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 15
  },
  labelFocused: {
    color: '#838b96 !important'
  },
  inputRoot: {
    backgroundColor: '#f4f4f4',
    color: '#223f63',
    borderRadius: 10,
    paddingLeft: 21,
    paddingRight: 21,
    fontSize: 12,
    minHeight: 40,
    width: '100%',
    border: 'solid 1px #d6d6d6'
  },
  inputFocused: {
    border: 'solid 1px #223f63'
  },
  helperRoot: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10,
    textAlign: 'end',
    paddingRight: 7
  },
  labelError: {
    color: `${theme.palette.error.main} !important`
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`
  },
  whiteInput: {
    backgroundColor: '#ffffff !important'
  }
}));

export default function Datepicker({
  name,
  label,
  value,
  onChange,
  disabled,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  keyboardIcon,
  errorMessage,
  className,
  whiteInput,
  required,
  chevron
}) {
  // Hooks
  const classes = styles();
  const picker = useRef();

  // Local error
  const [error, setError] = useState('');

  // Event handler
  const onError = newError => {
    setError(newError);
  };

  // Constants
  const computedError = Boolean(error) || Boolean(errorMessage);
  const computedHelper = errorMessage || error;

  const rootClass = clsx({
    [classes.root]: true,
    [className]: Boolean(className)
  });

  const inputRootClass = clsx({
    [classes.inputRoot]: true,
    [classes.whiteInput]: whiteInput
  });

  const computedLabel = (
    <>
      {label}
      {required && <span style={{ color: '#bc314f' }}>{' * '}</span>}
    </>
  );

  const [isOpen, setIsOpen] = useState(false);

  return (
    <KeyboardDatePicker
      refs={picker}
      allowKeyboardControl={false}
      label={computedLabel}
      name={name}
      value={value}
      onChange={(...args) => {
        onChange(...args);
        setIsOpen(false);
      }}
      disabled={disabled}
      keyboardIcon={chevron ? <KeyboardArrowDownRoundedIcon /> : keyboardIcon || <CalendarIcon />}
      format="DD/MM/YYYY"
      maxDate={maxDate}
      invalidDateMessage="Please enter a valid date"
      maxDateMessage={maxDateMessage || `Date cannot be past ${moment(maxDate).format('DD/MM/YYYY')}`}
      minDate={minDate}
      minDateMessage={minDateMessage || `Date cannot be before ${moment(minDate).format('DD/MM/YYYY')}`}
      classes={{
        root: rootClass
      }}
      DialogProps={{
        disableRestoreFocus: true,
        onClose: () => {
          setIsOpen(false);
        },
        className: classes.currentDay
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          shrink: classes.labelShrink,
          focused: classes.labelFocused,
          error: classes.labelError
        }
      }}
      InputProps={{
        onFocus: () => setIsOpen(true),
        disableUnderline: true,
        classes: {
          root: inputRootClass,
          error: classes.inputError,
          focused: classes.inputFocused
        }
      }}
      KeyboardButtonProps={{
        onFocus: () => setIsOpen(true),
        size: 'small'
      }}
      popoverprops={{
        disableRestoreFocus: true,
        onClose: () => {
          setIsOpen(false);
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperRoot
        }
      }}
      error={computedError}
      helperText={computedHelper}
      onError={onError}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
}

Datepicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.instanceOf(moment),
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.instanceOf(moment),
  minDateMessage: PropTypes.string,
  keyboardIcon: PropTypes.node,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  whiteInput: PropTypes.bool,
  required: PropTypes.bool,
  chevron: PropTypes.bool
};

Datepicker.defaultProps = {
  value: null,
  name: '',
  label: '',
  disabled: false,
  maxDate: moment().add(5, 'years'),
  maxDateMessage: '',
  minDate: moment().subtract(150, 'years'),
  minDateMessage: '',
  keyboardIcon: null,
  errorMessage: '',
  className: '',
  whiteInput: false,
  required: false,
  chevron: false
};
