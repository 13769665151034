import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthConfirmationCard from '../AuthConfirmationCard/AuthConfirmationCard';

import { selectAuthApiErrors } from '../../../state/auth/reducers';
import * as authTypes from '../../../state/auth/types';

import { parseApiErrors } from '../../../constants/validation';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: 20
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10
  }
});

export default function UpdatingEmailCard() {
  const classes = styles();

  // Redux
  const apiErrors = useSelector(state => selectAuthApiErrors(state));
  const hasErrors = parseApiErrors(apiErrors, authTypes.ACCEPT_EMAIL_CHANGE_ERROR);

  return !hasErrors ? (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Updating email</Typography>
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <CircularProgress />
      </Box>
    </Paper>
  ) : (
    <AuthConfirmationCard
      title="Uh oh!"
      copy="There was an error. Please request another email change."
    />
  );
}
