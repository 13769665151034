import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import statusIcons from '../../constants/statusIcons';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 75,
    marginBottom: 10
  },
  date: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    marginRight: 15
  },
  icon: {
    width: 20,
    marginRight: 15
  },
  copy: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    color: '#223f63',
    display: 'flex'
  }
});

const getIcon = comment => {
  switch (comment) {
    case 'objective created':
      return statusIcons.inactive;

    case 'objective marked as inactive':
      return statusIcons.inactive;

    case 'objective marked as in progress':
      return statusIcons.inprogress;

    case 'objective marked as need help':
      return statusIcons.needhelp;

    case 'objective marked as no longer relevant':
      return statusIcons.nolongerrelevant;

    case 'objective marked as complete':
      return statusIcons.complete;

    case 'task marked as complete':
      return statusIcons.taskComplete;

    case 'lifeEvent':
      return <FontAwesomeIcon icon="flag" color="#ff0052" style={{ height: 15 }} />

    default:
      return '';
  }
};

export default function MissionTimelineCard({ date, text, formattedComment }) {
  // Hooks
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.date}>{date}</Typography>
      <Box display="flex" alignItems="center" className={classes.icon}>
        {getIcon(formattedComment)}
      </Box>
      <Box display="flex" alignItems="center" width="100%">
        <Typography className={classes.copy}>{text}</Typography>
      </Box>
    </Box>
  );
}

MissionTimelineCard.propTypes = {
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  formattedComment: PropTypes.string.isRequired
};
