import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#072144 !important',
    textDecoration: 'none'
  },
  listItemIcon: {
    minWidth: 25,
    justifyContent: 'center',
    color: '#072144',
    '& path': {
      fill: '#072144 !important'
    }
  },
  listItemText: {
    color: 'inherit',
    fontSize: '11px !important',
    flexGrow: 1,
    paddingLeft: 14
  },
  listItemActive: {
    color: '#d9a035 !important',
    '& a, svg': {
      color: '#d9a035 !important',
      '& path': {
        fill: '#d9a035 !important'
      }
    }
  }
});

const MenuItem = ({ isActive, to, title, icon: Icon, permissions }) => {
  // Hooks
  const classes = useStyles();

  const itemClass = clsx({
    [classes.listItem]: true,
    [classes.listItemActive]: isActive,
    [classes.comAdmin]: permissions.every(permission => permission === 'admin')
  });

  return (
    <Link to={to} className={classes.link}>
      <ListItem button className={itemClass}>
        <ListItemIcon
          classes={{
            root: classes.listItemIcon
          }}
        >
          <Icon style={{ fontSize: 14 }} />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.listItemText
          }}
          primary={title}
        />
      </ListItem>
    </Link>
  );
};

MenuItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};

MenuItem.defaultProps = {
  permissions: []
};

export default MenuItem;
