
import Achiever from './Achiever/Achiever'
import Activator from './Activator/Activator'
import Archaic from './Archaic/Archaic'
import Diplomat from './Diplomat/Diplomat'
import Expert from './Expert/Expert'
import Redefiner from './Redefiner/Redefiner'
import Sensor from './Sensor/Sensor'
import Transformer from './Transformer/Transformer'

export default {
  Achiever,
  Activator,
  Archaic,
  Diplomat,
  Expert,
  Redefiner,
  Sensor,
  Transformer,
}