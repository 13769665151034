import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as MoreIcon } from '../../static/images/icons/icon-more.svg';

const styles = makeStyles({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  paperRoot: {
    borderRadius: 15
  },
  list: {
    padding: 0
  },
  menuItemRoot: {
    fontSize: 12,
    padding: '12px 16px',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(34, 63, 99, 0.1)'
    }
  }
});

export default function PopUpMenu({ menuActions, ...rest }) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <>
      <div className={classes.flex}>
        <IconButton onClick={handleClick} {...rest}>
          <MoreIcon />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.paperRoot, list: classes.list }}
      >
        {menuActions.map(({ onClick, label }) => (
          <MenuItem
            key={label}
            onClick={e => {
              e.stopPropagation();
              handleClose(e);
              onClick(e);
            }}
            classes={{ root: classes.menuItemRoot }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

PopUpMenu.propTypes = {
  menuActions: PropTypes.array.isRequired
};
