export const COMMUNITY_MISSION_OBJECTIVE_LOADING = 'communityMission/COMMUNITY_MISSION_OBJECTIVE_LOADING';

export const GET_COMMUNITY_MISSIONS = 'communityMission/GET_COMMUNITY_MISSIONS';
export const GET_COMMUNITY_MISSIONS_SUCCESS = 'communityMission/GET_COMMUNITY_MISSIONS_SUCCESS';
export const GET_COMMUNITY_MISSIONS_LOADING = 'communityMission/GET_COMMUNITY_MISSIONS_LOADING';

export const GET_COMMUNITY_MISSION = 'communityMission/GET_COMMUNITY_MISSION';
export const GET_COMMUNITY_MISSION_SUCCESS = 'communityMission/GET_COMMUNITY_MISSION_SUCCESS';
export const GET_COMMUNITY_MISSION_LOADING = 'communityMission/GET_COMMUNITY_MISSION_LOADING';

export const CREATE_COMMUNITY_MISSION = 'communityMission/CREATE_COMMUNITY_MISSION';
export const CREATE_COMMUNITY_MISSION_SUCCESS = 'communityMission/CREATE_COMMUNITY_MISSION_SUCCESS';
export const CREATE_COMMUNITY_MISSION_ERROR = 'communityMission/CREATE_COMMUNITY_MISSION_ERROR';

export const UPDATE_COMMUNITY_MISSION = 'communityMission/UPDATE_COMMUNITY_MISSION';
export const UPDATE_COMMUNITY_MISSION_SUCCESS = 'communityMission/UPDATE_COMMUNITY_MISSION_SUCCESS';
export const UPDATE_COMMUNITY_MISSION_LOADING = 'communityMission/UPDATE_COMMUNITY_MISSION_LOADING';

export const PATCH_COMMUNITY_MISSION_STATUS = 'communityMission/PATCH_COMMUNITY_MISSION_STATUS';
export const PATCH_COMMUNITY_MISSION_STATUS_SUCCESS = 'communityMission/PATCH_COMMUNITY_MISSION_STATUS_SUCCESS';

export const ARCHIVE_COMMUNITY_MISSION = 'communityMission/ARCHIVE_COMMUNITY_MISSION';
export const ARCHIVE_COMMUNITY_MISSION_SUCCESS = 'communityMission/ARCHIVE_COMMUNITY_MISSION_SUCCESS';
export const ARCHIVE_COMMUNITY_MISSION_LOADING = 'communityMission/ARCHIVE_COMMUNITY_MISSION_LOADING';

export const GET_COMMUNITY_MISSION_OBJECTIVES = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVES';
export const GET_COMMUNITY_MISSION_OBJECTIVES_SUCCESS = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVES_SUCCESS';
export const GET_COMMUNITY_MISSION_OBJECTIVES_LOADING = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVES_LOADING';

export const CREATE_COMMUNITY_MISSION_OBJECTIVE = 'communityMission/CREATE_COMMUNITY_MISSION_OBJECTIVE';
export const CREATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS = 'communityMission/CREATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS';
export const CREATE_COMMUNITY_MISSION_OBJECTIVE_LOADING = 'communityMission/CREATE_COMMUNITY_MISSION_OBJECTIVE_LOADING';

export const GET_COMMUNITY_MISSION_OBJECTIVE = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVE';
export const GET_COMMUNITY_MISSION_OBJECTIVE_SUCCESS = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVE_SUCCESS';
export const GET_COMMUNITY_MISSION_OBJECTIVE_LOADING = 'communityMission/GET_COMMUNITY_MISSION_OBJECTIVE_LOADING';

export const UPDATE_COMMUNITY_MISSION_OBJECTIVE = 'communityMission/UPDATE_COMMUNITY_MISSION_OBJECTIVE';
export const UPDATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS = 'communityMission/UPDATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS';
export const UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING = 'communityMission/UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING';

export const PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS = 'communityMission/PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS';
export const PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS_SUCCESS =
  'communityMission/PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS_SUCCESS';

export const ARCHIVE_COMMUNITY_MISSION_OBJECTIVE = 'communityMission/ARCHIVE_COMMUNITY_MISSION_OBJECTIVE';
export const ARCHIVE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS =
  'communityMission/ARCHIVE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS';

export const COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE =
  'communityMission/COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE';
export const CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE =
  'communityMission/CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE';
export const CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS =
  'communityMission/CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS';

export const UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE =
  'communityMission/UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE';
export const UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS =
  'communityMission/UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS';

export const DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE =
  'communityMission/DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE';
export const DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS =
  'communityMission/DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS';

export const COMMUNITY_MISSION_OBJECTIVE_TASK_LOADING_STATE =
  'communityMission/COMMUNITY_MISSION_OBJECTIVE_TASK_LOADING_STATE';

export const PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS =
  'communityMission/PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS';
export const PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS_SUCCESS =
  'communityMission/PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS_SUCCESS';

export const DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK = 'communityMission/DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK';
export const DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK_SUCCESS =
  'communityMission/DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK_SUCCESS';
export const CREATE_COMMUNITY_TASK = 'communityMission/CREATE_COMMUNITY_TASK';
export const CREATE_COMMUNITY_TASK_SUCCESS = 'communityMission/CREATE_COMMUNITY_TASK_SUCCESS';
export const CREATE_COMMUNITY_TASK_ERROR = 'communityMission/CREATE_COMMUNITY_TASK_ERROR';

export const UPDATE_COMMUNITY_TASK = 'communityMission/UPDATE_COMMUNITY_TASK';
export const UPDATE_COMMUNITY_TASK_SUCCESS = 'communityMission/UPDATE_COMMUNITY_TASK_SUCCESS';
export const UPDATE_COMMUNITY_TASK_ERROR = 'communityMission/UPDATE_COMMUNITY_TASK_ERROR';

export const COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE =
  'communityMission/COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE';
export const GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS =
  'communityMission/GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS';
export const GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS =
  'communityMission/GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS';
