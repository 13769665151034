import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '../Table/Table';
import Button from '../Button/Button';
import TaskCard from './TaskCard';

import * as communityMissionTypes from '../../state/communityMission/types';
import * as missionTypes from '../../state/mission/types';
import NoEntityBox from '../Common/NoEntityBox';

const useStyles = makeStyles({
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 5
  },
  noTasksWrap: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    padding: 8,
    height: 430
  },
  noTasksInner: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  noTasksText: {
    fontSize: 14,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    maxWidth: 320,
    marginBottom: 20,
    textAlign: 'center'
  },
  button: {
    minWidth: 108,
    width: 108
  }
});

const ObjectiveTasks = ({ readOnly, tasks, onAddTask, isCreating, onEditTask, isCommunity, communityId }) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userId, missionId, objectiveId } = useParams();

  // Create the menu actions for each card
  const generateMenuActions = row => [
    {
      onClick: () => {
        if (isCommunity) {
          dispatch({
            type: communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS,
            taskId: row.id,
            communityId,
            completed: !row.isComplete
          });
        } else {
          dispatch({
            type: missionTypes.UPDATE_TASK_STATUS,
            userId,
            missionId,
            objectiveId,
            taskId: row.id,
            isComplete: !row.isComplete
          });
        }
      },
      onClick: () => onEditTask(row),
      label: 'Edit'
    },
    {
      onClick: () =>
        dispatch({
          type: missionTypes.UPDATE_TASK_STATUS,
          userId,
          missionId,
          objectiveId,
          taskId: row.id,
          isComplete: !row.isComplete
        }),
      label: row.isComplete ? 'Mark as incomplete' : 'Mark as done'
    },
    {
      onClick: () => {
        if (isCommunity) {
          dispatch({
            type: communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK,
            taskId: row.id,
            communityId
          });
        } else {
          dispatch({
            type: missionTypes.DELETE_TASK,
            userId,
            missionId,
            objectiveId,
            taskId: row.id
          });
        }
      },
      label: 'Delete task'
    }
  ];

  // Constants
  const hasTasks = tasks.length > 0;

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={2} alignItems="center">
        <Typography className={classes.title}>Actions</Typography>
        {!readOnly && <Button label="Add" className={classes.button} onClick={onAddTask} />}
      </Box>

      {!readOnly && !hasTasks ? (
        <NoEntityBox text="Create actions for this member to keep track of their objectiv" />
      ) : (
        <Table
          data={tasks.sort((a, b) => {
            if (b.isComplete > a.isComplete) {
              return -1;
            }
            if (a.dueDate !== b.dueDate) {
              return new Date(b.dueDate).getTime() < new Date(a.dueDate).getTime() ? 1 : -1;
            }
            // Title (task) is only important when due dates are the same
            return b.task < a.task ? 1 : -1;
          })}
          elevation={0}
          boxShadow="0 4px 8px -1px rgba(0, 0, 0, 0.2)"
          style={{ flexGrow: 1 }}
          rowCard={TaskCard}
          menuActions={readOnly ? null : generateMenuActions}
          showEmptyRows={false}
          footer={
            isCreating ? (
              <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
                <CircularProgress size={20} />
              </Box>
            ) : null
          }
        />
      )}
    </>
  );
};

ObjectiveTasks.propTypes = {
  communityId: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  tasks: PropTypes.array,
  onAddTask: PropTypes.func.isRequired,
  isCommunity: PropTypes.bool,
  isCreating: PropTypes.bool,
  onEditTask: PropTypes.func
};

ObjectiveTasks.defaultProps = {
  communityId: null,
  tasks: [],
  isCommunity: false,
  isCreating: false,
  onEditTask: () => {}
};

export default ObjectiveTasks;
