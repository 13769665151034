import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import ObjectivePracticeForm from '../ObjectivePractice/ObjectivePracticeForm';
import Button from '../Button/Button';
import PracticeCard from './PracticeCard';

import { ensurePositiveNumber, randomId } from '../../utils/helpers';

const useStyles = makeStyles({
  wrap: {
    padding: '0px 0px'
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10
  },
  addButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    width: '100%',
    marginBottom: 10
  },
  copy: {
    fontSize: 11,
    fontWeight: 500
  },
  backdropRoot: {
    zIndex: 1
  },
  contentWrap: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    maxWidth: 640
  },
  inputWrap: {
    display: 'flex',
    marginBottom: 26
  },
  taskName: {
    marginBottom: 16
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 20px'
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  },
  cancel: {
    marginRight: 8
  }
});

const PracticeInput = ({ onUpdate, onEdit, isActive, practices }) => {
  // Hooks
  const classes = useStyles();
  const containerRef = useRef();

  // isActive denotes if the current objective is for a template or a one off mission
  const initialInactiveState = {
    title: '',
    description: '',
    reminderFrequency: 'NONE'
  };

  const initialActiveState = {
    task: '',
    description: ''
  };

  const defaultNewPractice = {
    title: '',
    description: '',
    richDescription: '[]',
    countPerWeek: 7,
    reminderFrequency: 'WEEKLY'
  };

  const [newPractice, setNewPractice] = useState(defaultNewPractice);

  // Local state
  const [open, setOpen] = useState(false);
  const [practiceDetails, setPracticeDetails] = useState(isActive ? initialActiveState : initialInactiveState);

  // Event handlers
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
    setPracticeDetails(isActive ? initialActiveState : initialInactiveState);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    const isNumeric = name === 'daysDue';

    setPracticeDetails({
      ...practiceDetails,
      [name]: isNumeric ? ensurePositiveNumber(value) : value
    });
  };

  const handleDueDateChange = date => {
    setPracticeDetails({
      ...taskDetails,
      dueDate: date
    });
  };

  const handleCreate = e => {
    // Ensure a default of zero days
    const task = isActive ? practiceDetails : { ...practiceDetails, daysDue: practiceDetails.daysDue || 0 };

    onCreate({ id: randomId(), ...task });
    handleClose(e);
  };

  useEffect(() => {
    setNewPractice(defaultNewPractice);
  }, [open]);

  // Helpers
  const getPopupPostion = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getClientRects()[0];
      return {
        position: 'absolute',
        bottom: 20,
        left: rect.left,
        width: rect.width
      };
    }
    return null;
  };

  const handlePracticeFormChange = e => {
    const { name, value } = e.target;
    setNewPractice({ ...newPractice, [name]: value });
  };

  const handleCreateKo = () => {
    // assign temporary id to avoid array's event conflict
    if (!newPractice.id) newPractice.id = Math.random()
    const allPractices = [...practices, newPractice];
    onUpdate(allPractices);
    setOpen(false);
  };

  const handleNewPractice = editedPractice => {
    // let allPractices = [...practices, newPractice];

    // allPractices = [...allPractices].reverse();
    // let newPractices = allPractices.filter((mission, index, array) => array.findIndex(t => t.id == mission.id) == index);
    onEdit(editedPractice);
    setOpen(false);
  };

  const handleDeletePractice = index => {
    const allPractices = [...practices];
    allPractices.splice(index, 1);
    onUpdate(allPractices);
  };

  // Constants
  const { title, description } = newPractice;
  const complete = Boolean(title);

  return (
    <div>
      <Box className={classes.wrap}>
        <Typography className={classes.title}>Practices</Typography>
        <div
          ref={containerRef}
          className={classes.addButton}
          onClick={handleClick}
          role="presentation"
          data-test-id="button-objective-add-task"
        >
          <Typography className={classes.copy}>
            <AddIcon />
            Add an practice
          </Typography>
        </div>

        {practices.map((p, i) => (
          <PracticeCard key={p.id} index={i} practice={p} onCreate={handleNewPractice} onDelete={index => handleDeletePractice(index)} />
        ))}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { ...getPopupPostion(), margin: 0 },
          className: classes.contentWrap
        }}
      >
        <Box className={classes.wrap}>
          <ObjectivePracticeForm
            title={newPractice.title}
            description={newPractice.description}
            richDescription={newPractice.richDescription}
            countPerWeek={newPractice.countPerWeek}
            reminderFrequency={newPractice.reminderFrequency}
            onChange={handlePracticeFormChange}
          />
        </Box>
        <Box className={classes.buttonWrap}>
          <Button
            data-test-id="button-objective-task-cancel"
            background="grey"
            label="Cancel"
            onClick={handleClose}
            className={clsx(classes.btn, classes.cancel)}
          />
          <Button
            label="Create"
            onClick={handleCreateKo}
            disabled={!complete}
            className={classes.btn}
            data-test-id="button-objective-task-create"
          />
        </Box>
      </Dialog>
    </div>
  );
};

PracticeInput.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isActive: PropTypes.bool,
  practices: PropTypes.array
};

PracticeInput.defaultProps = {
  isActive: false,
  onEdit: () => {},
  practices: []
};

export default PracticeInput;
