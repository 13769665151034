import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as reportTypes from './types';
import * as reportActions from './actions';
import * as modalTypes from '../modal/types';

function* getObjectivePracticeReports({ objectiveId, startDate, endDate }) {
  try {
    yield put({
      type: reportTypes.OBJECTIVE_PRACTICE_REPORTS_LOADING_STATE,
      state: true
    });

    const { data } = yield call(reportActions.getObjectivePracticeReports, objectiveId, startDate, endDate);

    yield put({
      type: reportTypes.GET_OBJECTIVE_PRACTICE_REPORTS_SUCCESS,
      results: data
    });
  } catch (e) {
  } finally {
    yield put({
      type: reportTypes.OBJECTIVE_PRACTICE_REPORTS_LOADING_STATE,
      state: false
    });
  }
}

function* getTeamsAccountabilityScore({ communityId, startDate, endDate }) {
  try {
    yield put({
      type: reportTypes.TEAMS_ACCOUNTABILITY_SCORE_LOADING_STATE,
      state: true
    });

    const {
      data: { results }
    } = yield call(reportActions.getTeamsAccountabilityScore, communityId, startDate, endDate);

    yield put({
      type: reportTypes.GET_TEAMS_ACCOUNTABILITY_SCORE_SUCCESS,
      teamAccountabilityScores: results
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      message: error.response.data.message
    });
  } finally {
    yield put({
      type: reportTypes.TEAMS_ACCOUNTABILITY_SCORE_LOADING_STATE,
      state: false
    });
  }
}

export default [
  takeLatest(reportTypes.GET_OBJECTIVE_PRACTICE_REPORTS, getObjectivePracticeReports),
  takeLatest(reportTypes.GET_TEAMS_ACCOUNTABILITY_SCORE, getTeamsAccountabilityScore)
];
