import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import TextInput from '../TextInput/TextInput';

import { getErrorMessage } from '../../constants/validation';
import Datepicker from '../Datepicker/Datepicker';

const useStyles = makeStyles({
  wrap: {
    padding: '20px 40px',
    backgroundColor: '#f4f4f4'
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10
  },
  copy: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 300,
    minHeight: 75
  },
  templateCopy: {
    maxWidth: 461
  },
  missionCopy: {
    width: 362
  },
  formControl: {
    display: 'inline-block',
    maxWidth: 115,
    minHeight: 76
  },
  datepicker: {
    minHeight: 76
  }
});

const Scheduling = ({
  onChange,
  startDays,
  errors,
  isTemplate,
  activateDate,
  existingStartDate,
  onDateChange
}) => {
  // Hooks
  const classes = useStyles();

  // Constants
  const helperCopy = isTemplate
    ? 'Once the Mission is assigned, how many days until the Objective becomes visible to the Member?'
    : 'When will this objective activate for the member?';

  // If the objective is already visible - it cannot be scheduled
  const isActive = existingStartDate
    ? moment(existingStartDate).isBefore(moment())
    : false;

  const computedCopy = isActive
    ? 'This objective is currently in progress, so scheduling is disabled.'
    : helperCopy;

  const copyClass = clsx({
    [classes.copy]: true,
    [classes.templateCopy]: isTemplate,
    [classes.missionCopy]: !isTemplate
  });

  return (
    <Box className={classes.wrap}>
      <Typography className={classes.title}>Scheduling</Typography>
      <Box display="flex" alignItems="flex-end">
        <Typography className={copyClass}>{computedCopy}</Typography>
        {!isActive && (
          <Box flex={1} display="flex" justifyContent="center">
            {isTemplate ? (
              <TextInput
                className={classes.formControl}
                whiteInput
                name="startDays"
                label="Days"
                value={startDays}
                onChange={onChange}
                errorMessage={getErrorMessage(errors, 'startDays')}
                showError={false}
              />
            ) : (
              <Datepicker
                className={classes.datepicker}
                label="Date"
                required
                value={activateDate}
                onChange={onDateChange}
                keyboardIcon={<KeyboardArrowDownRoundedIcon />}
                whiteInput
                errorMessage={getErrorMessage(errors, 'activateDate')}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

Scheduling.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDays: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  isTemplate: PropTypes.bool,
  activateDate: PropTypes.instanceOf(moment),
  onDateChange: PropTypes.func,
  existingStartDate: PropTypes.string
};

Scheduling.defaultProps = {
  errors: null,
  isTemplate: true,
  startDays: 10,
  activateDate: moment().add('10', 'days'),
  onDateChange: null,
  existingStartDate: ''
};

export default Scheduling;
