import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getMissionProfileImage } from '@/state/mission/actions';
import { selectSelectedMission, selectSelectedProfileImagesMission } from '@/state/mission/reducers';
import { selectSelectedProfileImagesCommunityTemplates, selectSelectedTemplate } from '@/state/templates/reducers';
import { getMissionTemplatesProfileImage } from '@/state/templates/actions';
import { ReactComponent as UploadIcon } from '../../static/images/icons/icon-upload.svg';

import IconPicker from '../IconPicker/IconPicker';
import { getErrorMessage } from '../../constants/validation';

import * as missionTypes from '../../state/mission/types';
import * as missionTemplateTypes from '../../state/templates/types';


const styles = makeStyles({
  button: {
    position: 'relative',
    height: 120,
    width: 120,
    borderRadius: 25,
    boxShadow: '0px 15px 30px -7.5px rgba(0,0,0,0.4)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundColor: '#ffffff'
  },
  pointer: {
    cursor: 'pointer'
  },
  iconContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 30,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 18
  }
});

/**
 * Profile Image for Either Mission or Template Mission.
 * It's combined because MissionDetails (component that is able to modify missions icon) is called from
 * both mission and missionTemplates entity
 * 
 * @param {*} param0 
 * @returns 
 */
export default function ProfileImageMissionUpload({
  icon, onImageLoad, readOnly, missionId, missionTemplateId, errors
}) {
  const classes = styles();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [src, setSrc] = useState('');
  // const [src, setSrc] = useState('/favicon-bnw.png');
  const [loading, setLoading] = useState(true);

  // Redux
  const profileImages = useSelector(state => 
    missionId ?
      selectSelectedProfileImagesMission(state):
      selectSelectedProfileImagesCommunityTemplates(state)
  );
  // const selectedMission = useSelector(state =>
  //   missionId ?
  //     selectSelectedMission(state):
  //     selectSelectedTemplate(state)
  // );

  const computedMissionId = missionId || missionTemplateId;
  const missionImage = profileImages.find(i => 
    missionId ?
      i?.missionId === computedMissionId:
      i?.templateId === computedMissionId
  );

  // Event handlers
  function storeInRedux(image) {
    if(missionId) {
      dispatch({
        type: missionTypes.STORE_PROFILE_IMAGE,
        missionId,
        image
      });
    } else if(missionTemplateId) {
      dispatch({
        type: missionTemplateTypes.STORE_PROFILE_IMAGE,
        templateId: missionTemplateId,
        image
      });
    }
  }

  function setInitialized(image) {
    setSrc(image);
    setLoading(false);
  }

  // Get the profile given users profile image
  useEffect(() => {
    let isSubscribed = true;
    const functionGetter = missionId ? getMissionProfileImage: getMissionTemplatesProfileImage
    if (computedMissionId) {
      functionGetter(computedMissionId)
        .then(val => {
          if (isSubscribed) {
            if (val) {
              const reader = new FileReader();

              reader.onload = event => {
                const { result } = event.target;
                setInitialized(result);
                storeInRedux(result);
              };

              reader.readAsDataURL(val);
            } else setLoading(false);
          }
        })
        .catch(() => {
          if (isSubscribed) setLoading(false);
        });
    } else setInitialized(missionImage?.image);

    return () => {
      isSubscribed = false;
    };
  }, [computedMissionId, missionImage]);

  // Event handlers
  function handleFileChange(e) {
    const { files } = e.target;
    if (files && files[0]) {
      // Send the file to parent
      onImageLoad(files[0]);

      // Read the file and display base64 string
      const reader = new FileReader();

      reader.onload = event => {
        const { result } = event.target;
        setSrc(result);
      };

      reader.readAsDataURL(files[0]);
    }
  }

  function handleFileOpen() {
    if (!readOnly) inputRef.current.click();
  }

  // Classes
  const buttonClass = clsx({
    [classes.button]: true,
    [classes.pointer]: !readOnly
  });

  return(
    <>
      <input
        id="profile-image"
        type="file"
        accept="image/*"
        capture="camera"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={inputRef}
      />
      {loading && (
        <CircularProgress size={115} />
      )}
      {
        (!loading && !src) &&
          <div role="presentation" onClick={handleFileOpen}>
            <IconPicker
              handleOpen={() => {}}
              icon={icon}
              onSubmit={() => {}}
              error={getErrorMessage(errors, 'icon')}
            />
            {!readOnly && (
              <div className={classes.iconContainer}>
                <UploadIcon />
              </div>
            )}
          </div>
      }
      {
        (!loading && src) &&
        <div
          role="presentation"
          onClick={handleFileOpen}
          className={buttonClass}
          style={{ backgroundImage: `url(${src})` }}
        >
          {!readOnly && (
            <div className={classes.iconContainer}>
              <UploadIcon />
            </div>
          )}
        </div>
      }
    </>);
}

ProfileImageMissionUpload.propTypes = {
  onImageLoad: PropTypes.func,
  readOnly: PropTypes.bool,
  missionId: PropTypes.string,
  missionTemplateId: PropTypes.string,
  icon: PropTypes.string,
  errors: PropTypes.object,
};

ProfileImageMissionUpload.defaultProps = {
  readOnly: false,
  onImageLoad: null,
  missionId: '',
  missionTemplateId: '',
  icon: '',
  errors: null,
};
