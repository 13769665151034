import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Fuse from 'fuse.js';

import StepActions from '../components/TemplateStepper/StepActions';
import TemplatesAndUsers from '../components/TemplateStepper/TemplatesAndUsers';
import SelectTemplates from '../components/TemplateStepper/SelectTemplates';
import SelectUsers from '../components/TemplateStepper/SelectUsers';
import AssigningTemplatesLoader from '../components/TemplateStepper/AssigningTemplatesLoader';

import * as modalTypes from '../state/modal/types';
import * as communityTypes from '../state/community/types';
import * as templateTypes from '../state/templates/types';

import {
  selectCommunityTemplates,
  selectCommunityTemplateLoading,
  selectLeaderTemplateLoading,
  selectLeaderTemplates,
  selectAssignSaving
} from '../state/templates/reducers';
import {
  selectLeaderUsers,
  selectLeaderUsersLoadingState
} from '../state/community/reducers';
import { selectLoggedInUserId } from '../state/auth/reducers';
import { selectAllLeaderUsers } from '../state/chat/reducers';

import { isLeaderInCommunity } from '../utils/helpers';

const getFuseOpts = isUser => ({
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: isUser ? ['firstName', 'lastName', 'teams.title'] : ['name', 'description']
});

const MissionTemplateStepper = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { communityId } = useParams();

  // Local state
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [communitySearch, setcommunitySearch] = useState('');
  const [leaderSearch, setLeaderSearch] = useState('');
  const [memberSearch, setMemberSearch] = useState('');
  const [assigning, setAssigning] = useState(false);

  const [step, setStep] = useState(1);

  // Redux
  const authUser = useSelector(state => selectLoggedInUserId(state));
  const assignSaving = useSelector(state => selectAssignSaving(state));
  const allLeaderUsers = useSelector(state => selectAllLeaderUsers(state));
  const leaderUsers = useSelector(state => selectLeaderUsers(state));
  const usersLoading = useSelector(state => selectLeaderUsersLoadingState(state));
  const communityTemplates = useSelector(state => selectCommunityTemplates(state));
  const leaderTemplates = useSelector(state => selectLeaderTemplates(state));
  const leaderTemplatesLoading = useSelector(state =>
    selectLeaderTemplateLoading(state)
  );
  const communityTemplatesLoading = useSelector(state =>
    selectCommunityTemplateLoading(state)
  );

  // Redirect if not a leader in community
  useEffect(() => {
    if (allLeaderUsers.length > 0 && !isLeaderInCommunity(authUser, allLeaderUsers, communityId)) {
      history.push('/');
    }
  }, [history, allLeaderUsers]);

  // Fetch community/leader templates and leader users on load
  useEffect(() => {
    dispatch({
      type: templateTypes.GET_COMMUNITY_MISSION_TEMPLATES,
      communityId
    });
    dispatch({
      type: templateTypes.GET_LEADER_MISSION_TEMPLATES
    });
    dispatch({
      type: communityTypes.GET_LEADER_USERS,
      communityId,
      userId: authUser
    });
  }, [dispatch, communityId, authUser]);

  // If on second step and all templates are removed - send them back
  useEffect(() => {
    if (step === 2 && !selectedTemplates.length) setStep(1);
  }, [selectedTemplates, step, setStep]);

  // Set a minimum animation duration
  useEffect(() => {
    let isSubscribed = true;

    if (assigning) {
      setTimeout(() => {
        if (isSubscribed) {
          setAssigning(false);
        }
      }, 500);
    }

    return () => {
      isSubscribed = false;
    };
  }, [assigning]);

  // Helpers
  const searchData = (data, search, isUsers = false) => {
    const fuseInstance = new Fuse(data, getFuseOpts(isUsers));
    return fuseInstance.search(search);
  };

  // Computed arrays
  const computedUsers =
    memberSearch.trim().length > 0
      ? searchData(leaderUsers, memberSearch, true)
      : leaderUsers;
  const computedCommunityTemplates =
    communitySearch.trim().length > 0
      ? searchData(communityTemplates, communitySearch)
      : communityTemplates;
  const computedLeaderTemplates =
    leaderSearch.trim().length > 0
      ? searchData(leaderTemplates, leaderSearch)
      : leaderTemplates;

  // Event handlers
  const onSearchChange = (name, value) => {
    if (name === 'community') setcommunitySearch(value);
    else if (name === 'leader') setLeaderSearch(value);
    else if (name === 'member') setMemberSearch(value);
  };

  const handleSelectTemplate = template => {
    const exists = selectedTemplates.some(t => t.id === template.id);
    const updated = exists
      ? selectedTemplates.filter(t => t.id !== template.id)
      : [...selectedTemplates, template];
    setSelectedTemplates(updated);
  };

  const handleSelectUser = user => {
    const exists = selectedUsers.some(u => u.id === user.id);
    const updated = exists
      ? selectedUsers.filter(u => u.id !== user.id)
      : [...selectedUsers, user];
    setSelectedUsers(updated);
  };

  const handleSelectAllUser = () => {
    setSelectedUsers(computedUsers);
  };

  const handleCLearUsers = () => {
    setSelectedUsers([]);
  };

  const onContinueToUsers = () => setStep(2);

  const onBackToTemplates = () => setStep(1);

  const confirmAssignTemplates = () => {
    setAssigning(true);
    dispatch({
      type: templateTypes.ASSIGN_MISSION_TEMPLATES,
      members: selectedUsers.map(u => u.id),
      templates: selectedTemplates.map(t => t.id)
    });
  };

  // Open confirmation modal to double confirm assignment
  const onAssignTemplates = () => {
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.CONFIRMATION_MODAL,
      props: {
        message:
          'You are about to assign your selected Mission Templates to your selected Members, do you want to continue?',
        onSubmit: confirmAssignTemplates
      }
    });
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });
  };

  // Constants
  const showSelectTemplates = step === 1;
  const showSelectUsers = step === 2;
  const hasSelectedTemplates = selectedTemplates.length > 0;
  const hasSelectedUsers = selectedUsers.length > 0;
  const computedSaving = assigning || assignSaving;

  // Reset view after successful post - TODO consider not resetting when errors
  useEffect(() => {
    let isSubscribed = true;

    if (!computedSaving && isSubscribed) {
      setSelectedTemplates([]);
      setSelectedUsers([]);
    }

    return () => {
      isSubscribed = false;
    };
  }, [computedSaving]);

  return (
    <>
      <StepActions
        step={step}
        onShowAssignUsers={onContinueToUsers}
        onBack={onBackToTemplates}
        hasUsers={hasSelectedUsers}
        hasTemplates={hasSelectedTemplates}
        onAssignTemplates={onAssignTemplates}
      />
      <TemplatesAndUsers
        selectedTemplates={selectedTemplates}
        selectedUsers={selectedUsers}
        onRemoveTemplate={handleSelectTemplate}
        onRemoveUser={handleSelectUser}
        step={step}
      />
      {showSelectTemplates && (
        <SelectTemplates
          selectedTemplates={selectedTemplates}
          communityTemplates={computedCommunityTemplates}
          leaderTemplates={computedLeaderTemplates}
          communityLoading={communityTemplatesLoading}
          leaderLoading={leaderTemplatesLoading}
          communitySearch={communitySearch}
          leaderSearch={leaderSearch}
          onSearchChange={onSearchChange}
          onSelectTemplate={handleSelectTemplate}
        />
      )}
      {showSelectUsers && (
        <SelectUsers
          search={memberSearch}
          onSearchChange={onSearchChange}
          selectedUsers={selectedUsers}
          users={computedUsers}
          onClick={handleSelectUser}
          loading={usersLoading}
          onSelectAll={handleSelectAllUser}
          onClear={handleCLearUsers}
        />
      )}
      <AssigningTemplatesLoader open={computedSaving} />
    </>
  );
};

export default MissionTemplateStepper;
