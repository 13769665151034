import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const LottiePlayer = ({ animationData, height, width }) => (
  <Lottie
    options={{
      loop: true,
      autoPlay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }}
    height={height}
    width={width}
  />
);

LottiePlayer.propTypes = {
  animationData: PropTypes.object.isRequired,
  height: PropTypes.number,
  width: PropTypes.number
};

LottiePlayer.defaultProps = {
  height: 300,
  width: 500
};

export default LottiePlayer;
