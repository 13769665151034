import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AuthBackground from '../../components/Auth/AuthBackground/AuthBackground';
import UpdatingEmailCard from '../../components/Auth/UpdatingEmailCard/UpdatingEmailCard';
import AuthConfirmationCard from '../../components/Auth/AuthConfirmationCard/AuthConfirmationCard';
import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import Modal from '../../components/Modal/Modal';

import * as authTypes from '../../state/auth/types';
import { selectEmailChange } from '../../state/auth/reducers';

import authStyles from './authStyles';

const styles = makeStyles({
  ...authStyles
});

export default function SetNewEmail() {
  const classes = styles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);

  // Redux state
  const emailChange = useSelector(state => selectEmailChange(state));

  useEffect(() => {
    const { code: token } = queryString.parse(location.search);
    dispatch({
      type: authTypes.ACCEPT_EMAIL_CHANGE,
      token
    });
  }, []);

  // Successful submit - show confirmation
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (emailChange) {
        setShowLoading(false);
      } else {
        setShowLoading(true);
      }
    }

    return () => {
      isSubscribed = false;
    }
  }, [emailChange]);

  return (
    <>
      <AuthBackground>
        <Container className={classes.container}>
          <Grid container direction="column" justify="center" align="center">
            <Grid item xs={12}>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              {showLoading ? (
                <UpdatingEmailCard />
              ) : (
                <AuthConfirmationCard
                  title="Success!"
                  copy='Your email has been updated. You are required to login again for this change to take effect'
                  showBack
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </AuthBackground>
      <Modal />
    </>
  );
}
