import { useEffect, useRef } from 'react';

const usePrevious = value => {
  const ref = useRef();

  // Store current value in ref
  // This only runs when the value changes
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect)
  return ref.current;
};

export default usePrevious;
