import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../TextInput/TextInput';
import PasswordInput from '../../PasswordInput/PasswordInput';
import Button from '../../Button/Button';

import { getErrorMessage } from '../../../constants/validation';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: '50px 42px'
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10
  },
  copy: {
    maxWidth: 345,
    margin: '0px auto 20px auto',
    fontSize: 14,
    fontWeight: 300
  },
  mb10: {
    marginBottom: 10
  },
  mb30: {
    marginBottom: 30
  },
  button: {
    width: 150
  }
});

export default function AcceptInvitationCard({
  firstName,
  lastName,
  password,
  passwordConfirm,
  onChange,
  onSubmit,
  errors,
  loading
}) {
  const classes = styles();

  const canSubmit = !errors && !loading;

  function onPressEnter(e) {
    if (e.key === 'Enter' && canSubmit) {
      onSubmit();
    }
  }

  return (
    <Paper className={classes.paper} onKeyPress={onPressEnter}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title} align="center">
            Create your new On Mission account
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.copy} align="center">
            Please fill in your name and create a password to access your community.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mb10}>
          <TextInput
            name="firstName"
            label="First name"
            value={firstName}
            onChange={onChange}
            error={Boolean(getErrorMessage(errors, 'firstName'))}
            errorMessage={getErrorMessage(errors, 'firstName')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb10}>
          <TextInput
            name="lastName"
            label="Last name"
            value={lastName}
            onChange={onChange}
            error={Boolean(getErrorMessage(errors, 'lastName'))}
            errorMessage={getErrorMessage(errors, 'lastName')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb10}>
          <PasswordInput
            name="password"
            label="Password"
            value={password}
            onChange={onChange}
            error={Boolean(getErrorMessage(errors, 'password'))}
            errorMessage={getErrorMessage(errors, 'password')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb30}>
          <PasswordInput
            name="passwordConfirm"
            label="Confirm password"
            value={passwordConfirm}
            onChange={onChange}
            error={Boolean(getErrorMessage(errors, 'passwordConfirm'))}
            errorMessage={getErrorMessage(errors, 'passwordConfirm')}
          />
        </Grid>
        <Grid container justify="center">
          <Button
            label="Submit"
            onClick={onSubmit}
            className={classes.button}
            loading={loading}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

AcceptInvitationCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordConfirm: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

AcceptInvitationCard.defaultProps = {
  errors: null
};
