import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SearchInput from '../SearchInput/SearchInput';
import UserList from './UserList';

const useStyles = makeStyles({
  title: {
    marginBottom: 15,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  search: {
    maxWidth: 480
  },
  typography: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginLeft: 20,
    fontSize: 12
  }
});

const SelectUsers = ({
  users,
  selectedUsers,
  onClick,
  search,
  onSearchChange,
  onSelectAll,
  onClear,
  loading
}) => {
  const classes = useStyles();

  const handleSearch = value => {
    onSearchChange('member', value);
  };

  // Constants
  const hasSearch = search.trim().length > 0;

  return (
    <Box>
      <Typography className={classes.title}>Members</Typography>
      <Box display="flex" marginBottom={1.25}>
        <Box flex={1}>
          <SearchInput
            value={search}
            onChange={handleSearch}
            placeholder="Filter by member or team name…"
            className={classes.search}
          />
        </Box>
        <Typography className={classes.typography} onClick={onSelectAll}>
          Select All
        </Typography>
        <Typography className={classes.typography} onClick={onClear}>
          Clear
        </Typography>
      </Box>
      <UserList
        users={users}
        selectedUsers={selectedUsers}
        onClick={onClick}
        loading={loading}
        hasSearch={hasSearch}
      />
    </Box>
  );
};

SelectUsers.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default SelectUsers;
