import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withTheme } from '@material-ui/core/styles';
import { getPracticeScoreLabel } from '../../utils/helpers';

const PracticeScoreChip = ({ score, theme }) => {
  const label = getPracticeScoreLabel(score);
  return (
    <Chip
      size="small"
      label={label}
      style={{
        backgroundColor: theme.palette.practiceScore[label] || '#8b8f95',
        fontSize: 10,
        color: 'white',
        fontWeight: 'bold'
      }}
    />
  );
};

export default withTheme(PracticeScoreChip);
