import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as HouseIcon } from '../../static/images/icons/icon-home.svg';
import { ReactComponent as MembersIcon } from '../../static/images/icons/icon-members.svg';

import CommunitySwitcher from '../CommunitySwitcher/CommunitySwitcher';
import MenuDrawerLoading from './MenuDrawerLoading';
import MenuItem from './MenuItem';

import routeBuilder from '../../constants/routes';

import { selectAllLeaderUsers } from '../../state/chat/reducers';
import { selectIsOfficer, selectLoggedInUserId } from '../../state/auth/reducers';
import {
  selectCurrentUserCommunityPermissions,
  selectSelectedCommunityId,
  selectCommunityLoadingState
} from '../../state/community/reducers';
import { isLeaderInCommunity } from '../../utils/helpers';

const styles = makeStyles(theme => ({
  drawerRoot: {
    width: 200
  },
  drawerPaper: {
    width: 200,
    background: theme.palette.background.dark,
    boxShadow: `
      0 0px 2.7px rgba(0, 0, 0, 0.019),
      0 0px 6.9px rgba(0, 0, 0, 0.027),
      0 0px 14.2px rgba(0, 0, 0, 0.033),
      0 0px 29.2px rgba(0, 0, 0, 0.041),
      0 0px 80px rgba(0, 0, 0, 0.06)
    `
  },
  paperPadding: {
    paddingTop: 0
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#072144 !important',
    textDecoration: 'none'
  },
  dashboard: {
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 5,
    margin: 10,
    border: '1px solid #d6d6d6',
    borderRadius: 10,
    marginBottom: 0
  },
  listWrap: {
    marginTop: 20
  },
  routeList: {
    paddingTop: 0
  },
  comAdmin: {
    background: '#f9f9f9'
  },
  heading: {
    marginLeft: 20,
    marginBottom: 10,
    fontSize: 12,
    color: '#a5721a',
    textTransform: 'uppercase',
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  }
}));

// Helper function for updating the nav based on user role/permissions
function visibleRoutes(routes, isOfficer, permissions) {
  const officerRoutes = routes.filter(route => route.roles.some(r => r === 'officer'));

  const permissionRoutes = routes.filter(
    route =>
      route.permissions.some(permission => permissions.some(p => p === permission)) ||
      route.view?.some(v => permissions.some(p => p === v))
  );

  const initial = new Set(officerRoutes.map(r => r.key));
  const merged = [...permissionRoutes.filter(r => !initial.has(r.key)), ...officerRoutes];

  return isOfficer ? merged : permissionRoutes;
}

export default function MenuDrawer() {
  const classes = styles();
  const { pathname } = useLocation();

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  // Redux
  const communityLoading = useSelector(state => selectCommunityLoadingState(state));
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));

  // Officer can lead users without being a leader - the mission template route needs this info
  const authUser = useSelector(state => selectLoggedInUserId(state));
  const allLeaderUsers = useSelector(state => selectAllLeaderUsers(state));
  const officerLeads = isLeaderInCommunity(authUser, allLeaderUsers, communityId);

  // Helper constants
  const isComAdmin = permissions.includes('admin');
  const isLeader = permissions.includes('leader') || officerLeads;

  // Build all of the routes then compute what is to be displayed
  const _routes = routeBuilder(communityId, isComAdmin, isLeader, isOfficer);
  const computedRoutes = visibleRoutes(_routes, isOfficer, permissions);
  const adminRoutes = computedRoutes.filter(r => r.navMenu && r.group === 'comAdmin');
  const leaderRoutes = computedRoutes.filter(r => r.navMenu && r.group === 'leader');

  const paperClasses = clsx({
    [classes.drawerPaper]: true,
    [classes.paperPadding]: !isOfficer
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Hidden mdUp>
        <AppBar position="fixed" style={{ backgroundColor: 'white' }}>
          <Toolbar>
            <IconButton color="primary" onClick={() => setIsMenuOpen(!isMenuOpen)} edge="start" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Drawer
        onClose={() => setIsMenuOpen(false)}
        open={largeScreen ? true : isMenuOpen}
        classes={{
          root: classes.drawerRoot,
          paper: paperClasses
        }}
        variant={largeScreen ? 'permanent' : 'temporary'}
        anchor="left"
        elevation={4}
      >
        {isOfficer && (
          <Box className={classes.dashboard}>
            <Typography className={classes.heading}>Officer</Typography>
            <MenuItem
              isActive={pathname === `/c/${communityId}/members/officer`}
              to={`/c/${communityId}/members/officer`}
              title="Members"
              icon={MembersIcon}
            />
            <MenuItem
              isActive={pathname === `/c/${communityId}/dashboard`}
              to={`/c/${communityId}/dashboard`}
              title="Activity"
              icon={HouseIcon}
            />
          </Box>
        )}
        <CommunitySwitcher />
        {communityLoading ? (
          <MenuDrawerLoading />
        ) : (
          <>
            {leaderRoutes.length > 0 && (
              <Box className={classes.listWrap}>
                <Typography className={classes.heading}>Leader</Typography>
                <List className={classes.routeList}>
                  {leaderRoutes.map(route => {
                    const { key, to, title, icon, permissions: routePermissions } = route;
                    const isActive = route.to === pathname;

                    return (
                      <MenuItem
                        key={key}
                        isActive={isActive}
                        to={to}
                        title={title}
                        icon={icon}
                        permissions={routePermissions}
                      />
                    );
                  })}
                </List>
              </Box>
            )}

            {adminRoutes.length > 0 && (
              <Box className={classes.listWrap}>
                <Typography className={classes.heading}>Community Admin</Typography>
                <List className={classes.routeList}>
                  {adminRoutes.map(route => {
                    const { key, to, title, icon, permissions: routePermissions } = route;
                    const isActive = route.to === pathname;

                    return (
                      <MenuItem
                        key={key}
                        isActive={isActive}
                        to={to}
                        title={title}
                        icon={icon}
                        permissions={routePermissions}
                      />
                    );
                  })}
                </List>
              </Box>
            )}
          </>
        )}
      </Drawer>
    </>
  );
}
