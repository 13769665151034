import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import PageHeader from '../components/PageHeader/PageHeader';
import InfoBar from '../components/InfoBar/InfoBar';
import MissionDetails from '../components/MissionTemplates/MissionDetails';
import Objectives from '../components/MissionTemplates/Objectives';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';
import Button from '../components/Button/Button';

import * as templateTypes from '../state/templates/types';
// import * as missionTypes from '../state/mission/types';
import * as modalTypes from '../state/modal/types';

import { ensurePositiveNumber } from '../utils/helpers';
import { validateMissionTemplate } from '../constants/validation';

// import { selectLoggedInUserId } from '../state/auth/reducers';

import { selectIsOfficer } from '../state/auth/reducers';
import {
  selectSelectedTemplate,
  selectSelectedMissionTemplate,
  selectMissionTemplateSaving
} from '../state/templates/reducers';
import { selectCurrentUserCommunityPermissions } from '../state/community/reducers';

const useStyles = makeStyles({
  createButton: {
    width: 230
  },
  ml10: {
    marginLeft: 10
  },
  mr10: {
    marginRight: 10
  }
});

const MissionCreateUpdate = () => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { templateId, templateType } = useParams();
  const history = useHistory();

  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [details, setDetails] = useState({
    icon: 'circle',
    title: '',
    description: '',
    startDays: 30
  });
  const [objectives, setObjectives] = useState([]);
  // const [missions, setMissions] = useState([]);
  const [errors, setErrors] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedObjective, setSelectedObjective] = useState(null);

  // Redux
  const permissions = useSelector(state =>
    selectCurrentUserCommunityPermissions(state)
  );
  const selectedTemplate = useSelector(state => selectSelectedTemplate(state));
  const selectedMissionTemplate = useSelector(state => selectSelectedMissionTemplate(state));
  const saving = useSelector(state => selectMissionTemplateSaving(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  // const loggedInUser = useSelector(state => selectLoggedInUserId(state));


  // Get mission template if in route and not in redux
  useEffect(() => {
    // There is a template selected but it's the wrong one
    const wrongTemplate = selectedTemplate && selectedTemplate.id !== templateId;
    if (wrongTemplate)
      dispatch({ type: templateTypes.CLEAR_SELECTED_MISSION_TEMPLATE });

    if (templateId && !selectedTemplate || templateId && wrongTemplate) {
      dispatch({
        type: templateTypes.GET_MISSION_TEMPLATE,
        templateId
      });
    }


  }, [templateId, dispatch, selectedTemplate]);

  useEffect(() => {

    if (templateId) {
      dispatch({
        type: templateTypes.GET_MISSION_BY_TEMPLATE,
        templateId
      });
    }

  }, [templateId, dispatch]);

  // If a leader only trys to create a community template - redirect
  useEffect(() => {
    const nonAdmin =
      templateType === 'community' && !permissions.some(p => p === 'admin');
    const nonLeader =
      templateType === 'leader' && !permissions.some(p => p === 'leader') && !isOfficer;
    if (nonAdmin || nonLeader) {
      history.push('/');
    }
  }, [templateType, permissions, history]);

  // Set state if viewing existing template
  useEffect(() => {
    if (selectedTemplate) {
      const {
        icon,
        title,
        description,
        startDays,
        objectives: existingObjectives
      } = selectedTemplate;

      setDetails({
        icon,
        title,
        description,
        startDays
      });

      setObjectives(existingObjectives);
    }
  }, [selectedTemplate]);

  // Set state if viewing existing template
  useEffect(() => {
    if (selectedMissionTemplate) {

      // setMissions(selectedMissionTemplate);
    }
  }, [selectedMissionTemplate]);

  // Event handlers
  const openDrawer = () => setDrawerOpen(true);
  // temporary disabled: not used
  // const onRemoveLeader = (mission) => {

  //   dispatch({
  //     type: missionTypes.ARCHIVE_MISSION_BY_TEMPLATE,
  //     templateId: mission.missiontemplate,
  //     missionId: mission._id
  //   });
  // }

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedObjective(null);
  };

  const handleTextChange = e => {
    const { name, value } = e.target;
    const updated = {
      ...details,
      [name]: value
    };

    if (errors) {
      const hasError = validateMissionTemplate({ ...updated });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    // Stop user from entering negative values
    if (name === 'startDays') {
      setDetails({
        ...details,
        startDays: ensurePositiveNumber(value)
      });
    } else {
      setDetails({
        ...updated
      });
    }
  };

  const handleIconChange = icon => {
    const updated = {
      ...details,
      icon
    };

    if (errors) {
      const hasError = validateMissionTemplate(updated);
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails(updated);
  };

  const addObjective = objective => {
    setObjectives([
      ...objectives,
      {
        ...objective,
        startDays: ensurePositiveNumber(objective.startDays) || 0
      }
    ]);
  };

  const updateObjective = objective => {
    const updated = objectives.map(o => {
      if (o.id === objective.id)
        return {
          ...objective,
          startDays: ensurePositiveNumber(objective.startDays) || 0
        };
      return o;
    });
    setObjectives(updated);
  };

  const deleteObjective = objective => {
    const updated = objectives.filter(o => o.id !== objective.id);
    setObjectives(updated);
    closeDrawer();
  };

  const createTemplate = () => {
    const errorMessages = validateMissionTemplate(details);
    if (!errorMessages) {
      // Determine if community or leader template
      const isCommunityTemplate = templateType === 'community';

      setErrors(null);
      const template = {
        ...details,
        objectives
      }
      if (!selectedTemplate) template.imageFile = imageFile
      dispatch({
        type: selectedTemplate
          ? templateTypes.UPDATE_MISSION_TEMPLATE
          : templateTypes.CREATE_MISSION_TEMPLATE,
        isCommunity: isCommunityTemplate,
        template
      });
      if (selectedTemplate?.id) {
        dispatch({
          type: templateTypes.UPDATE_PROFILE_IMAGE,
          templateId: selectedTemplate.id,
          imageFile,
        })
      }
    } else setErrors(errorMessages);
  };

  const viewObjective = objective => {
    setSelectedObjective(objective);
    openDrawer();
  };

  const confirmArchiveMission = () => {
    dispatch({
      type: templateTypes.DELETE_MISSION_TEMPLATE,
      isCommunity: Boolean(selectedTemplate.community),
      templateId: selectedTemplate.id
    });
  };

  const onClickArchive = () => {
    // Set the modal component
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.CONFIRMATION_MODAL,
      props: {
        title: 'Are you sure you want to archive this Mission Template?',
        message:
          'This will archive this Mission Template, but not the copies that have already been assigned to users.',
        onSubmit: confirmArchiveMission,
        savingSelector: selectMissionTemplateSaving
      }
    });
    // Open the modal
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });
  };

  // Constants
  const { icon, title, description, startDays } = details;
  const isEditing = Boolean(selectedTemplate);
  const isCommunityTemplate =
    templateType === 'community' || (selectedTemplate && selectedTemplate.community);

  const infoBarContent = `
    You are ${isEditing ? 'editing' : 'creating'}
    ${isCommunityTemplate ? 'a Company' : 'your own Leader'} Mission Template
  `;

  const editingActions = (
    <>
      <InfoBar content={infoBarContent} />
      <Button
        label="Archive"
        background="grey"
        className={clsx(classes.ml10, classes.mr10)}
        onClick={onClickArchive}
        saving={saving}
        disabled={saving}
      />
      <Button
        label="Save"
        onClick={createTemplate}
        loading={saving}
        disabled={saving}
      />
    </>
  );

  const headerActions = isEditing ? (
    editingActions
  ) : (
    <InfoBar content={infoBarContent} />
  );

  const handleImageChange = file => setImageFile(file);

  return (
    <>
      <PageHeader
        routeTitle={isEditing ? 'Edit template' : 'Template'}
        actions={headerActions}
        showBack
      />
      <Box component="main">
        <Box id="mission-details" marginBottom={2.5}>
          <MissionDetails
            icon={icon}
            name={title}
            description={description}
            startDays={startDays}
            missionTemplateId={selectedTemplate?.id}
            onImageChange={handleImageChange}
            onTextChange={handleTextChange}
            onIconChange={handleIconChange}
            errors={errors}
          />
        </Box>
        <Box id="mission-objectives">
          <Objectives
            onAdd={openDrawer}
            objectives={objectives}
            onViewObjective={viewObjective}
          />
        </Box>
        <Box marginTop={2} textAlign="center">
          {!isEditing && (
            <Button
              label="Create template"
              className={classes.createButton}
              onClick={createTemplate}
              loading={saving}
              disabled={saving}
            />
          )}
        </Box>
        <ObjectiveDrawer
          open={drawerOpen}
          onClose={closeDrawer}
          onCreate={addObjective}
          onDelete={deleteObjective}
          selectedObjective={selectedObjective}
          onUpdate={updateObjective}
          isTemplate
        />
      </Box>
    </>
  );
};

export default MissionCreateUpdate;
