import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';
import TemplateObjectiveCard from './TemplateObjectiveCard';

import { getTemplateObjectiveTitle } from '../../utils/helpers';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    flex: 1,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  paper: {
    minHeight: 187,
    padding: 15,
    borderRadius: 10,
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)'
  },
  placeholderWrap: {
    height: 155,
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pointer: {
    cursor: 'pointer'
  },
  placeholderText: {
    fontSize: 14,
    maxWidth: 245,
    textAlign: 'center',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    userSelect: 'none'
  }
});

const Objectives = ({
  objectives,
  onAdd,
  onViewObjective,
  isTemplate,
  readOnly,
  addDisabled
}) => {
  // Hooks
  const classes = useStyles();

  // Constants
  const hasObjectives = objectives.length > 0;
  const helperText = isTemplate
    ? 'Add Objectives to this mission.'
    : 'Create Objectives to help your member stay on track.';

  const placeholderWrapClass = clsx({
    [classes.placeholderWrap]: true,
    [classes.pointer]: !readOnly && !addDisabled
  });

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={1.5}>
        <Typography className={classes.title}>Objectives</Typography>
        {!readOnly && (
          <Button
            label="Add objective"
            onClick={onAdd}
            disabled={addDisabled}
          />
        )}
      </Box>
      <Paper className={classes.paper}>
        {hasObjectives ? (
          objectives.map((objective, idx) => {
            const { statement } = objective;
            const objectiveStatement = getTemplateObjectiveTitle(
              statement,
              Boolean(statement?.metric)
            );
            return (
              <TemplateObjectiveCard
                key={`objective-${idx}`}
                objectiveStatement={objectiveStatement}
                readOnly={readOnly}
                onClick={() => onViewObjective(objective)}
              />
            );
          })
        ) : (
          <Box
            className={placeholderWrapClass}
            onClick={readOnly || addDisabled ? null : onAdd}
            role="presentation"
          >
            <Typography className={classes.placeholderText}>
              {readOnly ? 'This template currently has no objectives' : helperText}
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
};

Objectives.propTypes = {
  objectives: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onViewObjective: PropTypes.func,
  isTemplate: PropTypes.bool,
  readOnly: PropTypes.bool,
  addDisabled: PropTypes.bool
};

Objectives.defaultProps = {
  objectives: [],
  onViewObjective: null,
  isTemplate: true,
  readOnly: false,
  addDisabled: false
};

export default Objectives;
