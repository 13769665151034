import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CreateTeamView from './CreateTeamView';
import TeamDetailsView from './TeamDetailsView';

const useStyles = makeStyles({
  drawer: {
    width: 720,
    marginLeft: 80
  },
  icon: {
    position: 'absolute'
  }
});

export default function TeamDrawer({
  open,
  onClose,
  showCreate,
  isEdit,
  onEditDetails,
  readOnly
}) {
  const classes = useStyles();
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      PaperProps={{ classes: { root: classes.drawer } }}
    >
      <IconButton onClick={onClose} className={classes.icon}>
        <CloseIcon />
      </IconButton>
      {showCreate ? (
        <CreateTeamView closeDrawer={onClose} isEdit={isEdit} />
      ) : (
        <TeamDetailsView
          onEditDetails={onEditDetails}
          onClose={onClose}
          readOnly={readOnly}
        />
      )}
    </Drawer>
  );
}

TeamDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  onEditDetails: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

TeamDrawer.defaultProps = {
  showCreate: false,
  isEdit: false,
  readOnly: false
};
