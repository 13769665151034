import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import PopUpMenu from '../PopUpMenu/PopUpMenu';
import { getStringDescFromSlate } from '@/utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 10px 10px 20px',
    minHeight: 60,
    marginBottom: 10,
    display: 'flex'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1
  },
  title: {
    fontSize: 16,
    color: '#223f63',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  description: {
    fontSize: 14
  },
  countPerWeek: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  }
}));

const ObjectivePracticeCard = ({ title, description, richDescription, countPerWeek, menuActions }) => {
  // Hooks
  const classes = useStyles();

  const descriptionShowed = richDescription ? getStringDescFromSlate(richDescription): description

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.countPerWeek}>{countPerWeek} times per week</Typography>
        </Box>
        <Box className={classes.body}>
          <Typography className={classes.description}>{descriptionShowed}</Typography>
        </Box>
      </Box>

      {menuActions && <PopUpMenu menuActions={menuActions} />}

      {/* <Box display="flex" justifyContent="space-between">
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.title}>
          {countPerWeek} times per week
        </Typography>
      </Box>
      {menuActions && <PopUpMenu menuActions={menuActions} />}
      <Typography className={classes.description}>{description}</Typography> */}
    </Box>
  );
};

ObjectivePracticeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  countPerWeek: PropTypes.number.isRequired,
  menuActions: PropTypes.array,
  richDescription: PropTypes.string,
};

ObjectivePracticeCard.defaultProps = {
  menuActions: [],
  richDescription: ''
};

export default ObjectivePracticeCard;
