import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import StatusDropdown from '../StatusDropdown/StatusDropdown';
import Button from '../Button/Button';
import ObjectiveActivityDrawer from '../Drawers/ObjectiveActivityDrawer';

const ObjectiveActions = ({ readOnly, objectiveStatus, onStatusChange, onEdit, saving, activity, entities }) => {
  // Hooks

  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  return (
    <>
      <Grid container spacing={1} display="flex" justify="flex-end" width="100%" style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={8} lg={5}>
          <StatusDropdown
            data-test-id="select-objective-status"
            readOnly={readOnly}
            selected={objectiveStatus}
            onChange={onStatusChange}
            saving={saving}
          />
        </Grid>
        <Hidden mdDown>
          {activity && (
            <Grid item xs={12} md={4} lg={4}>
              <Button fullWidth background="white" label="Activity" onClick={() => setIsActivityDrawerOpen(true)} />
            </Grid>
          )}
          {!readOnly && (
            <Grid item xs={12} md={4} lg={4}>
              <Button fullWidth label="Edit Objective" onClick={onEdit} disabled={saving} loading={saving} />
            </Grid>
          )}
        </Hidden>
      </Grid>

      <Box justifyContent="flex-end" display="flex" />

      {activity && (
        <ObjectiveActivityDrawer
          anchor="right"
          open={isActivityDrawerOpen}
          activities={activity}
          entities={entities}
          onClose={() => setIsActivityDrawerOpen(false)}
        />
      )}
    </>
  );
};

ObjectiveActions.propTypes = {
  objectiveStatus: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  activity: PropTypes.array
};

ObjectiveActions.defaultProps = {
  activity: null
};

export default ObjectiveActions;
