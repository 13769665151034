import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextInput from '../../TextInput/TextInput';
import Button from '../../Button/Button';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: '50px 21px',
    '@media (min-width: 768px)': {
      padding: '50px 42px'
    }
  },
  inputMargin: {
    marginBottom: 36
  },
  textCenter: {
    textAlign: 'center'
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10
  },
  copy: {
    maxWidth: 345,
    margin: '0px auto 20px auto',
    fontSize: 14,
    fontWeight: 300
  },
  sendButton: {
    width: 150
  }
});

export default function ResetPasswordCard({
  loading,
  email,
  errorMessage,
  handleChange,
  handleSubmit
}) {
  const classes = styles();

  const canSubmit = email !== '' || loading;

  function onPressEnter(e) {
    if (e.key === 'Enter' && canSubmit) {
      handleSubmit();
    }
  }

  return (
    <Paper className={classes.paper} onKeyPress={onPressEnter}>
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.textCenter}>
          <Typography variant="h1" className={classes.title}>
            Forgot password?
          </Typography>
          <Typography className={classes.copy}>
            Provide your email address and we’ll help you get back on track!
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.inputMargin}>
          <TextInput
            name="email"
            label="Email address"
            disabled={loading}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            value={email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.textCenter}>
          <Button
            label="Send"
            loading={loading}
            disabled={!canSubmit}
            onClick={handleSubmit}
            className={classes.sendButton}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

ResetPasswordCard.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

ResetPasswordCard.defaultProps = {
  loading: false,
  email: '',
  errorMessage: ''
};
