import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  wrap: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconGold: {
    '& *': {
      fill: 'url(#lgrad)'
    }
  }
});

const GoldIcon = ({ icon, style, className }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Box style={style} className={className}>
        <FontAwesomeIcon icon={icon} className={classes.iconGold} size="1x" />
      </Box>
    </Box>
  );
};

GoldIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

GoldIcon.defaultProps = {
  style: null,
  className: ''
};

export default GoldIcon;
