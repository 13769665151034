import PropTypes from 'prop-types'
import React from 'react'

const Transformer = ({className}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 170.08 170.08"
      className={className}
      width="250"
      height="250"
    >
      <defs>
        <style>{'.transformer-cls-1{fill:none;}.transformer-cls-2{opacityxs:0.23;}.transformer-cls-3{clip - path:url(#clip-path);}'}</style>
        <clipPath id="clip-path">
          <rect className="transformer-cls-1" x="21.73" y="28.35" width="126.61" height="113.39" />
        </clipPath>
      </defs>
      <g className="transformer-cls-2">
        <g className="transformer-cls-3">
          <path
            d="M59.41,50.8A11.23,11.23,0,1,1,70.64,39.57,11.24,11.24,0,0,1,59.41,50.8m0-19.84A8.62,8.62,0,1,0,68,39.57,8.62,8.62,0,0,0,59.41,31"
          />
          <path
            d="M33,97.09A11.23,11.23,0,1,1,44.19,85.87,11.24,11.24,0,0,1,33,97.09m0-19.84a8.62,8.62,0,1,0,8.61,8.62A8.63,8.63,0,0,0,33,77.25"
          />
          <path
            d="M57.77,141.73h-.3a11.37,11.37,0,1,1,.3,0m0-19.84a8.63,8.63,0,0,0-8.61,8.4A8.61,8.61,0,1,0,58,121.9h-.22"
          />
          <path
            d="M110.67,141.73h-.29a11.37,11.37,0,1,1,.29,0m0-19.84a8.61,8.61,0,0,0-6.23,14.54,8.53,8.53,0,0,0,6,2.68,8.59,8.59,0,0,0,6.45-14.53,8.51,8.51,0,0,0-6-2.68h-.23"
          />
          <path
            d="M137.12,97.09h-.29a11.37,11.37,0,1,1,.29,0m0-19.84a8.63,8.63,0,0,0-8.61,8.4,8.64,8.64,0,0,0,8.39,8.83,8.63,8.63,0,0,0,8.83-8.39,8.64,8.64,0,0,0-8.39-8.83h-.22"
          />
          <path
            d="M110.67,50.8a11.23,11.23,0,1,1,11.22-11.23A11.25,11.25,0,0,1,110.67,50.8m0-19.84a8.62,8.62,0,1,0,8.61,8.61A8.63,8.63,0,0,0,110.67,31"
          />
        </g>
        <rect x="67.68" y="130.85" width="33.07" height="2.61" />
        <rect x="42.93" y="92.51" width="2.61" height="31.87" transform="translate(-48.29 36.64) rotate(-30)" />
        <rect
          x="29.03"
          y="60.59"
          width="32.45"
          height="2.61"
          transform="translate(-30.98 70.12) rotate(-59.98)"
        />
        <rect x="69.33" y="38.64" width="31.41" height="2.61" />
        <rect x="122.64" y="45.64" width="2.61" height="32.86" transform="translate(-14.43 70.29) rotate(-30)" />
        <rect
          x="108.78"
          y="106.88"
          width="32.45"
          height="2.61"
          transform="translate(-31.2 162.32) rotate(-59.99)"
        />
        <g className="transformer-cls-3">
          <path d="M93.62,69h0A18.19,18.19,0,1,1,69,76.46,18.25,18.25,0,0,1,93.62,69" />
        </g>
        <rect
          x="83.32"
          y="42.9"
          width="2.61"
          height="85.11"
          transform="translate(-30.85 51.85) rotate(-29.05)"
        />
        <rect
          x="42.08"
          y="84.15"
          width="85.11"
          height="2.61"
          transform="translate(-31.16 117.96) rotate(-60.96)"
        />
        <rect x="42.08" y="84.15" width="85.11" height="2.61" transform="translate(-0.42 0.42) rotate(-0.28)" />
      </g>
    </svg>
  )
}

Transformer.propTypes = {
  className: PropTypes.string
}

Transformer.defaultProps = {
  className: ''
}

export default Transformer

