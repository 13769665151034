export const CREATE_TEAM = 'teams/CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'teams/CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR = 'teams/CREATE_TEAM_ERROR';

export const UPDATE_TEAM = 'teams/UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'teams/UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_ERROR = 'teams/UPDATE_TEAM_ERROR';

export const DELETE_TEAM = 'teams/DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'teams/DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'teams/DELETE_TEAM_ERROR';

export const GET_ALL_TEAMS = 'teams/GET_ALL_TEAMS';
export const GET_ALL_TEAMS_SUCCESS = 'teams/GET_ALL_TEAMS_SUCCESS';
export const GET_ALL_TEAMS_ERROR = 'teams/GET_ALL_TEAMS_ERROR';

export const GET_TEAM = 'teams/GET_TEAM';
export const GET_TEAM_SUCCESS = 'teams/GET_TEAM_SUCCESS';
export const GET_TEAM_ERROR = 'teams/GET_TEAM_ERROR';

export const SET_TEAM = 'teams/SET_TEAM';

export const ADD_MEMBERS_TO_TEAM = 'teams/ADD_MEMBERS_TO_TEAM';
export const ADD_MEMBERS_TO_TEAM_SUCCESS = 'teams/ADD_MEMBERS_TO_TEAM_SUCCESS';
export const ADD_MEMBERS_TO_TEAM_ERROR = 'teams/ADD_MEMBERS_TO_TEAM_ERROR';

export const REMOVE_MEMBER_FROM_TEAM = 'teams/REMOVE_MEMBER_FROM_TEAM';
export const REMOVE_MEMBER_FROM_TEAM_SUCCESS =
  'teams/REMOVE_MEMBER_FROM_TEAM_SUCCESS';
export const REMOVE_MEMBER_FROM_TEAM_ERROR = 'teams/REMOVE_MEMBER_FROM_TEAM_ERROR';

export const TEAM_LOADING_STATE = 'teams/TEAM_LOADING_STATE';
export const TEAM_SAVING_STATE = 'teams/TEAM_SAVING_STATE';

export const CLEAR_TEAM_API_ERRORS = 'teams/CLEAR_TEAM_API_ERRORS';
