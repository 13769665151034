import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import TaskInput from './TaskInput';
import TaskCard from './TaskCard';

const useStyles = makeStyles({
  taskWrap: {
    padding: '0px 0px'
  }
});

const ObjectiveDrawerTasks = ({
  addTask,
  editTask,
  tasks,
  deleteTask,
  objectiveStartDate,
  objectiveStatement,
  readOnly,
  isTemplate
}) => {
  // Hooks
  const classes = useStyles();

  // The initial due date for a task needs to be set to the objective due date
  const { daysOnceActive, dueDate: objectiveDueDate } = objectiveStatement;
  const computedObjectiveDueDate = daysOnceActive
    ? moment(objectiveStartDate).add(daysOnceActive, 'days')
    : moment(objectiveDueDate);

  return (
    <>
      {!readOnly && (
        <TaskInput
          onSubmit={addTask}
          onCreate={addTask}
          isActive={!isTemplate}
          objectiveDueDate={computedObjectiveDueDate}
          objectiveStartDate={objectiveStartDate}
        />
      )}
      <Box className={classes.taskWrap}>
        {tasks.map(t => (
          <div key={t.id || `${t.task}-${t.description}-${t.dueDate}`}>
            <TaskCard task={t} onDelete={deleteTask} onCreate={editTask} readOnly={readOnly} />
          </div>
        ))}
      </Box>
    </>
  );
};

ObjectiveDrawerTasks.propTypes = {
  readOnly: PropTypes.bool,
  addTask: PropTypes.func.isRequired,
  editTask: PropTypes.func,
  tasks: PropTypes.array.isRequired,
  deleteTask: PropTypes.func.isRequired,
  objectiveStartDate: PropTypes.instanceOf(moment),
  objectiveStatement: PropTypes.object.isRequired,
  isTemplate: PropTypes.bool
};

ObjectiveDrawerTasks.defaultProps = {
  readOnly: false,
  objectiveStartDate: null,
  isTemplate: false,
  editTask: () => {},
};

export default ObjectiveDrawerTasks;
