import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import PageHeader from '../components/PageHeader/PageHeader';
// import InfoBar from '../components/InfoBar/InfoBar';
import MissionDetails from '../components/MissionTemplates/MissionDetails';
import Objectives from '../components/MissionTemplates/Objectives';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';
import Button from '../components/Button/Button';

import * as missionTypes from '../state/mission/types';
import * as userTypes from '../state/users/types';
import * as modalTypes from '../state/modal/types';

import { validateMission } from '../constants/validation';
import { selectSelectedMission, selectMissionSaving } from '../state/mission/reducers';

const useStyles = makeStyles({
  createButton: {
    width: 230
  },
  mr10: {
    marginRight: 10
  }
});

const MissionCreateUpdate = () => {
  // Hooks
  const classes = useStyles();
  const { userId, missionId } = useParams();
  const dispatch = useDispatch();

  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [details, setDetails] = useState({
    icon: 'circle',
    title: '',
    description: '',
    endDate: moment().add(30, 'days')
  });
  const [objectives, setObjectives] = useState([]);
  const [errors, setErrors] = useState(null);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  // Redux
  const selectedMission = useSelector(state => selectSelectedMission(state));
  const missionSaving = useSelector(state => selectMissionSaving(state));

  // Set state if viewing existing template
  useEffect(() => {
    if (selectedMission && missionId) {
      const { icon, title, description, endDate, objectives: existingObjectives } = selectedMission;

      setDetails({
        icon,
        title,
        description,
        endDate: moment(endDate)
      });

      setObjectives(existingObjectives);
    }
  }, [selectedMission, missionId]);

  // Get user on mount
  useEffect(() => {
    dispatch({
      type: userTypes.GET_USER,
      userId
    });
  }, [userId]);

  // Get mission if in route
  useEffect(() => {
    if ((missionId && !selectedMission) || (missionId && selectedMission?.id !== missionId)) {
      dispatch({
        type: missionTypes.GET_MISSION,
        userId,
        missionId
      });
    }
  }, [missionId]);

  // Event handlers
  const openDrawer = () => setDrawerOpen(true);

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedObjective(null);
  };

  const handleIconChange = icon => {
    const updated = {
      ...details,
      icon
    };

    if (errors) {
      const hasError = validateMission(updated);
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails(updated);
  };

  const handleTextChange = e => {
    const { name, value } = e.target;
    const updated = {
      ...details,
      [name]: value
    };

    if (errors) {
      const hasError = validateMission({ ...updated });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails({
      ...updated
    });
  };

  const addObjective = newObjective => {
    if (selectedMission && missionId) {
      // Add objective at API level
      const objective = {
        ...newObjective,
        mission: missionId,
        statement: {
          ...newObjective.statement,
          daysOnceActive: Number(newObjective.daysOnceActive) || 0,
          currentMetric: newObjective.currentMetric || 0
        }
      };

      dispatch({
        type: missionTypes.CREATE_OBJECTIVE,
        userId,
        objective
      });
    } else {
      setObjectives([...objectives, newObjective]);
    }
  };

  const updateObjective = objective => {
    if (selectedMission) {
      // Edit the objective at API level

      // Full objective - need to manipulate data for API
      // Remove unaccepted keys and ensure currentMetric is a number
      const { status, comments, ...objectiveData } = objective;
      const payload = {
        ...objectiveData,
        currentMetric: objective.currentMetric || 0
      };

      dispatch({
        type: missionTypes.UPDATE_OBJECTIVE,
        userId,
        objectiveId: objective.id,
        updates: payload
      });
    } else {
      // Creating a new mission - update local objectives
      const updated = objectives.map(o => {
        if (o.id === objective.id) return objective;
        return o;
      });
      setObjectives(updated);
    }
  };

  const deleteObjective = objective => {
    const updated = objectives.filter(o => o.id !== objective.id);
    setObjectives(updated);
    closeDrawer();
  };

  const viewObjective = objective => {
    setSelectedObjective(objective);
    openDrawer();
  };

  const saveUpdates = () => {
    // TODO: Validate objectives? API needs to accept full object
    const errorMessages = validateMission(details);
    if (!errorMessages) {
      setErrors(null);
      dispatch({
        type: missionTypes.UPDATE_MISSION,
        userId,
        missionId,
        updates: {
          ...details,
          objectives
        }
      });
      if (missionId) {
        dispatch({
          type: missionTypes.UPDATE_PROFILE_IMAGE,
          missionId,
          imageFile,
        })
      }
    } else setErrors(errorMessages);
  };

  const confirmArchiveMission = () => {
    dispatch({
      type: missionTypes.ARCHIVE_MISSION,
      userId,
      missionId
    });
  };

  const onClickArchive = () => {
    // Set the modal component
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.CONFIRMATION_MODAL,
      props: {
        title: 'Are you sure you want to archive this mission?',
        message: 'This will also archive all Objectives and Tasks associated with the Mission. This cannot be undone.',
        onSubmit: confirmArchiveMission,
        savingSelector: selectMissionSaving
      }
    });
    // Open the modal
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });
  };

  const onDateChange = value => {
    const updated = {
      ...details,
      endDate: value
    };

    if (errors) {
      const hasError = validateMission({ ...updated });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails(updated);
  };

  const onCreate = () => {
    const errorMessages = validateMission(details);
    if (!errorMessages) {
      setErrors(null);
      dispatch({
        type: missionTypes.CREATE_MISSION,
        userId,
        imageFile,
        mission: {
          ...details,
          objectives
        }
      });
    } else setErrors(errorMessages);
  };

  // Constants
  const { icon, title, description, endDate } = details;
  const detailsComplete = icon && title && description && Boolean(endDate);

  // const infoBarJsx = <InfoBar content="You are creating this Mission as their Leader" />;
  const editActionsJsx = (
    <>
      <Button label="Archive" background="grey" className={classes.mr10} onClick={onClickArchive} />
      <Button label="Save" onClick={saveUpdates} disabled={missionSaving} loading={missionSaving} />
    </>
  );

  const headerActions = missionId ? editActionsJsx : null;

  const handleImageChange = file => setImageFile(file);

  return (
    <>
      <PageHeader routeTitle={`${missionId ? 'Edit' : 'Create'} Mission`} actions={headerActions} showBack />
      <Box component="main">
        <Box id="mission-details" marginBottom={2.5}>
          <MissionDetails
            icon={icon}
            name={title}
            description={description}
            onTextChange={handleTextChange}
            onIconChange={handleIconChange}
            onImageChange={handleImageChange}
            missionId={missionId}
            errors={errors}
            isTemplate={false}
            onDateChange={onDateChange}
            endDate={endDate}
          />
        </Box>
        <Box id="mission-objectives">
          <Objectives
            onAdd={openDrawer}
            objectives={objectives}
            onViewObjective={viewObjective}
            isTemplate={false}
            addDisabled={!detailsComplete}
          />
        </Box>
        {!missionId && (
          <Box marginTop={2} textAlign="center">
            <Button
              label="Save & Assign to Member"
              className={classes.createButton}
              onClick={onCreate}
              loading={missionSaving}
              disabled={missionSaving}
            />
          </Box>
        )}
        <ObjectiveDrawer
          open={drawerOpen}
          onClose={closeDrawer}
          onCreate={addObjective}
          onDelete={deleteObjective}
          selectedObjective={selectedObjective}
          onUpdate={updateObjective}
          missionEnd={endDate}
          isActive={Boolean(selectedObjective?.startDate)}
        />
      </Box>
    </>
  );
};

export default MissionCreateUpdate;
