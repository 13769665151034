import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ToolkitForm from '../ToolkitForm/ToolkitForm';
import Button from '../Button/Button';
import {validateCreateToolkit} from '../../constants/validation';
import * as toolkitTypes from '../../state/toolkits/types';

const styles = makeStyles({
  container: {
    marginBottom: 10
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
});

const CreateToolkit = () => {
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const classes = styles();
  const [values, setValues] = useState({
    content: [],
    heroImage: undefined,
    heroVideo: '',
    title: '',
    description: '',
    categories: []
  });
  const [errors, setErrors] = useState(null);

  const handleSubmit = async () => {
    const errorMessages = validateCreateToolkit(values);

    if (errorMessages === undefined) {
      setErrors(null);

      const formData = new FormData();
      formData.append('categories', JSON.stringify(values.categories))
      formData.append('description', values.description)
      formData.append('heroImage', values.heroImage)
      formData.append('heroVideo', values.heroVideo)
      formData.append('title', values.title)

      if (values?.content) {
        formData.append('content', JSON.stringify(values.content))
      }

      dispatch({
        type: toolkitTypes.CREATE_TOOLKIT,
        communityId,
        payload: formData
      })
    } else {
      setErrors(errorMessages);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{display: 'flex', justifyContent: 'end'}}>
        <Button
          iconPosition="start"
          icon={<AddIcon />}
          label="Create tool"
          onClick={handleSubmit}
          style={{borderRadius:50}}
        />
      </Grid>
      <Grid item xs={12}>
        <ToolkitForm values={values} setValues={setValues} errors={errors} setErrors={setErrors} />
      </Grid>
    </Grid>
  )
}

export default CreateToolkit