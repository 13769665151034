import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  themeName: 'Project Theme',
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: '"SFUIText-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"SFUIDisplay-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 34
    },
    h2: {
      fontFamily: '"SFUIDisplay-Reg", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 24
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 22
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 'bold'
    }
  },
  palette: {
    primary: {
      main: '#072144'
    },
    secondary: {
      main: '#A5721A'
    },
    success: {
      main: '#4ab970'
    },
    warning: {
      main: '#f2b836'
    },
    error: {
      main: '#f20046'
    },
    info: {
      main: '#0079a3'
    },
    disabled: {
      main: '#4f5c6e'
    },
    text: {
      primary: '#223f63',
      secondary: '#223f63'
    },
    background: {
      default: '#ffffff',
      paper: '#fefefe'
    },
    status: {
      inactive: '#B7B7B7',
      'in progress': '#437CC4',
      complete: '#4cd964',
      'needs help': '#EBA628',
      'no longer relevant': 'FF5151'
    },
    // can be refactored to just be based on the 'score' key below
    practiceScore: {
      Great: 'rgba(40 162 82, 1)',
      High: 'rgba(40 162 82, 1)',
      Good: 'rgba(40 162 82, 1)',
      Average: '#f2b836',
      Poor: '#f20046',
      Zero: '#8b8f95'
    },
    score: {
      great: 'rgb(40 162 82)',
      high: 'rgb(40 162 82)',
      good: 'rgb(40 162 82)',
      average: '#fb792b',
      poor: '#f20046',
      zero: '#8b8f95'
    },
    collection: ['#f20046', '#437CC4', '#fb792b', '#4cd964']
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(41, 48, 57, 0.85)'
      }
    },

    MuiAvatar: {
      root: {
        fontSize: 14
      }
    },

    MuiTabs: {
      root: {
        borderBottom: '2px solid #c7cace',
        marginBottom: 20
      },
      indicator: {
        display: 'none'
      }
    },

    MuiInputBase: {
      root: {
        borderColor: '#d2d2d2',
        borderWidth: 1,
        borderStyle: 'solid'
      }
    },

    MuiTab: {
      root: {
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 0
        },
        '&:active': {
          outline: 'none'
        },
        '&:focus': {
          outline: 'none'
        },
        '&:hover': {
          fontWeight: 'bold'
        },
        '&.Mui-selected': {
          fontWeight: 'bold'
        }
      }
    },
    '.MuiTab-root.Mui-selected': {
      root: {
        backgroundColor: 'yellow'
      }
    },
    '.MuiTabs-indicator': {
      display: 'none'
    },
    'Mui-selected': {
      outline: 'none'
    }
  }
});

export default theme;
