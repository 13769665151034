import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import ObjectiveActions from '../components/Objective/ObjectiveActions';
import PageHeader from '../components/PageHeader/PageHeader';
import ObjectiveViewLoading from '../components/Objective/ObjectiveViewLoading';
import ObjectiveChart from '../components/Objective/ObjectiveChart';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';
import ObjectiveTaskDrawer from '../components/Drawers/ObjectiveTaskDrawer';
import ObjectiveTasks from '../components/Objective/ObjectiveTasks';
import ObjectiveComments from '../components/Objective/ObjectiveComments';
import ObjectivePracticeDrawer from '../components/ObjectivePracticeDrawer/ObjectivePracticeDrawer';
import ObjectivePractices from '../components/Objective/ObjectivePratices';
import SpecList from '../components/List/SpecList';

import * as communityMissionTypes from '../state/communityMission/types';
import * as communityTypes from '../state/community/types';
import * as modalTypes from '../state/modal/types';

import useUpdateEffect from '../hooks/useUpdateEffect';

import { selectCommunityUsers } from '../state/community/reducers';
import { selectTeams } from '../state/teams/reducers';
import {
  selectCommunityMission,
  selectCommunityMissionObjective,
  selectCommunityMissionObjectiveSaving,
  selectCommunityMissionObjectivePracticeSaved,
  selectCommunityMissionObjectivePracticeDeleted,
  selectCommunityMissionObjectiveSystemComments
} from '../state/communityMission/reducers';

// import { selectObjectiveSystemComments } from '../state/mission/reducers';

import { selectLoggedInUserId } from '../state/auth/reducers';

const useStyles = makeStyles(theme => ({
  missionTitle: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily,
    color: '#969696',
    marginBottom: 0
  },
  objectiveTitle: {
    fontSize: 34,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10
  }
}));

const CommunityObjectiveView = () => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { communityId, missionId, objectiveId } = useParams();

  // Local state
  const [objectiveChartReload, setObjectiveChartReload] = useState(0);
  const [objectiveDrawer, setObjectiveDrawerOpen] = useState(false);
  const [practiceDrawerOpen, setPracticeDrawerOpen] = useState(false);
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState(null);

  const [currentEditTask, setCurrentEditTask] = useState();

  // Redux
  const authUser = useSelector(selectLoggedInUserId);
  const mission = useSelector(selectCommunityMission);
  const objective = useSelector(selectCommunityMissionObjective);
  const objectiveSaving = useSelector(state => selectCommunityMissionObjectiveSaving(state));
  const communityUsers = useSelector(selectCommunityUsers);
  const teams = useSelector(selectTeams);
  const activity = useSelector(state => selectCommunityMissionObjectiveSystemComments(state, objectiveId));

  let teamUsers = teams.reduce((all, curr) => {
    if (mission && mission.teams.includes(curr.id)) {
      all = [...all, ...curr.users];
    }

    return all;
  }, []);

  // remove duplication by id
  teamUsers = teamUsers.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  const practiceSaved = useSelector(selectCommunityMissionObjectivePracticeSaved);
  const practiceDeleted = useSelector(selectCommunityMissionObjectivePracticeDeleted);
  const isCreatingTask = useSelector(state => state.communityMissions.isCreatingTask);

  const readOnly = !mission || authUser !== mission.leader?._id;

  const assignedUserObject = objective?.user ? communityUsers.find(u => u._id === objective.user) : null;

  // Remove empty comments
  const cleanComments = objective?.comments.filter(c => Boolean(c.comment)) || [];

  // Objective comments and system comments living in harmony
  const computedComments = cleanComments.sort((a, b) => moment(a.createdOn).diff(b.createdOn));

  // Helpers
  const openObjectiveDrawer = () => setObjectiveDrawerOpen(true);

  // const closeObjectiveDrawer = () => {};

  const openPracticeDrawer = () => setPracticeDrawerOpen(true);
  const closePracticeDrawer = () => {
    setPracticeDrawerOpen(false);
    setSelectedPractice(null);
  };

  const setModalComponent = (componentType, props) =>
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: componentType,
      props
    });

  const openModal = () =>
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });

  // Event handlers
  const handleStatusChange = status => {
    dispatch({
      type: communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS,
      communityId,
      objectiveId,
      status
    });
  };

  const onConfirmArchive = () => {
    dispatch({
      type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_OBJECTIVE,
      communityId,
      missionId
    });
  };

  const handleObjectiveDelete = () => {
    // Set the modal component
    setModalComponent(modalTypes.CONFIRMATION_MODAL, {
      title: 'Are you sure you want to archive this objective?',
      message: 'This will also archive all associated tasks. This cannot be undone.',
      onSubmit: onConfirmArchive
    });

    openModal();
  };

  const handleObjectiveUpdate = updates => {
    const { status, comments, ...rest } = updates;

    dispatch({
      type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE,
      communityId,
      objectiveId,
      updates: rest
    });
  };

  const handlePracticeEdit = practice => {
    setSelectedPractice(practice);
    openPracticeDrawer();
  };

  const handlePracticeDelete = practice => {
    dispatch({
      type: communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
      communityId,
      practiceId: practice.id
    });
  };

  // Effects
  useEffect(() => {
    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSION,
      communityId,
      missionId
    });
    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE,
      communityId,
      objectiveId
    });

    dispatch({
      type: communityTypes.GET_COMMUNITY_USERS,
      communityId
    });

    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS,
      communityId,
      objectiveId
    });
  }, []);

  useUpdateEffect(() => {
    if (practiceSaved || practiceDeleted) {
      setObjectiveChartReload(objectiveChartReload + 1);
    }
  }, [practiceSaved, practiceDeleted]);

  // Don't render the view until there is something to see
  if (!objective || !mission || mission?.id !== missionId || objective?.id !== objectiveId) {
    return <ObjectiveViewLoading />;
  }

  const title = `${objective.statement.verb.charAt(0).toUpperCase()}${objective.statement.verb.slice(1)} ${
    objective.statement.noun
  }`;

  // get simple references to entities for reference in the drawers
  const entityReferences = objective.tasks.reduce((acc, entity) => {
    acc[entity.entity] = {
      title: entity.title
    };
    return acc;
  }, {});

  return (
    <>
      <PageHeader routeTitle="Objective" />

      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
          <Typography className={classes.missionTitle}>Mission: {mission.title}</Typography>
          <Typography className={classes.objectiveTitle}>Objective: {title}</Typography>
          <Typography style={{ marginBottom: 10 }}>In order to {objective.statement.outcome}</Typography>
          <SpecList
            size="small"
            data={[
              {
                label: objective.startDate ? 'Started' : 'Created',
                value: objective.startDate
                  ? moment(objective.startDate).format('LL')
                  : moment(objective.createdAt).format('LL')
              },
              { label: 'Due', value: moment(objective.statement.dueDate).format('LL') }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
          <ObjectiveActions
            readOnly={readOnly}
            saving={objectiveSaving}
            entities={entityReferences}
            activity={activity}
            objectiveStatus={objective.status}
            onStatusChange={handleStatusChange}
            onEdit={openObjectiveDrawer}
          />
          {assignedUserObject && (
            <Box display="flex" justifyContent="flex-end">
              <Box className={classes.userName}>
                <div>
                  {assignedUserObject.firstName}
                  <br />
                  {assignedUserObject.lastName}
                </div>
              </Box>
              <Avatar
                alt={`${assignedUserObject.firstName} ${assignedUserObject.lastName}`}
                src={assignedUserObject.avatar?.sm}
                style={{ marginLeft: 10, display: 'flex' }}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      {objective.tasks && (
        <ObjectiveChart
          objectiveId={objective.id}
          practices={objective.practices}
          tasks={objective.tasks}
          reload={objectiveChartReload}
        />
      )}

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} lg={4}>
          <ObjectiveTasks
            readOnly={readOnly}
            tasks={objective.tasks}
            onAddTask={() => setTaskDrawerOpen(true)}
            isCreating={isCreatingTask}
            communityId={communityId}
            isCommunity
            onEditTask={task => {
              setCurrentEditTask(task);
              setTaskDrawerOpen(true);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ObjectivePractices
            readOnly={readOnly}
            practices={objective.practices}
            onAddPractice={openPracticeDrawer}
            onEditPractice={handlePracticeEdit}
            onDeletePractice={handlePracticeDelete}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ObjectiveComments comments={computedComments} />
        </Grid>
      </Grid>

      {!readOnly && objective && (
        <>
          <ObjectiveDrawer
            open={objectiveDrawer}
            onClose={() => {
              setCurrentEditTask();
              setObjectiveDrawerOpen(false);
            }}
            selectedObjective={objective}
            onDelete={handleObjectiveDelete}
            onUpdate={handleObjectiveUpdate}
            communityUsers={teamUsers}
            isCommunity
          />

          <ObjectivePracticeDrawer
            open={practiceDrawerOpen}
            onClose={closePracticeDrawer}
            selectedObjective={objective}
            selectedPractice={selectedPractice}
            isCommunity
            communityId={communityId}
          />

          <ObjectiveTaskDrawer
            task={currentEditTask}
            statement={objective.statement}
            width={500}
            open={taskDrawerOpen}
            onSave={task => {
              dispatch({
                type: communityMissionTypes.CREATE_COMMUNITY_TASK,
                communityId,
                task,
                objectiveId
              });

              if (objective.status === 'complete') {
                objective.status = 'in progress'
              }

              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
            onUpdate={task => {
              dispatch({
                type: communityMissionTypes.UPDATE_COMMUNITY_TASK,
                communityId,
                task,
                objectiveId
              });
              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
            onClose={() => {
              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default CommunityObjectiveView;
