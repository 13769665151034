import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiDataGrid-row': {},
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: 12,
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-cell': {
      fontSize: 12,
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: 'solid',
      borderColor: '#aaaaaa',
      flex: 1,
      width: '100%',
      marginBottom: 10,
      height: 52,
      maxHeight: 52
    }
  }
}));

const getAvatarColor = (colors, index) => {
  const current = index % colors.length;
  return colors[current];
};

const CommunityMissionsList = ({
  theme,
  loading,
  communityMissions,
  pagination,
  onSortChange,
  onPageChange,
  onRowClick
}) => {
  const classes = useStyles();
  const columns = [
    {
      field: 'title',
      headerName: 'Mission',
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.title}</Typography>;
      }
    },
    {
      field: 'teams',
      headerName: 'Assigned teams',
      renderCell: ({ row }) => {
        return (
          <AvatarGroup max={6}>
            {row.teams.map((i, index) => (
              <Avatar style={{ backgroundColor: getAvatarColor(theme.palette.collection, index) }} key={i.id}>
                {i.title.match(/\b\w/g).join('')}
              </Avatar>
            ))}
          </AvatarGroup>
        );
      },
      flex: 0.5,
      sortable: false
    },
    {
      field: 'leader',
      headerName: 'Leader',
      flex: 1
    },
    {
      field: 'objectives',
      headerName: 'Objectives',
      flex: 0.5
    },
    {
      field: 'endDate',
      headerName: 'End date',
      flex: 0.3
    }
  ];

  const rows = communityMissions.map(communityMission => {
    return {
      id: communityMission.id,
      title: communityMission.title,
      teams: communityMission.teams,
      objectives: communityMission.objectives.length,
      endDate: moment(communityMission.endDate).format('D MMM YY') || '',
      leader: communityMission.leader
        ? `${communityMission.leader.firstName} ${communityMission.leader.lastName}`
        : 'None'
    };
  });

  const CustomNoRowsOverlay = () => (
    <Box alignItems="flex-end" justifyContent="center" padding={4} display="flex" style={{ height: 200 }}>
      There are currently no community missions in this community
    </Box>
  );

  return (
    <Box>
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay
        }}
        row
        hideFooter
        rowHeight={72}
        className={classes.root}
        loading={loading}
        rows={rows}
        sortingMode="server"
        onSortModelChange={onSortChange}
        columns={columns}
        rowsPerPageOptions={[]}
        sortingOrder={['asc', 'desc']}
        disableColumnMenu
        autoHeight
        hideFooterPagination
        onRowClick={onRowClick}
      />

      {pagination && pagination.totalPages > 1 && (
        <Box display="flex" justifyContent="center" m={3}>
          <Pagination count={pagination.totalPages} page={pagination.page} onChange={(e, v) => onPageChange(v)} />
        </Box>
      )}
    </Box>
  );
};

CommunityMissionsList.propTypes = {
  loading: PropTypes.bool,
  communityMissions: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func
};

CommunityMissionsList.defaultProps = {
  loading: false,
  pagination: null,
  onSortChange: null,
  onPageChange: null,
  onRowClick: null
};

export default withTheme(CommunityMissionsList);
