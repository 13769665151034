import validate from 'validate.js';

const constraints = {
  // Login constraint
  login: {
    email: {
      email: {
        message: '^Please enter a valid email.'
      },
      presence: {
        message: '^You must enter your email.',
        allowEmpty: false
      }
    },
    password: {
      presence: {
        message: '^You must enter your password.',
        allowEmpty: false
      }
    }
  },

  // Email constraint
  email: {
    email: {
      email: {
        message: '^Please enter a valid email.'
      },
      presence: {
        message: '^You must enter your email.',
        allowEmpty: false
      }
    }
  },

  // Accept invitation
  invitation: {
    firstName: {
      presence: {
        message: '^You must enter your first name.',
        allowEmpty: false
      }
    },
    lastName: {
      presence: {
        message: '^You must enter your last name.',
        allowEmpty: false
      }
    },
    password: {
      presence: {
        message: '^You must enter your password.',
        allowEmpty: false
      },
      length: {
        minimum: 8,
        message: '^Your password must be a minimum of 8 characters.'
      }
    },
    passwordConfirm: {
      presence: {
        message: '^You must confirm your password',
        allowEmpty: false
      },
      equality: {
        attribute: 'password',
        message: '^Your passwords do not match.'
      }
    }
  },

  // Set new password constraint
  newPassword: {
    password: {
      presence: {
        message: '^You must enter a new password',
        allowEmpty: false
      },
      length: {
        minimum: 8,
        message: '^Your password must be a minimum of 8 characters.'
      }
    },
    passwordConfirm: {
      presence: {
        message: '^You must confirm your password',
        allowEmpty: false
      },
      equality: {
        attribute: 'password',
        message: '^Your passwords do not match.'
      }
    }
  },

  // Edit user constraints
  editUser: {
    firstName: {
      presence: {
        message: '^You must include a first name.',
        allowEmpty: false
      }
    },
    lastName: {
      presence: {
        message: '^You must include a last name',
        allowEmpty: false
      }
    }
  },

  // Create user constraints
  createUser: {
    firstName: {
      presence: {
        message: '^You must include a first name.',
        allowEmpty: false
      }
    },
    lastName: {
      presence: {
        message: '^You must include a last name',
        allowEmpty: false
      }
    },
    email: {
      email: {
        message: '^Please enter a valid email.'
      },
      presence: {
        message: '^You must enter an email.',
        allowEmpty: false
      }
    }
  },

  // Create toolkit constraints
  createToolkit: {
    title: {
      presence: {
        message: '^You must include a title.',
        allowEmpty: false
      }
    },
    description: {
      presence: {
        message: '^You must include a summary',
        allowEmpty: false
      }
    },
  },

  // Create team constraint
  createTeam: {
    title: {
      presence: {
        message: '^You must include a team name.',
        allowEmpty: false
      }
    },
    description: {
      presence: {
        message: '^You must include a team description.',
        allowEmpty: false
      }
    }
  },

  // Create mission template constraint
  createMissionTemplate: {
    icon: {
      presence: {
        message: '^You must choose an icon',
        allowEmpty: false
      }
    },
    title: {
      presence: {
        message: '^You must include a mission name.',
        allowEmpty: false
      }
    },
    description: {
      presence: {
        message: '^You must include a mission description.',
        allowEmpty: false
      }
    },
    startDays: {
      presence: {
        message: '^You must include an amount of days.',
        allowEmpty: false
      }
    }
  },

  // Create objective constraint
  createObjectiveTemplate: {
    verb: {
      presence: {
        message: '^You must specify an action.',
        allowEmpty: false
      }
    },
    noun: {
      presence: {
        message: '^You must specify a thing.',
        allowEmpty: false
      }
    },
    daysOnceActive: {
      presence: {
        message: '^You must specify an amount of days.',
        allowEmpty: false
      }
    },
    outcome: {
      presence: {
        message: '^You must specify an outcome',
        allowEmpty: false
      }
    }
  },

  // Create mission template constraint
  createMission: {
    icon: {
      presence: {
        message: '^You must choose an icon',
        allowEmpty: false
      }
    },
    title: {
      presence: {
        message: '^You must include a mission name.',
        allowEmpty: false
      }
    },
    description: {
      presence: {
        message: '^You must include a mission description.',
        allowEmpty: false
      }
    },
    endDate: {
      presence: {
        message: '^You must include an end date.',
        allowEmpty: false
      }
    }
  },

  createObjective: {
    verb: {
      presence: {
        message: '^You must specify an action.',
        allowEmpty: false
      }
    },
    noun: {
      presence: {
        message: '^You must specify a thing.',
        allowEmpty: false
      }
    },
    dueDate: {
      presence: {
        message: '^You must specify a due date.',
        allowEmpty: false
      }
    },
    outcome: {
      presence: {
        message: '^You must specify an outcome',
        allowEmpty: false
      }
    },
    startDate: {
      presence: {
        message: '^You must specify an activation date',
        allowEmpty: false
      }
    }
  },

  updateActiveObjective: {
    verb: {
      presence: {
        message: '^You must specify an action.',
        allowEmpty: false
      }
    },
    noun: {
      presence: {
        message: '^You must specify a thing.',
        allowEmpty: false
      }
    },
    outcome: {
      presence: {
        message: '^You must specify an outcome',
        allowEmpty: false
      }
    },
    dueDate: {
      presence: {
        message: '^You must specify a due date',
        allowEmpty: false
      }
    }
  },

  // Practice
  createObjectivePractice: {
    title: {
      presence: {
        message: '^You must include a title.',
        allowEmpty: false
      }
    },
    description: {
      presence: {
        message: '^You must include a description.',
        allowEmpty: false
      }
    },
    richDescription: {
      presence: {
        message: '^You must include a description.',
        allowEmpty: false
      }
    },
    countPerWeek: {
      presence: {
        message: '^You must include a number.',
        allowEmpty: false,
        numericality: true
      }
    }
  }
};

// Helpers
export const getErrorMessage = (errors, field) => {
  if (errors === null || errors === undefined) return '';
  if (errors[field]) return errors[field][0];
  if (Array.isArray(errors)) {
    const apiError = errors.find(e => e.key === field);
    if (apiError) return apiError.message;
  }
  return '';
};

export const parseApiErrors = (apiErrors, type) => {
  if (apiErrors === null) return null;
  const { key, errors } = apiErrors;
  if (key === type) return errors;
  return null;
};

export const validateLogin = formValues => {
  const errors = validate({ ...formValues }, constraints.login);
  return errors;
};

export const validateEmailFlat = email => {
  const error = validate({ email }, constraints.email, { format: 'flat' });
  return error;
};

export const validateEmail = email => {
  const error = validate({ email }, constraints.email);
  return error;
};

export const validateNewPassword = formValues => {
  const error = validate({ ...formValues }, constraints.newPassword);
  return error;
};

export const validateInvitation = formValues => {
  const errors = validate({ ...formValues }, constraints.invitation);
  return errors;
};

export const validateEditUser = formValues => {
  const error = validate({ ...formValues }, constraints.editUser);
  return error;
};

export const validateCreateUser = formValues => {
  const error = validate({ ...formValues }, constraints.createUser);
  return error;
};

export const validateCreateToolkit = formValues => {
  const error = validate({ ...formValues }, constraints.createToolkit);
  return error;
};

export const validateCreateTeam = formValues => {
  const error = validate({ ...formValues }, constraints.createTeam);
  return error;
};

export const validateMissionTemplate = formValues =>
  validate({ ...formValues }, constraints.createMissionTemplate);

export const validateMissionTemplateObjective = formValues =>
  validate({ ...formValues }, constraints.createObjectiveTemplate);

export const validateMission = formValues =>
  validate({ ...formValues }, constraints.createMission);

export const validateNewMissionObjective = formValues =>
  validate({ ...formValues }, constraints.createObjective);

export const validateActiveMissionObjective = formValues =>
  validate({ ...formValues }, constraints.updateActiveObjective);

export const validateObjectivePractice = formValues =>
  validate({ ...formValues }, constraints.createObjectivePractice);
