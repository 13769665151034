import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AuthGuardian from './AuthGuardian';
import PageContainer from '../components/PageContainer/PageContainer';

import { selectIsOfficer } from '../state/auth/reducers';
import { selectCurrentUserCommunityPermissions } from '../state/community/reducers';

export default function AuthenticatedView({ permissions, component: Component, routeTitle, childRoute, showHeader }) {
  // Redux
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const currentPermissions = useSelector(state => selectCurrentUserCommunityPermissions(state));

  // Helper constants
  const hasPermission = currentPermissions.some(p => permissions.some(rp => rp === p));
  const readOnly = (isOfficer && !hasPermission) || false;

  // If logged in - display component otherwise boot them to login with redirect
  return (
    <AuthGuardian routePermissions={permissions}>
      <PageContainer routeTitle={routeTitle} child={childRoute} showHeader={showHeader}>
        <Component readOnly={readOnly} />
      </PageContainer>
    </AuthGuardian>
  );
}

AuthenticatedView.propTypes = {
  permissions: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  routeTitle: PropTypes.string.isRequired,
  childRoute: PropTypes.bool,
  showHeader: PropTypes.bool
};

AuthenticatedView.defaultProps = {
  permissions: [],
  childRoute: false,
  showHeader: true
};
