/* eslint-disable import/no-dynamic-require */
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import config from '../config';

let _firebaseApp = null;

/**
 * Service worker init
 */
export const registerServiceWorker = async () => {
  if (!('serviceWorker' in navigator)) {
    return;
  }
  if (!('PushManager' in window)) {
    return;
  }

  // at this stage we have what we need
  try {
    await navigator.serviceWorker.register('./firebase-messaging-sw.js');
    console.log('Service worker successfully registered.');
  } catch (error) {
    console.error('Unable to register service worker.', error);
    throw error;
  }
};

/**
 * Messaging handler init
 */

let _db = null;
export const fbDb = () => _db;

export const initFirebase = () => {
  if (!_firebaseApp) {
    /*
    if (process.env.REACT_APP_FIREBASE_CLIENT) { 
      // uncomment these lines when running locally
      // eslint-disable-next-line global-require
      // const creds = require('../../credentials/onmission-client-credential-test.json');
      // _firebaseApp = firebase.initializeApp(creds);
    } else {
      _firebaseApp = firebase.initializeApp(window.__env__.REACT_APP_FIREBASE_CLIENT);
    }
    */

    const reactAppFirebaseClient = window.__env__?.REACT_APP_FIREBASE_CLIENT
      ? window.__env__.REACT_APP_FIREBASE_CLIENT
      : process.env.REACT_APP_FIREBASE_CLIENT;

    _firebaseApp = firebase.initializeApp(JSON.parse(reactAppFirebaseClient));
    firebase.analytics();
  } else {
    console.log('firebase already initialized');
  }
};

export const initFirebaseChat = async customToken => {
  try {
    // firebase.auth().tenantId = process.env.REACT_APP_TENANT_ID || window.__env__.REACT_APP_TENANT_ID;
    // sign in with custom token
    await firebase.auth().signInWithCustomToken(customToken);

    // Initialize firestore for chat
    _db = _firebaseApp.firestore();
  } catch (error) {
    console.info('DEBUG: initFirebaseChat -> error', error);
    throw error;
  }
};
