import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Typography from '@material-ui/core/Typography';

import QuestionBox from '../components/CommunityPsychFramework/QuestionBox';
import ValueBuilder from '../components/CommunityPsychFramework/ValueBuilder';

import * as communityTypes from '../state/community/types';
import { selectSelectedCommunity } from '../state/community/reducers';

const initialObject = {
  value: '',
  rating: 1
};

const initialValuesObject = {
  value: '',
  rating: 10,
  standards: [
    {
      value: '',
      rating: 10
    }
  ]
};

const humanReadable = {
  purpose: 'Purpose',
  mission: 'Mission',
  vision: 'Vision',
  values: 'Values & Standards',
  oneWord: 'One Word',
  philosophy: 'Philosophy'
};

// Used to inject blank values and standards (required in builder component)
const buildValues = values => {
  const newValues = values.map(v => {
    const standards = [...v.standards, { value: '', rating: 10 }];
    return { ...v, standards };
  });
  return [...newValues, { ...initialValuesObject }];
};

class PsychFramework extends Component {
  constructor(props) {
    super(props);

    // Set the framework values on load
    const { selectedCommunity } = props;
    const { psychFramework } = selectedCommunity;

    this.state = {
      purpose: psychFramework.purpose || initialObject,
      mission: psychFramework.mission || initialObject,
      vision: psychFramework.vision || initialObject,
      valuesStandards:
        psychFramework.values.length > 0
          ? buildValues(psychFramework.values)
          : [{ ...initialValuesObject }],
      oneWord: psychFramework.oneWord || '',
      philosophy: psychFramework.philosophy || ''
    };
  }

  onWordChange = e => {
    const { value, name } = e.target;
    this.setState({
      [name]: value
    });
  };

  onTextChange = e => {
    const { value, name } = e.target;
    this.setState(prev => ({
      [name]: {
        ...prev[name],
        value
      }
    }));
  };

  onRatingChange = e => {
    const { value, name } = e.target;
    this.setState(prev => ({
      [name]: {
        ...prev[name],
        rating: value
      }
    }));
  };

  onValueSubmit = values => {
    const { updatePsychFramework } = this.props;
    updatePsychFramework('values', { values }, humanReadable.values);
  };

  onSubmit = key => {
    const { updatePsychFramework } = this.props;
    const { [key]: updates } = this.state;

    // Pass human readble for success snack
    const name = humanReadable[key];
    updatePsychFramework(key, { [key]: updates }, name);
  };

  render() {
    const { readOnly } = this.props;
    const {
      purpose,
      mission,
      vision,
      valuesStandards,
      oneWord,
      philosophy
    } = this.state;

    return (
      <div>
        <Typography style={{ maxWidth: 480, fontSize: 14, marginBottom: 40 }}>
          Your community code defines what your community stands for, their purpose,
          mission, and vision.
        </Typography>
        <QuestionBox
          title="Purpose"
          label="Purpose"
          description="Your community’s purpose - why does it exist and who does it serve."
          inputName="purpose"
          textValue={purpose.value || ''}
          onTextChange={this.onTextChange}
          multiline
          showRating
          rating={purpose.rating}
          onRatingChange={this.onRatingChange}
          onSubmit={this.onSubmit}
          readOnly={readOnly}
        />
        <QuestionBox
          title="Mission"
          label="Mission"
          description="Your mission defines what the community wants to accomplish."
          inputName="mission"
          textValue={mission.value || ''}
          onTextChange={this.onTextChange}
          multiline
          showRating
          rating={mission.rating}
          onRatingChange={this.onRatingChange}
          onSubmit={this.onSubmit}
          readOnly={readOnly}
        />
        <QuestionBox
          title="Vision"
          label="Vision"
          description="The destination of your mission, led by your purpose."
          inputName="vision"
          textValue={vision.value || ''}
          onTextChange={this.onTextChange}
          multiline
          showRating
          rating={vision.rating}
          onRatingChange={this.onRatingChange}
          onSubmit={this.onSubmit}
          readOnly={readOnly}
        />
        <ValueBuilder
          description="The unwavering beliefs in what the community values."
          comValues={valuesStandards}
          onSubmit={this.onValueSubmit}
          readOnly={readOnly}
        />
        <QuestionBox
          title="One Word"
          label="One Word"
          description="One word that defines this community"
          inputName="oneWord"
          textValue={oneWord}
          onTextChange={this.onWordChange}
          onSubmit={this.onSubmit}
          readOnly={readOnly}
        />
        <QuestionBox
          title="Philosophy"
          label="Philosophy"
          description="The philosophy of the community."
          inputName="philosophy"
          textValue={philosophy}
          multiline
          onTextChange={this.onWordChange}
          onSubmit={this.onSubmit}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

PsychFramework.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  selectedCommunity: PropTypes.object.isRequired,
  updatePsychFramework: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedCommunity: selectSelectedCommunity(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePsychFramework: (key, updates, name) => ({
        type: communityTypes.UPDATE_COMMUNITY_PSYCH_FRAMEWORK,
        key,
        updates,
        name
      })
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PsychFramework);
