import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  noTasksWrap: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    padding: 8,
    height: 430
  },
  noTasksInner: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  noTasksText: {
    fontSize: 14,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    maxWidth: 320,
    marginBottom: 20,
    textAlign: 'center'
  }
});

const NoEntityBox = ({ children, text, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.noTasksWrap} {...props}>
      <Box className={classes.noTasksInner}>
        <Typography className={classes.noTasksText}>{text}</Typography>
        {children}
      </Box>
    </Box>
  );
};

NoEntityBox.propTypes = {
  text: PropTypes.string,
  children: PropTypes.any
};

NoEntityBox.defaultProps = {
  text: 'No info to display',
  children: undefined
};

export default NoEntityBox;
