import * as types from './types';

const initialState = {
  loadingState: false,
  userId: '',
  loggedInUser: null,
  timestamp: null,
  passwordRequested: false,
  savingState: false,
  passwordReset: false,
  inviteAccepted: false,
  emailUpdated: false,
  apiErrors: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.userId,
        timestamp: new Date().toISOString(),
        apiErrors: null
      };

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordRequested: true
      };

    case types.CLEAR_RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        passwordRequested: false
      };

    case types.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true
      };

    case types.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        passwordReset: false
      };

    case types.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        userId: '',
        loggedInUser: null,
        timestamp: null
      };

    case types.GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        loggedInUser: action.user
      };

    case types.AUTH_SAVING_STATE:
      return {
        ...state,
        savingState: action.state
      };

    case types.ACCEPT_INVITATION_SUCCESS: {
      return {
        ...state,
        inviteAccepted: true
      };
    }

    case types.CLEAR_ACCEPT_INVITE:
      return {
        ...state,
        inviteAccepted: false
      };

    case types.ACCEPT_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        emailUpdated: true
      };

    case types.CLEAR_EMAIL_CHANGE:
      return {
        ...state,
        emailUpdated: false
      };

    case types.AUTH_LOGIN_ERROR:
    case types.UPDATE_OWN_PROFILE_ERROR:
    case types.CHANGE_OWN_PASSWORD_ERROR:
    case types.ACCEPT_EMAIL_CHANGE_ERROR:
      return {
        ...state,
        apiErrors: action.errors
      };

    case types.UPDATE_OWN_PROFILE_SUCCESS:
      return {
        ...state,
        apiErrors: null
      };

    default:
      return state;
  }
};

export default authReducer;

export const selectAuthLoadingState = state => state.auth.loadingState;
export const selectLoggedInUserId = state => state.auth.userId;
export const selectLoggedInUser = state => state.auth.loggedInUser;
export const selectIsOfficer = state =>
  state.auth.loggedInUser
    ? state.auth.loggedInUser.roles.some(role => role === 'officer')
    : false;
export const selectAuthTimestamp = state => state.auth.timeStamp;
export const selectResetPasswordEmail = state => state.auth.resetPasswordEmail;
export const selectAuthSavingState = state => state.auth.savingState;
export const selectPasswordRequested = state => state.auth.passwordRequested;
export const selectPasswordReset = state => state.auth.passwordReset;
export const selectInviteAccepted = state => state.auth.inviteAccepted;
export const selectAuthApiErrors = state => state.auth.apiErrors;
export const selectEmailChange = state => state.auth.emailUpdated;
