export default [
  {name:'ad', type: 'fa'},
  {name:'address-book', type: 'fa'},
  {name:'address-card', type: 'fa'},
  {name:'adjust', type: 'fa'},
  {name:'air-freshener', type: 'fa'},
  {name:'align-center', type: 'fa'},
  {name:'align-justify', type: 'fa'},
  {name:'align-left', type: 'fa'},
  {name:'align-right', type: 'fa'},
  {name:'allergies', type: 'fa'},
  {name:'ambulance', type: 'fa'},
  {name:'american-sign-language-interpreting', type: 'fa'},
  {name:'anchor', type: 'fa'},
  {name:'angle-double-down', type: 'fa'},
  {name:'angle-double-left', type: 'fa'},
  {name:'angle-double-right', type: 'fa'},
  {name:'angle-double-up', type: 'fa'},
  {name:'angle-down', type: 'fa'},
  {name:'angle-left', type: 'fa'},
  {name:'angle-right', type: 'fa'},
  {name:'angle-up', type: 'fa'},
  {name:'angry', type: 'fa'},
  {name:'ankh', type: 'fa'},
  {name:'apple-alt', type: 'fa'},
  {name:'archive', type: 'fa'},
  {name:'archway', type: 'fa'},
  {name:'arrow-alt-circle-down', type: 'fa'},
  {name:'arrow-alt-circle-left', type: 'fa'},
  {name:'arrow-alt-circle-right', type: 'fa'},
  {name:'arrow-alt-circle-up', type: 'fa'},
  {name:'arrow-circle-down', type: 'fa'},
  {name:'arrow-circle-left', type: 'fa'},
  {name:'arrow-circle-right', type: 'fa'},
  {name:'arrow-circle-up', type: 'fa'},
  {name:'arrow-down', type: 'fa'},
  {name:'arrow-left', type: 'fa'},
  {name:'arrow-right', type: 'fa'},
  {name:'arrow-up', type: 'fa'},
  {name:'arrows-alt', type: 'fa'},
  {name:'arrows-alt-h', type: 'fa'},
  {name:'arrows-alt-v', type: 'fa'},
  {name:'assistive-listening-systems', type: 'fa'},
  {name:'asterisk', type: 'fa'},
  {name:'at', type: 'fa'},
  {name:'atlas', type: 'fa'},
  {name:'atom', type: 'fa'},
  {name:'audio-description', type: 'fa'},
  {name:'award', type: 'fa'},
  {name:'baby', type: 'fa'},
  {name:'baby-carriage', type: 'fa'},
  {name:'backspace', type: 'fa'},
  {name:'backward', type: 'fa'},
  {name:'bacon', type: 'fa'},
  {name:'bahai', type: 'fa'},
  {name:'balance-scale', type: 'fa'},
  {name:'balance-scale-left', type: 'fa'},
  {name:'balance-scale-right', type: 'fa'},
  {name:'ban', type: 'fa'},
  {name:'band-aid', type: 'fa'},
  {name:'barcode', type: 'fa'},
  {name:'bars', type: 'fa'},
  {name:'baseball-ball', type: 'fa'},
  {name:'basketball-ball', type: 'fa'},
  {name:'bath', type: 'fa'},
  {name:'battery-empty', type: 'fa'},
  {name:'battery-full', type: 'fa'},
  {name:'battery-half', type: 'fa'},
  {name:'battery-quarter', type: 'fa'},
  {name:'battery-three-quarters', type: 'fa'},
  {name:'bed', type: 'fa'},
  {name:'beer', type: 'fa'},
  {name:'bell', type: 'fa'},
  {name:'bell-slash', type: 'fa'},
  {name:'bezier-curve', type: 'fa'},
  {name:'bible', type: 'fa'},
  {name:'bicycle', type: 'fa'},
  {name:'biking', type: 'fa'},
  {name:'binoculars', type: 'fa'},
  {name:'biohazard', type: 'fa'},
  {name:'birthday-cake', type: 'fa'},
  {name:'blender', type: 'fa'},
  {name:'blender-phone', type: 'fa'},
  {name:'blind', type: 'fa'},
  {name:'blog', type: 'fa'},
  {name:'bold', type: 'fa'},
  {name:'bolt', type: 'fa'},
  {name:'bomb', type: 'fa'},
  {name:'bone', type: 'fa'},
  {name:'bong', type: 'fa'},
  {name:'book', type: 'fa'},
  {name:'book-dead', type: 'fa'},
  {name:'book-medical', type: 'fa'},
  {name:'book-open', type: 'fa'},
  {name:'book-reader', type: 'fa'},
  {name:'bookmark', type: 'fa'},
  {name:'border-all', type: 'fa'},
  {name:'border-none', type: 'fa'},
  {name:'border-style', type: 'fa'},
  {name:'bowling-ball', type: 'fa'},
  {name:'box', type: 'fa'},
  {name:'box-open', type: 'fa'},
  {name:'boxes', type: 'fa'},
  {name:'braille', type: 'fa'},
  {name:'brain', type: 'fa'},
  {name:'bread-slice', type: 'fa'},
  {name:'briefcase', type: 'fa'},
  {name:'briefcase-medical', type: 'fa'},
  {name:'broadcast-tower', type: 'fa'},
  {name:'broom', type: 'fa'},
  {name:'brush', type: 'fa'},
  {name:'bug', type: 'fa'},
  {name:'building', type: 'fa'},
  {name:'bullhorn', type: 'fa'},
  {name:'bullseye', type: 'fa'},
  {name:'burn', type: 'fa'},
  {name:'bus', type: 'fa'},
  {name:'bus-alt', type: 'fa'},
  {name:'business-time', type: 'fa'},
  {name:'calculator', type: 'fa'},
  {name:'calendar', type: 'fa'},
  {name:'calendar-alt', type: 'fa'},
  {name:'calendar-check', type: 'fa'},
  {name:'calendar-day', type: 'fa'},
  {name:'calendar-minus', type: 'fa'},
  {name:'calendar-plus', type: 'fa'},
  {name:'calendar-times', type: 'fa'},
  {name:'calendar-week', type: 'fa'},
  {name:'camera', type: 'fa'},
  {name:'camera-retro', type: 'fa'},
  {name:'campground', type: 'fa'},
  {name:'candy-cane', type: 'fa'},
  {name:'cannabis', type: 'fa'},
  {name:'capsules', type: 'fa'},
  {name:'car', type: 'fa'},
  {name:'car-alt', type: 'fa'},
  {name:'car-battery', type: 'fa'},
  {name:'car-crash', type: 'fa'},
  {name:'car-side', type: 'fa'},
  {name:'caravan', type: 'fa'},
  {name:'caret-down', type: 'fa'},
  {name:'caret-left', type: 'fa'},
  {name:'caret-right', type: 'fa'},
  {name:'caret-square-down', type: 'fa'},
  {name:'caret-square-left', type: 'fa'},
  {name:'caret-square-right', type: 'fa'},
  {name:'caret-square-up', type: 'fa'},
  {name:'caret-up', type: 'fa'},
  {name:'carrot', type: 'fa'},
  {name:'cart-arrow-down', type: 'fa'},
  {name:'cart-plus', type: 'fa'},
  {name:'cash-register', type: 'fa'},
  {name:'cat', type: 'fa'},
  {name:'certificate', type: 'fa'},
  {name:'chair', type: 'fa'},
  {name:'chalkboard', type: 'fa'},
  {name:'chalkboard-teacher', type: 'fa'},
  {name:'charging-station', type: 'fa'},
  {name:'chart-area', type: 'fa'},
  {name:'chart-bar', type: 'fa'},
  {name:'chart-line', type: 'fa'},
  {name:'chart-pie', type: 'fa'},
  {name:'check', type: 'fa'},
  {name:'check-circle', type: 'fa'},
  {name:'check-double', type: 'fa'},
  {name:'check-square', type: 'fa'},
  {name:'cheese', type: 'fa'},
  {name:'chess', type: 'fa'},
  {name:'chess-bishop', type: 'fa'},
  {name:'chess-board', type: 'fa'},
  {name:'chess-king', type: 'fa'},
  {name:'chess-knight', type: 'fa'},
  {name:'chess-pawn', type: 'fa'},
  {name:'chess-queen', type: 'fa'},
  {name:'chess-rook', type: 'fa'},
  {name:'chevron-circle-down', type: 'fa'},
  {name:'chevron-circle-left', type: 'fa'},
  {name:'chevron-circle-right', type: 'fa'},
  {name:'chevron-circle-up', type: 'fa'},
  {name:'chevron-down', type: 'fa'},
  {name:'chevron-left', type: 'fa'},
  {name:'chevron-right', type: 'fa'},
  {name:'chevron-up', type: 'fa'},
  {name:'child', type: 'fa'},
  {name:'church', type: 'fa'},
  {name:'circle', type: 'fa'},
  {name:'circle-notch', type: 'fa'},
  {name:'city', type: 'fa'},
  {name:'clinic-medical', type: 'fa'},
  {name:'clipboard', type: 'fa'},
  {name:'clipboard-check', type: 'fa'},
  {name:'clipboard-list', type: 'fa'},
  {name:'clock', type: 'fa'},
  {name:'clone', type: 'fa'},
  {name:'closed-captioning', type: 'fa'},
  {name:'cloud', type: 'fa'},
  {name:'cloud-download-alt', type: 'fa'},
  {name:'cloud-meatball', type: 'fa'},
  {name:'cloud-moon', type: 'fa'},
  {name:'cloud-moon-rain', type: 'fa'},
  {name:'cloud-rain', type: 'fa'},
  {name:'cloud-showers-heavy', type: 'fa'},
  {name:'cloud-sun', type: 'fa'},
  {name:'cloud-sun-rain', type: 'fa'},
  {name:'cloud-upload-alt', type: 'fa'},
  {name:'cocktail', type: 'fa'},
  {name:'code', type: 'fa'},
  {name:'code-branch', type: 'fa'},
  {name:'coffee', type: 'fa'},
  {name:'cog', type: 'fa'},
  {name:'cogs', type: 'fa'},
  {name:'coins', type: 'fa'},
  {name:'columns', type: 'fa'},
  {name:'comment', type: 'fa'},
  {name:'comment-alt', type: 'fa'},
  {name:'comment-dollar', type: 'fa'},
  {name:'comment-dots', type: 'fa'},
  {name:'comment-medical', type: 'fa'},
  {name:'comment-slash', type: 'fa'},
  {name:'comments', type: 'fa'},
  {name:'comments-dollar', type: 'fa'},
  {name:'compact-disc', type: 'fa'},
  {name:'compass', type: 'fa'},
  {name:'compress', type: 'fa'},
  {name:'compress-alt', type: 'fa'},
  {name:'compress-arrows-alt', type: 'fa'},
  {name:'concierge-bell', type: 'fa'},
  {name:'cookie', type: 'fa'},
  {name:'cookie-bite', type: 'fa'},
  {name:'copy', type: 'fa'},
  {name:'copyright', type: 'fa'},
  {name:'couch', type: 'fa'},
  {name:'credit-card', type: 'fa'},
  {name:'crop', type: 'fa'},
  {name:'crop-alt', type: 'fa'},
  {name:'cross', type: 'fa'},
  {name:'crosshairs', type: 'fa'},
  {name:'crow', type: 'fa'},
  {name:'crown', type: 'fa'},
  {name:'crutch', type: 'fa'},
  {name:'cube', type: 'fa'},
  {name:'cubes', type: 'fa'},
  {name:'cut', type: 'fa'},
  {name:'database', type: 'fa'},
  {name:'deaf', type: 'fa'},
  {name:'democrat', type: 'fa'},
  {name:'desktop', type: 'fa'},
  {name:'dharmachakra', type: 'fa'},
  {name:'diagnoses', type: 'fa'},
  {name:'dice', type: 'fa'},
  {name:'dice-d20', type: 'fa'},
  {name:'dice-d6', type: 'fa'},
  {name:'dice-five', type: 'fa'},
  {name:'dice-four', type: 'fa'},
  {name:'dice-one', type: 'fa'},
  {name:'dice-six', type: 'fa'},
  {name:'dice-three', type: 'fa'},
  {name:'dice-two', type: 'fa'},
  {name:'digital-tachograph', type: 'fa'},
  {name:'directions', type: 'fa'},
  {name:'divide', type: 'fa'},
  {name:'dizzy', type: 'fa'},
  {name:'dna', type: 'fa'},
  {name:'dog', type: 'fa'},
  {name:'dollar-sign', type: 'fa'},
  {name:'dolly', type: 'fa'},
  {name:'dolly-flatbed', type: 'fa'},
  {name:'donate', type: 'fa'},
  {name:'door-closed', type: 'fa'},
  {name:'door-open', type: 'fa'},
  {name:'dot-circle', type: 'fa'},
  {name:'dove', type: 'fa'},
  {name:'download', type: 'fa'},
  {name:'drafting-compass', type: 'fa'},
  {name:'dragon', type: 'fa'},
  {name:'draw-polygon', type: 'fa'},
  {name:'drum', type: 'fa'},
  {name:'drum-steelpan', type: 'fa'},
  {name:'drumstick-bite', type: 'fa'},
  {name:'dumbbell', type: 'fa'},
  {name:'dumpster', type: 'fa'},
  {name:'dumpster-fire', type: 'fa'},
  {name:'dungeon', type: 'fa'},
  {name:'edit', type: 'fa'},
  {name:'egg', type: 'fa'},
  {name:'eject', type: 'fa'},
  {name:'ellipsis-h', type: 'fa'},
  {name:'ellipsis-v', type: 'fa'},
  {name:'envelope', type: 'fa'},
  {name:'envelope-open', type: 'fa'},
  {name:'envelope-open-text', type: 'fa'},
  {name:'envelope-square', type: 'fa'},
  {name:'equals', type: 'fa'},
  {name:'eraser', type: 'fa'},
  {name:'ethernet', type: 'fa'},
  {name:'euro-sign', type: 'fa'},
  {name:'exchange-alt', type: 'fa'},
  {name:'exclamation', type: 'fa'},
  {name:'exclamation-circle', type: 'fa'},
  {name:'exclamation-triangle', type: 'fa'},
  {name:'expand', type: 'fa'},
  {name:'expand-alt', type: 'fa'},
  {name:'expand-arrows-alt', type: 'fa'},
  {name:'external-link-alt', type: 'fa'},
  {name:'external-link-square-alt', type: 'fa'},
  {name:'eye', type: 'fa'},
  {name:'eye-dropper', type: 'fa'},
  {name:'eye-slash', type: 'fa'},
  {name:'fan', type: 'fa'},
  {name:'fast-backward', type: 'fa'},
  {name:'fast-forward', type: 'fa'},
  {name:'fax', type: 'fa'},
  {name:'feather', type: 'fa'},
  {name:'feather-alt', type: 'fa'},
  {name:'female', type: 'fa'},
  {name:'fighter-jet', type: 'fa'},
  {name:'file', type: 'fa'},
  {name:'file-alt', type: 'fa'},
  {name:'file-archive', type: 'fa'},
  {name:'file-audio', type: 'fa'},
  {name:'file-code', type: 'fa'},
  {name:'file-contract', type: 'fa'},
  {name:'file-csv', type: 'fa'},
  {name:'file-download', type: 'fa'},
  {name:'file-excel', type: 'fa'},
  {name:'file-export', type: 'fa'},
  {name:'file-image', type: 'fa'},
  {name:'file-import', type: 'fa'},
  {name:'file-invoice', type: 'fa'},
  {name:'file-invoice-dollar', type: 'fa'},
  {name:'file-medical', type: 'fa'},
  {name:'file-medical-alt', type: 'fa'},
  {name:'file-pdf', type: 'fa'},
  {name:'file-powerpoint', type: 'fa'},
  {name:'file-prescription', type: 'fa'},
  {name:'file-signature', type: 'fa'},
  {name:'file-upload', type: 'fa'},
  {name:'file-video', type: 'fa'},
  {name:'file-word', type: 'fa'},
  {name:'fill', type: 'fa'},
  {name:'fill-drip', type: 'fa'},
  {name:'film', type: 'fa'},
  {name:'filter', type: 'fa'},
  {name:'fingerprint', type: 'fa'},
  {name:'fire', type: 'fa'},
  {name:'fire-alt', type: 'fa'},
  {name:'fire-extinguisher', type: 'fa'},
  {name:'first-aid', type: 'fa'},
  {name:'fish', type: 'fa'},
  {name:'fist-raised', type: 'fa'},
  {name:'flag', type: 'fa'},
  {name:'flag-checkered', type: 'fa'},
  {name:'flag-usa', type: 'fa'},
  {name:'flask', type: 'fa'},
  {name:'flushed', type: 'fa'},
  {name:'folder', type: 'fa'},
  {name:'folder-minus', type: 'fa'},
  {name:'folder-open', type: 'fa'},
  {name:'folder-plus', type: 'fa'},
  {name:'font', type: 'fa'},
  {name:'football-ball', type: 'fa'},
  {name:'forward', type: 'fa'},
  {name:'frog', type: 'fa'},
  {name:'frown', type: 'fa'},
  {name:'frown-open', type: 'fa'},
  {name:'funnel-dollar', type: 'fa'},
  {name:'futbol', type: 'fa'},
  {name:'gamepad', type: 'fa'},
  {name:'gas-pump', type: 'fa'},
  {name:'gavel', type: 'fa'},
  {name:'gem', type: 'fa'},
  {name:'genderless', type: 'fa'},
  {name:'ghost', type: 'fa'},
  {name:'gift', type: 'fa'},
  {name:'gifts', type: 'fa'},
  {name:'glass-cheers', type: 'fa'},
  {name:'glass-martini', type: 'fa'},
  {name:'glass-martini-alt', type: 'fa'},
  {name:'glass-whiskey', type: 'fa'},
  {name:'glasses', type: 'fa'},
  {name:'globe', type: 'fa'},
  {name:'globe-africa', type: 'fa'},
  {name:'globe-americas', type: 'fa'},
  {name:'globe-asia', type: 'fa'},
  {name:'globe-europe', type: 'fa'},
  {name:'golf-ball', type: 'fa'},
  {name:'gopuram', type: 'fa'},
  {name:'graduation-cap', type: 'fa'},
  {name:'greater-than', type: 'fa'},
  {name:'greater-than-equal', type: 'fa'},
  {name:'grimace', type: 'fa'},
  {name:'grin', type: 'fa'},
  {name:'grin-alt', type: 'fa'},
  {name:'grin-beam', type: 'fa'},
  {name:'grin-beam-sweat', type: 'fa'},
  {name:'grin-hearts', type: 'fa'},
  {name:'grin-squint', type: 'fa'},
  {name:'grin-squint-tears', type: 'fa'},
  {name:'grin-stars', type: 'fa'},
  {name:'grin-tears', type: 'fa'},
  {name:'grin-tongue', type: 'fa'},
  {name:'grin-tongue-squint', type: 'fa'},
  {name:'grin-tongue-wink', type: 'fa'},
  {name:'grin-wink', type: 'fa'},
  {name:'grip-horizontal', type: 'fa'},
  {name:'grip-lines', type: 'fa'},
  {name:'grip-lines-vertical', type: 'fa'},
  {name:'grip-vertical', type: 'fa'},
  {name:'guitar', type: 'fa'},
  {name:'h-square', type: 'fa'},
  {name:'hamburger', type: 'fa'},
  {name:'hammer', type: 'fa'},
  {name:'hamsa', type: 'fa'},
  {name:'hand-holding', type: 'fa'},
  {name:'hand-holding-heart', type: 'fa'},
  {name:'hand-holding-usd', type: 'fa'},
  {name:'hand-lizard', type: 'fa'},
  {name:'hand-middle-finger', type: 'fa'},
  {name:'hand-paper', type: 'fa'},
  {name:'hand-peace', type: 'fa'},
  {name:'hand-point-down', type: 'fa'},
  {name:'hand-point-left', type: 'fa'},
  {name:'hand-point-right', type: 'fa'},
  {name:'hand-point-up', type: 'fa'},
  {name:'hand-pointer', type: 'fa'},
  {name:'hand-rock', type: 'fa'},
  {name:'hand-scissors', type: 'fa'},
  {name:'hand-spock', type: 'fa'},
  {name:'hands', type: 'fa'},
  {name:'hands-helping', type: 'fa'},
  {name:'handshake', type: 'fa'},
  {name:'hanukiah', type: 'fa'},
  {name:'hard-hat', type: 'fa'},
  {name:'hashtag', type: 'fa'},
  {name:'hat-cowboy', type: 'fa'},
  {name:'hat-cowboy-side', type: 'fa'},
  {name:'hat-wizard', type: 'fa'},
  {name:'hdd', type: 'fa'},
  {name:'heading', type: 'fa'},
  {name:'headphones', type: 'fa'},
  {name:'headphones-alt', type: 'fa'},
  {name:'headset', type: 'fa'},
  {name:'heart', type: 'fa'},
  {name:'heart-broken', type: 'fa'},
  {name:'heartbeat', type: 'fa'},
  {name:'helicopter', type: 'fa'},
  {name:'highlighter', type: 'fa'},
  {name:'hiking', type: 'fa'},
  {name:'hippo', type: 'fa'},
  {name:'history', type: 'fa'},
  {name:'hockey-puck', type: 'fa'},
  {name:'holly-berry', type: 'fa'},
  {name:'home', type: 'fa'},
  {name:'horse', type: 'fa'},
  {name:'horse-head', type: 'fa'},
  {name:'hospital', type: 'fa'},
  {name:'hospital-alt', type: 'fa'},
  {name:'hospital-symbol', type: 'fa'},
  {name:'hot-tub', type: 'fa'},
  {name:'hotdog', type: 'fa'},
  {name:'hotel', type: 'fa'},
  {name:'hourglass', type: 'fa'},
  {name:'hourglass-end', type: 'fa'},
  {name:'hourglass-half', type: 'fa'},
  {name:'hourglass-start', type: 'fa'},
  {name:'house-damage', type: 'fa'},
  {name:'hryvnia', type: 'fa'},
  {name:'i-cursor', type: 'fa'},
  {name:'ice-cream', type: 'fa'},
  {name:'icicles', type: 'fa'},
  {name:'icons', type: 'fa'},
  {name:'id-badge', type: 'fa'},
  {name:'id-card', type: 'fa'},
  {name:'id-card-alt', type: 'fa'},
  {name:'igloo', type: 'fa'},
  {name:'image', type: 'fa'},
  {name:'images', type: 'fa'},
  {name:'inbox', type: 'fa'},
  {name:'indent', type: 'fa'},
  {name:'industry', type: 'fa'},
  {name:'infinity', type: 'fa'},
  {name:'info', type: 'fa'},
  {name:'info-circle', type: 'fa'},
  {name:'italic', type: 'fa'},
  {name:'jedi', type: 'fa'},
  {name:'joint', type: 'fa'},
  {name:'journal-whills', type: 'fa'},
  {name:'kaaba', type: 'fa'},
  {name:'key', type: 'fa'},
  {name:'keyboard', type: 'fa'},
  {name:'khanda', type: 'fa'},
  {name:'kiss', type: 'fa'},
  {name:'kiss-beam', type: 'fa'},
  {name:'kiss-wink-heart', type: 'fa'},
  {name:'kiwi-bird', type: 'fa'},
  {name:'landmark', type: 'fa'},
  {name:'language', type: 'fa'},
  {name:'laptop', type: 'fa'},
  {name:'laptop-code', type: 'fa'},
  {name:'laptop-medical', type: 'fa'},
  {name:'laugh', type: 'fa'},
  {name:'laugh-beam', type: 'fa'},
  {name:'laugh-squint', type: 'fa'},
  {name:'laugh-wink', type: 'fa'},
  {name:'layer-group', type: 'fa'},
  {name:'leaf', type: 'fa'},
  {name:'lemon', type: 'fa'},
  {name:'less-than', type: 'fa'},
  {name:'less-than-equal', type: 'fa'},
  {name:'level-down-alt', type: 'fa'},
  {name:'level-up-alt', type: 'fa'},
  {name:'life-ring', type: 'fa'},
  {name:'lightbulb', type: 'fa'},
  {name:'link', type: 'fa'},
  {name:'lira-sign', type: 'fa'},
  {name:'list', type: 'fa'},
  {name:'list-alt', type: 'fa'},
  {name:'list-ol', type: 'fa'},
  {name:'list-ul', type: 'fa'},
  {name:'location-arrow', type: 'fa'},
  {name:'lock', type: 'fa'},
  {name:'lock-open', type: 'fa'},
  {name:'long-arrow-alt-down', type: 'fa'},
  {name:'long-arrow-alt-left', type: 'fa'},
  {name:'long-arrow-alt-right', type: 'fa'},
  {name:'long-arrow-alt-up', type: 'fa'},
  {name:'low-vision', type: 'fa'},
  {name:'luggage-cart', type: 'fa'},
  {name:'magic', type: 'fa'},
  {name:'magnet', type: 'fa'},
  {name:'mail-bulk', type: 'fa'},
  {name:'male', type: 'fa'},
  {name:'map', type: 'fa'},
  {name:'map-marked', type: 'fa'},
  {name:'map-marked-alt', type: 'fa'},
  {name:'map-marker', type: 'fa'},
  {name:'map-marker-alt', type: 'fa'},
  {name:'map-pin', type: 'fa'},
  {name:'map-signs', type: 'fa'},
  {name:'marker', type: 'fa'},
  {name:'mars', type: 'fa'},
  {name:'mars-double', type: 'fa'},
  {name:'mars-stroke', type: 'fa'},
  {name:'mars-stroke-h', type: 'fa'},
  {name:'mars-stroke-v', type: 'fa'},
  {name:'mask', type: 'fa'},
  {name:'medal', type: 'fa'},
  {name:'medkit', type: 'fa'},
  {name:'meh', type: 'fa'},
  {name:'meh-blank', type: 'fa'},
  {name:'meh-rolling-eyes', type: 'fa'},
  {name:'memory', type: 'fa'},
  {name:'menorah', type: 'fa'},
  {name:'mercury', type: 'fa'},
  {name:'meteor', type: 'fa'},
  {name:'microchip', type: 'fa'},
  {name:'microphone', type: 'fa'},
  {name:'microphone-alt', type: 'fa'},
  {name:'microphone-alt-slash', type: 'fa'},
  {name:'microphone-slash', type: 'fa'},
  {name:'microscope', type: 'fa'},
  {name:'minus', type: 'fa'},
  {name:'minus-circle', type: 'fa'},
  {name:'minus-square', type: 'fa'},
  {name:'mitten', type: 'fa'},
  {name:'mobile', type: 'fa'},
  {name:'mobile-alt', type: 'fa'},
  {name:'money-bill', type: 'fa'},
  {name:'money-bill-alt', type: 'fa'},
  {name:'money-bill-wave', type: 'fa'},
  {name:'money-bill-wave-alt', type: 'fa'},
  {name:'money-check', type: 'fa'},
  {name:'money-check-alt', type: 'fa'},
  {name:'monument', type: 'fa'},
  {name:'moon', type: 'fa'},
  {name:'mortar-pestle', type: 'fa'},
  {name:'mosque', type: 'fa'},
  {name:'motorcycle', type: 'fa'},
  {name:'mountain', type: 'fa'},
  {name:'mouse', type: 'fa'},
  {name:'mouse-pointer', type: 'fa'},
  {name:'mug-hot', type: 'fa'},
  {name:'music', type: 'fa'},
  {name:'network-wired', type: 'fa'},
  {name:'neuter', type: 'fa'},
  {name:'newspaper', type: 'fa'},
  {name:'not-equal', type: 'fa'},
  {name:'notes-medical', type: 'fa'},
  {name:'object-group', type: 'fa'},
  {name:'object-ungroup', type: 'fa'},
  {name:'oil-can', type: 'fa'},
  {name:'om', type: 'fa'},
  {name:'otter', type: 'fa'},
  {name:'outdent', type: 'fa'},
  {name:'pager', type: 'fa'},
  {name:'paint-brush', type: 'fa'},
  {name:'paint-roller', type: 'fa'},
  {name:'palette', type: 'fa'},
  {name:'pallet', type: 'fa'},
  {name:'paper-plane', type: 'fa'},
  {name:'paperclip', type: 'fa'},
  {name:'parachute-box', type: 'fa'},
  {name:'paragraph', type: 'fa'},
  {name:'parking', type: 'fa'},
  {name:'passport', type: 'fa'},
  {name:'pastafarianism', type: 'fa'},
  {name:'paste', type: 'fa'},
  {name:'pause', type: 'fa'},
  {name:'pause-circle', type: 'fa'},
  {name:'paw', type: 'fa'},
  {name:'peace', type: 'fa'},
  {name:'pen', type: 'fa'},
  {name:'pen-alt', type: 'fa'},
  {name:'pen-fancy', type: 'fa'},
  {name:'pen-nib', type: 'fa'},
  {name:'pen-square', type: 'fa'},
  {name:'pencil-alt', type: 'fa'},
  {name:'pencil-ruler', type: 'fa'},
  {name:'people-carry', type: 'fa'},
  {name:'pepper-hot', type: 'fa'},
  {name:'percent', type: 'fa'},
  {name:'percentage', type: 'fa'},
  {name:'person-booth', type: 'fa'},
  {name:'phone', type: 'fa'},
  {name:'phone-alt', type: 'fa'},
  {name:'phone-slash', type: 'fa'},
  {name:'phone-square', type: 'fa'},
  {name:'phone-square-alt', type: 'fa'},
  {name:'phone-volume', type: 'fa'},
  {name:'photo-video', type: 'fa'},
  {name:'piggy-bank', type: 'fa'},
  {name:'pills', type: 'fa'},
  {name:'pizza-slice', type: 'fa'},
  {name:'place-of-worship', type: 'fa'},
  {name:'plane', type: 'fa'},
  {name:'plane-arrival', type: 'fa'},
  {name:'plane-departure', type: 'fa'},
  {name:'play', type: 'fa'},
  {name:'play-circle', type: 'fa'},
  {name:'plug', type: 'fa'},
  {name:'plus', type: 'fa'},
  {name:'plus-circle', type: 'fa'},
  {name:'plus-square', type: 'fa'},
  {name:'podcast', type: 'fa'},
  {name:'poll', type: 'fa'},
  {name:'poll-h', type: 'fa'},
  {name:'poo', type: 'fa'},
  {name:'poo-storm', type: 'fa'},
  {name:'poop', type: 'fa'},
  {name:'portrait', type: 'fa'},
  {name:'pound-sign', type: 'fa'},
  {name:'power-off', type: 'fa'},
  {name:'pray', type: 'fa'},
  {name:'praying-hands', type: 'fa'},
  {name:'prescription', type: 'fa'},
  {name:'prescription-bottle', type: 'fa'},
  {name:'prescription-bottle-alt', type: 'fa'},
  {name:'print', type: 'fa'},
  {name:'procedures', type: 'fa'},
  {name:'project-diagram', type: 'fa'},
  {name:'puzzle-piece', type: 'fa'},
  {name:'qrcode', type: 'fa'},
  {name:'question', type: 'fa'},
  {name:'question-circle', type: 'fa'},
  {name:'quidditch', type: 'fa'},
  {name:'quote-left', type: 'fa'},
  {name:'quote-right', type: 'fa'},
  {name:'quran', type: 'fa'},
  {name:'radiation', type: 'fa'},
  {name:'radiation-alt', type: 'fa'},
  {name:'rainbow', type: 'fa'},
  {name:'random', type: 'fa'},
  {name:'receipt', type: 'fa'},
  {name:'record-vinyl', type: 'fa'},
  {name:'recycle', type: 'fa'},
  {name:'redo', type: 'fa'},
  {name:'redo-alt', type: 'fa'},
  {name:'registered', type: 'fa'},
  {name:'remove-format', type: 'fa'},
  {name:'reply', type: 'fa'},
  {name:'reply-all', type: 'fa'},
  {name:'republican', type: 'fa'},
  {name:'restroom', type: 'fa'},
  {name:'retweet', type: 'fa'},
  {name:'ribbon', type: 'fa'},
  {name:'ring', type: 'fa'},
  {name:'road', type: 'fa'},
  {name:'robot', type: 'fa'},
  {name:'rocket', type: 'fa'},
  {name:'route', type: 'fa'},
  {name:'rss', type: 'fa'},
  {name:'rss-square', type: 'fa'},
  {name:'ruble-sign', type: 'fa'},
  {name:'ruler', type: 'fa'},
  {name:'ruler-combined', type: 'fa'},
  {name:'ruler-horizontal', type: 'fa'},
  {name:'ruler-vertical', type: 'fa'},
  {name:'running', type: 'fa'},
  {name:'rupee-sign', type: 'fa'},
  {name:'sad-cry', type: 'fa'},
  {name:'sad-tear', type: 'fa'},
  {name:'satellite', type: 'fa'},
  {name:'satellite-dish', type: 'fa'},
  {name:'save', type: 'fa'},
  {name:'school', type: 'fa'},
  {name:'screwdriver', type: 'fa'},
  {name:'scroll', type: 'fa'},
  {name:'sd-card', type: 'fa'},
  {name:'search', type: 'fa'},
  {name:'search-dollar', type: 'fa'},
  {name:'search-location', type: 'fa'},
  {name:'search-minus', type: 'fa'},
  {name:'search-plus', type: 'fa'},
  {name:'seedling', type: 'fa'},
  {name:'server', type: 'fa'},
  {name:'shapes', type: 'fa'},
  {name:'share', type: 'fa'},
  {name:'share-alt', type: 'fa'},
  {name:'share-alt-square', type: 'fa'},
  {name:'share-square', type: 'fa'},
  {name:'shekel-sign', type: 'fa'},
  {name:'shield-alt', type: 'fa'},
  {name:'ship', type: 'fa'},
  {name:'shipping-fast', type: 'fa'},
  {name:'shoe-prints', type: 'fa'},
  {name:'shopping-bag', type: 'fa'},
  {name:'shopping-basket', type: 'fa'},
  {name:'shopping-cart', type: 'fa'},
  {name:'shower', type: 'fa'},
  {name:'shuttle-van', type: 'fa'},
  {name:'sign', type: 'fa'},
  {name:'sign-in-alt', type: 'fa'},
  {name:'sign-language', type: 'fa'},
  {name:'sign-out-alt', type: 'fa'},
  {name:'signal', type: 'fa'},
  {name:'signature', type: 'fa'},
  {name:'sim-card', type: 'fa'},
  {name:'sitemap', type: 'fa'},
  {name:'skating', type: 'fa'},
  {name:'skiing', type: 'fa'},
  {name:'skiing-nordic', type: 'fa'},
  {name:'skull', type: 'fa'},
  {name:'skull-crossbones', type: 'fa'},
  {name:'slash', type: 'fa'},
  {name:'sleigh', type: 'fa'},
  {name:'sliders-h', type: 'fa'},
  {name:'smile', type: 'fa'},
  {name:'smile-beam', type: 'fa'},
  {name:'smile-wink', type: 'fa'},
  {name:'smog', type: 'fa'},
  {name:'smoking', type: 'fa'},
  {name:'smoking-ban', type: 'fa'},
  {name:'sms', type: 'fa'},
  {name:'snowboarding', type: 'fa'},
  {name:'snowflake', type: 'fa'},
  {name:'snowman', type: 'fa'},
  {name:'snowplow', type: 'fa'},
  {name:'socks', type: 'fa'},
  {name:'solar-panel', type: 'fa'},
  {name:'sort', type: 'fa'},
  {name:'sort-alpha-down', type: 'fa'},
  {name:'sort-alpha-down-alt', type: 'fa'},
  {name:'sort-alpha-up', type: 'fa'},
  {name:'sort-alpha-up-alt', type: 'fa'},
  {name:'sort-amount-down', type: 'fa'},
  {name:'sort-amount-down-alt', type: 'fa'},
  {name:'sort-amount-up', type: 'fa'},
  {name:'sort-amount-up-alt', type: 'fa'},
  {name:'sort-down', type: 'fa'},
  {name:'sort-numeric-down', type: 'fa'},
  {name:'sort-numeric-down-alt', type: 'fa'},
  {name:'sort-numeric-up', type: 'fa'},
  {name:'sort-numeric-up-alt', type: 'fa'},
  {name:'sort-up', type: 'fa'},
  {name:'spa', type: 'fa'},
  {name:'space-shuttle', type: 'fa'},
  {name:'spell-check', type: 'fa'},
  {name:'spider', type: 'fa'},
  {name:'spinner', type: 'fa'},
  {name:'splotch', type: 'fa'},
  {name:'spray-can', type: 'fa'},
  {name:'square', type: 'fa'},
  {name:'square-full', type: 'fa'},
  {name:'square-root-alt', type: 'fa'},
  {name:'stamp', type: 'fa'},
  {name:'star', type: 'fa'},
  {name:'star-and-crescent', type: 'fa'},
  {name:'star-half', type: 'fa'},
  {name:'star-half-alt', type: 'fa'},
  {name:'star-of-david', type: 'fa'},
  {name:'star-of-life', type: 'fa'},
  {name:'step-backward', type: 'fa'},
  {name:'step-forward', type: 'fa'},
  {name:'stethoscope', type: 'fa'},
  {name:'sticky-note', type: 'fa'},
  {name:'stop', type: 'fa'},
  {name:'stop-circle', type: 'fa'},
  {name:'stopwatch', type: 'fa'},
  {name:'store', type: 'fa'},
  {name:'store-alt', type: 'fa'},
  {name:'stream', type: 'fa'},
  {name:'street-view', type: 'fa'},
  {name:'strikethrough', type: 'fa'},
  {name:'stroopwafel', type: 'fa'},
  {name:'subscript', type: 'fa'},
  {name:'subway', type: 'fa'},
  {name:'suitcase', type: 'fa'},
  {name:'suitcase-rolling', type: 'fa'},
  {name:'sun', type: 'fa'},
  {name:'superscript', type: 'fa'},
  {name:'surprise', type: 'fa'},
  {name:'swatchbook', type: 'fa'},
  {name:'swimmer', type: 'fa'},
  {name:'swimming-pool', type: 'fa'},
  {name:'synagogue', type: 'fa'},
  {name:'sync', type: 'fa'},
  {name:'sync-alt', type: 'fa'},
  {name:'syringe', type: 'fa'},
  {name:'table', type: 'fa'},
  {name:'table-tennis', type: 'fa'},
  {name:'tablet', type: 'fa'},
  {name:'tablet-alt', type: 'fa'},
  {name:'tablets', type: 'fa'},
  {name:'tachometer-alt', type: 'fa'},
  {name:'tag', type: 'fa'},
  {name:'tags', type: 'fa'},
  {name:'tape', type: 'fa'},
  {name:'tasks', type: 'fa'},
  {name:'taxi', type: 'fa'},
  {name:'teeth', type: 'fa'},
  {name:'teeth-open', type: 'fa'},
  {name:'temperature-high', type: 'fa'},
  {name:'temperature-low', type: 'fa'},
  {name:'tenge', type: 'fa'},
  {name:'terminal', type: 'fa'},
  {name:'text-height', type: 'fa'},
  {name:'text-width', type: 'fa'},
  {name:'th', type: 'fa'},
  {name:'th-large', type: 'fa'},
  {name:'th-list', type: 'fa'},
  {name:'theater-masks', type: 'fa'},
  {name:'thermometer', type: 'fa'},
  {name:'thermometer-empty', type: 'fa'},
  {name:'thermometer-full', type: 'fa'},
  {name:'thermometer-half', type: 'fa'},
  {name:'thermometer-quarter', type: 'fa'},
  {name:'thermometer-three-quarters', type: 'fa'},
  {name:'thumbs-down', type: 'fa'},
  {name:'thumbs-up', type: 'fa'},
  {name:'thumbtack', type: 'fa'},
  {name:'ticket-alt', type: 'fa'},
  {name:'times', type: 'fa'},
  {name:'times-circle', type: 'fa'},
  {name:'tint', type: 'fa'},
  {name:'tint-slash', type: 'fa'},
  {name:'tired', type: 'fa'},
  {name:'toggle-off', type: 'fa'},
  {name:'toggle-on', type: 'fa'},
  {name:'toilet', type: 'fa'},
  {name:'toilet-paper', type: 'fa'},
  {name:'toolbox', type: 'fa'},
  {name:'tools', type: 'fa'},
  {name:'tooth', type: 'fa'},
  {name:'torah', type: 'fa'},
  {name:'torii-gate', type: 'fa'},
  {name:'tractor', type: 'fa'},
  {name:'trademark', type: 'fa'},
  {name:'traffic-light', type: 'fa'},
  {name:'trailer', type: 'fa'},
  {name:'train', type: 'fa'},
  {name:'tram', type: 'fa'},
  {name:'transgender', type: 'fa'},
  {name:'transgender-alt', type: 'fa'},
  {name:'trash', type: 'fa'},
  {name:'trash-alt', type: 'fa'},
  {name:'trash-restore', type: 'fa'},
  {name:'trash-restore-alt', type: 'fa'},
  {name:'tree', type: 'fa'},
  {name:'trophy', type: 'fa'},
  {name:'truck', type: 'fa'},
  {name:'truck-loading', type: 'fa'},
  {name:'truck-monster', type: 'fa'},
  {name:'truck-moving', type: 'fa'},
  {name:'truck-pickup', type: 'fa'},
  {name:'tshirt', type: 'fa'},
  {name:'tty', type: 'fa'},
  {name:'tv', type: 'fa'},
  {name:'umbrella', type: 'fa'},
  {name:'umbrella-beach', type: 'fa'},
  {name:'underline', type: 'fa'},
  {name:'undo', type: 'fa'},
  {name:'undo-alt', type: 'fa'},
  {name:'universal-access', type: 'fa'},
  {name:'university', type: 'fa'},
  {name:'unlink', type: 'fa'},
  {name:'unlock', type: 'fa'},
  {name:'unlock-alt', type: 'fa'},
  {name:'upload', type: 'fa'},
  {name:'user', type: 'fa'},
  {name:'user-alt', type: 'fa'},
  {name:'user-alt-slash', type: 'fa'},
  {name:'user-astronaut', type: 'fa'},
  {name:'user-check', type: 'fa'},
  {name:'user-circle', type: 'fa'},
  {name:'user-clock', type: 'fa'},
  {name:'user-cog', type: 'fa'},
  {name:'user-edit', type: 'fa'},
  {name:'user-friends', type: 'fa'},
  {name:'user-graduate', type: 'fa'},
  {name:'user-injured', type: 'fa'},
  {name:'user-lock', type: 'fa'},
  {name:'user-md', type: 'fa'},
  {name:'user-minus', type: 'fa'},
  {name:'user-ninja', type: 'fa'},
  {name:'user-nurse', type: 'fa'},
  {name:'user-plus', type: 'fa'},
  {name:'user-secret', type: 'fa'},
  {name:'user-shield', type: 'fa'},
  {name:'user-slash', type: 'fa'},
  {name:'user-tag', type: 'fa'},
  {name:'user-tie', type: 'fa'},
  {name:'user-times', type: 'fa'},
  {name:'users', type: 'fa'},
  {name:'users-cog', type: 'fa'},
  {name:'utensil-spoon', type: 'fa'},
  {name:'utensils', type: 'fa'},
  {name:'vector-square', type: 'fa'},
  {name:'venus', type: 'fa'},
  {name:'venus-double', type: 'fa'},
  {name:'venus-mars', type: 'fa'},
  {name:'vial', type: 'fa'},
  {name:'vials', type: 'fa'},
  {name:'video', type: 'fa'},
  {name:'video-slash', type: 'fa'},
  {name:'vihara', type: 'fa'},
  {name:'voicemail', type: 'fa'},
  {name:'volleyball-ball', type: 'fa'},
  {name:'volume-down', type: 'fa'},
  {name:'volume-mute', type: 'fa'},
  {name:'volume-off', type: 'fa'},
  {name:'volume-up', type: 'fa'},
  {name:'vote-yea', type: 'fa'},
  {name:'vr-cardboard', type: 'fa'},
  {name:'walking', type: 'fa'},
  {name:'wallet', type: 'fa'},
  {name:'warehouse', type: 'fa'},
  {name:'water', type: 'fa'},
  {name:'wave-square', type: 'fa'},
  {name:'weight', type: 'fa'},
  {name:'weight-hanging', type: 'fa'},
  {name:'wheelchair', type: 'fa'},
  {name:'wifi', type: 'fa'},
  {name:'wind', type: 'fa'},
  {name:'window-close', type: 'fa'},
  {name:'window-maximize', type: 'fa'},
  {name:'window-minimize', type: 'fa'},
  {name:'window-restore', type: 'fa'},
  {name:'wine-bottle', type: 'fa'},
  {name:'wine-glass', type: 'fa'},
  {name:'wine-glass-alt', type: 'fa'},
  {name:'won-sign', type: 'fa'},
  {name:'wrench', type: 'fa'},
  {name:'x-ray', type: 'fa'},
  {name:'yen-sign', type: 'fa'},
  {name:'yin-yang', type: 'fa'},
  {name: 'Achiever', type: 'custom'},
  {name: 'Activator', type: 'custom'},
  {name: 'Archaic', type: 'custom'},
  {name: 'Diplomat', type: 'custom'},
  {name: 'Expert', type: 'custom'},
  {name: 'Redefiner', type: 'custom'},
  {name: 'Sensor', type: 'custom'},
  {name: 'Transformer', type: 'custom'},
]