import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import {
  selectCommunityLeaders,
  selectCurrentUserCommunityPermissions,
  selectSelectedCommunityId } from '../../state/community/reducers';
import Button from '../Button/Button';
import * as missionTypes from '../../state/mission/types';
import ProfileImage from '../ProfileImage/ProfileImage';
import { DANGER_SNACK } from '../../state/snack/types';
import * as communityTypes from '../../state/community/types';


const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    borderRadius: 10,
    // backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  icon: {
    marginRight: 10
  },
  objective: {
    fontSize: 14,
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    flex: 1
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  },
  button: {
    marginTop: 15
  },
  label: {
    fontSize: 15
  }
});

const LeaderDropdown = ({ mission }) => {
  const [defaultData, setDefaultData] = useState({});
  const [defaultVal, setDefaultVal] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const leaderUsers = useSelector(state => selectCommunityLeaders(state));

  const currentPermissions = useSelector(state =>
    selectCurrentUserCommunityPermissions(state)
  );
  const newleaderUsers = leaderUsers.map(user => {
    return {
      id: user._id,
      label: `${user.firstName} ${user.lastName}`,
      image: user._id
    }
  })

  useEffect(() => {
    if (communityId) {

      dispatch({
        type: communityTypes.GET_COMMUNITY_LEADERS,
        communityId
      });
    }
  }, [communityId]);

  useEffect(() => {
    if (mission.leader) {
      setDefaultVal({
        label: `${mission.leader.firstName} ${mission.leader.lastName}`,
        id: mission.leader._id,
        image: mission.leader.id,
      })
    }
  }, [mission]);

  const onChange = (e, value) => {
    const defaultData = {
      ...mission,
      leader: value.id,
    }

    delete defaultData.community;
    setDefaultData(defaultData);
    setDefaultVal(value)
  }


  const onSubmit = (e, value) => {
    if (Object.keys(defaultData).length === 0) {
      dispatch({
        type: DANGER_SNACK,
        content: 'You can\'t reassign yourself!'
      });
      return;
    }

    dispatch({
      type: missionTypes.UPDATE_MISSION,
      userId: mission.user._id,
      missionId: mission._id,
      updates: {
        ...defaultData
      }
    });

    dispatch({

      type: communityTypes.GET_COMMUNITY_LEADERS,
      communityId
    })
  }

  return (
    <>
      <Grid item xs={6} sm={8}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{ paddingLeft: 8 }}
          getOptionLabel={(option) => option.label.toString()}
          options={newleaderUsers}
          value={defaultVal}
          getOptionSelected={(option, value) => option.value === value.value}
          size="medium"
          renderOption={(option, props) => (
            <Box
              style={{ display: 'flex', alignItems: 'center' }}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <ProfileImage userId={option.image} style={{ marginRight: 10 }} />
              {option.label}
            </Box>
          )}
          disableClearable
          onChange={onChange}
          renderInput={(params) => <TextField
            {...params}
            InputLabelProps={{
              className: classes.label
            }}
            label="Do you want to reassign the leader?"
          />}
        />

      </Grid>
      <Grid item xs={6} sm={4}>
        <Button
          label="Save"
          onClick={onSubmit}
          className={classes.button}
        />
      </Grid>
    </>
  );
};

LeaderDropdown.propTypes = {
  mission: PropTypes.object,
};

LeaderDropdown.defaultProps = {
};

export default LeaderDropdown;
