import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import MissionIcon from '../MissionIcon/MissionIcon';

import MissionReflection from './MissionReflection';
import SpecList from '../List/SpecList';

import { getMostRecentObject } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey200
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  },
  pageHeading: {
    fontSize: 30,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily
  },
  content: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'green',
    flex: 1
  },
  actions: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex'
  }
}));
export default function MissionHeader({ mission, actions }) {
  // const currentDay = moment().startOf('day');
  // const computedEndDate = moment(mission.endDate).endOf('day');
  // const { status } = mission;

  // const isOverdue = computedEndDate.isBefore(currentDay) && status !== 'complete';
  // const daysOverdue = currentDay.diff(computedEndDate, 'days');

  const missionReflection = mission.reflection ? getMostRecentObject(mission.reflection, 'createdOn') : null;

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={6} lg={7} xl={9} display="flex" className={classes.title}>
          <Box marginRight={3} marginBottom={4}>
            <MissionIcon icon={mission.icon} id={mission.id} large />
          </Box>
          <Box>
            <Typography className={classes.pageHeading}>{mission.title}</Typography>

            <SpecList
              size="small"
              data={[
                { label: 'DESCRIPTION', value: mission.description },
                {
                  label: 'CREATED DATE',
                  value: moment(mission.createdOn).format('D MMMM YYYY')
                },
                {
                  label: 'END DATE',
                  value: moment(mission.endDate).format('D MMMM YYYY')
                }
                // isOverdue && {
                //   label: 'OVERDUE DATE',
                //   value: `${daysOverdue} days`
                // },
                // {
                //   label: 'LEADER',
                //   value: mission.leader
                //     ? `${mission.leader.firstName} ${mission.leader.lastName}`
                //     : '-'
                // }
              ].filter(Boolean)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={3} className={classes.actions}>
          {actions}
        </Grid>

        <Grid item />
      </Grid>

      {missionReflection && mission.status === 'complete' && <MissionReflection reflection={missionReflection} />}
    </>
  );
}

MissionHeader.propTypes = {
  mission: PropTypes.object.isRequired,
  actions: PropTypes.node
};

MissionHeader.defaultProps = {
  actions: null
};
