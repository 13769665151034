import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    padding: 40
  },
  activeWrap: {
    padding: '40px 40px 0px 40px !important'
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    textTransform: 'uppercase'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const DrawerHeading = ({ heading, actions, isActive }) => {
  // Hooks
  const classes = useStyles();

  const wrapClass = clsx({
    [classes.wrap]: true,
    [classes.activeWrap]: isActive
  });

  return (
    <Box className={wrapClass}>
      <Typography className={classes.title}>{heading}</Typography>
      <Box className={classes.actions}>{actions}</Box>
    </Box>
  );
};

DrawerHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  actions: PropTypes.node,
  isActive: PropTypes.bool
};

DrawerHeading.defaultProps = {
  actions: null,
  isActive: false
};

export default DrawerHeading;
