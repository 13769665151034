import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import PageHeader from '../components/PageHeader/PageHeader';
import MissionHeader from '../components/MissionHeader/MissionHeader';
import Table from '../components/Table/Table';
import MissionTimelineCard from '../components/RowCards/MissionTimelineCard';
import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import Button from '../components/Button/Button';
import SearchableObjectives from '../components/Objective/SearchableObjectives';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';
import MemberNavigationHeader from '../components/Members/MemberHeaderNavigation';
import LeaderDropdown from '../components/MissionTemplates/LeaderDropdown';

import { deriveObjectiveTitleFromStatement } from '../utils/helpers';

import {
  selectMissionLoading,
  selectSelectedMissionObjectives,
  selectMissionObjectivesPagination,
  selectObjectiveLoading,
  selectSelectedMission,
  selectMissionTimeline,
  selectMissionTimelineLoading
} from '../state/mission/reducers';
import { selectLoggedInUserId } from '../state/auth/reducers';
import { selectSelectedCommunityId } from '../state/community/reducers';

import * as missionTypes from '../state/mission/types';
import * as userTypes from '../state/users/types';
import * as modalTypes from '../state/modal/types';

const styles = {
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    margin: '25px 0px 10px 0px'
  },
  mr10: {
    marginRight: 10
  }
};


class MissionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabFilter: 'objectives',
      objectiveDrawerOpen: false
    };

    // bootstrap mission
    // maybe check permissions
    const { getMission, getUser, getMissionObjectives, getMissionTimeline } = props;
    const { userId, missionId } = this.extractIdsFromRoute();

    getUser(userId);
    getMission(userId, missionId);
    getMissionObjectives(userId, missionId);
    getMissionTimeline(missionId);
  }

  extractIdsFromRoute = () => {
    const {
      match: { params }
    } = this.props;
    const { communityId, userId, missionId } = params;
    return {
      communityId,
      userId,
      missionId
    };
  };

  parseObjectives = objectives => {
    return objectives.map(ob => ({
      ...ob,
      text: deriveObjectiveTitleFromStatement(ob.statement, ob.isMetric),
      unread: ob.unread,
      dueDate: ob.statement.dueDate
    }));
  };

  // Used for the mission timeline
  getObjectiveText = action => {
    switch (action) {
      case 'marked as in progress':
        return 'marked as In Progress.';

      case 'marked as need help':
        return 'marked as Need Help.';

      case 'marked as no longer relevant':
        return 'marked as No Longer Relevant.';

      case 'marked as complete':
        return 'marked as Complete.';

      default:
        return 'updated';
    }
  };

  getTaskText = action => {
    switch (action) {
      case 'update':
        return 'updated';
      case 'create':
        return 'created';
      default:
        return action;
    }
  };

  // Manipulate API text to something easier on the eye
  getTimelineText = activity => {
    const { kind, entity, description, formattedString, action } = activity;

    switch (kind) {
      case 'LifeEvent':
        return `Life event created: ${description}`;

      case 'Objective':
        return `Objective "${deriveObjectiveTitleFromStatement(
          entity.statement,
          entity.isMetric
        )}" has been ${this.getObjectiveText(action)}`;

      case 'Task':
        return `Action has been ${this.getTaskText(action)}`;

      default:
        return formattedString || description;
    }
  };

  parseTimeline = timeline =>
    timeline.map(t => ({
      date: moment(t.createdOn).format('DD/MM/YY'),
      text: this.getTimelineText(t),
      formattedComment: t.formattedComment || (t.kind === 'LifeEvent' ? 'lifeEvent' : '')
    }));

  openObjectiveDrawer = () => {
    this.setState({ objectiveDrawerOpen: true });
  };

  closeObjectiveDrawer = () => {
    this.setState({ objectiveDrawerOpen: false });
  };

  onStatusChange = status => {
    const { setModalComponent, openModal, updateMissionStatus } = this.props;
    const { userId, missionId } = this.extractIdsFromRoute();

    if (status === 'complete') {
      setModalComponent(modalTypes.MISSION_REFLECTION_MODAL, {
        onSubmit: reflection => updateMissionStatus(userId, missionId, status, reflection)
      });
      openModal();
    } else {
      updateMissionStatus(userId, missionId, status);
    }
  };

  onEditMission = () => {
    const { history, communityId } = this.props;
    const { userId, missionId } = this.extractIdsFromRoute();
    history.push(`/c/${communityId}/u/${userId}/m/${missionId}/edit`);
  };

  onObjectiveFilterChange = ({ page, term, status, sortModel }) => {
    const { getMissionObjectives } = this.props;
    const { userId, missionId } = this.extractIdsFromRoute();
    const sortField = sortModel?.field;
    const sort = sortModel?.sort;

    getMissionObjectives(userId, missionId, page, term, status, sortField, sort);
  };

  onObjectiveRowClick = rowData => {
    const { history } = this.props;
    const { communityId, userId, missionId } = this.extractIdsFromRoute();

    history.push(`/c/${communityId}/u/${userId}/m/${missionId}/o/${rowData.id}`);
  };

  addObjective = newObjective => {
    const { selectedMission, createObjective } = this.props;
    const { userId } = this.extractIdsFromRoute();

    const objective = {
      ...newObjective,
      mission: selectedMission.id,
      statement: {
        ...newObjective.statement,
        daysOnceActive: Number(newObjective.daysOnceActive) || 0,
        currentMetric: newObjective.currentMetric || 0
      }
    };

    createObjective(userId, objective, selectedMission);
  };

  render() {
    const {
      missionLoading,
      objectivesLoading,
      missionTimeline,
      timelineLoading,
      selectedMission,
      loggedInUserId,
      missionObjectives,
      classes,
      missionObjectivesPagination
    } = this.props;

    const { tabFilter, objectiveDrawerOpen } = this.state;

    const hasObjectives = missionTimeline !== null;
    const missionInitialized = selectedMission !== null;

    const computedData = hasObjectives ? this.parseTimeline(missionTimeline) : [];
    const parsedObjectives = missionObjectives; // this.parseObjectives(missionObjectives);

    // Check if logged in user is leader
    const readOnly = missionInitialized ? selectedMission.leader?._id !== loggedInUserId : true;

    const headerActions = (
      <Grid container spacing={2}>
        <Grid item xs={6} sm={8}>
          {!readOnly && <StatusDropdown
            data-test-id="select-mission-status"
            selected={selectedMission ? selectedMission.status : ''}
            formControlClass={classes.mr10}
            onChange={this.onStatusChange}
            isMission
            readOnly={readOnly}
          />
          }
        </Grid>
        <Grid item xs={6} sm={4}>
          {!readOnly && <Button label="Edit Mission" onClick={this.onEditMission} />}
        </Grid>

        <LeaderDropdown
          mission={selectedMission}
        />

      </Grid>
    );

    return (
      <>
        <PageHeader
          showBack
          routeTitle="Mission"
        // showSelectedUser
        // actions={readOnly ? null : headerActions}
        />
        <MemberNavigationHeader member={selectedMission?.user} />

        {selectedMission ?
          <MissionHeader
            mission={selectedMission}
            actions={
              headerActions
            }
          />
          : ''}
        <Box>
          <Tabs
            value={tabFilter}
            onChange={(e, value) => {
              this.setState({ tabFilter: value });
            }}
          >
            <Tab label="Objectives" value="objectives" />
            <Tab label="Activity" value="activity" />
          </Tabs>

          <Box hidden={tabFilter !== 'objectives'}>
            <SearchableObjectives
              loading={objectivesLoading}
              objectives={parsedObjectives}
              pagination={missionObjectivesPagination}
              onFilterChange={this.onObjectiveFilterChange}
              onAddObjectiveClick={this.openObjectiveDrawer}
              onRowClick={this.onObjectiveRowClick}
            />
          </Box>

          <Box hidden={tabFilter !== 'activity'}>
            <Table
              data={computedData}
              rowCard={MissionTimelineCard}
              loading={timelineLoading}
              noDataMessage="No activity to report."
              readOnly={readOnly}
              minHeight={750}
              usePaper={false}
            />
          </Box>
        </Box>

        <ObjectiveDrawer
          open={objectiveDrawerOpen}
          onClose={this.closeObjectiveDrawer}
          onCreate={this.addObjective}
          onDelete={() => { }}
          onUpdate={() => { }}
          missionEnd={moment(selectedMission?.endDate)}
        />
      </>
    );
  }
}

MissionView.propTypes = {
  match: PropTypes.object.isRequired,
  getMission: PropTypes.func.isRequired,
  missionLoading: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  getMissionObjectives: PropTypes.func.isRequired,
  missionObjectives: PropTypes.array,
  objectivesLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  selectedMission: PropTypes.object,
  loggedInUserId: PropTypes.string.isRequired,
  getMissionTimeline: PropTypes.func.isRequired,
  missionTimeline: PropTypes.array.isRequired,
  timelineLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  updateMissionStatus: PropTypes.func.isRequired,
  setModalComponent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  communityId: PropTypes.string.isRequired,
  missionObjectivesPagination: PropTypes.object.isRequired,
  createObjective: PropTypes.func.isRequired
};

MissionView.defaultProps = {
  missionObjectives: null,
  selectedMission: null
};

const mapStateToProps = state => ({
  missionLoading: selectMissionLoading(state),
  objectivesLoading: selectObjectiveLoading(state),
  missionObjectives: selectSelectedMissionObjectives(state, state.mission.selectedMission?.id),
  missionObjectivesPagination: selectMissionObjectivesPagination(state),
  loggedInUserId: selectLoggedInUserId(state),
  selectedMission: selectSelectedMission(state),
  missionTimeline: selectMissionTimeline(state),
  timelineLoading: selectMissionTimelineLoading(state),
  communityId: selectSelectedCommunityId(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMission: (userId, missionId) => ({
        type: missionTypes.GET_MISSION,
        userId,
        missionId
      }),
      getMissionObjectives: (userId, missionId, page, term, status, orderBy, order) => ({
        type: missionTypes.GET_MISSION_OBJECTIVES,
        userId,
        missionId,
        page,
        term,
        status,
        orderBy,
        order
      }),
      getMissionTimeline: missionId => ({
        type: missionTypes.GET_MISSION_TIMELINE,
        missionId
      }),
      getUser: userId => ({
        type: userTypes.GET_USER,
        userId
      }),
      updateMissionStatus: (userId, missionId, status, reflection) => ({
        type: missionTypes.UPDATE_MISSION_STATUS,
        userId,
        missionId,
        status,
        reflection
      }),
      setModalComponent: (component, props) => ({
        type: modalTypes.MODAL_SET_COMPONENT,
        component,
        props
      }),
      openModal: () => ({
        type: modalTypes.MODAL_SET_OPEN_STATE,
        state: true
      }),
      createObjective: (userId, objective, mission) => ({
        type: missionTypes.CREATE_OBJECTIVE,
        userId,
        objective,
        mission
      })
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(MissionView)));
