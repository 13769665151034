import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from '../../components/Button/Button';

import * as modalTypes from '../../state/modal/types';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#223f63',
    marginBottom: 20
  },
  errorWrap: {
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
    minheight: 230,
    maxHeight: 370,
    overflowY: 'auto',
    padding: 19,
    marginBottom: 20,
    width: 410,
    '& p': {
      fontSize: 14
    }
  },
  userWrap: {
    marginBottom: 10
  },
  list: {
    padding: 0
  },
  emphasis: {
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  listItem: {
    display: 'block',
    paddingTop: 0
  }
});

const TemplateAssignmentErrorsModal = ({ assignmentErrors }) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Event handlers
  function closeModal() {
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
  }

  return (
    <Box className={classes.wrap}>
      <Typography className={classes.title}>
        Not all of the templates could be assigned
      </Typography>
      <Box className={classes.errorWrap}>
        {assignmentErrors.map(({ userName, errors }) => (
          <div className={classes.userWrap}>
            <Typography>
              <span className={classes.emphasis}>Member:</span> {userName}
            </Typography>
            <Typography className={classes.emphasis}>Errors:</Typography>
            <List className={classes.list}>
              {errors.map(({ templateName, error }) => (
                <ListItem className={classes.listItem}>
                  <Typography>
                    <span className={classes.emphasis}>Template:</span>{' '}
                    {templateName}
                  </Typography>
                  <Typography>
                    <span className={classes.emphasis}>Message:</span>{' '}
                    {error.message}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        ))}
      </Box>
      <Button
        label="Okay"
        onClick={closeModal}
        className={classes.button}
        background="grey"
      />
    </Box>
  );
};

TemplateAssignmentErrorsModal.propTypes = {
  assignmentErrors: PropTypes.array.isRequired
};

export default TemplateAssignmentErrorsModal;
