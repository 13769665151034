import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { debounce } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ObjectivesTable from './ObjectivesTable';
import SearchInput from '../SearchInput/SearchInput';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const useStyles = makeStyles(theme => ({
  mb10: {
    marginBottom: 10
  },
  searchInput: {
    height: '100%'
  }
}));

const SearchableObjectives = ({
  loading,
  objectives,
  pagination,
  showAssignedUser,
  onFilterChange,
  onAddObjectiveClick,
  onRowClick
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(pagination.page);
  const [search, setSearch] = useState('');
  const [term, setTerm] = useState(null);
  const [status, setStatus] = useState('');
  const [sortModel, setSortModel] = useState({ field: null, sort: null });

  const delayedTerm = useCallback(
    debounce(q => setTerm(q), 500),
    []
  );

  const statuses = [
    {
      label: 'inactive',
      value: 'inactive'
    },
    {
      label: 'complete',
      value: 'complete'
    },
    {
      label: 'in progress',
      value: 'in progress'
    },
    {
      label: 'need help',
      value: 'need help'
    },
    {
      label: 'no longer relevant',
      value: 'no longer relevant'
    }
  ];

  const onSortChange = params => {
    const paramsModel = Array.isArray(params.sortModel) ? params.sortModel[0] : params.sortModel;

    if (paramsModel !== sortModel) {
      setSortModel(paramsModel);
    }
  };

  const onPageChange = value => {
    setPage(value);
  };

  const fireFilterChange = () => {
    onFilterChange({
      page,
      term,
      status,
      sortModel
    });
  };

  useUpdateEffect(() => {
    fireFilterChange();
  }, [page]);

  useUpdateEffect(() => {
    if (page === 1) {
      fireFilterChange();
    } else {
      setPage(1);
    }
  }, [status, term, sortModel]);

  return (
    <Box>
      <Grid container justify="space-between" spacing={1} className={classes.mb10}>
        <Grid item xs={12} md={6} lg={7} xl={9}>
          <SearchInput
            value={search}
            onChange={e => {
              setSearch(e);
              delayedTerm(e);
            }}
            showFocus={false}
            className={classes.searchInput}
            placeholder="Search objective and mission titles"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5} xl={3} style={{ display: 'flex' }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Dropdown
                showNoneOption
                shrink={false}
                options={statuses}
                selected={status}
                onChange={e => {
                  setStatus(e.target.value);
                }}
                style={{ flexGrow: 1, width: '100%' }}
                placeholder="Filter by"
              />
            </Grid>

            {onAddObjectiveClick && (
              <Grid item xs={5}>
                <Button fullWidth label="ADD OBJECTIVE" onClick={onAddObjectiveClick} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <ObjectivesTable
          loading={loading}
          objectives={objectives}
          pagination={pagination}
          sortingMode="server"
          sortModel={sortModel}
          showAssignedUser={showAssignedUser}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onRowClick={onRowClick}
        />
      </Box>
    </Box>
  );
};

SearchableObjectives.propTypes = {
  loading: PropTypes.bool,
  objectives: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }),
  showAssignedUser: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onAddObjectiveClick: PropTypes.func,
  onRowClick: PropTypes.func
};

SearchableObjectives.defaultProps = {
  loading: false,
  pagination: null,
  showAssignedUser: false,
  onAddObjectiveClick: null,
  onRowClick: null
};

export default SearchableObjectives;
