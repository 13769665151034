import { all } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import userSagas from './users/sagas';
import communitySagas from './community/sagas';
import bootstrapSagas from './bootstrap/sagas';
import missionSagas from './mission/sagas';
import teamSaga from './teams/sagas';
import chatSaga from './chat/sagas';
import templateSaga from './templates/sagas';
import reportSaga from './reports/sagas';
import communityMissionSaga from './communityMission/sagas';
import toolkitSaga from './toolkits/sagas';
import toolkitCategorySaga from './toolkitCategories/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...userSagas,
    ...communitySagas,
    ...bootstrapSagas,
    ...missionSagas,
    ...teamSaga,
    ...chatSaga,
    ...templateSaga,
    ...reportSaga,
    ...communityMissionSaga,
    ...toolkitSaga,
    ...toolkitCategorySaga
  ]);
}
