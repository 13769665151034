import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';

const useStyles = makeStyles({
  instructions: {
    maxWidth: 480,
    fontSize: 14
  },
  backButton: {
    marginRight: 10
  }
});

const StepActions = ({
  step,
  onBack,
  onShowAssignUsers,
  hasUsers,
  hasTemplates,
  onAssignTemplates
}) => {
  const classes = useStyles();

  // Constants
  const canGoBack = step > 1;
  const continueDisabled =
    (!hasUsers && step === 2) || (!hasTemplates && step === 1);

  const continueFunction = canGoBack ? onAssignTemplates : onShowAssignUsers;

  const continueLabels = {
    1: 'Assign Members',
    2: 'Assign & Finish'
  };

  const backLabels = {
    2: 'Back to Select Missions'
  };

  return (
    <>
      <Typography className={classes.instructions}>
        Mission Templates are repeatable missions that are created by community
        admins and leaders. Leaders are able to assign mission templates to their
        members.
      </Typography>
      <Box display="flex" justifyContent="flex-end" marginBottom={1.25}>
        {canGoBack && (
          <Button
            label={backLabels[step]}
            onClick={onBack}
            className={classes.backButton}
          />
        )}
        <Button
          label={continueLabels[step]}
          onClick={continueFunction}
          disabled={continueDisabled}
        />
      </Box>
    </>
  );
};

StepActions.propTypes = {
  step: PropTypes.number.isRequired,
  onShowAssignUsers: PropTypes.func.isRequired,
  onAssignTemplates: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  hasUsers: PropTypes.bool.isRequired,
  hasTemplates: PropTypes.bool.isRequired
};

export default StepActions;
