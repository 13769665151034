import * as types from './types';

const initialState = {
  open: false,
  content: null,
  props: {
    variant: 'info'
  }
};

const snackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SNACK:
      return {
        ...state,
        content: action.content,
        open: action.open,
        props: action.props
      };

    case types.SET_SNACK_CONTENT:
      return {
        ...state,
        content: action.content
      };

    case types.SET_SNACK_STATE:
      return {
        ...state,
        open: action.open
      };

    case types.DANGER_SNACK:
      return {
        ...state,
        content: action.content,
        open: true,
        props: { variant: 'error' }
      };

    case types.SUCCESS_SNACK:
      return {
        ...state,
        content: action.content,
        open: true,
        props: { variant: 'success' }
      };

    default:
      return state;
  }
};

export default snackReducer;

export const selectSnackState = state => state.snack;
