import * as types from './types';

const initialState = {
  loadingState: false,
  savingState: false,
  toolkitCreated: false,
  toolkitsAssigned: false,
  toolkits: [],
  userToolkits: [],
  apiErrors: null,
  selectedToolkit: undefined
};

const toolkitReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOOLKIT_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.TOOLKIT_SAVING_STATE:
      return {
        ...state,
        savingState: action.state
      };

    case types.CREATE_TOOLKIT_SUCCESS:
      return {
        ...state,
        toolkitCreated: true,
        apiErrors: null
      };

    case types.EDIT_TOOLKIT_SUCCESS:
      return {
        ...state,
        apiErrors: null
      };

    case types.DELETE_TOOLKIT_SUCCESS:
      return {
        ...state,
        apiErrors: null
      };

    case types.ASSIGN_TOOLKITS_SUCCESS:
      return {
        ...state,
        apiErrors: null
      };

    case types.CLEAR_TOOLKIT_CREATED:
      return {
        ...state,
        toolkitCreated: false
      };

    case types.GET_TOOLKITS_SUCCESS:
      return {
        ...state,
        toolkits: action.toolkits
      };

    case types.GET_USER_TOOLKITS_SUCCESS:
      return {
        ...state,
        userToolkits: action.userToolkits
      };

    case types.GET_TOOLKIT_SUCCESS:
      return {
        ...state,
        selectedToolkit: action.toolkit
      };

    case types.REMOVE_TOOLKIT_FROM_USER_SUCCESS:
      return {
        ...state,
        userToolkits: action.userToolkits
      };

    case types.CREATE_TOOLKIT_ERROR:
    case types.EDIT_TOOLKIT_ERROR:
    case types.ASSIGN_TOOLKITS_ERROR:
    case types.GET_TOOLKITS_ERROR:
    case types.GET_USER_TOOLKITS_ERROR:
    case types.REMOVE_TOOLKIT_FROM_USER_ERROR:
    case types.DELETE_TOOLKIT_ERROR:
      return {
        ...state,
        apiErrors: action.errors
      };

    default:
      return state;
  }
}

export default toolkitReducer

export const selectToolkits = state => state.toolkits.toolkits;
export const selectToolkitsLoadingState = state => state.toolkits.loadingState;
export const selectUserToolkits = state => state.toolkits.userToolkits;
export const selectSelectedToolkit = state => state.toolkits.selectedToolkit;