import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';

import { ReactComponent as SearchIcon } from '../../static/images/icons/icon-search.svg';

const styles = makeStyles({
  paper: {
    padding: '6px 20px 8px 6px !important',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: '#f7f7f7'
  },
  icon: {
    marginRight: 10
  },
  inputRoot: {
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#dcdcdc',
    '& svg': {
      color: '#adb6c1'
    },
    '&::placeholder': {
      fontSize: 10
    }
  },
  input: {
    padding: 0,
    fontSize: 12,
    color: '#223f63'
    // border: 'solid 1px transparent'
  },
  focus: {
    border: 'solid 1px #223f63'
  }
});

const SearchInput = props => {
  const classes = styles();
  const { placeholder, onChange, style, value, className, showFocus, ...rest } = props;

  return (
    <InputBase
      style={style}
      value={value}
      className={clsx(classes.input, className)}
      classes={{
        root: clsx(classes.inputRoot, classes.paper),
        input: classes.input,
        focused: showFocus && classes.focus
      }}
      startAdornment={<SearchIcon className={classes.icon} />}
      placeholder={placeholder}
      onChange={event => onChange(event.target.value)}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  showFocus: PropTypes.bool
};

SearchInput.defaultProps = {
  placeholder: '',
  style: null,
  value: null,
  className: null,
  showFocus: true
};

export default SearchInput;
