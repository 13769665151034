import axios from 'axios';

export const getAllMissions = async (userId, role, communityId = null) => {
  try {
    const url = communityId
      ? `/v1/users/${userId}/missions?role=${role}&communityID=${communityId}`
      : `/v1/users/${userId}/missions?role=${role}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMission = async (userId, missionId) => {
  try {
    const result = await axios.get(`/v1/users/${userId}/missions/${missionId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMissionProfileImage = async (missionId) => {
  try {
    const result = await axios.get(`/v1/missions/${missionId}/profilePicture`, {
      responseType: 'blob'
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (missionId, formData) => {
  try {
    const result = await axios.post(
      `/v1/missions/${missionId}/profilePicture`,
      formData
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMission = async (userId, missionId) => {
  try {
    const result = await axios.delete(`/v1/template/${userId}/missions/${missionId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const searchAllMissionsInCommunity = async (userId, role, communityId, status, term, limit = 10, skip = 0) => {
  try {
    const query = [
      !!userId && `userUuid=${userId}`,
      !!role && `role=${role}`,
      !!status && `status=${status}`,
      !!term && `term=${term}`,
      `limit=${limit}`,
      `skip=${skip}`
    ].filter(Boolean);

    const result = await axios.get(`/v1/communities/${communityId}/search/all-missions?${query.join('&')}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMissionObjectives = async (userId, missionId, term, status, limit, skip, orderBy, order) => {
  try {
    const query = [
      !!status && `status=${status}`,
      !!term && `term=${term}`,
      `limit=${limit}`,
      `skip=${skip}`,
      !!orderBy && `sort=${orderBy}`,
      !!order && `order=${order}`
    ].filter(Boolean);

    const result = await axios.get(`/v1/users/${userId}/missions/${missionId}/objectives?${query.join('&')}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getObjective = async (userId, missionId, objectiveId) => {
  try {
    const result = await axios.get(`/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserObjectives = async (userId, leaderId = null) => {
  try {
    const leaderQuery = leaderId ? `?leader=${leaderId}` : '';
    const url = `/v1/users/${userId}/objectives${leaderQuery}`;

    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getTasks = async (userId, missionId, objectiveId) => {
  try {
    const result = await axios.get(`/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/tasks`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addCommunityTask = async (communityId, { title, description, dueDate }) => {
  try {
    const result = await axios.post(`/v1/communities/${communityId}/tasks`, {
      title,
      description,
      dueDate
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const postComment = async (userId, missionId, objectiveId, comment, leaderId) => {
  try {
    const result = await axios.patch(`/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/comments`, {
      comment,
      user: leaderId
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllObjectives = async userId => {
  try {
    const result = await axios.get(`/v1/users/${userId}/objectives`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMissionTimeline = async missionId => {
  try {
    const result = await axios.get(`/v1/mission/${missionId}/activity`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateMissionStatus = async (userId, missionId, status, reflection) => {
  const result = await axios.patch(`/v1/users/${userId}/missions/${missionId}/status`, { status, reflection });
  return result.data;
};

export const createNewMission = async (userId, mission, communityId) => {
  const result = await axios.post(`/v1/users/${userId}/missions`, {
    ...mission,
    community: communityId
  });
  return result.data;
};

export const archiveMission = async (userId, missionId) => {
  const result = await axios.delete(`/v1/users/${userId}/missions/${missionId}`);
  return result.data;
};

export const updateObjectiveStatus = async (userId, objectiveId, status, comment) => {
  const payload = comment ? { status, comment } : { status };
  const result = await axios.patch(`/v1/users/${userId}/objectives/${objectiveId}/status`, payload);
  return result.data;
};

export const getObjectiveSystemComments = async (userId, objectiveId) => {
  const result = await axios.get(`/v1/users/${userId}/objectives/${objectiveId}/activity`);
  return result.data;
};

export const markTaskAsComplete = async (userId, missionId, objectiveId, taskId, isComplete) => {
  const result = await axios.patch(
    `/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/tasks/${taskId}/status`,
    { isComplete }
  );
  return result.data;
};

export const deleteTask = async (userId, missionId, objectiveId, taskId) => {
  const result = await axios.delete(
    `/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/tasks/${taskId}`
  );
  return result.data;
};

export const archiveObjective = async (userId, objectiveId) => {
  const result = await axios.delete(`/v1/users/${userId}/objectives/${objectiveId}`);
  return result.data;
};

export const updateObjective = async (userId, objectiveId, updates) => {
  const result = await axios.put(`/v1/users/${userId}/objectives/${objectiveId}`, updates);
  return result.data;
};

export const updateMission = async (userId, missionId, updates) => {
  const result = await axios.put(`/v1/users/${userId}/missions/${missionId}`, updates);
  return result.data;
};

export const createObjective = async (userId, objective) => {
  const result = await axios.post(`/v1/leader/users/${userId}/objectives`, objective);
  return result.data;
};

export const createObjectivePractice = async (
  userId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency,
  objective
) => {
  const result = await axios.post(`/v1/users/${userId}/practices`, {
    title,
    description,
    richDescription,
    countPerWeek,
    reminderFrequency,
    objective
  });
  return result.data;
};

export const updateObjectivePractice = async (
  userId,
  practiceId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency
) => {
  const result = await axios.put(`/v1/users/${userId}/practices/${practiceId}`, {
    title,
    description,
    richDescription,
    countPerWeek,
    reminderFrequency
  });
  return result.data;
};

export const deleteObjectivePractice = async (userId, practiceId) => {
  const result = await axios.delete(`/v1/users/${userId}/practices/${practiceId}`);
  return result.data;
};

// Tasks

export const createTask = async (userId, missionId, objectiveId, payload={}) => {
  const { task, description, richDescription, dueDate } = payload
  const result = await axios.post(`/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/tasks`, {
    task,
    description,
    richDescription,
    dueDate
  });
  return result.data;
};

export const updateTask = async (userId, missionId, objectiveId, payload={}) => {
  const { task, description, richDescription, dueDate, _id } = payload
  const result = await axios.put(`/v1/users/${userId}/missions/${missionId}/objectives/${objectiveId}/tasks/${_id}`, {
    task,
    description,
    richDescription,
    dueDate,
    mission: missionId,
    objective: objectiveId
  });
  return result.data;
};

export const archiveMissionByTempate = async (missionId) => {
  const result = await axios.delete(`/v1/template/mission/${missionId}`);
  return result.data;
};

export const fetchMissionStats = async (uuid) => {
  const { data } = await axios.get(`/v1/users/${uuid}/missions/stats`);
  return data?.data?.results
}