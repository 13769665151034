import React, { useEffect, useState } from 'react';
import { Switch, Route, useParams, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticatedView from '../system/AuthenticatedView';
import Authenticated404 from './Authenticated404';

import PageHeader from '../components/PageHeader/PageHeader';
import MemberProfile from '../components/Members/MemberProfile';
import EditUserDrawer from '../components/EditUserDrawer/EditUserDrawer';
import MemberOverview from '../components/Members/MemberOverview';
import MemberHeaderNavigation from '../components/Members/MemberHeaderNavigation';

import * as communityTypes from '../state/community/types';
import * as userTypes from '../state/users/types';
import * as teamTypes from '../state/teams/types';

import {
  selectLeaderUsers,
  selectOfficerMembers,
  selectSelectedCommunityId,
  selectCurrentUserCommunityPermissions,
  selectCommunityLeaders,
  selectCommunityUsers
} from '../state/community/reducers';

import { selectSelectedUserLeaders } from '../state/users/reducers';

import { selectIsOfficer, selectLoggedInUserId } from '../state/auth/reducers';

import { getPermissionsArray, getUserCommunityLeaders } from '../utils/helpers';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    rowGap: '20px'
  }
});

const ViewMember = () => {
  const [showEdit, setShowEdit] = useState(false);

  const closeEditDrawer = () => setShowEdit(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { userId, role } = useParams();
  const isOfficerView = role === 'o';
  const isLeaderView = role === 'l';
  const isAdminView = role === 'a';

  const leaderMembers = useSelector(state => selectLeaderUsers(state));
  const officerMembers = useSelector(state => selectOfficerMembers(state));
  const communityMembers = useSelector(state => selectCommunityUsers(state));

  const viewMembers = isOfficerView ? officerMembers : leaderMembers;
  const computedMembers = isAdminView ? communityMembers : viewMembers;

  const selectedMember = computedMembers.find(m => m._id === userId);
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const rolesInCommunity = getPermissionsArray(selectedMember?.memberships, communityId);

  const authUser = useSelector(state => selectLoggedInUserId(state));

  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const isLeaderOfUser = selectedMember?.leaders
    ?.find(c => c.communityId === communityId)
    ?.leaders?.some(l => l._id === authUser);

  const isLeader = permissions.some(p => p === 'leader');
  const isAdmin = permissions.some(p => p === 'admin');

  const canSeeActivity = isLeaderOfUser || isOfficer;
  const canSeeOverview = canSeeActivity;

  // Redirect if unauthorized to be here
  useEffect(() => {
    // No valid role in url
    if (!['l', 'o', 'a'].some(r => r === role)) {
      history.push('/');
    }

    // Non leader or officer accessing leader view
    if (!isLeader && !isOfficer && isLeaderView) history.push('/');

    // Non officer accessing officer view
    if (!isOfficer && isOfficerView) history.push('/');
  }, [role, isOfficer, permissions, history]);

  // Get officer data is authorized and relevant
  useEffect(() => {
    if (isOfficer && isOfficerView) {
      // Get the officers members
      dispatch({
        type: communityTypes.GET_OFFICER_MEMBERS,
        leaderId: authUser
      });
    }
  }, [isOfficer, isOfficerView]);

  const setMember = user => {
    dispatch({
      type: userTypes.SET_SELECTED_USER,
      user,
      leaders: isOfficerView ? getUserCommunityLeaders(user) : getUserCommunityLeaders(user, communityId)
    });
  };

  // Set the member as selectedMember - currently needed for leaders
  useEffect(() => {
    if (selectedMember) {
      setMember(selectedMember);
      if (!isAdminView) {
        dispatch({ type: userTypes.GET_USER_LIFE_EVENTS, userId: selectedMember._id });
      }
    }
  }, [selectedMember, isAdminView]);

  // Get leader data if authorized and relevant
  useEffect(() => {
    if ((isLeader || isOfficer) && !isOfficerView) {
      // Get the leaders users
      // TODO: Performance Bug - validate this isn't required
      // dispatch({
      //   type: communityTypes.GET_LEADER_USERS,
      //   communityId,
      //   userId: isOfficer ? null : authUser
      // });
      // Get the community teams
      // TODO: Performance Bug - validate this isn't required
      // dispatch({
      //   type: teamTypes.GET_ALL_TEAMS,
      //   communityId,
      //   leaderReq: true
      // });
    }

    if (isAdminView && isAdmin) {
      // Get the community members
      dispatch({
        type: communityTypes.GET_COMMUNITY_USERS,
        communityId
      });
    }
  }, [isLeader, isOfficerView, isAdminView, dispatch, communityId, authUser]);

  // Get officer data is authorized and relevant
  useEffect(() => {
    if (isOfficer && isOfficerView) {
      // Get the officers members
      dispatch({
        type: communityTypes.GET_OFFICER_MEMBERS,
        leaderId: authUser
      });
    }
  }, [isOfficer, isOfficerView]);

  const canChat = leaderMembers.some(u => u._id === userId);

  // Users leaders
  const userLeaders = useSelector(state => selectSelectedUserLeaders(state));
  const communityLeaders = useSelector(state => selectCommunityLeaders(state));
  const computedLeaders =
    communityLeaders.length > 0 ? userLeaders.filter(l => communityLeaders.some(cl => cl._id === l._id)) : userLeaders;

  const Header = () => (
    <MemberHeaderNavigation member={selectedMember} canChat={canChat} />
    // <MemberHeader
    //   userId={userId}
    //   userName={`${selectedMember?.firstName} ${selectedMember?.lastName}`}
    //   userDescription={selectedMember?.biography}
    //   hasData={Boolean(selectedMember)}
    // />
  );

  return (
    <main>
      <Switch>
        <Route exact path="/c/:communityId/u/:userId/:role/overview">
          {isAdmin && !canSeeOverview && <Redirect to={`/c/${communityId}/u/${userId}/${role}/profile`} />}
          <PageHeader routeTitle="Member overview" showBack />
          <div className={classes.headerContainer}>
            <Header />
            {/* <MemberNavigation
              showProfile
              showCode
              canChat={canChat}
              openChat={openChat}
              showEdit={isAdmin}
              openEdit={openEditDrawer}
            /> */}
          </div>
          <MemberOverview
            selectedMember={selectedMember}
            isAdmin={isAdmin}
            isAdminView={isAdminView}
            isOfficer={isOfficer}
            isOfficerView={isOfficerView}
            userId={userId}
            communityId={communityId}
            role={isLeaderOfUser ? 'leader' : 'communityAdmin'}
          />
        </Route>
        {/* TODO: this route is no longer use. Double check before removing it from the code 25/06/2021
        <Route exact path="/c/:communityId/u/:userId/:role/activity">
          {isAdmin && !canSeeActivity && <Redirect to={`/c/${communityId}/u/${userId}/${role}/profile`} />}
          <PageHeader routeTitle="Member activity" showBack />
          <div className={classes.headerContainer}>
            <Header />

           
          </div>
          <MemberActivity selectedMember={selectedMember} />
        </Route>
        */}
        <Route exact path="/c/:communityId/u/:userId/:role/profile">
          <PageHeader routeTitle="Member profile" showBack />
          <div className={classes.headerContainer}>
            <Header />
            {/* <MemberNavigation
              showActivity={canSeeActivity}
              showCode
              canChat={canChat}
              openChat={openChat}
              showEdit={isAdmin}
              openEdit={openEditDrawer}
            /> */}
          </div>
          <MemberProfile
            roles={rolesInCommunity}
            birthday={selectedMember?.birthday}
            phoneNumber={selectedMember?.phoneNumber}
            email={selectedMember?.email}
            leaders={computedLeaders}
            hasData={Boolean(selectedMember)}
          />
        </Route>
        {/*
        <Route exact path="/c/:communityId/u/:userId/:role/personal-code">
          <PageHeader routeTitle="Member personal code" showBack />
          <div className={classes.headerContainer}>
            <Header />            
          </div>
          <MemberPersonalCode firstName={selectedMember?.firstName} framework={selectedMember?.psychFramework} />
        </Route>
        */}
        <Route render={() => <AuthenticatedView component={Authenticated404} showHeader={false} />} />
      </Switch>
      <EditUserDrawer open={showEdit} readOnly={!isAdmin} handleClose={closeEditDrawer} />
    </main>
  );
};

export default ViewMember;
