import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = makeStyles(theme => ({
  w100: {
    width: '100%'
  },
  labelRoot: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    zIndex: 1,
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 7,
    paddingLeft: 21,
    pointerEvents: 'none'
  },
  labelFocused: {
    color: '#838b96 !important'
  },
  labelShrink: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 15
  },
  labelError: {
    color: `${theme.palette.error.main} !important`
  },
  inputRoot: {
    fontFamily: 'SFUIText-Reg',
    backgroundColor: '#f4f4f4',
    color: '#223f63',
    borderRadius: 10,
    paddingLeft: 21,
    paddingRight: 21,
    fontSize: 12,
    minHeight: 40,
    border: 'solid 1px #d6d6d6'
  },
  whiteInput: {
    backgroundColor: '#ffffff !important'
  },
  inputAdornmentStart: {
    paddingLeft: '10px !important'
  },
  inputAdornmentEnd: {
    paddingRight: '10px !important'
  },
  inputFocused: {
    border: 'solid 1px #223f63'
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  helperRoot: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10,
    textAlign: 'end',
    paddingRight: 7
  },
  multiline: {
    paddingTop: 11,
    paddingBottom: 11
  }
}));

export default function TextInput({
  label,
  value,
  name,
  required,
  disabled,
  onChange,
  multiline,
  rows,
  errorMessage,
  startAdornment,
  endAdornment,
  whiteInput,
  formControlStyle,
  helperMessage,
  className,
  type,
  onClick,
  onKeyDown,
  placeholder,
  showOptional,
  showError,
  minNumber,
  maxNumber,
  maxLength
}) {
  const classes = styles();

  const error = Boolean(errorMessage);
  const hasErrorWithMessage = error && errorMessage !== '';
  const hasHelperNoError = !error && errorMessage === '' && helperMessage !== '';
  const hasPlaceholder = Boolean(placeholder);
  const hasValue = Boolean(value) || value === 0;

  const formControlClass = clsx({
    [classes.w100]: true,
    [className]: Boolean(className)
  });

  return (
    <FormControl className={formControlClass} error={error} style={formControlStyle}>
      {label && (
        <InputLabel
          disabled={disabled}
          shrink={hasPlaceholder || hasValue}
          classes={{
            root: classes.labelRoot,
            focused: classes.labelFocused,
            shrink: classes.labelShrink,
            error: classes.labelError
          }}
        >
          {label}
          {required && <span style={{ color: '#bc314f' }}>{' * '}</span>}
          {!required && showOptional && (
            <span style={{ color: '#adb6c1', fontStyle: 'italic', fontWeight: 400 }}>{' (Optional)'}</span>
          )}
        </InputLabel>
      )}
      <Input
        name={name}
        required={required}
        disabled={disabled}
        disableUnderline
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        value={value}
        multiline={multiline}
        rows={rows}
        type={type}
        onClick={onClick}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        inputProps={{
          min: minNumber,
          max: maxNumber,
          maxLength
        }}
        classes={{
          root: clsx({
            [classes.inputRoot]: true,
            [classes.whiteInput]: whiteInput,
            [classes.inputAdornmentStart]: startAdornment !== null,
            [classes.inputAdornmentEnd]: endAdornment !== null
          }),
          focused: classes.inputFocused,
          error: classes.inputError,
          multiline: classes.multiline
        }}
        onChange={onChange}
      />
      {hasHelperNoError && <FormHelperText classes={{ root: classes.helperRoot }}>{helperMessage}</FormHelperText>}
      {hasErrorWithMessage && showError && (
        <FormHelperText classes={{ root: classes.helperRoot }}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  whiteInput: PropTypes.bool,
  formControlStyle: PropTypes.object,
  helperMessage: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  showOptional: PropTypes.bool,
  showError: PropTypes.bool,
  minNumber: PropTypes.number,
  maxNumber: PropTypes.number,
  maxLength: PropTypes.number
};

TextInput.defaultProps = {
  label: '',
  required: false,
  errorMessage: '',
  startAdornment: null,
  endAdornment: null,
  onChange: null,
  value: '',
  name: '',
  disabled: false,
  multiline: false,
  rows: 3,
  whiteInput: false,
  formControlStyle: null,
  helperMessage: '',
  className: '',
  onClick: null,
  onKeyDown: null,
  type: 'text',
  placeholder: '',
  showOptional: false,
  showError: true,
  minNumber: null,
  maxNumber: null,
  maxLength: null
};
