import { call, put, takeLatest } from 'redux-saga/effects';
import * as toolkitCategoryTypes from './types';
import * as modalTypes from '../modal/types';
import * as toolkitCategoryActions from './actions';
import * as snackTypes from '../snack/types';

function* createToolkitCategory({payload}) {
  try {
    yield put({type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: true});

    yield call(toolkitCategoryActions.createToolkitCategory, payload);

    yield put({ type: toolkitCategoryTypes.CREATE_TOOLKIT_CATEGORY_SUCCESS });

    yield put({ type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES});

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit category successfully created.',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: toolkitCategoryTypes.CREATE_TOOLKIT_CATEGORY_ERROR,
      errors: {
        key: toolkitCategoryTypes.CREATE_TOOLKIT_CATEGORY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({ type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: false });
  }
}

function* editToolkit({ payload, toolkitCategoryId }) {
  try {
    yield put({type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: true});

    yield call(toolkitCategoryActions.editToolkitCategory, toolkitCategoryId, payload);

    yield put({ type: toolkitCategoryTypes.EDIT_TOOLKIT_CATEGORY_SUCCESS });

    yield put({ type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES});

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit category successfully updated.',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: toolkitCategoryTypes.EDIT_TOOLKIT_CATEGORY_ERROR,
      errors: {
        key: toolkitCategoryTypes.EDIT_TOOLKIT_CATEGORY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({ type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: false });
  }
}

function* getToolkitCategories({ showProgress = false }) {
  try {
    yield put({ type: toolkitCategoryTypes.TOOLKIT_CATEGORY_LOADING_STATE, state: true });

    const results = yield call(toolkitCategoryActions.getToolkitCategories);

    yield put({ type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES_SUCCESS, toolkitCategories: results });
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    const errResponse = err.response
    yield put({
      type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES_ERROR,
      errors: {
        key: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES_ERROR,
        message: errResponse ? errResponse.data?.message : '',
        errors: errResponse ? errResponse.data?.errors : null
      }
    });
  } finally {
    yield put({ type: toolkitCategoryTypes.TOOLKIT_CATEGORY_LOADING_STATE, state: false });
  }
}

function* deleteToolkit({ toolkitCategoryId }) {
  try {
    yield put({type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: true});

    yield call(toolkitCategoryActions.deleteToolkitCategory, toolkitCategoryId);

    yield put({ type: toolkitCategoryTypes.DELETE_TOOLKIT_CATEGORY_SUCCESS });

    yield put({ type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES});

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit category successfully deleted.',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: toolkitCategoryTypes.DELETE_TOOLKIT_CATEGORY_ERROR,
      errors: {
        key: toolkitCategoryTypes.DELETE_TOOLKIT_CATEGORY_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({type: toolkitCategoryTypes.TOOLKIT_CATEGORY_SAVING_STATE, state: false});
  }
}

export default [
  takeLatest(toolkitCategoryTypes.CREATE_TOOLKIT_CATEGORY, createToolkitCategory),
  takeLatest(toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES, getToolkitCategories),
  takeLatest(toolkitCategoryTypes.EDIT_TOOLKIT_CATEGORY, editToolkit),
  takeLatest(toolkitCategoryTypes.DELETE_TOOLKIT_CATEGORY, deleteToolkit),
]