import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';
import Table from '../Table/Table';
import ObjectivePracticeCard from './ObjectivePracticeCard';

import NoEntityBox from '../Common/NoEntityBox';

const useStyles = makeStyles({
  button: {
    minWidth: 108
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 5
  }
});

const ObjectivePractices = ({ readOnly, practices, onAddPractice, onEditPractice, onDeletePractice }) => {
  // Hooks
  const classes = useStyles();

  // Create the menu actions for each card
  const generateMenuActions = row =>
    [
      onEditPractice && {
        onClick: () => onEditPractice(row),
        label: 'Edit practice'
      },
      onDeletePractice && {
        onClick: () => onDeletePractice(row),
        label: 'Delete practice'
      }
    ].filter(Boolean);

  // Constants
  const hasPractices = practices.length > 0;

  return (
    <Box className={classes.wrap}>
      <Box display="flex" justifyContent="space-between" marginBottom={2} alignItems="center">
        {' '}
        <Typography className={classes.title}>Practices</Typography>
        {!readOnly && onAddPractice && (
          <Button label="Add" className={classes.button} onClick={onAddPractice} data-test-id="btn-practice-add" />
        )}
      </Box>

      {hasPractices ? (
        <Table
          data={practices}
          elevation={0}
          boxShadow="0 4px 8px -1px rgba(0, 0, 0, 0.2)"
          rowCard={ObjectivePracticeCard}
          showEmptyRows={false}
          menuActions={readOnly ? null : generateMenuActions}
        />
      ) : (
        <NoEntityBox text="Create practices for this member to create behavioural change" />
      )}
    </Box>
  );
};

ObjectivePractices.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  practices: PropTypes.array,
  onAddPractice: PropTypes.func,
  onEditPractice: PropTypes.func,
  onDeletePractice: PropTypes.func
};

ObjectivePractices.defaultProps = {
  practices: [],
  onAddPractice: null,
  onEditPractice: null,
  onDeletePractice: null
};

export default ObjectivePractices;
