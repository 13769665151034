import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as UploadIcon } from '../../static/images/icons/icon-upload.svg';

import * as userTypes from '../../state/users/types';
import { selectSelectedUserId, selectProfileImages } from '../../state/users/reducers';

import { getUserProfileImage } from '../../state/users/actions';

const styles = makeStyles({
  button: {
    position: 'relative',
    height: 120,
    width: 120,
    borderRadius: 25,
    boxShadow: '0px 15px 30px -7.5px rgba(0,0,0,0.4)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundColor: '#ffffff'
  },
  pointer: {
    cursor: 'pointer'
  },
  iconContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 30,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 18
  }
});

export default function ProfileImageUpload({ onImageLoad, readOnly, userId }) {
  const classes = styles();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(true);

  // Redux
  const profileImages = useSelector(state => selectProfileImages(state));
  const selectedUserId = useSelector(state => selectSelectedUserId(state));

  const computedUserId = userId || selectedUserId;
  const userImage = profileImages.find(i => i.userId === computedUserId);

  // Event handlers
  function storeInRedux(image) {
    dispatch({
      type: userTypes.STORE_PROFILE_IMAGE,
      userId,
      image
    });
  }

  function setInitialized(image) {
    setSrc(image);
    setLoading(false);
  }

  // Get the profile given users profile image
  useEffect(() => {
    let isSubscribed = true;

    if (!userImage) {
      getUserProfileImage(computedUserId)
        .then(val => {
          if (isSubscribed) {
            if (val) {
              const reader = new FileReader();

              reader.onload = event => {
                const { result } = event.target;
                setInitialized(result);
                storeInRedux(result);
              };

              reader.readAsDataURL(val);
            } else setLoading(false);
          }
        })
        .catch(() => {
          if (isSubscribed) setLoading(false);
        });
    } else setInitialized(userImage.image);

    return () => {
      isSubscribed = false;
    };
  }, [computedUserId, userImage]);

  // Event handlers
  function handleFileChange(e) {
    const { files } = e.target;
    if (files && files[0]) {
      // Send the file to parent
      onImageLoad(files[0]);

      // Read the file and display base64 string
      const reader = new FileReader();

      reader.onload = event => {
        const { result } = event.target;
        setSrc(result);
      };

      reader.readAsDataURL(files[0]);
    }
  }

  function handleFileOpen() {
    if (!readOnly) inputRef.current.click();
  }

  // Classes
  const buttonClass = clsx({
    [classes.button]: true,
    [classes.pointer]: !readOnly
  });

  return (
    <>
      <input
        id="profile-image"
        type="file"
        accept="image/*"
        capture="camera"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={inputRef}
      />
      {loading ? (
        <CircularProgress size={115} />
      ) : (
        <div
          role="presentation"
          onClick={handleFileOpen}
          className={buttonClass}
          style={{ backgroundImage: `url(${src})` }}
        >
          {!readOnly && (
            <div className={classes.iconContainer}>
              <UploadIcon />
            </div>
          )}
        </div>
      )}
    </>
  );
}

ProfileImageUpload.propTypes = {
  onImageLoad: PropTypes.func,
  readOnly: PropTypes.bool,
  userId: PropTypes.string
};

ProfileImageUpload.defaultProps = {
  readOnly: false,
  onImageLoad: null,
  userId: ''
};
