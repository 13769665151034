import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';

import {
  selectPsychFrameworkSaving,
  selectPsychFrameworkSaveKey
} from '../../state/community/reducers';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 5
  },
  description: {
    maxWidth: 480,
    fontSize: 14,
    marginBottom: 10
  },
  paper: {
    borderRadius: 10,
    padding: 20,
    display: 'flex'
  },
  input: {
    flex: 1
  },
  dropdown: {
    maxWidth: 70,
    marginLeft: 20
  },
  button: {
    width: 150
  }
});

export default function QuestionBox({
  title,
  label,
  inputName,
  textValue,
  multiline,
  onTextChange,
  onSubmit,
  showRating,
  rating,
  onRatingChange,
  readOnly,
  description
}) {
  // Hooks
  const classes = useStyles();

  // Local state
  const [error, setError] = useState(false);
  const [initialTextValue, setInitialTextValue] = useState(textValue);
  const [initialRating, setInitialRating] = useState(rating);

  // Redux
  const loading = useSelector(state => selectPsychFrameworkSaving(state));
  const loadingKey = useSelector(state => selectPsychFrameworkSaveKey(state));

  // Event handlers
  function handleSubmit() {
    const hasValue = textValue.trim().length > 0;
    if (hasValue) {
      onSubmit(inputName);
      // Update the initial values
      setInitialTextValue(textValue);
      setInitialRating(rating);
      if (error) setError(false);
    } else {
      setError(true);
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    if (error && value.trim().length > 0) setError(false);
    onTextChange(e);
  }

  // Helper constants
  const dropdownOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const computedRating = rating || 10;
  const isLoading = loading && loadingKey === inputName;
  const textValueChanged = initialTextValue !== textValue;
  const ratingChanged = initialRating !== rating;
  const updated = showRating ? textValueChanged || ratingChanged : textValueChanged;

  return (
    <Box>
      <Typography className={classes.title}>{title}</Typography>
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
      <Paper className={classes.paper}>
        <TextInput
          name={inputName}
          label={label}
          className={classes.input}
          multiline={multiline}
          rows={6}
          onChange={handleChange}
          value={textValue}
          disabled={loading || readOnly}
          error={error}
          errorMessage={error ? 'This cannot be empty' : ''}
        />
        {/* {showRating && (
          <Dropdown
            shrink
            centered
            name={inputName}
            selected={computedRating}
            label="Rate this"
            options={dropdownOptions}
            className={classes.dropdown}
            onChange={onRatingChange}
            disabled={loading || readOnly}
          />
        )} */}
      </Paper>
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
        marginTop={2.5}
        marginBottom={2.5}
      >
        {!readOnly && (
          <Button
            label="Save"
            onClick={handleSubmit}
            className={classes.button}
            disabled={loading || !updated}
            loading={isLoading}
          />
        )}
      </Box>
    </Box>
  );
}

QuestionBox.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  textValue: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  showRating: PropTypes.bool,
  rating: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  onRatingChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  description: PropTypes.string
};

QuestionBox.defaultProps = {
  title: '',
  label: '',
  showRating: false,
  rating: 1,
  multiline: false,
  onRatingChange: null,
  description: ''
};
