import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../components/Button/Button';

import * as modalTypes from '../../state/modal/types';

import usePrevious from '../../hooks/usePrevious';

const useStyles = makeStyles({
  gridContainer: {
    maxWidth: 450,
    flex: 1
  },
  title: {
    fontSize: 24,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#223f63',
    marginBottom: 20
  },
  message: {
    fontSize: 16
  },
  buttonGroup: {
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: 150,
    margin: '0px 10px'
  }
});

export default function ConfirmationModal({
  title,
  message,
  cancelText,
  confirmText,
  onCancel,
  onSubmit,
  savingSelector
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux
  const saving = savingSelector ? useSelector(state => savingSelector(state)) : null;
  const prevSaving = usePrevious(saving);

  // Event handlers
  function closeModal() {
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
  }

  function handleCancel() {
    if (onCancel !== null) return onCancel();
    return closeModal();
  }

  // If no redux save state supplied - close the modal instantly
  function handleSubmit() {
    if (saving === null) closeModal();
    onSubmit();
  }

  // Close when redux save state goes from true to false
  useEffect(() => {
    if (prevSaving && !saving) closeModal();
  }, [saving, prevSaving]);

  // Helper constants
  const hasMessage = message.trim().length > 0;
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <Typography align="center" className={classes.title}>
          {title}
        </Typography>
        {hasMessage && (
          <Typography align="center" className={classes.message}>
            {message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.buttonGroup}>
        <Button
          label={cancelText}
          onClick={handleCancel}
          className={classes.button}
          disabled={saving}
          background="grey"
        />
        <Button
          label={confirmText}
          onClick={handleSubmit}
          className={classes.button}
          loading={saving}
          disabled={saving}
        />
      </Grid>
    </Grid>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  savingSelector: PropTypes.func
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  message: '',
  cancelText: 'No',
  confirmText: 'Yes',
  onCancel: null,
  savingSelector: null,
  onSubmit: null
};
