import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

// import IconPicker from '../IconPicker/IconPicker';
import TextInput from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';

import { getErrorMessage } from '../../constants/validation';
import ProfileImageMissionUpload from './ProfileImageMissionUpload';

const useStyles = makeStyles({
  detailsWrap: {
    backgroundColor: '#dcdcdc',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '40px 16px 32px 16px'
  },
  contentWrap: {
    width: 460,
    margin: '0px auto'
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  mb26: {
    marginBottom: 26
  },
  schedulingWrap: {
    backgroundColor: '#f4f4f4',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: '20px 0px'
  },
  schedulingCopy: {
    display: 'inline-block',
    fontSize: 14,
    maxWidth: 292,
    fontWeight: 300
  },
  formControl: {
    display: 'inline-block',
    maxWidth: 115
  }
});

const MissionDetails = ({
  icon,
  name,
  description,
  startDays,
  onTextChange,
  // onIconChange,
  onImageChange,
  missionId,
  missionTemplateId,
  errors,
  endDate,
  onDateChange,
  isTemplate
}) => {
  // Hooks
  const classes = useStyles();

  return (
    <Box component="section" borderRadius={10}>
      <Box className={classes.detailsWrap}>
        <Box className={classes.contentWrap}>
          <Typography className={classes.title}>Mission details</Typography>
          <Box display="flex" justifyContent="center" marginBottom={2.5}>
            {/* <IconPicker
              icon={icon}
              onSubmit={onIconChange}
              error={getErrorMessage(errors, 'icon')}
            /> */}
            <ProfileImageMissionUpload
              icon={icon}
              errors={errors}
              onImageLoad={onImageChange}
              missionId={missionId}
              missionTemplateId={missionTemplateId}
            />
          </Box>
          <TextInput
            name="title"
            label="Mission name"
            placeholder="Enter a mission name…"
            className={classes.mb26}
            value={name}
            required
            onChange={onTextChange}
            errorMessage={getErrorMessage(errors, 'title')}
          />
          <TextInput
            name="description"
            label="Description"
            placeholder="Enter a mission description…"
            multiline
            rows={4}
            value={description}
            required
            onChange={onTextChange}
            errorMessage={getErrorMessage(errors, 'description')}
            className={isTemplate ? null : classes.mb26}
          />
          {!isTemplate && (
            <Datepicker
              label="End date"
              value={endDate}
              onChange={onDateChange}
              keyboardIcon={<KeyboardArrowDownRoundedIcon />}
              errorMessage={getErrorMessage(errors, 'endDate')}
              required
            />
          )}
        </Box>
      </Box>
      {isTemplate && (
        <Box className={classes.schedulingWrap}>
          <Box className={classes.contentWrap}>
            <Typography className={classes.title}>Scheduling</Typography>
            <Box display="flex" alignItems="flex-end">
              <Typography className={classes.schedulingCopy}>
                Set the number of days the member has to do the mission. Due dates
                start from when the mission is assigned.
              </Typography>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <TextInput
                  className={classes.formControl}
                  whiteInput
                  name="startDays"
                  label="Days"
                  required
                  value={startDays}
                  onChange={onTextChange}
                  errorMessage={getErrorMessage(errors, 'startDays')}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

MissionDetails.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onTextChange: PropTypes.func.isRequired,
  // onIconChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  missionId: PropTypes.string,
  missionTemplateId: PropTypes.string,
  errors: PropTypes.object,
  endDate: PropTypes.instanceOf(moment),
  isTemplate: PropTypes.bool,
  onDateChange: PropTypes.func
};

MissionDetails.defaultProps = {
  errors: null,
  isTemplate: true,
  endDate: moment(),
  onDateChange: null,
  startDays: 30,
  missionId: null,
  missionTemplateId: null,
};

export default MissionDetails;
