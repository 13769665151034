import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  paper: {
    cursor: 'pointer',
    position: 'relative',
    margin: 5,
    padding: 8,
    top: 0,
    transition: 'all 0.1s ease',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    animation: '$glow 1.5s ease-in-out infinite',
    minHeight: 170,
    maxWidth: 140
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  }
});

const TemplateCardsLoading = ({ number }) => {
  const classes = useStyles();
  return [...new Array(number)].map((e, idx) => (
    <Paper key={`loading-${idx}`} className={classes.paper} />
  ));
};

TemplateCardsLoading.propTypes = {
  number: PropTypes.number
};

TemplateCardsLoading.defaultProps = {
  number: 30
};

export default TemplateCardsLoading;
