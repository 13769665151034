import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import UserPermissions from '../EditUserDrawer/UserPermissions';
import AssignedLeaders from '../AssignedLeaders/AssignedLeaders';

import * as userTypes from '../../state/users/types';
import {
  selectUserSavingState,
  selectUserCreated,
  selectUserApiErrors
} from '../../state/users/reducers';

import {
  validateCreateUser,
  parseApiErrors,
  getErrorMessage
} from '../../constants/validation';

const styles = makeStyles({
  drawer: {
    maxWidth: 720,
    marginLeft: 80
  },
  gridContainer: {
    padding: 40
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#838b96'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    width: 150
  },
  mb70: {
    marginBottom: 70
  },
  mb25: {
    marginBottom: 25
  },
  mb15: {
    marginBottom: 15
  },
  pr10: {
    paddingRight: 10
  },
  pl10: {
    paddingLeft: 10
  }
});

export default function CreateUserDrawer({ open, handleClose }) {
  const dispatch = useDispatch();
  const classes = styles();
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isLeader: false,
    isComAdmin: false,
    leaders: []
  });
  const [errors, setErrors] = useState(null);

  // Redux
  const saving = useSelector(state => selectUserSavingState(state));
  const userCreated = useSelector(state => selectUserCreated(state));
  const apiErrors = useSelector(state => selectUserApiErrors(state));

  // Create the success state when drawer opens
  useEffect(() => {
    if (open) {
      dispatch({ type: userTypes.CLEAR_USER_CREATED });
    }
  }, [open]);

  function closeAndReset() {
    setValues({
      firstName: '',
      lastName: '',
      email: '',
      isLeader: false,
      isComAdmin: false,
      leaders: []
    });
    handleClose();
  }

  function handleChange(e) {
    const { name, value } = e.target;

    // If they have submitted and had errors - check on change and update accordingly
    if (errors !== null) {
      const hasError = validateCreateUser({ ...values, [name]: value });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setValues({
      ...values,
      [name]: value
    });
  }

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked
    });
  }

  function handleSubmit() {
    // Check for errors
    const errorMessages = validateCreateUser(values);
    if (errorMessages === undefined) {
      setErrors(null);
      // Everything checks out - submit the payload
      dispatch({
        type: userTypes.CREATE_USER,
        payload: {
          ...values,
          leaders: values.leaders.map(leader => leader.id)
        }
      });
    } else setErrors(errorMessages);
  }

  // Close drawer after successful post
  useEffect(() => {
    if (userCreated) {
      closeAndReset();
    }
  }, [userCreated]);

  function onLeaderSelect(leader) {
    setValues({
      ...values,
      leaders: [...values.leaders, leader]
    });
  }

  function onRemoveLeader(leaderId) {
    setValues({
      ...values,
      leaders: values.leaders.filter(leader => leader.id !== leaderId)
    });
  }

  const { firstName, lastName, email, isLeader, isComAdmin, leaders } = values;

  const computedErrors =
    errors || parseApiErrors(apiErrors, userTypes.CREATE_USER_ERROR);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleClose}
      PaperProps={{ classes: { root: classes.drawer } }}
    >
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6} className={clsx(classes.flexCenter, classes.mb70)}>
          <Typography className={classes.title}>Create user</Typography>
        </Grid>
        <Grid item xs={6} className={clsx(classes.flexEnd, classes.mb70)}>
          <Button
            label="Create"
            className={classes.button}
            onClick={handleSubmit}
            disabled={saving}
            loading={saving}
          />
        </Grid>
        <Grid item xs={6} className={clsx(classes.pr10, classes.mb15)}>
          <TextInput
            name="firstName"
            label="First name"
            value={firstName}
            onChange={handleChange}
            error={Boolean(getErrorMessage(computedErrors, 'firstName'))}
            errorMessage={getErrorMessage(computedErrors, 'firstName')}
          />
        </Grid>
        <Grid item xs={6} className={clsx(classes.pl10, classes.mb15)}>
          <TextInput
            name="lastName"
            label="Last name"
            value={lastName}
            onChange={handleChange}
            error={Boolean(getErrorMessage(computedErrors, 'lastName'))}
            errorMessage={getErrorMessage(computedErrors, 'lastName')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb25}>
          <TextInput
            name="email"
            label="Email address"
            value={email}
            onChange={handleChange}
            error={Boolean(getErrorMessage(computedErrors, 'email'))}
            errorMessage={getErrorMessage(computedErrors, 'email')}
          />
        </Grid>
        <Grid item xs={12}>
          <UserPermissions
            readOnly={false}
            isLeader={isLeader}
            isComAdmin={isComAdmin}
            onChange={handleCheckboxChange}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <AssignedLeaders
            readOnly={false}
            selectedLeaders={leaders}
            assignedLeaders={[]}
            onSelect={onLeaderSelect}
            onRemove={onRemoveLeader}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
}

CreateUserDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
