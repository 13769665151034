import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STORE_PROFILE_IMAGE } from '../state/users/types';

import { selectProfileImages } from '../state/users/reducers';
import { getUserProfileImage } from '../state/users/actions';

import defaultAvatar from '../static/images/default-avatar.png';

const useProfileImage = userId => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const profileImages = useSelector(state => selectProfileImages(state));
  const userImage = profileImages.find(i => i.userId === userId);

  const storeInRedux = image => {
    dispatch({
      type: STORE_PROFILE_IMAGE,
      userId,
      image
    });
  };

  useEffect(() => {
    let isSubscribed = true;

    if (!userImage) {
      if (isSubscribed) setLoading(true);

      getUserProfileImage(userId)
        .then(val => {
          if (isSubscribed) {
            if (val) {
              const reader = new FileReader();

              reader.onload = event => {
                const { result } = event.target;
                setLoading(false);
                storeInRedux(result);
              };

              reader.readAsDataURL(val);
            }
          }
        })
        .catch(() => {
          if (isSubscribed) setLoading(false);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [userId]);

  return {
    image: userImage ? userImage.image : defaultAvatar,
    loading,
    isPlaceholder: !userImage?.image
  };
};

export default useProfileImage;
