import axios from 'axios';

const API_V2_BASE_URL =
    process.env.NODE_ENV === 'production' ? window.__env__.REACT_APP_V2_BASE_URL : process.env.REACT_APP_V2_BASE_URL;

export const createToolkitCategory = async toolkitCategory => {
  try {
    const result = await axios.post('/v1/toolkit-categories', toolkitCategory);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editToolkitCategory = async (toolkitCategoryId, toolkitCategory) => {
  try {
    const result = await axios.put(`/v1/toolkit-categories/${toolkitCategoryId}`, toolkitCategory);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getToolkitCategories = async () => {
  try {
    const result = await axios.get('/v1/toolkit-categories');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteToolkitCategory = async toolkitCategoryId => {
  try {
    const result = await axios.delete(`/v1/toolkit-categories/${toolkitCategoryId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};