import * as types from './types';
import * as authTypes from '../auth/types';
import * as communityTypes from '../community/types';

const initialState = {
  loadingState: false,
  savingState: false,
  apiErrors: null,
  teams: [],
  selectedTeam: null,
  usersPendingChange: []
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TEAM_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.TEAM_SAVING_STATE:
      return {
        ...state,
        savingState: action.state
      };

    case types.GET_TEAM_ERROR:
    case types.GET_ALL_TEAMS_ERROR:
    case types.UPDATE_TEAM_ERROR:
    case types.DELETE_TEAM_ERROR:
    case types.CREATE_TEAM_ERROR:
      return {
        ...state,
        apiErrors: action.errors
      };

    case types.GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.teams
      };

    case types.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.team]
      };

    case types.SET_TEAM:
    case types.GET_TEAM_SUCCESS:
      return {
        ...state,
        selectedTeam: action.team
      };

    case types.DELETE_TEAM_SUCCESS:
    case types.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        selectedTeam: action.team,
        teams: action.teams
      };

    case types.ADD_MEMBERS_TO_TEAM:
      return {
        ...state,
        usersPendingChange: [
          ...state.usersPendingChange,
          ...action.users.map(u => u._id)
        ]
      };

    case types.REMOVE_MEMBER_FROM_TEAM:
      return {
        ...state,
        usersPendingChange: [...state.usersPendingChange, action.userId]
      };

    case types.REMOVE_MEMBER_FROM_TEAM_SUCCESS:
    case types.ADD_MEMBERS_TO_TEAM_SUCCESS:
      return {
        ...state,
        selectedTeam: action.team,
        teams: action.teams,
        usersPendingChange: action.usersPendingChange
      };

    case types.REMOVE_MEMBER_FROM_TEAM_ERROR:
    case types.ADD_MEMBERS_TO_TEAM_ERROR:
      return {
        ...state,
        usersPendingChange: action.usersPendingChange,
        apiErrors: action.errors
      };

    case authTypes.AUTH_LOGOUT:
    case communityTypes.GET_COMMUNITY_SUCCESS:
      return {
        ...initialState
      };

    case types.CLEAR_TEAM_API_ERRORS:
      return {
        ...state,
        apiErrors: null
      };

    default:
      return state;
  }
};

export default teamReducer;

export const selectTeamLoading = state => state.teams.loadingState;
export const selectTeamSaving = state => state.teams.savingState;
export const selectTeams = state => state.teams.teams;
export const selectTeamApiErrors = state => state.teams.apiErrors;
export const selectSelectedTeam = state => state.teams.selectedTeam;
export const selectUsersPendingChange = state => state.teams.usersPendingChange;
