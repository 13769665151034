import { call, put, takeLatest, select, take, spawn } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import history from '../../utils/history';

import * as authServices from './services';
import * as authActions from './actions';
import * as authTypes from './types';
import * as communityTypes from '../community/types';

import * as userActions from '../users/actions';

import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';
import * as userTypes from '../users/types';
// import * as notifyTypes from '../notify/types';
import { initializeFirebaseServices } from '../chat/sagas';

// import { requestPermission } from '../notify/sagas';

import { selectLoggedInUser } from './reducers';
import { selectAllCommunities, selectSelectedCommunityId } from '../community/reducers';

import {
  checkForPermissions,
  getPermissions,
  isLeader,
  isCommunityAdmin,
  toBase64,
  communityWithPermissions
} from '../../utils/helpers';

function* handleLogout({ redirect = true }) {
  console.log('HANDLE LOGOUT');
  yield call(authServices.clearAuthToken);
  yield put({ type: authTypes.AUTH_LOGOUT_SUCCESS });
  if (redirect) yield put(push('/login'));
}

function* handleLogin(payload) {
  try {
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: true });

    const response = yield call(authActions.attemptLogin, payload);
    const { data } = response;

    if (payload.remember) {
      authServices.setLocalStore('email', payload.email);
    } else authServices.removeLocalStore('email');

    // Login success - set local storage
    yield call(authServices.setAuthToken, data);

    // Get user data and set auth user
    const tokenData = yield call(authServices.getDataFromToken, data);
    const { user_id: userId } = tokenData;

    yield put({ type: authTypes.GET_LOGGED_IN_USER, userId });
    const { user } = yield take(authTypes.GET_LOGGED_IN_USER_SUCCESS);

    // perform some checks to ensure that the user should proceed or not
    // isOfficer
    // isLeader
    // isCA
    const { memberships } = user;
    const isOfficer = user.roles.includes('officer');
    const _isLeader = isLeader(memberships);
    const _isCA = isCommunityAdmin(memberships);

    if (!isOfficer && !_isLeader && !_isCA) {
      throw new Error('auth/USER_IS_MEMBER_ONLY');
    }

    if (isOfficer) {
      yield put({ type: communityTypes.GET_ALL_COMMUNITIES });
      yield take(communityTypes.GET_ALL_COMMUNITIES_SUCCESS);
    }

    // Needs to set to a community that the user has permissions in
    let community = communityWithPermissions(user) || user.communities[0] || null;
    if (isOfficer && !community) {
      const allCommunities = yield select(selectAllCommunities);
      community = allCommunities.length > 0 ? allCommunities[0] : null;
    }

    // If community hasn't been set and user is a member of one - set it
    if (community) {
      yield put({ type: communityTypes.SET_SELECTED_COMMUNITY, community });
      const hasPermissions = checkForPermissions(memberships, community._id);
      if (hasPermissions) {
        const permissions = getPermissions(memberships, community._id);
        yield put({ type: communityTypes.SET_COMMUNITY_PERMISSIONS, permissions });
        yield take(communityTypes.SET_COMMUNITY_PERMISSIONS_SUCCESS);
      }
    }

    // kick off the firebase auth process for realtime chat here
    // using spawn so that it doesnt block the rest of the auth flow

    // initialize firebase services
    yield spawn(initializeFirebaseServices);

    yield put({ type: authTypes.AUTH_LOGIN_SUCCESS, userId });
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });

    if (error?.message === 'auth/USER_IS_MEMBER_ONLY') {
      // do a logout - dont redirect
      yield call(handleLogout, { redirect: false });
      yield history.replace('/nxt-lvl-app');
    } else {
      // Display the error message modal
      yield put({
        type: modalTypes.ERROR_MODAL,
        error
      });

      yield put({
        type: authTypes.AUTH_LOGIN_ERROR,
        errors: {
          key: authTypes.AUTH_LOGIN_ERROR,
          errors: error?.response?.data?.errors || 'AUTH LOGIN ERROR'
        }
      });

      yield put({ type: authTypes.AUTH_LOGOUT });
    }
  }
}

function* setAuthFromToken({ token }) {
  // console.info('DEBUG: function*setAuthFromToken -> token', token);
  try {
    const tokenData = yield call(authServices.getDataFromToken, { idToken: token });
    const { user_id: userId } = tokenData;

    // request push notification token
    yield spawn(initializeFirebaseServices);

    yield put({ type: authTypes.AUTH_LOGIN_SUCCESS, userId });
  } catch (error) {
    console.log(error);
  }
}

function* resetPassword({ email }) {
  try {
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: true });

    yield call(authActions.requestPasswordReset, email);

    yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
    yield put({ type: authTypes.RESET_PASSWORD_SUCCESS, email });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
    yield put({ type: authTypes.RESET_PASSWORD_ERROR });
  }
}

function* getLoggedInUser({ userId }) {
  try {
    const { data } = yield call(userActions.getUser, userId);
    yield put({ type: authTypes.GET_LOGGED_IN_USER_SUCCESS, user: data });
  } catch (error) {
    yield put({ type: authTypes.AUTH_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({ type: authTypes.AUTH_LOGOUT });

    yield put({ type: authTypes.GET_LOGGED_IN_USER_ERROR });
  }
}

function* acceptInvitation({ invitationId, payload }) {
  try {
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: true });
    yield call(authActions.acceptInvite, invitationId, payload);
    yield put({ type: authTypes.ACCEPT_INVITATION_SUCCESS });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.ACCEPT_INVITATION_ERROR,
      errors: {
        key: authTypes.ACCEPT_INVITATION_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  }
}

function* setNewPassword({ password, passwordConfirm, code }) {
  try {
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: true });

    yield call(handleLogout, { redirect: false });

    yield call(authActions.resetPassword, password, passwordConfirm, code);

    yield put({ type: authTypes.SET_NEW_PASSWORD_SUCCESS });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  }
}

function* acceptEmailChange({ token }) {
  try {
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: true });

    yield call(authActions.acceptEmailChange, token);

    yield put({ type: authTypes.ACCEPT_EMAIL_CHANGE_SUCCESS });

    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });

    // perform the same process as a logout here to enforce a re-auth via login
    yield call(handleLogout, { redirect: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.ACCEPT_EMAIL_CHANGE_ERROR,
      errors: {
        key: authTypes.ACCEPT_EMAIL_CHANGE_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  }
}

function* changeOwnPassword({ oldPassword, password, passwordConfirm }) {
  try {
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: true });

    yield call(authActions.changeOwnPassword, oldPassword, password, passwordConfirm);

    // Silently login again to set new token
    const currentUser = yield select(selectLoggedInUser);
    const response = yield call(authActions.attemptLogin, {
      email: currentUser.email,
      password
    });
    const { data } = response;
    yield call(authServices.setAuthToken, data);

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Your password has been updated',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.CHANGE_OWN_PASSWORD_ERROR,
      errors: {
        key: authTypes.CHANGE_OWN_PASSWORD_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: authTypes.AUTH_SAVING_STATE, state: false });
  }
}

function* refreshToken() {
  try {
    const storedToken = yield call(authServices.getLocalStore, 'refreshToken');
    if (storedToken === null || storedToken === undefined) {
      yield put({ type: authTypes.AUTH_REFRESH_DONE });
      return;
    }
    const { data } = yield call(authActions.refreshToken);
    yield call(authServices.setAuthToken, data);

    yield call(setAuthFromToken, { token: data.idToken });
  } catch (error) {
    console.log('REFRESH ERROR', error);
  } finally {
    yield put({ type: authTypes.AUTH_REFRESH_DONE });
  }
}

function* requestChangeOwnEmail({ email }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: true });

    yield call(authActions.requestOwnEmailChange, email);

    // Upon successful request - get the request and store in redux
    const result = yield call(authActions.getOwnEmailChangeRequest);
    const { data } = result;

    yield put({
      type: authTypes.REQUEST_CHANGE_OWN_EMAIL_SUCCESS,
      emailChange: data
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Update email request sent',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.REQUEST_CHANGE_OWN_EMAIL_ERROR,
      errors: {
        key: authTypes.REQUEST_CHANGE_OWN_EMAIL_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  }
}

function* getOwnEmailChangeRequest() {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: true });

    const result = yield call(authActions.getOwnEmailChangeRequest);
    const { data } = result;

    yield put({
      type: authTypes.GET_OWN_EMAIL_CHANGE_REQUEST_SUCCESS,
      emailChange: data
    });

    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.GET_OWN_EMAIL_CHANGE_REQUEST_ERROR,
      errors: {
        key: authTypes.GET_OWN_EMAIL_CHANGE_REQUEST_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  }
}

function* cancelOwnEmailChange({ requestId }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: true });

    yield call(authActions.cancelOwnEmailChange, requestId);

    yield put({ type: authTypes.CANCEL_OWN_EMAIL_CHANGE_SUCCESS });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Request successfully canceled',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.CANCEL_OWN_EMAIL_CHANGE_ERROR,
      errors: {
        key: authTypes.CANCEL_OWN_EMAIL_CHANGE_ERROR,
        errors: error.response.data.errors
      }
    });
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  }
}

function* updateOwnProfile({ userId, updates }) {
  try {
    yield put({ type: userTypes.USER_SAVING_STATE, state: true });
    const communityId = yield select(selectSelectedCommunityId);

    // Check the keys exist in the payload (they wont from the profile screen)
    const updateKeys = Object.keys(updates);

    if (updateKeys.includes('imageFile') && (updates.imageFile !== null || updates.imageFile !== undefined)) {
      const { imageFile } = updates;
      const formData = new FormData();
      formData.append('photo', imageFile);
      yield call(userActions.uploadProfileImage, userId, communityId, formData);

      const image = yield call(toBase64, imageFile);

      yield put({ type: userTypes.STORE_PROFILE_IMAGE, userId, image });
    }

    const result = yield call(authActions.updateOwnUser, userId, updates);
    const { data } = result;

    yield put({ type: authTypes.UPDATE_OWN_PROFILE_SUCCESS, user: data });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Your profile has been updated',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: authTypes.UPDATE_OWN_PROFILE_ERROR,
      errors: {
        key: authTypes.UPDATE_OWN_PROFILE_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  }
}

export default [
  takeLatest(authTypes.AUTH_LOGIN, handleLogin),
  takeLatest(authTypes.AUTH_LOGOUT, handleLogout),
  takeLatest(authTypes.RESET_PASSWORD, resetPassword),
  takeLatest(authTypes.GET_LOGGED_IN_USER, getLoggedInUser),
  takeLatest(authTypes.SET_AUTH_FROM_TOKEN, setAuthFromToken),
  takeLatest(authTypes.ACCEPT_INVITATION, acceptInvitation),
  takeLatest(authTypes.SET_NEW_PASSWORD, setNewPassword),
  takeLatest(authTypes.ACCEPT_EMAIL_CHANGE, acceptEmailChange),
  takeLatest(authTypes.CHANGE_OWN_PASSWORD, changeOwnPassword),
  takeLatest(authTypes.AUTH_REFRESH, refreshToken),
  takeLatest(authTypes.REQUEST_CHANGE_OWN_EMAIL, requestChangeOwnEmail),
  takeLatest(authTypes.GET_OWN_EMAIL_CHANGE_REQUEST, getOwnEmailChangeRequest),
  takeLatest(authTypes.CANCEL_OWN_EMAIL_CHANGE, cancelOwnEmailChange),
  takeLatest(authTypes.UPDATE_OWN_PROFILE, updateOwnProfile)
];
