import React from 'react';
import { VictoryTooltip } from 'victory';

class ToolTip extends React.Component {
  static defaultEvents = VictoryTooltip.defaultEvents;

  render() {
    return (
      <VictoryTooltip
        flyoutStyle={{
          fill: 'rgba(0,0,0,0.7)',
          strokeWidth: 2,
          padding: 10
        }}
        {...this.props}
        style={{ ...this.props.style, fontSize: '8px', fill: 'white', padding: 10 }}
      />
    );
  }
}

export default ToolTip;
