import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Table from '../components/Table/Table';
import LeaderActivityRowCard from '../components/RowCards/LeaderActivityCard';

import {
  selectLeaderActivityLoadingState,
  selectLeaderActivity,
  selectSelectedCommunityId,
  selectCommunityLoadingState
} from '../state/community/reducers';

import * as communityTypes from '../state/community/types';
import * as userTypes from '../state/users/types';
import * as missionTypes from '../state/mission/types';

class ActivityList extends Component {
  constructor(props) {
    super(props);
    const { getLeaderActivity } = props;
    getLeaderActivity();
  }

  componentDidUpdate(prevProps) {
    // If the community is changed - refresh the data
    const { communityId: prevCommunityId } = prevProps;
    const { communityId, getLeaderActivity } = this.props;
    if (communityId !== prevCommunityId) {
      getLeaderActivity(0);
    }
  }

  generateMenuActions = row => {
    const { kind } = row;
    const isUser = kind === 'User';
    const userMenuActions = [
      {
        onClick: () => this.openUserDrawer(row.user),
        label: 'View user'
      }
    ];
    if (isUser) return userMenuActions;
    return null;
  };

  handleRowClick = row => {
    const { communityId, history, setSelectedUser } = this.props;
    const { user, entity, kind } = row;

    // Set selected user based on activity
    setSelectedUser(user);

    const noEntity = entity === null || entity === undefined;

    switch (kind) {
      case 'Mission':
        if (noEntity) break;
        history.push(`/c/${communityId}/u/${user._id}/m/${entity._id}`);
        break;

      case 'Task':
        if (noEntity) break;
        history.push(
          `/c/${communityId}/u/${user._id}/m/${entity.mission}/o/${entity.objective}`
        );
        break;

      case 'Objective':
        if (noEntity) break;
        history.push(
          `/c/${communityId}/u/${user._id}/m/${entity.mission}/o/${entity._id}`
        );
        break;

      case 'LifeEvent':
        history.push(`/c/${communityId}/u/${user._id}/l/activity`);
        break;

      default:
        break;
    }
  };

  handleTablePageChange = newPage => {
    const { getLeaderActivity } = this.props;
    getLeaderActivity(newPage);
  };

  render() {
    const { activityLoading, communityLoading, leaderActivity } = this.props;

    const { total, activities } = leaderActivity;

    const selectPageActivities = page =>
      activities
        .find(a => a.page === page)
        ?.data?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)) || [];

    const loading = communityLoading || activityLoading;
    return (
      <React.Fragment>
        <Table
          loading={loading}
          menuActions={this.generateMenuActions}
          rowCard={LeaderActivityRowCard}
          onClick={this.handleRowClick}
          rowsPerPage={25}
          cardHeight={40}
          onChangePage={this.handleTablePageChange}
          totalCount={total}
          dataSelector={selectPageActivities}
        />
      </React.Fragment>
    );
  }
}

ActivityList.propTypes = {
  getLeaderActivity: PropTypes.func.isRequired,
  activityLoading: PropTypes.bool.isRequired,
  leaderActivity: PropTypes.object.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  communityId: PropTypes.string.isRequired,
  communityLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getUserActivity: PropTypes.func.isRequired,
  getUserObjectives: PropTypes.func.isRequired,
  getUserMissions: PropTypes.func.isRequired,
  getUserLifeEvents: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  activityLoading: selectLeaderActivityLoadingState(state),
  leaderActivity: selectLeaderActivity(
    state,
    state.community.selectedCommunity?._id,
    state.auth.userId
  ),
  communityId: selectSelectedCommunityId(state),
  communityLoading: selectCommunityLoadingState(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLeaderActivity: page => ({
        type: communityTypes.GET_LEADER_ACTIVITY,
        page
      }),
      setSelectedUser: (user, leaders) => ({
        type: userTypes.SET_SELECTED_USER,
        user,
        leaders
      }),
      getUserActivity: (communityId, userId) => ({
        type: userTypes.GET_USER_ACTIVITY,
        communityId,
        userId
      }),
      getUserObjectives: userId => ({
        type: missionTypes.GET_USER_OBJECTIVES,
        userId,
        leaderReq: true
      }),
      getUserLifeEvents: userId => ({
        type: userTypes.GET_USER_LIFE_EVENTS,
        userId
      }),
      getUserMissions: (userId, communityId) => ({
        type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
        userId,
        role: 'leader',
        communityId
      })
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityList));
