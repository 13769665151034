import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { debounce } from 'lodash';
// import TaskEdit from './TaskEdit';
import { ReactComponent as EditIcon } from '../../static/images/icons/icon-edit.svg';
import { ensurePositiveNumber, getSlateValueFromTaskAndPractice, getStringDescFromSlate, initialSlateValue } from '../../utils/helpers';

import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';

import { ReactComponent as TrashIcon } from '../../static/images/icons/icon-trash.svg';
import { ReactComponent as TickIcon } from '../../static/images/icons/icon-gold-tick.svg';
// import InputLabel from '../InputLabel/InputLabel';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 60,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 10
  },
  date: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  },
  task: {
    fontFamily: 'SFUIText-Light',
    fontSize: 14,
    flex: 1,
    marginRight: 10
  },
  statusCircle: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    backgroundColor: '#adb6c1'
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: '#dcdcdc',
    borderRadius: 20,
    padding: '12px 18px'
  },
  dueDate: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginRight: 10
  },
  overdue: {
    textTransform: 'uppercase',
    fontSize: 10,
    color: theme.palette.error.main,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginRight: 10
  },
  contentWrap: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    maxWidth: 640,
    padding: 20
  },
  inputWrap: {
    display: 'flex',
    marginBottom: 26
  },
  taskName: {
    marginBottom: 16
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  },
  cancel: {
    marginRight: 8
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    outline: 'none !important',
    backgroundColor: '#ededed',
    maxWidth: 28,
    maxHeight: 29,
    borderRadius: 10,
    padding: 4
  },
  descriptionLabel: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    paddingLeft: 12,
    fontSize: 10,
    marginBottom: 12,
  },
  richDescriptionContainer: {
    marginLeft: 8,
    padding: '20px 20px 32px 20px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: 10,
  },
}));

const TaskCard = ({ task, readOnly, onDelete, onCreate }) => {
  // Hooks
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const containerRef = useRef();

  // const { task: taskName, description, richDescription, dueDate, daysDue, isComplete, createdOn } = task;

  const [taskDetails, setTaskDetails] = useState(task);
  const { task: taskName, description, richDescription, dueDate, daysDue, isComplete, createdOn } = taskDetails

  useEffect(() => {
    setTaskDetails(task)
  }, [task])

  const isOverdue = moment(dueDate).isBefore(moment()) && !isComplete;
  const daysOverdue = moment().diff(moment(dueDate), 'days');

  const daysDueText =
    daysDue > 0 ? `Due ${daysDue} days after the objective starts` : 'Due date inherits from objective';
  
  const descriptionShowed = richDescription ? getStringDescFromSlate(richDescription): description

  const activeTaskJsx = (
    <>
      <Box className={classes.dateContainer}>
        <Typography className={classes.date}>{moment(createdOn).format('D/MM')}</Typography>
      </Box>
      <Box paddingLeft={1.5} display="flex" flex={1} alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center" width={25} height={25} marginRight={1.25}>
          {isComplete && <TickIcon />}
          {!isComplete && <Box className={classes.statusCircle} />}
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <Typography className={classes.task} style={{ fontWeight: '700' }}>
            {taskName}
          </Typography>
          <Typography className={classes.task} style={{ fontSize: 12 }}>
            {descriptionShowed || '(No description)'}
          </Typography>
        </Box>
        <>
          {isOverdue ? (
            <Typography className={classes.overdue}>{daysOverdue} days overdue</Typography>
          ) : (
            <Typography className={classes.dueDate}>
              {!isComplete && `DUE ${moment(dueDate).format('D/MM/YYYY')}`}
            </Typography>
          )}
        </>
      </Box>
    </>
  );

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  // Helpers
  const getPopupPostion = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getClientRects()[0];
      return {
        position: 'absolute',
        // top: rect.top,
        left: rect.left,
        width: rect.width
      };
    }
    return null;
  };

  const handleCreate = e => {
    // Ensure a default of zero days
    // const task = taskDetails;

    // onCreate({ id: randomId(), ...task });
    onCreate({ ...taskDetails });
    handleClose(e);
  };


  const handleChange = e => {
    const { name, value } = e.target;

    const isNumeric = name === 'daysDue';

    setTaskDetails({
      ...taskDetails,
      [name]: isNumeric ? ensurePositiveNumber(value) : value
    });
  };
  const complete = Boolean(task);

  const initialDescription = getSlateValueFromTaskAndPractice(taskDetails.description, taskDetails.richDescription)

  return (
    <Box
      className={classes.container}
      role="presentation"
      ref={containerRef}
    >
      {dueDate ? (
        activeTaskJsx
      ) : (
        <>
          <Typography noWrap className={classes.task}>
            {taskName}
          </Typography>
          <Typography className={classes.dueDate}>{daysDueText}</Typography>
          <IconButton
            className={classes.icon}
            onClick={handleClick}
          >
            <EditIcon />
          </IconButton>
        </>
      )}

      {!readOnly && (
        <IconButton className={classes.icon} onClick={() => onDelete(task)}>
          <TrashIcon />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { ...getPopupPostion(), margin: 0 },
          className: classes.contentWrap
        }}
      >
        <Box>
          <Box className={classes.inputWrap}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <div>
                  <TextInput
                    name="task"
                    label="Action title"
                    placeholder="Write the action here…"
                    value={taskDetails.task}
                    onChange={handleChange}
                    whiteInput
                    required
                    className={classes.taskName}
                  />
                  {/* <TextInput
                    name="description"
                    label="Action description"
                    placeholder="Describe the action…"
                    value={taskDetails.description}
                    onChange={handleChange}
                    whiteInput
                  /> */}
                </div>
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  name="daysDue"
                  label="Days due after the Objective starts"
                  value={taskDetails.daysDue}
                  whiteInput
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <MuiInputLabel
                    shrink={false}
                    label="Action Description"
                    classes={{
                      root: classes.descriptionLabel,
                    }}
                  >
                    Action Description
                  </MuiInputLabel>
                  <div className={classes.richDescriptionContainer}>
                    <RichTextEditor
                      defaultValue={initialDescription}
                      name="content"
                      minHeight={100}
                      onlyLink
                      onChange={debounce(content => {
                        const target = {
                          target: {
                            name: 'richDescription',
                            value: content
                          }
                        }
                        handleChange(target)
                      }, 500)}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.buttonWrap}>
            <Button
              data-test-id="button-objective-task-cancel"
              background="grey"
              label="Cancel"
              onClick={handleClose}
              className={clsx(classes.btn, classes.cancel)}
            />
            <Button
              label="Update"
              onClick={handleCreate}
              disabled={!complete}
              className={classes.btn}
              data-test-id="button-objective-task-create"
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  onCreate: PropTypes.func
};

TaskCard.defaultProps = {
  onDelete: null,
  readOnly: false,
  onCreate: null
};

export default TaskCard;
