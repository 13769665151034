import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Fuse from 'fuse.js';

import MemberFilter from '../components/MemberStats/MemberFilter';
import MissionOverview from '../components/MemberStats/MissionOverview';
import StatTable from '../components/MemberStats/StatTable';

import * as communityTypes from '../state/community/types';
import * as userTypes from '../state/users/types';
import * as missionTypes from '../state/mission/types';
// import * as teamTypes from '../state/teams/types';

import { selectTeams } from '../state/teams/reducers';
import { selectIsOfficer, selectLoggedInUserId } from '../state/auth/reducers';
import {
  selectLeaderUsers,
  selectCurrentUserCommunityPermissions,
  selectOfficerMembers,
  selectLeaderUsersLoadingState,
  selectOfficerMembersLoading
} from '../state/community/reducers';

import { getUserCommunityLeaders, calculateMemberStats } from '../utils/helpers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName']
};

const MemberStats = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { communityId, role } = useParams();

  // Param booleans
  const isLeaderView = role === 'leader';
  const isOfficerView = role === 'officer';

  // Local state
  const [search, setSearch] = useState('');
  const [team, setTeam] = useState('');

  // Redux
  const authUser = useSelector(state => selectLoggedInUserId(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const leaderMembers = useSelector(state => selectLeaderUsers(state));
  const officerMembers = useSelector(state => selectOfficerMembers(state));
  const leaderMemberLoading = useSelector(state => selectLeaderUsersLoadingState(state));
  const officerMemberLoading = useSelector(state => selectOfficerMembersLoading(state));
  const communityTeams = useSelector(state => selectTeams(state));
  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));

  // Permission booleans
  const isLeader = permissions.some(p => p === 'leader');

  // Redirect if unauthorized to be here
  useEffect(() => {
    // No valid role in url
    if (!['leader', 'officer'].some(r => r === role)) {
      history.push('/');
    }

    // Non leader or officer accessing leader view
    if (!isLeader && !isOfficer && isLeaderView) history.push('/');

    // Non officer accessing officer view
    if (!isOfficer && isOfficerView) history.push('/');

    // Neither officer or leader
    if (!isOfficer && !isLeader) history.push('/');
  }, [role, isOfficer, permissions, history]);

  // Get officer data is authorized and relevant
  useEffect(() => {
    if (isOfficer && isOfficerView) {
      // Get the officers members
      dispatch({
        type: communityTypes.GET_OFFICER_MEMBERS,
        leaderId: authUser
      });
    }
  }, [isOfficer, isOfficerView]);

  // Get leader data if authorized and relevant
  useEffect(() => {
    if ((isLeader || isOfficer) && isLeaderView) {
      // Get the leaders users
      dispatch({
        type: communityTypes.GET_LEADER_USERS,
        communityId,
        userId: isOfficer ? null : authUser
      });

      // TODO: Performance Bug - validate this isn't required
      // // Get the community teams
      // dispatch({
      //   type: teamTypes.GET_ALL_TEAMS,
      //   communityId,
      //   leaderReq: true
      // });
    }
  }, [isLeader, isLeaderView, dispatch, communityId, authUser]);

  // Search helper
  const searchMembers = members => {
    const fustInstance = new Fuse(members, fuseOpts);
    return fustInstance.search(search);
  };

  // Prep data for user drawer - dispatch all relevant actions
  const dispatchUserActions = user => {
    // Set the selected user
    dispatch({
      type: userTypes.SET_SELECTED_USER,
      user,
      leaders: isOfficerView ? getUserCommunityLeaders(user) : getUserCommunityLeaders(user, communityId)
    });

    // Get their life events
    dispatch({ type: userTypes.GET_USER_LIFE_EVENTS, userId: user._id });

    // Get their missions
    if (isLeaderView) {
      dispatch({
        type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
        userId: user._id,
        communityId,
        role: isOfficer ? 'communityAdmin' : 'leader'
      });
    }

    if (isOfficer) {
      dispatch({
        type: missionTypes.GET_ALL_OFFICER_MISSIONS,
        userId: user.id,
        communityId: null,
        role: 'communityAdmin'
      });
    }

    // Get their objectives
    dispatch({
      type: missionTypes.GET_USER_OBJECTIVES,
      userId: user._id,
      leaderReq: !isOfficer
    });

    // Get their activity
    // TODO: Performance Bug - validate this isn't required
    // dispatch({ type: userTypes.GET_USER_ACTIVITY, userId: user.id, communityId });
  };

  // Event handlers
  const onUserClick = user => {
    const { _id: userId } = user;
    dispatchUserActions(user);
    history.push(`/c/${communityId}/u/${userId}/${isLeaderView ? 'l' : 'o'}/overview`);
  };

  const onSearchChange = value => {
    setSearch(value);
  };

  const onTeamChange = e => {
    const { value } = e.target;
    setTeam(value);
  };

  // Convert teams to dropdown options
  const computedTeams = communityTeams.map(t => ({
    label: t.title,
    value: t._id
  }));

  // Constants
  // console.log("CEK OFFICER", isOfficerView, officerMembers, leaderMembers)
  const computedLoading = isOfficerView ? officerMemberLoading : leaderMemberLoading;
  const members = isOfficerView ? officerMembers : leaderMembers;
  const hasSearch = search.trim().length > 0;
  const searchResults = hasSearch ? searchMembers(members) : members;
  const noDataMessage = hasSearch ? 'No data found' : 'Nothing here yet... Check back soon!';
  const computedMembers = team ? searchResults.filter(u => u.teams.some(t => t.id === team)) : searchResults;

  const computedOverview = calculateMemberStats(computedMembers);

  return (
    <main>
      <MemberFilter
        search={search}
        onSearchChange={onSearchChange}
        team={team}
        teams={computedTeams}
        onTeamChange={onTeamChange}
        isOfficerView={isOfficerView}
      />
      <MissionOverview loading={computedLoading} totalMembers={computedMembers.length} overview={computedOverview} />
      <StatTable
        loading={computedLoading}
        data={computedMembers}
        onRowClick={onUserClick}
        noDataMessage={noDataMessage}
      />
    </main>
  );
};

export default MemberStats;
