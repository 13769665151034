import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const useStyles = makeStyles({
  container: {
    paddingLeft: 15,
    paddingRight: 40,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: 40,
    borderRadius: 10,
    marginBottom: 10
  },
  teamDetails: {
    width: 400,
    display: 'flex',
    flex: 1,
    flexGrow: 0,
    flexBasis: 400,
    justifyContent: 'space-evenly'
  },
  scores: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'column'
  },
  scoreItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  node: {
    borderRadius: 10,
    display: 'flex',
    padding: 20,
    paddingBottom: 10,
    flex: 1,
    alignItems: 'center',
    marginRight: 5
  },
  item: {
    borderRadius: 10,
    display: 'flex',
    padding: 10,
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 1,
    width: 90,
    marginLeft: 5,
    fontSize: 10
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16
  },
  description: {
    fontSize: 16,
    fontWeight: 300,
    padding: '0px 20px',
    flex: 1
  },
  week: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    flex: 1
  }
});

export default function TeamRowHeader({ numberOfScores, grouping }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.teamDetails}>
        <Box className={classes.node}>
          <Typography noWrap className={classes.title}>
            Team
          </Typography>
        </Box>
        <Box className={classes.node}>
          <Typography className={classes.title}>Description</Typography>
        </Box>
      </Box>

      <Box className={classes.scores}>
        <Typography className={classes.title}>
          Accountability scores for the last {numberOfScores} {grouping}s.
        </Typography>
        <Box className={classes.scoreItems}>
          {grouping === 'week' &&
            [...Array(numberOfScores)].map((_, i) => {
              const week = moment()
                .startOf('isoWeek')
                .subtract(numberOfScores - 1, 'weeks')
                .add(i, 'weeks')
                .isoWeek();
              return (
                <Box className={classes.item}>
                  <Typography noWrap className={classes.week}>
                    WK {week}
                  </Typography>
                </Box>
              );
            })}
          {grouping === 'month' &&
            [...Array(numberOfScores)].map((_, i) => {
              const month = new Date().getMonth() - (i - 1);
              return (
                <Box className={classes.item}>
                  <Typography noWrap className={classes.week}>
                    {monthNames[month]}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}

TeamRowHeader.propTypes = {
  numberOfScores: PropTypes.number,
  grouping: PropTypes.oneOf(['week', 'month'])
};

TeamRowHeader.defaultProps = {
  numberOfScores: 1,
  grouping: 'week'
};
