import PropTypes from 'prop-types'
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import {selectSelectedCommunityId} from '../../state/community/reducers';
import { selectMissionLoading } from '../../state/mission/reducers';
// import {calculateScores} from '../../utils/accountabilityScoreHelper';
// import * as missionTypes from '../../state/mission/types';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
}))

const PerformanceScore = ({data}) => {
  // const dispatch = useDispatch();
  // const communityId = useSelector(state => selectSelectedCommunityId(state));
  const isMissionLoading = useSelector(state => selectMissionLoading(state));
  // const missions = useSelector(state => selectAllUserMissions(state));
  // const {performanceScore} = useSelector((state) => selectMissionsStats(state, data.id))
  const performance = data.stats.performanceScore
  const classes = useStyles();

  // useEffect(()=>{
  //   dispatch({
  //     type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
  //     userId: data?.id,
  //     communityId,
  //   })
  //   dispatch({ type: missionTypes.GET_MISSIONS_STATS_REQUEST, userId: data.id, communityId })
  // }, [])

  if (isMissionLoading) {
    return <CircularProgress size="2em" />
  }

  // const {performance} = calculateScores(missions)
  // console.log("CEK PERFORMANCE", performanceScore, performance)
  // if (performanceScore !== performance) console.log("PROBLEM", data.id)
  return (
    <Typography className={classes.text}>
      {(performance || 0).toFixed(2)}%
    </Typography>
  )
}

PerformanceScore.propTypes = {
  data: PropTypes.object.isRequired
}

export default PerformanceScore