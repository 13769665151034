import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as EditIcon } from '../../static/images/icons/icon-edit.svg';
import MissionIcon from '../MissionIcon/MissionIcon';
import GoldIcon from './GoldIcon';

import { truncateString } from '../../utils/helpers';

const useStyles = makeStyles({
  paper: {
    cursor: 'pointer',
    position: 'relative',
    margin: 5,
    padding: 8,
    top: 0,
    transition: 'all 0.1s ease',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    height: 170,
    minHeight: 170,
    maxHeight: 170,
    '&:hover': {
      top: -2
    }
  },
  selected: {
    backgroundColor: '#c0c0c0 !important',
    boxShadow: 'none !important'
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    outline: 'none !important',
    backgroundColor: '#ededed',
    maxWidth: 28,
    maxHeight: 29,
    borderRadius: 10,
    padding: 4
  },
  title: {
    fontSize: 14,
    marginBottom: 8,
    userSelect: 'none',
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  description: {
    fontSize: 10,
    userSelect: 'none',
    marginBottom: 8
  },
  objective: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  icon: {
    marginRight: 5
  },
  meta: {
    fontSize: 12,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  }
});

const TemplateCard = ({
  title,
  description,
  templateId,
  icon,
  objectives,
  tasks,
  onClick,
  onEdit,
  selected,
  readOnly
}) => {
  // Classes
  const classes = useStyles();

  // Constants
  const hasObjectives = objectives.length > 0;
  const hasTasks = tasks.length > 0;

  const handleEdit = e => {
    e.stopPropagation();
    if (onEdit) onEdit();
    else if (onClick) onClick();
  };

  const wrapClass = clsx({
    [classes.paper]: true,
    [classes.selected]: selected
  });

  return (
    <Paper className={wrapClass} onClick={onClick}>
      {!readOnly && (
        <IconButton className={classes.iconButton} onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      )}
      <Box
        display="flex"
        justifyContent="center"
        paddingTop={1.5}
        paddingBottom={1.5}
      >
        <MissionIcon icon={icon} id={templateId} isTemplate />
      </Box>
      <Typography align="center" noWrap className={classes.title}>
        {title}
      </Typography>
      {description && (
        <Typography align="center" className={classes.description}>
          {truncateString(description, 37)}
        </Typography>
      )}
      <Box display="flex" justifyContent="center">
        {hasObjectives && (
          <Box className={classes.objective}>
            <GoldIcon icon="bullseye" className={classes.icon} />
            <span className={classes.meta}>{objectives.length}</span>
          </Box>
        )}
        {hasTasks && (
          <Box display="flex" alignItems="center">
            <GoldIcon icon="list" className={classes.icon} />
            <span className={classes.meta}>{tasks.length}</span>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

TemplateCard.propTypes = {
  title: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string.isRequired,
  objectives: PropTypes.array,
  tasks: PropTypes.array,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  readOnly: PropTypes.bool,
  selected: PropTypes.bool
};

TemplateCard.defaultProps = {
  description: '',
  objectives: [],
  tasks: [],
  onClick: null,
  onEdit: null,
  readOnly: false,
  selected: false
};

export default TemplateCard;
