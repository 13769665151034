import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as modalTypes from '../../state/modal/types';

import Button from '../../components/Button/Button';

const styles = makeStyles({
  container: {
    '@media (min-width: 768px)': {
      width: 816
    },
    flex: 1
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uhOh: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 700,
    marginBottom: 10,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      textAlign: 'left'
    }
  },
  error: {
    textAlign: 'center',
    '@media (min-width: 768px)': {
      textAlign: 'left'
    }
  },
  button: {
    marginTop: 20,
    width: 150,
    '@media (min-width: 768px)': {
      marginTop: 0
    }
  }
});

export default function ApiErrorModal({ message, code }) {
  const mailTo =
    process.env.NODE_ENV === 'production'
      ? window.__env__.REACT_APP_MAIL_TO
      : process.env.REACT_APP_MAILTO;

  const classes = styles();
  const dispatch = useDispatch();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12}>
        <Typography className={classes.uhOh}>
          {code !== 'auth/revoked' ?
            <>An error has occurred!</>
            :
            <>Permission was changed.</>
          }
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography className={classes.error}>{message}</Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.flex}>
        <Button
          label="Close"
          className={classes.button}
          onClick={() =>
            dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
          }
        />
      </Grid>
    </Grid>
  );
}

ApiErrorModal.propTypes = {
  message: PropTypes.string,
  code: PropTypes.string
};

ApiErrorModal.defaultProps = {
  message: 'Something went wrong!',
  code: ''
};
