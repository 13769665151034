// eslint-disable

import axios from 'axios';
import store from '../state/store';
import { AUTH_LOGOUT } from '../state/auth/types';
import { refreshToken } from '../state/auth/actions';
import { setAuthToken } from '../state/auth/services';
import { isTokenExpired } from './tokenHelpers';

const { dispatch } = store;

let isAlreadyFetchingAccessToken = false;

// See comments at bottom - limiting to 1 attempt to stop loop
let fetchTokenAttempts = 0;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

// Do not send bearer token if hitting these routes
function isUnauthenticatedRoute(config = {}) {
  const { url } = config;
  const endpoints = [
    '/v1/auth/token/refresh',
    '/v1/auth/login',
    '/v1/auth/logout',
    '/v1/auth/password/reset',
    '/v1/auth/password/request',
    '/v1/auth/email/update'
  ];

  // Manually handle accept invite endpoint
  const isAcceptInvite = config.method === 'patch' && url.includes('/v1/invites/');

  return endpoints.some(e => e === url) || isAcceptInvite;
}

// Check token expiry - refresh before hitting authenticated route
async function requestHandler(config) {
  let token = localStorage.getItem('idToken');
  if (!isUnauthenticatedRoute(config)) {
    // They are unauthenticated - send them to login
    if (!token) {
      dispatch({ type: AUTH_LOGOUT });
    }

    // if it's expired - refresh it!
    if (token && isTokenExpired(token)) {
      try {
        const { data: tokenData } = await refreshToken();
        setAuthToken(tokenData);
        const { idToken } = tokenData;
        token = idToken;
      } catch (error) {
        // They are unauthenticated - send them to login
        dispatch({ type: AUTH_LOGOUT });
        console.error('Error refreshing token before request -> ', error);
        return Promise.reject(error);
      }
    }

    const _config = { ...config };
    if (token) {
      _config.headers.Authorization = `Bearer ${token}`;
    }
    return _config;
  }
  return config;
}

/*
customAxios.interceptors.response.use(
  function(response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response
  },
  function(error) {
    const errorResponse = error.response
    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error)
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error)
  }
)
function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
}
*/

// When the refresh is successful, we start retrying the requests one by one and empty the queue
function onAccessTokenFetched(accessToken) {
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function isTokenExpiredError(errorResponse) {
  return errorResponse.status === 401 && !isUnauthenticatedRoute(errorResponse.config);
}

// This fires after a 401 is returned by a request
async function resetTokenAndReattemptRequest(error) {
  fetchTokenAttempts += 1;
  try {
    const { response: errorResponse } = error;
    const resetToken = localStorage.getItem('idToken');

    if (!resetToken) {
      // We can't refresh - log them out and close the error
      dispatch({ type: AUTH_LOGOUT });
      return Promise.reject(error);
    }

    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
      /* We need to add the request retry to the queue
      since there another request that already attempt to
      refresh the token */
      addSubscriber(accessToken => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    }).catch(err => {
      console.error('Error in error axios error interceptor subscriptions', err);
      throw err;
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const { data: tokenData } = await refreshToken();
      setAuthToken(tokenData);
      const { idToken } = tokenData;
      const token = idToken;

      if (!token) {
        dispatch({ type: AUTH_LOGOUT });
        return Promise.reject(error);
      }

      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(token);
    }

    return retryOriginalRequest;
  } catch (err) {
    dispatch({ type: AUTH_LOGOUT });
    return Promise.reject(err);
  }
}

export default function(isProd) {
  axios.defaults.timeout === 30000; // 30 seconds
  axios.defaults.baseURL = isProd ? window.__env__.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL;

  // set default headers
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // Refresh token before request (if necessary)
  axios.interceptors.request.use(
    async config => requestHandler(config),
    error => Promise.reject(error)
  );

  axios.interceptors.response.use(
    async config => Promise.resolve(config),
    error => {
      const { response: errorResponse } = error;
      // Check for auth/revoked - log them out and throw error to axios
      if (errorResponse?.data?.code === 'auth/revoked') {
        dispatch({ type: AUTH_LOGOUT });
        return Promise.reject(error);
      }

      // Check if it was a 401 error was was hitting an authenticated route
      // API currently returns 401 instead of 403 which causes problems
      if (isTokenExpiredError(errorResponse)) {
        // Goes into infinite loop as API can continuously send back 401 so the front end gets stuck here retrying
        // Limiting it to one attempt for now;
        if (fetchTokenAttempts < 1) return resetTokenAndReattemptRequest(error);
      }

      // If the error is due to other reasons, we just throw it back to axios
      fetchTokenAttempts = 0;
      return Promise.reject(error);
    }
  );
}
