import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {withRouter} from 'react-router-dom';
import Fuse from 'fuse.js';
import {orderBy} from 'lodash';
import {
  selectCommunityLoadingState,
  selectCommunityUsers,
  selectCommunityUsersLoadingState,
  selectSelectedCommunityId
} from '../state/community/reducers';
import ListFilter from '../components/ListFilter/ListFilter';
import {selectTeams} from '../state/teams/reducers';
import * as communityTypes from '../state/community/types';
import * as toolkitCategoriesTypes from '../state/toolkitCategories/types';
import * as toolkitTypes from '../state/toolkits/types';
import * as userTypes from '../state/users/types';
import * as missionTypes from '../state/mission/types';
import * as modalTypes from '../state/modal/types';
import * as teamTypes from '../state/teams/types';
import Button from '../components/Button/Button';
import ToolkitCard from '../components/RowCards/ToolkitCard';
import Table from '../components/Table/Table';
import {selectToolkits, selectToolkitsLoadingState} from '../state/toolkits/reducers';
import Checkbox from '../components/Checkbox/Checkbox';
import AssignUserDrawer from '../components/AssignUserDrawer/AssignUserDrawer';
import {selectToolkitCategories} from '../state/toolkitCategories/reducers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['title', 'description']
};

class Toolkit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filter: '',
      showAssignUserDrawer: false,
      selectedToolkits: [],
    };
    const { getCommunityUsers, getCommunityLeaders, communityId, getAllTeams, getToolkits, getToolkitCategories } = this.props;
    getToolkitCategories()
    getToolkits()
    getCommunityUsers(communityId);
    getCommunityLeaders(communityId);
    getAllTeams(communityId);
  }

  componentDidUpdate(prevProps) {
    // If the community is changed - refresh the data
    const { communityId: prevCommunityId } = prevProps;
    const { communityId, getCommunityUsers, getCommunityLeaders, getAllTeams } = this.props;
    if (communityId !== prevCommunityId) {
      getCommunityLeaders(communityId);
      getCommunityUsers(communityId);
      getAllTeams(communityId);
    }
  }

  openCreateToolkit = () => {
    const {communityId, history} = this.props;
    history.push(`/c/${communityId}/toolkits/add`);
  }

    openEditToolkit = toolkit => {
      const {communityId, history} = this.props;
      history.push(`/c/${communityId}/toolkits/${toolkit.id}/edit`);
    };

    searchToolkits = toolkits => {
      const { search } = this.state;
      const fuseInstance = new Fuse(toolkits, fuseOpts);
      return fuseInstance.search(search);
    };

    onSearchChange = e => {
      this.setState({ search: e });
    };

    onFilterChange = e => {
      const { value } = e.target;
      this.setState({ filter: value });
    };

    onFilterCategories = (toolkits) => {
      const {  filter } = this.state;


      return toolkits.filter(toolkit => toolkit.categories.some(t => {
        if (t === filter) {
          return true
        }

        return false;
      }))
    }

    render() {
      const { readOnly, toolkitCategories, toolkits, toolkitsLoadingState: loadingState } = this.props;
      const { search, filter, selectedToolkits, showAssignUserDrawer } = this.state;

      const filterOptions = orderBy(
        toolkitCategories.map(c => ({label: c.name, value: c.id})), ['value'], ['asc']
      )

      const hasSearch = search.trim().length > 0;
      const searchResults = hasSearch ? this.searchToolkits(toolkits) : toolkits;
      const computedToolkits = filter ? this.onFilterCategories(searchResults) : searchResults;

      return (
        <React.Fragment>
          <ListFilter
            readOnly={readOnly}
            search={search}
            filterOptions={filterOptions}
            filter={filter}
            onSearchChange={this.onSearchChange}
            onFilterChange={this.onFilterChange}
            onCreate={this.openCreateToolkit}
            createLabel="Create new tool"
            searchPlaceholder="Search all tools..."
            filterByPlaceholder="Filter by category"
            btnStyles={{width: '50%', borderRadius: 50}}
            customBatchBtn={
              <Button
                disabled={selectedToolkits.length === 0}
                iconPosition="start"
                icon={<AddIcon />}
                background="grey"
                label={`Batch assign ${selectedToolkits.length} tools`}
                style={{width: '50%', borderRadius: 50}}
                onClick={() => this.setState({showAssignUserDrawer: true})}
              />
            }
            btnIcon={<AddIcon />}
          />
          <div style={{
            marginTop: '1rem',
            marginLeft: '1rem',
            background: '#f7f7f7',
            width: '10rem',
            borderRadius: 10,
            paddingLeft: '1rem'
          }}
          >
            <Checkbox
              checked={filter ?
                computedToolkits.some(t => selectedToolkits.includes(t.id)) :
                toolkits.some(t => selectedToolkits.includes(t.id))
              }
              label={`${selectedToolkits.length} tools selected`}
              onChange={() => {
                const filteredToolkits = filter ? computedToolkits : toolkits;

                if (filteredToolkits.some(t => selectedToolkits.includes(t.id))) {
                  this.setState({
                    selectedToolkits:[]
                  })
                } else {
                  this.setState({
                    selectedToolkits: filteredToolkits.map(t => t.id)
                  })
                }
              }}
            />
          </div>
          <div style={{marginTop: '1rem'}}>
            <Table
              enableCheckbox
              isChecked={row => selectedToolkits.includes(row.id)}
              onCheck={(row) => {
                if (selectedToolkits.includes(row.id)) {
                  this.setState({
                    selectedToolkits: selectedToolkits.filter(t => t !== row.id)
                  })
                } else {
                  this.setState({
                    selectedToolkits: [...selectedToolkits, row.id]
                  })
                }
              }}
              onClick={row => (this.openEditToolkit(row))}
              loading={loadingState}
              data={computedToolkits}
              rowCard={ToolkitCard}
              readOnly={readOnly}
              noDataMessage={hasSearch ? 'No toolkits found' :
              <>
                <p>No Toolkits</p>
                <small style={
                  {
                    fontFamily: 'SFUIDisplay-SemiBold',
                  }
                }
                >
                  Start by clicking “Create new tool”
                </small>
              </>}
            />
          </div>
          <AssignUserDrawer
            selectedToolkits={selectedToolkits}
            open={showAssignUserDrawer}
            handleClose={() => this.setState({showAssignUserDrawer: false})}
            onSubmit={() => {
              this.setState({
                selectedToolkits: [],
                showAssignUserDrawer: false
              })
            }}
          />
        </React.Fragment>
      )
    }
}

Toolkit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  communityLoadingState: PropTypes.bool.isRequired,
  communityUsersLoadingState: PropTypes.bool.isRequired,
  communityId: PropTypes.string.isRequired,
  communityUsers: PropTypes.array.isRequired,
  getCommunityUsers: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  getCommunityLeaders: PropTypes.func.isRequired,
  getUserMissions: PropTypes.func.isRequired,
  getUserActivity: PropTypes.func.isRequired,
  getUserObjectives: PropTypes.func.isRequired,
  setModalComponent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  removeUserFromCommunity: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  communityTeams: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  getUserLifeEvents: PropTypes.func.isRequired,
  getToolkitCategories: PropTypes.func.isRequired,
  getToolkits: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  toolkitCategories: PropTypes.array.isRequired,
  toolkits: PropTypes.array.isRequired,
  toolkitsLoadingState: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  communityLoadingState: selectCommunityLoadingState(state),
  communityUsersLoadingState: selectCommunityUsersLoadingState(state),
  communityId: selectSelectedCommunityId(state),
  communityUsers: selectCommunityUsers(state),
  toolkitCategories: selectToolkitCategories(state),
  toolkits: selectToolkits(state),
  toolkitsLoadingState: selectToolkitsLoadingState(state),
  communityTeams: selectTeams(state),
  loggedInUser: state.auth.loggedInUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getToolkitCategories: () => ({
        type: toolkitCategoriesTypes.GET_TOOLKIT_CATEGORIES
      }),
      getToolkits: () => ({
        type: toolkitTypes.GET_TOOLKITS
      }),
      getCommunityUsers: communityId => ({
        type: communityTypes.GET_COMMUNITY_USERS,
        communityId
      }),
      getCommunityLeaders: communityId => ({
        type: communityTypes.GET_COMMUNITY_LEADERS,
        communityId
      }),
      setSelectedUser: (user, leaders) => ({
        type: userTypes.SET_SELECTED_USER,
        user,
        leaders
      }),
      resendInvitation: invitationId => ({
        type: userTypes.RESEND_INVITATION,
        invitationId
      }),
      getUserMissions: (userId, communityId) => ({
        type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
        userId,
        role: 'communityAdmin',
        communityId
      }),
      getUserActivity: (communityId, userId) => ({
        type: userTypes.GET_USER_ACTIVITY,
        communityId,
        userId
      }),
      getUserLifeEvents: userId => ({
        type: userTypes.GET_USER_LIFE_EVENTS,
        userId
      }),
      getUserObjectives: userId => ({
        type: missionTypes.GET_USER_OBJECTIVES,
        userId,
        leaderReq: false
      }),
      setModalComponent: (component, props) => ({
        type: modalTypes.MODAL_SET_COMPONENT,
        component,
        props
      }),
      openModal: () => ({
        type: modalTypes.MODAL_SET_OPEN_STATE,
        state: true
      }),
      removeUserFromCommunity: (communityId, userId) => ({
        type: userTypes.REMOVE_USER_FROM_COMMUNITY,
        communityId,
        userId
      }),
      getAllTeams: communityId => ({
        type: teamTypes.GET_ALL_TEAMS,
        communityId
      }),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Toolkit))