import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Table from '../Table/Table';
import ObjectiveCommentCard from './ObjectiveCommentCard';
import NoEntityBox from '../Common/NoEntityBox';

const useStyles = makeStyles({
  wrap: {},
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 5
  }
});

const ObjectiveComments = ({ comments }) => {
  // Hooks
  const classes = useStyles();

  // Constants
  const hasComments = comments.length > 0;

  return (
    <Box className={classes.wrap}>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={2}
        alignItems="center"
        style={{ minHeight: 40 }}
      >
        <Typography className={classes.title}>Reflections</Typography>
      </Box>
      {hasComments ? (
        <Table
          data={comments}
          elevation={0}
          boxShadow="0 4px 8px -1px rgba(0, 0, 0, 0.2)"
          rowCard={ObjectiveCommentCard}
          showEmptyRows={false}
        />
      ) : (
        <NoEntityBox text="There are no reflections yet" />
      )}
    </Box>
  );
};

ObjectiveComments.propTypes = {
  comments: PropTypes.array
};

ObjectiveComments.defaultProps = {
  comments: []
};

export default ObjectiveComments;
