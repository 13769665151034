import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles({
  paper: {
    padding: '25px 20px',
    borderRadius: 10
  },
  inline: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  input: {
    flex: 1
  },
  button: {
    width: 140,
    marginLeft: 20
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  backgroundCover: {
    borderRadius: 5,
    color: '#dcdcdc',
    background: '#dcdcdc',
    animation: '$glow 1.5s ease-in-out infinite'
  }
});

export default function UserAccountSettingsFormLoading() {
  const classes = styles();
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.inline}>
          <Typography className={clsx(classes.input, classes.backgroundCover)}>
            Loading
          </Typography>
          <Typography className={clsx(classes.backgroundCover, classes.button)}>
            Loading
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
