import * as types from './types';
import * as authTypes from '../auth/types';
import * as userTypes from '../users/types';

import { consolidateLeaderActivity, mergeArray } from '../../utils/stateHelpers';

const initialState = {
  loadingState: false,
  savingState: false,
  selectedCommunity: null, // Everyone
  currentUserCommunityPermissions: [],
  allCommunities: [], // Officers and admins only
  communityUsers: [],
  communityUsersLoadingState: false,
  communityLeaders: [],
  communityLeadersLoadingState: false,
  leaderUsers: [],
  leaderUsersLoadingState: true,
  leaderActivity: [],
  leaderActivityLoadingState: false,
  officerActivity: {
    total: 0,
    activities: []
  },
  officerAcitivityLoading: false,
  batchResults: null,
  psychFramworkSaving: false,
  psychFrameworkSaveKey: null,
  apiErrors: null,
  communityStats: [],
  statLoading: false,
  officerMembers: [],
  officerMembersLoading: true
};

const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_COMMUNITY:
      return {
        ...state,
        selectedCommunity: action.community
      };

    case types.UPDATE_COMMUNITY_PSYCH_FRAMEWORK_SUCCESS:
    case types.GET_COMMUNITY_SUCCESS:
      return {
        ...state,
        selectedCommunity: action.community
      };

    case types.CLEAR_COMMUNITY_SPECIFICS:
      return {
        ...state,
        communityUsers: [],
        leaderUsers: [],
        leaderActivity: [],
        leaderUsersLoadingState: true
      };

    case types.SET_COMMUNITY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentUserCommunityPermissions: action.permissions
      };

    case types.GET_ALL_COMMUNITIES_SUCCESS:
      return {
        ...state,
        allCommunities: action.communities
      };

    case userTypes.REMOVE_USER_FROM_COMMUNITY_SUCCESS:
    case types.GET_COMMUNITY_USERS_SUCCESS:
      return {
        ...state,
        communityUsers: action.users
      };

    case types.COMMUNITY_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.COMMUNITY_SAVING_STATE:
      return {
        ...state,
        savingState: action.state
      };

    case types.COMMUNITY_USERS_LOADING_STATE:
      return {
        ...state,
        communityUsersLoadingState: action.state
      };

    case types.GET_LEADER_USERS_SUCCESS:
      return {
        ...state,
        leaderUsers: action.leaderUsers
      };

    case types.LEADER_USERS_LOADING_STATE:
      return {
        ...state,
        leaderUsersLoadingState: action.state
      };

    case types.OFFICER_MEMBERS_LOADING_STATE:
      return {
        ...state,
        officerMembersLoading: action.state
      };

    case authTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        selectedCommunity: null,
        currentUserCommunityPermissions: [],
        allCommunities: [],
        communityUsers: [],
        communityLeaders: [],
        leaderUsers: [],
        leaderActivity: [],
        officerActivity: []
      };

    case userTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        // Add to users array
        communityUsers: [...state.communityUsers, action.user],
        // Updated selected community permissions
        selectedCommunity: action.community,
        // Updated communityLeaders
        communityLeaders: action.isLeader ? [...state.communityLeaders, action.user] : state.communityLeaders
      };

    case userTypes.ADD_LEADER_TO_USER_SUCCESS:
    case userTypes.REMOVE_LEADER_FROM_USER_SUCCESS:
    case userTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        communityUsers: state.communityUsers.map(user => {
          if (user._id === action.user._id) return action.user;
          return user;
        })
      };

    case types.COMMUNITY_LEADERS_LOADING_STATE:
      return {
        ...state,
        communityLeadersLoadingState: action.state
      };

    case types.UPDATE_COMMUNITY_LEADERS_SUCCESS:
    case types.GET_COMMUNITY_LEADERS_SUCCESS:
      return {
        ...state,
        communityLeaders: action.leaders
      };

    case types.BATCH_UPLOAD_USERS_SUCCESS:
      return {
        ...state,
        batchResults: action.batchResults,
        communityUsers: action.communityUsers,
        apiErrors: null
      };

    case types.UPDATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        selectedCommunity: action.community,
        apiErrors: null
      };

    case types.UPDATE_COMMUNITY_PSYCH_FRAMEWORK_ERROR:
    case types.UPDATE_COMMUNITY_ERROR:
    case types.BATCH_UPLOAD_USERS_ERROR:
      return {
        ...state,
        apiErrors: action.errors
      };

    case types.LEADER_ACTIVITY_LOADING_STATE:
      return {
        ...state,
        leaderActivityLoadingState: action.state
      };

    case types.OFFICER_ACTIVITY_LOADING_STATE:
      return {
        ...state,
        officerAcitivityLoading: action.state
      };

    case types.GET_AGNOSTIC_LEADER_ACTIVITY_SUCCESSS:
      return {
        ...state,
        officerActivity: {
          total: action.total,
          activities: action.activities
        }
      };

    case types.GET_LEADER_ACTIVITY_SUCCESS:
      return {
        ...state,
        leaderActivity: consolidateLeaderActivity(state.leaderActivity, action.leaderActivity)
      };

    case types.PSYCH_FRAMEWORK_SAVING_STATE:
      return {
        ...state,
        psychFrameworkSaveKey: action.key || null,
        psychFramworkSaving: action.state
      };

    case types.GET_MEMBER_STAT_OVERVIEW_SUCCESS:
      return {
        ...state,
        memberStats: action.memberStats
      };

    case types.GET_OFFICER_MEMBERS_SUCCESS:
      return {
        ...state,
        officerMembers: action.officerMembers
      };

    case types.MEMBER_STAT_LOADING_STATE:
      return {
        ...state,
        statLoading: action.state
      };

    default:
      return state;
  }
};

export default communityReducer;

export const selectCommunityLoadingState = state => state.community.loadingState;
export const selectCommunitySavingState = state => state.community.savingState;
export const selectSelectedCommunity = state => state.community.selectedCommunity;
export const selectSelectedCommunityId = state =>
  state.community.selectedCommunity ? state.community.selectedCommunity._id : null;
export const selectCurrentUserCommunityPermissions = state => state.community.currentUserCommunityPermissions;
export const selectAllCommunities = state => state.community.allCommunities;
export const selectCommunityUsersLoadingState = state => state.community.communityUsersLoadingState;
export const selectCommunityUsers = state => state.community.communityUsers;
export const selectLeaderUsers = state => state.community.leaderUsers;
export const selectLeaderUsersLoadingState = state => state.community.leaderUsersLoadingState;
export const selectCommunityLeaders = state => state.community.communityLeaders;
export const selectLeadersLoadingState = state => state.community.communityLeadersLoadingState;
export const selectBatchResults = state => state.community.batchResults;
export const selectCommunityApiErrors = state => state.community.apiErrors;
export const selectLeaderActivityLoadingState = state => state.community.leaderActivityLoadingState;
export const selectLeaderActivity = (state, communityId, userId) =>
  state.community.leaderActivity.find(a => a.userId === userId && a.communityId === communityId) || {
    userId,
    communityId,
    activities: []
  };
export const selectPsychFrameworkSaving = state => state.community.psychFramworkSaving;
export const selectPsychFrameworkSaveKey = state => state.community.psychFrameworkSaveKey;
export const selectOfficerActivityLoading = state => state.community.officerAcitivityLoading;
export const selectOfficerActivity = state => state.community.officerActivity;
export const selectMemberStats = state => state.community.memberStats;
export const selectOfficerMembers = state => state.community.officerMembers;
export const selectMemberStatLoading = state => state.community.statLoading;
export const selectOfficerMembersLoading = state => state.community.officerMembersLoading;

export const selectMemberById = (state, id) => state.community.leaderUsers.find(u => u.id === id);
