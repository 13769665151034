import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { selectLoggedInUserId } from '../../state/auth/reducers';
import { selectSelectedCommunityId } from '../../state/community/reducers';

import { AUTH_LOGOUT } from '../../state/auth/types';

import useProfileImage from '../../hooks/useProfileImage';

const useStyles = makeStyles(theme => ({
  image: ({ profileImage }) => ({
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 0 10px -5px rgba(0, 0, 0, 0.1)',
    height: 40,
    width: 40,
    backgroundColor: theme.palette.common.white,
    backgroundImage: `url(${profileImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#d2d2d2'
  })
}));

const AuthUserMenu = () => {
  const userId = useSelector(state => selectLoggedInUserId(state));
  const communityId = useSelector(state => selectSelectedCommunityId(state));

  const { image: profileImage } = useProfileImage(userId);

  const classes = useStyles({ profileImage });
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = e => setAnchorEl(e.target);
  const closeMenu = () => setAnchorEl(null);

  const handleProfile = () => {
    history.push(`/c/${communityId}/u/${userId}/account`);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch({ type: AUTH_LOGOUT });
    history.push('/login');
  };

  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <div
        onClick={openMenu}
        className={classes.image}
        role="button"
        onKeyPress={null}
        tabIndex={0}
        title="User menu"
      />
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        classes={{ paper: classes.paperRoot, list: classes.list }}
      >
        <MenuItem onClick={handleProfile} classes={{ root: classes.menuItemRoot }}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} classes={{ root: classes.menuItemRoot }}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AuthUserMenu;
