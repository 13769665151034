import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import statusIcons from '../../constants/statusIcons';

const MenuProps = {
  // Required or element adds padding to body when open
  // https://github.com/FormidableLabs/victory/issues/396
  disableScrollLock: true,

  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  anchorOrigin: {
    vertical: 0,
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: 40
  },
  readOnly: {
    display: 'flex',
    alignItems: 'center'
  },
  valueWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: 10,
    padding: '5px 34px 5px 15px !important',
    borderRadius: 10,
    border: 'solid 1px #d6d6d6',
    '&:focus': {
      backgroundColor: '#ffffff !important',
      borderRadius: '10px !important'
    }
  },
  inputRoot: {
    paddingLeft: 6,
    borderRadius: 10,
    backgroundColor: '#ffffff',
    border: 'none !important',
    height: '100%',
    '& svg': {
      marginRight: 10
    }
  },
  menu: {
    padding: '0px 3px',
    // Add border so it lines up with the border of the parent element
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white'
  },
  menuItem: {
    fontSize: 12,
    padding: '12px 16px',
    fontWeight: 300,
    borderRadius: 10
  },
  iconRoot: {
    minWidth: 30
  },
  menuItemText: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  inactive: {
    color: '#96a5b6'
  },
  complete: {
    color: '#21b15f'
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  }
}));

const StatusDropdown = ({ readOnly, isMission, onChange, selected, formControlClass, saving, ...rest }) => {
  // Hooks
  const classes = useStyles();

  // Event handlers
  const handleChange = e => {
    const { value: newValue } = e.target;
    if (onChange) onChange(newValue);
  };

  // Constants
  const statusOptions = [
    {
      value: 'inactive',
      label: 'Inactive',
      icon: statusIcons.inactive,
      textClass: classes.inactive,
      mission: false
    },
    {
      value: 'in progress',
      label: 'In Progress',
      icon: statusIcons.inprogress,
      textClass: classes.inprogress,
      mission: true
    },
    {
      value: 'complete',
      label: 'Complete',
      icon: statusIcons.complete,
      textClass: classes.complete,
      mission: true
    },
    {
      value: 'need help',
      label: 'Need help',
      icon: statusIcons.needhelp,
      textClass: classes.warning,
      mission: false
    },
    {
      value: 'no longer relevant',
      label: 'No Longer Relevant',
      icon: statusIcons.nolongerrelevant,
      textClass: classes.error,
      mission: false
    }
  ];

  const computedOptions = isMission ? statusOptions.filter(o => o.mission) : statusOptions;

  const computedFormControlClass = clsx({
    [classes.formControl]: true,
    [formControlClass]: Boolean(formControlClass)
  });

  const selectedOption = statusOptions.find(o => o.value === selected);

  return (
    <FormControl className={computedFormControlClass}>
      {(readOnly || saving) && (
        <Typography
          className={clsx(classes.inputRoot, classes.readOnly, classes.menuItemText, selectedOption.textClass)}
        >
          {selectedOption.icon}
          {selectedOption.label}
        </Typography>
      )}
      {!readOnly && !saving && (
        <Select
          {...rest}
          value={selected}
          IconComponent={KeyboardArrowDownIcon}
          input={<InputBase classes={{ root: classes.inputRoot, focused: classes.selectFocus }} />}
          classes={{ select: classes.select }}
          onChange={handleChange}
          MenuProps={{
            ...MenuProps,
            classes: {
              paper: classes.menu
            }
          }}
          renderValue={value => {
            const op = computedOptions.find(o => o.value === value);
            const OptionIcon = op?.icon || statusOptions[0].icon;

            return (
              <div className={classes.valueWrap}>
                <ListItemIcon classes={{ root: classes.iconRoot }}>{OptionIcon}</ListItemIcon>
                <ListItemText
                  primary={op?.label}
                  classes={{
                    primary: clsx(classes.menuItemText, op?.textClass)
                  }}
                />
              </div>
            );
          }}
        >
          {computedOptions.map((option, idx) => {
            const OptionIcon = option.icon || null;
            return (
              <MenuItem key={`dropdownItem-${idx}`} value={option.value || option} className={classes.menuItem}>
                {OptionIcon && <ListItemIcon classes={{ root: classes.iconRoot }}>{OptionIcon}</ListItemIcon>}
                <ListItemText
                  primary={option.label || option}
                  classes={{
                    primary: classes.menuItemText
                  }}
                />
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};

StatusDropdown.propTypes = {
  isMission: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  formControlClass: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  saving: PropTypes.bool
};

StatusDropdown.defaultProps = {
  isMission: false,
  formControlClass: '',
  selected: 'inactive',
  saving: false
};

export default StatusDropdown;
