import React, { useState, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

import DrawerHeading from '../DrawerHeading/DrawerHeading';
import UserSelect from '../UserSelect/UserSelect';

import InfoBar from '../InfoBar/InfoBar';
import ObjectiveStatement from './ObjectiveStatement';
import Scheduling from './Scheduling';
import ObjectiveDrawerTasks from './ObjectiveDrawerTasks';
import ObjectiveDrawerPractices from './ObjectiveDrawerPractices';
// import PracticeInput from './PracticeInput';
import Button from '../Button/Button';

import {
  deriveObjectiveActionTitle, randomId, ensurePositiveNumber, getStringDescFromSlate
} from '../../utils/helpers';
import {
  validateMissionTemplateObjective,
  validateNewMissionObjective,
  validateActiveMissionObjective,
  getErrorMessage
} from '../../constants/validation';

import { DANGER_SNACK, SUCCESS_SNACK } from '../../state/snack/types';
import TextInput from '../TextInput/TextInput';

const useStyles = makeStyles({
  wrap: {
    padding: '20px 40px',
  },
  drawer: {
    width: 720,
    marginLeft: 80
  },
  icon: {
    position: 'absolute'
  },
  button: {
    width: 150
  },
  delete: {
    marginRight: 10
  },
  infoWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 40px'
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10
  }
});

const initialStatement = {
  verb: '',
  metric: '',
  noun: '',
  daysOnceActive: '',
  outcome: '',
  dueDate: moment().add(1, 'months')
};

const ObjectiveDrawer = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  onDelete,
  selectedObjective,
  isTemplate,
  isActive,
  missionEnd,
  readOnly,
  communityUsers,
  isCommunity
}) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local state
  const [isPreviouslyEdit, setIsPreviouslyEdit] = useState(false)
  const [statement, setStatement] = useState(initialStatement);
  const [startDays, setStartDays] = useState('');
  const [objectiveTitle, setObjectiveTitle] = useState('')
  const [startDate, setStartDate] = useState(moment());
  const [tasks, setTasks] = useState([]);
  const [practices, setPractices] = useState([]);
  const [errors, setErrors] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(selectedObjective?.user);

  // Note: null is the default.
  // An empty array is still considered a community objective
  // Set state based on props
  useEffect(() => {
    if (selectedObjective && open) {
      setIsPreviouslyEdit(true)
      const {
        statement: existingStatement,
        tasks: existingTasks,
        practices: existingPractices,
        startDays: existingStartDays,
        startDate: existingStartDate,
        title: existingTitle,
      } = selectedObjective;

      setObjectiveTitle(existingTitle);
      setStatement(existingStatement);
      setTasks(existingTasks);
      setPractices(existingPractices);
      setStartDays(existingStartDays);
      setStartDate(existingStartDate ? moment(existingStartDate) : moment());
    } else if (open) {
      setPractices([]);
      setIsPreviouslyEdit(false)
    }
  }, [selectedObjective, open]);

  // Helpers
  const showErrorSnack = () => {
    dispatch({
      type: DANGER_SNACK,
      content: 'Please check all of the required fields'
    });
  };

  const showSaveSnack = () => {
    dispatch({
      type: SUCCESS_SNACK,
      content: 'Please click the Save button at the top to make changes.'
    });
  };

  // There are three different validtors for an objective
  const getObjectiveValidator = () => {
    // An objective that has already started
    if (moment(selectedObjective?.startDate).isBefore(moment())) return validateActiveMissionObjective;
    // A brand new objective
    return validateNewMissionObjective;
  };

  const updateErrors = values => {
    const validator = isTemplate ? validateMissionTemplateObjective : getObjectiveValidator();
    const hasError = validator(values);
    if (!hasError) setErrors(null);
    else setErrors(hasError);
  };

  // Event handlers
  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'startDays') {
      setStartDays(ensurePositiveNumber(value));

      if (errors) {
        updateErrors({ ...statement, startDays: value, startDate });
      }
    } else if (name === 'title') {
      setObjectiveTitle(value)
      if (errors) {
        updateErrors({ ...statement, title: value });
      }
    } else {
      const isNumeric = name === 'metric' || name === 'daysOnceActive';

      const updated = {
        ...statement,
        [name]: isNumeric ? ensurePositiveNumber(value) : value
      };

      if (errors) {
        updateErrors({ ...updated, startDays, startDate });
      }

      setStatement({
        ...updated
      });
    }
  };

  const handleDateChange = date => {
    if (errors) {
      updateErrors({ ...statement, startDays, startDate: date });
    }
    setStartDate(date);
  };

  const handleClose = () => {
    onClose();
    if (isPreviouslyEdit) {
      setStatement(initialStatement);
      setStartDays('');
      setTasks([]);
      setObjectiveTitle('')
      setErrors(null);
    }
  };

  const formatRichDescription = (taskOrPractice) => {
    const newFormat = {...taskOrPractice}
    if (taskOrPractice.richDescription) newFormat.description = getStringDescFromSlate(taskOrPractice.richDescription)
    // avoid validation error in backend on null description
    if (!taskOrPractice.description) newFormat.description = `Do ${taskOrPractice.task}`
    return newFormat
  }

  const addTask = task => {
    const newTask = formatRichDescription(task)
    // assign temporary id to avoid array's event conflict
    if (!newTask.id) newTask.id = Math.random()
    let tasksData = [...tasks, newTask];
    tasksData = [...tasksData].reverse();
    setTasks(tasksData);
  };

  const editTask = (task) => {
    const newList = tasks.map(ts => {
      let newTask = {...ts}
      if (ts.id && ts.id === task.id) {
        // change ts to new 'task'
        newTask = formatRichDescription(task)
      }
      return newTask
    })
    setTasks([...newList]);
  }

  const addPractice = p => {
    p.map(pt => {
      return formatRichDescription(pt)
    })
    setPractices(p);
  }

  const editPractice = practice => {
    const newList = practices.map(pt => {
      let newPractice = {...pt}
      if (pt.id && pt.id === practice.id) {
        // change pt to new 'p'
        newPractice = formatRichDescription(practice)
      }
      return newPractice
    })
    setPractices(newList);
  }

  const handleSubmit = () => {
    const { metric } = statement;
    const isMetric = Boolean(metric);
    const title = objectiveTitle || deriveObjectiveActionTitle(statement);

    const validator = isTemplate ? validateMissionTemplateObjective : getObjectiveValidator();

    // Validate
    const errorMessages = validator({
      ...statement,
      startDays,
      startDate
    });

    if (!errorMessages) {
      setErrors(null);
      // make sure task & description has description
      let newTasks = []
      let newPractices = []
      if (tasks && tasks.length) {
        newTasks = tasks.map(ts => {
          if (typeof ts.id === 'number') delete ts.id
          return formatRichDescription(ts)
        })
      }
      // WARNING: double assigning of richDescription with addPractice
      if (practices && practices.length) {
        newPractices = practices.map(nt => {
          if (typeof nt.id === 'number') delete nt.id
          return formatRichDescription(nt)
        })
      }
      if (!selectedObjective) {
        showSaveSnack();

        onCreate({
          id: randomId(),
          title,
          startDays,
          isMetric,
          tasks: newTasks,
          practices: newPractices,
          startDate,
          ...(isCommunity && { user: selectedUserId || null }),
          statement: { ...statement, metric: isMetric ? statement.metric : null }
        });
      } else {
        onUpdate({
          ...selectedObjective,
          title,
          startDays,
          startDate,
          isMetric,
          tasks,
          practices,
          ...(isCommunity && { user: selectedUserId || null }),
          statement: { ...statement, metric: isMetric ? statement.metric : null }
        });
      }

      if (isTemplate) {
        dispatch({
          type: SUCCESS_SNACK,
          content: 'Objective updated successfully.'
        });
      }

      handleClose();
    } else {
      setErrors(errorMessages);
      showErrorSnack();
    }
  };

  const handleDelete = () => {
    onDelete(selectedObjective);

    handleClose();
  };

  const deleteTask = task => {
    const updated = tasks.filter(t => t.id !== task.id);
    setTasks(updated);
  };

  const onDueDateChange = date => {
    if (errors) {
      updateErrors({ ...statement, startDays, startDate, dueDate: date });
    }
    setStatement({
      ...statement,
      dueDate: date
    });
  };

  // Constants
  const headerActions = (
    <>
      {selectedObjective && (
        <Button
          className={clsx(classes.button, classes.delete)}
          background="grey"
          label="Archive"
          onClick={handleDelete}
        />
      )}
      <Button className={classes.button} label={selectedObjective ? 'Save' : 'Create'} onClick={handleSubmit} />
    </>
  );

  const templateInfoJsx = (
    <InfoBar
      background="blue"
      content={`
        You are ${selectedObjective ? 'editing' : 'creating'}
        this Objective as ${isTemplate ? 'part of a Mission Template' : 'their Leader'}
      `}
    />
  );

  // const leaderInfoJsx = (
  //   <InfoBar
  //     background="blue"
  //     content={`You are ${selectedObjective ? 'editing' : 'creating'} this Objective as their Leader`}
  //   />
  // );

  const { verb, metric, noun, daysOnceActive, outcome } = statement;

  const renderCommunityUsers = () =>
    communityUsers.length === 0 ? null : (
      <Box padding={5}>
        <Typography className={classes.title}>User</Typography>
        <UserSelect
          multiple={false}
          users={communityUsers}
          onSelect={user => setSelectedUserId(user)}
          selected={selectedUserId}
          placeholder="Search member"
          buttonText={!selectedUserId ? 'Assign a user to objective' : 'Change objectives assigned user'}
          noDataMessage="No users to choose from!"
          saving={false}
          loading={[]}
        // userIdsPendingChange={false}
        />
      </Box>
    );

  return (
    <Drawer open={open} anchor="right" onClose={handleClose} PaperProps={{ classes: { root: classes.drawer } }}>
      <IconButton onClick={handleClose} className={classes.icon}>
        <CloseIcon />
      </IconButton>

      <DrawerHeading
        isActive={isActive}
        heading={selectedObjective ? 'Edit Objective' : 'Create Objective'}
        actions={headerActions}
      />
      {!isActive && <Box className={classes.infoWrap}>{isTemplate ? templateInfoJsx : null}</Box>}
      <Box className={classes.wrap}>
        <Typography className={classes.title}>Objective Info</Typography>
        <TextInput
          className={classes.action}
          name="title"
          label="Title"
          required
          value={objectiveTitle}
          onChange={handleChange}
          errorMessage={getErrorMessage(errors, 'title')}
          showError={false}
        />
      </Box>
      <ObjectiveStatement
        onChange={handleChange}
        action={verb}
        qty={metric}
        thing={noun}
        daysOnceActive={daysOnceActive}
        outcome={outcome}
        errors={errors}
        isTemplate={isTemplate}
        dueDate={statement?.dueDate || ''}
        onDueDateChange={onDueDateChange}
      />
      <Scheduling
        onChange={handleChange}
        startDays={startDays}
        errors={errors}
        isTemplate={isTemplate}
        activateDate={startDate}
        onDateChange={handleDateChange}
        missionEnd={missionEnd}
        existingStartDate={selectedObjective?.startDate || ''}
      />
      <Box p={4}>
        {isCommunity && renderCommunityUsers()}
        <ObjectiveDrawerTasks
          addTask={addTask}
          editTask={editTask}
          deleteTask={deleteTask}
          tasks={tasks}
          objectiveStartDate={moment(startDate)}
          readOnly={readOnly}
          objectiveStatement={statement}
          isTemplate={isTemplate}
        />

        <ObjectiveDrawerPractices
          onUpdate={addPractice}
          onEdit={editPractice}
          practices={practices}
        />
        {/* {
          selectedObjective?.practices.map(p => <PracticeInput prac)
        } */}
      </Box>
      {/* {JSON.stringify(communityUsers)} */}
    </Drawer>
  );
};

ObjectiveDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  isTemplate: PropTypes.bool,
  selectedObjective: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  missionEnd: PropTypes.instanceOf(moment),
  isActive: PropTypes.bool,
  readOnly: PropTypes.bool,
  communityUsers: PropTypes.array,
  isCommunity: PropTypes.bool,
};

ObjectiveDrawer.defaultProps = {
  isTemplate: false,
  selectedObjective: null,
  missionEnd: null,
  isActive: false,
  onCreate: null,
  readOnly: false,
  communityUsers: [],
  isCommunity: false,
};

export default ObjectiveDrawer;
