import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import { DataGrid } from '@material-ui/data-grid';
import ProfileImageAvatar from '../ProfileImageAvatar/ProfileImageAvatar';
import PracticeScoreChip from '../Chips/PracticeScoreChip';

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: 12,
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-cell': {
      fontSize: 12,
      // background: 'white',
      borderWidth: 0,
      borderTopWidth: 1,
      // borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: '#aaaaaa',
      flex: 1,
      width: '100%',
      marginBottom: 10,
      height: 52,
      maxHeight: 52
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer'
    }
  }
}));

const ObjectivesTable = ({
  loading,
  objectives,
  sortingMode,
  pagination,
  showAssignedUser,
  onSortChange,
  onPageChange,
  onRowClick
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    {
      field: 'title',
      headerName: 'Objective',
      flex: 0.2,
      renderCell: ({ row }) => <Typography style={{ fontWeight: 'bold' }}>{row.title}</Typography>
    },
    !!showAssignedUser && {
      field: 'user',
      headerName: 'Assigned User',
      flex: 0.2,
      renderCell: ({ row }) => (row?.user ? <ProfileImageAvatar userId={row.user.id} /> : null)
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 0.1,
      // valueFormatter: params => moment(params.value).format('Do MMM YYYY'),
      renderCell: ({ row }) =>
        new Date(row.dueDate) < new Date() ? (
          <span style={{ color: 'red' }}>{moment(row.dueDate).format('Do MMM YYYY')}</span>
        ) : (
          moment(row.dueDate).format('Do MMM YYYY')
        )
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: ({ row }) => (
        <Chip
          size="small"
          label={row.status}
          style={{
            backgroundColor: theme.palette.status[row.status],
            color: 'white',
            fontSize: 10
          }}
        />
      )
    },
    {
      field: 'practiceScore',
      headerName: 'Practice Score',
      flex: 0.1,
      sortable: false,
      renderCell: ({ row }) => <PracticeScoreChip score={row.practiceScore} />
    },
    {
      field: 'completedActions',
      headerName: 'Completed Actions',
      flex: 0.1,
      sortable: false
    }
  ].filter(Boolean);

  const rows = objectives.map(objective => {
    const completedTasks = objective.tasks.filter(task => task.isComplete).length;
    // currently objective titles are not used
    const title = `${objective.statement.verb} ${objective.statement.noun}`;
    const formatterTitle = title.charAt(0).toUpperCase() + title.slice(1);
    return {
      id: objective.id,
      title: formatterTitle,
      ...(showAssignedUser && { user: objective.user }),
      dueDate: objective.statement.dueDate,
      status: objective.status,
      practiceScore: objective.practicesScore,
      completedActions: `${completedTasks} of ${objective.tasks.length}`,
      mission: objective.mission
    };
  });

  const sortProps =
    sortingMode === 'server'
      ? {
          sortingMode,
          onSortModelChange: onSortChange
        }
      : {};

  return (
    <Box width={1}>
      <DataGrid
        row
        hideFooter
        height={2000}
        rowHeight={72}
        // style={{ height: 5400 }}
        className={classes.root}
        loading={loading}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[]}
        sortingOrder={['asc', 'desc']}
        {...sortProps}
        disableColumnMenu
        autoHeight
        hideFooterPagination
        getRowClassName={params => `objective-row objective-row${params.id}`}
        onRowClick={onRowClick}
      />

      {pagination && pagination.totalPages > 1 && (
        <Box display="flex" justifyContent="center" p={1}>
          <Pagination count={pagination.totalPages} page={pagination.page} onChange={(e, v) => onPageChange(v)} />
        </Box>
      )}
    </Box>
  );
};

ObjectivesTable.propTypes = {
  loading: PropTypes.bool,
  objectives: PropTypes.array.isRequired,
  sortingMode: PropTypes.oneOf(['client', 'server']),
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }),
  showAssignedUser: PropTypes.bool,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func
};

ObjectivesTable.defaultProps = {
  loading: false,
  sortingMode: 'client',
  pagination: null,
  showAssignedUser: false,
  onSortChange: null,
  onPageChange: null,
  onRowClick: null
};

export default ObjectivesTable;
