import PropTypes from 'prop-types'
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TreeItem from '@material-ui/lab/TreeItem';
import {useDispatch, useSelector} from "react-redux";
import {selectToolkitCategories, selectToolkitCategoriesLoadingState} from "../../state/toolkitCategories/reducers";
import * as toolkitCategoryTypes from "../../state/toolkitCategories/types";
import Spinner from "../Spinner/Spinner";
import Typography from "@material-ui/core/Typography";
import {getNodeIds, groupCategories} from "../../utils/toolkitHelpers";

const useViewStyles = makeStyles({
  root: {}
});

const useItemStyles = makeStyles(() => ({
  root: {},
  iconContainer: {},
  label: {
    padding: '.2rem',
    fontFamily: 'SFUIText-Reg',
    fontSize: '1rem',
  },
  selected: {}
}));

const ToolkitCategoriesTree = ({nodes, onChange, selectedCategories}) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => selectToolkitCategoriesLoadingState(state));
  const toolkitCategories = useSelector(state => selectToolkitCategories(state));
  const classesView = useViewStyles();
  const classesItem = useItemStyles();

  useEffect(() => {
    dispatch({
      type: toolkitCategoryTypes.GET_TOOLKIT_CATEGORIES,
    });
  },[])

  const renderNodes = () => {
    if (toolkitCategories.length === 0) {
      return (<div style={{
        textAlign: 'center',
        marginTop: '2rem'
      }}
      >
        <Typography style={{fontSize: '.8rem', color: '#838b96',}}>
          No categories yet. Click `Edit Categories` to add a category.
        </Typography>
      </div>)
    }

    const renderedNodes = groupCategories(toolkitCategories).map((parentNode, parentNodeIndex) => {
      const parentNodeId = `${parentNode.name}${parentNodeIndex}`

      return (
        <TreeItem
          onIconClick={(e) => {
            onChange(parentNode?.id, selectedCategories.includes(parentNode?.id))
          }}
          icon={selectedCategories.includes(parentNode?.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          key={parentNodeId}
          classes={classesItem}
          nodeId={parentNodeId}
          label={parentNode.name}
        >
          {parentNode?.children && parentNode?.children.map((childrenNode, childrenNodeIndex) => {
            const childrenNodeId = `${childrenNode.name}${childrenNodeIndex}`

            return (
              <TreeItem
                onIconClick={(e) => {
                  onChange(childrenNode?.id, selectedCategories.includes(childrenNode?.id))
                }}
                icon={selectedCategories.includes(childrenNode?.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                key={childrenNodeId}
                classes={classesItem}
                nodeId={childrenNodeId}
                label={childrenNode.name}
              >
                {childrenNode?.children &&
                    childrenNode?.children.map((grandChildrenNode, grandChildrenNodeIndex) => {
                  const grandChildrenNodeId = `${grandChildrenNode.name}${grandChildrenNodeIndex}`

                  return (
                    <TreeItem
                      onIconClick={(e) => {
                        onChange(grandChildrenNode?.id, selectedCategories.includes(grandChildrenNode?.id))
                      }}
                      icon={selectedCategories.includes(grandChildrenNode?.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      classes={classesItem}
                      key={grandChildrenNodeId}
                      nodeId={grandChildrenNodeId}
                      label={grandChildrenNode.name}
                    />)
                })}
              </TreeItem>
            )
          })}
        </TreeItem>
      )
    })

    return renderedNodes
  }

  if (loading) {
    return <Spinner />
  }

  let defaultExpanded = getNodeIds(toolkitCategories)

  return (
    <TreeView
      expanded={defaultExpanded}
      disableSelection
      classes={classesView}
      defaultExpanded={defaultExpanded}
      defaultCollapseIcon={<CheckBoxIcon />}
      defaultExpandIcon={<CheckBoxIcon />}
      defaultEndIcon={<CheckBoxIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {renderNodes()}
    </TreeView>
  )
}

ToolkitCategoriesTree.propTypes = {
  nodes: PropTypes.array.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ToolkitCategoriesTree
