import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';

import UserAccountSettingsFormLoading from './UserAccountSettingsFormLoading';

import {
  getErrorMessage,
  parseApiErrors,
  validateEmail,
  validateNewPassword
} from '../../constants/validation';

import {
  selectUserEmailChange,
  selectEmailChangeSaving,
  selectSelectedUser,
  selectUserApiErrors
} from '../../state/users/reducers';
import * as userTypes from '../../state/users/types';

import {
  selectAuthApiErrors,
  selectAuthSavingState
} from '../../state/auth/reducers';
import * as authTypes from '../../state/auth/types';

const styles = makeStyles({
  paper: {
    borderRadius: 10
  },
  gridPadding: {
    padding: 20
  },
  inline: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  button: {
    width: 140,
    marginLeft: 20
  },
  pendingButton: {
    marginLeft: 10
  },
  emailPending: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#ff7500'
  }
});

export default function UserAccountSettingsForm({
  onChange,
  loading,
  onEmailSave,
  onEmailResend,
  onEmailCancel,
  password,
  passwordConfirm,
  oldPassword,
  onPasswordSave
}) {
  const classes = styles();

  // Redux
  const selectedUser = useSelector(state => selectSelectedUser(state));
  const saving = useSelector(state => selectEmailChangeSaving(state));
  const emailChange = useSelector(state => selectUserEmailChange(state));
  const userApiErrors = useSelector(state => selectUserApiErrors(state));
  const authApiErrors = useSelector(state => selectAuthApiErrors(state));
  const authSaving = useSelector(state => selectAuthSavingState(state));

  // Local hook state
  const [email, setEmail] = useState('');
  const [emailErrors, setEmailErrors] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState(null);

  useEffect(() => {
    if (selectedUser !== null && !emailChange) {
      setEmail(selectedUser.email);
    }
    if (emailChange) setEmail(emailChange.email);
  }, [selectedUser, emailChange]);

  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  function handleEmailSubmit() {
    const errors = validateEmail(email);
    if (errors === undefined) {
      setEmailErrors(null);
      onEmailSave(email);
    } else setEmailErrors(errors);
  }

  function handlePasswordSubmit() {
    const errors = validateNewPassword({ password, passwordConfirm });
    if (errors === undefined) {
      setPasswordErrors(null);
      onPasswordSave();
    } else setPasswordErrors(errors);
  }

  // Computed error objects
  const computedEmailErrors =
    emailErrors ||
    parseApiErrors(userApiErrors, userTypes.REQUEST_EMAIL_CHANGE_ERROR);
  const computedPasswordErrors =
    passwordErrors ||
    parseApiErrors(authApiErrors, authTypes.CHANGE_OWN_PASSWORD_ERROR);

  // Helpers
  const hasEmailChangeRequest = emailChange !== null && selectedUser !== null;
  const helperMessage = hasEmailChangeRequest
    ? `Changed from ${selectedUser.email}`
    : null;

  return loading ? (
    <UserAccountSettingsFormLoading />
  ) : (
    <Paper className={classes.paper}>
      <Grid container className={classes.gridPadding}>
        <Grid item xs={12} className={classes.inline}>
          <TextInput
            label="Email address"
            name="email"
            value={email}
            onChange={handleChange}
            helperMessage={helperMessage}
            error={Boolean(getErrorMessage(computedEmailErrors, 'email'))}
            errorMessage={getErrorMessage(computedEmailErrors, 'email')}
            endAdornment={
              hasEmailChangeRequest ? (
                <Typography className={classes.emailPending}>Pending</Typography>
              ) : null
            }
          />
          <Box display="flex" height="100%" alignItems="flex-start" paddingTop={2}>
            {hasEmailChangeRequest ? (
              <>
                <Button
                  label="Resend"
                  background="grey"
                  className={classes.pendingButton}
                  onClick={onEmailResend}
                  disabled={saving}
                  loading={saving}
                />
                <Button
                  label="Cancel"
                  background="grey"
                  className={classes.pendingButton}
                  onClick={onEmailCancel}
                  disabled={saving}
                  loading={saving}
                />
              </>
            ) : (
              <Button
                label="Save"
                background="grey"
                className={classes.button}
                onClick={handleEmailSubmit}
                loading={saving}
                disabled={saving}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={classes.gridPadding}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Box display="flex">
            <TextInput
              label="Old password"
              name="oldPassword"
              value={oldPassword}
              onChange={onChange}
              type="password"
              error={Boolean(getErrorMessage(computedPasswordErrors, 'oldPassword'))}
              errorMessage={getErrorMessage(computedPasswordErrors, 'oldPassword')}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex">
            <TextInput
              label="New password"
              name="password"
              value={password}
              onChange={onChange}
              type="password"
              error={Boolean(getErrorMessage(computedPasswordErrors, 'password'))}
              errorMessage={getErrorMessage(computedPasswordErrors, 'password')}
            />
            <TextInput
              formControlStyle={{ marginLeft: 20 }}
              label="Confirm new password"
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={onChange}
              type="password"
              error={Boolean(
                getErrorMessage(computedPasswordErrors, 'passwordConfirm')
              )}
              errorMessage={getErrorMessage(
                computedPasswordErrors,
                'passwordConfirm'
              )}
            />
            <Box display="flex" height="100%" alignItems="flex-start" paddingTop={2}>
              <Button
                label="Save"
                background="grey"
                className={classes.button}
                onClick={handlePasswordSubmit}
                disabled={authSaving}
                loading={authSaving}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

UserAccountSettingsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onEmailSave: PropTypes.func.isRequired,
  onEmailResend: PropTypes.func.isRequired,
  onEmailCancel: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  passwordConfirm: PropTypes.string.isRequired,
  onPasswordSave: PropTypes.func.isRequired,
  oldPassword: PropTypes.string.isRequired
};
