import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import CommunityMissionsListView from '../components/CommunityMissions/CommunityMissionsListView';
import { selectCurrentUserCommunityPermissions } from '../state/community/reducers';

const CommunityMissions = () => {
  const { communityId } = useParams();
  const history = useHistory();

  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));

  const isAdmin = permissions.some(p => p === 'admin');

  const handleMissionClick = mission => {
    history.push(`/c/${communityId}/m/${mission.id}`);
  };

  return (
    <Box>
      <CommunityMissionsListView
        communityId={communityId}
        onMissionClick={handleMissionClick}
        canCreateMission={isAdmin}
      />
    </Box>
  );
};

export default CommunityMissions;
