import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import Fab from '@material-ui/core/Fab';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import ChatLoading from './ChatLoading';
import SearchInput from '../SearchInput/SearchInput';
import ChatDrawerItem from './ChatDrawerItem';

import * as chatTypes from '../../state/chat/types';
import {
  selectOpenChats,
  selectAllLeaderUsers,
  selectChatLoading,
  selectChatMessages,
  selectUnreadChatCounts
} from '../../state/chat/reducers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName']
};

const styles = makeStyles(theme => ({
  drawerRoot: {
    minWidth: 200
  },
  drawerPaper: {
    width: 200,
    paddingTop: 19,
    background: 'white',
    overflow: 'auto',
    boxShadow: `
      0 0px 2.7px inset rgba(0, 0, 0, 0.019),
      0 0px 6.9px inset rgba(0, 0, 0, 0.027),
      0 0px 14.2px inset rgba(0, 0, 0, 0.033),
      0 0px 29.2px inset rgba(0, 0, 0, 0.041),
      0 0px 80px inset rgba(0, 0, 0, 0.06)
    `
  },
  padding: {
    padding: '0px 20px'
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    marginBottom: theme.spacing(1)
  },
  searchInput: {
    height: '34px',
    flexShrink: 0
  },
  noResults: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontSize: 12
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 5
  }
}));

export default function ChatDrawer() {
  const classes = styles();
  const dispatch = useDispatch();

  // Local state
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  // Redux
  const users = useSelector(state => selectAllLeaderUsers(state));
  const openChats = useSelector(state => selectOpenChats(state));
  const chatLoading = useSelector(state => selectChatLoading(state));
  const chatMessages = useSelector(state => selectChatMessages(state));

  const unreadChatCounts = useSelector(state => selectUnreadChatCounts(state));

  // Search helper
  const searchMembers = members => {
    const fuseInstance = new Fuse(members, fuseOpts);
    return fuseInstance.search(search);
  };

  // Event handler
  function onSearchChange(value) {
    setSearch(value);
  }

  function onClick(user) {
    const isOpen = openChats.some(u => u._id === user._id);
    setOpen(false);
    if (!isOpen) {
      dispatch({
        type: chatTypes.OPEN_CHAT,
        user
      });
    }
  }

  // Helper constants
  const messagesInitialized = chatMessages.length > 0;
  const hasSearch = search.trim().length > 0;
  const computedUsers = hasSearch ? searchMembers(users) : users;
  const noResults = hasSearch && !computedUsers.length;

  let latestMessage = chatMessages.sort((a, b) => a.timestamp - b.timestamp);
  const userList = computedUsers
    .map(user => {
      const isOpen = openChats.some(u => u._id === user._id);
      const userName = `${user.firstName} ${user.lastName}`;
      const { _id: userId } = user;
      const messageLength = chatMessages.filter(m => m.fromID === userId).length;
      const lastMessage = chatMessages.filter(m => {
        if(m.toID === userId) {
          return m;
        }
      });
        
      const latestTimestamp = lastMessage && lastMessage[lastMessage.length - 1] ? lastMessage[lastMessage.length - 1].timestamp: "";
      const unreadCount = unreadChatCounts?.[user?._id] || 0;
      return { ...user, isOpen, userName, messageLength, unreadCount, lastMessage: latestTimestamp };
    })
    .sort((a, b) => {
      return a.lastMessage > b.lastMessage ? -1 : 1;
    })
     .sort((a, b) => {
      return a.unreadCount > b.unreadCount ? -1 : 1;
    });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
      <Fab className={classes.fab} onClick={handleDrawerOpen}>
        <ChatIcon />
      </Fab>

      <Drawer
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper
        }}
        open={open}
        anchor="right"
      >

        {chatLoading ? (
          <ChatLoading />
        ) : (
          <>
            <IconButton className={classes.iconButton} onClick={handleDrawerClose} >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Typography className={clsx(classes.padding, classes.title)}>Chat</Typography>
            <FormControl className={classes.padding} style={{ display: 'block' }}>
              <SearchInput
                onChange={onSearchChange}
                value={search}
                className={classes.searchInput}
                placeholder="Search members..."
              />
            </FormControl>
            {noResults && <Typography className={classes.noResults}>No results found</Typography>}
            <List>
              {userList.map(user => {
                return (
                  <ChatDrawerItem
                    key={user._id}
                    userId={user._id}
                    onClick={() => onClick(user)}
                    userName={user.userName}
                    isOpen={user.isOpen}
                    messagesInitialized={messagesInitialized}
                    numOfMessages={user.messageLength}
                    unreadCount={user.unreadCount}
                  />
                );
              })}
            </List>
          </>
        )}
      </Drawer>
    </Box>
  );
}
