import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ProfileImage from '../ProfileImage/ProfileImage';
import StatCardLoading from './StatCardLoading';
import PaginationActions from '../Table/PaginationActions';

import { selectUnreadChatCounts } from '../../state/chat/reducers';
import PerformanceScore from './PerformanceScore';
// import { selectAllMissionsStats } from '../../state/mission/reducers';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    padding: '18px 15px 8px 11px'
  },
  columnHeadingCell: {
    border: 'none',
    padding: 0,
    fontSize: 12,
    textTransform: 'uppercase',
    color: '#adb6c1',
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  headerCell: {
    padding: '0px 0px 10px 0px',
    border: 'none',
    '& svg': {
      opacity: 1
    }
  },
  label: {
    fontSize: 9,
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Heavy, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 900,
    '& svg': {
      fontSize: 16,
      margin: 0
    }
  },
  numericCol: {
    width: '80px !important',
    padding: '0px 0px 10px 0px'
  },
  bodyRow: {
    '&:not(:last-child)': {
      '& td': {
        paddingBottom: 5
      }
    }
  },
  noData: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 700,
    fontSize: 24
  },
  tableBody: {
    '& td': {
      padding: 0,
      border: 'none'
    }
  },
  borderLeft: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  borderRight: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  },
  contentWrap: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f7f7f7',
    height: 60
  },
  profileImage: {
    marginRight: 10
  },
  text: {
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  unreadCounter: {
    backgroundColor: theme.palette.error.main,
    minHeight: 24,
    minWidth: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    borderRadius: '50%',
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  numericBox: {
    minHeight: 30,
    minWidth: 48,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  overdue: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: theme.palette.common.white
  },
  inProgress: {
    backgroundColor: `${theme.palette.info.main} !important`,
    color: theme.palette.common.white
  },
  needHelp: {
    backgroundColor: `${theme.palette.warning.main} !important`,
    color: theme.palette.common.white
  },
  mr5: {
    marginRight: 5
  },
  pagination: {
    '& td': {
      border: 'none'
    }
  }
}));

const headerCells = [
  { id: 'name', numeric: false, label: 'Name' },
  { id: 'performance', numeric: true, label: 'Performance' },
  { id: 'unread', numeric: true, label: 'Unread' },
  { id: 'lastAction', numeric: true, label: 'Last Action' },
  { id: 'missionOverdue', numeric: true, label: 'Overdue' },
  { id: 'missionInProgress', numeric: true, label: 'In Prog.' },
  { id: 'objectiveInProgress', numeric: true, label: 'In Prog.' },
  { id: 'objectiveOverdue', numeric: true, label: 'Overdue' },
  { id: 'objectiveNeedHelp', numeric: true, label: 'Need Help' },
  { id: 'tasksCount', numeric: true, label: 'Actions' },
  { id: 'practicesCount', numeric: true, label: 'Practices' }
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

// Parse users to have sortable top level keys
// const parseUsers = (users, allMissionStats) => {
const parseUsers = (users) => {
  if (!users) return [];
  return users.map(u => ({
    ...u,
    name: `${u.firstName} ${u.lastName}`,
    unread: u.stats.objectives.unread,
    lastAction: u.lastAction,
    missionOverdue: u.stats.missions.overdue,
    missionInProgress: u.stats.missions.inProgress,
    objectiveOverdue: u.stats.objectives.overdue,
    objectiveInProgress: u.stats.objectives.inProgress,
    objectiveNeedHelp: u.stats.objectives.needHelp,
    tasksCount: u.stats.tasks.count,
    practicesCount: u.stats.practices.count,
    performance: u.stats.performanceScore ? u.stats.performanceScore: 0
    // performance: allMissionStats[u._id] ? allMissionStats[u._id].performanceScore || 0 : 0
  }));
};

const StatTable = ({ loading, data, onRowClick, rowsPerPage, noDataMessage }) => {
  // Hooks
  const classes = useStyles();

  // Local state
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('taskOverdue');
  // const allMissionStats = useSelector((state) => selectAllMissionsStats(state))
  const unreadChatCounts = useSelector(state => selectUnreadChatCounts(state));

  // Helpers
  const getNumericBoxClass = (uiClass, condition) =>
    clsx({
      [classes.numericBox]: true,
      [uiClass]: condition
    });

  // Event handlers
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (e, newPage) => {
    e.stopPropagation();
    setPage(newPage);
  };

  // Slice data for pagination
  const computedData = data ? parseUsers(data) : null;

  const loadingJsx = [...new Array(6)].map((e, idx) => <StatCardLoading key={`loading-${idx}`} />);

  // Run data through sorting algorithm
  const sortedData = stableSort(computedData, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const hasData = sortedData.length > 0;

  return (
    <>
      <Box className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnHeadingCell} colSpan={4} />
                <TableCell className={classes.columnHeadingCell} colSpan={2} align="center">
                  Missions
                </TableCell>
                <TableCell className={classes.columnHeadingCell} colSpan={3} align="center">
                  Objectives
                </TableCell>
                <TableCell className={classes.columnHeadingCell} colSpan={2} align="center" />
              </TableRow>
              <TableRow>
                {headerCells.map(c => (
                  <TableCell
                    key={c.id}
                    align={c.numeric ? 'center' : 'left'}
                    sortDirection={orderBy === c.id ? order : false}
                    className={clsx({
                      [classes.headerCell]: true,
                      [classes.numericCol]: c.numeric || !sortedData.length
                    })}
                  >
                    <TableSortLabel
                      className={classes.label}
                      active={orderBy === c.id}
                      direction={orderBy === c.id ? order : 'asc'}
                      onClick={createSortHandler(c.id)}
                      IconComponent={ArrowDropDownIcon}
                      classes={{
                        icon: classes.arrow
                      }}
                    >
                      {c.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {loading ? (
                loadingJsx
              ) : (
                <>
                  {!hasData && (
                    <TableRow>
                      <TableCell className={classes.noBorder} colSpan={10}>
                        <Box alignItems="center" justifyContent="center" display="flex" padding={2}>
                          <Typography align="center" className={classes.noData}>
                            {noDataMessage}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {hasData &&
                    sortedData.map(d =>
                      (
                        <TableRow key={d._id} className={classes.bodyRow} onClick={() => onRowClick(d)}>
                          <TableCell>
                            <div className={clsx(classes.borderLeft, classes.contentWrap)}>
                              <ProfileImage className={classes.profileImage} userId={d._id} />
                              <Typography className={classes.text} noWrap>
                                {d.name}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.borderRight, classes.contentWrap, classes.mr5)}>
                              <PerformanceScore data={d} />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.contentWrap}>
                              {unreadChatCounts[d._id] && unreadChatCounts[d._id] > 0 && (
                                <div className={classes.unreadCounter}>{unreadChatCounts[d._id]}</div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.borderRight, classes.contentWrap, classes.mr5)}>
                              <Typography className={classes.text}>
                                {d.lastAction && moment(d.lastAction).format('DD/MM/YYYY')}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderLeft)}>
                              <div className={getNumericBoxClass(classes.overdue, d.missionOverdue > 0)}>
                                {d.missionOverdue}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderRight, classes.mr5)}>
                              <div className={getNumericBoxClass(classes.inProgress, d.missionInProgress === 0)}>
                                {d.missionInProgress}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.contentWrap}>
                              <div className={getNumericBoxClass(classes.inProgress, d.objectiveInProgress === 0)}>
                                {d.objectiveInProgress}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderLeft)}>
                              <div className={getNumericBoxClass(classes.overdue, d.objectiveOverdue > 0)}>
                                {d.objectiveOverdue}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderRight, classes.mr5)}>
                              <div className={getNumericBoxClass(classes.needHelp, d.objectiveNeedHelp > 0)}>
                                {d.objectiveNeedHelp}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderLeft)}>
                              <div className={getNumericBoxClass(classes.overdue)}>{d.tasksCount}</div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={clsx(classes.contentWrap, classes.borderRight)}>
                              <div className={getNumericBoxClass(classes.inProgress)}>{d.practicesCount}</div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!loading && hasData && (
        <Table className={classes.pagination}>
          <TableBody>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                classes={{
                  root: classes.noBorder
                }}
                onChangePage={handleChangePage}
                ActionsComponent={PaginationActions}
              />
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

StatTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  rowsPerPage: PropTypes.number,
  onRowClick: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string
};

StatTable.defaultProps = {
  data: [],
  rowsPerPage: 10,
  noDataMessage: 'Nothing here yet... Check back soon!'
};

export default StatTable;
