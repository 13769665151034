import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import UserDropdown from '../UserDropdown/UserDropdown';
import LeaderCard from '../LeaderCard/LeaderCard';

import { selectCommunityLeaders } from '../../state/community/reducers';
import { selectSelectedUser } from '../../state/users/reducers';

const styles = makeStyles({
  labelRoot: {
    color: '#838b96',
    zIndex: 1,
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 7,
    pointerEvents: 'none',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  labelShrink: {
    paddingTop: 0
  },
  noLeaders: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600
  }
});

export default function AssignedLeaders({
  readOnly,
  assignedLeaders,
  selectedLeaders,
  onSelect,
  onRemove,
  menuPosition,
  isSingle
}) {
  const classes = styles();

  // Redux
  const communityLeaders = useSelector(state => selectCommunityLeaders(state));
  const user = useSelector(state => selectSelectedUser(state));
  const userId = user ? user._id : null;

  // Filter out the leaders already assigned and the current selected leaders
  const availableLeaders = communityLeaders
    .filter(leader => !assignedLeaders.some(a => a._id === leader.id))
    .filter(leader => !selectedLeaders.some(s => s.id === leader.id))
    .filter(leader => leader.id !== userId);

  const computedAssignedLeaders = [...assignedLeaders, ...selectedLeaders];
  const hasLeaders = computedAssignedLeaders.length > 0;

  const getButtonText = () => {
    if (isSingle) {
      return 'Assign a leader';
    }
    if (hasLeaders) {
      return 'Assign another leader';
    }
    return 'Assign a leader';
  };

  return (
    <>
      <InputLabel
        shrink
        classes={{
          root: classes.labelRoot,
          shrink: classes.labelShrink
        }}
      >
        Leaders available to member
      </InputLabel>
      {!readOnly && (
        <UserDropdown
          users={availableLeaders}
          buttonText={getButtonText()}
          placeholder="Search and/or select a leader..."
          noDataMessage="No leaders to choose from!"
          onSelect={value => {
            onSelect(value);
          }}
          menuPosition={menuPosition}
        />
      )}
      {computedAssignedLeaders.map(({ _id, firstName, lastName, isOfficer }) => (
        <LeaderCard
          key={_id}
          userId={_id}
          readOnly={readOnly}
          name={`${firstName} ${lastName}`}
          isOfficer={isOfficer}
          avatar="https://via.placeholder.com/40"
          onRemove={() => onRemove(_id)}
        />
      ))}
      {!hasLeaders && readOnly && <Typography className={classes.noLeaders}>This user has no leaders</Typography>}
    </>
  );
}

AssignedLeaders.propTypes = {
  readOnly: PropTypes.bool,
  isSingle: PropTypes.bool,
  assignedLeaders: PropTypes.array.isRequired,
  selectedLeaders: PropTypes.array,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  menuPosition: PropTypes.string
};

AssignedLeaders.defaultProps = {
  onRemove: null,
  onSelect: null,
  readOnly: false,
  selectedLeaders: [],
  menuPosition: 'bottom',
  isSingle: false
};
