import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';

import * as teamTypes from '../../state/teams/types';
import { selectTeamApiErrors, selectTeamSaving, selectSelectedTeam } from '../../state/teams/reducers';
import { selectSelectedCommunityId } from '../../state/community/reducers';

import usePrevious from '../../hooks/usePrevious';
import { validateCreateTeam, parseApiErrors, getErrorMessage } from '../../constants/validation';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 40
  },
  header: {
    marginBottom: 70
  },
  title: {
    fontSize: 12,
    color: '#838b96',
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  helper: {
    fontSize: 12,
    marginTop: theme.spacing(2)
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    width: 150
  },
  inputNoError: {
    marginBottom: 24
  },
  inputError: {
    marginBottom: 8
  }
}));

export default function CreateTeamView({ closeDrawer, isEdit }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState(null);

  // Redux
  const apiErrors = useSelector(state => selectTeamApiErrors(state));
  const saving = useSelector(state => selectTeamSaving(state));
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const selectedTeam = useSelector(state => selectSelectedTeam(state));

  // Previous saving state
  const prevSaving = usePrevious(saving);

  // Event handlers
  function handleSubmit() {
    const errorMessages = validateCreateTeam({ title, description });
    if (errorMessages === undefined) {
      setErrors(null);
      dispatch({
        type: isEdit ? teamTypes.UPDATE_TEAM : teamTypes.CREATE_TEAM,
        communityId,
        teamId: isEdit ? selectedTeam._id : null,
        payload: {
          title,
          description
        }
      });
    } else setErrors(errorMessages);
  }

  // Close drawer
  useEffect(() => {
    if (prevSaving && !saving && !apiErrors) {
      closeDrawer();
    }
  }, [prevSaving, saving, apiErrors, closeDrawer]);

  // Update state if in edit mode
  useEffect(() => {
    if (isEdit && selectedTeam !== null) {
      setTitle(selectedTeam.title);
      setDescription(selectedTeam.description);
    }
  }, [isEdit, selectedTeam]);

  // Helper constants
  const computedErrors = errors || parseApiErrors(apiErrors, teamTypes.CREATE_TEAM_ERROR);
  const titleError = getErrorMessage(computedErrors, 'title');
  const descriptionError = getErrorMessage(computedErrors, 'description');

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.header}>
        <Grid container>
          <Grid item xs={6} className={classes.flexStart}>
            <Typography className={classes.title}>{isEdit ? 'Edit' : 'Create'} team</Typography>
          </Grid>
          <Grid item xs={6} className={classes.flexEnd}>
            <Button
              data-test-id="button-team-create"
              className={classes.button}
              onClick={handleSubmit}
              label={isEdit ? 'Save' : 'Create'}
              loading={saving}
              disabled={saving}
            />
          </Grid>
          {!isEdit && (
            <Grid item xs={12}>
              <Typography className={classes.helper}>Organise your community members into teams</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Team name"
          name="title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          error={Boolean(titleError)}
          errorMessage={titleError}
          className={titleError ? classes.inputError : classes.inputNoError}
          maxLength={100}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Team description"
          value={description}
          name="description"
          onChange={e => setDescription(e.target.value)}
          multiline
          rows={6}
          maxLength={500}
          error={Boolean(descriptionError)}
          errorMessage={descriptionError}
        />
      </Grid>
    </Grid>
  );
}

CreateTeamView.propTypes = {
  isEdit: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired
};

CreateTeamView.defaultProps = {
  isEdit: false
};
