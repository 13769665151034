import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../components/PageHeader/PageHeader';
import MemberHeaderNavigation from '../components/Members/MemberHeaderNavigation';
import ObjectiveViewLoading from '../components/Objective/ObjectiveViewLoading';
import ObjectiveTasks from '../components/Objective/ObjectiveTasks';
import ObjectiveActions from '../components/Objective/ObjectiveActions';
import ObjectiveComments from '../components/Objective/ObjectiveComments';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';
import ObjectiveTaskDrawer from '../components/Drawers/ObjectiveTaskDrawer';
import ObjectivePracticeDrawer from '../components/ObjectivePracticeDrawer/ObjectivePracticeDrawer';
import ObjectiveChart from '../components/Objective/ObjectiveChart';
import ObjectivePractices from '../components/Objective/ObjectivePratices';
import SpecList from '../components/List/SpecList';

import * as missionTypes from '../state/mission/types';
import * as modalTypes from '../state/modal/types';

import { selectLoggedInUserId } from '../state/auth/reducers';
import {
  selectSelectedMission,
  selectSelectedObjective,
  selectObjectiveSaving,
  selectObjectivePracticeSaved,
  selectObjectivePracticeDeleted,
  selectObjectiveSystemComments
} from '../state/mission/reducers';
import useUpdateEffect from '../hooks/useUpdateEffect';

import { selectMemberById } from '../state/community/reducers';

const useStyles = makeStyles(theme => ({
  missionTitle: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily,
    color: '#969696',
    marginBottom: 0
  },
  objectiveTitle: {
    fontSize: 34,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily
  },
  objectiveOutcome: {}
}));

const ObjectiveView = () => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { communityId, userId, missionId, objectiveId } = useParams();

  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [practiceDrawerOpen, setPracticeDrawerOpen] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [objectiveChartReload, setObjectiveChartReload] = useState(0);
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false);
  const [currentEditTask, setCurrentEditTask] = useState();

  // Redux
  const authUser = useSelector(state => selectLoggedInUserId(state));
  const mission = useSelector(state => selectSelectedMission(state));
  const objective = useSelector(state => selectSelectedObjective(state));
  const objectiveSaving = useSelector(state => selectObjectiveSaving(state));
  const practiceSaved = useSelector(state => selectObjectivePracticeSaved(state));
  const practiceDeleted = useSelector(state => selectObjectivePracticeDeleted(state));
  const member = useSelector(state => selectMemberById(state, mission?.user?._id));
  const updatingTasks = useSelector(state => state.mission.updatingTasks);
  const isCreatingTask = useSelector(state => state.mission.isCreatingTask);
  const activity = useSelector(state => selectObjectiveSystemComments(state, objectiveId));

  const readOnly = !mission || authUser !== mission.leader?._id;

  // Remove empty comments
  const cleanComments = objective?.comments.filter(c => Boolean(c.comment)) || [];

  // Objective comments and system comments living in harmony
  const computedComments = cleanComments.sort((a, b) => moment(a.createdOn).diff(b.createdOn));

  // Helpers
  const setModalComponent = (componentType, props) =>
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: componentType,
      props
    });

  const openModal = () =>
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const openPracticeDrawer = () => setPracticeDrawerOpen(true);
  const closePracticeDrawer = () => {
    setPracticeDrawerOpen(false);
    setSelectedPractice(null);
  };

  const dispatchObjectiveUpdates = payload =>
    dispatch({
      type: missionTypes.UPDATE_OBJECTIVE,
      userId,
      objectiveId,
      updates: payload
    });

  // Event handlers
  const handleStatusChange = status => {
    dispatch({
      type: missionTypes.UPDATE_OBJECTIVE_STATUS,
      userId,
      objectiveId,
      status
    });
  };

  const handleObjectiveUpdate = updates => {
    // Updates comes in as full objective - need to manipulate data for API
    // Remove unaccepted keys and ensure currentMetric is a number
    const { status, comments, ...rest } = updates;
    const payload = {
      ...rest,
      currentMetric: updates.currentMetric || 0
    };

    dispatchObjectiveUpdates(payload);
  };

  const handlePracticeEdit = practice => {
    setSelectedPractice(practice);
    openPracticeDrawer();
  };

  const handlePracticeDelete = practice => {
    dispatch({
      type: missionTypes.DELETE_OBJECTIVE_PRACTICE,
      userId,
      practiceId: practice.id
    });
  };

  const onConfirmArchive = () => {
    dispatch({
      type: missionTypes.ARCHIVE_OBJECTIVE,
      communityId,
      userId,
      missionId,
      objectiveId
    });
  };

  const onClickArchive = () => {
    // Set the modal component
    setModalComponent(modalTypes.CONFIRMATION_MODAL, {
      title: 'Are you sure you want to archive this objective?',
      message: 'This will also archive all associated tasks. This cannot be undone.',
      onSubmit: onConfirmArchive
    });

    openModal();
  };

  // Get mission on mount
  useEffect(() => {
    dispatch({
      type: missionTypes.GET_MISSION,
      userId,
      missionId
    });
  }, [missionId]);

  // Get objective on mount
  useEffect(() => {
    dispatch({
      type: missionTypes.GET_OBJECTIVE,
      userId,
      missionId,
      objectiveId
    });

    dispatch({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS,
      userId,
      objectiveId
    });
  }, [userId, missionId, objectiveId]);

  useUpdateEffect(() => {
    if (practiceSaved || practiceDeleted) {
      setObjectiveChartReload(objectiveChartReload + 1);
    }
  }, [practiceSaved, practiceDeleted]);

  // Dont render the view until there is something to see
  if (!objective || !mission || mission?.id !== missionId || objective?.id !== objectiveId) {
    return <ObjectiveViewLoading />;
  }

  const title = objective.title ?
    `${objective.title.charAt(0).toUpperCase()}${objective.title.slice(1)}`
    :
    `${objective.statement.verb.charAt(0).toUpperCase()}${objective.statement.verb.slice(1)} ${
      objective.statement.noun
    }`;

  // get simple references to entities for reference in the drawers
  const entityReferences = objective.tasks.reduce((acc, entity) => {
    acc[entity.entity] = {
      title: entity.title
    };
    return acc;
  }, {});

  return (
    <>
      <PageHeader routeTitle="Objective" showBack />

      <MemberHeaderNavigation member={member} />

      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
          <Typography className={classes.missionTitle}>Mission: {mission.title}</Typography>
          <Typography className={classes.objectiveTitle}>Objective: {title}</Typography>
          <Typography style={{ marginBottom: 10 }} className={classes.objectiveOutcome}>
            In order to {objective.statement.outcome}
          </Typography>
          <SpecList
            size="small"
            data={[
              {
                label: objective.startDate ? 'Started' : 'Created',
                value: objective.startDate
                  ? moment(objective.startDate).format('LL')
                  : moment(objective.createdAt).format('LL')
              },
              {
                label: 'Due',
                value: moment(objective.statement.dueDate).format('LL'),
                style: {
                  color: new Date(objective.statement.dueDate) < new Date() ? 'red' : 'inherit'
                }
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
          <ObjectiveActions
            readOnly={readOnly}
            saving={objectiveSaving}
            entities={entityReferences}
            activity={activity}
            objectiveStatus={objective.status}
            onStatusChange={handleStatusChange}
            onEdit={openDrawer}
          />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      {objective.tasks && (
        <ObjectiveChart objectiveId={objective.id} tasks={objective.tasks} reload={objectiveChartReload} />
      )}

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} lg={4}>
          <ObjectiveTasks
            readOnly={readOnly}
            tasks={objective.tasks.map(o => ({
              ...o,
              isUpdating: updatingTasks.includes(o._id)
            }))}
            onAddTask={() => setTaskDrawerOpen(true)}
            isCreating={isCreatingTask}
            onEditTask={task => {
              setCurrentEditTask(task);
              setTaskDrawerOpen(true);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ObjectivePractices
            readOnly={readOnly}
            practices={objective.practices}
            onAddPractice={openPracticeDrawer}
            onEditPractice={handlePracticeEdit}
            onDeletePractice={handlePracticeDelete}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ObjectiveComments comments={computedComments} />
        </Grid>
      </Grid>

      {!readOnly && (
        <>
          <ObjectiveDrawer
            isActive
            open={drawerOpen}
            onClose={closeDrawer}
            selectedObjective={objective}
            onDelete={onClickArchive}
            onUpdate={handleObjectiveUpdate}
            readOnly={readOnly}
          />

          <ObjectivePracticeDrawer
            userId={userId}
            open={practiceDrawerOpen}
            onClose={closePracticeDrawer}
            selectedObjective={objective}
            selectedPractice={selectedPractice}
          />

          <ObjectiveTaskDrawer
            task={currentEditTask}
            statement={objective.statement}
            width={500}
            open={taskDrawerOpen}
            onSave={task => {
              dispatch({
                type: missionTypes.CREATE_TASK,
                userId,
                missionId,
                objectiveId,
                task
              });

              if (objective.status === 'complete') {
                objective.status = 'in progress'
              }

              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
            onUpdate={task => {
              dispatch({
                type: missionTypes.UPDATE_TASK,
                userId,
                missionId,
                objectiveId,
                task
              });
              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
            onClose={() => {
              setCurrentEditTask();
              setTaskDrawerOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default ObjectiveView;
