import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ProfileImage from '../ProfileImage/ProfileImage';
import { ReactComponent as TrashIcon } from '../../static/images/icons/icon-trash.svg';

const styles = makeStyles({
  leaderCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    padding: 10,
    marginTop: 10
  },
  leaderDetails: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  leaderName: {
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 500
  },
  cursor: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dadada'
    }
  }
});

export default function LeaderCard({ readOnly, userId, name, onRemove, onClick, isOfficer }) {
  const classes = styles();
  return (
    <div
      data-test-id="leader-card"
      className={clsx({
        [classes.leaderCard]: true,
        [classes.cursor]: onClick !== null
      })}
      onClick={onClick}
      role="presentation"
    >
      <div className={classes.leaderDetails}>
        <ProfileImage userId={userId} />
        <Typography className={classes.leaderName}>{name}</Typography>
      </div>
      {!readOnly && (
        <IconButton onClick={onRemove} data-test-id="button-leader-remove">
          <TrashIcon />
        </IconButton>
      )}
    </div>
  );
}

LeaderCard.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  isOfficer: PropTypes.bool
};

LeaderCard.defaultProps = {
  onRemove: null,
  userId: '',
  onClick: null,
  isOfficer: false
};
