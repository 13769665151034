import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import PageHeader from '../PageHeader/PageHeader';

import { glowKeyframe } from '../../constants/mixins';

const useStyles = makeStyles({
  ...glowKeyframe,
  loadingBox: {
    transition: 'all 0.1s ease',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    animation: '$glow 1.5s ease-in-out infinite',
    width: '100%'
  },
  textLoading: {
    transition: 'all 0.1s ease',
    backgroundColor: '#f7f7f7',
    animation: '$glow 1.5s ease-in-out infinite'
  }
});

const ObjectiveViewLoading = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader routeTitle="Objective" showBack />
      <Grid container component="main" spacing={2}>
        <Grid item xs={7}>
          <Box className={classes.loadingBox} height={40} marginBottom={2.5} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.textLoading} height={21} width={100} marginBottom={1.25} />
              <Box className={classes.loadingBox} height={60} marginBottom={5} />
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.textLoading} height={21} width={100} marginBottom={1.25} />
              <Box className={classes.loadingBox} height={60} marginBottom={5} />
            </Grid>
          </Grid>
          <Box className={classes.textLoading} height={21} width={140} marginBottom={0.75} />
          <Box className={classes.loadingBox} height={115} marginBottom={6.75} />
          <Box className={classes.textLoading} height={21} width={120} marginBottom={0.75} />
          <Box className={classes.loadingBox} height={430} marginBottom={6.75} />
        </Grid>
        <Grid item xs={5}>
          <Box marginBottom={2.5} display="flex" justifyContent="flex-end">
            <Box className={classes.loadingBox} height={40} marginRight={1.25} style={{ width: 140 }} />
            <Box className={classes.loadingBox} height={40} style={{ width: 100 }} />
          </Box>
          <Box className={classes.loadingBox} height={815} />
        </Grid>
      </Grid>
    </>
  );
};

export default ObjectiveViewLoading;
