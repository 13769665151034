import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';

import { selectMissionSaving } from '../../state/mission/reducers';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#223f63',
    marginBottom: 20
  },
  copy: {
    maxWidth: 325,
    fontSize: 14,
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif',
    textAlign: 'center',
    marginBottom: 40
  },
  commentWrap: {
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
    height: 230,
    padding: 19,
    marginBottom: 20,
    width: 410
  }
});

const MissionReflectionModal = ({ onSubmit, isObjective }) => {
  // Hooks
  const classes = useStyles();

  // Local state
  const [reflection, setReflection] = useState('');

  // Redux
  const saving = useSelector(state => selectMissionSaving(state));

  // Event handlers
  const handleChange = e => {
    const { value } = e.target;
    setReflection(value);
  };

  // Constants
  const hasReflection = reflection.trim().length > 0;

  return (
    <Box className={classes.wrap}>
      <Typography className={classes.title}>
        {isObjective ? 'Objective Complete' : 'Mission Complete'}
      </Typography>
      <Typography className={classes.copy}>
        You’ve completed this {isObjective ? 'Objective' : 'Mission'} on behalf of
        your member. Write a little something to reflect on this.
      </Typography>
      <Box className={classes.commentWrap}>
        <TextInput
          label="Comment"
          required
          multiline
          rows={10}
          value={reflection}
          whiteInput
          onChange={handleChange}
          placeholder="Write your reflection here…"
        />
      </Box>
      <Button
        label="Post Reflection"
        onClick={() => onSubmit(reflection)}
        disabled={!hasReflection || saving}
        loading={saving}
      />
    </Box>
  );
};

MissionReflectionModal.propTypes = {
  onSubmit: PropTypes.func,
  isObjective: PropTypes.bool
};

MissionReflectionModal.defaultProps = {
  onSubmit: null,
  isObjective: false
};

export default MissionReflectionModal;
