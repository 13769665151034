import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';

import ProfileImage from '../ProfileImage/ProfileImage';

import * as chatTypes from '../../state/chat/types';
import { selectLoggedInUserId } from '../../state/auth/reducers';
import { selectChatMessages } from '../../state/chat/reducers';
import ChatMessage from './ChatMessage';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    maxWidth: 350,
    width: '100%',
    margin: '0px 10px',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)'
  },
  header: {
    height: 40,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    background: '#ffffff',
    boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.2)',
    padding: '7px 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  userName: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    flex: 1
  },
  content: {
    height: 220,
    overflow: 'auto',
    background: '#f7f7f7',
    padding: 10
  },
  inputBox: {
    height: 40,
    background: '#ffffff',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: 10
  },
  inputRoot: {
    width: '100%'
  },
  input: {
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    color: '#223f63',
    paddingLeft: 10,
    paddingRight: 10
  },
  unreadMessages: {
    backgroundColor: '#f20046',
    height: 5,
    width: 5,
    borderRadius: '50%',
    marginRight: 5
  }
});

export default function ChatWindow({ user }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local hook state
  const [open, setOpen] = useState(true);
  const [msg, setMsg] = useState('');
  const [read, setRead] = useState(0);

  // Redux
  const loggedInUser = useSelector(state => selectLoggedInUserId(state));
  const chatMessages = useSelector(state => selectChatMessages(state));
  const loggedInUserDetials = useSelector(state => state.auth.loggedInUser);
  const { firstName: myFirstName, lastName: myLastName } = loggedInUserDetials;
  const fromName = myFirstName || myLastName ? `${myFirstName} ${myLastName}` : null;

  // Ref to scroll to
  const bottomRef = useRef(null);

  // Animation hook
  const [animate, setAnimate] = useSpring(() => ({ top: -260, height: 300 }));

  // Update animation based on open state
  useEffect(() => {
    if (open) {
      setAnimate({ top: -260, height: 300 });
    } else setAnimate({ top: 0, height: 40 });
  }, [open]);

  // Event handlers
  function scrollIntoView() {
    bottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  function onClick() {
    setOpen(!open);
  }

  function handleChange(e) {
    const { value } = e.target;
    setMsg(value);
  }

  function handleSubmit(e) {
    if (e.key === 'Enter' && msg.trim().length > 0) {
      dispatch({
        type: chatTypes.SEND_MESSAGE,
        userId: loggedInUser,
        payload: {
          fromID: loggedInUser,
          message: msg.trim(),
          toID: user._id,
          timestamp: new Date(),
          fromName
        }
      });
      setMsg('');
    }
  }

  function closeChat(e) {
    e.stopPropagation();
    dispatch({
      type: chatTypes.CLOSE_CHAT,
      userId: user._id
    });
  }

  // Helper constants
  const { firstName, lastName, _id: userId } = user;
  const userName = `${firstName} ${lastName}`;

  // Filter out the specific users messages
  const messagesToDisplay = chatMessages.filter(m => m.toID === userId || m.fromID === userId);

  // Sort by timestamp
  const sortedMessages = messagesToDisplay.sort(
    (a, b) => a.timestamp.toDate().getTime() - b.timestamp.toDate().getTime()
  );

  // Scroll to bottom when messages change
  useEffect(() => {
    if (open) {
      setTimeout(() => scrollIntoView(), 500);
    }
  }, [messagesToDisplay, open]);

  // Update 'read' count on load
  useEffect(() => {
    if (sortedMessages.length > read && open) {
      setRead(sortedMessages.length);
    }
  }, [sortedMessages, open]);

  const showUnread = !open && read !== sortedMessages.length;

  return (
    <animated.div className={classes.root} style={animate}>
      <div className={classes.header} onClick={onClick} role="presentation">
        <ProfileImage shadow={false} userId={userId} borderRadius={8} size={25} style={{ marginRight: 10 }} />
        {showUnread && <div className={classes.unreadMessages} />}
        <Typography noWrap className={classes.userName}>
          {userName}
        </Typography>
        <IconButton onClick={closeChat} size="small">
          <Close fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.content}>
        {sortedMessages.map(m => (
          <ChatMessage
            key={m.timestamp.seconds}
            fromId={m.fromID}
            message={m.message}
            timestamp={m.timestamp}
            recipient={userName}
          />
        ))}
        <div ref={bottomRef} />
      </div>
      <div className={classes.inputBox}>
        <Input
          value={msg}
          onChange={handleChange}
          placeholder="Type a message..."
          disableUnderline
          onKeyDown={handleSubmit}
          classes={{ root: classes.inputRoot, input: classes.input }}
        />
      </div>
    </animated.div>
  );
}

ChatWindow.propTypes = {
  user: PropTypes.object.isRequired
};
