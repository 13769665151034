import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {omit, pick} from 'lodash';
import ToolkitForm from '../ToolkitForm/ToolkitForm';
import Button from '../Button/Button';
import {validateCreateToolkit} from '../../constants/validation';
import * as toolkitTypes from '../../state/toolkits/types';
import {selectSelectedToolkit, selectToolkitsLoadingState} from '../../state/toolkits/reducers';
import AssignUserDrawer from '../AssignUserDrawer/AssignUserDrawer';
import * as modalTypes from '../../state/modal/types';
import {contentParser} from '../../utils/toolkitHelpers';
import EditToolkitActions from './EditToolkitActions';
import Spinner from '../Spinner/Spinner';

const styles = makeStyles({
  container: {
    marginBottom: 10
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
});

const EditToolkit = () => {
  const dispatch = useDispatch();
  const { communityId, toolkitId } = useParams();
  const selectedToolkit = useSelector(state => selectSelectedToolkit(state));
  const toolkitLoadingState = useSelector(state => selectToolkitsLoadingState(state));
  const classes = styles();
  const [values, setValues] = useState({
    content: [],
    heroImage: undefined,
    heroVideo: '',
    title: '',
    description: '',
    categories: []
  });
  const [errors, setErrors] = useState(null);
  const [showAssignUserDrawer, setShowAssignUserDrawer] = useState(false);

  useEffect((
  ) => {
    dispatch({type: toolkitTypes.GET_TOOLKIT, toolkitId})
  }, [toolkitId])

  useEffect((
  ) => {
    if (selectedToolkit) {
      const pickedValues = pick(selectedToolkit, [
        'content',
        'thumbnail',
        'video',
        'title',
        'description',
        'categories',
      ])

      setValues(omit({
        ...pickedValues,
        content: contentParser(pickedValues.content) || pickedValues.content,
        heroImage: pickedValues?.thumbnail,
        heroVideo: pickedValues?.video,
      },
      ['video', 'thumbnail'])
      )
    }
  }, [selectedToolkit])

  const handleSubmit = async () => {
    const errorMessages = validateCreateToolkit(values);

    if (errorMessages === undefined) {
      setErrors(null);

      const formData = new FormData();
      formData.append('categories', JSON.stringify(values.categories))
      formData.append('description', values.description)
      formData.append('heroImage', values.heroImage)
      formData.append('heroVideo', values.heroVideo)
      formData.append('title', values.title)

      if (values?.content) {
        formData.append('content', JSON.stringify(values.content))
      }

      dispatch({
        type: toolkitTypes.EDIT_TOOLKIT,
        communityId,
        toolkitId: selectedToolkit.id,
        payload: formData
      })
    } else {
      setErrors(errorMessages);
    }
  }

  if (toolkitLoadingState) {
    return <Spinner />;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{display: 'flex', justifyContent: 'end'}}>
        <EditToolkitActions toolkit={selectedToolkit} />
        <Button
          label="Preview tool"
          background="grey"
          onClick={() => {
            dispatch({
              type: modalTypes.MODAL_SET_COMPONENT,
              component: modalTypes.PREVIEW_TOOLKIT_MODAL,
              props: {
                toolkit: selectedToolkit
              }
            });

            dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
          }}
          style={{borderRadius:50, marginRight: 4}}
        />
        <Button
          label="Assign tool"
          background="grey"
          onClick={() => {
            setShowAssignUserDrawer(true)
          }}
          style={{borderRadius:50, marginRight: 4}}
        />
        <Button
          iconPosition="start"
          icon={<AddIcon />}
          label="Save tool"
          onClick={handleSubmit}
          style={{borderRadius:50}}
        />
      </Grid>
      <Grid item xs={12}>
        <ToolkitForm values={values} setValues={setValues} errors={errors} setErrors={setErrors} />
      </Grid>
      <AssignUserDrawer
        selectedToolkits={[selectedToolkit?.id]}
        open={showAssignUserDrawer}
        handleClose={() => setShowAssignUserDrawer(false)}
        onSubmit={() => {
          setShowAssignUserDrawer(false)
        }}
      />
    </Grid>
  )
}

export default EditToolkit