import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as userActions from './actions';
import * as userTypes from './types';

import { consolidateArrayState } from '../../utils/stateHelpers';

import { selectSelectedUser, selectUserEmailChange, selectSelectedUserId, selectUserActivities } from './reducers';
import { selectSelectedCommunityId, selectCommunityUsers } from '../community/reducers';

import * as snackTypes from '../snack/types';
import * as modalTypes from '../modal/types';
import * as communityTypes from '../community/types';

import { checkAndGetPermissions, getUserCommunityLeaders, toBase64 } from '../../utils/helpers';

function* getUser({ userId }) {
  try {
    yield put({ type: userTypes.USER_LOADING_STATE, state: true });

    const result = yield call(userActions.getUser, userId);
    const { data } = result;

    // Extract leaders to separate in state
    const communityId = yield select(selectSelectedCommunityId);
    const leaders = getUserCommunityLeaders(data, communityId);

    yield put({ type: userTypes.GET_USER_SUCCESS, user: data, leaders });
    yield put({ type: userTypes.USER_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: userTypes.USER_LOADING_STATE, state: false });
  }
}

function* updateUser({ userId, updates }) {
  try {
    yield put({ type: userTypes.USER_SAVING_STATE, state: true });

    // Check if permissions changed before making API calls
    // This could probably be made into its own function
    const communityId = yield select(selectSelectedCommunityId);
    const selectedUser = yield select(selectSelectedUser);
    const { memberships, email: prevEmail } = selectedUser;
    const { isLeader: prevIsLeader, isComAdmin: prevIsComAdmin } = checkAndGetPermissions(memberships, communityId);

    // Check the keys exist in the payload (they wont from the profile screen)
    const updateKeys = Object.keys(updates);

    // If the keys exist - call appropriate endpoint
    // Probably need to wrap around separate try catch
    if (updateKeys.includes('isLeader') && updateKeys.includes('isComAdmin')) {
      const { isLeader, isComAdmin } = updates;

      // If previous leader state doesn't match updated state - send the call
      if (prevIsLeader !== isLeader) {
        let leaderResult = null;
        if (isLeader) {
          leaderResult = yield call(userActions.addCommunityLeader, communityId, selectedUser._id);
        } else {
          leaderResult = yield call(userActions.removeCommunityLeader, communityId, selectedUser._id);
        }

        const {
          data: { leaders }
        } = leaderResult;
        yield put({
          type: communityTypes.UPDATE_COMMUNITY_LEADERS_SUCCESS,
          leaders
        });
      }

      // If previous admin state doesn't match updated state - send the call
      if (prevIsComAdmin !== isComAdmin) {
        if (isComAdmin) yield call(userActions.addCommunityAdmin, communityId, selectedUser._id);
        else yield call(userActions.removeCommunityAdmin, communityId, selectedUser._id);
      }

      // re-fetch the community users to ensure they are in sync with the changes
      yield put({
        type: communityTypes.GET_COMMUNITY_USERS,
        communityId,
        showProgress: false
      });
    }

    // Check if the email has changed
    // Probably need to wrap around separate try catch
    if (updateKeys.includes('email')) {
      const { email } = updates;
      if (prevEmail !== email) {
        // Check if email request already exists and if email the same
        const emailChange = yield select(selectUserEmailChange);
        if (emailChange) {
          if (email !== emailChange.email) yield put({ type: userTypes.REQUEST_EMAIL_CHANGE, userId, email });
        } else yield put({ type: userTypes.REQUEST_EMAIL_CHANGE, userId, email });
      }
    }

    // Update profile image if present (could use some optimization)
    if (updateKeys.includes('imageFile') && (updates.imageFile !== null || updates.imageFile !== undefined)) {
      const { imageFile } = updates;
      const formData = new FormData();
      formData.append('photo', imageFile);
      yield call(userActions.uploadProfileImage, userId, communityId, formData);

      const image = yield call(toBase64, imageFile);

      yield put({ type: userTypes.STORE_PROFILE_IMAGE, userId, image });
    }

    // TODO: Update leaders
    if (updateKeys.includes('leaders')) {
      const { leaders } = updates;
      yield call(userActions.addLeaderToUser, communityId, userId, leaders);
    }

    // Update the rest of the user
    const result = yield call(userActions.updateUser, communityId, userId, updates);
    const { data } = result;

    // Extract leaders to separate in state
    const leaders = getUserCommunityLeaders(data, communityId);

    yield put({ type: userTypes.UPDATE_USER_SUCCESS, user: data, leaders });
    yield put({ type: userTypes.USER_SAVING_STATE, state: false });

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'User successfully updated',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: userTypes.UPDATE_USER_ERROR,
      errors: {
        key: userTypes.UPDATE_USER_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
  }
}

function* createUser({ payload }) {
  try {
    yield put({ type: userTypes.USER_SAVING_STATE, state: true });
    const communityId = yield select(selectSelectedCommunityId);
    const communityUsers = yield select(selectCommunityUsers);

    // Check if user already exists in the community
    const alreadyExists = communityUsers.some(user => user.email === payload.email);

    if (alreadyExists) {
      yield put({
        type: snackTypes.SET_SNACK,
        content: 'User with that email exists in the community',
        open: true,
        props: { variant: 'error' }
      });
    } else {
      const { isLeader, isComAdmin, ...rest } = payload;
      const permissions = [];
      if (isLeader) permissions.push('leader');
      if (isComAdmin) permissions.push('admin');
      const parsedPayload = {
        ...rest,
        permissions
      };

      const result = yield call(userActions.createUser, communityId, parsedPayload);
      const {
        data: { user, community }
      } = result;

      yield put({ type: userTypes.CREATE_USER_SUCCESS, user, community, isLeader, isComAdmin });
      // Display success snack
      yield put({
        type: snackTypes.SET_SNACK,
        content: 'User successfully created/invited',
        open: true,
        props: { variant: 'success' }
      });
    }

    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: userTypes.CREATE_USER_ERROR,
      errors: {
        key: userTypes.CREATE_USER_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
  }
}

function* resendInvitation({ invitationId }) {
  try {
    const communityId = yield select(selectSelectedCommunityId);
    yield call(userActions.resendInvitation, communityId, invitationId);
    yield put({ type: userTypes.RESEND_INVITATION_SUCCESS });
    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Invitation resent',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: userTypes.RESEND_INVITATION_ERROR,
      errors: {
        key: userTypes.RESEND_INVITATION_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  }
}

function* addLeaderToUser({ userId, leaders }) {
  try {
    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const result = yield call(userActions.addLeaderToUser, communityId, userId, leaders);
    const { data } = result;

    yield put({
      type: userTypes.ADD_LEADER_TO_USER_SUCCESS,
      user: data,
      leaders: getUserCommunityLeaders(data, communityId)
    });

    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: false });
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: userTypes.ADD_LEADER_TO_USER_ERROR,
      errors: {
        key: userTypes.ADD_LEADER_TO_USER_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  }
}

function* removeLeaderFromUser({ userId, leaderId }) {
  try {
    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const result = yield call(userActions.removeLeaderFromUser, communityId, userId, leaderId);
    const { data } = result;

    yield put({
      type: userTypes.REMOVE_LEADER_FROM_USER_SUCCESS,
      user: data,
      leaders: getUserCommunityLeaders(data, communityId)
    });
    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: false });
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Leader removed from user',
      open: true,
      props: { variant: 'success' }
    });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      message: error.response.data.message
    });

    yield put({
      type: userTypes.REMOVE_LEADER_FROM_USER_ERROR,
      errors: {
        key: userTypes.REMOVE_LEADER_FROM_USER_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });

    yield put({ type: userTypes.USER_LEADER_SAVING_STATE, state: false });
  }
}

function* requestEmailChange({ userId, email }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);

    // Send the request
    yield call(userActions.requestEmailChange, communityId, userId, email);

    // Upon successful request - get the request and store in redux
    const result = yield call(userActions.getEmailChangeRequest, communityId, userId);
    const { data } = result;

    yield put({ type: userTypes.REQUEST_EMAIL_CHANGE_SUCCESS, emailChange: data });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Update email request sent',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  }
}

function* getUserEmailChange({ userId }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const result = yield call(userActions.getEmailChangeRequest, communityId, userId);
    const { data } = result;

    yield put({ type: userTypes.GET_EMAIL_CHANGE_SUCCESS, emailChange: data });

    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: userTypes.EMAIL_CHANGE_LOADING_STATE, state: false });
  }
}

function* cancelEmailChange({ userId, requestId }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    yield call(userActions.cancelEmailChange, communityId, userId, requestId);
    yield put({ type: userTypes.CANCEL_EMAIL_CHANGE_SUCCESS });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Request successfully canceled',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  }
}

function* resendEmailChange({ requestId }) {
  try {
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const userId = yield select(selectSelectedUserId);
    yield call(userActions.resendEmailChange, communityId, userId, requestId);
    yield put({ type: userTypes.RESEND_EMAIL_CHANGE_SUCCESS });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Request successfully resent',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: userTypes.EMAIL_CHANGE_SAVING_STATE, state: false });
  }
}

function* getUserActivity({ communityId, userId }) {
  try {
    // Check if the user/community activity data exists
    const currentActivities = yield select(selectUserActivities);
    const dataExists = currentActivities.find(a => a.userId === userId && a.communityId === communityId);

    // Show loading animation if it doesn't exist
    if (!dataExists) {
      yield put({ type: userTypes.USER_ACTIVITY_LOADING_STATE, state: true });
    }

    const result = yield call(userActions.getUserActivity, communityId, userId);
    const {
      data: { results }
    } = result;

    // Only add their data if there are activities to add
    if (results.length > 0) {
      const userActivity = { userId, communityId, activity: results };
      const updatedUserActivities = consolidateArrayState(userActivity, currentActivities);

      yield put({
        type: userTypes.GET_USER_ACTIVITY_SUCCESS,
        userActivities: updatedUserActivities
      });
    }

    yield put({ type: userTypes.USER_ACTIVITY_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: userTypes.USER_ACTIVITY_LOADING_STATE, state: false });

    yield put({
      type: modalTypes.ERROR_MODAL,
      message: error.response.data.message
    });
  }
}

function* removeUserFromCommunity({ communityId, userId }) {
  try {
    yield put({ type: userTypes.USER_SAVING_STATE, state: true });
    yield call(userActions.removeUserFromCommunity, communityId, userId);

    const communityUsers = yield select(selectCommunityUsers);
    const updatedCommunityUsers = communityUsers.filter(user => user._id !== userId);
    yield put({
      type: userTypes.REMOVE_USER_FROM_COMMUNITY_SUCCESS,
      users: updatedCommunityUsers
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'User has been removed from the community',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: userTypes.USER_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getUserLifeEvents({ userId }) {
  try {
    yield put({ type: userTypes.LIFE_EVENTS_LOADING_STATE, state: true });

    const {
      data: { results }
    } = yield call(userActions.getUserLifeEvents, userId);
    yield put({ type: userTypes.GET_USER_LIFE_EVENTS_SUCCESS, lifeEvents: results });

    yield put({ type: userTypes.LIFE_EVENTS_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: userTypes.LIFE_EVENTS_LOADING_STATE, state: false });
  }
}

function* getUserAccountabilityScores({ communityId, userId, startDate, endDate }) {
  try {
    yield put({
      type: userTypes.USER_ACCOUNTABILITY_SCORE_LOADING_STATE,
      state: true
    });

    const {
      data: { results }
    } = yield call(userActions.getUserAccountabilityScore, communityId, userId, startDate, endDate);

    yield put({
      type: userTypes.GET_USER_ACCOUNTABILITY_SCORE_SUCCESS,
      accountabilityScores: results
    });

    yield put({
      type: userTypes.USER_ACCOUNTABILITY_SCORE_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: userTypes.USER_ACCOUNTABILITY_SCORE_LOADING_STATE,
      state: false
    });
  }
}

function* getUserCurrentAccountabilityScore({ communityId, userId }) {
  try {
    const {
      data: { score, actions, practices }
    } = yield call(userActions.getUserCurrentAccountabilityScore, communityId, userId);
    yield put({
      type: userTypes.GET_USER_CURRENT_ACCOUNTABILITY_SCORE_SUCCESS,
      score,
      actions,
      practices
    });
  } catch (error) { }
}

export default [
  takeLatest(userTypes.GET_USER, getUser),
  takeLatest(userTypes.UPDATE_USER, updateUser),
  takeLatest(userTypes.CREATE_USER, createUser),
  takeLatest(userTypes.RESEND_INVITATION, resendInvitation),
  takeLatest(userTypes.ADD_LEADER_TO_USER, addLeaderToUser),
  takeLatest(userTypes.REMOVE_LEADER_FROM_USER, removeLeaderFromUser),
  takeLatest(userTypes.REQUEST_EMAIL_CHANGE, requestEmailChange),
  takeLatest(userTypes.GET_EMAIL_CHANGE, getUserEmailChange),
  takeLatest(userTypes.CANCEL_EMAIL_CHANGE, cancelEmailChange),
  takeLatest(userTypes.RESEND_EMAIL_CHANGE, resendEmailChange),
  takeLatest(userTypes.GET_USER_ACTIVITY, getUserActivity),
  takeLatest(userTypes.REMOVE_USER_FROM_COMMUNITY, removeUserFromCommunity),
  takeLatest(userTypes.GET_USER_LIFE_EVENTS, getUserLifeEvents),
  takeLatest(userTypes.GET_USER_ACCOUNTABILITY_SCORE, getUserAccountabilityScores),
  takeLatest(userTypes.GET_USER_CURRENT_ACCOUNTABILITY_SCORE, getUserCurrentAccountabilityScore)
];
