import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import ProfileImage from '../ProfileImage/ProfileImage';

import { ReactComponent as Chevron } from '../../static/images/icons/icon-chevron.svg';

import { selectAllCommunities } from '../../state/community/reducers';

const styles = makeStyles({
  container: {
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 40,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  dateWrap: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 20,
    marginRight: 20,
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: 10,
      color: '#838b96'
    }
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 173,
    marginRight: 20
  },
  userName: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10
  },
  message: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    fontSize: 10,
    color: '#223f63'
  },
  communityContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    backgroundColor: '#adb6c1',
    width: 150,
    fontSize: 10,
    color: '#ffffff'
  }
});

export default function OfficerActivityRowCard({ createdOn, user, formattedString, kind, onClick, community, entity }) {
  // Hooks
  const classes = styles();
  const history = useHistory();

  // Redux
  const communities = useSelector(state => selectAllCommunities(state));

  // Event handlers
  function handleClick() {
    if (!entity) return;
    // TODO - add other activity click events
    switch (kind) {
      case 'Mission':
        if (community) history.push(`/c/${community}/u/${user._id}/m/${entity._id}`);
        else onClick();
        break;

      case 'Task':
        if (community) history.push(`/c/${community}/u/${user._id}/m/${entity.mission}/o/${entity.objective}`);
        else onClick();
        break;

      case 'Objective':
        if (community) history.push(`/c/${community}/u/${user._id}/m/${entity.mission}/o/${entity._id}`);
        else onClick();
        break;

      case 'LifeEvent':
        onClick();
        break;

      default:
        break;
    }
  }

  // Helper constants

  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const activityCommunity = communities.find(c => c._id === community);

  return (
    <div className={classes.container} onClick={handleClick} role="presentation">
      <div className={classes.dateWrap}>
        <div>
          <Typography>{moment(createdOn).format('hh:mma')}</Typography>
          <Typography className={classes.date}>{moment(createdOn).format('DD/MM/YYYY')}</Typography>
        </div>
      </div>
      <div className={classes.profileContainer}>
        {user && (
          <>
            <ProfileImage userId={user._id} size={20} style={{ marginRight: 10 }} />
            <Typography className={classes.userName} noWrap>
              {userName}
            </Typography>
          </>
        )}
      </div>
      <Typography className={classes.message}>{formattedString}</Typography>
      {activityCommunity && (
        <div className={classes.communityContainer}>
          <Chip className={classes.chip} label={activityCommunity.title} size="small" />
        </div>
      )}
      <Chevron style={{ marginLeft: 40, marginRight: 20 }} />
    </div>
  );
}

OfficerActivityRowCard.propTypes = {
  kind: PropTypes.string.isRequired,
  entity: PropTypes.object,
  createdOn: PropTypes.string.isRequired,
  user: PropTypes.object,
  formattedString: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  community: PropTypes.string
};

OfficerActivityRowCard.defaultProps = {
  onClick: null,
  community: '',
  entity: null,
  user: undefined
};
