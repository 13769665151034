import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { debounce } from 'lodash';
import { FormControlLabel, FormControl, FormLabel, Divider } from '@material-ui/core';

import TextInput from '../TextInput/TextInput';
import { getErrorMessage } from '../../constants/validation';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { getSlateValueFromTaskAndPractice, initialSlateValue } from '@/utils/helpers';

const useStyles = makeStyles({
  wrap: {
    padding: 40
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  descriptionLabel: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    paddingLeft: 12,
    fontSize: 10,
    marginBottom: 12,
  },
  richDescriptionContainer: {
    padding: '20px 20px 32px 20px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  errorRichTextEditor: {
    borderColor: 'red',
  }
});

const descriptionErrorFilter = e => e === 'description' || e === 'richDescription'

const ObjectivePracticeForm = ({ title, description, richDescription, countPerWeek, reminderFrequency, errors, onChange }) => {
  // Hooks
  const classes = useStyles();
  const initialDescription = getSlateValueFromTaskAndPractice(description, richDescription)
  const isContainDescriptionError = errors && Object.keys(errors || {}).filter(descriptionErrorFilter).length

  return (
    <Box className={classes.wrap}>
      <TextInput
        name="title"
        label="Create a practice title..."
        required
        value={title}
        onChange={onChange}
        errorMessage={getErrorMessage(errors, 'title')}
        showError={false}
      />

      {/* <TextInput
        name="description"
        label="Create a practice description..."
        required
        multiline
        value={description}
        onChange={onChange}
        errorMessage={getErrorMessage(errors, 'description')}
        showError={false}
      /> */}

      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <MuiInputLabel
          shrink={false}
          label="Create a practice description..."
          required
          classes={{
            root: classes.descriptionLabel,
          }}
        >
          Create a practice description...
        </MuiInputLabel>
        <div className={`${classes.richDescriptionContainer}  ${isContainDescriptionError ? classes.errorRichTextEditor: {}}`}>
          <RichTextEditor
            defaultValue={initialDescription}
            name="content"
            minHeight={100}
            onlyLink
            required
            onChange={debounce(content => {
              onChange({
                target: {
                  name: 'richDescription',
                  value: content
                }
              })
            }, 500)}
          />
        </div>
      </div>

      <TextInput
        name="countPerWeek"
        label="Set how many times a week"
        type="number"
        minNumber={1}
        required
        value={countPerWeek}
        onChange={onChange}
        errorMessage={getErrorMessage(errors, 'countPerWeek')}
        showError={false}
      />

      <Divider style={{ marginTop: 30, marginBottom: 30 }} />

      <FormControl component="fieldset">
        <FormLabel component="legend">How often should the user be reminded?</FormLabel>
        <RadioGroup name="reminderFrequency" value={reminderFrequency || ''} onChange={onChange}>
          <FormControlLabel value="" control={<Radio />} label="Never" />
          <FormControlLabel value="DAILY" control={<Radio />} label="Daily" />
          <FormControlLabel value="WEEKLY" control={<Radio />} label="Weekly" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

ObjectivePracticeForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  richDescription: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  countPerWeek: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reminderFrequency: PropTypes.string.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

ObjectivePracticeForm.defaultProps = {
  errors: null,
  richDescription: null,
};

export default ObjectivePracticeForm;
