import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryScatter,
  VictoryLabel,
  VictoryTooltip
} from 'victory';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowSize from '../../hooks/useWindowSize';

const TeamAccountabilityScoreChart = ({ theme, loading, teamAccountabilityScores }) => {
  const { width } = useWindowSize();

  const colors = theme.palette.collection;

  const renderChart = () => {
    const chartData = teamAccountabilityScores.reduce((all, curr) => {
      const date = `${moment(curr.fromDate).format('MMM D')} - ${moment(curr.toDate).format('MMM D')}`;

      if (!curr.team) {
        all[curr.team] = [];
      }

      all[curr.team] = all[curr.team] || [];

      all[curr.team].push({
        x: date,
        y: parseFloat(curr.score)
      });

      return all;
    }, {});

    return (
      <VictoryChart
        padding={{ top: 25, bottom: 25, left: 35, right: 35 }}
        domainPadding={{ top: 25, bottom: 25, left: 0, right: 0 }}
        height={340}
        width={width ? width / 2 : 800} // Note: This is the window size, not the width of the container. Adjustments will change the aspect ratio and font sizes
        domain={{ y: [0, 100] }}
        style={{
          background: { fill: 'white' }
        }}
      >
        <defs>
          {colors.map((stopColor, i) => (
            <linearGradient key={`${i}-gradient`} id={`${i}-gradient`} x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={stopColor} stopOpacity="0.2" />
              <stop offset="97%" stopColor={stopColor} stopOpacity="0.01" />
            </linearGradient>
          ))}
        </defs>

        {/** Y Axis */}
        <VictoryAxis
          dependentAxis
          tickFormat={tick => `${tick}%`}
          tickLabelComponent={<VictoryLabel angle={-90} alignContent="center" />}
          style={{
            grid: {
              stroke: 'rgba(0, 0, 0, 0.05)',
              fill: 'red',
              strokeDasharray: '10,5'
            },
            tickLabels: {
              background: 'white',
              fontSize: 8,
              padding: 8,
              fontWeight: 'bold'
            }
          }}
        />

        {/** X Axis */}
        <VictoryAxis
          tickLabelComponent={<VictoryLabel />}
          style={{
            grid: {
              stroke: 'rgba(0, 0, 0, 0.1)'
            },
            tickLabels: {
              fill: '#676767',
              fontSize: 10,
              padding: 8,
              fontWeight: 'bold'
            }
          }}
        />
        {Object.keys(chartData).map((team, index) => {
          const colorIndex = index % colors.length;
          const color = colors[colorIndex];

          return (
            <VictoryGroup key={team}>
              <VictoryArea
                // Don't think this is the official way to hide the labels but could not find any other option that worked
                labelComponent={<></>}
                interpolation="natural"
                animate={{
                  duration: 600,
                  onLoad: { duration: 0 }
                }}
                style={{
                  data: {
                    strokeWidth: 1,
                    strokeLinecap: 'round',
                    opacity: 0.9,
                    fill: `url(#${index}-gradient)`,
                    stroke: color
                  }
                }}
                data={chartData[team]}
              />

              <VictoryScatter
                data={chartData[team]}
                tick={() => ''}
                labelComponent={
                  <VictoryTooltip
                    style={{ fontSize: '10px', color: 'white', fill: 'white' }}
                    flyoutStyle={{
                      stroke: 'black',
                      fill: 'rgba(0,0,0,0.8)',
                      fontSize: 10,
                      color: 'white',
                      backgroundColor: 'black'
                    }}
                  />
                }
                style={{
                  data: {
                    fill: color,
                    stroke: color,
                    strokeWidth: 1
                  }
                }}
              />
            </VictoryGroup>
          );
        })}
      </VictoryChart>
    );

    // }

    // return <div>We couldn&apos;t find any records between the selected dates</div>;
  };

  return (
    <>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" m={8} minHeight={300}>
          <CircularProgress />
        </Box>
      ) : (
        renderChart()
      )}
    </>
  );
};

TeamAccountabilityScoreChart.propTypes = {
  loading: PropTypes.bool,
  teamAccountabilityScores: PropTypes.array
};

TeamAccountabilityScoreChart.defaultProps = {
  loading: false,
  teamAccountabilityScores: []
};

export default withTheme(TeamAccountabilityScoreChart);
