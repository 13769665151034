import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import AuthBackground from '../../components/Auth/AuthBackground/AuthBackground';
import ResetPasswordCard from '../../components/Auth/ResetPasswordCard/ResetPasswordCard';
import AuthConfirmationCard from '../../components/Auth/AuthConfirmationCard/AuthConfirmationCard';
import Modal from '../../components/Modal/Modal';

import { validateEmailFlat } from '../../constants/validation';

import {
  selectPasswordRequested,
  selectAuthLoadingState
} from '../../state/auth/reducers';
import * as authTypes from '../../state/auth/types';

import authStyles from './authStyles';

const styles = makeStyles({
  ...authStyles
});

export default function ResetPassword() {
  const classes = styles();
  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  // Redux state
  const authLoading = useSelector(state => selectAuthLoadingState(state));
  const passwordRequested = useSelector(state => selectPasswordRequested(state));

  useEffect(() => {
    // Set redux passwordRequested to false on mount
    dispatch({ type: authTypes.CLEAR_RESET_PASSWORD_REQUESTED });
  }, []);

  // Successful submit - show confirmation instead
  useEffect(() => {
    if (passwordRequested) setShowForm(false);
    else setShowForm(true);
  }, [passwordRequested]);

  // Handle update to email input
  function handleChange(e) {
    const { value } = e.target;

    // If errors exist and the user updates to valid email - remove the error
    if (error) {
      const errorMessage = validateEmailFlat(email);
      if (!errorMessage) setError(null);
    }

    setEmail(value);
  }

  // Submit the request
  function handleSubmit() {
    // Check for errors
    const errorMessage = validateEmailFlat(email);
    if (errorMessage === undefined) {
      setError(null);
      // Everything is groovy, submit the payload
      dispatch({
        type: authTypes.RESET_PASSWORD,
        email
      });
    } else setError(errorMessage[0]);
  }

  return (
    <AuthBackground>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          <Logo className={classes.logo} />
          {showForm ? (
            <>
              <ResetPasswordCard
                loading={authLoading}
                email={email}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errorMessage={error}
              />
              <Typography className={classes.bottomLink}>
                <Link to="/">Back to log in</Link>
              </Typography>
            </>
          ) : (
            <AuthConfirmationCard
              title="Check your email"
              copy={`If there's an existing account associated with ${email} we'll send you an email to help you reset your password.`}
            />
          )}
        </Grid>
      </Container>
      <Modal />
    </AuthBackground>
  );
}
