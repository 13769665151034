import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Unauthenticated container components
import Login from '../containers/Auth/Login';
import ResetPassword from '../containers/Auth/ResetPassword';
import DownloadTheApp from '../containers/Auth/DownloadTheApp';
import AcceptInvitation from '../containers/Auth/AcceptInvitation';
import SetNewPassword from '../containers/Auth/SetNewPassword';
import UpdateEmail from '../containers/Auth/UpdateEmail';
import Route404 from '../containers/Route404';

// Authenticated route switch
import AuthenticatedRouteSwitch from './AuthenticatedRouteSwitch';

// All unauthenticated routes and 404
// If route starts with /c/:communityId it will go to the authenticated switch
export default function TopLevelRoutes() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/set-password" component={SetNewPassword} />
      <Route exact path="/update-email" component={UpdateEmail} />
      <Route exact path="/accept-invitation" component={AcceptInvitation} />
      <Route exact path="/nxt-lvl-app" component={DownloadTheApp} />
      <Route path="/c/:communityId" render={() => <AuthenticatedRouteSwitch />} />
      <Route component={Route404} />
    </Switch>
  );
}
