import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import ProfileImage from '../ProfileImage/ProfileImage';

const useStyles = makeStyles({
  userName: {
    fontSize: 11,
    flex: 1
  },
  unreadMessages: {
    backgroundColor: '#f20046',
    borderRadius: '50%',
    color: 'white',
    padding: 2,
    fontSize: 10,
    minWidth: 20,
    textAlign: 'center'
  }
});

const ChatDrawerItem = ({ userId, onClick, userName, isOpen, numOfMessages, messagesInitialized, unreadCount }) => {
  // Hooks
  const classes = useStyles();

  // Local state
  const [length, setLength] = useState(0);

  // Only update the initial 'read' count on initial load
  useEffect(() => {
    if (messagesInitialized) {
      setLength(numOfMessages);
    }
  }, [messagesInitialized]);

  // Update 'read' count if the chat is open and a message comes in
  useEffect(() => {
    if (numOfMessages !== length && isOpen) {
      setLength(numOfMessages);
    }
  }, [numOfMessages, isOpen]);

  return (
    <ListItem key={userId} button onClick={onClick}>
      <ProfileImage userId={userId} style={{ marginRight: 10 }} />
      <Typography className={classes.userName} noWrap>
        {userName}
      </Typography>
      {unreadCount !== undefined && unreadCount > 0 && <div className={classes.unreadMessages}>{unreadCount}</div>}
    </ListItem>
  );
};

ChatDrawerItem.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  numOfMessages: PropTypes.number.isRequired,
  messagesInitialized: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number
};

ChatDrawerItem.defaultProps = {
  unreadCount: undefined
};

export default ChatDrawerItem;
