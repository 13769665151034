import React, { useState, useEffect } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import axiosConfig from './utils/axiosConfig';
// import envConfig from '@/utils/envConfig';
// import { initFirebase } from '@/utils/fcmConfig';
import AppLoadingPage from './components/Auth/AppLoadingPage/AppLoadingPage';
import Bootstrapper from './system/Bootstrapper';

// Add all free solid font awesome icons
library.add(fas);

const App = () => {
  // Potentially do some set up here... env, axios etc
  const [initialized, setInitialized] = useState(false);

  // Set up essential services before rendering app
  useEffect(() => {
    const isProd = '__env__' in window;
    axiosConfig(isProd);

    setInitialized(true);
    // async function setEnv() {
    //   const { isProd } = await envConfig();
    //   axiosConfig(isProd);

    //   setInitialized(true);
    // }
    // setEnv();
  }, []);

  return initialized ? <Bootstrapper /> : <AppLoadingPage />;
};

export default App;
