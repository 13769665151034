export const slimScrollBar = {
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#dcdcdc !important'
  },
  '&::-webkit-scrollbar': {
    width: 5
  },
  '&::-webkit-scrollbar-thumb': {
    height: 174,
    borderRadius: 15,
    backgroundColor: '#a5a5a5'
  }
};

export const glowKeyframe = {
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  }
};

export default slimScrollBar;
