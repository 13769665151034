import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextInput from '../TextInput/TextInput';

import { selectCommunityApiErrors } from '../../state/community/reducers';
import * as communityTypes from '../../state/community/types';
import IconPicker from '../IconPicker/IconPicker';

import { getErrorMessage, parseApiErrors } from '../../constants/validation'

const styles = makeStyles({
  paper: {
    borderRadius: 10,
    padding: '25px 20px'
  },
  mb20: {
    marginBottom: 20
  }
});

export default function CommunityDetailsForm({
  onChange,
  onIconChange,
  icon,
  communityName,
  communityDescription,
  readOnly
}) {
  const classes = styles();

  // Redux
  const apiErrors = useSelector(state => selectCommunityApiErrors(state));
  const computedErrors = parseApiErrors(apiErrors, communityTypes.UPDATE_COMMUNITY_ERROR);

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.mb20}>
          <IconPicker
            name="icon"
            icon={icon}
            onSubmit={onIconChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb20}>
          <TextInput
            name="title"
            label="Community name"
            onChange={onChange}
            value={communityName}
            disabled={readOnly}
            error={Boolean(getErrorMessage(computedErrors, 'title'))}
            errorMessage={getErrorMessage(computedErrors, 'title')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            multiline
            rows={6}
            name="description"
            label="Description"
            onChange={onChange}
            value={communityDescription}
            disabled={readOnly}
            error={Boolean(getErrorMessage(computedErrors, 'description'))}
            errorMessage={getErrorMessage(computedErrors, 'description')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

CommunityDetailsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onIconChange: PropTypes.func.isRequired,
  communityName: PropTypes.string.isRequired,
  communityDescription: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

CommunityDetailsForm.defaultProps = {
  readOnly: false
};
