import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import Checkbox from '../Checkbox/Checkbox';

const styles = makeStyles({
  labelRoot: {
    color: '#838b96',
    zIndex: 1,
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 7,
    pointerEvents: 'none',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
  },
  permissions: {
    padding: '5px 16px 0px 16px',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    marginBottom: 40
  }
});

export default function UserPermissions({
  readOnly,
  isLeader,
  isComAdmin,
  onChange,
  style
}) {
  const classes = styles();
  return (
    <>
      <InputLabel
        shrink
        classes={{
          root: classes.labelRoot
        }}
      >
        Permissions
      </InputLabel>
      <div className={classes.permissions} style={style}>
        <Checkbox
          name="isLeader"
          label="Leader"
          checked={isLeader}
          disabled={readOnly}
          onChange={onChange}
        />
        <Checkbox
          name="isComAdmin"
          label="Community Admin"
          checked={isComAdmin}
          disabled={readOnly}
          onChange={onChange}
        />
        <Checkbox
          name="isUser"
          label="Member"
          checked
          disabled
        />
      </div>
    </>
  );
}

UserPermissions.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isLeader: PropTypes.bool.isRequired,
  isComAdmin: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
};

UserPermissions.defaultProps = {
  style: null
};
