import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const styles = makeStyles(theme => ({
  paper: {
    padding: '25px 20px',
    borderRadius: 10
  },
  pl: {
    paddingLeft: theme.spacing(1)
  },
  pr: {
    paddingRight: theme.spacing(1)
  },
  mb: {
    marginBottom: 25
  },
  mt: {
    marginTop: 20
  },
  button: {
    width: 150
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  backgroundCover: {
    borderRadius: 5,
    color: '#dcdcdc',
    background: '#dcdcdc',
    animation: '$glow 1.5s ease-in-out infinite'
  },
  description: {
    height: 150
  }
}));

export default function UserProfileFormLoading({ readOnly }) {
  const classes = styles();
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={6} className={clsx(classes.mb, classes.pr)}>
            <Typography className={classes.backgroundCover}>Loading</Typography>
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pl)}>
            <Typography className={classes.backgroundCover}>Loading</Typography>
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pr)}>
            <Typography className={classes.backgroundCover}>Loading</Typography>
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pl)}>
            <Typography className={classes.backgroundCover}>Loading</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(classes.description, classes.backgroundCover)}
            >
              Loading
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {!readOnly && (
        <Grid container justify="flex-end" className={classes.mt}>
          <Typography className={classes.backgroundCover}>Loading</Typography>
        </Grid>
      )}
    </>
  );
}

UserProfileFormLoading.propTypes = {
  readOnly: PropTypes.bool.isRequired
};
