import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as BOOTSTRAP_TYPES from '../state/bootstrap/types';
import { AUTH_LOGOUT } from '../state/auth/types';
import { isLeader, isCommunityAdmin } from '../utils/helpers';

// Page components
import AppLoadingPage from '../components/Auth/AppLoadingPage/AppLoadingPage';
import TopLevelRoutes from './TopLevelRoutes';

const doNotRedirect = ['/accept-invitation', '/update-email', '/set-password', '/login', '/', '/nxt-lvl-app'];

const Bootstrapper = () => {
  // When all the conditions have been met, we set this to true
  const isBootstrapped = useSelector(state => state.bootstrap.isBoostraped);
  const loggedInUser = useSelector(state => state.auth.loggedInUser);

  const [isInitialized, setIsInitialized] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  /**
   * If we have a stored refresh token in local storage, use it to refresh the auth credentials
   */

  useEffect(() => {
    if (isInitialized) return;

    // Trigger initial bootstrap
    if (!isBootstrapped && !loggedInUser) {
      dispatch({ type: BOOTSTRAP_TYPES.BOOTSTRAP });
    }

    // This nested if could be better but have run out of time
    // Need to refactor entire bootstrap loading saga logic
    if (loggedInUser && isBootstrapped) {
      const { roles, memberships } = loggedInUser;
      const isOfficer = roles.includes('officer');
      const isALeader = isLeader(memberships);
      const isACommunityAdmin = isCommunityAdmin(memberships);

      const hasAccess = isALeader === true || isACommunityAdmin === true;

      if (hasAccess || isOfficer) {
        // Should be a leader in a community
        setIsInitialized(true);
      } else {
        // User permission level does not allow them to use the portal so redirect them to the get the app page
        dispatch({ type: AUTH_LOGOUT });
        setIsInitialized(true);
        history.push('/nxt-lvl-app');
      }
    } else if (isBootstrapped) {
      // No authenticated user
      setIsInitialized(true);
      if (!doNotRedirect.some(path => path === location.pathname))
        history.push(`/login?redirect=${location.pathname}${location.search}`);
    }
  }, [isBootstrapped, loggedInUser, isInitialized, setIsInitialized]);

  return isInitialized ? <TopLevelRoutes /> : <AppLoadingPage />;
};

export default Bootstrapper;
