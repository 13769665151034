export const GET_COMMUNITY = 'community/GET_COMMUNITY';
export const GET_COMMUNITY_SUCCESS = 'community/GET_COMMUNITY_SUCCESS';
export const GET_COMMUNITY_ERROR = 'community/GET_COMMUNITY_ERROR';

export const UPDATE_COMMUNITY = 'community/UPDATE_COMMUNITY';
export const UPDATE_COMMUNITY_SUCCESS = 'community/UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_ERROR = 'community/UPDATE_COMMUNITY_ERROR';

export const SET_COMMUNITY_PERMISSIONS = 'community/SET_COMMUNITY_PERMISSIONS';
export const SET_COMMUNITY_PERMISSIONS_SUCCESS =
  'community/SET_COMMUNITY_PERMISSIONS_SUCCESS';
export const SET_COMMUNITY_PERMISSIONS_ERROR =
  'community/SET_COMMUNITY_PERMISSIONS_ERROR';

export const GET_ALL_COMMUNITIES = 'community/GET_ALL_COMMUNITIES';
export const GET_ALL_COMMUNITIES_SUCCESS = 'community/GET_ALL_COMMUNITIES_SUCCESS';
export const GET_ALL_COMMUNITIES_ERROR = 'community/GET_ALL_COMMUNITIES_ERROR';
export const GET_ALL_COMMUNITIES_DONE = 'community/GET_ALL_COMMUNITIES_DONE';

export const GET_COMMUNITY_USERS = 'community/GET_COMMUNITY_USERS';
export const GET_COMMUNITY_USERS_SUCCESS = 'community/GET_COMMUNITY_USERS_SUCCESS';
export const GET_COMMUNITY_USERS_ERROR = 'community/GET_COMMUNITY_USERS_ERROR';

export const GET_COMMUNITY_LEADERS = 'community/GET_COMMUNITY_LEADERS';
export const GET_COMMUNITY_LEADERS_SUCCESS =
  'community/GET_COMMUNITY_LEADERS_SUCCESS';
export const GET_COMMUNITY_LEADERS_ERROR = 'community/GET_COMMUNITY_LEADERS_ERROR';

export const GET_LEADER_USERS = 'community/GET_LEADER_USERS';
export const GET_LEADER_USERS_SUCCESS = 'community/GET_LEADER_USERS_SUCCESS';
export const GET_LEADER_USERS_ERROR = 'community/GET_LEADER_USERS_ERROR';

export const BATCH_UPLOAD_USERS = 'community/BATCH_UPLOAD_USERS';
export const BATCH_UPLOAD_USERS_SUCCESS = 'community/BATCH_UPLOAD_SUCCESS';
export const BATCH_UPLOAD_USERS_ERROR = 'community/BATCH_UPLOAD_ERROR';

export const GET_LEADER_ACTIVITY = 'community/GET_LEADER_ACTIVITY';
export const GET_LEADER_ACTIVITY_SUCCESS = 'community/GET_LEADER_ACTIVITY_SUCCESS';
export const GET_LEADER_ACTIVITY_ERROR = 'community/GET_LEADER_ACTIVITY_ERROR';

export const OFFICER_ACTIVITY_LOADING_STATE =
  'community/OFFICER_ACTIVITY_LOADING_STATE';
export const GET_AGNOSTIC_LEADER_ACTIVITY = 'community/GET_AGNOSTIC_LEADER_ACTIVITY';
export const GET_AGNOSTIC_LEADER_ACTIVITY_SUCCESSS =
  'community/GET_AGNOSTIC_LEADER_ACTIVITY_SUCCESS';
export const GET_AGNOSTIC_LEADER_ACTIVITY_ERROR =
  'community/GET_AGNOSTIC_LEADER_ACTIVITY_ERROR';

export const COMMUNITY_LOADING_STATE = 'community/COMMUNITY_LOADING_STATE';
export const COMMUNITY_SAVING_STATE = 'community/COMMUNITY_SAVING_STATE';
export const COMMUNITY_USERS_LOADING_STATE =
  'community/COMMUNITY_USERS_LOADING_STATE';
export const COMMUNITY_LEADERS_LOADING_STATE =
  'community/COMMUNITY_LEADERS_LOADING_STATE';
export const LEADER_USERS_LOADING_STATE = 'community/LEADER_USERS_LOADING_STATE';
export const LEADER_ACTIVITY_LOADING_STATE =
  'community/LEADER_ACTIVITY_LOADING_STATE';

export const PSYCH_FRAMEWORK_SAVING_STATE = 'community/PSYCH_FRAMEWORK_SAVING_STATE';
export const UPDATE_COMMUNITY_PSYCH_FRAMEWORK =
  'community/UPDATE_COMMUNITY_PSYCH_FRAMEWORK';
export const UPDATE_COMMUNITY_PSYCH_FRAMEWORK_SUCCESS =
  'community/UPDATE_COMMUNITY_PSYCH_FRAMEWORK_SUCCESS';
export const UPDATE_COMMUNITY_PSYCH_FRAMEWORK_ERROR =
  'community/UPDATE_COMMUNITY_PSYCH_FRAMEWORK_ERROR';

export const SET_SELECTED_COMMUNITY = 'community/SET_SELECTED_COMMUNITY';

export const UPDATE_COMMUNITY_LEADERS_SUCCESS =
  'community/UPDATE_COMMUNITY_LEADERS_SUCCESS';

export const MEMBER_STAT_LOADING_STATE = 'community/MEMBER_STATE_LOADING_STATE';
export const GET_MEMBER_STAT_OVERVIEW = 'community/GET_MEMBER_STAT_OVERVIEW';
export const GET_MEMBER_STAT_OVERVIEW_SUCCESS =
  'community/GET_MEMBER_STAT_OVERVIEW_SUCCESS';

export const GET_OFFICER_MEMBERS = 'community/GET_OFFICER_MEMBERS';
export const GET_OFFICER_MEMBERS_SUCCESS = 'community/GET_OFFICER_MEMBERS_SUCCESS';
export const OFFICER_MEMBERS_LOADING_STATE =
  'community/OFFICER_MEMBERS_LOADING_STATE';

export const CLEAR_COMMUNITY_SPECIFICS = 'community/CLEAR_COMMUNITY_SPECIFICS';
