/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import {
  VictoryLine,
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryGroup,
  VictoryScatter
} from 'victory';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, withTheme } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Datepicker from '../Datepicker/Datepicker';
import ToolTip from '../Charts/Tooltip';
import useWindowSize from '../../hooks/useWindowSize';

import * as reportTypes from '../../state/reports/types';
import { selectObjectivePracticeReport, selectObjectivePracticeReportLoading } from '../../state/reports/reducers';

const useStyles = makeStyles({
  header: {
    alignItems: 'center'
  },
  dateContainer: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  datePicker: {
    width: 'auto'
  },
  mr10: {
    marginRight: 10
  },
  label: {
    fontSize: 12
  }
});

const ObjectiveChart = ({ objectiveId, tasks, practices, reload, theme }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [startDate, setStartDate] = useState(moment().subtract(8, 'weeks'));
  const [endDate, setEndDate] = useState(moment());

  // temp solution
  const preferenceIsShowingActions = JSON.parse(localStorage.getItem('onmission.preferences.isShowingActions'));
  const preferenceIsShowingPractices = JSON.parse(localStorage.getItem('onmission.preferences.isShowingPractices'));

  const [isShowingActions, setIsShowingActions] = useState(
    preferenceIsShowingActions !== null ? preferenceIsShowingActions : true
  );
  const [isShowingPractices, setIsShowingPractices] = useState(
    preferenceIsShowingPractices !== null ? preferenceIsShowingPractices : true
  );

  // Disabled for now - this would hide the chart if nothing is selected
  const showChart = true; // isShowingActions || isShowingPractices;

  const loading = useSelector(selectObjectivePracticeReportLoading);
  const reports = useSelector(selectObjectivePracticeReport);

  const { width } = useWindowSize();
  const fetchData = () => {
    dispatch({
      type: reportTypes.GET_OBJECTIVE_PRACTICE_REPORTS,
      objectiveId,
      startDate: moment(startDate)
        .startOf('isoWeek')
        .toISOString(),
      endDate: moment(endDate)
        .endOf('isoWeek')
        .toISOString()
    });
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, reload]);

  useEffect(() => {
    // temp solution
    localStorage.setItem('onmission.preferences.isShowingActions', isShowingActions);
    localStorage.setItem('onmission.preferences.isShowingPractices', isShowingPractices);
  }, [isShowingActions, isShowingPractices]);

  const renderChart = () => {
    const practicesGroup = reports.reduce((all, curr) => {
      all[curr.practice] = all[curr.practice] || [];
      all[curr.practice].push(curr);
      return all;
    }, {});

    const getWeekString = date => {
      const startWeek = moment(date).startOf('isoWeek');
      const endWeek = moment(date).endOf('isoWeek');

      return `${startWeek.format('MMM D')}-${endWeek.format('D')}`;
    };

    const chartData = Object.keys(practicesGroup).map((o, index) => {
      const colorIndex = index % theme.palette.collection.length;
      const color = theme.palette.collection[colorIndex];
      const practice = {
        color
      };

      practice.data = practicesGroup[o].reduce((all, curr) => {
        const date = moment()
          .year(curr.year)
          .isoWeek(curr.week);

        const fullPractice = practices.find(p => p._id === curr.practice);
        const title = fullPractice?.title || '';

        all.push({ x: getWeekString(date), y: curr.count, label: `${title} ${curr.count}%` });
        return all;
      }, []);

      return practice;
    });

    const taskChartData = [];
    if (tasks) {
      const completedTasksByWeekYear = tasks.reduce((all, curr) => {
        if (curr.completeDate) {
          const date = moment(curr.completeDate);

          const key = `${date.isoWeek()}-${date.year()}`;
          all[key] = all[key] || [];
          all[key].push(curr);
        }
        return all;
      }, {});

      for (
        let m = moment(startDate).startOf('day');
        m.isSameOrBefore(moment(endDate).startOf('day'));
        m.add(1, 'weeks')
      ) {
        const d = m.clone();
        const key = `${d.isoWeek()}-${d.year()}`;
        const date = getWeekString(d);

        let y = 0;
        let label;

        if (completedTasksByWeekYear[key]) {
          y = 100;
          label = completedTasksByWeekYear[key].map(item => item.task).join('\n');
        }

        taskChartData.push({
          x: date,
          y,
          label
        });
      }
    }

    if (chartData.length > 0 || taskChartData.length > 0) {
      return (
        <Box>
          {showChart ? (
            <VictoryChart
              padding={{ top: 20, bottom: 30, left: 20, right: 20 }}
              domainPadding={30}
              height={300}
              width={width ? width / 2 : 800}
            >
              <defs>
                <linearGradient id="gradientStroke" x1="0%" x2="0%" y1="0%" y2="100%">
                  <stop offset="0%" stopColor="#f5bd56" stopOpacity="0.2" />
                  <stop offset="97%" stopColor="#f5bd56" stopOpacity="0.01" />
                </linearGradient>

                {theme?.palette?.collection.map((stopColor, i) => (
                  <linearGradient key={`${i}-gradient`} id={`${i}-gradient`} x1="0%" x2="0%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor={stopColor} stopOpacity="0.2" />
                    <stop offset="97%" stopColor={stopColor} stopOpacity="0.01" />
                  </linearGradient>
                ))}
              </defs>

              {isShowingActions && taskChartData.length > 0 && (
                <VictoryGroup data={taskChartData} domain={{ y: [0, 100] }}>
                  <VictoryArea
                    interpolation="step"
                    // color={theme.palette.secondary.main}
                    style={{
                      labels: { display: 'none' },
                      data: {
                        fill: 'url(#gradientStroke)',
                        stroke: '#f5bd56'
                      }
                    }}
                  />
                  <VictoryScatter
                    style={{
                      data: {
                        fill: 'transparent'
                      }
                    }}
                    size={12}
                    labelComponent={<ToolTip />}
                  />
                </VictoryGroup>
              )}

              {isShowingPractices &&
                chartData.map((practice, index) => (
                  <VictoryGroup key={`c${index}`} data={practice.data} color={practice.color} domain={{ y: [0, 100] }}>
                    <VictoryLine
                      style={{
                        data: {
                          fill: `url(#${index}-gradient)`,
                          fillRule: 'evenodd'
                        }
                      }}
                      interpolation="natural"
                      labelComponent={<ToolTip />}
                    />
                    <VictoryScatter labelComponent={<ToolTip />} />
                  </VictoryGroup>
                ))}

              {/** X Axis */}
              <VictoryAxis
                style={{
                  tickLabels: {
                    fill: '#676767',
                    fontSize: 8,
                    padding: 8,
                    fontWeight: 'bold'
                  }
                }}
              />

              {/** Y Axis */}
              <VictoryAxis
                dependentAxis
                tickFormat={tick => `${tick}%`}
                tickLabelComponent={<VictoryLabel angle={-90} alignContent="center" />}
                style={{
                  grid: {
                    stroke: 'rgba(0, 0, 0, 0.05)',
                    fill: 'red',
                    strokeDasharray: '10,5'
                  },
                  tickLabels: {
                    background: 'white',
                    fontSize: 8,
                    padding: 8,
                    fontWeight: 'bold'
                  }
                }}
              />
            </VictoryChart>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={3}
              style={{ background: '#f7f7f7', minHeight: 100, marginTop: 20, borderRadius: 10 }}
            >
              <h4 style={{ opacity: 0.5 }}>No objective timeline metrics selected</h4>
              <p style={{ opacity: 0.5, marginBottom: 0 }}>
                Use the checkboxes below to enable the metric you would like to see.
              </p>
            </Box>
          )}
          <Box justifyContent="flex-end" display="flex" marginBottom={2}>
            <FormControlLabel
              className={classes.label}
              label={<Typography className={classes.label}>Show Actions</Typography>}
              control={
                <Checkbox
                  // defaultChecked={false}
                  checked={isShowingActions}
                  onChange={() => setIsShowingActions(!isShowingActions)}
                  name="checkedB"
                  color="primary"
                />
              }
            />
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  // defaultChecked={false}
                  checked={isShowingPractices}
                  onChange={() => setIsShowingPractices(!isShowingPractices)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Typography className={classes.label}>Show Practices</Typography>}
            />
          </Box>
        </Box>
      );
    }

    return <div>We couldn&apos;t find any records between the selected dates</div>;
  };

  return (
    <>
      {showChart && (
        <Hidden mdDown>
          <Grid container className={classes.header}>
            <Grid item xs={12} sm={6}>
              <div>Objective Timeline</div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.dateContainer}>
              <Datepicker
                label="Start Date"
                name="startDate"
                readOnly={loading}
                onChange={e => {
                  if (e) setStartDate(e);
                }}
                value={startDate}
                className={clsx(classes.datePicker, classes.mr10)}
              />
              <Datepicker
                label="End Date"
                name="endDate"
                readOnly={loading}
                onChange={e => {
                  if (e) setEndDate(e);
                }}
                value={endDate}
                className={classes.datePicker}
              />
            </Grid>
          </Grid>
        </Hidden>
      )}

      <div>{loading ? 'Loading' : renderChart()}</div>
    </>
  );
};

ObjectiveChart.propTypes = {
  objectiveId: PropTypes.string.isRequired,
  tasks: PropTypes.array,
  practices: PropTypes.array,
  reload: PropTypes.number
};

ObjectiveChart.defaultProps = {
  tasks: null,
  practices: [],
  reload: 0
};

export default withTheme(ObjectiveChart);
