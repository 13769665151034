import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SearchInput from '../SearchInput/SearchInput';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import BatchUpload from './BatchUpload';

const styles = makeStyles({
  container: {
    marginBottom: 10
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  addButton: {
    marginLeft: 9,
    width: 145
  }
});

export default function ListFilter({
  search,
  filterOptions,
  filter,
  searchPlaceholder,
  filterByPlaceholder,
  onSearchChange,
  onFilterChange,
  onCreate,
  showBatch,
  readOnly,
  createLabel,
  customBatchBtn,
  btnStyles,
  customFilter,
  btnIcon
}) {
  const classes = styles();

  const hasFilterOptions = filterOptions.length > 0;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={5}>
        <SearchInput
          style={{ height: '100%' }}
          placeholder={searchPlaceholder}
          value={search}
          onChange={onSearchChange}
        />
      </Grid>
      {!customFilter && (
        <Grid item xs={3} className={classes.filterContainer}>
          {hasFilterOptions && (
            <Dropdown
              showNoneOption
              options={filterOptions}
              selected={filter}
              onChange={onFilterChange}
              placeholder={filterByPlaceholder}
            />
          )}
        </Grid>
      )}
      {customFilter !== undefined && customFilter}
      {!readOnly && (
        <Grid item xs={!customFilter ? 4 : 3} className={classes.buttonContainer}>
          {showBatch && <BatchUpload />}
          {customBatchBtn || <></>}
          {btnIcon ?
            (<Button
              iconPosition="start"
              icon={btnIcon}
              label={createLabel}
              className={classes.addButton}
              onClick={onCreate}
              style={btnStyles}
            />) :
            (<Button label={createLabel} className={classes.addButton} onClick={onCreate} style={btnStyles} />)}
        </Grid>
      )}
    </Grid>
  );
}

ListFilter.propTypes = {
  search: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  filterByPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  filterOptions: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  showBatch: PropTypes.bool,
  createLabel: PropTypes.string,
  customBatchBtn: PropTypes.node,
  btnStyles: PropTypes.object,
  customFilter: PropTypes.node,
  btnIcon: PropTypes.node,
};

ListFilter.defaultProps = {
  searchPlaceholder: 'Search...',
  filterByPlaceholder: 'Filter by...',
  showBatch: false,
  onCreate: null,
  createLabel: 'create',
  customBatchBtn: undefined,
  btnStyles: {},
  customFilter: undefined,
  btnIcon: undefined,
};
