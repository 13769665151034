import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 9
  },
  wrap: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 12
  },
  date: {
    fontSize: 12,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
  },
  reflection: {
    fontSize: 14,
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    lineHeight: 1.43
  }
});

const MissionReflection = ({ reflection }) => {
  // Hooks
  const classes = useStyles();

  // Constants
  const { createdOn, text } = reflection;

  return (
    <Box width="100%">
      <Typography className={classes.title}>Reflection</Typography>
      <Box className={classes.wrap}>
        <Typography className={classes.date}>
          Posted {moment(createdOn).format('DD MMMM YYYY')}
        </Typography>
        <Typography className={classes.reflection}>{`"${text}"`}</Typography>
      </Box>
    </Box>
  );
};

MissionReflection.propTypes = {
  reflection: PropTypes.object.isRequired
};

export default MissionReflection;
