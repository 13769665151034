import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as EditIcon } from '../../static/images/icons/icon-edit.svg';
import { ReactComponent as TrashIcon } from '../../static/images/icons/icon-trash.svg';
import ObjectivePracticeForm from '../ObjectivePractice/ObjectivePracticeForm';

import Dialog from '@material-ui/core/Dialog';
import Button from '../Button/Button';
import clsx from 'clsx';
import { DANGER_SNACK } from '@/state/snack/types';
import { validateObjectivePractice } from '@/constants/validation';
import { ensurePositiveNumber, initialSlateValue } from '@/utils/helpers';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 60,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 10
  },
  date: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  },
  task: {
    fontFamily: 'SFUIText-Bold',
    fontSize: 14,
    flex: 1,
    marginRight: 10
  },
  countPerWeek: {
    fontFamily: 'SFUIText-Light',
    fontSize: 14,
    flex: 1,
    marginRight: 10
  },
  statusCircle: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    backgroundColor: '#adb6c1'
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: '#dcdcdc',
    borderRadius: 20,
    padding: '12px 18px'
  },
  dueDate: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginRight: 10
  },
  overdue: {
    textTransform: 'uppercase',
    fontSize: 10,
    color: theme.palette.error.main,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginRight: 10
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    outline: 'none !important',
    backgroundColor: '#ededed',
    maxWidth: 28,
    maxHeight: 29,
    borderRadius: 10,
    padding: 4
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 20px'
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  },
  cancel: {
    marginRight: 8
  }
}));

const PracticeCard = ({ practice, index, readOnly, onDelete, onCreate }) => {
  // Hooks
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const containerRef = useRef();
  // const [errors, setErrors] = useState(null);
  const { title, countPerWeek } = practice;
  // const dispatch = useDispatch();

  const [practiceDetails, setPracticeDetails] = useState(practice);

  // Helpers
  const getPopupPostion = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getClientRects()[0];
      return {
        position: 'absolute',
        // top: rect.top,
        left: rect.left,
        width: rect.width
      };
    }
    return null;
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  // const showErrorSnack = () => {
  //   dispatch({
  //     type: DANGER_SNACK,
  //     content: 'Please check all of the required fields'
  //   });
  // };

  const handleCreate = e => {
    // Ensure a default of zero days
    const practice = practiceDetails;

    // onCreate({ id: randomId(), ...practice });
    onCreate({ ...practice });
    handleClose(e);

    // const errorMessages = validateObjectivePractice(practiceDetails);
    // if (errorMessages) {
    //   setErrors(errorMessages);
    //   showErrorSnack();
    // } else {
    //   // onCreate({ id: randomId(), ...practice });
    //   onCreate({ ...practiceDetails });
    //   handleClose(e);
    // }
  };


  const handleChange = e => {
    const { name, value } = e.target;

    const isNumeric = name === 'daysDue';

    setPracticeDetails({
      ...practiceDetails,
      [name]: isNumeric ? ensurePositiveNumber(value) : value
    });
  };

  // Constants
  // const { title, description } = practiceDetails;
  const complete =
    Boolean(practiceDetails.title) &&
    (
      practiceDetails.richDescription &&
      JSON.stringify(practiceDetails.richDescription) !== JSON.stringify(initialSlateValue())
    );

  return (
    <Box
      className={classes.container}
      role="presentation"
      ref={containerRef}
    >
      <Box display="flex" flexGrow={1} flexDirection="column">
        <Typography noWrap className={classes.task}>
          {title}
        </Typography>
        <Typography noWrap className={classes.countPerWeek}>
          {countPerWeek} times a week
        </Typography>
      </Box>
      <IconButton
        className={classes.icon}
        onClick={handleClick}
      >
        <EditIcon />
      </IconButton>
      {!readOnly && (
        <IconButton className={classes.icon} onClick={() => onDelete(index)}>
          <TrashIcon />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { ...getPopupPostion(), margin: 0 },
          className: classes.contentWrap
        }}
      >
        <Box>
          <ObjectivePracticeForm
            title={practiceDetails.title}
            description={practiceDetails.description}
            richDescription={practiceDetails.richDescription}
            countPerWeek={practiceDetails.countPerWeek}
            reminderFrequency={practiceDetails.reminderFrequency}
            onChange={handleChange}
            // errors={errors}
          />
        </Box>
        <Box className={classes.buttonWrap}>
          <Button
            data-test-id="button-objective-task-cancel"
            background="grey"
            label="Cancel"
            onClick={handleClose}
            className={clsx(classes.btn, classes.cancel)}
          />
          <Button
            label="Update"
            onClick={handleCreate}
            disabled={!complete}
            className={classes.btn}
            data-test-id="button-objective-task-create"
          />
        </Box>
      </Dialog>
    </Box>
  );
};

PracticeCard.propTypes = {
  practice: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool
};

PracticeCard.defaultProps = {
  onDelete: null,
  readOnly: false
};

export default PracticeCard;
