import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';

import LottiePlayer from '../LottiePlayer/LottiePlayer';

import assigningTemplatesAnimation from '../../static/lotties/assigning-templates.json';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1399,
    flexDirection: 'column'
  },
  text: {
    fontSize: 24,
    marginBottom: 64,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#ffffff'
  }
});

const AssigningTemplatesLoader = ({ open }) => {
  // Hooks
  const classes = useStyles();

  return (
    <Backdrop open={open} classes={{ root: classes.backdrop }}>
      <Typography className={classes.text}>
        Assigning Mission Templates to Members
      </Typography>
      {open && <LottiePlayer animationData={assigningTemplatesAnimation} />}
    </Backdrop>
  );
};

AssigningTemplatesLoader.propTypes = {
  open: PropTypes.bool.isRequired
};

export default AssigningTemplatesLoader;
