import React from 'react';
import * as modalTypes from '../../state/modal/types';

import ApiErrorModal from '../../containers/Modals/ApiErrorModal';
import ConfirmationModal from '../../containers/Modals/ConfirmationModal';
import MissionReflectionModal from '../../containers/Modals/MissionReflectionModal';
import TemplateAssignmentErrorsModal from '../../containers/Modals/TemplateAssignmentErrorsModal';
import ToolkitCategoriesModal from '../../containers/Modals/ToolkitCategoriesModal';
import PreviewToolkitModal from '../../containers/Modals/PreviewToolkitModal';
import AddHyperLink from '../../containers/Modals/AddHyperlink';

export const getModalFromType = type => {
  switch (type) {
    case modalTypes.API_ERROR_MODAL:
      return <ApiErrorModal />;

    case modalTypes.CONFIRMATION_MODAL:
      return <ConfirmationModal />;

    case modalTypes.MISSION_REFLECTION_MODAL:
      return <MissionReflectionModal />;

    case modalTypes.TEMPLATE_ASSIGNMENT_ERRORS_MODAL:
      return <TemplateAssignmentErrorsModal />;

    case modalTypes.TOOLKIT_CATEGORIES_MODAL:
      return <ToolkitCategoriesModal />;

    case modalTypes.PREVIEW_TOOLKIT_MODAL:
      return <PreviewToolkitModal />;

    case modalTypes.ADD_HYPERLINK:
      return <AddHyperLink />;
    default:
      return <div />;
  }
};

export default getModalFromType;
