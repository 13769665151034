import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

import createFirebaseMiddleware from './middleware/firebase';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const firebaseMiddleware = createFirebaseMiddleware();

const middleware = [sagaMiddleware, firebaseMiddleware, routerMiddleware(history)];

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : compose;

const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(...middleware), reduxDevTools)
);

sagaMiddleware.run(rootSaga);

export default store;
