import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Icons from './icons';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    paddingTop: 8
  },
  wrap: {
    width: 54,
    height: 54,
    borderRadius: 12,
    backgroundColor: '#dfdfdf',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4 !important',
      boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
      '& *': {
        transition: 'all 0.2s ease-in-out',
        fill: 'url(#lgrad)'
      }
    }
  },
  active: {
    backgroundColor: '#f4f4f4 !important',
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)'
  },
  iconGrey: {
    '& *': {
      fill: '#d0d0d0'
    }
  },
  iconGold: {
    '& *': {
      fill: 'url(#lgrad)'
    }
  }
});

const CustomIcon = ({ icon, selected, onClick, viewOnly, missionIcon }) => {
  const classes = useStyles();

  const wrapClass = clsx({
    [classes.wrap]: true,
    [classes.active]: selected
  });

  let iconClass = clsx({
    [classes.iconGold]: selected,
    [classes.iconGrey]: !selected
  });

  let flexStyles = {}
  let wrapStyles = {}

  if (viewOnly) {
    // flexStyles = {paddingTop: 0};
    wrapStyles = {
      height: '5rem',
      width: '5rem',
      background: '#fff',
      pointerEvents: 'none'
    };
    iconClass = clsx({
      [classes.iconGold]: true,
      [classes.iconGrey]: false
    });
  }

  if (missionIcon) {
    wrapStyles.height = '';
    wrapStyles.width = '';
    wrapStyles.boxShadow = '0 5px 10px -3px rgba(0, 0, 0, 0.2)';
  }

  const IconComponent = Icons[icon];

  return (
    <div onClick={onClick} role="presentation" className={classes.flex} style={flexStyles}>
      <div className={wrapClass} style={wrapStyles}>
        <IconComponent className={iconClass} />
      </div>
    </div>
  );
};

CustomIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  missionIcon: PropTypes.bool
};

CustomIcon.defaultProps = {
  selected: false,
  viewOnly: false,
  missionIcon: false,
};

export default CustomIcon