import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { toBase64 } from '@/utils/helpers';
import * as templateActions from './actions';
import * as templateTypes from './types';

import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

import { selectLoggedInUserId } from '../auth/reducers';
import {
  selectSelectedCommunityId,
  selectCurrentUserCommunityPermissions
} from '../community/reducers';
import {
  selectSelectedTemplate,
  selectCommunityTemplates,
  selectLeaderTemplates
} from './reducers';

function* getCommunityMissionTemplates({ communityId }) {
  try {
    const templatesInState = yield select(selectCommunityTemplates);
    if (!templatesInState.length) {
      yield put({
        type: templateTypes.COMMUNITY_MISSION_TEMPLATE_LOADING_STATE,
        state: true
      });
    }

    const {
      data: { results }
    } = yield call(templateActions.getCommunityMissionTemplates, communityId);
    yield put({
      type: templateTypes.GET_COMMUNITY_MISSION_TEMPLATES_SUCCESS,
      templates: results
    });

    yield put({
      type: templateTypes.COMMUNITY_MISSION_TEMPLATE_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: templateTypes.COMMUNITY_MISSION_TEMPLATE_LOADING_STATE,
      state: false
    });

    yield put({
      type: snackTypes.DANGER_SNACK,
      content:
        'There was an error fetching the Company Mission Templates. Please refresh the page'
    });
  }
}

function* getLeaderMissionTemplates() {
  try {
    const templatesInState = yield select(selectLeaderTemplates);
    if (!templatesInState.length) {
      yield put({
        type: templateTypes.LEADER_MISSION_TEMPLATE_LOADING_STATE,
        state: true
      });
    }

    const userId = yield select(selectLoggedInUserId);

    const {
      data: { results }
    } = yield call(templateActions.getLeaderMissionTemplates, userId);
    yield put({
      type: templateTypes.GET_LEADER_MISSION_TEMPLATES_SUCCESS,
      templates: results
    });

    yield put({
      type: templateTypes.LEADER_MISSION_TEMPLATE_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: templateTypes.LEADER_MISSION_TEMPLATE_LOADING_STATE,
      state: false
    });

    yield put({
      type: snackTypes.DANGER_SNACK,
      content:
        'There was an error fetching your Mission Templates. Please refresh the page.'
    });
  }
}

function* createMissionTemplate({ template, isCommunity = false }) {
  try {
    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: true
    });

    const communityId = yield select(selectSelectedCommunityId);

    const computedTemplate = isCommunity
      ? { community: communityId, ...template }
      : template;

    const { data } = yield call(
      templateActions.createMissionTemplate,
      computedTemplate
    );
    if (template.imageFile !== null && template.imageFile !== undefined ) {
      const formData = new FormData();
      formData.append('photo', template.imageFile);
      yield call(templateActions.uploadProfileImage, data.id, formData);

      const image = yield call(toBase64, template.imageFile);

      yield put({ type: templateTypes.STORE_PROFILE_IMAGE, templateId: data.id, image });
    }

    yield put({
      type: isCommunity
        ? templateTypes.CREATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS
        : templateTypes.CREATE_LEADER_MISSION_TEMPLATE_SUCCESS,
      template: data
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Mission template successfully created!',
      open: true,
      props: { variant: 'success' }
    });

    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });

    // Direct to edit view
    if (isCommunity)
      yield put(push(`/c/${communityId}/mission-template/${data.id}`));
  } catch (error) {
    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });

    // Open the error modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: templateTypes.CREATE_MISSION_TEMPLATE_ERROR
    });
  }
}

function* updateMissionTemplate({ template }) {
  try {
    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: true
    });

    const { _id: templateId, community } = yield select(selectSelectedTemplate);

    const isCommunityTemplate = community !== null;

    // Updating a community template requires community in body otherwise middleware rejects request
    const computedPayload = isCommunityTemplate
      ? { ...template, community }
      : template;

    const { data } = yield call(
      templateActions.updateMissionTemplate,
      templateId,
      computedPayload
    );

    yield put({
      type: isCommunityTemplate
        ? templateTypes.UPDATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS
        : templateTypes.UPDATE_LEADER_MISSION_TEMPLATE_SUCCESS,
      template: data
    });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission template successfully updated!'
    });

    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: templateTypes.MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });

    // Open the error modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: templateTypes.UPDATE_MISSION_TEMPLATE_ERROR
    });
  }
}

function* assignTemplates({ templates, members }) {
  try {
    yield put({
      type: templateTypes.ASSIGN_MISSION_TEMPLATE_SAVING_STATE,
      state: true
    });

    const communityId = yield select(selectSelectedCommunityId);
    const { data } = yield call(
      templateActions.assignMissionTemplates,
      templates,
      members,
      communityId
    );

    // Check for any errors and parse data
    const assignmentErrors = [];
    Object.keys(data).forEach(k => {
      const userObject = data[k];
      if (userObject.errors.length > 0) {
        assignmentErrors.push({
          userId: k,
          userName: userObject.memberName,
          errors: userObject.errors
        });
      }
    });

    if (assignmentErrors.length > 0) {
      // Display modal with errors
      yield put({
        type: modalTypes.MODAL_SET_COMPONENT,
        component: modalTypes.TEMPLATE_ASSIGNMENT_ERRORS_MODAL,
        open: true,
        props: {
          assignmentErrors
        }
      });
    } else {
      // All templates assigned successfully
      yield put({
        type: snackTypes.SUCCESS_SNACK,
        content: 'Mission templates successfully assigned'
      });
    }

    yield put({
      type: templateTypes.ASSIGN_MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: templateTypes.ASSIGN_MISSION_TEMPLATE_SAVING_STATE,
      state: false
    });

    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* deleteTemplate({ templateId, isCommunity = false }) {
  try {
    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const userId = yield select(selectLoggedInUserId);

    // No data returned on success
    yield call(
      templateActions.deleteMissionTemplate,
      isCommunity,
      templateId,
      communityId,
      userId
    );

    // Remove from current templates
    const successType = isCommunity
      ? templateTypes.DELETE_COMMUNITY_MISSION_TEMPLATE_SUCCESS
      : templateTypes.DELETE_LEADER_MISSION_TEMPLATE_SUCCESS;

    yield put({ type: successType, templateId });

    const currentPermissions = yield select(selectCurrentUserCommunityPermissions);
    const isLeader = currentPermissions.some(p => p === 'leader');

    // Redirect to the appropriate template list
    yield put(
      push(
        `/c/${communityId}/${isLeader ? 'mission-templates' : 'community-mission-templates'
        }`
      )
    );

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Template successfully archived'
    });

    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: false });
  } catch (error) {
    console.log('function*deleteTemplate -> error', error);
    yield put({
      type: snackTypes.DANGER_SNACK,
      content: 'There was a problem archiving the template. Please try again.'
    });
    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: false });
  }
}

function* getMissionTemplate({ templateId }) {
  try {
    const { data: template } = yield call(templateActions.getMissionTemplate, templateId);
    yield put({ type: templateTypes.GET_MISSION_TEMPLATE_SUCCESS, template });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getMissionByTemplate({ templateId }) {
  try {
    const { data: template } = yield call(templateActions.getMissionByTemplate, templateId);
    yield put({ type: templateTypes.GET_MISSION_BY_TEMPLATE_SUCCESS, template });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateProfileImage({ templateId, imageFile }) {
  try {
    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: true });
    // Check the keys exist in the payload (they wont from the profile screen)

    if (imageFile !== null && imageFile !== undefined) {
      const formData = new FormData();
      formData.append('photo', imageFile);
      yield call(templateActions.uploadProfileImage, templateId, formData);

      const image = yield call(toBase64, imageFile);

      yield put({ type: templateTypes.STORE_PROFILE_IMAGE, templateId, image });
    }

    // yield put({ type: templateTypes.UPDATE_OWN_PROFILE_SUCCESS });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Mission image has been updated',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: templateTypes.MISSION_TEMPLATE_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    // yield put({
    //   type: authTypes.UPDATE_OWN_PROFILE_ERROR,
    //   errors: {
    //     key: authTypes.UPDATE_OWN_PROFILE_ERROR,
    //     message: error.response.data.message,
    //     errors: error.response.data.errors
    //   }
    // });
  }
}

export default [
  takeLatest(
    templateTypes.GET_COMMUNITY_MISSION_TEMPLATES,
    getCommunityMissionTemplates
  ),
  takeLatest(templateTypes.CREATE_MISSION_TEMPLATE, createMissionTemplate),
  takeLatest(templateTypes.UPDATE_MISSION_TEMPLATE, updateMissionTemplate),
  takeLatest(templateTypes.GET_LEADER_MISSION_TEMPLATES, getLeaderMissionTemplates),
  takeLatest(templateTypes.ASSIGN_MISSION_TEMPLATES, assignTemplates),
  takeLatest(templateTypes.DELETE_MISSION_TEMPLATE, deleteTemplate),
  takeLatest(templateTypes.GET_MISSION_TEMPLATE, getMissionTemplate),
  takeLatest(templateTypes.GET_MISSION_BY_TEMPLATE, getMissionByTemplate),
  takeLatest(templateTypes.UPDATE_PROFILE_IMAGE, updateProfileImage)
];
