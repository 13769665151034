import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import {MenuItem} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch, useSelector} from 'react-redux';
import Fuse from 'fuse.js';
import ListFilter from '../ListFilter/ListFilter';
import Checkbox from '../Checkbox/Checkbox';
import * as teamTypes from '../../state/teams/types';
import {
  selectCommunityUsers,
  selectCommunityUsersLoadingState,
  selectSelectedCommunityId
} from '../../state/community/reducers';
import {selectTeams} from '../../state/teams/reducers';
import * as communityTypes from '../../state/community/types';
import Table from '../Table/Table';
import UserCard from '../RowCards/UserCard';
import * as toolkitTypes from '../../state/toolkits/types';

const styles = makeStyles({
  drawer: {
    width: '100%',
    maxWidth: 720,
    marginLeft: 80
  },
  gridContainer: {
    padding: 40
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 18,
    color: '#223f63'
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'start'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    width: 150
  },
  mb70: {
    marginBottom: 70
  },
  mb25: {
    marginBottom: 25
  },
  mb15: {
    marginBottom: 15
  },
  pr10: {
    paddingRight: 10
  },
  pl10: {
    paddingLeft: 10
  },
  inputRoot: {
    height: '100%',
    borderRadius: 10,
    backgroundColor: '#f4f4f4',
    border: 'none !important',
    '& svg': {
      marginRight: 10
    }
  },
  select: {
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: 10,
    padding: '10px 34px 10px 20px !important',
    border: 'solid 1px #dcdcdc',
    borderRadius: 10,
    '&:focus': {
      backgroundColor: '#f7f7f7 !important',
      border: 'solid 1px #223f63',
      borderRadius: '10px !important'
    },
    height: '60%'
  },
  menuItem: {
    fontSize: 12,
    padding: '12px 16px',
    fontWeight: 300,
    borderRadius: 15,
  },
});

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName', 'email']
};

export default function AssignUserDrawer({open, handleClose, selectedToolkits, onSubmit}) {
  const dispatch = useDispatch();
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const teams = useSelector(state => selectTeams(state));
  const users = useSelector(state => selectCommunityUsers(state));
  const communityUsersLoadingState = useSelector(state => selectCommunityUsersLoadingState(state));
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])
  const classes = styles();

  useEffect(() => {
    dispatch({
      type: teamTypes.GET_ALL_TEAMS,
      communityId
    });
    dispatch({
      type:  communityTypes.GET_COMMUNITY_USERS,
      communityId
    });
  }, [communityId]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedTeams(value)
  };

  const onSearchChange = e => {
    setSearch(e)
  };

  const onFilterChange = e => {
    const { value } = e.target;
    setFilter(value)
  };

  const searchUsers = u => {
    const fuseInstance = new Fuse(u, fuseOpts);
    return fuseInstance.search(search);
  };

  const onFilterUsers = searchResults => {
    return searchResults.filter(u => selectedTeams.some(t => u.teams.map(ut => ut.id).includes(t)))
  }

  const formControlClasses = clsx({
    [classes.w100]: true,
  });

  const hasSearch = search.trim().length > 0;
  const searchResults = hasSearch ? searchUsers(users) : users;
  const computedUsers = selectedTeams.length > 0 ? onFilterUsers(searchResults) : searchResults

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleClose}
      PaperProps={{ classes: { root: classes.drawer } }}
    >
      <Grid container className={classes.gridContainer}>
        <Grid item md={12} className={classes.flexStart} style={{marginBottom: '1rem'}}>
          <Typography className={classes.title}>Select users to assign</Typography>
        </Grid>
        <Grid item md={12}>
          <ListFilter
            search={search}
            filterOptions={[]}
            filter={filter}
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            onCreate={() => {
              dispatch({
                type: toolkitTypes.ASSIGN_TOOLKITS,
                toolkits: selectedToolkits,
                users: selectedUsers
              })

              setSearch('')
              setFilter('')
              setSelectedUsers([])
              setSelectedTeams([])

              onSubmit();
            }}
            createLabel={`Assign ${selectedUsers.length} users`}
            searchPlaceholder="Search all users..."
            filterByPlaceholder="Select users by team..."
            btnIcon={<AddIcon />}
            btnStyles={{justifyContent: 'inherit !important'}}
            customFilter={
              <Grid
                md={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 4
                }}
              >
                <FormControl className={formControlClasses}>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedTeams}
                    onChange={handleChange}
                    input={<InputBase classes={{ root: classes.inputRoot }} />}
                    classes={{ select: classes.select }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select teams...</em>;
                      }

                      return (
                        <Box style={{
                          // display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5
                        }}
                        >
                          {teams.map((value) => {
                            if (selected.includes(value.id)) {
                              return (
                                <Chip
                                  style={{fontSize: 12, display: 'inline-grid'}}
                                  key={value}
                                  label={value.title}
                                  // onDelete={() => {
                                  //   setSelectedTeams(selectedTeams.filter(st => st !== value.id))
                                  // }}
                                />
                              );
                            }

                          }).filter(Boolean)}
                        </Box>
                      );
                    }}
                  >
                    <MenuItem disabled value="" className={classes.menuItem}>
                      <em>Select teams...</em>
                    </MenuItem>
                    {teams.map(({title, id}) => (
                      <MenuItem
                        key={id}
                        value={id}
                        className={classes.menuItem}
                      >
                        <Checkbox checked={selectedTeams.includes(id)} />
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{
            marginBottom: 10,
            background: '#f7f7f7',
            borderRadius: 10,
            paddingLeft: '1rem'
          }}
        >
          <Checkbox
            checked={selectedTeams ?
              computedUsers.some(t => selectedUsers.includes(t.id)) :
              users.some(t => selectedUsers.includes(t.id))
            }
            label={`${selectedUsers.length} users selected`}
            onChange={() => {
              const filteredUsers = selectedTeams ? computedUsers : users;

              if (filteredUsers.some(t => selectedUsers.includes(t.id))) {
                setSelectedUsers([])
              } else {
                setSelectedUsers(filteredUsers.map(t => t.id))
              }
            }}
          />
        </Grid>
        <Grid item md={12}>
          <Table
            enableCheckbox
            isChecked={row => selectedUsers.includes(row.id)}
            onCheck={(row) => {
              if (selectedUsers.includes(row.id)) {
                setSelectedUsers(selectedUsers.filter(u => u !== row.id))
              } else {
                setSelectedUsers([...selectedUsers, row.id])
              }
            }}
            loading={communityUsersLoadingState}
            data={computedUsers}
            rowCard={UserCard}
            readOnly={false}
            noDataMessage={hasSearch ? 'No users found' :
            <>
              <p>No Users</p>
            </>}
          />
        </Grid>
      </Grid>
    </Drawer>
  )
}

AssignUserDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedToolkits: PropTypes.array.isRequired
};
