import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { debounce } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import SearchInput from '../SearchInput/SearchInput';
import Button from '../Button/Button';
import CommunityMissionsList from './CommunityMissionsList';

import * as communityMissionTypes from '../../state/communityMission/types';
import {
  selectCommunityMissions,
  selectCommunityMissionsLoading,
  selectCommunityMissionsPagination
} from '../../state/communityMission/reducers';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const initialQuery = {
  sort: { field: null, sort: null },
  page: 1,
  search: '',
  searchDebounced: ''
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  searchInput: {
    height: '100%'
  }
}));

const CommunityMissionsListView = ({ communityId, canCreateMission, onMissionClick }) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectCommunityMissionsLoading);
  const communityMissions = useSelector(selectCommunityMissions);
  const communityMissionsPagination = useSelector(selectCommunityMissionsPagination);
  const [queryFilter, setQueryFilter] = useReducer((state, newState) => ({ ...state, ...newState }), initialQuery);

  const debounceSearch = useCallback(
    debounce(q => setQueryFilter({ searchDebounced: q }), 500),
    []
  );

  const fetchData = () => {
    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSIONS,
      communityId,
      search: queryFilter.searchDebounced,
      page: queryFilter.page,
      orderBy: queryFilter.sort.field,
      order: queryFilter.sort.sort
    });
  };

  // Events
  const handleSortChange = params => {
    const paramsModel = Array.isArray(params.sortModel) ? params.sortModel[0] : params.sortModel;

    if (paramsModel !== queryFilter.sort) {
      setQueryFilter({ sort: paramsModel });
    }
  };

  const handlePageChange = value => {
    setQueryFilter({ page: value });
  };

  const handleCreateCommunityMission = () => {
    history.push(`/c/${communityId}/m/create`);
  };

  // Effects
  useEffect(() => {
    fetchData();
  }, []);

  useUpdateEffect(() => {
    fetchData();
  }, [queryFilter.page]);

  useUpdateEffect(() => {
    if (queryFilter.page === 1) {
      fetchData();
    } else {
      setQueryFilter({ page: 1 });
    }
  }, [queryFilter.sort.field, queryFilter.sort.sort, queryFilter.searchDebounced]);

  return (
    <Box>
      <Grid container justify="space-between" spacing={1} className={classes.root}>
        <Grid item xs={12} lg={5}>
          <SearchInput
            value={queryFilter.search}
            onChange={e => {
              setQueryFilter({ search: e });
              debounceSearch(e);
            }}
            showFocus={false}
            className={classes.searchInput}
            placeholder="Search"
          />
        </Grid>
        {canCreateMission && (
          <Grid item xs={12} lg={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button label="CREATE COMMUNITY MISSION" onClick={handleCreateCommunityMission} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Paper elevation={6}>
        <CommunityMissionsList
          loading={loading}
          communityMissions={communityMissions}
          pagination={communityMissionsPagination}
          sortModel={queryFilter.sort}
          onSortChange={handleSortChange}
          onPageChange={handlePageChange}
          onRowClick={onMissionClick}
        />
      </Paper>
    </Box>
  );
};

CommunityMissionsListView.propTypes = {
  communityId: PropTypes.string.isRequired,
  canCreateMission: PropTypes.bool,
  onMissionClick: PropTypes.func
};

CommunityMissionsListView.defaultProps = {
  canCreateMission: false,
  onMissionClick: null
};

export default CommunityMissionsListView;
