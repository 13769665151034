import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  circleNumber: {
    height: 60,
    width: 60,
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10,
    fontSize: 39,
    textAlign: 'center'
  },
  helperText: {
    fontSize: 14,
    maxWidth: 280,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    textAlign: 'center'
  }
});

const HelperContent = ({ content }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography className={classes.helperText}>{content}</Typography>
    </Box>
  );
};

HelperContent.propTypes = {
  content: PropTypes.string.isRequired
};

export default HelperContent;
