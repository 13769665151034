import * as types from './types';
import * as authTypes from '../auth/types';
import * as communityTypes from '../community/types';

const initialState = {
  communityTemplateLoading: false,
  leaderTemplateLoading: false,
  templateSaving: false,
  communityTemplates: [],
  leaderTemplates: [],
  profileImages: [],
  selectedTemplate: null,
  selectedMissionTemplate: null,
  assignSaving: false
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMMUNITY_MISSION_TEMPLATE_LOADING_STATE:
      return {
        ...state,
        communityTemplateLoading: action.state
      };

    case types.MISSION_TEMPLATE_SAVING_STATE:
      return {
        ...state,
        templateSaving: action.state
      };

    case types.LEADER_MISSION_TEMPLATE_LOADING_STATE:
      return {
        ...state,
        leaderTemplateLoading: action.state
      };

    case types.GET_COMMUNITY_MISSION_TEMPLATES_SUCCESS:
      return {
        ...state,
        communityTemplates: action.templates
      };
    case types.STORE_PROFILE_IMAGE:
      return {
        ...state,
        profileImages: [
          ...state.profileImages.filter(template => template.id !== action.templateId),
          { templateId: action.templateId, image: action.image }
        ]
      };
    case types.GET_LEADER_MISSION_TEMPLATES_SUCCESS:
      return {
        ...state,
        leaderTemplates: action.templates
      };

    case types.CREATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        communityTemplates: [...state.communityTemplates, action.template],
        selectedTemplate: action.template
      };

    case types.CREATE_LEADER_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        leaderTemplates: [...state.leaderTemplates, action.template],
        selectedTemplate: action.template
      };

    case types.UPDATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedTemplate: action.template,
        communityTemplates: state.communityTemplates.map(t => {
          if (t._id === action.template.id) return action.template;
          return t;
        })
      };

    case types.UPDATE_LEADER_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedTemplate: action.template,
        leaderTemplates: state.leaderTemplates.map(t => {
          if (t._id === action.template.id) return action.template;
          return t;
        })
      };

    case types.SET_MISSION_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.template
      };

    case types.CLEAR_SELECTED_MISSION_TEMPLATE:
      return {
        ...state,
        selectedTemplate: null
      };

    case types.ASSIGN_MISSION_TEMPLATE_SAVING_STATE:
      return {
        ...state,
        assignSaving: action.state
      };

    case types.DELETE_LEADER_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        leadertemplates: state.leaderTemplates.filter(
          t => t.id !== action.templateId
        )
      };

    case types.DELETE_COMMUNITY_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        communityTemplates: state.communityTemplates.filter(
          t => t.id !== action.templateId
        )
      };

    case types.GET_MISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedTemplate: action.template
      };
    case types.GET_MISSION_BY_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedMissionTemplate: action.template
      };

    case communityTypes.GET_COMMUNITY:
      return {
        ...state,
        communityTemplates: [],
        selectedTemplate: null
      };

    case authTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        leaderTemplates: [],
        communityTemplates: [],
        selectedTemplate: null
      };

    default:
      return state;
  }
};

export default templatesReducer;

export const selectCommunityTemplateLoading = state =>
  state.templates.communityTemplateLoading;
export const selectMissionTemplateSaving = state => state.templates.templateSaving;
export const selectCommunityTemplates = state => state.templates.communityTemplates;
export const selectSelectedProfileImagesCommunityTemplates = state => state.templates.profileImages;
export const selectSelectedTemplate = state => state.templates.selectedTemplate;
export const selectSelectedMissionTemplate = state => state.templates.selectedMissionTemplate;
export const selectLeaderTemplateLoading = state =>
  state.templates.leaderTemplateLoading;
export const selectLeaderTemplates = state => state.templates.leaderTemplates;
export const selectAssignSaving = state => state.templates.assignSaving;
