import axios from 'axios';

export const getCommunityMissions = async (
  communityId,
  userId = null,
  seearch,
  limit = 10,
  skip = 0,
  orderBy,
  order
) => {
  const query = [
    !!userId && `userUuid=${userId}`,
    `limit=${limit}`,
    `skip=${skip}`,
    !!orderBy && `sort=${orderBy}`,
    !!order && `order=${order}`
  ].filter(Boolean);

  const result = await axios.get(`/v1/communities/${communityId}/missions?${query.join('&')}`);

  return result.data;
};

export const getCommunityMission = async (communityId, missionId) => {
  const result = await axios.get(`/v1/communities/${communityId}/missions/${missionId}`);

  return result.data;
};

export const createCommunityMission = async (communityId, title, description, icon, leader, teams, endDate) => {
  const result = await axios.post(`/v1/communities/${communityId}/missions`, {
    title,
    description,
    icon,
    leader,
    teams,
    endDate
  });
  return result.data;
};

export const updateCommunityMission = async (
  communityId,
  missionId,
  title,
  description,
  icon,
  leader,
  teams,
  endDate
) => {
  const result = await axios.put(`/v1/communities/${communityId}/missions/${missionId}`, {
    ...(title && { title }),
    ...(description && { description }),
    ...(icon && { icon }),
    leader,
    ...(teams && { teams }),
    ...(endDate && { endDate })
  });

  return result.data;
};

export const patchCommunityMissionStatus = async (communityId, missionId, status) => {
  const result = await axios.patch(`/v1/communities/${communityId}/missions/${missionId}/status`, { status });

  return result.data;
};

export const archiveCommunityMission = async (communityId, missionId) => {
  const result = await axios.delete(`/v1/communities/${communityId}/missions/${missionId}`);
  return result.data;
};

export const getCommunityMissionObjectives = async (
  communityId,
  userId,
  missionId,
  term,
  status,
  limit,
  skip,
  orderBy,
  order
) => {
  try {
    const query = [
      !!userId && `userUuid=${userId}`,
      !!missionId && `missionUuid=${missionId}`,
      !!status && `status=${status}`,
      !!term && `term=${term}`,
      `limit=${limit}`,
      `skip=${skip}`,
      !!orderBy && `sort=${orderBy}`,
      !!order && `order=${order}`
    ].filter(Boolean);

    const result = await axios.get(`/v1/communities/${communityId}/objectives?${query.join('&')}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCommunityMissionObjective = async (communityId, objectiveId) => {
  const result = await axios.get(`/v1/communities/${communityId}/objectives/${objectiveId}`);

  return result.data;
};

export const createCommunityMissionObjective = async (communityId, objective) => {
  const result = await axios.post(`/v1/communities/${communityId}/objectives`, objective);
  return result.data;
};

export const getCommunityMissionObjectiveSystemComments = async (communityId, objectiveId) => {
  const result = await axios.get(`/v1/communities/${communityId}/objectives/${objectiveId}/activities`);
  return result.data;
};

export const updateCommunityMissionObjective = async (
  communityId,
  objectiveId,
  { statement, startDate, user, title, tasks, practices }
) => {
  const result = await axios.put(`/v1/communities/${communityId}/objectives/${objectiveId}`, {
    statement,
    startDate,
    user,
    title,
    tasks,
    practices
  });

  return result.data;
};

export const patchCommunityMissionObjectiveStatus = async (communityId, objectiveId, status) => {
  const result = await axios.patch(`/v1/communities/${communityId}/objectives/${objectiveId}/status`, { status });

  return result.data;
};

export const archiveCommunityMissionObjective = async (communityId, objectiveId) => {
  const result = await axios.delete(`/v1/communities/${communityId}/objectives/${objectiveId}`);

  return result.data;
};

export const createCommunityMissionObjectivePractice = async (
  communityId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency,
  objective
) => {
  const result = await axios.post(`/v1/communities/${communityId}/practices`, {
    title,
    description,
    richDescription,
    countPerWeek,
    reminderFrequency,
    objective
  });
  return result.data;
};

export const updateCommunityMissionObjectivePractice = async (
  communityId,
  practiceId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency
) => {
  const result = await axios.put(`/v1/communities/${communityId}/practices/${practiceId}`, {
    title,
    description,
    richDescription,
    countPerWeek,
    reminderFrequency
  });
  return result.data;
};

export const deleteCommunityMissionObjectivePractice = async (communityId, practiceId) => {
  const result = await axios.delete(`/v1/communities/${communityId}/practices/${practiceId}`);
  return result.data;
};

// Tasks

export const createCommunityTask = async (communityId, objectiveId, payload) => {
  const { task, description, richDescription, dueDate } = payload
  const result = await axios.post(`/v1/communities/${communityId}/tasks`, {
    task,
    description,
    richDescription,
    dueDate,
    objective: objectiveId
  });
  return result.data;
};

export const updateCommunityTask = async (communityId, objectiveId, payload) => {
  const { task, description, richDescription, dueDate, _id } = payload
  const result = await axios.put(`/v1/communities/${communityId}/tasks/${_id}`, {
    task,
    description,
    richDescription,
    dueDate
    //    objective: objectiveId
  });
  return result.data;
};

export const patchCommunityMissionObjectiveTaskStatus = async (communityId, taskId, completed) => {
  const result = await axios.patch(`/v1/communities/${communityId}/tasks/${taskId}/status`, { completed });

  return result.data;
};

export const deleteCommunityMissionObjectiveTask = async (communityId, taskId) => {
  const result = await axios.delete(`/v1/communities/${communityId}/tasks/${taskId}`);

  return result.data;
};
