import axios from 'axios';

const API_V2_BASE_URL =
  process.env.NODE_ENV === 'production' ? window.__env__.REACT_APP_V2_BASE_URL : process.env.REACT_APP_V2_BASE_URL;

export const createToolkit = async toolkit => {
  try {
    const result = await axios.post('/v1/toolkits', toolkit, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editToolkit = async (toolkitId, toolkit) => {
  try {
    const result = await axios.put(`/v1/toolkits/${toolkitId}`, toolkit, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getToolkits = async () => {
  try {
    const result = await axios.get('/v1/toolkits?plain=true');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserToolkits = async userId => {
  try {
    const result = await axios.get(`/v1/toolkits/user/${userId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getToolkit = async toolkitId => {
  try {
    const result = await axios.get(`/v1/toolkits/${toolkitId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const assignToolkits = async (toolkits, users) => {
  try {
    const result = await axios.put('/v1/toolkits/assign', { toolkits, users });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeToolkitFromUser = async (toolkitId, userId) => {
  try {
    const result = await axios.put(`/v1/toolkits/${toolkitId}/remove/user/${userId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteToolkit = async toolkitId => {
  try {
    const result = await axios.delete(`/v1/toolkits/${toolkitId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};
