import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { debounce } from 'lodash';

import Datepicker from '../Datepicker/Datepicker';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';

import { ensurePositiveNumber, getSlateValueFromTaskAndPractice, initialSlateValue, randomId } from '../../utils/helpers';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const useStyles = makeStyles({
  wrap: {
    padding: '20px 0px'
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 10
  },
  addButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    width: '100%'
  },
  copy: {
    fontSize: 11,
    fontWeight: 500
  },
  backdropRoot: {
    zIndex: 1
  },
  contentWrap: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    maxWidth: 640,
    padding: 20
  },
  inputWrap: {
    display: 'flex',
    marginBottom: 26
  },
  taskName: {
    marginBottom: 16
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  },
  cancel: {
    marginRight: 8
  },
  descriptionLabel: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    paddingLeft: 12,
    fontSize: 10,
    marginBottom: 12,
  },
  richDescriptionContainer: {
    marginLeft: 8,
    padding: '20px 20px 32px 20px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: 10,
  },
});

const TaskInput = ({ onCreate, isActive, objectiveDueDate }) => {
  // Hooks
  const classes = useStyles();
  const containerRef = useRef();

  // isActive denotes if the current objective is for a template or a one off mission
  const initialInactiveState = {
    task: '',
    description: '',
    daysDue: ''
  };

  const initialActiveState = {
    task: '',
    description: '',
    dueDate: moment(objectiveDueDate)
  };

  // Local state
  const [open, setOpen] = useState(false);
  const [taskDetails, setTaskDetails] = useState(isActive ? initialActiveState : initialInactiveState);

  // Objective active state controls the task payload
  useEffect(() => {
    if (objectiveDueDate && isActive) setTaskDetails(initialActiveState);
    else if (!isActive) setTaskDetails(initialInactiveState);
  }, [objectiveDueDate, isActive]);

  // Event handlers
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
    setTaskDetails(isActive ? initialActiveState : initialInactiveState);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    const isNumeric = name === 'daysDue';

    setTaskDetails({
      ...taskDetails,
      [name]: isNumeric ? ensurePositiveNumber(value) : value
    });
  };

  const handleDueDateChange = date => {
    setTaskDetails({
      ...taskDetails,
      dueDate: date
    });
  };

  const handleCreate = e => {
    // Ensure a default of zero days
    const task = isActive ? taskDetails : { ...taskDetails, daysDue: taskDetails.daysDue || 0 };

    // onCreate({ id: randomId(), ...task });
    onCreate({ ...task });
    handleClose(e);
  };

  // Helpers
  const getPopupPostion = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getClientRects()[0];
      return {
        position: 'absolute',
        // top: rect.top,
        left: rect.left,
        width: rect.width
      };
    }
    return null;
  };

  // Constants
  const { task, daysDue, dueDate, description } = taskDetails;
  const complete = Boolean(task);

  // const initialValue = initialSlateValue(taskDetails.description)

  // if(typeof taskDetails.richDescription === 'string') {
  //   taskDetails.richDescription = JSON.parse(taskDetails.richDescription)
  // }
  // const initialDescription = !taskDetails.richDescription && taskDetails.description ?
  //   initialValue: taskDetails.richDescription;
  const initialDescription = getSlateValueFromTaskAndPractice(taskDetails.description, taskDetails.richDescription)

  return (
    <>
      <Box className={classes.wrap}>
        <Typography className={classes.title}>Actions</Typography>
        <div
          ref={containerRef}
          className={classes.addButton}
          onClick={handleClick}
          role="presentation"
          data-test-id="button-objective-add-task"
        >
          <Typography className={classes.copy}>
            <AddIcon />
            Add an action
          </Typography>
        </div>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { ...getPopupPostion(), margin: 0 },
          className: classes.contentWrap
        }}
      >
        <Box>
          <Box className={classes.inputWrap}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <div>
                  <TextInput
                    name="task"
                    label="Action title"
                    placeholder="Write the action here…"
                    value={task}
                    onChange={handleChange}
                    whiteInput
                    required
                    className={classes.taskName}
                  />
                  {/* <TextInput
                    name="description"
                    label="Action description"
                    placeholder="Describe the action…"
                    value={description}
                    onChange={handleChange}
                    whiteInput
                  /> */}
                  <Grid item xs={12}>
                    <div>
                      <MuiInputLabel
                        shrink={false}
                        label="Action Description"
                        classes={{
                          root: classes.descriptionLabel,
                        }}
                      >
                        Action Description
                      </MuiInputLabel>
                      <div className={classes.richDescriptionContainer}>
                        <RichTextEditor
                          defaultValue={initialDescription}
                          name="content"
                          minHeight={100}
                          onlyLink
                          onChange={debounce(content => {
                            const target = {
                              target: {
                                name: 'richDescription',
                                value: content
                              }
                            }
                            handleChange(target)
                          }, 500)}
                        />
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={5}>
                {isActive ? (
                  <Datepicker chevron label="Due date" onChange={handleDueDateChange} value={dueDate} whiteInput />
                ) : (
                  <TextInput
                    name="daysDue"
                    label="Days due after the Objective starts"
                    value={daysDue}
                    whiteInput
                    onChange={handleChange}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.buttonWrap}>
            <Button
              data-test-id="button-objective-task-cancel"
              background="grey"
              label="Cancel"
              onClick={handleClose}
              className={clsx(classes.btn, classes.cancel)}
            />
            <Button
              label="Create"
              onClick={handleCreate}
              disabled={!complete}
              className={classes.btn}
              data-test-id="button-objective-task-create"
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

TaskInput.propTypes = {
  onCreate: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  objectiveDueDate: PropTypes.instanceOf(moment).isRequired
};

TaskInput.defaultProps = {
  isActive: false
};

export default TaskInput;
