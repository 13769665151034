import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TemplateCardsLoading from './TemplateCardsLoading';
import TemplateCard from './TemplateCard';

const useStyles = makeStyles({
  container: {
    padding: 5,
    borderRadius: 10,
    backgroundColor: '#dcdcdc',
    maxHeight: 700,
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f7f7f7 !important'
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      height: 174,
      borderRadius: 15,
      backgroundColor: '#a5a5a5'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))'
  },
  noTemplates: {
    textAlign: 'center',
    margin: 32
  }
});

const TemplateList = ({
  templates,
  hasSearch,
  loading,
  onClick,
  editTemplate,
  selectedTemplates,
  loadingCards,
  noDataMessage,
  readOnly
}) => {
  // Hooks
  const classes = useStyles();

  // Event handlers
  const handleClick = template => {
    if (onClick) onClick(template);
  };

  const handleEdit = template => {
    if (editTemplate) editTemplate(template);
  };

  // Constants
  const hasTemplates = templates.length > 0;

  const containerClass = clsx({
    [classes.container]: true,
    [classes.grid]: hasTemplates || loading
  });

  return (
    <Box className={containerClass}>
      {loading && <TemplateCardsLoading number={loadingCards} />}
      {hasTemplates &&
        !loading &&
        templates.map((template, idx) => {
          const { title, description, icon, objectives, id } = template;
          const tasks = [];
          objectives.forEach(o => {
            o.tasks.forEach(t => tasks.push(t));
          });

          const selected = selectedTemplates.some(s => s.id === template.id);

          return (
            <TemplateCard
              key={`template-${idx}`}
              icon={icon}
              title={title}
              templateId={id}
              description={description}
              objectives={objectives}
              tasks={tasks}
              onClick={() => handleClick(template)}
              onEdit={() => handleEdit(template)}
              selected={selected}
              readOnly={readOnly}
            />
          );
        })}
      {!hasTemplates && !loading && (
        <Typography className={classes.noTemplates}>
          {hasSearch ? 'No results found' : noDataMessage}
        </Typography>
      )}
    </Box>
  );
};

TemplateList.propTypes = {
  templates: PropTypes.array,
  hasSearch: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  editTemplate: PropTypes.func,
  selectedTemplates: PropTypes.array,
  loadingCards: PropTypes.number,
  noDataMessage: PropTypes.string,
  readOnly: PropTypes.bool
};

TemplateList.defaultProps = {
  templates: [],
  onClick: null,
  editTemplate: null,
  selectedTemplates: [],
  loadingCards: 30,
  noDataMessage: 'There are currently no templates',
  readOnly: false
};

export default TemplateList;
