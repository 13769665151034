import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchInput from '../SearchInput/SearchInput';
import ProfileImage from '../ProfileImage/ProfileImage';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName', 'email']
};

const styles = makeStyles({
  assignLeaderButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    width: '100%'
  },
  assignLeaderTypography: {
    fontSize: 11,
    fontWeight: 500
  },
  backdropRoot: {
    zIndex: 1
  },
  listWrap: {
    position: 'relative',
    maxHeight: 400,
    width: '100%'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    padding: '9px 20px 7px 20px',
    borderRadius: 10,
    height: 60,
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
    width: '100%'
  },
  searchContainerTop: {
    position: 'absolute',
    bottom: 0
  },
  leaderList: {
    padding: '0px 10px',
    borderRadius: 10,
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    maxHeight: 330,
    overflow: 'hidden',
    width: '100%'
  },
  leaderListTop: {
    position: 'absolute',
    bottom: 70
  },
  leaderListBottom: {
    position: 'absolute',
    top: 70
  },
  listInner: {
    maxHeight: 330,
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f7f7f7 !important'
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      height: 174,
      borderRadius: 15,
      backgroundColor: '#a5a5a5'
    }
  },
  listInnerPadding: {
    paddingRight: 10
  },
  noAvailable: {
    padding: 10,
    fontWeight: 600
  },
  userCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    padding: 10,
    marginTop: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dcdcdc'
    },
    '&:last-child': {
      marginBottom: 10
    }
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  userName: {
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 500,
    flex: 1
  }
});

export default function UserDropdown({
  readOnly,
  users,
  buttonText,
  placeholder,
  noDataMessage,
  onSelect,
  saving,
  menuPosition,
  userIdsPendingChange
}) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  function handleClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  function handleChange(e) {
    setSearch(e);
  }

  function getSearchPosition(anchorTop) {
    if (anchorEl) {
      const rect = anchorEl.getClientRects()[0];
      return {
        position: 'absolute',

        left: rect.left,
        width: rect.width
      };
    }
    return null;
  }

  function searchUsers(data) {
    const fuseInstance = new Fuse(data, fuseOpts);
    return fuseInstance.search(search);
  }

  const open = Boolean(anchorEl);

  const anchorTop = menuPosition !== 'bottom';

  const hasSearch = search.trim().length > 0;
  const computedUsers = hasSearch ? searchUsers(users) : users;

  const hasComputedUsers = computedUsers.length > 0;

  const computedNoDataMsg = hasSearch ? 'No results found' : noDataMessage;

  return (
    <>
      {!readOnly && open && (
        <Backdrop open={open} className={classes.backdropRoot}>
          <ClickAwayListener onClickAway={handleClose}>
            <div style={{ ...getSearchPosition(anchorTop) }}>
              <div className={classes.listWrap}>
                <div
                  className={clsx({
                    [classes.searchContainer]: true,
                    [classes.searchContainerTop]: anchorTop
                  })}
                >
                  <SearchInput
                    data-test-id="input-member-search"
                    value={search}
                    onChange={handleChange}
                    showFocus={false}
                    placeholder={placeholder}
                    style={{ backgroundColor: '#ffffff' }}
                  />
                  <IconButton size="small" onClick={handleClose} data-test-id="button-member-search-close">
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  className={clsx({
                    [classes.leaderList]: true,
                    [classes.leaderListTop]: anchorTop,
                    [classes.leaderListBottom]: !anchorTop
                  })}
                >
                  <div
                    className={clsx({
                      [classes.listInner]: true,
                      [classes.listInnerPadding]: hasComputedUsers
                    })}
                  >
                    {hasComputedUsers ? (
                      computedUsers.map(user => {
                        const pending = userIdsPendingChange.some(id => user._id === id);
                        return (
                          <div
                            key={user._id}
                            className={classes.userCard}
                            data-test-id="member-row"
                            onClick={saving ? null : () => onSelect(user)}
                            role="presentation"
                          >
                            <div className={classes.userDetails}>
                              <ProfileImage userId={user._id} />
                              <Typography className={classes.userName}>
                                {`${user.firstName} ${user.lastName}`}
                              </Typography>
                              {pending && <CircularProgress />}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Typography align="center" className={classes.noAvailable}>
                        {computedNoDataMsg}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </Backdrop>
      )}
      <div
        data-test-id="button-member-add"
        className={classes.assignLeaderButton}
        onClick={handleClick}
        role="presentation"
      >
        <Typography className={classes.assignLeaderTypography}>
          <AddIcon />
          {buttonText}
        </Typography>
      </div>
    </>
  );
}

UserDropdown.propTypes = {
  users: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  noDataMessage: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  menuPosition: PropTypes.string,
  saving: PropTypes.bool,
  userIdsPendingChange: PropTypes.array
};

UserDropdown.defaultProps = {
  readOnly: false,
  buttonText: 'Select a user',
  placeholder: 'Search and/or select a member...',
  noDataMessage: 'Could not find any members!',
  menuPosition: 'bottom',
  saving: false,
  userIdsPendingChange: []
};
