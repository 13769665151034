import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PageHeader from '../PageHeader/PageHeader';
import { selectChatEnabled } from '../../state/chat/reducers';

export default function PageContainer({ children, routeTitle, child, showHeader }) {
  const chatEnabled = useSelector(selectChatEnabled);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: 'white',
        // backgroundColor: ' #e4e4e4',
        minHeight: '100vh',
        padding: '0px 40px 40px 40px'
        // maxWidth: `calc(100% - ${chatEnabled ? 400 : 200}px)`
      }}
    >
      {showHeader && <PageHeader routeTitle={routeTitle} showSelectedUser={child} />}
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  routeTitle: PropTypes.string.isRequired,
  child: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired
};
