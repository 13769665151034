import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import TemplateSearch from '../MissionTemplates/TemplateSearch';
import TemplateList from '../MissionTemplates/TemplateList';

const TemplateGroup = ({
  heading,
  templates,
  onClick,
  onEdit,
  search,
  onChange,
  onAdd,
  loading,
  name,
  selectedTemplates,
  readOnly
}) => {
  // Event handlers
  const handleSearchChange = value => {
    onChange(name, value);
  };

  // Constants
  const hasSearch = search.trim().length > 0;

  return (
    <Box>
      <TemplateSearch
        heading={heading}
        value={search}
        onChange={handleSearchChange}
        onAdd={onAdd}
        goldButton
        readOnly={readOnly}
      />
      <TemplateList
        selectedTemplates={selectedTemplates}
        templates={templates}
        hasSearch={hasSearch}
        loading={loading}
        onClick={onClick}
        editTemplate={onEdit}
        loadingCards={6}
        readOnly={readOnly}
      />
    </Box>
  );
};

TemplateGroup.propTypes = {
  heading: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  selectedTemplates: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
};

TemplateGroup.defaultProps = {
  onAdd: null,
  readOnly: false
};

export default TemplateGroup;
