import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import MissionIcon from '../MissionIcon/MissionIcon';

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    margin: 5,
    padding: 8,
    top: 0,
    transition: 'all 0.2s ease-in-out',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    minHeight: 140
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    outline: 'none !important',
    maxWidth: 28,
    maxHeight: 29,
    borderRadius: 10,
    padding: 4
  },
  title: {
    fontSize: 14,
    marginBottom: 8,
    userSelect: 'none',
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  }
});

const SelectedTemplateCard = ({ title, templateId, icon, onRemove }) => {
  // Classes
  const classes = useStyles();

  const handleRemove = e => {
    e.stopPropagation();
    onRemove();
  };

  const wrapClass = clsx({
    [classes.paper]: true,
    [classes.visible]: true
  });

  return (
    <Paper className={wrapClass}>
      <IconButton className={classes.iconButton} onClick={handleRemove}>
        <CloseRoundedIcon />
      </IconButton>
      <Box
        display="flex"
        justifyContent="center"
        paddingTop={1.5}
        paddingBottom={1.5}
      >
        <MissionIcon icon={icon} id={templateId} isTemplate />
      </Box>
      <Typography align="center" noWrap className={classes.title}>
        {title}
      </Typography>
    </Paper>
  );
};

SelectedTemplateCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired
};

export default SelectedTemplateCard;
