import React from 'react';

// Need this to achieve the gradient on the svg icons!
export default function GradientDefinition() {
  return (
    <div style={{ display: 'flex' }}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="lgrad" x1="100%" y1="100%" x2="0%" y2="0%">
            <stop offset="30%" style={{ stopColor: '#f5bd56', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#a5721a', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
