import { put, takeLatest, select, take } from 'redux-saga/effects';
import * as bootstrapTypes from './types';
import * as authTypes from '../auth/types';
import * as communityTypes from '../community/types';

import { selectAllCommunities } from '../community/reducers';
import { selectLoggedInUserId, selectLoggedInUser } from '../auth/reducers';

import {
  checkForPermissions,
  getPermissions,
  communityWithPermissions
} from '../../utils/helpers';

function* bootstrap() {
  try {
    yield put({ type: authTypes.AUTH_REFRESH });
    yield take(authTypes.AUTH_REFRESH_DONE);
    const userId = yield select(selectLoggedInUserId);
    if (userId) {
      yield put({ type: authTypes.GET_LOGGED_IN_USER, userId });
      const { user } = yield take(authTypes.GET_LOGGED_IN_USER_SUCCESS);

      // If officer - get all communities
      const { roles } = user;
      const isOfficer = roles.includes('officer');
      if (isOfficer) {
        yield put({ type: communityTypes.GET_ALL_COMMUNITIES });
        yield take(communityTypes.GET_ALL_COMMUNITIES_SUCCESS);
      }

      // Needs to set to a community that the user has permissions in
      let community = communityWithPermissions(user) || user.communities[0] || null;
      if (isOfficer && !community) {
        const allCommunities = yield select(selectAllCommunities);
        community = allCommunities.length > 0 ? allCommunities[0] : null;
      }

      if (community) {
        yield put({ type: communityTypes.SET_SELECTED_COMMUNITY, community });

        // Get the users members
        yield put({
          type: communityTypes.GET_LEADER_USERS,
          userId,
          communityId: community._id
        });

        // Check if the user has permissions for the selected community
        const { memberships } = yield select(selectLoggedInUser);
        const hasPermissions = checkForPermissions(memberships, community._id);
        if (hasPermissions) {
          const permissions = getPermissions(memberships, community._id);
          yield put({ type: communityTypes.SET_COMMUNITY_PERMISSIONS, permissions });
          yield take(communityTypes.SET_COMMUNITY_PERMISSIONS_SUCCESS);
        }
      }
    }
  } catch (error) {
    yield put({ type: bootstrapTypes.BOOTSTRAP_ERROR, error });
  } finally {
    yield put({ type: bootstrapTypes.BOOTSTRAP_COMPLETE });
  }
}

export default [takeLatest(bootstrapTypes.BOOTSTRAP, bootstrap)];
