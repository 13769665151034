import * as types from './types';
import * as authTypes from '../auth/types';

const initialState = {
  loadingState: false,
  savingState: false,
  selectedUser: null,
  userCreated: false,
  apiErrors: null,
  userLeaderSaveState: false,
  selectedUserLeaders: [],
  emailLoading: false,
  emailSaving: false,
  selectedUserEmailChange: null,
  userActivity: [],
  activityLoading: false,
  profileImages: [],
  userLifeEvents: [],
  lifeEventsLoading: false,
  userActivities: [],
  selectedUserAccountabilityScores: [],
  selectedUserCurrentAccountabilityScore: null,
  accountabilityScoreLoading: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.USER_SAVING_STATE:
      return {
        ...state,
        savingState: action.state
      };

    case authTypes.UPDATE_OWN_PROFILE_SUCCESS:
      return {
        ...state,
        selectedUser: action.user
      };

    case types.UPDATE_USER_SUCCESS:
    case types.SET_SELECTED_USER:
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.user,
        selectedUserLeaders: action.leaders,
        apiErrors: null
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        userCreated: true,
        apiErrors: null
      };

    case types.CLEAR_USER_CREATED:
      return {
        ...state,
        userCreated: false
      };

    case types.REQUEST_EMAIL_CHANGE_ERROR:
    case types.ADD_LEADER_TO_USER_ERROR:
    case types.REMOVE_LEADER_FROM_USER_ERROR:
    case types.UPDATE_USER_ERROR:
    case types.RESEND_INVITATION_ERROR:
    case types.CREATE_USER_ERROR:
      return {
        ...state,
        apiErrors: action.errors
      };

    case types.USER_LEADER_SAVING_STATE:
      return {
        ...state,
        userLeaderSaveState: action.state
      };

    case types.REMOVE_LEADER_FROM_USER_SUCCESS:
    case types.ADD_LEADER_TO_USER_SUCCESS:
      return {
        ...state,
        selectedUserLeaders: action.leaders
      };

    case authTypes.GET_OWN_EMAIL_CHANGE_REQUEST_SUCCESS:
    case authTypes.REQUEST_CHANGE_OWN_EMAIL_SUCCESS:
    case types.REQUEST_EMAIL_CHANGE_SUCCESS:
    case types.GET_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        selectedUserEmailChange: action.emailChange
      };

    case authTypes.CANCEL_OWN_EMAIL_CHANGE_SUCCESS:
    case types.CANCEL_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        selectedUserEmailChange: null
      };

    case types.EMAIL_CHANGE_LOADING_STATE:
      return {
        ...state,
        emailLoading: action.state
      };

    case types.EMAIL_CHANGE_SAVING_STATE:
      return {
        ...state,
        emailSaving: action.state
      };

    case types.USER_ACTIVITY_LOADING_STATE:
      return {
        ...state,
        activityLoading: action.state
      };

    case types.GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userActivities: action.userActivities // All data manipulation done in saga
      };

    case types.STORE_PROFILE_IMAGE:
      return {
        ...state,
        profileImages: [
          ...state.profileImages.filter(user => user.userId !== action.userId),
          { userId: action.userId, image: action.image }
        ]
      };

    case types.GET_USER_LIFE_EVENTS_SUCCESS:
      return {
        ...state,
        userLifeEvents: action.lifeEvents
      };

    case types.LIFE_EVENTS_LOADING_STATE:
      return {
        ...state,
        lifeEventsLoading: action.state
      };

    case types.GET_USER_ACCOUNTABILITY_SCORE_SUCCESS:
      return {
        ...state,
        selectedUserAccountabilityScores: action.accountabilityScores
      };

    case types.USER_ACCOUNTABILITY_SCORE_LOADING_STATE:
      return {
        ...state,
        accountabilityScoreLoading: action.state
      };

    case types.GET_USER_CURRENT_ACCOUNTABILITY_SCORE_SUCCESS:
      return {
        ...state,
        selectedUserCurrentAccountabilityScore: Number(action.score),
        selectedUserCurrentActionsScore: Number(action.actions),
        selectedUserCurrentPracticesScore: Number(action.practices),
      };

    default:
      return state;
  }
};

export default userReducer;

export const selectUserLoadingState = state => state.users.loadingState;
export const selectSelectedUser = state => state.users.selectedUser;
export const selectSelectedUserId = state =>
  state.users.selectedUser ? state.users.selectedUser.id : null;
export const selectUserSavingState = state => state.users.savingState;
export const selectUserCreated = state => state.users.userCreated;
export const selectUserApiErrors = state => state.users.apiErrors;
export const selectUserLeaderSaveState = state => state.users.userLeaderSaveState;
export const selectSelectedUserLeaders = state => state.users.selectedUserLeaders;
export const selectUserEmailChange = state => state.users.selectedUserEmailChange;
export const selectEmailChangeLoading = state => state.users.emailLoading;
export const selectEmailChangeSaving = state => state.users.emailSaving;
export const selectActivityLoadingState = state => state.users.activityLoading;
// export const selectUserActivity = state => state.users.userActivity;
export const selectProfileImages = state => state.users.profileImages;
export const selectLifeEvents = state => state.users.userLifeEvents;
export const selectLifeEventsLoading = state => state.users.lifeEventsLoading;

// BOSS2-1318 - store and keep all data
export const selectUserActivities = state => state.users.userActivities;
// Get activity for the user in the given community
export const selectUserActivity = (state, userId, communityId) =>
  state.users.userActivities.find(
    a => a.userId === userId && a.communityId === communityId
  )?.activity || [];

export const selectSelectedUserAccountabilityScores = state =>
  state.users.selectedUserAccountabilityScores;

export const selectSelectedUserAccountabilityScoresLoading = state =>
  state.users.accountabilityScoreLoading;

export const selectSelectedUserCurrentAccountabilityScore = state =>
  state.users.selectedUserCurrentAccountabilityScore;

export const selectSelectedUserCurrentActionsScore = state =>
  state.users.selectedUserCurrentActionsScore;

export const selectSelectedUserCurrentPracticesScore = state =>
  state.users.selectedUserCurrentPracticesScore;