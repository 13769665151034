export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    color: '#ffffff',
    '@media (max-width: 411px)': {
      marginBottom: 40
    }
  },
  logo: {
    height: 90,
    width: 70,
    marginBottom: 80,
    '@media (max-width: 411px)': {
      marginTop: 40
    }
  },
  bottomLink: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12,
    marginTop: 40,
    '& a': {
      color: '#e2edfc',
      '&:hover': {
        color: '#e2edfc'
      }
    }
  },
  appContainer: {
    position: 'relative',
    overflowX: 'hidden',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  appContent: {
    paddingTop: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fefefe'
  },
  appStores: {
    marginBottom: 80,
    '@media (min-width: 768px)': {
      marginBottom: 161
    }
  },
  apple: {
    marginRight: 15
  },
  mobileScreenshots: {
    height: 'auto',
    width: '100%',
    '@media (min-width: 992px)': {
      width: 'auto'
    }
  }
};
