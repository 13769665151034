/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Divider } from '@material-ui/core';

import PageHeader from '../components/PageHeader/PageHeader';
import MissionHeaderLoading from '../components/MissionHeader/MissionHeaderLoading';
import SpecList from '../components/List/SpecList';

import StatusDropdown from '../components/StatusDropdown/StatusDropdown';
import Button from '../components/Button/Button';
import DrawGeneric from '../components/Drawers/DrawerGeneric';
import MissionTeamsSelection from '../components/Mission/MissionTeamsSelection';
import SearchableObjectives from '../components/Objective/SearchableObjectives';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';

import { selectLoggedInUserId } from '../state/auth/reducers';

import {
  selectCommunityMissionLoading,
  selectCommunityMission,
  selectCommunityMissionSaved,
  selectCommunityMissionObjectives,
  selectCommunityMissionObjectivesLoading,
  selectCommunityMissionObjectivesPagination,
  selectCommunityMissionObjectiveSaved
} from '../state/communityMission/reducers';
import { selectTeams } from '../state/teams/reducers';

import * as teamTypes from '../state/teams/types';
import * as communityMissionTypes from '../state/communityMission/types';
import useUpdateEffect from '../hooks/useUpdateEffect';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    margin: '25px 0px 10px 0px'
  },
  mr10: {
    marginRight: 10
  },
  missionTitle: {
    fontSize: 30,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily
  }
}));

const CommunityMissionView = () => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { communityId, missionId } = useParams();

  // Local state
  const [teamsDrawer, setTeamsDrawerOpen] = useState(false);
  const [objectiveDrawer, setObjectiveDrawerOpen] = useState(false);

  // Redux
  const mission = useSelector(selectCommunityMission);
  const loading = useSelector(selectCommunityMissionLoading);
  const saved = useSelector(selectCommunityMissionSaved);
  const loggedInUser = useSelector(state => selectLoggedInUserId(state));
  const objectiveSaved = useSelector(selectCommunityMissionObjectiveSaved);

  const communityMissionObjectives = useSelector(selectCommunityMissionObjectives);
  const communityMissionObjectivesPagination = useSelector(selectCommunityMissionObjectivesPagination);
  const communityMissionObjectivesLoading = useSelector(selectCommunityMissionObjectivesLoading);
  const isLeaderOfMission = (mission?.leader && loggedInUser === mission?.leader?.id) || false;
  const teams = useSelector(selectTeams);

  let teamUsers = teams.reduce((all, curr) => {
    if (mission && mission.teams.includes(curr.id)) {
      all = [...all, ...curr.users];
    }

    return all;
  }, []);

  // remove duplication by id
  teamUsers = teamUsers.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  // Helpers
  const openTeamsDrawer = () => setTeamsDrawerOpen(true);
  const closeTeamsDrawer = () => setTeamsDrawerOpen(false);
  const openObjectiveDrawer = () => setObjectiveDrawerOpen(true);
  const closeObjectiveDrawer = () => setObjectiveDrawerOpen(false);

  const fetchObjectives = ({ page, term, status, sortField, sort }) => {
    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVES,
      communityId,
      missionId,
      page,
      term,
      status,
      sortField,
      sort
    });
  };

  // Event handlers
  const handleStatusChange = status => {
    dispatch({
      type: communityMissionTypes.PATCH_COMMUNITY_MISSION_STATUS,
      communityId,
      missionId,
      status
    });
  };
  const handleEditMission = () => {
    history.push(`/c/${communityId}/m/${missionId}/edit`);
  };

  const handleObjectiveFilterChange = ({ page, term, status, sortModel }) => {
    const sortField = sortModel?.field;
    const sort = sortModel?.sort;

    fetchObjectives({ page, term, status, sortField, sort });
  };

  const handleObjectiveRowClick = rowData => {
    history.push(`/c/${communityId}/m/${missionId}/o/${rowData.id}`);
  };

  const handleOnCreateObjective = newObjective => {
    const objective = {
      ...newObjective,
      mission: missionId,
      statement: {
        ...newObjective.statement,
        daysOnceActive: Number(newObjective.daysOnceActive) || 0,
        currentMetric: newObjective.currentMetric || 0
      }
    };

    dispatch({
      type: communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE,
      communityId,
      objective
    });
  };

  // Effects
  useEffect(() => {
    dispatch({
      type: communityMissionTypes.GET_COMMUNITY_MISSION,
      communityId,
      missionId
    });

    dispatch({
      type: teamTypes.GET_ALL_TEAMS,
      communityId
    });

    fetchObjectives({});
  }, []);

  useUpdateEffect(() => {
    if (saved) {
      closeTeamsDrawer();
    }
  }, [saved]);

  useUpdateEffect(() => {
    if (objectiveSaved) {
      fetchObjectives({});
    }
  }, [objectiveSaved]);

  const headerActions = (
    <Box marginBottom={2} display="flex" flexGrow={1}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <StatusDropdown
            selected={mission ? mission.status : ''}
            formControlClass={classes.mr10}
            onChange={handleStatusChange}
            isMission
            readOnly={false}
          />
        </Grid>
        <Grid item xs={5}>
          <Button fullWidth label="Edit Mission" onClick={handleEditMission} />
        </Grid>
      </Grid>
    </Box>
  );

  const teamsButton = (
    <Button
      data-test-id="button-community-mission-teams"
      fullWidth
      label={`Teams (${mission?.teams.length || 0})`}
      onClick={openTeamsDrawer}
    />
  );

  return (
    <>
      <PageHeader routeTitle="Community Mission" showBack />

      {loading || !mission ? (
        <MissionHeaderLoading />
      ) : (
        <>
          <Grid container>
            <Grid item xs={7}>
              <Typography className={classes.missionTitle}>{mission.title}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex">{headerActions}</Box>
            </Grid>
          </Grid>

          <Divider />

          <Box marginBottom={1} display="flex" paddingTop={2} paddingBottom={1}>
            <Grid container>
              <Grid item xs={10}>
                <SpecList
                  size="small"
                  data={[
                    {
                      label: 'LEADER',
                      value: mission.leader ? `${mission.leader.firstName} ${mission.leader.lastName}` : 'No leader'
                    },
                    { label: 'DESCRIPTION', value: mission.description },
                    {
                      label: 'CREATED DATE',
                      value: moment(mission.createdOn).format('D MMMM YYYY')
                    },
                    {
                      label: 'END DATE',
                      value: moment(mission.endDate).format('D MMMM YYYY')
                    }
                  ].filter(Boolean)}
                />
              </Grid>
              <Grid item xs={2}>
                {teamsButton}
              </Grid>
            </Grid>
          </Box>

          <Divider style={{ marginBottom: 20 }} />

          {/* <MissionHeader mission={mission} actions={teamsButton} /> */}

          <SearchableObjectives
            loading={communityMissionObjectivesLoading}
            objectives={communityMissionObjectives}
            pagination={communityMissionObjectivesPagination}
            onFilterChange={handleObjectiveFilterChange}
            onAddObjectiveClick={isLeaderOfMission ? openObjectiveDrawer : null}
            onRowClick={handleObjectiveRowClick}
            showAssignedUser
          />

          <DrawGeneric open={teamsDrawer} onClose={closeTeamsDrawer} anchor="right">
            <MissionTeamsSelection mission={mission} />
          </DrawGeneric>

          <ObjectiveDrawer
            open={objectiveDrawer}
            onClose={closeObjectiveDrawer}
            onCreate={handleOnCreateObjective}
            onDelete={() => {}}
            onUpdate={() => {}}
            missionEnd={moment(mission?.endDate)}
            communityUsers={teamUsers}
            isCommunity
          />
        </>
      )}
    </>
  );
};

export default CommunityMissionView;
