import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import ProfileImage from '../ProfileImage/ProfileImage';
import Avatar from '../ProfileImageAvatar/ProfileImageAvatar';
import PopUpMenu from '../PopUpMenu/PopUpMenu';

import {
  selectSelectedCommunityId,
  selectCommunityLeaders
} from '../../state/community/reducers';

import { getUserCommunityLeaders } from '../../utils/helpers';
import { selectIsOfficer } from '../../state/auth/reducers';
import Checkbox from '../Checkbox/Checkbox';

const styles = makeStyles({
  container: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 80,
    marginBottom: 10,
    display: 'flex',
    cursor: 'pointer'
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    width: '100%',
    maxWidth: 193,
    paddingRight: 15
  },
  leaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderRight: '2px solid #dadada',
    width: '100%',
    maxWidth: '35%',
    padding: '0px 20px'
  },
  userName: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    cursor: 'pointer'
  },
  teamContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flex: 1,
    padding: '0px 20px'
  },
  teamChip: {
    padding: '0px 10px',
    backgroundColor: '#adb6c1',
    color: '#ffffff',
    fontSize: 10
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  paperRoot: {
    borderRadius: 15
  },
  menuItemRoot: {
    fontSize: 12,
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(34, 63, 99, 0.1)'
    }
  },
  officerChip: {
    height: 20,
    borderRadius: 25,
    backgroundColor: 'none !important',
    color: '#ffffff',
    fontSize: 8,
    backgroundImage: 'linear-gradient(to left, #2e598d, #072144)',
    marginRight: 5
  }
});

export default function UserCard({
  _id,
  firstName,
  lastName,
  teams,
  communityLeaders: userCommunityLeaders,
  menuActions,
  readOnly,
  onClick,
  onCheck,
  isChecked,
  enableCheckbox
}) {
  const [checked, setChecked] = useState(false);

  // Hooks
  const classes = styles();

  // Redux
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const communityLeaders = useSelector(state => selectCommunityLeaders(state));

  // Helper function is expecting full user object and deconstructing... need to pass object with that key
  // Filter out leaders who have been demoted since initial load
  const computedLeaders = getUserCommunityLeaders(
    { communityLeaders: userCommunityLeaders },
    communityId
  ).filter(l => communityLeaders.some(cl => cl._id === l._id));

  const userName = `${firstName} ${lastName}`;
  const computedTeams = teams.filter(t => t.community === communityId);
  const hasTeams = computedTeams.length > 0;
  const hasLeaders = computedLeaders.length > 0;

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  return (
    <div className={classes.container} onClick={onClick} role="presentation">
      <div className={classes.profileContainer}>
        {enableCheckbox && (
          <div>
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked)
                onCheck()
              }}
            />
          </div>
        )}
        <div>
          <ProfileImage userId={_id} style={{ marginRight: 10 }} onClick={onClick} />
        </div>
        <Typography noWrap className={classes.userName}>
          {userName}
        </Typography>
      </div>
      {(readOnly || isOfficer) && (
        <div className={classes.leaderContainer}>
          {hasLeaders ? (
            computedLeaders.map(leader => (
              <Chip
                key={leader._id}
                avatar={<Avatar userId={leader._id} />}
                className={classes.officerChip}
                label={`${leader.firstName} ${leader.lastName}`}
              />
            ))
          ) : (
            <Typography style={{ fontSize: 12 }}>
              This user does not have any leaders
            </Typography>
          )}
        </div>
      )}
      <div className={classes.teamContainer}>
        {hasTeams ? (
          computedTeams.map(team => (
            <Chip
              key={team.id}
              className={clsx({
                [classes.teamChip]: true
              })}
              label={team.title}
              size="small"
            />
          ))
        ) : (
          <Typography style={{ fontSize: 12 }}>
            This user does not belong to a team
          </Typography>
        )}
      </div>
      {menuActions && <PopUpMenu menuActions={menuActions} />}
    </div>
  );
}

UserCard.propTypes = {
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
  menuActions: PropTypes.array,
  readOnly: PropTypes.bool,
  communityLeaders: PropTypes.object,
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  isChecked: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
};

UserCard.defaultProps = {
  menuActions: null,
  readOnly: false,
  communityLeaders: null,
  onClick: null,
  onCheck: null,
  isChecked: false,
  enableCheckbox: false,
};
