// Compartors used in function 'consolidateArrayState'
const userAndCommunityMatch = (a, b) =>
  a.userId === b.userId && a.communityId === b.communityId;
const keyMatch = (a, b, key) => a[key] === b[key];

/**
 * Updates or adds an object to an existing array
 * @param {Object} newElement the element to be updated/added
 * @param {Array} existingArray the existing array in redux
 * @param {Boolean} isCommunity if true it will use the 'userAndCommunityMatch' helper
 * @param {String} key the key used in the 'keyMatch' helper
 */
export const consolidateArrayState = (
  newElement,
  existingArray,
  isCommunity = true,
  key = null
) => {
  const comparator = isCommunity ? userAndCommunityMatch : keyMatch;

  const dataExists = existingArray.some(e => comparator(e, newElement, key));

  // Update the existing data if some exists
  if (dataExists) {
    return existingArray.map(e => {
      if (comparator(e, newElement)) return newElement;
      return e;
    });
  }

  // Add the new element to the array
  return [...existingArray, newElement];
};

/**
 * Updates or inserts a mission into the user mission state
 * @param {Array} userMissions The userMissions currently in state
 * @param {Object} mission The new mission object
 * @param {String} userId The id of the user who owns the mission
 * @param {String} communityId The id of the community that the mission belongs to
 */
export const updateOrAddMission = (userMissions, mission, userId, communityId) => {
  // Check if the user is in the state
  const userInState = userMissions.find(u => u.userId === userId);

  // The user has missions in state
  if (userInState) {
    // Check if the mission has been fetched previously
    const missionInState = userInState.missions.some(m => m.id === mission.id);

    // Abstracted for readability
    const updatedMissions = userInState.missions.map(m => {
      if (m.id === mission.id) return mission;
      return m;
    });

    // Either update the mission or add it
    const computedMissions = missionInState
      ? updatedMissions
      : [...userInState.missions, mission];

    return userMissions.map(u => {
      if (u.userId === userId) return { ...u, missions: computedMissions };
      return u;
    });
  }

  // The user wasn't in state - create a record and add the mission
  return [...userMissions, { userId, communityId, missions: [mission] }];
};

/**
 * Removed the archived mission from the current missions stored in state
 * @param {Array} userMissions The current userMissions in state
 * @param {String} userId The id of the user who the mission belonged to
 * @param {String} missionId The id of the mission being archived
 */
export const removeMissionFromUserMissions = (userMissions, userId, missionId) =>
  userMissions.map(u => {
    if (u.userId === userId) {
      return {
        ...u,
        missions: u.missions.filter(m => m.id !== missionId)
      };
    }
    return u;
  });

/**
 * Inserts or updates an objective into the existing mission objectives in state
 * @param {Array} missionObjectives The current missionObjectives array in redux
 * @param {String} missionId The ID of the mission the objective belongs to
 * @param {Object} objective The updated objective object
 */
export const updateOrAddMissionObjectives = (
  missionObjectives,
  missionId,
  objective
) => {
  // Check if the mission exists in the state
  const missionInState = missionObjectives.find(m => m.missionId === missionId);

  // The mission is in state - update objectives
  if (missionInState) {
    // Check if the objective has been fetched previously
    const objectiveInMission = missionInState.objectives.find(
      o => o.id === objective.id
    );

    // Abstracted for readability
    const updatedObjectives = missionInState.objectives.map(o => {
      if (o.id === objective.id) return objective;
      return o;
    });

    // Either update the objective or add it
    const computedObjectives = objectiveInMission
      ? updatedObjectives
      : [...missionInState.objectives, objective];

    return missionObjectives.map(m => {
      if (m.missionId === missionId) return { ...m, objectives: computedObjectives };
      return m;
    });
  }

  // Mission wasn't in state
  return [...missionObjectives, { missionId, objectives: [objective] }];
};

export const removeObjectiveFromMissionObjectives = (
  missionObjectives,
  missionId,
  objectiveId
) => {
  // Check if the mission exists in the state
  const missionInState = missionObjectives.find(m => m.missionId === missionId);

  if (missionInState) {
    // Filter out the archived objective
    const filteredObjectives = missionInState.objectives.filter(
      o => o.id !== objectiveId
    );

    // Update the parent mission
    return missionObjectives.map(m => {
      if (m.missionId === missionId) return { ...m, objectives: filteredObjectives };
      return m;
    });
  }

  return missionObjectives;
};

/**
 * Inserts or updates a task in an objective
 * @param {Array} missionObjectives The current missionObjectives array in redux
 * @param {String} missionId The ID of the mission the task belongs to
 * @param {String} objectiveId The ID of the objective the task belongs to
 * @param {Object} task The Task object
 * @param {Boolean} deleteTask is the function to delete the task
 */
export const updateObjectiveTasks = (
  missionObjectives,
  missionId,
  objectiveId,
  task,
  deleteTask = false
) => {
  // Check if the mission exists in the state (should always if updating task)
  const missionInState = missionObjectives.some(m => m.missionId === missionId);

  // Arrays on arrays on arrays
  if (missionInState) {
    return missionObjectives.map(m => {
      // Find the tasks mission
      if (m.id === missionId) {
        return {
          ...m,
          objectives: m.objectives.map(o => {
            // Find the tasks objective
            if (o.id === objectiveId) {
              // Check if new task or existing (SHOULD always be existing)
              const taskExists = o.tasks.some(t => t.id === task.id);
              const addTask = [...o.tasks, task];
              const updatedTasks = o.tasks.map(t => {
                if (t.id === task.id) return task;
                return t;
              });

              // Either add the task or update it
              const computedTasks = taskExists ? updatedTasks : addTask;

              return {
                ...o,
                tasks: deleteTask
                  ? o.tasks.filter(t => t.id !== task)
                  : computedTasks
              };
            }
            return o;
          })
        };
      }
      return m;
    });
  }

  return missionObjectives;
};

/**
 * Merge to arrays of objects - uses 'id' key to check for duplicates
 * @param {Array} newArray The new array of objects (objects must have id key)
 * @param {Array} oldArray The old array of objects (objects must have id key)
 */
export const mergeArray = (newArray, oldArray) => {
  const oldArrayWithUpdatedValues = oldArray.map(e => {
    const updatedElement = newArray.find(n => n.id === e.id);
    if (updatedElement) return updatedElement;
    return e;
  });

  const exsitingIds = new Set(oldArrayWithUpdatedValues.map(d => d.id));

  return [
    ...oldArrayWithUpdatedValues,
    ...newArray.filter(d => !exsitingIds.has(d.id))
  ];
};

/**
 * The get user objectives call can return either only leader objectives or all objectives. The leader view filters these by mission.
 * Need to store them both for faster load times.
 * @param {Array} currentState The current state of userObjectives
 * @param {String} userId The id of the user who owns the objectives
 * @param {Array} newData The new objectives
 */
export const updateUserObjectives = (currentState, userId, newData) => {
  const dataExists = currentState.some(s => s.userId === userId);
  if (dataExists) {
    return currentState.map(s => {
      if (s.userId === userId)
        return { userId, objectives: mergeArray(newData, s.objectives) };
      return s;
    });
  }
  return [...currentState, { userId, objectives: newData }];
};

export default consolidateArrayState;

/**
 * Either adds or removes task id from array
 * @param {Array} currentState the current array of task ids that are saving
 * @param {String} taskId the id of the task
 */
export const updateSavingTasks = (currentState, taskId) => {
  const exists = currentState.find(id => id === taskId);
  return exists
    ? currentState.filter(id => id !== taskId)
    : [...currentState, taskId];
};

/**
 * Handles server paginated data - either adds or merges leader activities
 * @param {Array} stateActivities the current array of leader activities in state
 * @param {Object} newData an object with userId, communityId and array of activities
 */
export const consolidateLeaderActivity = (stateActivities, newData) => {
  const dataExists = stateActivities.some(e => userAndCommunityMatch(e, newData));

  if (dataExists) {
    return stateActivities.map(e => {
      if (userAndCommunityMatch(e, newData)) {
        const { page, data, total } = newData;
        const pageExists = e.activities.some(a => a.page === page);

        const updatedActivities = e.activities.map(a => {
          if (a.page === page) return { page, data };
          return a;
        });

        return {
          ...e,
          total,
          activities: pageExists
            ? updatedActivities
            : [...e.activities, { page, data }]
        };
      }
      return e;
    });
  }

  const { page, data, ...rest } = newData;

  return [...stateActivities, { ...rest, activities: [{ page, data }] }];
};
