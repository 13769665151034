import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListIcon from '@material-ui/icons/List';
import bg from '../../static/images/authBg.jpg';
import { ReactComponent as ArrowIcon } from '../../static/images/icons/icon-arrow-turn.svg';

import * as communityTypes from '../../state/community/types';
import {
  selectSelectedCommunity,
  selectAllCommunities,
  selectCommunityLoadingState,
  selectSelectedCommunityId
} from '../../state/community/reducers';
import { selectLoggedInUser, selectIsOfficer } from '../../state/auth/reducers';

import { getMembershipIds, checkAndGetPermissions } from '../../utils/helpers';

const styles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 70,
    borderRadius: 25,
    margin: '10px 20px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${bg})`,
    flexDirection: 'column'
  },
  pointer: {
    cursor: 'pointer'
  },
  selectedCommunity: {
    userSelect: 'none',
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Helvetica, sans-serif',
    fontSize: 12,
    color: '#fff',
    padding: '0px 10px',
    textShadow: '0 0 10px rgba(0,0,0,0.3)'
  },
  change: {
    userSelect: 'none',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Helvetica, sans-serif',
    fontSize: 9,
    color: '#fff',
    padding: '0px 10px',
    textShadow: '0 0 10px rgba(0,0,0,1)'
  },
  arrowContainer: {
    position: 'absolute',
    top: 8,
    right: 10,
    width: 30,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 18
  },
  backdrop: {
    zIndex: 1
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  paperRoot: {
    width: 314,
    borderRadius: 20,
    padding: 10,
    maxHeight: 400,
    overflow: 'auto',
    zIndex: 2
  },
  menuItem: {
    borderRadius: 15,
    padding: 20,
    margin: 10,
    backgroundColor: '#f4f4f4',
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  }
});

export default function CommunitySwitcher() {
  const classes = styles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  // Get the selected community id then get the name from the user object
  const loadingState = useSelector(state => selectCommunityLoadingState(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const allCommunities = useSelector(state => selectAllCommunities(state));
  const loggedInUser = useSelector(state => selectLoggedInUser(state));
  const currentCommunity = useSelector(state => selectSelectedCommunity(state));
  const currentCommunityId = useSelector(state => selectSelectedCommunityId(state));
  const { communities, memberships } = loggedInUser || {};

  // Only show communities that the user has permissions for
  // Confirm they exist against the community key
  const membershipIds = getMembershipIds(memberships);
  const visibleCommunities = communities
    .filter(c => membershipIds.some(key => key === c._id))
    .filter(comm => comm._id !== currentCommunityId);

  // If they are an officer - show all communities
  const computedCommunities = isOfficer ? allCommunities.filter(c => c._id !== currentCommunityId) : visibleCommunities;

  // Get the title of the current community
  const { title: currentCommunityTitle } = currentCommunity;

  function handleClick(event) {
    if (computedCommunities.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(communityId) {
    // Wipe existing community data
    dispatch({ type: communityTypes.CLEAR_COMMUNITY_SPECIFICS });

    dispatch({
      type: communityTypes.GET_COMMUNITY,
      communityId
    });
    handleClose();

    const { isComAdmin } = checkAndGetPermissions(memberships, communityId);

    // Otherwise direct to either base community id or leader id
    if (!isComAdmin) {
      history.push(`/c/${communityId}/members/leader`);
    } else {
      history.push(`/c/${communityId}/users`);
    }
  }

  return (
    <>
      <div
        className={clsx({
          [classes.container]: true,
          [classes.pointer]: computedCommunities.length > 0
        })}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyDown={null}
      >
        {loadingState ? (
          <CircularProgress style={{ color: '#d9a035' }} />
        ) : (
          <>
            <Typography noWrap className={classes.selectedCommunity}>
              {currentCommunityTitle}
            </Typography>
            {computedCommunities.length > 0 && (
              <Typography noWrap className={classes.change}>
                (Change community)
              </Typography>
            )}
          </>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 0, horizontal: 170 }}
        getContentAnchorEl={null}
        classes={{
          list: classes.list
        }}
        PaperProps={{
          className: classes.paperRoot
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          classes: {
            root: classes.backdrop
          }
        }}
      >
        {computedCommunities.map(({ _id, title }) => (
          <MenuItem key={_id} className={classes.menuItem} onClick={() => handleChange(_id)}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
