import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import AuthBackground from '../../components/Auth/AuthBackground/AuthBackground';
import LoginCard from '../../components/Auth/LoginCard/LoginCard';
import Modal from '../../components/Modal/Modal';

import { validateLogin, getErrorMessage, parseApiErrors } from '../../constants/validation';

import * as authTypes from '../../state/auth/types';
import { getLocalStore } from '../../state/auth/services';

import {
  selectAuthLoadingState,
  selectIsOfficer,
  selectLoggedInUser,
  selectAuthApiErrors
} from '../../state/auth/reducers';
import {
  selectCommunityLoadingState,
  selectSelectedCommunityId,
  selectCurrentUserCommunityPermissions
} from '../../state/community/reducers';

import authStyles from './authStyles';

const styles = makeStyles({
  ...authStyles
});

export default function Login() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // The state (hook style)
  const [values, setValues] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [errors, setErrors] = useState(null);

  // Redux state
  const apiErrors = useSelector(state => selectAuthApiErrors(state));
  const communityLoading = useSelector(state => selectCommunityLoadingState(state));
  const authLoading = useSelector(state => selectAuthLoadingState(state));
  const selectedCommunityId = useSelector(state => selectSelectedCommunityId(state));
  const loggedInUser = useSelector(state => selectLoggedInUser(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));
  const isComAdmin = permissions.includes('admin');
  const isLeader = permissions.includes('leader');

  // Auth storage
  const token = localStorage.getItem('idToken');

  // If a user is already logged in - redirect them
  useEffect(() => {
    if (loggedInUser && !authLoading && token) {
      const { redirect } = queryString.parse(location.search);
      if (redirect !== undefined) history.push(redirect);
      else if (!selectedCommunityId) history.push('/nxt-lvl-app');
      else if (isOfficer) history.push(`/c/${selectedCommunityId}/members/officer`);
      else if (isLeader) history.push(`/c/${selectedCommunityId}/members/leader`);
      else if (isComAdmin) history.push(`/c/${selectedCommunityId}/users`);
    }
  }, [loggedInUser, authLoading, token]);

  // Populate email if set in localstorage
  useEffect(() => {
    const savedEmail = getLocalStore('email');
    if (savedEmail) setValues({ ...values, email: savedEmail, remember: true });
  }, []);

  // Handles the change of email/password
  function handleTextChange(e) {
    const { name, value } = e.target;

    // If errors exist and the user updates to valid email - remove the error
    if (errors !== null && name === 'email') {
      const hasError = validateLogin(values);
      if (!hasError) setErrors(null);
    }

    setValues({
      ...values,
      [name]: value
    });
  }

  // Handles toggling of the checkbox
  function handleCheck(e) {
    const { checked } = e.target;
    setValues({
      ...values,
      remember: checked
    });
  }

  // Submit the request
  function handleSubmit() {
    // Check for errors before sending
    const errorMessages = validateLogin(values);
    if (errorMessages === undefined) {
      setErrors(null);
      // Everything is groovy, submit the payload
      dispatch({
        type: authTypes.AUTH_LOGIN,
        ...values
      });
    } else setErrors(errorMessages);
  }

  const loadingState = authLoading || communityLoading;

  // Deconstruct for readability
  const { email, password, remember } = values;

  const computedErrors = errors || parseApiErrors(apiErrors, authTypes.AUTH_LOGIN_ERROR);

  return (
    <AuthBackground>
      <Container className={classes.container}>
        <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <Logo className={classes.logo} />
          <LoginCard
            loading={loadingState}
            handleTextChange={handleTextChange}
            handleCheck={handleCheck}
            handleSubmit={handleSubmit}
            email={email}
            password={password}
            remember={remember}
            emailError={getErrorMessage(computedErrors, 'email')}
            passwordError={getErrorMessage(computedErrors, 'password')}
          />
          <Typography className={classes.bottomLink}>
            <Link to="/reset-password">Forgot password?</Link>
          </Typography>
        </Grid>
      </Container>
      <Modal />
    </AuthBackground>
  );
}
