import React from 'react';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Drawer from './DrawerGeneric';

const Item = ({ content, title }) => (
  <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={2}>
    <Typography style={{ fontWeight: 700 }}>{title}</Typography>
    {content || `${title} have not been completed yet.`}
  </Box>
);

const ValueItem = ({ value }) => (
  <Box padding={2} bgcolor="#ddd" borderRadius={10} marginBottom={2}>
    <Typography style={{ fontWeight: 700, marginBottom: 10 }}>{value.value}</Typography>
    <Typography style={{ fontWeight: 700, marginBottom: 10, fontSize: 12 }}>Standards:</Typography>
    {value.standards.map(s => (
      <Typography>- {s.value}</Typography>
    ))}
  </Box>
);

const FrameworkDrawer = ({ framework, ...props }) => (
  <Drawer width={450} {...props}>
    {framework && (
      <>
        <Item title="Vision" content={framework?.vision.value} />
        <Item title="Mission" content={framework?.mission.value} />
        <Item title="Purpose" content={framework?.purpose.value} />
        {framework.values.length > 0 ? (
          <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={2}>
            <Typography style={{ fontWeight: 700, marginBottom: 10 }}>Values</Typography>
            {framework.values.map(v => (
              <ValueItem value={v} key={v._id} />
            ))}
          </Box>
        ) : (
          <Item title="Values" content={null} />
        )}

        <Item title="Mantra" content={framework?.purpose.value} />
        <Item title="Philosophy" content={framework?.philosophy} />
        <Item title="Quote" content={framework?.purpose.value} />
        <Item title="One Word" content={framework?.oneWord} />
      </>
    )}
  </Drawer>
);

export default FrameworkDrawer;
