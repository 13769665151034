import PropTypes from 'prop-types'
import React from 'react';

const Activator = ({className}) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 595.28 595.28"
      style={{enableBackground: '0 0 595.28 595.28'}}
      xmlSpace="preserve"
      width="250"
      height="250"
    >
      <defs>
        <style>{'.st0{opacityxs:0.23;}'}</style>
      </defs>
      <g className="st0">
        <g>
          <path d="M488.45,544.41H74.71l-2.68-2.57c-85.41-81.89-53.39-146.47-30.01-193.63c16.78-33.85,24.67-52.32,6.13-69.74l12.63-13.45
			c13.43,12.62,18.57,27.42,15.7,45.26c-1.36,8.45-4.43,17.08-8.32,25.95c-7.91,19.09-8.47,33.77-1.65,43.62
			c10.37,15,37.15,17.47,46.57,17.14l0.33-0.01h13.03c10.31-23.07,33.47-39.18,60.32-39.18c18.87,0,36.31,7.81,48.68,21.37
			c10.11-10.99,23.88-18.55,39.37-20.73v-40.74H150.01l7.95-13.82c0.12-0.21,12.82-22.78,19.04-59.99
			c5.72-34.22,6.97-88.27-19.55-151.41l-5.37-12.8h122.59V29.84h18.45V79.7h83.3l2.64,4.73c17.21,30.85,27.93,62.07,31.86,92.78
			c3.16,24.74,1.96,49.18-3.59,72.64c-9.5,40.17-28.39,63.52-29.19,64.49l-2.77,3.37h-82.1v40.73c15.27,2.11,29.12,9.42,39.45,20.74
			c12.08-13.13,29.39-21.37,48.59-21.37c26.82,0,49.96,16.08,60.28,39.1c28.02-1.37,52.13-19.15,60.68-45.13
			c2.53-7.7,3.97-16.25,4.27-25.48c-6.67-12.62-12.36-24.7-15.14-36.17c-4.71-19.39-0.6-34.83,12.59-47.22
			c25.19-23.66,65.92-1.92,67.64-0.98l7.48,4.07l-11.19,25.11l-3.2,1.44c-0.05,0.02-6.29,2.9-20.52,11.36
			c3.51,7.7,8.63,12.54,15.27,14.42c6.61,1.87,12.61,0.28,12.66,0.26l5.1,17.73c-1.04,0.3-10.5,2.89-21.71,0.05
			c-10.62-2.7-24.7-10.76-31.44-33.86l-1.98-6.77l6.02-3.68c13.31-8.13,21.57-12.59,25.72-14.72l1.15-2.59
			c-10.18-3.79-27.94-8.23-38.38,1.59l0,0c-15.66,14.71-8.38,33.98,8.25,65.05l0.18,0c0,0.12,0,0.23,0,0.35
			c1.68,3.13,3.45,6.38,5.3,9.76c30.72,56.01,72.79,132.72-39.52,211.22L488.45,544.41z M82.16,525.96h400.45
			c42.66-30.43,63.32-62.19,63.13-97.03c-0.14-26.4-12.06-52.2-24.63-75.75c-0.41,1.48-0.85,2.94-1.33,4.38
			c-10.5,31.94-39.25,54.21-73.04,57.48c0.39,2.88,0.59,5.82,0.59,8.81c0,36.41-29.62,66.03-66.02,66.03
			c-19.2,0-36.52-8.24-48.59-21.37c-12.37,13.56-29.81,21.37-48.67,21.37c-19.2,0-36.52-8.24-48.59-21.37
			c-12.37,13.56-29.8,21.37-48.68,21.37c-36.41,0-66.02-29.62-66.02-66.03c0-2.84,0.18-5.65,0.53-8.39h-7.71
			c-3.6,0.11-44.32,0.69-62.18-25.03c-1.92-2.77-3.5-5.71-4.73-8.81C29.59,421.68,22.64,467.4,82.16,525.96z M381.31,376.27
			c-26.24,0-47.58,21.34-47.58,47.58c0,26.23,21.34,47.58,47.58,47.58c26.23,0,47.58-21.34,47.58-47.58
			C428.88,397.62,407.54,376.27,381.31,376.27z M284.04,376.27c-26.23,0-47.58,21.34-47.58,47.58c0,26.23,21.34,47.58,47.58,47.58
			c15.08,0,28.9-6.93,37.87-18.76c-4.24-8.71-6.63-18.5-6.63-28.82s2.38-20.11,6.63-28.82C312.93,383.2,299.12,376.27,284.04,376.27
			z M186.77,376.27c-26.23,0-47.58,21.34-47.58,47.58c0,26.23,21.34,47.58,47.58,47.58c15.09,0,28.9-6.92,37.87-18.76
			c-4.25-8.71-6.63-18.49-6.63-28.82s2.38-20.11,6.63-28.82C215.67,383.2,201.86,376.27,186.77,376.27z M180.49,299.27h185.83
			c5.09-7.39,16.79-26.55,23.26-54.57c4.9-21.22,5.89-43.36,2.95-65.78c-3.49-26.61-12.57-53.76-27-80.77h-186
			c23.12,62.13,21.4,115.24,15.51,149.71C191.16,270.57,185.12,288.12,180.49,299.27z M381.31,448.13
			c-13.39,0-24.28-10.89-24.28-24.28c0-13.39,10.89-24.28,24.28-24.28c13.39,0,24.28,10.89,24.28,24.28
			C405.58,437.24,394.69,448.13,381.31,448.13z M381.31,418.02c-3.22,0-5.83,2.62-5.83,5.83s2.62,5.83,5.83,5.83
			c3.21,0,5.83-2.62,5.83-5.83S384.52,418.02,381.31,418.02z M284.04,448.13c-13.39,0-24.28-10.89-24.28-24.28
			c0-13.39,10.89-24.28,24.28-24.28c13.39,0,24.28,10.89,24.28,24.28C308.32,437.24,297.43,448.13,284.04,448.13z M284.04,418.02
			c-3.22,0-5.83,2.62-5.83,5.83s2.62,5.83,5.83,5.83c3.21,0,5.83-2.62,5.83-5.83S287.25,418.02,284.04,418.02z M186.77,448.13
			c-13.39,0-24.28-10.89-24.28-24.28c0-13.39,10.89-24.28,24.28-24.28c13.39,0,24.28,10.89,24.28,24.28
			C211.05,437.24,200.16,448.13,186.77,448.13z M186.77,418.02c-3.21,0-5.83,2.62-5.83,5.83s2.62,5.83,5.83,5.83
			c3.22,0,5.83-2.62,5.83-5.83S189.99,418.02,186.77,418.02z"
          />
        </g>
      </g>
    </svg>
  )
}

Activator.propTypes = {
  className: PropTypes.string
}

Activator.defaultProps = {
  className: ''
}

export default Activator

