import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
// import { getErrorMessage } from '@/constants/validation';
import { getMissionProfileImage } from '@/state/mission/actions';
import { getMissionTemplatesProfileImage } from '@/state/templates/actions';
import { CircularProgress } from '@material-ui/core';
import customIcons from '../../constants/customIcons'

import CustomIcon from '../CustomIcon/CustomIcon';
// import IconPicker from '../IconPicker/IconPicker';

const useStyles = makeStyles({
  container: {
    boxShadow: '0 5px 10px -3px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bgWhite: {
    backgroundColor: '#ffffff'
  },
  bgGold: {
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to left, #f5bd56, #a5721a)'
  },
  bgRed: {
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to right, #ac0037, #ff0052)'
  },
  sm: {
    height: 40,
    width: 40,
    borderRadius: 12
  },
  lg: {
    height: 100,
    width: 100,
    borderRadius: 30
  },
  iconGold: {
    '& *': {
      fill: 'url(#lgrad)'
    }
  },
  iconWhite: {
    '& *': {
      fill: '#ffffff'
    }
  },
  tooltip: {
    backgroundColor: '#223f63',
    textAlign: 'left',
    padding: '13px 30px 13px 10px',
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: '12px',
    letterSpacing: 'normal',
    fontWeight: '500',
    borderRadius: 8
  },
  arrow: {
    color: '#223f63',
    transform: 'scale(1, 3)'
  },
  imageBox: {
    borderRadius: 12
  }
});

export default function MissionIcon({ icon, isTemplate, id, large, variant, style, tooltip }) {
  const classes = useStyles();
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(true);

  const containerClass = clsx({
    [classes.container]: true,
    [classes.bgWhite]: variant === 'white',
    [classes.bgGold]: variant === 'gold',
    [classes.bgRed]: variant === 'red',
    [classes.sm]: !large,
    [classes.lg]: large
  });

  const iconClass = clsx({
    [classes.iconGold]: variant === 'white',
    [classes.iconWhite]: variant === 'gold' || variant === 'red'
  });

  const iconSize = large ? '3x' : '1x';
  const loaderSize = large ? 80: 30;

  useEffect(() => {
    let isSubscribed = true;
    if (id) {
      const functionGetter = !isTemplate ? getMissionProfileImage: getMissionTemplatesProfileImage
      functionGetter(id)
        .then(val => {
          setLoading(false)
          if (val && isSubscribed) {
            const reader = new FileReader();
            
            reader.onload = event => {
              const { result } = event.target;
              setSrc(result);
            };

            reader.readAsDataURL(val);
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return () => {
      isSubscribed = false;
    };
  }, [id, isTemplate]);

  function renderIcon() {
    return (
      <>
        {loading && (
          <CircularProgress size={loaderSize} />
        )}
        {
          !src && !loading ? customIcons.filter(customIcon => customIcon.type === 'custom')
            .map(customIcon => customIcon.name).includes(icon) &&
            (<CustomIcon
              icon={icon}
              onClick={()=>{}}
              viewOnly
              missionIcon
            />) || (
            <FontAwesomeIcon icon={icon} className={iconClass} size={iconSize} />
          )
            :<></>
        }
        {
          src && !loading &&
            <img
              alt="mission-icon"
              src={src}
              width="100%"
              height="100%"
              className={classes.imageBox}
            />
        }
      </>
    )
  }

  return tooltip ? (
    <>
      <Tooltip
        title={tooltip}
        arrow
        placement="top-start"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <Box className={containerClass} style={style}>
          {renderIcon()}
        </Box>
      </Tooltip>
    </>
  ) : (
    <>
      <Box className={containerClass} style={style}>
        {renderIcon()}
      </Box>
    </>
  );
}

MissionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool,
  id: PropTypes.string,
  large: PropTypes.bool,
  variant: PropTypes.oneOf(['white', 'gold', 'red']),
  style: PropTypes.object,
  tooltip: PropTypes.string
};

MissionIcon.defaultProps = {
  large: false,
  isTemplate: false,
  id: '',
  variant: 'white',
  style: null,
  tooltip: null
};
