import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import TemplateGroup from './TemplateGroup';

import {
  selectSelectedCommunity,
  selectCurrentUserCommunityPermissions
} from '../../state/community/reducers';
import * as templateTypes from '../../state/templates/types';

const SelectTemplates = ({
  communityTemplates,
  leaderTemplates,
  communityLoading,
  leaderLoading,
  communitySearch,
  leaderSearch,
  onSearchChange,
  onSelectTemplate,
  selectedTemplates
}) => {
  // Hooks
  const history = useHistory();
  const dispatch = useDispatch();

  // Redux
  const community = useSelector(state => selectSelectedCommunity(state));
  const currentPermissions = useSelector(state =>
    selectCurrentUserCommunityPermissions(state)
  );

  // Event handlers
  const handleEdit = template => {
    const { _id: communityId } = community;
    const { id: templateId } = template;
    dispatch({
      type: templateTypes.SET_MISSION_TEMPLATE,
      template
    });
    history.push(`/c/${communityId}/mission-template/${templateId}/edit`);
  };

  const onAddTemplate = type => {
    dispatch({ type: templateTypes.CLEAR_SELECTED_MISSION_TEMPLATE });
    const { _id: communityId } = community;
    history.push(`/c/${communityId}/create-mission-template/${type}`);
  };

  // Constants
  const communityHeading = `Company templates`;
  const isCommunityAdmin = currentPermissions.some(p => p === 'admin');

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TemplateGroup
          name="community"
          heading={communityHeading}
          selectedTemplates={selectedTemplates}
          templates={communityTemplates}
          loading={communityLoading}
          search={communitySearch}
          onChange={onSearchChange}
          onClick={onSelectTemplate}
          onEdit={handleEdit}
          onAdd={() => onAddTemplate('community')}
          readOnly={!isCommunityAdmin}
        />
      </Grid>
      <Grid item xs={6}>
        <TemplateGroup
          name="leader"
          heading="Personal templates"
          selectedTemplates={selectedTemplates}
          templates={leaderTemplates}
          loading={leaderLoading}
          search={leaderSearch}
          onChange={onSearchChange}
          onClick={onSelectTemplate}
          onEdit={handleEdit}
          onAdd={() => onAddTemplate('leader')}
        />
      </Grid>
    </Grid>
  );
};

SelectTemplates.propTypes = {
  communityTemplates: PropTypes.array.isRequired,
  leaderTemplates: PropTypes.array.isRequired,
  communityLoading: PropTypes.bool.isRequired,
  leaderLoading: PropTypes.bool.isRequired,
  communitySearch: PropTypes.string,
  leaderSearch: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  selectedTemplates: PropTypes.array.isRequired
};

SelectTemplates.defaultProps = {
  communitySearch: '',
  leaderSearch: ''
};

export default SelectTemplates;
