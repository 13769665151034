import React from 'react';
import PropTypes from 'prop-types';
import authBg from '../../../static/images/authBg.jpg';

export default function AuthBackground({ children }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundImage: `url(${authBg})`
      }}
    >
      {children}
    </div>
  );
}

AuthBackground.propTypes = {
  children: PropTypes.node.isRequired
};
