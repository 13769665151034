export const GET_ALL_COMMUNITY_MISSIONS = 'mission/GET_ALL_COMMUNITY_MISSIONS';
export const GET_ALL_COMMUNITY_MISSIONS_SUCCESS = 'mission/GET_ALL_COMMUNITY_MISSIONS_SUCCESS';
export const GET_ALL_COMMUNITY_MISSIONS_ERROR = 'missions/GET_ALL_COMMUNITY_MISSIONS_ERROR';

export const GET_MISSION = 'mission/GET_MISSION';
export const GET_MISSION_SUCCESS = 'mission/GET_MISSION_SUCCESS';
export const GET_MISSION_ERROR = 'mission/GET_MISSION_ERROR';

export const STORE_PROFILE_IMAGE = 'mission/STORE_PROFILE_IMAGE';
export const UPDATE_PROFILE_IMAGE = 'mission/UPDATE_PROFILE_IMAGE';

export const GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY = 'mission/GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY';
export const GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_SUCCESS = 'mission/GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_SUCCESS';
export const GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_ERROR = 'mission/GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_ERROR';

export const GET_MISSIONS_STATS_REQUEST = 'missions/GET_MISSIONS_STATS_REQUEST';
export const GET_MISSIONS_STATS_SUCCESS = 'missions/GET_MISSIONS_STATS_SUCCESS';
export const GET_MISSIONS_STATS_ERROR = 'missions/GET_MISSIONS_STATS_ERROR';

export const GET_MISSION_OBJECTIVES = 'mission/GET_MISSION_OBJECTIVES';
export const GET_MISSION_OBJECTIVES_SUCCESS = 'mission/GET_MISSION_OBJECTIVES_SUCCESS';
export const GET_MISSION_OBJECTIVES_ERROR = 'mission/GET_MISSION_OBJECTIVES_ERROR';

export const GET_OBJECTIVE = 'mission/GET_OBJECTIVE';
export const GET_OBJECTIVE_SUCCESS = 'mission/GET_OBJECTIVE_SUCCESS';
export const GET_OBJECTIVE_ERROR = 'mission/GET_OBJECTIVE_ERROR';

export const GET_OBJECTIVE_TASKS = 'mission/GET_OBJECTIVE_TASKS';
export const GET_OBJECTIVE_TASKS_SUCCESS = 'mission/GET_OBJECTIVE_TASKS_SUCCESS';
export const GET_OBJECTIVE_TASKS_ERROR = 'mission/GET_OBJECTIVE_TASKS_ERROR';

export const POST_COMMENT = 'mission/POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'mission/POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'mission/POST_COMMENT_ERROR';

export const MISSION_LOADING_STATE = 'mission/MISSION_LOADING_STATE';
export const OBJECTIVE_LOADING_STATE = 'mission/OBJECTIVE_LOADING_STATE';
export const TASK_LOADING_STATE = 'mission/TASK_LOADING_STATE';

export const COMMENT_SAVING_STATE = 'mission/COMMENT_SAVING_STATE';

export const GET_ALL_OBJECTIVES = 'mission/GET_ALL_OBJECTIVES';
export const GET_ALL_OBJECTIVES_SUCCESS = 'mission/GET_ALL_OBJECTIVES_SUCCESS';
export const GET_ALL_OBJECTIVES_ERROR = 'mission/GET_ALL_OBJECTIVES_ERROR';

export const GET_USER_OBJECTIVES = 'objectives/GET_USER_OBJECTIVES';
export const GET_USER_OBJECTIVES_SUCCESS = 'objectives/GET_USER_OBJECTIVES_SUCCESS';
export const GET_USER_OBJECTIVES_ERROR = 'objectives/GET_USER_OBJECTIVES_ERROR';

export const GET_MISSION_TIMELINE = 'mission/GET_MISSION_TIMELINE';
export const GET_MISSION_TIMELINE_SUCCESS = 'mission/GET_MISSION_TIMELINE_SUCCESS';
export const GET_MISSION_TIMELINE_ERROR = 'mission/GET_MISSION_TIMELINE_ERROR';

export const MISSION_SAVING_STATE = 'mission/MISSION_SAVING_STATE';

export const UPDATE_MISSION_STATUS = 'mission/UPDATE_MISSION_STATUS';
export const UPDATE_MISSION_STATUS_SUCCESS = 'mission/UPDATE_MISSION_STATUS_SUCCESS';
export const UPDATE_MISSION_STATUS_ERROR = 'mission/UPDATE_MISSION_STATUS_ERROR';

export const CREATE_MISSION = 'mission/CREATE_MISSION';
export const CREATE_MISSION_SUCCESS = 'mission/CREATE_MISSION_SUCCESS';
export const CREATE_MISSION_ERROR = 'mission/CREATE_MISSION_ERROR';

export const ARCHIVE_MISSION = 'mission/ARCHIVE_MISSION';
export const ARCHIVE_MISSION_SUCCESS = 'mission/ARCHIVE_MISSION_SUCCESS';
export const ARCHIVE_MISSION_ERROR = 'mission/ARCHIVE_MISSION_ERROR';


export const ARCHIVE_MISSION_BY_TEMPLATE = 'mission/ARCHIVE_MISSION_BY_TEMPLATE';
export const ARCHIVE_MISSION_BY_TEMPLATE_SUCCESS = 'mission/ARCHIVE_MISSION_BY_TEMPLATE_SUCCESS';
export const ARCHIVE_MISSION_BY_TEMPLATE_ERROR = 'mission/ARCHIVE_MISSION_BY_TEMPLATE_ERROR';

export const UPDATE_OBJECTIVE_STATUS = 'mission/UPDATE_OBJECTIVE_STATUS';
export const UPDATE_OBJECTIVE_STATUS_SUCCESS = 'mission/UPDATE_OBJECTIVE_STATUS_SUCCESS';
export const UPDATE_OBJECTIVE_STATUS_ERROR = 'mission/UPDATE_OBJECTIVE_STATUS_ERROR';

export const UPDATE_TASKS_CURRENTLY_SAVING = 'mission/UPDATE_TASKS_CURRENTLY_SAVING';
export const UPDATE_TASK_STATUS = 'mission/UPDATE_TASK_STATUS';
export const UPDATE_TASK_STATUS_SUCCESS = 'mission/UPDATE_TASK_STATUS_SUCCESS';

export const DELETE_TASK = 'mission/DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'mission/DELETE_TASK_SUCCESS';

export const ARCHIVE_OBJECTIVE = 'mission/ARCHIVE_OBJECTIVE';
export const ARCHIVE_OBJECTIVE_SUCCESS = 'mission/ARCHIVE_OBJECTIVE_SUCCESS';

export const UPDATE_MISSION = 'mission/UPDATE_MISSION';
export const UPDATE_MISSION_SUCCESS = 'mission/UPDATE_MISSION_SUCCESS';
export const UPDATE_MISSION_ERROR = 'mission/UPDATE_MISSION_ERROR';

export const CREATE_OBJECTIVE = 'mission/CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_SUCCESS = 'mission/CREATE_OBJECTIVE_SUCCESS';

export const UPDATE_OBJECTIVE = 'mission/UPDATE_OBJECTIVE';
export const UPDATE_OBJECTIVE_SUCCESS = 'mission/UPDATE_OBJECTIVE_SUCCESS';

export const OBJECTIVE_SAVING_STATE = 'mission/OBJECTIVE_SAVING_STATE';

export const CLEAR_SELECTED_MISSION = 'mission/CLEAR_SELECTED_MISSION';

export const MISSION_TIMELINE_LOADING_STATE = 'mission/MISSION_TIMELINE_LOADING_STATE';

export const OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE = 'mission/OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE';
export const GET_OBJECTIVE_SYSTEM_COMMENTS = 'missions/GET_OBJECTIVES_SYSTEM_COMMENTS';
export const GET_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS = 'missions/GET_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS';

export const GET_ALL_OFFICER_MISSIONS = 'missions/GET_ALL_OFFICER_MISSIONS';
export const GET_ALL_OFFICER_MISSIONS_SUCCESS = 'missions/GET_ALL_OFFICER_MISSIONS_SUCCESS';

export const SET_SELECTED_MISSION = 'missions/SET_SELECTED_MISSION';
export const SET_SELECTED_OBJECTIVE = 'missions/SET_SELECTED_OBJECTIVE';

export const OBJECTIVE_PRACTICE_LOADING_STATE = 'missions/OBJECTIVE_PRACTICE_LOADING_STATE';
export const CREATE_OBJECTIVE_PRACTICE = 'missions/CREATE_OBJECTIVE_PRACTICE';
export const CREATE_OBJECTIVE_PRACTICE_SUCCESS = 'missions/CREATE_OBJECTIVE_PRACTICE_SUCCESS';

export const UPDATE_OBJECTIVE_PRACTICE = 'missions/UPDATE_OBJECTIVE_PRACTICE';
export const UPDATE_OBJECTIVE_PRACTICE_SUCCESS = 'missions/UPDATE_OBJECTIVE_PRACTICE_SUCCESS';

export const DELETE_OBJECTIVE_PRACTICE = 'missions/DELETE_OBJECTIVE_PRACTICE';
export const DELETE_OBJECTIVE_PRACTICE_SUCCESS = 'missions/DELETE_OBJECTIVE_PRACTICE_SUCCESS';

// Tasks

export const CREATE_TASK = 'missions/CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'missions/CREATE_TASK_SUCCESS';
export const CREATE_TASK_ERROR = 'missions/CREATE_TASK_ERROR';

export const UPDATE_TASK = 'missions/UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'missions/UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'missions/UPDATE_TASK_ERROR';
