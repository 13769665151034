import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles({
  paper: {
    borderRadius: 10,
    padding: '25px 20px'
  },
  mb20: {
    marginBottom: 20
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  backgroundCover: {
    borderRadius: 5,
    color: '#dcdcdc',
    background: '#dcdcdc',
    animation: '$glow 1.5s ease-in-out infinite'
  },
  description: {
    height: 150
  }
});

export default function CommunityDetailsLoading() {
  const classes = styles();
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.mb20}>
          <Typography className={classes.backgroundCover}>Loading</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(classes.backgroundCover, classes.description)}>
            Loading
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
