/* eslint-disable */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import AuthLoading from '../../components/Auth/AppLoadingPage/AppLoadingPage'

import AuthBackground from '../../components/Auth/AuthBackground/AuthBackground';
import { ReactComponent as Logo } from '../../static/images/logoMark.svg';
import appleStore from '../../static/images/apple-appstore@3x.png';
import googlePlay from '../../static/images/google-play@3x.png';

import * as authTypes from '../../state/auth/types';

import screenshots from '../../static/images/mobileApp.png';
import authStyles from './authStyles';

const styles = makeStyles({
  ...authStyles,
  title: {
    fontWeight: 700,
    marginBottom: 10,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  copy: {
    fontSize: 14,
    marginBottom: 40,
    maxWidth: 345,
    textAlign: 'center',
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif'
  },
  logo: {
    width: 142,
    height: 42,
    margin: 7
  },
  loginWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  login: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  }
});

export default function DownloadTheApp() {
  // BOSS2-1608 - Redirect to external landing page
  window.location.replace('https://onmission.app/get-app/');

  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: authTypes.AUTH_LOGOUT, redirect: false });
  }, []);

  function backToLogin() {
    history.push('/login');
  }

  // BOSS2-1608 - Don't render this page (see redirect above)
  return <AuthLoading />;

  return (
    <AuthBackground>
      <Container className={classes.appContainer}>
        <div className={classes.appContent}>
          <div className={classes.loginWrap}>
            <div className={classes.login} onClick={backToLogin} role="presentation">
              Back to login
            </div>
          </div>
          <Logo className={classes.logo} />
          <Typography variant="h1" className={classes.title} align="center">
            Download the NXT LVL app!
          </Typography>
          <Typography className={classes.copy}>
            It looks like you don't have permission to access this portal. Try
            logging in to the NXT LVL app.
          </Typography>
          <div className={classes.appStores}>
            <img
              src={appleStore}
              alt="Apple store"
              className={classes.logo}
              style={{ height: 44 }}
            />
            <img src={googlePlay} alt="Goolge store" className={classes.logo} />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <img
            src={screenshots}
            alt="NXT LVL mobile app screenshots"
            className={classes.mobileScreenshots}
          />
        </div>
      </Container>
    </AuthBackground>
  );
}
