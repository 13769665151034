export const AUTH_LOGIN = 'auth/AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'auth/AUTH_LOGIN_ERROR';

export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'auth/AUTH_LOGOUT_SUCCESS';

export const AUTH_REFRESH = 'auth/AUTH_REFRESH';
export const AUTH_REFRESH_SUCCESS = 'auth/AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_ERROR = 'auth/AUTH_REFRESH_ERROR';
export const AUTH_REFRESH_DONE = 'auth/AUTH_REFRESH_DONE';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR';
export const CLEAR_RESET_PASSWORD_REQUESTED = 'auth/CLEAR_RESET_PASSWORD_REQUESTED';
export const CLEAR_RESET_PASSWORD = 'auth/CLEAR_RESET_PASSWORD';

export const GET_LOGGED_IN_USER = 'auth/GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_SUCCESS = 'auth/GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_ERROR = 'auth/GET_LOGGED_IN_USER_ERROR';

export const SET_NEW_PASSWORD = 'auth/SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'auth/SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_ERROR = 'auth/SET_NEW_PASSWORD_ERROR';

export const SET_AUTH_FROM_TOKEN = 'auth/SET_AUTH_FROM_TOKEN';

export const ACCEPT_INVITATION = 'auth/ACCEPT_INVITATION';
export const ACCEPT_INVITATION_SUCCESS = 'auth/ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'auth/ACCEPT_INVITATION_ERROR';
export const CLEAR_ACCEPT_INVITE = 'auth/CLEAR_ACCEPT_INVITE';

export const ACCEPT_EMAIL_CHANGE = 'auth/ACCEPT_EMAIL_CHANGE';
export const ACCEPT_EMAIL_CHANGE_SUCCESS = 'auth/ACCEPT_EMAIL_CHANGE_SUCCESS';
export const ACCEPT_EMAIL_CHANGE_ERROR = 'auth/ACCEPT_EMAIL_CHANGE_ERROR';
export const CLEAR_EMAIL_CHANGE = 'auth/CLEAR_EMAIL_CHANGE';

export const CHANGE_OWN_PASSWORD = 'auth/CHANGE_OWN_PASSWORD';
export const CHANGE_OWN_PASSWORD_SUCCESS = 'auth/CHANGE_OWN_PASSWORD_SUCCESS';
export const CHANGE_OWN_PASSWORD_ERROR = 'auth/CHANGE_OWN_PASSWORD_ERROR';

export const REQUEST_CHANGE_OWN_EMAIL = 'auth/REQUEST_CHANGE_OWN_EMAIL';
export const REQUEST_CHANGE_OWN_EMAIL_SUCCESS =
  'auth/REQUEST_CHANGE_OWN_EMAIL_SUCESS';
export const REQUEST_CHANGE_OWN_EMAIL_ERROR = 'auth/REQUEST_CHANGE_OWN_EMAIL_ERROR';

export const GET_OWN_EMAIL_CHANGE_REQUEST = 'auth/GET_OWN_EMAIL_CHANGE_REQUEST';
export const GET_OWN_EMAIL_CHANGE_REQUEST_SUCCESS =
  'auth/GET_OWN_EMAIL_CHANGE_REQUEST_SUCCESS';
export const GET_OWN_EMAIL_CHANGE_REQUEST_ERROR =
  'auth/GET_OWN_EMAIL_CHANGE_REQUEST_ERROR';

export const CANCEL_OWN_EMAIL_CHANGE = 'auth/CANCEL_OWN_EMAIL_CHANGE';
export const CANCEL_OWN_EMAIL_CHANGE_SUCCESS =
  'auth/CANCEL_OWN_EMAIL_CHANGE_SUCCESS';
export const CANCEL_OWN_EMAIL_CHANGE_ERROR = 'auth/CANCEL_OWN_CHANGE_ERROR';

export const UPDATE_OWN_PROFILE = 'auth/UPDATE_OWN_PROFILE';
export const UPDATE_OWN_PROFILE_SUCCESS = 'auth/UPDATE_OWN_PROFILE_SUCCESS';
export const UPDATE_OWN_PROFILE_ERROR = 'auth/UPDATE_OWN_PROFILE_ERROR';

export const AUTH_LOADING_STATE = 'auth/AUTH_LOADING_STATE';
export const AUTH_SAVING_STATE = 'auth/AUTH_SAVING_STATE';
