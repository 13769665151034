import * as types from './types';

const initialState = {
  isBoostraped: false,
  boostrapError: null,
  ifOfficerBootstraped: false
};

const bootstrapReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BOOTSTRAP:
      return {
        ...state,
        isBoostraped: false,
        boostrapError: null
      }
    case types.BOOTSTRAP_ERROR:
      return {
        ...state,
        boostrapError: action.error
      }
    case types.BOOTSTRAP_COMPLETE:
      return {
        ...state,
        isBoostraped: true,
      }
    default: 
      return {
        ...state
      }
  }
}

export default bootstrapReducer;