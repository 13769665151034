import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TextInput from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';

import { getErrorMessage } from '../../constants/validation';

const useStyles = makeStyles({
  wrap: {
    padding: 40
  },
  title: {
    fontSize: 18,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 20
  },
  textWrap: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    paddingTop: 16,
    '&:not(:last-child)': {
      paddingRight: 10
    }
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  text: {
    fontSize: 14,
    fontFamily: 'SFUIText-Light'
  },
  action: {
    width: 211,
    paddingRight: 10
  },
  qty: {
    width: 117,
    paddingRight: 10
  },
  thing: {
    width: 390
  },
  daysActive: {
    width: 130,
    paddingRight: 10
  },
  dueDate: {
    width: 189,
    paddingRight: 10
  },
  outcome: {
    width: 348
  }
});

const ObjectiveStatement = ({
  onChange,
  action,
  // qty,
  thing,
  daysOnceActive,
  outcome,
  errors,
  dueDate,
  isTemplate,
  onDueDateChange
}) => {
  // Hooks
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Typography className={classes.title}>Objective Statement</Typography>
      <Box className={classes.formRow}>
        <Box className={classes.textWrap}>
          <Typography className={classes.text}>I will</Typography>
        </Box>
        <TextInput
          className={classes.action}
          name="verb"
          label="Action"
          required
          value={action}
          onChange={onChange}
          errorMessage={getErrorMessage(errors, 'verb')}
          showError={false}
        />
        {/* <TextInput
          name="metric"
          label="Qty"
          value={qty || ''}
          showOptional
          onChange={onChange}
          className={classes.qty}
          showError={false}
        /> */}
        <TextInput
          name="noun"
          label="Thing"
          required
          value={thing}
          onChange={onChange}
          className={classes.thing}
          errorMessage={getErrorMessage(errors, 'noun')}
          showError={false}
        />
      </Box>
      <Box className={classes.formRow}>
        <Box className={classes.textWrap}>
          <Typography className={classes.text}>{isTemplate ? 'within' : 'by'}</Typography>
        </Box>
        {isTemplate ? (
          <TextInput
            name="daysOnceActive"
            label="Days once active"
            required
            value={daysOnceActive}
            onChange={onChange}
            className={classes.daysActive}
            errorMessage={getErrorMessage(errors, 'daysOnceActive')}
            showError={false}
            formControlStyle={{ marginBottom: -20 }} // Negative margin to offset the helper message
            helperMessage="e.g. 30"
          />
        ) : (
          <Datepicker
            label="Date"
            required
            value={dueDate ? moment(dueDate) : moment()}
            chevron
            className={classes.dueDate}
            onChange={onDueDateChange}
          />
        )}
        <Box className={classes.textWrap}>
          <Typography className={classes.text}>{isTemplate ? 'days in order to' : 'in order to'}</Typography>
        </Box>
        <TextInput
          name="outcome"
          label="Outcome"
          required
          value={outcome}
          onChange={onChange}
          className={classes.outcome}
          errorMessage={getErrorMessage(errors, 'outcome')}
          showError={false}
        />
      </Box>
    </Box>
  );
};

ObjectiveStatement.propTypes = {
  onChange: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  // qty: PropTypes.string,
  thing: PropTypes.string.isRequired,
  daysOnceActive: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outcome: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isTemplate: PropTypes.bool,
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
  onDueDateChange: PropTypes.func.isRequired
};

ObjectiveStatement.defaultProps = {
  errors: null,
  isTemplate: false,
  // qty: '',
  dueDate: moment().add(1, 'days'),
  daysOnceActive: 7
};

export default ObjectiveStatement;
