import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TemplateSearch from '../components/MissionTemplates/TemplateSearch';
import TemplateList from '../components/MissionTemplates/TemplateList';

import * as templateTypes from '../state/templates/types';
import {
  selectSelectedCommunity,
  selectCurrentUserCommunityPermissions
} from '../state/community/reducers';
import {
  selectCommunityTemplates,
  selectCommunityTemplateLoading
} from '../state/templates/reducers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['title', 'description']
};

const useStyles = makeStyles({
  instructions: {
    maxWidth: 480,
    fontSize: 14,
    marginBottom: 46
  }
});

const MissionTemplates = () => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { communityId } = useParams();

  // Fetch community templates on load
  useEffect(() => {
    dispatch({
      type: templateTypes.GET_COMMUNITY_MISSION_TEMPLATES,
      communityId
    });
  }, [dispatch]);

  // Local state
  const [search, setSearch] = useState('');

  // Redux
  const community = useSelector(state => selectSelectedCommunity(state));
  const permissions = useSelector(state =>
    selectCurrentUserCommunityPermissions(state)
  );
  const communityTemplates = useSelector(state => selectCommunityTemplates(state));
  const communityTemplatesLoading = useSelector(state =>
    selectCommunityTemplateLoading(state)
  );

  // Event handlers
  const handleChange = value => {
    setSearch(value);
  };

  const addTemplate = () => {
    dispatch({ type: templateTypes.CLEAR_SELECTED_MISSION_TEMPLATE });
    history.push(`/c/${communityId}/create-mission-template/community`);
  };

  const viewTemplate = template => {
    const { _id: templateId } = template;

    dispatch({
      type: templateTypes.SET_MISSION_TEMPLATE,
      template
    });

    history.push(`/c/${communityId}/mission-template/${templateId}`);
  };

  const editTemplate = template => {
    const { _id: templateId } = template;

    dispatch({
      type: templateTypes.SET_MISSION_TEMPLATE,
      template
    });

    history.push(`/c/${communityId}/mission-template/${templateId}/edit`);
  };

  // Helpers
  const searchTemplates = templates => {
    const fuseInstance = new Fuse(templates, fuseOpts);
    return fuseInstance.search(search);
  };

  // Constants
  const isAdmin = permissions.some(p => p === 'admin');
  const hasSearch = search.trim().length > 0;
  const heading = `Community templates`;
  const computedTemplates = hasSearch
    ? searchTemplates(communityTemplates)
    : communityTemplates;

  return (
    <>
      <Typography className={classes.instructions}>
        As a Community Admin, you can create Mission Templates that leaders of this
        community can assign to their members.
      </Typography>
      <TemplateSearch
        heading={heading}
        value={search}
        onChange={handleChange}
        onAdd={addTemplate}
        readOnly={!isAdmin}
        goldButton
      />
      <TemplateList
        templates={computedTemplates}
        hasSearch={hasSearch}
        onClick={viewTemplate}
        editTemplate={editTemplate}
        loading={communityTemplatesLoading}
        readOnly={!isAdmin}
      />
    </>
  );
};

export default MissionTemplates;
