import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Table from '../components/Table/Table';

import {
  selectOfficerActivityLoading,
  selectOfficerActivity,
  selectCommunityLoadingState,
  selectSelectedCommunityId
} from '../state/community/reducers';

import * as communityTypes from '../state/community/types';
import OfficerActivityRowCard from '../components/RowCards/OfficerActivityCard';

import * as userTypes from '../state/users/types';
import * as missionTypes from '../state/mission/types';

class OfficerDashboard extends Component {
  constructor(props) {
    super(props);
    const { getAgnosticLeaderActivity } = props;
    getAgnosticLeaderActivity(0);
  }

  openViewUser = ({ user }) => {
    const {
      setSelectedUser,
      getUserActivity,
      getUserMissions,
      getUserObjectives,
      getUserLifeEvents,
      communityId,
      history
    } = this.props;
    setSelectedUser(user);
    getUserMissions(user._id);
    getUserObjectives(user._id);
    getUserLifeEvents(user._id);
    // get the objectives for a given user

    // TODO - get agnostic activity for specific user
    getUserActivity(communityId, user._id);
    history.push(`/c/${communityId}/u/${user._id}/o/activity`);
  };

  handleTablePageChange = newPage => {
    const { getAgnosticLeaderActivity } = this.props;
    getAgnosticLeaderActivity(newPage);
  };

  render() {
    const { activityLoading, communityLoading, leaderActivity } = this.props;

    const { total, activities = [] } = leaderActivity;

    // Helper constants

    const loading = communityLoading || activityLoading;

    const selectPageActivities = page =>
      activities
        .find(a => a.page === page)
        ?.data?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)) || [];

    return (
      <React.Fragment>
        <Table
          loading={loading}
          rowCard={OfficerActivityRowCard}
          onClick={this.openViewUser}
          rowsPerPage={25}
          cardHeight={40}
          totalCount={total}
          onChangePage={this.handleTablePageChange}
          dataSelector={selectPageActivities}
        />
      </React.Fragment>
    );
  }
}

OfficerDashboard.propTypes = {
  activityLoading: PropTypes.bool.isRequired,
  leaderActivity: PropTypes.object.isRequired,
  communityLoading: PropTypes.bool.isRequired,
  getAgnosticLeaderActivity: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  getUserMissions: PropTypes.func.isRequired,
  getUserActivity: PropTypes.func.isRequired,
  getUserObjectives: PropTypes.func.isRequired,
  communityId: PropTypes.string.isRequired,
  getUserLifeEvents: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  activityLoading: selectOfficerActivityLoading(state),
  leaderActivity: selectOfficerActivity(state),
  communityLoading: selectCommunityLoadingState(state),
  communityId: selectSelectedCommunityId(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAgnosticLeaderActivity: page => ({
        type: communityTypes.GET_AGNOSTIC_LEADER_ACTIVITY,
        page
      }),
      setSelectedUser: (user, leaders = []) => ({
        type: userTypes.SET_SELECTED_USER,
        user,
        leaders
      }),
      getUserMissions: userId => ({
        type: missionTypes.GET_ALL_OFFICER_MISSIONS,
        userId
      }),
      getUserActivity: (communityId, userId) => ({
        type: userTypes.GET_USER_ACTIVITY,
        communityId,
        userId
      }),
      getUserLifeEvents: userId => ({
        type: userTypes.GET_USER_LIFE_EVENTS,
        userId
      }),
      getUserObjectives: userId => ({
        type: missionTypes.GET_USER_OBJECTIVES,
        userId,
        leaderReq: false
      })
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfficerDashboard));
