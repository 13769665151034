import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = makeStyles({
  routeList: {
    marginTop: 40
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  container: {
    padding: '15px 30px',
    borderRadius: 10,
    display: 'flex',
  },
  listItemText: {
    width: '100%',
    backgroundColor: '#f7f7f7',
    color: '#f7f7f7',
    animation: '$glow 1.5s ease-in-out infinite'
  }
});

export default function MenuDrawerLoading() {
  const classes = styles();
  return (
    <List className={classes.routeList}>
      {[...new Array(5)].map((el, idx) => (
        <ListItem key={`loading-${idx}`} className={classes.container}>
          <ListItemText
            classes={{
              root: classes.listItemText
            }}
            primary="Loading"
          />
        </ListItem>
      ))}
    </List>
  );
}
