import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as authServices from '@/state/auth/services';

import { selectLoggedInUserId, selectAuthTimestamp, selectLoggedInUser, selectIsOfficer } from '@/state/auth/reducers';
import {
  selectSelectedCommunityId,
  selectAllCommunities,
  selectCurrentUserCommunityPermissions,
  selectCommunityLoadingState
} from '../state/community/reducers';

import * as authTypes from '../state/auth/types';
import * as communityTypes from '../state/community/types';

import { checkForPermissions, includesCommunity, isLeader, isCommunityAdmin } from '../utils/helpers';

class AuthGuardian extends Component {
  componentDidMount() {
    // Check secure
    this.loggedInCheck();
    this.tokenCheck();
    this.checkPermissions();
  }

  componentDidUpdate() {
    // Check secure
    this.loggedInCheck();
    this.tokenCheck();
    this.checkPermissions();
  }

  tokenCheck = () => {
    const { history, loggedInUser } = this.props;

    // If either token is null - redirect to login
    if (loggedInUser === null) history.push('/login');
  };

  loggedInCheck = () => {
    const { history } = this.props;

    const _loggedIn = authServices.getLocalStore('loggedIn');
    const loggedIn = _loggedIn === 'true';
    if (!loggedIn) history.push('/');
  };

  checkPermissions = () => {
    const {
      isOfficer,
      history,
      selectedCommunityId,
      currentCommunityPermissions,
      routePermissions,
      loggedInUser,
      allCommunities,
      match: { params },
      getCommunity,
      logout,
      communityLoading
    } = this.props;

    // Check is a leader and is a community admin
    if (!isOfficer && !isLeader(loggedInUser.memberships) && !isCommunityAdmin(loggedInUser.memberships)) {
      console.log('AUTH GUARDIAN: Not a leader, officer or admin. Booting to app screen.');
      logout();
      history.push('/nxt-lvl-app');
    }

    // NOTE: Maybe a 'change community' saga is required to clean this up
    // Check the user has permission for this communityId
    else if (params.communityId !== undefined) {
      // Check the current redux community matchs the url
      const { communityId } = params;
      const isCommunity = selectedCommunityId === communityId;

      // If they don't match - run some checks
      if (!isCommunity && !communityLoading) {
        if (isOfficer) {
          // Check if community exists
          const communityExists = includesCommunity(allCommunities, communityId);
          if (communityExists) {
            getCommunity(communityId);
            console.log('AUTH GUARDIAN: User is an officer - letting them in');
          } else history.push(`/c/${selectedCommunityId}/uhoh`);
        } else {
          // Check user has permissions for the community in url
          const { memberships } = loggedInUser;
          const isInCommunity = checkForPermissions(memberships, communityId) || isOfficer;

          if (isInCommunity) {
            getCommunity(communityId);
            console.log('AUTH GUARDIAN: User has permission to enter');
          } else history.push(`/c/${selectedCommunityId}/uhoh`);
        }
      } else {
        const userIsLeader = currentCommunityPermissions.includes('leader');
        const userIsAdmin = currentCommunityPermissions.includes('admin');
        const leaderRoute = routePermissions.includes('leader');
        const adminRoute = routePermissions.includes('admin');
        const missionRoute = params.missionId !== undefined;
        const objectiveRoute = params.objectiveId !== undefined;

        // Handle redirecting from leader/admin routes based on permissions
        if (
          (isOfficer || userIsAdmin) &&
          !userIsLeader &&
          leaderRoute &&
          !userIsAdmin &&
          adminRoute &&
          !missionRoute &&
          !objectiveRoute &&
          !communityLoading
        ) {
          history.push(`/c/${selectedCommunityId}/community-details`);
        } else if (
          !communityLoading &&
          !userIsAdmin &&
          !isOfficer &&
          adminRoute &&
          userIsLeader &&
          !missionRoute &&
          !objectiveRoute
        ) {
          history.push(`/c/${selectedCommunityId}/activity`);
        }
      }
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCommunity: communityId => ({
        type: communityTypes.GET_COMMUNITY,
        communityId
      }),
      logout: () => ({
        type: authTypes.AUTH_LOGOUT
      })
    },
    dispatch
  );

const mapStateToProps = state => ({
  userId: selectLoggedInUserId(state),
  authTimestamp: selectAuthTimestamp(state),
  loggedInUser: selectLoggedInUser(state),
  selectedCommunityId: selectSelectedCommunityId(state),
  currentCommunityPermissions: selectCurrentUserCommunityPermissions(state),
  isOfficer: selectIsOfficer(state),
  allCommunities: selectAllCommunities(state),
  communityLoading: selectCommunityLoadingState(state)
});

AuthGuardian.propTypes = {
  children: PropTypes.node.isRequired,
  isOfficer: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  selectedCommunityId: PropTypes.string.isRequired,
  currentCommunityPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  routePermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  loggedInUser: PropTypes.object.isRequired,
  allCommunities: PropTypes.array,
  match: PropTypes.object.isRequired,
  getCommunity: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  communityLoading: PropTypes.bool.isRequired
};

AuthGuardian.defaultProps = {
  allCommunities: []
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthGuardian));
