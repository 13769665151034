export const COMMUNITY_MISSION_TEMPLATE_LOADING_STATE =
  'templates/COMMUNITY_MISSION_TEMPLATE_LOADING_STATE';
export const LEADER_MISSION_TEMPLATE_LOADING_STATE =
  'templates/LEADER_MISSION_TEMPLATE_LOADING_STATE';
export const MISSION_TEMPLATE_SAVING_STATE =
  'templates/MISSION_TEMPLATE_SAVING_STATE';

export const GET_COMMUNITY_MISSION_TEMPLATES =
  'templates/GET_COMMUNITY_MISSION_TEMPLATES';
export const GET_COMMUNITY_MISSION_TEMPLATES_SUCCESS =
  'templates/GET_COMMUNITY_MISSION_TEMPLATES_SUCCESS';
export const GET_COMMUNITY_MISSION_TEMPLATES_ERROR =
  'templates/GET_COMMUNITY_MISSION_TEMPLATES_ERROR';

export const STORE_PROFILE_IMAGE = 'templates/STORE_PROFILE_IMAGE';
export const UPDATE_PROFILE_IMAGE = 'templates/UPDATE_PROFILE_IMAGE';

export const GET_LEADER_MISSION_TEMPLATES = 'templates/GET_LEADER_MISSION_TEMPLATES';
export const GET_LEADER_MISSION_TEMPLATES_SUCCESS =
  'templates/GET_LEADER_MISSION_TEMPLATES_SUCCESS';
export const GET_LEADER_MISSION_TEMPLATES_ERROR =
  'templates/GET_LEADER_MISSION_TEMPLATES_ERROR';

export const GET_MISSION_BY_TEMPLATE = 'templates/GET_MISSION_BY_TEMPLATE';
export const GET_MISSION_BY_TEMPLATE_SUCCESS = 'templates/GET_MISSION_BY_TEMPLATE_SUCCESS';
export const GET_MISSION_BY_TEMPLATE_ERROR = 'templates/GET_MISSION_BY_TEMPLATE_ERROR';

export const GET_MISSION_TEMPLATE = 'templates/GET_MISSION_TEMPLATE';
export const GET_MISSION_TEMPLATE_SUCCESS = 'templates/GET_MISSION_TEMPLATE_SUCCESS';
export const GET_MISSION_TEMPLATE_ERROR = 'templates/GET_MISSION_TEMPLATE_ERROR';

export const CREATE_MISSION_TEMPLATE = 'templates/CREATE_MISSION_TEMPLATE';
export const CREATE_MISSION_TEMPLATE_ERROR =
  'templates/CREATE_MISSION_TEMPLATE_ERROR';
export const CREATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS =
  'templates/CREATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS';
export const CREATE_LEADER_MISSION_TEMPLATE_SUCCESS =
  'templates/CREATE_LEADER_MISSION_TEMPLATE_SUCCESS';

export const UPDATE_MISSION_TEMPLATE = 'templates/UPDATE_MISSION_TEMPLATE';
export const UPDATE_MISSION_TEMPLATE_ERROR =
  'templates/UPDATE_MISSION_TEMPLATE_ERROR';

export const UPDATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS =
  'templates/UPDATE_COMMUNITY_MISSION_TEMPLATE_SUCCESS';
export const UPDATE_LEADER_MISSION_TEMPLATE_SUCCESS =
  'templates/UPDATE_LEADER_MISSION_TEMPLATE_SUCCESS';

export const SET_MISSION_TEMPLATE = 'templates/SET_MISSION_TEMPLATE';
export const CLEAR_SELECTED_MISSION_TEMPLATE =
  'templates/CLEAR_SELECTED_MISSION_TEMPLATE';

export const ASSIGN_MISSION_TEMPLATE_SAVING_STATE =
  'templates/ASSIGN_MISSION_TEMPLATE_SAVING_STATE';
export const ASSIGN_MISSION_TEMPLATES = 'templates/ASSIGN_MISSION_TEMPLATES';
export const ASSIGN_MISSION_TEMPLATES_SUCCESS =
  'templates/ASSIGN_MISSION_TEMPLATES_SUCCESS';
export const ASSIGN_MISSION_TEMPLATES_ERROR =
  'templates/ASSIGN_MISSION_TEMPLATES_ERROR';

export const DELETE_MISSION_TEMPLATE = 'templates/DELETE_MISSION_TEPLATE';
export const DELETE_MISSION_TEMPLATE_ERROR =
  'templates/DELETE_MISSION_TEMPLATE_ERROR';
export const DELETE_COMMUNITY_MISSION_TEMPLATE_SUCCESS =
  'templates/DELETE_COMMUNITY_MISSION_TEMPLATE_SUCCESS';
export const DELETE_LEADER_MISSION_TEMPLATE_SUCCESS =
  'templates/DELETE_LEADER_MISSION_TEMPLATE_SUCCESS';
