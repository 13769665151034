import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as teamActions from './actions';
import * as teamTypes from './types';

import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

import { selectTeams, selectUsersPendingChange } from './reducers';

const replaceUpdatedTeam = (teams, updatedTeam) =>
  teams.map(team => {
    if (team._id === updatedTeam._id) return updatedTeam;
    return team;
  });

const replaceUsersPending = (users, userId) => users.filter(u => u !== userId);

function* createTeam({ communityId, payload }) {
  try {
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: true });

    const result = yield call(teamActions.createTeam, communityId, payload);
    const { data } = result;
    yield put({ type: teamTypes.CREATE_TEAM_SUCCESS, team: data });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Team successfully created!',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: teamTypes.CREATE_TEAM_ERROR,
      errors: {
        key: teamTypes.CREATE_TEAM_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  }
}

function* updateTeam({ communityId, teamId, payload }) {
  try {
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: true });

    const result = yield call(teamActions.updateTeam, communityId, teamId, payload);
    const { data } = result;

    const teams = yield select(selectTeams);
    const updatedTeams = replaceUpdatedTeam(teams, data);

    yield put({
      type: teamTypes.UPDATE_TEAM_SUCCESS,
      team: data,
      teams: updatedTeams
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Team successfully updated!',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: teamTypes.UPDATE_TEAM_ERROR,
      errors: {
        key: teamTypes.UPDATE_TEAM_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  }
}

function* deleteTeam({ communityId, teamId }) {
  try {
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: true });

    // If successful, set selected team to null and remove from in state teams
    yield call(teamActions.deleteTeam, communityId, teamId);
    const teams = yield select(selectTeams);

    yield put({
      type: teamTypes.DELETE_TEAM_SUCCESS,
      team: null,
      teams: teams.filter(team => team._id !== teamId)
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Team successfully deleted!',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: teamTypes.DELETE_TEAM_ERROR,
      errors: {
        key: teamTypes.DELETE_TEAM_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  }
}

function* addMembersToTeam({ communityId, teamId, users }) {
  try {
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: true });

    const result = yield call(
      teamActions.addMembersToTeam,
      communityId,
      teamId,
      users
    );
    const { data } = result;

    const teams = yield select(selectTeams);
    const pendingUsers = yield select(selectUsersPendingChange);
    const updatedTeams = replaceUpdatedTeam(teams, data);
    const updatedUsersPending = pendingUsers.filter(
      p => !users.some(u => u._id === p)
    );

    yield put({
      type: teamTypes.ADD_MEMBERS_TO_TEAM_SUCCESS,
      team: data,
      teams: updatedTeams,
      usersPendingChange: updatedUsersPending
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'User has been added to the team',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    // Remove user from pending requests
    const pendingUsers = yield select(selectUsersPendingChange);
    const updatedUsersPending = pendingUsers.filter(
      p => !users.some(u => u._id === p._id)
    );

    yield put({
      type: teamTypes.ADD_MEMBERS_TO_TEAM_ERROR,
      usersPendingChange: updatedUsersPending,
      errors: {
        key: teamTypes.ADD_MEMBERS_TO_TEAM_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  }
}

function* removeMemberFromTeam({ communityId, teamId, userId }) {
  try {
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: true });

    const result = yield call(
      teamActions.removeMemberFromTeam,
      communityId,
      teamId,
      userId
    );
    const { data } = result;

    const teams = yield select(selectTeams);
    const users = yield select(selectUsersPendingChange);
    const updatedTeams = replaceUpdatedTeam(teams, data);
    const updatedUsersPending = users.filter(u => u !== userId);

    yield put({
      type: teamTypes.REMOVE_MEMBER_FROM_TEAM_SUCCESS,
      team: data,
      teams: updatedTeams,
      usersPendingChange: updatedUsersPending
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'User has been removed from the team',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    // Remove user from pending requests
    const users = yield select(selectUsersPendingChange);
    const updatedUsersPending = users.filter(u => u !== userId);

    yield put({
      type: teamTypes.REMOVE_MEMBER_FROM_TEAM_ERROR,
      usersPendingChange: updatedUsersPending,
      errors: {
        key: teamTypes.REMOVE_MEMBER_FROM_TEAM_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_SAVING_STATE, state: false });
  }
}

function* getCommunityTeams({ communityId, leaderReq }) {
  try {
    // Only show loading if no data exists
    const teams = yield select(selectTeams);
    if (!teams.length) {
      yield put({ type: teamTypes.TEAM_LOADING_STATE, state: true });
    }

    const result = yield call(teamActions.getAllTeams, communityId, leaderReq);
    const {
      data: { results }
    } = result;
    yield put({ type: teamTypes.GET_ALL_TEAMS_SUCCESS, teams: results });

    yield put({ type: teamTypes.TEAM_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: teamTypes.GET_ALL_TEAMS_ERROR,
      errors: {
        key: teamTypes.GET_ALL_TEAMS_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
    yield put({ type: teamTypes.TEAM_LOADING_STATE, state: false });
  }
}

export default [
  takeLatest(teamTypes.CREATE_TEAM, createTeam),
  takeLatest(teamTypes.UPDATE_TEAM, updateTeam),
  takeLatest(teamTypes.DELETE_TEAM, deleteTeam),
  takeLatest(teamTypes.ADD_MEMBERS_TO_TEAM, addMembersToTeam),
  takeLatest(teamTypes.REMOVE_MEMBER_FROM_TEAM, removeMemberFromTeam),
  takeLatest(teamTypes.GET_ALL_TEAMS, getCommunityTeams)
];
