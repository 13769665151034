import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dl: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto'
  },
  dlSmall: {
    fontSize: '12px',
    gridGap: '0px'
  },
  dt: {
    gridColumnStart: 1,
    fontWeight: 'bold'
  },
  dd: {
    gridColumnStart: 2,
    margin: '0 0 0 5px'
  }
});

const SpecList = ({ data, size }) => {
  const classes = useStyles();

  const containerClass = clsx({
    [classes.dl]: true,
    [classes.dlSmall]: size === 'small'
  });

  return (
    <div className={containerClass}>
      {data.map((d, i) => {
        return (
          <React.Fragment key={`${d.value}-${i}`}>
            <div className={classes.dt} style={d.style}>
              {d.label}:
            </div>
            <div className={classes.dd} style={d.style}>
              {d.value}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

SpecList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium'])
};

SpecList.defaultProps = {
  size: 'medium'
};

export default SpecList;
