import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import RoleChip from './RoleChip';
import AssignedLeaders from '../AssignedLeaders/AssignedLeaders';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bio: {
    textAlign: 'center',
    marginBottom: 10,
    maxWidth: 400
  },
  metaWrap: {
    margin: '40px 0px'
  },
  grid: {
    maxWidth: 400,
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  leaderWrap: {
    width: '100%',
    marginTop: 20,
    padding: '18px 15px'
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    padding: '13px 20px',
    borderRadius: 10,
    height: 40,
    width: 260,
    fontSize: 12
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: '#838b96',
    fontWeight: 500,
    fontSize: 10,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  flex: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const Meta = ({ label, content }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={3}>
        <Typography className={classes.label} noWrap>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography className={classes.meta} noWrap>
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
};

Meta.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string
};

Meta.defaultProps = {
  content: ''
};

const MemberProfile = ({
  roles,
  birthday,
  phoneNumber,
  email,
  leaders,
  hasData
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.flexCenter}>
        {hasData && (
          <>
            <div style={{ marginTop: 10 }}>
              {roles.map((r, idx) => {
                const finalIdx = idx === roles.length - 1;
                return (
                  <RoleChip
                    key={r}
                    role={r}
                    style={{ marginRight: finalIdx ? 0 : 10 }}
                  />
                );
              })}
            </div>
            <div className={classes.metaWrap}>
              {birthday && (
                <Meta
                  label="Date of birth"
                  content={moment(birthday).format('DD MMMM YYYY')}
                />
              )}
              {phoneNumber && <Meta label="Phone number" content={phoneNumber} />}
              <Meta label="Email address" content={email} />
            </div>
          </>
        )}
        <Paper elevation={0} className={classes.leaderWrap}>
          {hasData ? (
            <AssignedLeaders readOnly assignedLeaders={leaders} />
          ) : (
            <div className={classes.flex}>
              <CircularProgress />
            </div>
          )}
        </Paper>
      </div>
    </>
  );
};

MemberProfile.propTypes = {
  roles: PropTypes.array,
  birthday: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  leaders: PropTypes.array,
  hasData: PropTypes.bool
};

MemberProfile.defaultProps = {
  roles: [],
  birthday: '',
  phoneNumber: '',
  email: '',
  leaders: [],
  hasData: false
};

export default MemberProfile;
