import axios from 'axios';
import * as chatTypes from '../chat/types';

import { fbDb } from '../../utils/fcmConfig';

// this is a problem as this file is initialized before the env
const createFirebaseMiddleware = () => {
  const tenantId = process.env.REACT_APP_TENANT_ID || window.__env__.REACT_APP_TENANT_ID;

  // Handlers
  const streamChat = (userId, observer) => {
    const db = fbDb();
    // evaluate the chat token

    return db
      .collection(tenantId)
      .doc('messages')
      .collection(userId)
      .onSnapshot(observer);
  };

  const streamConversations = (userId, observer) => {
    const db = fbDb();
    // evaluate the chat token

    return db
      .collection(tenantId)
      .doc('conversations')
      .collection(userId)
      .onSnapshot(observer);
  };

  const sendMessage = (userId, payload) => {
    console.log('sendMessage', userId, payload);
    const db = fbDb();
    // evaluate the chat token

    return db
      .collection(tenantId)
      .doc('messages')
      .collection(userId)
      .add(payload);
  };

  const markConversationAsRead = (uid, partnerId) => {
    console.log('markConversationAsRead', uid, partnerId);
    const db = fbDb();
    return db
      .collection(tenantId)
      .doc('conversations')
      .collection(uid)
      .doc(partnerId)
      .set({
        lastRead: new Date()
      });
  };

  const sendPushNotification = async (uid, payload) => {
    try {
      await axios.post(`/v1/notify/${uid}`, payload);
    } catch (error) {
      throw error;
    }
  }

  return store => next => action => {
    const state = store.getState();
    const { userId: currentUser } = state.auth;

    switch (action.type) {
      case chatTypes.CONNECT_CHAT:
        streamChat(action.userId, {
          next: querySnapshot => {
            if (querySnapshot.docs) {
              const updatedMessages = querySnapshot.docs.map(docSnapshot => {
                return {
                  ...docSnapshot.data()
                };
              });
              store.dispatch({
                type: chatTypes.UPDATE_CHAT_MESSAGES,
                messages: updatedMessages
              });
            }
          },
          error: e => console.error('Error retrieving message', e)
        });

        streamConversations(action.userId, {
          next: querySnapshot => {
            if (querySnapshot.docs) {
              const updatedConversations = querySnapshot.docs.map(docSnapshot => {
                const data = docSnapshot.data();
                return {
                  lastRead: data.lastRead.toDate(),
                  id: docSnapshot.id
                };
              });
              store.dispatch({
                type: chatTypes.UPDATE_CHAT_CONVERSATIONS,
                payload: updatedConversations
              });
            }
          },
          error: e => console.error('Error retrieving conversations', e)
        });
        break;
      case chatTypes.SEND_MESSAGE:
        sendMessage(action.userId, action.payload);
        sendPushNotification(action.payload.toID, {
          title: `New message from ${action.payload.fromName}`,
          message: action.payload.message
        })
        markConversationAsRead(action.userId, action.payload.toID);
        break;

      case chatTypes.CLOSE_CHAT:
        markConversationAsRead(currentUser, action.userId);
        return next(action);

      default:
        return next(action);
    }
  };
};

export default createFirebaseMiddleware;
