import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import PageHeader from '../components/PageHeader/PageHeader';
import InfoBar from '../components/InfoBar/InfoBar';
import Button from '../components/Button/Button';
import TemplateDetails from '../components/MissionTemplates/TemplateDetails';
import Objectives from '../components/MissionTemplates/Objectives';
import ObjectiveDrawer from '../components/ObjectiveDrawer/ObjectiveDrawer';

import { selectSelectedTemplate } from '../state/templates/reducers';
import { selectCurrentUserCommunityPermissions } from '../state/community/reducers';

import * as templateTypes from '../state/templates/types';

const useStyles = makeStyles({
  ml20: {
    marginLeft: 20
  }
});

const TemplateView = () => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { communityId, templateId } = useParams();

  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(null);

  // Redux
  const selectedTemplate = useSelector(state => selectSelectedTemplate(state));
  const permissions = useSelector(state =>
    selectCurrentUserCommunityPermissions(state)
  );

  // Get mission template if in route and not in redux
  useEffect(() => {
    // There is a template selected but it's the wrong one
    const wrongTemplate = selectedTemplate && selectedTemplate.id !== templateId;
    if (wrongTemplate)
      dispatch({ type: templateTypes.CLEAR_SELECTED_MISSION_TEMPLATE });

    if ((templateId && !selectedTemplate) || wrongTemplate) {
      dispatch({
        type: templateTypes.GET_MISSION_TEMPLATE,
        templateId
      });
    }
  }, [templateId, dispatch, selectedTemplate]);

  // Event handlers
  const openDrawer = () => setDrawerOpen(true);

  const closeDrawer = () => setDrawerOpen(false);

  const onEditTemplate = () => {
    history.push(`/c/${communityId}/mission-template/${templateId}/edit`);
  };

  const viewObjective = objective => {
    setSelectedObjective(objective);
    openDrawer();
  };

  const updateTemplate = payload => {
    dispatch({
      type: templateTypes.UPDATE_MISSION_TEMPLATE,
      isCommunity: selectedTemplate.community !== null,
      template: payload
    });
  };

  const addObjective = objective => {
    updateTemplate({
      ...selectedTemplate,
      objectives: [...selectedTemplate.objectives, objective]
    });
    setSelectedObjective(null);
  };

  const deleteObjective = objective => {
    updateTemplate({
      ...selectedTemplate,
      objectives: selectedTemplate.objectives.filter(o => o.id !== objective.id)
    });
    setSelectedObjective(null);
  };

  const updateObjective = objective => {
    updateTemplate({
      ...selectedTemplate,
      objectives: selectedTemplate.objectives.map(o => {
        if (o.id === objective.id) return objective;
        return o;
      })
    });
    setSelectedObjective(null);
  };

  // Constants
  const objectives = selectedTemplate?.objectives || [];
  const isAdmin = permissions.some(p => p === 'admin');

  const headerActions = (
    <>
      <InfoBar content="You are viewing a Company Mission Template" />
      {isAdmin && (
        <Button
          label="Edit Template"
          onClick={onEditTemplate}
          className={classes.ml20}
        />
      )}
    </>
  );

  return (
    <>
      <PageHeader routeTitle="Template" actions={headerActions} showBack />
      <Box component="main">
        <Box id="template-details" mb={5}>
          <TemplateDetails template={selectedTemplate} />
        </Box>
        <Box id="template-objectives">
          <Objectives
            onAdd={openDrawer}
            objectives={objectives}
            onViewObjective={viewObjective}
            readOnly={!isAdmin}
          />
        </Box>
        <ObjectiveDrawer
          open={drawerOpen}
          onClose={closeDrawer}
          onCreate={addObjective}
          onDelete={deleteObjective}
          selectedObjective={selectedObjective}
          onUpdate={updateObjective}
          isTemplate
          readOnly={!isAdmin}
        />
      </Box>
    </>
  );
};

export default TemplateView;
