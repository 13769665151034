import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  container: {
    padding: '80px 0px'
  },
  helper: {
    fontSize: '0.725rem'
  },
  link: {
    color: '#a5721a',
    cursor: 'pointer'
  }
});

const Authenticated404 = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Typography variant="h1">404</Typography>
      <Typography>Oops... looks like you got lost.</Typography>
      <Typography className={classes.helper}>
        <span onClick={() => history.replace('/')} role="presentation" className={classes.link} title="Go back">
          Click here
        </span>{' '}
        to go back to where you were... Or choose an option from the menu!
      </Typography>
    </div>
  );
};

export default Authenticated404;
