import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import Drawer from './DrawerGeneric';

const Item = ({ activity, entities }) => {
  const title = `${activity.kind} ${activity.action}`;

  return (
    <>
      <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={1}>
        <Typography style={{ fontWeight: 700 }}>{title}</Typography>

        <Typography>
          {`${activity.formattedString.trim()}${activity.formattedEntityTitle ? ':' : ''}`}
          {activity.formattedEntityTitle && <span>{`"${activity.formattedEntityTitle}"`}</span>}
        </Typography>
      </Box>

      <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={3} alignSelf="flex-end" flexGrow={0}>
        {moment(activity.timeStamp).format('DD-MM-YYYY')}
      </Box>
    </>
  );
};

const ObjectiveActivitiesDrawer = ({ activities, entities, ...props }) => {
  return (
    <Drawer width={450} {...props}>
      {activities.length ? (
        activities.map(activity => <Item key={activity.id || Math.random()} activity={activity} entities={entities} />)
      ) : (
        <Typography align="center">There are no activities for this objective</Typography>
      )}
    </Drawer>
  );
};

ObjectiveActivitiesDrawer.propTypes = {
  activities: PropTypes.array
};

ObjectiveActivitiesDrawer.defaultProps = {
  activities: []
};

export default ObjectiveActivitiesDrawer;
