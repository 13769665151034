import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchInput from '../SearchInput/SearchInput';
import ProfileImage from '../ProfileImage/ProfileImage';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName', 'email']
};

const styles = makeStyles({
  assignLeaderButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    width: '100%'
  },
  assignLeaderTypography: {
    fontSize: 11,
    fontWeight: 500
  },
  backdropRoot: {
    zIndex: 1
  },
  listWrap: {
    position: 'relative',
    maxHeight: 400,
    width: '100%'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    padding: '9px 20px 7px 20px',
    borderRadius: 10,
    height: 60,
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
    width: '100%'
  },
  searchContainerTop: {
    position: 'absolute',
    bottom: 0
  },
  leaderList: {
    padding: '0px 10px',
    borderRadius: 10,
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    maxHeight: 330,
    overflow: 'hidden',
    width: '100%'
  },
  leaderListTop: {
    position: 'absolute',
    bottom: 70
  },
  leaderListBottom: {
    position: 'absolute',
    top: 70
  },
  listInner: {
    maxHeight: 330,
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f7f7f7 !important'
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      height: 174,
      borderRadius: 15,
      backgroundColor: '#a5a5a5'
    }
  },
  listInnerPadding: {
    paddingRight: 10
  },
  noAvailable: {
    padding: 10,
    fontWeight: 600
  },
  userCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    height: 60,
    padding: 10,
    marginTop: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dcdcdc'
    },
    '&:last-child': {
      marginBottom: 10
    }
  },
  userCardSelected: {
    backgroundColor: 'red'
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  userName: {
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 500,
    flex: 1
  }
});

export default function UserSelect({
  readOnly,
  users,
  selected,
  buttonText,
  placeholder,
  noDataMessage,
  onSelect,
  saving,
  menuPosition,
  loading,
  multiple
}) {
  const classes = styles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const userObjects = users.map(u => ({
    ...u,
    isSelected: multiple ? selected.includes(u._id) : selected === u._id,
    isLoading: multiple ? loading.includes(u._id) : loading === u._id
  }));

  const selectedUserObjects = userObjects.filter(u => u.isSelected) || [];

  function handleClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  function handleChange(e) {
    setSearch(e);
  }

  function getSearchPosition(anchorTop) {
    if (anchorEl) {
      const rect = anchorEl.getClientRects()[0];
      return {
        position: 'absolute',
        top: anchorTop ? rect.top + 60 : rect.top,
        left: rect.left,
        width: rect.width
      };
    }
    return null;
  }

  function handelSelectSingle(e, user) {
    const { _id } = user;
    const alreadySelected = selected === _id;
    if (alreadySelected) {
      onSelect();
    } else {
      onSelect(user._id);
    }
    handleClose(e);
  }

  function handelSelectMultiple(e, user) {
    const { _id } = user;
    const alreadySelected = selected.includes(_id);
    const result = alreadySelected ? selected.filter(u => u !== _id) : [...selected, user._id];
    onSelect(result);
  }

  function handleSelect(e, user) {
    if (multiple) {
      handelSelectMultiple(e, user);
    } else {
      handelSelectSingle(e, user);
    }
  }

  function searchUsers(data) {
    const fuseInstance = new Fuse(data, fuseOpts);
    return fuseInstance.search(search);
  }

  const open = Boolean(anchorEl);

  const anchorTop = menuPosition !== 'bottom';

  const hasSearch = search.trim().length > 0;
  const computedUsers = hasSearch ? searchUsers(userObjects) : userObjects;

  const hasComputedUsers = computedUsers.length > 0;

  const computedNoDataMsg = hasSearch ? 'No results found' : noDataMessage;

  const UserCell = ({ user, isSelected, accessory, ...props }) => {
    const selectedStyle = isSelected ? { border: '1px solid #223f63' } : {};
    return (
      <div
        key={user._id}
        className={classes.userCard}
        style={selectedStyle}
        onClick={saving ? null : e => handleSelect(e, user)}
        role="presentation"
        {...props}
      >
        <div className={classes.userDetails}>
          <ProfileImage userId={user._id} />
          <Typography className={classes.userName}>{`${user.firstName} ${user.lastName}`}</Typography>
          {user.isLoading && <CircularProgress />}
        </div>
        {accessory}
      </div>
    );
  };

  return (
    <>
      {!readOnly && open && (
        <Backdrop open={open} className={classes.backdropRoot}>
          <ClickAwayListener onClickAway={handleClose}>
            <div style={{ ...getSearchPosition(anchorTop) }}>
              <div className={classes.listWrap}>
                <div
                  className={clsx({
                    [classes.searchContainer]: true,
                    [classes.searchContainerTop]: anchorTop
                  })}
                >
                  <SearchInput
                    value={search}
                    onChange={handleChange}
                    showFocus={false}
                    placeholder={placeholder}
                    style={{ backgroundColor: '#ffffff' }}
                  />
                  <IconButton size="small" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  className={clsx({
                    [classes.leaderList]: true,
                    [classes.leaderListTop]: anchorTop,
                    [classes.leaderListBottom]: !anchorTop
                  })}
                >
                  <div
                    className={clsx({
                      [classes.listInner]: true,
                      [classes.listInnerPadding]: hasComputedUsers
                    })}
                  >
                    {hasComputedUsers ? (
                      computedUsers.map(user => {
                        return <UserCell user={user} isSelected={user.isSelected} key={user._id} />;
                      })
                    ) : (
                      <Typography align="center" className={classes.noAvailable}>
                        {computedNoDataMsg}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </Backdrop>
      )}
      <>
        <div className={classes.assignLeaderButton} onClick={handleClick} role="presentation">
          <Typography className={classes.assignLeaderTypography}>
            <AddIcon />
            {buttonText}
          </Typography>
        </div>

        {selectedUserObjects.map(user => (
          <UserCell
            key={user._id}
            user={user}
            style={{ marginBottom: 10 }}
            accessory={
              multiple ? null : (
                <IconButton size="small" onClick={e => handleSelect(e, user)}>
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        ))}
      </>
    </>
  );
}

UserSelect.propTypes = {
  users: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loading: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  readOnly: PropTypes.bool,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  noDataMessage: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  menuPosition: PropTypes.string,
  saving: PropTypes.bool
};

UserSelect.defaultProps = {
  readOnly: false,
  multiple: true,
  selected: [],
  loading: [],
  buttonText: 'Select a user',
  placeholder: 'Search and/or select a member...',
  noDataMessage: 'Could not find any members!',
  menuPosition: 'bottom',
  saving: false
};
