import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTransition, animated } from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import HelperContent from './HelperContent';
import SelectedTemplateCard from '../MissionTemplates/SelectedTemplateCard';
import SelectedUserSnack from './SelectedUserSnack';

const useStyles = makeStyles({
  paper: {
    padding: 10,
    borderRadius: 10,
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    marginBottom: 25,
    display: 'flex'
  },
  box: {
    display: 'inline-block',
    width: 'calc(50% - 5px)',
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
    minHeight: 175,
    '&:not(:last-child)': {
      marginRight: 10
    }
  },
  maxHeight: {
    maxHeight: 175
  },
  templateInner: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(105px, 1fr))'
  },
  userInner: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(146px, 1fr))'
  }
});

const TemplatesAndUsers = ({
  selectedTemplates,
  selectedUsers,
  onRemoveTemplate,
  onRemoveUser,
  step
}) => {
  const classes = useStyles();

  // Local state
  const [showUserHelp, setShowUserHelp] = useState(false);
  const [showTemplateHelp, setShowTemplateHelp] = useState(
    selectedTemplates.length === 0
  );

  // Update template helper visibility
  useEffect(() => {
    if (showTemplateHelp) {
      setShowTemplateHelp(selectedTemplates.length === 0);
    }
  }, [selectedTemplates, showTemplateHelp]);

  // Update user helper visibility
  useEffect(() => {
    if (step === 2 && !selectedUsers.length) {
      setShowUserHelp(true);
    } else setShowUserHelp(false);
  }, [step, selectedUsers]);

  const templateTransitions = useTransition(
    selectedTemplates,
    template => template.id,
    {
      from: { transform: 'translate3d(0,40px,0)', opacity: 0 },
      enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
      leave: { transform: 'translate3d(0,40px,0)', opacity: 0 },
      unique: true,
      config: { tension: 500, friction: 50 },
      onDestroyed: () => {
        if (selectedTemplates.length === 0) {
          setShowTemplateHelp(true);
        }
      }
    }
  );

  const selectTemplateJsx = (
    <HelperContent content="Start by selecting the Mission(s) you would like to assign to your members." />
  );

  const selectUserJsx = (
    <HelperContent content="Select at least one member to assign the chosen mission(s)." />
  );

  // Constants
  const noTemplates = !selectedTemplates.length;
  const noUsers = !selectedUsers.length;

  const wrapClass = clsx({
    [classes.box]: true,
    [classes.maxHeight]: noTemplates || noUsers
  });

  return (
    <Paper className={classes.paper}>
      <Box className={wrapClass}>
        {showTemplateHelp && selectTemplateJsx}
        <Box className={classes.templateInner}>
          {templateTransitions.map(({ item, props, key }) => {
            const { icon, title, id } = item;
            return (
              <animated.div key={key} style={props}>
                <SelectedTemplateCard
                  icon={icon}
                  templateId={id}
                  title={title}
                  onRemove={() => onRemoveTemplate(item)}
                />
              </animated.div>
            );
          })}
        </Box>
      </Box>
      <Box className={wrapClass}>
        {showUserHelp && selectUserJsx}
        <Box className={classes.userInner}>
          {selectedUsers.map((user, idx) => {
            return (
              <SelectedUserSnack
                key={`user-${idx}`}
                user={user}
                onRemove={() => onRemoveUser(user)}
              />
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
};

TemplatesAndUsers.propTypes = {
  selectedTemplates: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  onRemoveTemplate: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired
};

export default TemplatesAndUsers;
