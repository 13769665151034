import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import PopUpMenu from '../PopUpMenu/PopUpMenu';
import { ReactComponent as TickIcon } from '../../static/images/icons/icon-gold-tick.svg';
import { ReactComponent as StopwatchIcon } from '../../static/images/icons/icon-stopwatch.svg';

import { selectSavingTasks } from '../../state/mission/reducers';
import { getStringDescFromSlate } from '@/utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 10px',
    minHeight: 60,
    marginBottom: 10,
    display: 'flex'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1
  },
  accessory: {
    flexGrow: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginRight: 10,
    width: 25
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 10
  },
  statusCircle: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    backgroundColor: '#adb6c1'
  },
  date: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  },
  task: {
    fontSize: 16,
    color: '#223f63',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  description: {
    fontSize: 14
  },
  dueDate: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif'
  },
  overdue: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 9,
    color: theme.palette.error.main,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    flexDirection: 'column'
  },
  icon: {
    marginRight: 5,
    height: 17,
    width: 15
  },
  circular: {
    height: '20px !important',
    width: '20px !important',
    marginBottom: 4
  }
}));

const TaskCard = ({ task, description, richDescription, dueDate, inferredDueDate, isComplete, menuActions, id }) => {
  // Hooks
  const classes = useStyles();

  // Redux
  const updatingTasks = useSelector(state => state.mission.updatingTasks);
  const updatingCommunityTasks = useSelector(state => state.communityMissions.updatingTasks);

  // Constants
  const taskDueDate = dueDate || inferredDueDate;
  const saving = [...updatingTasks, ...updatingCommunityTasks].includes(id);

  const currentDay = moment().startOf('day');
  const computedDueDate = moment(taskDueDate).endOf('day');
  const isOverdue = computedDueDate.isBefore(currentDay) && !isComplete;
  const daysOverdue = currentDay.diff(computedDueDate, 'days');

  const renderAccessory = () => {
    if (isOverdue) {
      return (
        <Typography className={classes.overdue}>
          <StopwatchIcon className={classes.icon} />
          {daysOverdue} days
        </Typography>
      );
    }
    if (isComplete) {
      return <TickIcon />;
    }
    return <Box className={classes.statusCircle} />;
  };

  const descriptionShowed = richDescription ? getStringDescFromSlate(richDescription): description

  return (
    <Box className={classes.root}>
      <Box className={classes.accessory}>
        {renderAccessory()}
        {/* {isComplete && <TickIcon />}
        {!isComplete && <Box className={classes.statusCircle} />} */}
      </Box>

      <Box className={classes.content}>
        <Box className={classes.header}>
          <Typography className={classes.task}>{task}</Typography>
          <Typography className={classes.date}>{moment(dueDate).format('D/MM/YY')}</Typography>
        </Box>

        <Box className={classes.body}>
          <Typography className={classes.description}>{descriptionShowed}</Typography>
        </Box>
      </Box>

      {menuActions && !saving && <PopUpMenu menuActions={menuActions} />}
      {saving && (
        <Box display="flex" alignItems="center">
          <CircularProgress className={classes.circular} />
        </Box>
      )}
      {/* 
      <Box className={classes.dateContainer} />
      <Box paddingLeft={2.5} display="flex" flex={1} alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center" width={25} height={25} marginRight={1.25}>
          {isComplete && <TickIcon />}
          {!isComplete && <Box className={classes.statusCircle} />}
        </Box>
        <Box flex={1} paddingRight={1}>
          <Typography className={classes.task}>{task}</Typography>
          <Typography className={classes.description}>{description}</Typography>
          <Typography className={classes.dueDate}>DUE DATE • {moment(taskDueDate).format('D/MM/YYYY')}</Typography>
        </Box>
        {isOverdue && (
          <Typography className={classes.overdue}>
            <StopwatchIcon className={classes.icon} />
            {daysOverdue} days overdue
          </Typography>
        )}
      </Box>
      {menuActions && !saving && <PopUpMenu menuActions={menuActions} />}
      {saving && (
        <Box display="flex" alignItems="center">
          <CircularProgress className={classes.circular} />
        </Box>
      )} */}
    </Box>
  );
};

TaskCard.propTypes = {
  createdOn: PropTypes.string,
  task: PropTypes.string.isRequired,
  description: PropTypes.string,
  dueDate: PropTypes.string,
  isComplete: PropTypes.bool,
  menuActions: PropTypes.array,
  inferredDueDate: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  richDescription: PropTypes.string
};

TaskCard.defaultProps = {
  createdOn: new Date(),
  dueDate: '',
  isComplete: false,
  description: '',
  menuActions: null,
  richDescription: ''
};

export default TaskCard;
