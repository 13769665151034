import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';

import * as userTypes from '../../state/users/types';

import {
  selectEmailChangeLoading,
  selectEmailChangeSaving,
  selectUserEmailChange,
  selectSelectedUser
} from '../../state/users/reducers';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1
  },
  labelRoot: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    zIndex: 1,
    fontSize: 10,
    fontWeight: 500,
    marginBottom: '0.5rem',
    pointerEvents: 'none'
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  loadingContainer: {
    borderRadius: 10,
    color: '#ffffff',
    height: 40,
    width: '100%',
    backgroundColor: '#ffffff',
    animation: '$glow 1.5s ease-in-out infinite'
  },
  emailPending: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#ff7500'
  },
  emailButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  alignCenter: {
    alignItems: 'center'
  },
  alignEnd: {
    alignItems: 'flex-end'
  }
});

export default function UserEmail({
  readOnly,
  currentEmail,
  hasPendingRequest,
  email,
  verified,
  handleChange
}) {
  const dispatch = useDispatch();
  const classes = styles();

  // Redux
  const loading = useSelector(state => selectEmailChangeLoading(state));
  const saving = useSelector(state => selectEmailChangeSaving(state));
  const emailChange = useSelector(state => selectUserEmailChange(state));
  const selectedUser = useSelector(state => selectSelectedUser(state));

  function handleResend() {
    dispatch({
      type: userTypes.RESEND_EMAIL_CHANGE,
      requestId: emailChange._id
    });
  }

  function handleCancel() {
    dispatch({
      type: userTypes.CANCEL_EMAIL_CHANGE,
      userId: selectedUser._id,
      requestId: emailChange._id
    });
  }

  const helperMessage = hasPendingRequest ? `Changed from ${currentEmail}` : null;

  return loading || saving ? (
    <div style={{ width: '100%' }}>
      <Typography className={classes.loadingContainer}>Loading</Typography>
    </div>
  ) : (
    <div className={classes.container}>
      <TextInput
        name="email"
        label="Email address"
        value={email}
        onChange={handleChange}
        whiteInput
        formControlStyle={{
          width: '100%',
          marginRight: 10
        }}
        endAdornment={
          !verified || hasPendingRequest ? (
            <Typography className={classes.emailPending}>Pending</Typography>
          ) : null
        }
        disabled={readOnly}
        helperMessage={helperMessage}
      />
      {hasPendingRequest && (
        <div
          className={clsx({
            [classes.emailButtons]: true,
            [classes.alignCenter]: hasPendingRequest,
            [classes.alignEnd]: !hasPendingRequest
          })}
        >
          <Button
            onClick={handleResend}
            background="grey"
            label="Resend"
            style={{ marginRight: 5 }}
            disabled={saving}
            loading={saving}
          />
          <Button
            onClick={handleCancel}
            background="grey"
            label="Cancel"
            disabled={saving}
            loading={saving}
          />
        </div>
      )}
    </div>
  );
}

UserEmail.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  currentEmail: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  verified: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  hasPendingRequest: PropTypes.bool.isRequired
};

UserEmail.defaultProps = {
  handleChange: null
};
