import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SearchInput from '../SearchInput/SearchInput';
import approvedIcons from '../../constants/customIcons';
import Icon from './Icon';
import Button from '../Button/Button';
import CustomIcon from '../CustomIcon/CustomIcon';

const fuseOpts = {
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['name']
};

const useStyles = makeStyles({
  paper: {
    borderRadius: 40,
    padding: '40px 40px 25px 40px',
    minWidth: 550
  },
  title: {
    fontSize: 18,
    marginBottom: 25,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  },
  search: {
    marginBottom: 18
  },
  wrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(calc(100% / 8), 1fr))'
  },
  noResults: {
    margin: '20px 0px',
    textAlign: 'center'
  },
  flex: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: 125
  },
  cancel: {
    marginRight: 10
  }
});

const StyledPaper = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

StyledPaper.propTypes = {
  children: PropTypes.node.isRequired
};

const SelectionPopup = ({ open, onChange, onSelect, onCancel, chosen, fileOption }) => {
  // Hooks
  const classes = useStyles();

  // Local state
  const [search, setSearch] = useState('');

  // Event handlers
  const onSearchChange = val => {
    setSearch(val);
  };

  // Helpers
  const searchIcons = () => {
    const fuseInstance = new Fuse(approvedIcons, fuseOpts);
    return fuseInstance.search(search);
  };

  // Constants
  const hasSearch = search.trim().length > 0;
  const computedIcons = hasSearch ? searchIcons() : approvedIcons;
  const hasResults = computedIcons.length > 0;

  return (
    <Dialog open={open} onClose={onCancel} PaperComponent={StyledPaper}>
      {/* experimental, to provide between icon or user inputted image
      {
        fileOption ? (
          <>
            <Typography className={classes.title}>Select mission picture</Typography>
            <Typography className={classes.title}>or</Typography>
          </>
        ): <></>
      } */}
      <Typography className={classes.title}>Select mission icon</Typography>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        placeholder="Filter icons by name..."
        className={classes.search}
      />
      {hasResults ? (
        <Box className={classes.wrap} marginBottom={2.5}>
          {computedIcons.slice(0, 32).map((icon, idx) => {
            return (
              <div key={`${icon?.name}-${idx}`} className={classes.flex}>
                {icon?.type === 'fa' && (<Icon
                  icon={icon?.name}
                  selected={chosen === icon}
                  onClick={() => onChange(icon)}
                />)}
                {icon?.type === 'custom' && (<CustomIcon
                  icon={icon?.name}
                  selected={chosen === icon}
                  onClick={() => onChange(icon)}
                />)}
              </div>
            );
          })}
        </Box>
      ) : (
        <Typography className={classes.noResults}>No results found</Typography>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button
          background="grey"
          label="Cancel"
          onClick={onCancel}
          className={clsx(classes.button, classes.cancel)}
        />
        <Button label="Use selected" onClick={onSelect} className={classes.button} />
      </Box>
    </Dialog>
  );
};

SelectionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  chosen: PropTypes.string,
  fileOption: PropTypes.bool,
};

SelectionPopup.defaultProps = {
  chosen: '',
  fileOption: false,
};

export default SelectionPopup;
