import axios from 'axios';
import * as authServices from './services';

export const attemptLogin = async payload => {
  try {
    const result = await axios.post('/v1/auth/login', payload);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const token = authServices.getLocalStore('refreshToken');
    if (!token) throw Error('No token in local storage');
    const result = await axios.post('/v1/auth/token/refresh', {
      refreshToken: token
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestPasswordReset = async email => {
  try {
    const isProd = 'NODE_ENV' in process.env && process.env.NODE_ENV === 'production';
    const url = isProd
      ? `${window.__env__.REACT_APP_SET_PASSWORD_ROUTE}`
      : `${process.env.REACT_APP_SET_PASSWORD_ROUTE}`;
    const result = await axios.post('/v1/auth/password/request', {
      email,
      url
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const acceptInvite = async (inviteId, payload) => {
  try {
    const result = await axios.patch(`/v1/invites/${inviteId}/accepted`, payload);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (password, passwordConfirm, code) => {
  try {
    const result = await axios.post('/v1/auth/password/reset', {
      password,
      passwordConfirm,
      code
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const completeEmailUpdate = async token => {
  try {
    const result = await axios.post('/v1/auth/email/update', { token });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const acceptEmailChange = async token => {
  try {
    const result = await axios.post('/v1/auth/email/update', { token });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const changeOwnPassword = async (oldPassword, password, passwordConfirm) => {
  try {
    const result = await axios.post('/v1/auth/password', {
      oldPassword,
      password,
      passwordConfirm
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestOwnEmailChange = async email => {
  try {
    const result = await axios.post('/v1/auth/email', { email });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOwnEmailChangeRequest = async () => {
  try {
    const result = await axios.get('/v1/auth/email');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const cancelOwnEmailChange = async requestId => {
  try {
    const result = await axios.delete(`/v1/auth/email/${requestId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateOwnUser = async (userId, updates) => {
  try {
    const result = await axios.put(`/v1/users/${userId}`, updates);
    return result.data;
  } catch (error) {
    throw error;
  }
};
