import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth/reducers';
import userReducer from './users/reducers';
import communityReducer from './community/reducers';
import modalReducer from './modal/reducers';
import snackReducer from './snack/reducers';
import bootstrapReducer from './bootstrap/reducers';
import missionReducer from './mission/reducers';
import teamReducer from './teams/reducers';
import chatReducer from './chat/reducers';
import templateReducer from './templates/reducers';
import reportsReducer from './reports/reducers';
import communityMissionReducer from './communityMission/reducers';
import toolkitReducer from './toolkits/reducers';
import toolkitCategoryReducer from './toolkitCategories/reducers';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    bootstrap: bootstrapReducer,
    auth: authReducer,
    community: communityReducer,
    users: userReducer,
    mission: missionReducer,
    modals: modalReducer,
    snack: snackReducer,
    teams: teamReducer,
    chat: chatReducer,
    templates: templateReducer,
    reports: reportsReducer,
    communityMissions: communityMissionReducer,
    toolkits: toolkitReducer,
    toolkitCategories: toolkitCategoryReducer,
  });

export default rootReducer;
