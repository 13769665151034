import moment from 'moment';

export const initialSlateValue = (initial) => ([
  {
    type: 'paragraph',
    children: [{ text: initial || '' }],
  },
])

export const getSlateValueFromTaskAndPractice = (description, richDescription) => {
  const initialValue = initialSlateValue(description)

  if(typeof richDescription === 'string') {
    richDescription = JSON.parse(richDescription)
    if(!Array.isArray(richDescription)) {
      console.error("failed to parse Slate Description")
      richDescription = initialSlateValue()
    }
  }
  let initialDescription = !richDescription && description ?
    initialValue: richDescription;
  if(!initialDescription || !initialDescription.length) initialDescription = initialSlateValue()
  return initialDescription
}

export const getStringDescFromSlate = (slates=[]) => {
  let res = ''
  try {
    if (typeof slates === 'string') slates = JSON.parse(slates)
    const lists = !Array.isArray(slates) ? [slates]: slates
    lists.map(sl => {
      if(!sl.children) res += ` ${sl.text || ''}`
      else if(sl.children) {
        res += getStringDescFromSlate(sl.children)
      }
    })
  } catch (err) {
    console.error("Failed to convert to string from slate", err)
  }
  return res.trim()
}

// Checks if communityId exists in user memberships
export function checkForPermissions(memberships, communityId) {
  const filtered = memberships.filter(m => m[Object.keys(m)[0]].permissions.length > 0);
  return filtered.some(m => Object.keys(m).some(key => key === communityId));
}

// Return the permissions for a given community as array
export function getPermissions(memberships, communityId) {
  const membership = memberships.find(m => Object.keys(m).find(key => key === communityId));
  const { permissions } = membership[communityId];
  return permissions;
}

export const getPermissionsArray = (memberships, communityId) => {
  if (!memberships) return [];

  const membership = memberships.find(m => Object.keys(m).find(key => key === communityId));

  return membership ? membership[communityId].permissions : [];
};

// Check user has permissions for given community - return object
export function checkAndGetPermissions(memberships, communityId) {
  const membership = memberships.find(m => Object.keys(m).find(key => key === communityId));

  return {
    isLeader: membership ? membership[communityId].permissions.includes('leader') : false,
    isComAdmin: membership ? membership[communityId].permissions.includes('admin') : false
  };
}

export function isOfficer(user) {
  const { roles } = user;
  const idx = roles.indexOf('officer');
  return idx > -1;
}

export function isLeader(memberships) {
  return memberships
    .map(m => Object.keys(m).map(key => m[key].permissions))
    .flat()
    .flat()
    .includes('leader');
}

export function isCommunityAdmin(memberships) {
  return memberships
    .map(m => Object.keys(m).map(key => m[key].permissions))
    .flat()
    .flat()
    .includes('admin');
}

// Returns a communityId that exists in the users community key and memberships key
export function getValidCommunity(communities, memberships) {
  const membership = memberships.find(m => Object.keys(m).find(key => communities.find(c => c._id === key)));
  return Object.keys(membership)[0];
}

// Get array of communityIds that the user has permissions for
export function getMembershipIds(memberships) {
  const filtered = memberships.filter(m => m[Object.keys(m)[0]].permissions.length > 0);
  return filtered.map(c => Object.keys(c)[0]);
}

// Check current user has permission for route
export function checkRoutePermission(routePermissions, userPermissions) {
  return userPermissions.some(up => routePermissions.some(rp => rp === up));
}

// Check communityId exists in array of community objects
export function includesCommunity(communities, communityId) {
  return communities.some(c => c._id === communityId);
}

// Get community leader Ids from user object
export function getUserCommunityLeaderIds(user, communityId) {
  const { leaders } = user;
  const communityLeaders = leaders.find(l => l.communityId === communityId);
  if (communityLeaders) return communityLeaders.leaders;
  return [];
}

// Get the leaders of a user
export function getUserCommunityLeaders(user, communityId = null) {
  const { communityLeaders } = user;
  if (communityLeaders) {
    // Only return leaders in specified community
    if (communityId) {
      const hasLeaders = Object.keys(communityLeaders).some(key => key === communityId);
      return hasLeaders ? communityLeaders[communityId] : [];
    }

    // Return all leaders
    const leaders = [];
    Object.keys(communityLeaders).forEach(key => {
      leaders.push(...communityLeaders[key]);
    });
    return leaders;
  }

  // No leaders supplied in user data packet - return empty array
  return [];
}

// Format objective string
export const deriveObjectiveTitleFromStatement = (statement, isMetric) => {
  const dueDate = typeof statement.dueDate === 'string' ? new Date(statement.dueDate) : statement.dueDate;

  const outcome = ` by ${moment(dueDate).format('D MMMM YYYY')} in order to ${statement.outcome}`;

  return isMetric
    ? `I will ${statement.verb} ${statement.noun} ${outcome}`
    : `I will ${statement.verb} ${statement.noun} ${outcome}`;
};

export const getTemplateObjectiveTitle = (statement, isMetric) => {
  const hasDays = Boolean(statement.daysOnceActive);
  const dueDate = moment(statement.dueDate).format('D MMMM YYYY');
  const outcome = hasDays
    ? ` within ${statement.daysOnceActive} days in order to ${statement.outcome}`
    : ` by ${dueDate} in order to ${statement.outcome}`;

  return isMetric
    ? `I will ${statement.verb} ${statement.noun} ${outcome}`
    : `I will ${statement.verb} ${statement.noun} ${outcome}`;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const communityWithPermissions = user => {
  const { memberships, communities } = user;

  const withPermissions = memberships.filter(m => m[Object.keys(m)[0]].permissions.length > 0);
  const communityKeys = withPermissions.map(membership => Object.keys(membership)[0]);

  return communityKeys.length > 0 ? communities.find(comm => comm._id === communityKeys[0]) : null;
};

export const deriveObjectiveActionTitle = statement => {
  const { verb, metric, noun } = statement;
  return metric ? `${verb} ${metric} ${noun}` : `${verb} ${noun}`;
};

export const randomId = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const ensurePositiveNumber = num => {
  if (!num) return '';
  if (num && num < 0) return 0;
  return typeof num === 'string' ? num.replace(/\D/g, ''): num;
};

export const getShortDate = date => {
  const now = moment();

  const secs = now.diff(date, 'seconds');
  if (secs < 60) return `${secs}s ago`;

  const mins = now.diff(date, 'minutes');
  if (mins < 60) return `${mins}m ago`;

  const hours = now.diff(date, 'hours');
  if (hours < 24) return `${hours}h ago`;

  const days = now.diff(date, 'days');
  if (days < 7) return `${days}${days === 1 ? 'dy' : 'dys'} ago`;

  const weeks = now.diff(date, 'weeks');
  if (weeks < 4) return `${weeks}${weeks === 1 ? 'wk' : 'wks'} ago`;

  const months = now.diff(date, 'months');
  if (months < 12) return `${months}${months === 1 ? 'mnth' : 'mnths'} ago`;

  return moment(date).format('DD/MM/YYYY');
  // return `${now.diff(date, 'years')}yr ago`;
};

export const getFriendlyTime = date => {
  const formattedDatetime = moment(date).format('D MMM YYYY hh:mm');

  const delta = Math.round((new Date().getTime() - date) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  let fuzzy = '';
  if (delta < 30) {
    fuzzy = 'just now.';
  } else if (delta < minute) {
    fuzzy = `${delta} seconds ago.`;
  } else if (delta < 2 * minute) {
    fuzzy = 'a minute ago.';
  } else if (delta < hour) {
    fuzzy = `${Math.floor(delta / minute)} minutes ago`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = '1 hour ago.';
  } else if (delta < day) {
    fuzzy = `${Math.floor(delta / hour)} hours ago`;
  } else if (delta < day * 2) {
    fuzzy = 'yesterday';
  } else if (delta < day * 4) {
    fuzzy = `a few days ago ${formattedDatetime}`;
  } else if (delta < week) {
    fuzzy = `last week ${formattedDatetime}`;
  } else if (delta < week * 2) {
    fuzzy = `two weeks ago ${formattedDatetime}`;
  } else fuzzy = formattedDatetime;

  return fuzzy;
};

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

// Sort the objectives by status then subsort by due date
export const sortObjectives = objectives => {
  const sortDate = (a, b) => {
    const aDate = a.dueDate || a.inferredDueDate;
    const bDate = b.dueDate || b.inferredDueDate;
    return new Date(aDate) - new Date(bDate);
  };

  const needHelp = objectives.filter(o => o.status === 'need help').sort(sortDate);
  const complete = objectives.filter(o => o.status === 'complete').sort(sortDate);
  const inProgress = objectives.filter(o => o.status === 'in progress').sort(sortDate);
  const noLongerRelevant = objectives.filter(o => o.status === 'no longer relevant').sort(sortDate);
  const inactive = objectives.filter(o => o.status === 'inactive').sort(sortDate);

  return [...needHelp, ...inProgress, ...inactive, ...complete, ...noLongerRelevant];
};

// Get the most recent object in an array of objects with a date
export const getMostRecentObject = (arr, key) => {
  // Must have array and object date key
  if (!arr || !key) return null;

  // Calculate latest timestamp (Math.max converts Date to timestamp)
  const latestTimestamp = Math.max.apply(
    null,
    arr.map(e => new Date(e[key]))
  );

  // Find the object with the corresponding timestamp
  return arr.find(e => new Date(e[key]).getTime() === latestTimestamp);
};

/**
 * Calculates the stat overview at the top of the members table
 * @param {Array} members an array of members with the stat key
 */
export const calculateMemberStats = members => {
  const data = {
    missions: {
      complete: 0,
      inProgress: 0,
      overdue: 0
    },
    objectives: {
      complete: 0,
      inProgress: 0,
      overdue: 0,
      needHelp: 0
    },
    tasks: {
      complete: 0,
      inProgress: 0,
      overdue: 0
    }
  };

  members.forEach(m => {
    const { stats } = m;
    data.missions.complete += stats.missions.complete;
    data.missions.inProgress += stats.missions.inProgress;
    data.missions.overdue += stats.missions.overdue;
    data.objectives.complete += stats.objectives.complete;
    data.objectives.inProgress += stats.objectives.inProgress;
    data.objectives.overdue += stats.objectives.overdue;
    data.objectives.needHelp += stats.objectives.needHelp;
    data.tasks.complete += stats.tasks.complete;
    data.tasks.inProgress += stats.tasks.inProgress;
    data.tasks.overdue += stats.tasks.overdue;
  });

  return data;
};

/**
 * Officers can be leaders of users without explictly being an officer in the community. This calculates
 * the users leader status based on the user objects.
 * @param {String} userId the id of the user you are checking for leader status
 * @param {Array} users an array of user objects
 * @param {String} communityId the id of the selected community
 */
export const isLeaderInCommunity = (userId, users, communityId) => {
  return users.some(u =>
    u.leaders.some(l => l.communityId === communityId && l.leaders.some(leaderId => leaderId === userId))
  );
};

/**
 *
 * @param {Number} score
 */
export const getPracticeScoreLabel = score => {
  if (!!score === false) {
    return 'no score yet';
  }

  if (score >= 96) {
    return 'Great';
  }
  if (score >= 86) {
    return 'High';
  }
  if (score >= 70) {
    return 'Good';
  }
  if (score >= 51) {
    return 'Average';
  }
  if (score >= 1) {
    return 'Poor';
  }

  return 'Zero';
};
