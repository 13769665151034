import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextInput from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';
import Button from '../Button/Button';

import UserProfileFormLoading from './UserProfileFormLoading';

import { selectUserSavingState } from '../../state/users/reducers';

import { getErrorMessage } from '../../constants/validation';

const styles = makeStyles(theme => ({
  paper: {
    padding: '25px 20px',
    borderRadius: 10
  },
  pl: {
    paddingLeft: theme.spacing(1)
  },
  pr: {
    paddingRight: theme.spacing(1)
  },
  mb: {
    marginBottom: 25
  },
  mt: {
    marginTop: 20
  },
  button: {
    width: 150
  }
}));

export default function UserProfileForm({
  readOnly,
  loading,
  firstName,
  lastName,
  phone,
  biography,
  birthday,
  onChange,
  onDateChange,
  onPhoneChange,
  onSave,
  errors
}) {
  const classes = styles();
  const saving = useSelector(state => selectUserSavingState(state));
  return loading ? (
    <UserProfileFormLoading readOnly={readOnly} />
  ) : (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={6} className={clsx(classes.mb, classes.pr)}>
            <TextInput
              label="First name"
              name="firstName"
              value={firstName}
              onChange={onChange}
              disabled={readOnly}
              error={Boolean(getErrorMessage(errors, 'firstName'))}
              errorMessage={getErrorMessage(errors, 'firstName')}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pl)}>
            <TextInput
              label="Last name"
              name="lastName"
              value={lastName}
              onChange={onChange}
              disabled={readOnly}
              error={Boolean(getErrorMessage(errors, 'lastName'))}
              errorMessage={getErrorMessage(errors, 'lastName')}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pr)}>
            <Datepicker
              name="birthday"
              value={birthday}
              label="Date of birth"
              disabled={readOnly}
              onChange={onDateChange}
              maxDate={moment().subtract(1, 'days')}
              maxDateMessage="Date of Birth cannot be a future date"
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.mb, classes.pl)}>
            <TextInput
              label="Phone number"
              name="phoneNumber"
              value={phone}
              onChange={onPhoneChange}
              disabled={readOnly}
              error={Boolean(getErrorMessage(errors, 'phoneNumber'))}
              errorMessage={getErrorMessage(errors, 'phoneNumber')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Describe yourself with a short bio"
              name="biography"
              value={biography}
              onChange={onChange}
              multiline
              disabled={readOnly}
              error={Boolean(getErrorMessage(errors, 'biography'))}
              errorMessage={getErrorMessage(errors, 'biography')}
            />
          </Grid>
        </Grid>
      </Paper>
      {!readOnly && (
        <Grid container justify="flex-end" className={classes.mt}>
          <Button
            className={classes.button}
            label="Save"
            onClick={onSave}
            loading={saving}
            disabled={saving}
          />
        </Grid>
      )}
    </>
  );
}

UserProfileForm.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  biography: PropTypes.string.isRequired,
  birthday: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

UserProfileForm.defaultProps = {
  errors: null,
  birthday: null
};
