import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { randomId } from '../../utils/helpers';

const styles = makeStyles({
  tableCell: {
    padding: 0,
    border: 0,
    paddingBottom: 5
  },
  '@keyframes glow': {
    '0%, 100%': { opacity: 0.5 },
    '50%': { opacity: 1 }
  },
  container: {
    color: '#f7f7f7',
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 60,
    display: 'flex',
    marginBottom: 5,
    animation: '$glow 1.5s ease-in-out infinite'
  },
  borderLeft: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  borderRight: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  }
});

export default function StatCardLoading() {
  const classes = styles();
  return (
    <TableRow>
      {[...new Array(10)].map((d, idx) => {
        const containerClass = clsx({
          [classes.container]: true,
          [classes.borderLeft]: idx === 0,
          [classes.borderRight]: idx === 9
        });

        return (
          <TableCell key={randomId()} scope="row" className={classes.tableCell}>
            <Typography className={containerClass}>Loading</Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
