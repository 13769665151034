import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { selectIsOfficer } from '../../state/auth/reducers';
import { selectCurrentUserCommunityPermissions, selectSelectedCommunity } from '../../state/community/reducers';
import { selectSelectedUser } from '../../state/users/reducers';
import AuthUserMenu from './AuthUserMenu';

// import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const styles = makeStyles({
  root: {
    padding: '20px 0px 40px',
    display: 'flex'
  },
  role: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    fontSize: 14,
    color: '#838b96'
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: 26,
    fontWeight: 700
  },
  iconContainer: {
    background: '#ffffff',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    height: 40,
    width: 40,
    boxShadow: '0px 10px 20px -5px rgba(0, 0, 0, 0.2)'
  },
  list: {
    padding: 0
  },
  paperRoot: {
    borderRadius: 15
  },
  menuItemRoot: {
    fontSize: 12,
    padding: '12px 16px',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(34, 63, 99, 0.1)'
    }
  },
  actionsWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 28px'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  }
});

export default function PageHeader({ routeTitle, role, showSelectedUser, actions, showBack }) {
  const classes = styles();
  const history = useHistory();

  const selectedUser = useSelector(state => selectSelectedUser(state));
  const isOfficer = useSelector(state => selectIsOfficer(state));
  const permissions = useSelector(state => selectCurrentUserCommunityPermissions(state));
  // const userPermission = permissions.includes('admin') ? 'Community Admin' : 'Leader';
  const { title: communityTitle } = useSelector(state => selectSelectedCommunity(state));

  // const computedRole = role || (isOfficer ? 'Officer' : userPermission);

  const prefix = !isOfficer ? `Officer: ${communityTitle}` : `${communityTitle} community`;

  function goBack() {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      {(showSelectedUser || showBack) && (
        <Box display="flex" alignItems="center" marginLeft={-5}>
          <ChevronLeftIcon
            data-test-id="button-navigation-back"
            fontSize="large"
            onClick={goBack}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      )}
      <div className={classes.titleContainer}>
        {/* {!showSelectedUser && <Typography className={classes.role}>{computedRole}</Typography>} */}
        {/* <Typography className={classes.role}>{prefix}</Typography> */}
        {showSelectedUser && selectedUser && (
          <Typography className={classes.role}>{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
        )}
        <Typography variant="h1" className={classes.title}>
          {routeTitle}
        </Typography>
      </div>
      {actions && <div className={classes.actionsWrap}>{actions}</div>}
      <div className={classes.flex}>
        <AuthUserMenu />
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  routeTitle: PropTypes.string,
  showSelectedUser: PropTypes.bool,
  actions: PropTypes.node,
  showBack: PropTypes.bool,
  role: PropTypes.string
};

PageHeader.defaultProps = {
  routeTitle: '',
  showSelectedUser: false,
  actions: null,
  showBack: false,
  role: undefined
};
