import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import usePrevious from '../../hooks/usePrevious';
import * as modalTypes from '../../state/modal/types';
import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';

const useStyles = makeStyles({
  gridContainer: {
    maxWidth: 450,
    flex: 1
  },
  mb26: {
    marginBottom: 26,
  },
  title: {
    fontSize: 24,
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    color: '#223f63',
    marginBottom: 20
  },
  message: {
    fontSize: 16
  },
  buttonGroup: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  button: {
    width: 150,
    margin: '0px 10px'
  },
  toolkitContainer: {
    overflowY: 'scroll',
    maxHeight: 1000,
    height: 450,
  },
  thumbnail: ({thumbnail}) => ({
    backgroundImage: `url(${thumbnail})`,
    backgroundColor: '#1C1C1C',
    height: 250,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  content: {
    minHeight: 250,
    paddingBottom: '1rem',
    backgroundColor: '#3E3E3E',
    marginTop: '-2rem',
    border: '1px solid grey',
  }
});

export default function AddHyperLink({
  editUrl,
  editText,
  onSave,
  onCancel,
  savingSelector,
}){
  // Hooks
  const classes = useStyles({});
  const dispatch = useDispatch();

  // Redux
  const saving = savingSelector ? useSelector(state => savingSelector(state)) : null;
  const prevSaving = usePrevious(saving);
  const [link, setLink] = useState()
  const [text, setText] = useState()
  const [error,setError] = useState()
  const pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);

  function isUrlValid(userInput) {
    return pattern.test(userInput);
  }

  useEffect(() => {
    if(editUrl) setLink(editUrl)
  }, [editUrl])

  useEffect(() => {
    if(editText) setText(editText)
  }, [editText])

  // Event handlers
  const closeModal = () => {
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
  };

  // Close when redux save state goes from true to false
  useEffect(() => {
    if (prevSaving && !saving) closeModal();
  }, [saving, prevSaving]);

  useEffect(() => {
    if(link && error && isUrlValid(link)) setError(false)
  }, [link])

  const handleSubmit = () => {
    if(isUrlValid(link)) {
      setError()
      onSave(link, text)
      closeModal()
    } else {
      setError('Invalid URL')
    }
  }

  const handleCancel = () => {
    if (onCancel !== null) return onCancel();
    return closeModal();
  };

  return (
    <>
      <DialogTitle>Add Hyperlink</DialogTitle>
      <DialogContent>
        <TextInput
          id="link"
          className={classes.mb26}
          margin="dense"
          label="Link"
          placeholder="Paste Link"
          variant="standard"
          fullWidth
          autoFocus
          error={!!error}
          value={link}
          errorMessage={error}
          onChange={(e) => setLink(e.target.value)}
        />
        <TextInput
          id="text"
          className={classes.mb26}
          margin="dense"
          label="Text"
          placeholder="Link To Display"
          variant="standard"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} background="grey" label="Cancel" />
        <Button onClick={handleSubmit} label="Ok" />
      </DialogActions>
    </>
  );
}

AddHyperLink.propTypes = {
  editUrl: PropTypes.string,
  editText: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  savingSelector: PropTypes.func,
};

AddHyperLink.defaultProps = {
  editUrl: null,
  editText: null,
  onSave: () => {}, 
  onCancel: null,
  savingSelector: null,
};