import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { selectLifeEvents } from '../../state/users/reducers';
import * as userTypes from '../../state/users/types';

import FrameworkDrawer from '../Drawers/FrameworkDrawer';
import LifeEventsDrawer from '../Drawers/LifeEventsDrawer';
import { selectLeaderUsers } from '../../state/community/reducers';
import { OPEN_CHAT } from '../../state/chat/types';
import Button from '../Button/Button';
import ProfileImage from '../ProfileImage/ProfileImage';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily
  },
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e6e6e6',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    paddingBottom: 10,
    paddingTop: 9,
    width: '100%',
    alignItems: 'center'
  },
  userInfo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

const MemberHeaderNavigation = React.memo(({ member }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { communityId, role } = useParams();

  const isCommunityAdmin = role === 'a'; // bad

  const name = member ? `${member.firstName} ${member.lastName}` : '';

  const [isShowingPF, setIsShowingPF] = useState(false);
  const [isShowingLifeEvents, setIsShowingLifeEvents] = useState(false);

  const lifeEvents = useSelector(state => selectLifeEvents(state));
  const leaderMembers = useSelector(state => selectLeaderUsers(state));

  const canChat = member && leaderMembers.some(u => u._id === member?._id);

  const openChat = () => {
    dispatch({
      type: OPEN_CHAT,
      user: member
    });
  };

  const handleProfileClick = () => {
    history.push(`/c/${communityId}/u/${member._id}/account`);
  };

  return member ? (
    <Box marginBottom={2} className={classes.root}>
      <Grid container alignItems="center">
        {/** User info */}
        <Grid item sm={12} md={12} lg={5} xl={8}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box className={classes.userInfo}>
                  <ProfileImage userId={member._id} size={60} borderRadius={15} />
                </Box>
                <Typography className={classes.title}>{name}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/** Buttons */}
        <Hidden xsDown>
          <Grid item sm={12} md={12} lg={7} xl={4} display="flex">
            <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
              {!isCommunityAdmin && (
                <Grid item sm={6} md={3}>
                  <Button fullWidth label="Profile" background="white" onClick={handleProfileClick} />
                </Grid>
              )}
              <Grid item sm={6} md={3}>
                <Button fullWidth label="Personal code" background="white" onClick={() => setIsShowingPF(true)} />
              </Grid>
              {!isCommunityAdmin && (
                <Grid item sm={6} md={3}>
                  <Button
                    fullWidth
                    label="Life Events"
                    background="white"
                    onClick={() => setIsShowingLifeEvents(true)}
                  />
                </Grid>
              )}
              {canChat && (
                <Grid item sm={6} md={3}>
                  <Button fullWidth background="white" label="Message" onClick={() => openChat()} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <FrameworkDrawer
        title="Personal code"
        framework={member?.psychFramework}
        open={isShowingPF}
        onClose={() => setIsShowingPF(false)}
        anchor="right"
      />

      {!isCommunityAdmin && (
        <LifeEventsDrawer
          title="Life Events"
          onOpen={() => {
            dispatch({ type: userTypes.GET_USER_LIFE_EVENTS, userId: member._id });
          }}
          lifeEvents={lifeEvents}
          open={isShowingLifeEvents}
          onClose={() => setIsShowingLifeEvents(false)}
          anchor="right"
        />
      )}
    </Box>
  ) : null;
});

MemberHeaderNavigation.propTypes = {
  member: PropTypes.object,
  lifeEvents: PropTypes.array,
  canChat: PropTypes.bool
};

MemberHeaderNavigation.defaultProps = {
  canChat: false,
  member: undefined,
  lifeEvents: []
};
export default MemberHeaderNavigation;
