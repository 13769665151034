import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import SearchInput from '../SearchInput/SearchInput';
import { ReactComponent as AddIcon } from '../../static/images/icons/icon-add.svg';

const useStyles = makeStyles({
  heading: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    flex: 1,
    fontSize: 18,
    margin: 0
  },
  iconButton: {
    height: 32,
    width: 62,
    backgroundColor: '#dcdcdc',
    borderRadius: 20,
    padding: '0px !important'
  },
  goldBackground: {
    color: '#ffffff !important',
    backgroundImage: 'linear-gradient(to left, #f5bd56, #a5721a)',
    backgroundColor: 'none !important',
    '&:hover': {
      backgroundImage: 'linear-gradient(to left, #f5bd56, #f5bd56)'
    }
  }
});

const TemplateSearch = ({
  value,
  onChange,
  onAdd,
  heading,
  goldButton,
  readOnly
}) => {
  // Hooks
  const classes = useStyles();

  // Event handlers
  const handleAdd = () => {
    if (onAdd) onAdd();
  };

  const iconClass = clsx({
    [classes.iconButton]: true,
    [classes.goldBackground]: goldButton
  });

  return (
    <Box component="section" marginBottom={1.25}>
      <Box display="flex" paddingBottom={2} alignItems="center">
        <Typography className={classes.heading}>{heading}</Typography>
        {!readOnly && (
          <IconButton className={iconClass} onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        )}
      </Box>
      <SearchInput
        value={value}
        onChange={onChange}
        placeholder="Filter by mission name..."
      />
    </Box>
  );
};

TemplateSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  heading: PropTypes.string,
  goldButton: PropTypes.bool,
  readOnly: PropTypes.bool
};

TemplateSearch.defaultProps = {
  heading: '',
  goldButton: false,
  readOnly: false
};

export default TemplateSearch;
