import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import Drawer from './DrawerGeneric';

const Item = ({ lifeEvent }) => (
  <>
    <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={1}>
      <Typography style={{ fontWeight: 700 }}>{lifeEvent.title}</Typography>
      <Typography>{lifeEvent.description}</Typography>
    </Box>

    <Box padding={2} bgcolor="#eaeaea" borderRadius={10} marginBottom={3} alignSelf="flex-end" flexGrow={0}>
      {moment(lifeEvent.timeStamp).format('DD-MM-YYYY')}
    </Box>
  </>
);

const LifeEventsDrawer = ({ lifeEvents, ...props }) => {
  return (
    <Drawer width={450} {...props}>
      {lifeEvents.length > 0 ? lifeEvents.map(l => <Item key={l._id} lifeEvent={l} />) : <>No life events</>}
    </Drawer>
  );
};

LifeEventsDrawer.propTypes = {
  lifeEvents: PropTypes.array
};

LifeEventsDrawer.defaultProps = {
  lifeEvents: []
};

export default LifeEventsDrawer;
