import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { ThemeProvider } from '@material-ui/styles';
import theme from './constants/theme';

import store, { history } from './state/store';
import App from './App';

import './styles/main.scss';

// register service worker
// import { registerServiceWorker } from './utils/fcmConfig';

// registerServiceWorker();

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('app')
);
