export const GET_USER = 'users/GET_USER';
export const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'users/GET_USER_ERROR';

export const SET_SELECTED_USER = 'users/SET_SELECTED_USER';

export const UPDATE_USER = 'users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'users/UPDATE_USER_ERROR';

export const CREATE_USER = 'users/CREATE_USER';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'users/CREATE_USER_ERROR';
export const CLEAR_USER_CREATED = 'users/CLEAR_USER_CREATED';

export const RESEND_INVITATION = 'users/RESEND_INVITATION';
export const RESEND_INVITATION_SUCCESS = 'users/RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_ERROR = 'users/RESEND_INVITATION_ERROR';

export const ADD_LEADER_TO_USER = 'users/ADD_LEADER_TO_USER';
export const ADD_LEADER_TO_USER_SUCCESS = 'users/ADD_LEADER_TO_USER_SUCCESS';
export const ADD_LEADER_TO_USER_ERROR = 'users/ADD_LEADER_TO_USER_ERROR';

export const REMOVE_LEADER_FROM_USER = 'users/REMOVE_LEADER_FROM_USER';
export const REMOVE_LEADER_FROM_USER_SUCCESS =
  'users/REMOVE_LEADER_FROM_USER_SUCCESS';
export const REMOVE_LEADER_FROM_USER_ERROR = 'users/REMOVE_LEADER_FROM_USER_ERROR';
export const USER_LEADER_SAVING_STATE = 'users/USER_LEADER_SAVING_STATE';

export const REQUEST_EMAIL_CHANGE = 'users/REQUEST_EMAIL_CHANGE';
export const REQUEST_EMAIL_CHANGE_SUCCESS = 'users/REQUEST_EMAIL_CHANGE_SUCCESS';
export const REQUEST_EMAIL_CHANGE_ERROR = 'users/REQUEST_EMAIL_CHANGE_ERROR';

export const GET_EMAIL_CHANGE = 'users/GET_EMAIL_CHANGE';
export const GET_EMAIL_CHANGE_SUCCESS = 'users/GET_EMAIL_CHANGE_SUCCESS';
export const GET_EMAIL_CHANGE_ERROR = 'users/GET_EMAIL_CHANGE_ERROR';
export const EMAIL_CHANGE_LOADING_STATE = 'users/EMAIL_CHANGE_LOADING_STATE';
export const EMAIL_CHANGE_SAVING_STATE = 'users/EMAIL_CHANGE_SAVING_STATE';

export const RESEND_EMAIL_CHANGE = 'users/RESEND_EMAIL_CHANGE';
export const RESEND_EMAIL_CHANGE_SUCCESS = 'users/RESEND_EMAIL_CHANGE_SUCCESS';
export const RESEND_EMAIL_CHANGE_ERROR = 'users/RESEND_EMAIL_CHANGE_ERROR';

export const CANCEL_EMAIL_CHANGE = 'users/CANCEL_EMAIL_CHANGE';
export const CANCEL_EMAIL_CHANGE_SUCCESS = 'users/CANCEL_EMAIL_CHANGE_SUCCESS';
export const CANCEL_EMAIL_CHANGE_ERROR = 'users/CANCEL_EMAIL_CHANGE_ERROR';

export const GET_USER_ACTIVITY = 'users/GET_USER_ACTIVITY';
export const GET_USER_ACTIVITY_SUCCESS = 'users/GET_USER_ACTIVITY_SUCCESS';
export const GET_USER_ACTIVITY_ERROR = 'users/GET_USER_ACTIVITY_ERROR';
export const USER_ACTIVITY_LOADING_STATE = 'users/USER_ACTIVITY_LOADING_STATE';

export const STORE_PROFILE_IMAGE = 'users/STORE_PROFILE_IMAGE';

export const REMOVE_USER_FROM_COMMUNITY = 'users/REOMOVE_USER_FROM_COMMUNITY';
export const REMOVE_USER_FROM_COMMUNITY_SUCCESS =
  'users/REMOVE_USER_FROM_COMMUNTY_SUCCESS';
export const REMOVE_USER_FROM_COMMUNITY_ERROR =
  'users/REMOVE_USER_FROM_COMMUNITY_ERROR';

export const GET_USER_LIFE_EVENTS = 'users/GET_USER_LIFE_EVENTS';
export const GET_USER_LIFE_EVENTS_SUCCESS = 'users/GET_USER_LIFE_EVENTS_SUCCESS';
export const GET_USER_LIFE_EVENTS_ERROR = 'users/GET_USER_LIFE_EVENTS_ERROR';
export const LIFE_EVENTS_LOADING_STATE = 'users/LIFE_EVENT_LOADING_STATE';

export const GET_USER_ACCOUNTABILITY_SCORE = 'users/GET_USER_ACCOUNTABILITY_SCORE';
export const GET_USER_ACCOUNTABILITY_SCORE_SUCCESS =
  'users/GET_USER_ACCOUNTABILITY_SCORE_SUCCESS';
export const GET_USER_ACCOUNTABILITY_SCORE_ERROR =
  'users/GET_USER_ACCOUNTABILITY_SCORE_ERROR';
export const USER_ACCOUNTABILITY_SCORE_LOADING_STATE =
  'users/USER_ACCOUNTABILITY_SCORE_LOADING_STATE';

export const GET_USER_CURRENT_ACCOUNTABILITY_SCORE =
  'users/GET_USER_CURRENT_ACCOUNTABILITY_SCORE';
export const GET_USER_CURRENT_ACCOUNTABILITY_SCORE_SUCCESS =
  'users/GET_USER_CURRENT_ACCOUNTABILITY_SCORE_SUCCESS';
export const GET_USER_CURRENT_ACCOUNTABILITY_SCORE_ERROR =
  'users/GET_USER_CURRENT_ACCOUNTABILITY_SCORE_ERROR';

export const USER_LOADING_STATE = 'users/USER_LOADING_STATE';
export const USER_SAVING_STATE = 'users/USER_SAVING_STATE';
