import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import AppShell from '../containers/AppShell';
import AuthenticatedView from './AuthenticatedView';

// Authenticated routes
import Routes from '../constants/routes';

// Selectors
import { selectCurrentUserCommunityPermissions } from '../state/community/reducers';

import Authenticated404 from '../containers/Authenticated404';

// Hack to ensure safe loading of user in sub pages
// Should be in higher level component
// Consider refactor
const HasUserGuard = props => {
  const { children } = props;
  const auth = useSelector(state => state.auth.userId);
  return <>{auth ? children : null}</>;
};

// Potentially overkill - further wrap the authenticated route component
// to bring in redux state to stop unnecessary re-renders
export default function AuthenticatedRouteSwitch() {
  // Redux
  const authPermissions = useSelector(state => selectCurrentUserCommunityPermissions(state));
  const isComAdmin = authPermissions.some(p => p === 'admin');

  // Build routes with titles etc
  const _routes = Routes('', isComAdmin);

  return (
    <HasUserGuard>
      <AppShell>
        <Switch>
          {_routes.map(route => {
            const { key, path, permissions, component, title, child, showHeader, exact } = route;
            return (
              <Route
                key={key}
                exact={exact !== undefined ? exact : true}
                path={path}
                render={() => (
                  <AuthenticatedView
                    permissions={permissions}
                    component={component}
                    routeTitle={title}
                    childRoute={child}
                    showHeader={showHeader}
                  />
                )}
              />
            );
          })}
          <Route
            render={() => <AuthenticatedView component={Authenticated404} showHeader={false} routeTitle="404" />}
          />
          )}
        </Switch>
      </AppShell>
    </HasUserGuard>
  );
}
