import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  chip: {
    width: 150,
    height: 30,
    borderRadius: 25,
    backgroundColor: 'none !important',
    color: '#ffffff',
    fontSize: 12,
    backgroundImage: 'linear-gradient(to left, #2e598d, #072144)'
  }
});

const getLabel = role => {
  let label = '';
  switch (role) {
    case 'leader':
      label = 'Leader';
      break;
    case 'admin':
      label = 'Community Admin';
      break;
    default:
      break;
  }

  return label;
};

const RoleChip = ({ role, style, className }) => {
  const classes = useStyles();

  const label = getLabel(role);

  return (
    <Chip style={style} className={clsx(classes.chip, className)} label={label} />
  );
};

RoleChip.propTypes = {
  role: PropTypes.oneOf(['leader', 'admin']).isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

RoleChip.defaultProps = {
  style: null,
  className: ''
};

export default RoleChip;
