import React, { useState, useEffect } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import Button from '../Button/Button';

const useStyles = makeStyles(() => ({
  drawer: {
    width: props => props.width || 450,
    flexGrow: 0,
    padding: 20,
    zIndex: 10000 // Chat component,
  },
  drawerPaper: {
    width: props => props.width || 450,
    padding: 20
  },
  closeButton: {
    width: 10
  }
}));

const DrawGeneric = ({ open, onOpen, onClose, title, children, ...props }) => {
  const classes = useStyles(props);

  const [localOpen, setLocalOpen] = useState(open);

  useEffect(() => {
    setLocalOpen(open);
    if (open && onOpen) {
      onOpen(true);
    }
  }, [open]);

  const handleClose = () => {
    setLocalOpen(false);
    onClose(false);
  };
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      open={localOpen}
      {...props}
      onClose={handleClose}
    >
      <Box padding={1}>
        <IconButton disableTouchRipple onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box padding={1}>{children}</Box>
    </Drawer>
  );
};
export default DrawGeneric;
