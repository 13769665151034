import decode from 'jwt-decode';

export const setLocalStore = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (error) {
    throw error;
  }
};

export const getLocalStore = key => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    throw error;
  }
};

export const removeLocalStore = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    throw error;
  }
};

export const setAuthToken = tokenData => {
  const { idToken, refreshToken, expiresIn } = tokenData;
  setLocalStore('idToken', idToken);
  setLocalStore('refreshToken', refreshToken);
  setLocalStore('expiresIn', expiresIn);
  setLocalStore('loggedIn', true);
};

export const clearAuthToken = () => {
  removeLocalStore('idToken');
  removeLocalStore('refreshToken');
  removeLocalStore('expiresIn');
  removeLocalStore('community');
  setLocalStore('loggedIn', false);
};

export const getDataFromToken = async tokenData => {
  const { idToken } = tokenData;
  try {
    const decodedToken = await decode(idToken);

    const { user_id } = decodedToken;
    return { user_id };
  } catch (error) {
    throw error;
  }
};
