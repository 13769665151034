import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import SearchInput from '../SearchInput/SearchInput';
import Dropdown from '../Dropdown/Dropdown';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    marginBottom: 20
  },
  search: {
    flex: 1
  },
  mr16: {
    marginRight: 16
  },
  team: {
    maxWidth: 226
  }
});

const MemberFilter = ({
  search,
  onSearchChange,
  team,
  onTeamChange,
  teams,
  isOfficerView
}) => {
  // Hooks
  const classes = useStyles();

  const searchClass = clsx({
    [classes.search]: true,
    [classes.mr16]: !isOfficerView
  });

  return (
    <Box className={classes.wrap}>
      <SearchInput
        className={searchClass}
        value={search}
        onChange={onSearchChange}
        placeholder="Filter by member name…"
      />
      {teams.length > 0 && !isOfficerView && (
        <Dropdown
          showNoneOption
          className={classes.team}
          options={teams}
          selected={team}
          onChange={onTeamChange}
          disabled={!teams.length}
          placeholder="Filter by team name…"
        />
      )}
    </Box>
  );
};

MemberFilter.propTypes = {
  search: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  team: PropTypes.string.isRequired,
  onTeamChange: PropTypes.func.isRequired,
  teams: PropTypes.array,
  isOfficerView: PropTypes.bool.isRequired
};

MemberFilter.defaultProps = {
  teams: []
};

export default MemberFilter;
