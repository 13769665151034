import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../TextInput/TextInput';
import PasswordInput from '../../PasswordInput/PasswordInput';
import Checkbox from '../../Checkbox/Checkbox';
import Button from '../../Button/Button';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: '68px 20px 50px 20px',
    '@media (min-width: 768px)': {
      padding: '68px 44px 49px 41px'
    }
  },
  m20: {
    marginBottom: 20
  },
  textCenter: {
    textAlign: 'center'
  },
  loginButton: {
    width: 150
  }
});

export default function LoginCard({
  loading,
  email,
  password,
  remember,
  handleTextChange,
  handleCheck,
  handleSubmit,
  emailError,
  passwordError
}) {
  const classes = styles();

  const canSubmit = (email !== '' && password !== '') || loading;

  function onPressEnter(e) {
    if (e.key === 'Enter' && canSubmit) {
      handleSubmit();
    }
  }

  return (
    <Paper className={classes.paper} onKeyPress={onPressEnter}>
      <Grid container>
        <Grid item xs={12} className={classes.m20}>
          <TextInput
            name="email"
            label="Email address"
            disabled={loading}
            value={email}
            onChange={handleTextChange}
            error={Boolean(emailError)}
            errorMessage={emailError}
          />
        </Grid>
        <Grid item xs={12} className={classes.m20}>
          <PasswordInput
            name="password"
            label="Password"
            disabled={loading}
            value={password}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12} className={clsx(classes.textCenter, classes.m20)}>
          <Checkbox
            label="Remember me"
            checked={remember}
            onChange={handleCheck}
            disabled={loading}
            error={Boolean(passwordError)}
            errorMessage={passwordError}
            grey
          />
        </Grid>
        <Grid item xs={12} className={classes.textCenter}>
          <Button
            label="Log in"
            loading={loading}
            disabled={!canSubmit}
            onKeyPress={onPressEnter}
            onClick={handleSubmit}
            className={classes.loginButton}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

LoginCard.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  remember: PropTypes.bool,
  handleTextChange: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string,
  passwordError: PropTypes.string
};

LoginCard.defaultProps = {
  loading: false,
  email: '',
  password: '',
  remember: false,
  emailError: '',
  passwordError: ''
};
