import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';

import PopUpMenu from '../PopUpMenu/PopUpMenu';

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    minHeight: 60,
    borderRadius: 10,
    marginBottom: 10
  },
  teamDetails: {
    width: 400,
    display: 'flex',
    flex: 1,
    flexGrow: 0,
    flexBasis: 400,
    justifyContent: 'space-evenly'
  },
  scores: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  node: {
    backgroundColor: '#f2f2f2',
    borderRadius: 10,
    display: 'flex',
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flex: 1,
    alignItems: 'center',
    marginRight: 5
  },
  item: {
    backgroundColor: '#f2f2f2',
    borderRadius: 10,
    display: 'flex',
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    flexGrow: 0,
    justifyContent: 'center',
    flexShrink: 1,
    width: 90,
    marginLeft: 5
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  },
  description: {
    fontSize: 12,
    fontWeight: 300,
    flex: 1
  }
});

export default function TeamRowCard({ title, description, menuActions, onClick, scores, groupBy, scoresByMonth }) {
  const classes = useStyles();

  const calculateDirection = (before, current) => {
    return before > current ? -1 : 1;
  };

  const renderArrow = direction => {
    switch (direction) {
      case 1:
        return <UpIcon fontSize="small" />;
      case -1:
        return <DownIcon fontSize="small" />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.container} onClick={onClick}>
      <Box className={classes.teamDetails}>
        <Box className={classes.node}>
          <Typography noWrap className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.node}>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>

      <Box className={classes.scores}>
        {groupBy === 'week' &&
          scores.map((score, index) => {
            if (score.score) {
              score.score = parseFloat(score.score)
              if (score.score && !Number.isInteger(score.score)) {
                score.score = score.score.toFixed(1)
              }
              const lastEntry = scores[index - 1];
              const direction = lastEntry ? calculateDirection(lastEntry.score, score.score) : 0;
              return (
                <Box className={classes.item}>
                  {(score.score)}% {renderArrow(direction)}
                </Box>
              );
            }

          })}

        {groupBy === 'month' &&
          scoresByMonth.map((score, index) => {
            if (score.average) {
              score.average = parseFloat(score.average)

              if (score.average && !Number.isInteger(score.average)) {
                score.average = score.average.toFixed(1)
              }
              const lastEntry = scores[index - 1];
              const direction = lastEntry ? calculateDirection(lastEntry.average, score.average) : 0;
              return (
                <Box className={classes.item}>
                  {(score.average)}% {renderArrow(direction)}
                </Box>
              );
            }

          })}
      </Box>
      {menuActions.length > 0 && <PopUpMenu menuActions={menuActions} />}
    </Box>
  );
}

TeamRowCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  menuActions: PropTypes.array,
  onClick: PropTypes.func,
  scores: PropTypes.array,
  scoresByMonth: PropTypes.array,
  groupBy: PropTypes.string.isRequired
};

TeamRowCard.defaultProps = {
  menuActions: [],
  onClick: null,
  scores: [],
  scoresByMonth: []
};
