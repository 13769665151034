import PropTypes from 'prop-types'
import React from 'react'

const Archaic = ({className}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 170.08 170.08"
      className={className}
      width="250"
      height="250"
    >
      <defs>
        <style>{'.archaic-cls-1{opacityxs:0.23;} /**.archaic-cls-2{fill:none;stroke:#000;stroke-linejoin:round;stroke-width:1.5px;}**/'}</style>
      </defs>
      <g className="archaic-cls-1">
        <path
          className="archaic-cls-2"
          d="M105.59,84.13l7.91,10.42,2.38,13.12a5.32,5.32,0,1,0,7.2-1l-2.22-17-17.22-20.5"
        />
        <polyline className="archaic-cls-2" points="81.16 70.08 73.61 73.17 65.73 84.94 60.34 83.42 55.68 77.39" />
        <polyline className="archaic-cls-2" points="54.49 89.19 56.17 90.27 68.3 97.09 76.98 83.28 78.17 96.92" />
        <path
          d="M50.24,80.09c-.77-3-3-11.6-4.17-14.47-.17-.42-.36-.83-.55-1.22a13.46,13.46,0,0,1-1.75-4.5,6.22,6.22,0,0,1,0-1.6c.08-.52,2.4.08,2.54-.52.18-.81-1.78-3-1.58-3.76.77-3,1.68-6.91,4.38-9,7.55-5.78,9.87.38,9.73,10.09,0,2.58.84,5.15.38,7.7-.23,1.31-.5,2.61-.78,3.9C58.24,67.85,58,69,57.72,70a74.26,74.26,0,0,0-1.29,14c-.21,7.8,0,15.22,0,15.22L53,99.54a70.65,70.65,0,0,0-.74-7.79m-5-6c.06,3.06.3,5.53,3.3,5.44a5.51,5.51,0,0,0,5.31-5.67,5.43,5.43,0,0,0-5.55-5.38C47.23,80.17,47.11,82.65,47.18,85.7Z"
        />
        <path
          d="M103.76,54.06c0-6.09-.65-10-3.51-12.16-1.32-1-3.34,1.27-3.34,1.27s-4.29-3.53-5.29-3.53c-2.14,0-5.15,5.36-5.15,5.36s-3.62-3-4.35-2.22c-2.21,2.29-2.37,5.93-2.37,11.28a7.8,7.8,0,0,0,4.86,8C84.89,58.81,88.8,57,92.09,57s6.74,2.1,7.19,5.14A8.51,8.51,0,0,0,103.76,54.06ZM87.3,54a1.51,1.51,0,1,1,1.51-1.51A1.51,1.51,0,0,1,87.3,54Zm2.27-3.19L85,49.66V48l4.55,1.18ZM96.78,54a1.51,1.51,0,1,1,1.51-1.51A1.52,1.52,0,0,1,96.78,54Zm2.28-4.38-4.55,1.19V49.14L99.06,48Z"
        />
        <polyline
          className="archaic-cls-2"
          points="80.94 54.06 76.27 64.51 85.79 76.09 97.33 76.09 107.62 64.51 102.57 54.06"
        />
        <rect x="89.1" y="61.79" width="6.17" height="1.48" />
        <path
          d="M104.83,69.21c.64,0,6.36,53.46,6.36,53.46s-10-3-10.29-3.81c0,0-10.61,6.78-10.61,6.78l-7.79-5.8s-8.09,2-10.49,2.57c.42-.9,2.17-16.19,2.17-16.19L77,91.6C87.3,88.35,96.09,76,96.09,76M81.16,94.34s.06,10.16,3.86,15.1a23,23,0,0,0,9.15,6.92L85.79,99.21Zm1.51,20.06A1,1,0,0,0,84,113.21a23.85,23.85,0,0,0-3.78-5.59,16.08,16.08,0,0,0-3.37-2.79.76.76,0,0,0-1.11.93l2.2,5.72ZM89.1,88.68A15.85,15.85,0,0,0,93,102.48c5.93,7,10.28,11.71,10.78,13.88s-5-15.32-7-17.15-2.61-5.79-3.8-8.06A5.09,5.09,0,0,0,89.1,88.68Zm8.07-7.38a.56.56,0,0,0-1,.49,94.27,94.27,0,0,0,5.58,14.11c2.28,4,2.68-3.9,2.68-3.9l-4-6.28Zm5.43,20s5.27,12.3,6.2,12.07c.63-.16-.77-5.7-1.73-8.48a5,5,0,0,0-3.09-3.1Z"
        />
        <polyline
          className="archaic-cls-2"
          points="80.94 54.06 72.61 50.33 77.59 42.81 79.75 32.39 87.3 35.01 91.94 31.14 97.87 36.28 105.02 34.99 106.58 43.94 110.32 51.34 102.57 54.06"
        />
        <path
          className="archaic-cls-2"
          d="M86.37,137.81l-.84-20.49L77,114l-1.19,24a8.34,8.34,0,0,0-3.09,6.46H89.81A8.31,8.31,0,0,0,86.37,137.81Z"
        />
        <path
          className="archaic-cls-2"
          d="M106.53,137.81l-.29-16.94-8.95-9.93-.82,26.73a8.38,8.38,0,0,0-3.61,6.86H110A8.35,8.35,0,0,0,106.53,137.81Z"
        />
      </g>
    </svg>
  )
}

Archaic.propTypes = {
  className: PropTypes.string
}

Archaic.defaultProps = {
  className: ''
}

export default Archaic
