import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as communityMissionActions from './actions';
import * as communityMissionTypes from './types';
import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

import { consolidateArrayState } from '../../utils/stateHelpers';

import { selectCommunityMissionObjectiveSystemComments } from './reducers';

function* getCommunityMissions({ communityId, userId = null, page = 1, search = null, orderBy, order }) {
  try {
    const limit = 25;
    const skip = page - 1 > 0 ? (page - 1) * limit : 0;

    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSIONS_LOADING, state: true });
    const {
      data: { results, total }
    } = yield call(
      communityMissionActions.getCommunityMissions,
      communityId,
      userId,
      search,
      limit,
      skip,
      orderBy,
      order
    );

    yield put({
      type: communityMissionTypes.GET_COMMUNITY_MISSIONS_SUCCESS,
      total,
      page,
      results,
      totalPages: Math.ceil(total / limit)
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSIONS_LOADING, state: false });
  }
}

function* getCommunityMission({ communityId, missionId }) {
  try {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_LOADING, state: true });
    const { data } = yield call(communityMissionActions.getCommunityMission, communityId, missionId);

    yield put({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_SUCCESS,
      communityMission: data
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_LOADING, state: false });
  }
}

function* createCommunityMission({ communityId, title, description, icon, leader, teams, endDate }) {
  try {
    const { data } = yield call(
      communityMissionActions.createCommunityMission,
      communityId,
      title,
      description,
      icon,
      leader,
      teams,
      endDate
    );
    yield put({
      type: communityMissionTypes.CREATE_COMMUNITY_MISSION_SUCCESS,
      communityMission: data
    });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Company Mission Created'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateCommunityMission({ communityId, missionId, title, description, icon, leader, teams, endDate }) {
  try {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_LOADING, state: true });
    const { data } = yield call(
      communityMissionActions.updateCommunityMission,
      communityId,
      missionId,
      title,
      description,
      icon,
      leader,
      teams,
      endDate
    );

    yield put({
      type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_SUCCESS,
      communityMission: data
    });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Company Mission Updated'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_LOADING, state: false });
  }
}

function* patchCommunityMissionStatus({ communityId, missionId, status }) {
  try {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_LOADING, state: true });
    const { data } = yield call(communityMissionActions.patchCommunityMissionStatus, communityId, missionId, status);

    yield put({
      type: communityMissionTypes.PATCH_COMMUNITY_MISSION_STATUS_SUCCESS,
      communityMission: data
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_LOADING, state: false });
  }
}

function* archiveCommunityMission({ communityId, missionId }) {
  try {
    yield put({ type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_LOADING, state: true });
    yield call(communityMissionActions.archiveCommunityMission, communityId, missionId);

    yield put({
      type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_SUCCESS
    });

    yield put(push(`/c/${communityId}/missions`));

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission archived successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_LOADING, state: false });
  }
}

function* getCommunityMissionObjectives({ communityId, userId, missionId, page = 1, term, status, orderBy, order }) {
  try {
    const limit = 25;
    const skip = page - 1 > 0 ? (page - 1) * limit : 0;

    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVES_LOADING, state: true });

    const {
      data: { results, total }
    } = yield call(
      communityMissionActions.getCommunityMissionObjectives,
      communityId,
      userId,
      missionId,
      term,
      status,
      limit,
      skip,
      orderBy,
      order
    );

    yield put({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVES_SUCCESS,
      objectives: results,
      total,
      page,
      results,
      limit,
      totalPages: Math.ceil(total / limit)
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVES_LOADING, state: false });
  }
}

function* getCommunityMissionObjective({ communityId, objectiveId }) {
  try {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: true });
    const { data } = yield call(communityMissionActions.getCommunityMissionObjective, communityId, objectiveId);

    yield put({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_SUCCESS,
      communityMissionObjective: data
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: false });
  }
}

function* createCommunityMissionObjective({ communityId, objective }) {
  try {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_LOADING, state: true });
    const { data } = yield call(communityMissionActions.createCommunityMissionObjective, communityId, objective);

    yield put({
      type: communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS,
      communityMission: data
    });

    // Display success snack
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective successfully created'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_LOADING, state: false });
  }
}

function* updateCommunityMissionObjective({ communityId, objectiveId, updates }) {
  try {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: true });

    const { data } = yield call(
      communityMissionActions.updateCommunityMissionObjective,
      communityId,
      objectiveId,
      updates
    );

    yield put({
      type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS,
      communityMissionObjective: data
    });

    // Display success snack
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective successfully updated'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: false });
  }
}

function* patchCommunityMissionObjectiveStatus({ communityId, objectiveId, status }) {
  try {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: true });
    const { data } = yield call(
      communityMissionActions.patchCommunityMissionObjectiveStatus,
      communityId,
      objectiveId,
      status
    );

    yield put({
      type: communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS_SUCCESS,
      communityMissionObjective: data
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_LOADING, state: false });
  }
}

function* archiveCommunityMissionObjective({ communityId, missionId, objectiveId }) {
  try {
    // No data returned on successful post
    yield call(communityMissionActions.archiveCommunityMissionObjective, communityId, objectiveId);

    // Redirect to mission page
    yield put(push(`/c/${communityId}/m/${missionId}`));

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective successfully archived'
    });

    yield put({
      type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_OBJECTIVE_SUCCESS,
      objectiveId,
      missionId
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* createCommunityMissionObjectivePractice({
  communityId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency,
  objective
}) {
  try {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE, state: true });

    const { data } = yield call(
      communityMissionActions.createCommunityMissionObjectivePractice,
      communityId,
      title,
      description,
      richDescription,
      countPerWeek,
      reminderFrequency,
      objective
    );

    yield put({
      type: communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS,
      practice: data
    });
    yield put({ type: snackTypes.SUCCESS_SNACK, content: 'Practice created'});
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE, state: false });
  }
}

function* updateCommunityMissionObjectivePractice({
  communityId,
  practiceId,
  title,
  description,
  richDescription,
  countPerWeek,
  reminderFrequency
}) {
  try {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE, state: true });

    const { data } = yield call(
      communityMissionActions.updateCommunityMissionObjectivePractice,
      communityId,
      practiceId,
      title,
      description,
      richDescription,
      countPerWeek,
      reminderFrequency
    );

    yield put({
      type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS,
      practice: data
    });
    yield put({ type: snackTypes.SUCCESS_SNACK, content: 'Practice successfully updated'});
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_PRACTICE_LOADING_STATE, state: false });
  }
}

function* deleteCommunityMissionObjectivePractice({ communityId, practiceId }) {
  try {
    yield call(communityMissionActions.deleteCommunityMissionObjectivePractice, communityId, practiceId);

    yield put({ type: communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE_SUCCESS, practiceId });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Practice deleted successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* patchCommunityMissionObjectiveTaskStatus({ communityId, taskId, completed }) {
  try {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_TASK_LOADING_STATE, state: true });

    const { data } = yield call(
      communityMissionActions.patchCommunityMissionObjectiveTaskStatus,
      communityId,
      taskId,
      completed
    );

    yield put({
      type: communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS_SUCCESS,
      task: data
    });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Task successfully updated'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_TASK_LOADING_STATE, state: false });
  }
}

function* deleteCommunityMissionObjectiveTask({ communityId, taskId }) {
  try {
    yield call(communityMissionActions.deleteCommunityMissionObjectiveTask, communityId, taskId);

    yield put({ type: communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK_SUCCESS, taskId });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Task deleted successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* createCommunityTask({ communityId, task, objectiveId }) {
  try {
    const { data } = yield call(communityMissionActions.createCommunityTask, communityId, objectiveId, task);
    yield put({ type: communityMissionTypes.CREATE_COMMUNITY_TASK_SUCCESS, task: data });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Action created'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateCommunityTask({ communityId, task, objectiveId }) {
  try {
    const { data } = yield call(communityMissionActions.updateCommunityTask, communityId, objectiveId, task);
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_TASK_SUCCESS, task: data.updated });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Action updated'
    });
  } catch (error) {
    yield put({ type: communityMissionTypes.UPDATE_COMMUNITY_TASK_ERROR, error, task });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getCommunityMissionObjectiveSystemComments({ communityId, objectiveId }) {
  try {
    const existingState = yield select(selectCommunityMissionObjectiveSystemComments);
    const dataExists = existingState.some(e => e.objectiveId === objectiveId);

    if (!dataExists) {
      yield put({
        type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
        state: true
      });
    }

    const { data } = yield call(
      communityMissionActions.getCommunityMissionObjectiveSystemComments,
      communityId,
      objectiveId
    );

    const systemComments = data.filter(d => d.systemComment);
    const newElement = { objectiveId, comments: systemComments };

    yield put({
      type: communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS,
      systemComments: consolidateArrayState(newElement, existingState, false, 'objectiveId')
    });

    yield put({
      type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: communityMissionTypes.COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
      state: false
    });
  }
}

export default [
  takeLatest(communityMissionTypes.GET_COMMUNITY_MISSIONS, getCommunityMissions),
  takeLatest(communityMissionTypes.GET_COMMUNITY_MISSION, getCommunityMission),
  takeLatest(communityMissionTypes.CREATE_COMMUNITY_MISSION, createCommunityMission),
  takeLatest(communityMissionTypes.UPDATE_COMMUNITY_MISSION, updateCommunityMission),
  takeLatest(communityMissionTypes.PATCH_COMMUNITY_MISSION_STATUS, patchCommunityMissionStatus),
  takeLatest(communityMissionTypes.ARCHIVE_COMMUNITY_MISSION, archiveCommunityMission),

  takeLatest(communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVES, getCommunityMissionObjectives),
  takeLatest(communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE, getCommunityMissionObjective),
  takeLatest(communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE, createCommunityMissionObjective),
  takeLatest(communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE, updateCommunityMissionObjective),
  takeLatest(communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_STATUS, patchCommunityMissionObjectiveStatus),
  takeLatest(communityMissionTypes.ARCHIVE_COMMUNITY_MISSION_OBJECTIVE, archiveCommunityMissionObjective),
  takeLatest(
    communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
    createCommunityMissionObjectivePractice
  ),
  takeLatest(
    communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
    updateCommunityMissionObjectivePractice
  ),
  takeLatest(
    communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
    deleteCommunityMissionObjectivePractice
  ),

  takeLatest(
    communityMissionTypes.PATCH_COMMUNITY_MISSION_OBJECTIVE_TASK_STATUS,
    patchCommunityMissionObjectiveTaskStatus
  ),

  takeLatest(communityMissionTypes.DELETE_COMMUNITY_MISSION_OBJECTIVE_TASK, deleteCommunityMissionObjectiveTask),
  takeLatest(communityMissionTypes.CREATE_COMMUNITY_TASK, createCommunityTask),
  takeLatest(communityMissionTypes.UPDATE_COMMUNITY_TASK, updateCommunityTask),

  takeLatest(
    communityMissionTypes.GET_COMMUNITY_MISSION_OBJECTIVE_SYSTEM_COMMENTS,
    getCommunityMissionObjectiveSystemComments
  )
];
