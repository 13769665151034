import React from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player/lazy'
import Uploady, {useBatchAddListener} from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import { asUploadButton } from '@rpldy/upload-button';
import { useSelector, useDispatch } from 'react-redux';
import isUrl from 'is-url';
import {debounce, uniq} from 'lodash';
import TextInput from '../TextInput/TextInput';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import {getErrorMessage, parseApiErrors, validateCreateToolkit} from '../../constants/validation';
import { ReactComponent as UploadIcon } from '../../static/images/icons/icon-upload.svg';
import * as modalTypes from '../../state/modal/types';
import ToolkitCategoriesTree from '../ToolkitCategoriesTree/ToolkitCategoriesTree';
import * as userTypes from '../../state/users/types';
import {selectUserApiErrors} from '../../state/users/reducers';

const styles = makeStyles({
  container: {
    marginTop: '40'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  detailsWrap: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '40px 20px 32px 20px'
  },
  contentWrap: {
    width: 560,
  },
  mb26: {
    marginBottom: 26
  },
  toolkitContent:{
    padding: '20px 20px 32px 20px',
    border: '1px solid lightgrey',
    // height: 1000,
    borderRadius: 10
  },
  title: {
    fontSize: 9,
    marginBottom: 5,
    marginLeft: 12,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
  },
  heroImageText: {
    fontSize: '.7rem',
    marginLeft: '.4rem',
    fontWeight: 500,
    color: 'black',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: '-0.4rem',
  },
  heroImageWrap: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '40px 20px 1px 20px'
  },
  heroVideoText: {
    fontSize: '.7rem',
    marginLeft: '.4rem',
    fontWeight: 500,
    color: 'black',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: '.2rem',
  },
  heroVideoWrap: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '20px 20px 1px 20px'
  },
  categoriesWrap: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '0px 20px 32px 20px'
  },
  editCategoriesText: {
    textDecoration: 'none',
    fontSize: 10,
    color: '#838b96',
  }
});

const ToolkitForm = ({values, setValues, errors, setErrors}) => {
  const dispatch = useDispatch();
  const classes = styles();

  // Redux
  const apiErrors = useSelector(state => selectUserApiErrors(state));

  function handleChange(e) {
    const { name, value } = e.target;

    if (errors !== null) {
      const hasError = validateCreateToolkit({ ...values, [name]: value });
      if (!hasError) {
        setErrors(null);
      } else {
        setErrors(hasError);
      }
    }

    setValues({
      ...values,
      [name]: value
    });
  }

  const {heroVideo} = values;

  const MyClickableDropZone = React.forwardRef((props, ref) => {
    const { onClick, ...buttonProps } = props;

    const onZoneClick = React.useCallback(
      e => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick]
    );

    useBatchAddListener((batch) => {
      setValues({...values, heroImage: batch.items[0].file})
    });

    return (
      <UploadDropZone
        {...buttonProps}
        ref={ref}
        onDragOverClassName="active"
        extraProps={{ onClick: onZoneClick }}
      >
        <div style={{
          textAlign: 'center',
          backgroundColor: '#E0E0E0',
          padding: '4rem',
          marginTop: '.5rem'
        }}
        >
          <div>
            <UploadIcon style={{width:30, height: 30, fill: '#223F63'}} />
          </div>
          <div>
            <small style={{fontWeight: 'bold'}}>
              Upload a hero image
            </small>
          </div>
          <div>
            <small>
              1200 x 800 recommended
            </small>
          </div>
        </div>
      </UploadDropZone>
    );
  });

  const DropZoneButton = asUploadButton(MyClickableDropZone);

  const handleShowEditCategoriesModal = () => {
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.TOOLKIT_CATEGORIES_MODAL,
      props: {
        nodes: values.categories,
        onSubmit: (nodes) => {
          setValues({...values, categories: nodes});
        },
        onEditNode: (nodes) => {
          setValues({...values, categories: nodes});
        },
        onDeleteNode: (nodes) => {
          setValues({...values, categories: nodes});
        },
      }
    });

    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
  }

  const computedErrors =
      errors || parseApiErrors(apiErrors, userTypes.CREATE_USER_ERROR);

  return (
    <Grid container spacing={2} style={{marginTop: '2rem'}}>
      <Grid item md={8}>
        <Paper elevation={6}>
          <Grid item md={12} className={classes.detailsWrap}>
            <Grid item md={8}>
              <TextInput
                value={values.title}
                name="title"
                label="Title"
                placeholder="Tool title"
                required
                onChange={handleChange}
                className={classes.mb26}
                error={Boolean(getErrorMessage(computedErrors, 'title'))}
                errorMessage={getErrorMessage(computedErrors, 'title')}
              />
            </Grid>
            <Grid item md={8}>
              <TextInput
                value={values.description}
                name="description"
                label="Summary"
                placeholder="Provide a short summary of the tool (approx 20 words)"
                multiline
                rows={6}
                required
                onChange={handleChange}
                className={classes.mb26}
                error={Boolean(getErrorMessage(computedErrors, 'description'))}
                errorMessage={getErrorMessage(computedErrors, 'description')}
              />
            </Grid>
            <Typography className={classes.title}>Toolkit Content</Typography>
            <Grid item md={12} className={classes.toolkitContent}>
              {Array.isArray(values.content) && values.content.length > 0 ? (
                <>
                  <p style={{display: 'none'}}>{JSON.stringify(values.content)}</p>
                  <RichTextEditor
                    defaultValue={values.content}
                    name="content"
                    onChange={debounce(content => {
                      setValues({
                        ...values,
                        content
                      });
                    }, 500)}
                  />
                </>
              ) : (
                <RichTextEditor
                  name="content"
                  onChange={debounce(content => {
                    setValues({
                      ...values,
                      content
                    });
                  }, 500)}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={4}>
        <Paper elevation={6}>
          <Grid item md={12} className={classes.heroImageWrap}>
            <Typography className={classes.heroImageText}>
              Hero Image
            </Typography>
            {!values.heroImage && (
              <div style={{cursor: 'pointer'}}>
                <Uploady autoUpload={false}>
                  <DropZoneButton  />
                </Uploady>
              </div>
            )}
            {values.heroImage && (
              <div style={{marginTop: '1rem'}}>
                <img
                  src={(isUrl(values.heroImage)) ? values.heroImage : URL.createObjectURL(values.heroImage)}
                  alt={values.heroImage.name}
                  style={{width: '100%', height: '100%'}}
                />
                <a
                  href=""
                  className={classes.editCategoriesText}
                  onClick={(e) => {
                    e.preventDefault()
                    setValues({...values, heroImage: undefined});
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </Grid>
          <Grid item md={12} className={classes.heroVideoWrap}>
            <Typography className={classes.heroVideoText}>
              Hero Video
            </Typography>
            <TextInput
              value={heroVideo}
              name="heroVideo"
              placeholder="Enter in Youtube or Vimeo link"
              onChange={handleChange}
              className={classes.mb26}
              error={Boolean(getErrorMessage(errors, 'heroVideo'))}
              errorMessage={getErrorMessage(errors, 'heroVideo')}
            />
            <div>
              <ReactPlayer
                style={{display: !heroVideo || Boolean(getErrorMessage(errors, 'heroVideo')) ? 'none' : ''}}
                width="100%"
                height="100%"
                url={heroVideo}
                onReady={() => {
                  setErrors(null)
                }}
                onError={(e) => {
                  if (e.type === 'error') {
                    setErrors({heroVideo: ['Invalid video URL.']})
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item md={12} className={classes.categoriesWrap}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography className={classes.heroImageText}>
                Categories
              </Typography>
              <a
                href=""
                className={classes.editCategoriesText}
                onClick={e => {
                  e.preventDefault();
                  handleShowEditCategoriesModal()
                }}
              >
                Edit Categories
              </a>
            </div>
            <div style={{marginLeft: '1rem', marginTop: '1rem'}}>
              <ToolkitCategoriesTree
                onChange={(category, isSelected) => {
                  if (isSelected) {
                    setValues({...values, categories: values.categories.filter(c => c !== category)});
                  } else {
                    setValues({...values, categories: uniq([...values.categories, category])});
                  }
                }}
                nodes={values.categories}
                selectedCategories={values.categories}
              />
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ToolkitForm