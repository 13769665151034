import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ProfileImage from '../ProfileImage/ProfileImage';

const useStyles = makeStyles({
  paper: {
    cursor: 'pointer',
    position: 'relative',
    margin: 5,
    padding: 8,
    top: 0,
    transition: 'all 0.1s ease',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      top: -2
    }
  },
  selected: {
    backgroundColor: '#c0c0c0 !important',
    boxShadow: 'none !important'
  },
  image: {
    marginRight: 5
  },
  name: {
    fontSize: 12,
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  }
});

const UserSnack = ({ user, onClick, selected }) => {
  const classes = useStyles();

  const wrapClass = clsx({
    [classes.paper]: true,
    [classes.selected]: selected
  });

  return (
    <Paper onClick={onClick} className={wrapClass}>
      <ProfileImage userId={user.id} className={classes.image} />
      <Typography className={classes.name} noWrap>
        {`${user.firstName} ${user.lastName}`}
      </Typography>
    </Paper>
  );
};

UserSnack.propTypes = {
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default UserSnack;
