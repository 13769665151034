import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import * as userTypes from '../../state/users/types';

import AccountabilityScore from '../AccountabilityScore/AccountabilityScore';
import AccountabilityScoreChart from '../AccountabilityScore/AccountabilityScoreChart';
import MemberMissions from './MemberMissions';

import {
  selectSelectedUserCurrentAccountabilityScore,
  selectSelectedUserCurrentActionsScore,
  selectSelectedUserCurrentPracticesScore
} from '../../state/users/reducers';

const useStyles = makeStyles({
  wrap: {}
});

const MemberOverview = ({ selectedMember, isAdminView, isOfficer, isOfficerView, userId, communityId, role }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUserScore = useSelector(selectSelectedUserCurrentAccountabilityScore);
  const currentUserActions = useSelector(selectSelectedUserCurrentActionsScore);
  const currentUserPractices = useSelector(selectSelectedUserCurrentPracticesScore);

  /*
  let missionType = null;


  if (!isOfficerView && !isAdminView) {
    missionType = missionTypes.GET_ALL_COMMUNITY_MISSIONS;
  }

  if (isOfficer) {
    missionType = missionTypes.GET_ALL_OFFICER_MISSIONS;
  }
  */

  const fetchData = () => {
    if (!isAdminView) {
      dispatch({
        type: userTypes.GET_USER_CURRENT_ACCOUNTABILITY_SCORE,
        userId,
        communityId
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.wrap}>
      <AccountabilityScore
        userId={userId}
        score={currentUserScore}
        actions={currentUserActions}
        practices={currentUserPractices}
      />

      {!isAdminView && (
        <Box marginBottom={8}>
          <AccountabilityScoreChart userId={userId} communityId={communityId} />
        </Box>
      )}

      {((!isOfficerView && !isAdminView) || isOfficer) && (
        <>
          <Typography variant="h6">Missions</Typography>
          <MemberMissions
            isOfficerView={isOfficerView}
            userId={userId}
            communityId={communityId}
            role={role}
            selectedMember={selectedMember}
          />
        </>
      )}
    </div>
  );
};

MemberOverview.propTypes = {
  selectedMember: PropTypes.object,
  isAdminView: PropTypes.bool,
  isOfficer: PropTypes.bool,
  isOfficerView: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  communityId: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['communityAdmin', 'leader']).isRequired
};

MemberOverview.defaultProps = {
  selectedMember: null,
  isAdminView: false,
  isOfficer: false,
  isOfficerView: false
};

export default MemberOverview;
