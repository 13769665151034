import axios from 'axios';

export const requestChatToken = async () => {
  try {
    const response = await axios.get('/v1/chat');
    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  }
};


export default requestChatToken;