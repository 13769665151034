import * as types from './types';

const initialState = {
  loadingState: false,
  savingState: false,
  allLeaderUsers: [],
  openChats: [],
  messages: [],
  isChatAuthenticated: false,
  conversations: {}
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_CHAT:
      return {
        ...state,
        openChats: [...state.openChats, action.user]
      };

    case types.CLOSE_CHAT:
      return {
        ...state,
        openChats: state.openChats.filter(user => user._id !== action.userId)
      };

    case types.UPDATE_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };

    case types.UPDATE_CHAT_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload.reduce((acc, c) => {
          acc[c.id] = c.lastRead;
          return acc;
        }, {})
      };
    }
    case types.GET_ALL_LEADER_USERS_SUCCESS:
      return {
        ...state,
        allLeaderUsers: action.users
      };

    case types.ALL_LEADER_USERS_LOADING_STATE:
      return {
        ...state,
        loadingState: action.state
      };

    case types.CHAT_AUTH_SUCCESS:
      return {
        ...state,
        isChatAuthenticated: true
      };

    case types.CHAT_AUTH_ERROR:
      return {
        ...state,
        isChatAuthenticated: false
      };

    default:
      return state;
  }
};

export default chatReducer;

export const selectChatLoading = state => state.chat.loadingState;
export const selectChatSaving = state => state.chat.savingState;
export const selectOpenChats = state => state.chat.openChats;
export const selectChatMessages = state => state.chat.messages;
export const selectAllLeaderUsers = state => state.chat.allLeaderUsers;
export const selectChatAuthState = state => state.chat.isChatAuthenticated;
export const selectChatEnabled = state => state.chat.allLeaderUsers.length > 0;

export const selectUnreadChatCounts = state =>
  state.chat.messages
    .filter(m => m.fromID !== state.auth.userId)
    .filter(m => m.timestamp.toDate() > new Date(state.chat.conversations[m.fromID]))
    .reduce((acc, m) => {
      acc[m.fromID] = acc[m.fromID] ? acc[m.fromID] + 1 : 1;
      return acc;
    }, {});
