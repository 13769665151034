import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import PhoneNumber from 'awesome-phonenumber';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Button/Button';
import ProfileImageUpload from '../ProfileImageUpload/ProfileImageUpload';
import TextInput from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';

import UserPermissions from './UserPermissions';
import UserEmail from './UserEmail';
import AssignedLeaders from '../AssignedLeaders/AssignedLeaders';

import * as userTypes from '../../state/users/types';
import * as toolkitTypes from '../../state/toolkits/types';
import {
  selectSelectedUser,
  selectUserSavingState,
  selectSelectedUserLeaders,
  selectUserEmailChange,
  selectUserApiErrors
} from '../../state/users/reducers';
import { selectSelectedCommunityId } from '../../state/community/reducers';

import { validateEditUser, getErrorMessage, parseApiErrors } from '../../constants/validation';
import { checkAndGetPermissions } from '../../utils/helpers';
import {selectToolkits, selectToolkitsLoadingState, selectUserToolkits} from '../../state/toolkits/reducers';
import AssignedToolkits from '../AssignedToolkits/AssignedToolkits';

const styles = makeStyles({
  drawer: {
    maxWidth: 720,
    marginLeft: 80,
    paddingBottom: 40,
    display: 'block'
  },
  title: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#838b96'
  },
  button: {
    width: 150
  },
  topGridContainer: {
    padding: '40px 40px 0px 40px'
  },
  emailContainer: {
    padding: '20px 40px',
    backgroundColor: '#f4f4f4'
  },
  profileImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20
  },
  flex: {
    display: 'flex'
  },
  divCenter: {
    alignItems: 'center',
    display: 'flex',
    margin: '0 auto',
  },
  alignCenter: {
    alignItems: 'center'
  },
  alignEnd: {
    alignItems: 'flex-end'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  },
  mb25: {
    marginBottom: 25
  },
  mb40: {
    marginBottom: 40
  },
  pr10: {
    paddingRight: 10
  },
  pl10: {
    paddingLeft: 10
  },
  icon: {
    position: 'absolute'
  }
});

export default function EditUserDrawer({ readOnly, open, handleClose }) {
  const dispatch = useDispatch();
  const classes = styles();

  // Redux state
  const selectedUser = useSelector(state => selectSelectedUser(state));
  const communityId = useSelector(state => selectSelectedCommunityId(state));
  const userSaving = useSelector(state => selectUserSavingState(state));
  const userLeaders = useSelector(state => selectSelectedUserLeaders(state));
  const emailChange = useSelector(state => selectUserEmailChange(state));
  const toolkitsLoadingState = useSelector(state => selectToolkitsLoadingState(state));
  const userToolkits = useSelector(state => selectUserToolkits(state));
  const apiErrors = useSelector(state => selectUserApiErrors(state));

  // Local hook state
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    biography: '',
    phoneNumber: '',
    birthday: null,
    birthdayUpdated: false,
    currentEmail: '',
    emailVerified: false,
    isLeader: false,
    isComAdmin: false
  });
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [newLeaders, setNewLeaders] = useState([]);
  const [newToolkits, setNewToolkits] = useState([]);
  const [loadingLeaders, setLoading] = useState(false);

  // Set state when selected user changes
  useEffect(() => {
    if (selectedUser !== null && open === true) {
      // Parse the selected users permissions
      const { isLeader, isComAdmin } = checkAndGetPermissions(selectedUser.memberships, communityId);

      // Update local state
      setValues({
        ...values,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        biography: selectedUser.biography,
        phoneNumber: selectedUser.phoneNumber,
        emailVerified: selectedUser.isEmailVerified,
        currentEmail: selectedUser.email,
        birthday: selectedUser.birthday ? moment(selectedUser.birthday) : null,
        isLeader,
        isComAdmin
      });

      // Initially set the email to current address
      setEmail(selectedUser.email);

      // Check for email change requests
      dispatch({
        type: userTypes.GET_EMAIL_CHANGE,
        userId: selectedUser._id
      });

      // Get toolkits
      dispatch({
        type: toolkitTypes.GET_TOOLKITS,
      });

      dispatch({
        type: toolkitTypes.GET_USER_TOOLKITS,
        userId: selectedUser._id
      });

      setImageFile(null);
    }
  }, [selectedUser, open]);

  // Update selected new leaders after successful post
  useEffect(() => {
    const updatedNewLeaders = newLeaders.filter(l => !userLeaders.some(ul => ul._id === l.id));
    setNewLeaders(updatedNewLeaders);
  }, [userLeaders]);

  // Update email if request to change exists
  useEffect(() => {
    if (emailChange !== null) {
      const { email: newEmail } = emailChange;
      setEmail(newEmail);
    }
    if (selectedUser !== null && !emailChange) setEmail(selectedUser.email);
  }, [emailChange, selectedUser]);

  // Event handlers
  function onClose() {
    setNewLeaders([]);
    handleClose();
  }

  function handleChange(e) {
    const { name, value } = e.target;

    // If they have submitted and had errors - check on change and update accordingly
    if (errors !== null) {
      const hasError = validateEditUser({ ...values, [name]: value });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    if (name === 'email') {
      setEmail(value);
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  }

  function handlePhoneChange(e) {
    const { value } = e.target;
    const parsedNumber = new PhoneNumber(value, 'AU');
    if (parsedNumber && parsedNumber.isValid()) {
      setValues({
        ...values,
        phoneNumber: parsedNumber.getNumber()
      });
    } else setValues({ ...values, phoneNumber: value });
  }

  function onAddLeader(leader) {
    setNewLeaders([...newLeaders, leader]);
  }

  function onAddToolkit(newToolkit) {
    setNewToolkits([...newToolkits, newToolkit]);
  }

  function onRemoveToolkit(toolkitId) {
    const isNewToolkit = newToolkits.some(t => t.id === toolkitId);

    if (isNewToolkit) {
      const updatedNewToolkits = newToolkits.filter(t => t.id !== toolkitId);
      setNewToolkits(updatedNewToolkits);

      setLoading(false)
    } else {
      dispatch({
        type: toolkitTypes.REMOVE_TOOLKIT_FROM_USER,
        toolkitId,
        userId: selectedUser._id
      });
    }
  }

  // If it's a new leader, update local state otherwise send api request
  function onRemoveLeader(leaderId) {
    setLoading(true)
    const isNewLeader = newLeaders.some(l => l.id === leaderId);
    if (isNewLeader) {
      const updated = newLeaders.filter(l => l.id !== leaderId);
      setNewLeaders(updated);

      setLoading(false)
    } else {
      dispatch({
        type: userTypes.REMOVE_LEADER_FROM_USER,
        userId: selectedUser.id,
        leaderId
      });

      // Add Delay (TODO remove leaders by batch)
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }

  }

  function onSave() {
    // Check for errors before sending
    const errorMessages = validateEditUser(values);
    if (errorMessages === undefined) {
      setErrors(null);

      // Everything checks out - submit the payload
      const { birthday, ...rest } = values;
      let updates = { ...rest, email };

      if (birthday) {
        updates = {
          ...updates,
          birthday: birthday.format()
        };
      }

      if (imageFile) {
        updates = {
          ...updates,
          imageFile
        };
      }

      if (newLeaders.length) {
        updates = {
          ...updates,
          leaders: newLeaders.map(l => l.id)
        };
      }

      if (newToolkits.length) {
        updates = {
          ...updates,
          toolkits: newToolkits.map(t => t.id)
        };

        dispatch({
          type: toolkitTypes.ASSIGN_TOOLKITS,
          toolkits: newToolkits.map(t => t.id),
          users: [selectedUser._id,],
          showSnack: false
        })
      }

      dispatch({
        type: userTypes.UPDATE_USER,
        userId: selectedUser._id,
        updates
      });
    } else setErrors(errorMessages);
  }

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked
    });
  }

  function handleDateChange(date) {
    setValues({
      ...values,
      birthday: moment(date)
    });
  }

  function handleImageChange(file) {
    setImageFile(file);
  }

  const {
    firstName,
    lastName,
    biography,
    birthday,
    phoneNumber,
    currentEmail,
    emailVerified,
    isLeader,
    isComAdmin
  } = values;

  const hasPendingRequest = emailChange !== null;

  // Remove duplicates from new leaders
  const computedNewLeaders = newLeaders.filter(l => !userLeaders.some(ul => ul._id === l.id));
  const computedLeaders = [...userLeaders, ...computedNewLeaders];

  const computedNewToolkits = newToolkits.filter(nt => !userToolkits.some(ut => ut.id === nt.id));
  const computedToolkits = [...computedNewToolkits, ...userToolkits];

  const computedErrors = errors || parseApiErrors(apiErrors, userTypes.UPDATE_USER_ERROR);

  return (
    <Drawer open={open} anchor="right" onClose={onClose} PaperProps={{ classes: { root: classes.drawer } }}>
      <IconButton onClick={onClose} className={classes.icon}>
        <CloseIcon />
      </IconButton>
      <Grid container className={classes.topGridContainer}>
        <Grid item xs={12}>
          <Grid container className={classes.mb40}>
            <Grid item xs={6} className={clsx(classes.flex, classes.alignCenter)}>
              <Typography className={classes.title}>{readOnly ? 'View user' : 'Edit user'}</Typography>
            </Grid>
            {!readOnly && (
              <Grid item xs={6} className={clsx(classes.flex, classes.justifyEnd)}>
                <Button
                  label="Save"
                  className={classes.button}
                  onClick={onSave}
                  loading={userSaving}
                  disabled={userSaving}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.profileImage}>
          <ProfileImageUpload onImageLoad={handleImageChange} />
        </Grid>
        <Grid item xs={6} className={clsx(classes.mb25, classes.pr10)}>
          <TextInput
            name="firstName"
            label="First name"
            value={firstName}
            onChange={handleChange}
            disabled={readOnly}
            error={Boolean(getErrorMessage(computedErrors, 'firstName'))}
            errorMessage={getErrorMessage(computedErrors, 'firstName')}
          />
        </Grid>
        <Grid item xs={6} className={clsx(classes.mb25, classes.pl10)}>
          <TextInput
            name="lastName"
            label="Last name"
            value={lastName}
            onChange={handleChange}
            disabled={readOnly}
            error={Boolean(getErrorMessage(computedErrors, 'lastName'))}
            errorMessage={getErrorMessage(computedErrors, 'lastName')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mb25}>
          <TextInput
            name="biography"
            label="Description"
            multiline
            rows={3}
            value={biography}
            onChange={handleChange}
            disabled={readOnly}
            error={Boolean(getErrorMessage(computedErrors, 'biography'))}
            errorMessage={getErrorMessage(computedErrors, 'biography')}
          />
        </Grid>
        <Grid item xs={6} className={clsx(classes.mb25, classes.pr10)}>
          <TextInput
            name="phoneNumber"
            label="Phone number"
            onChange={handlePhoneChange}
            value={phoneNumber}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6} className={clsx(classes.mb25, classes.pl10)}>
          <Datepicker
            label="Date of birth"
            name="birthday"
            readOnly={readOnly}
            onChange={handleDateChange}
            value={birthday}
            maxDate={moment().subtract(1, 'days')}
            maxDateMessage="Date of Birth cannot be a future date"
          />
        </Grid>
        <Grid item xs={12}>
          <UserPermissions
            readOnly={readOnly}
            isLeader={isLeader}
            isComAdmin={isComAdmin}
            onChange={handleCheckboxChange}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.emailContainer}>
        <Grid item xs={12} className={clsx(classes.flex, classes.alignEnd)}>
          <UserEmail
            readOnly={readOnly}
            hasPendingRequest={hasPendingRequest}
            currentEmail={currentEmail}
            email={email}
            verified={emailVerified}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.topGridContainer}>
        <Grid item xs={12}>
          {loadingLeaders ? (
            <CircularProgress className={classes.divCenter} size={50} />
          ) : (
            <AssignedLeaders
              readOnly={readOnly}
              assignedLeaders={computedLeaders}
              selectedLeaders={[]}
              onSelect={onAddLeader}
              onRemove={onRemoveLeader}
              menuPosition="top"
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.topGridContainer}>
        <Grid item xs={12}>
          {toolkitsLoadingState ? (
            <CircularProgress className={classes.divCenter} size={50} />
          ) : (
            <AssignedToolkits
              readOnly={readOnly}
              assignedToolkits={computedToolkits}
              selectedToolkits={[]}
              onSelect={onAddToolkit}
              onRemove={onRemoveToolkit}
              menuPosition="top"
            />
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}

EditUserDrawer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
