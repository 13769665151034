import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

const styles = makeStyles(theme => ({
  w100: {
    width: '100%'
  },
  labelRoot: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    zIndex: 1,
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 7,
    paddingLeft: 21,
    pointerEvents: 'none'
  },
  labelFocused: {
    color: '#838b96 !important'
  },
  labelShrink: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 15
  },
  labelError: {
    color: `${theme.palette.error.main} !important`
  },
  inputRoot: {
    backgroundColor: '#f4f4f4',
    color: '#223f63',
    borderRadius: 10,
    paddingLeft: 21,
    paddingRight: 21,
    fontSize: 12,
    minHeight: 40,
    '& svg': {
      height: 15,
      width: 15
    }
  },
  inputAdornmentEnd: {
    paddingRight: '10px !important'
  },
  inputFocused: {
    border: 'solid 1px #223f63'
  },
  inputError: {
    border: `solid 1px ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  helperRoot: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10,
    fontWeight: 300
  },
  helperError: {
    textAlign: 'end',
    paddingRight: 7
  }
}));

export default function PasswordInput({
  label,
  value,
  required,
  disabled,
  error,
  errorMessage,
  onChange,
  name
}) {
  const classes = styles();
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(!show);
  }

  const hasErrorWithMessage = error && errorMessage !== '';

  const endAdornment = (
    <InputAdornment>
      <IconButton size="small" onClick={handleShow} disabled={disabled}>
        {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl className={classes.w100} error={error}>
      <InputLabel
        disabled={disabled}
        classes={{
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError
        }}
      >
        {label}
      </InputLabel>
      <Input
        name={name}
        type={show ? 'text' : 'password'}
        required={required}
        value={value}
        disabled={disabled}
        disableUnderline
        endAdornment={endAdornment}
        classes={{
          root: clsx(classes.inputRoot, classes.inputAdornmentEnd),
          focused: classes.inputFocused,
          error: classes.inputError
        }}
        onChange={onChange}
      />
      {hasErrorWithMessage && (
        <FormHelperText
          classes={{ root: classes.helperRoot, error: classes.helperError }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool
};

PasswordInput.defaultProps = {
  label: '',
  required: false,
  error: false,
  errorMessage: '',
  onChange: null,
  value: '',
  name: '',
  disabled: false
};
