import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import ProfileImage from '../ProfileImage/ProfileImage';

import { selectLoggedInUserId } from '../../state/auth/reducers';

import { getFriendlyTime } from '../../utils/helpers';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  },
  wrap: {
    marginBottom: 10
  },
  message: {
    fontSize: 12,
    fontWeight: 300,
    // maxWidth: '60%',
    padding: 6,
    borderRadius: 15,
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif'
  },
  sender: {
    backgroundColor: '#ededed'
  },
  recipient: {
    backgroundImage: 'linear-gradient(to left, #2e598d, #072144)',
    color: '#ffffff'
  },
  date: {
    fontSize: 9,
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    userSelect: 'none'
  },
  tooltip: {
    fontWeight: 500,
    backgroundColor: '#223f63',
    margin: 0
  },
  arrow: {
    color: '#223f63'
  }
});

export default function ChatMessage({ fromId, message, timestamp, recipient }) {
  // Hooks
  const classes = useStyles();

  // Local state
  const [friendlyDate, setFriendlyDate] = useState(
    getFriendlyTime(timestamp.toDate().getTime())
  );

  // Redux
  const loggedInUser = useSelector(state => selectLoggedInUserId(state));

  // Update friendly date on enter (potentially overkill?)
  const updateFriendlyDate = () =>
    setFriendlyDate(getFriendlyTime(timestamp.toDate().getTime()));

  // Helper constants
  const isAuthor = fromId === loggedInUser;

  // Classes
  const wrapClass = clsx({
    [classes.flex]: true,
    [classes.wrap]: true,
    [classes.justifyEnd]: isAuthor
  });

  const messageClass = clsx({
    [classes.message]: true,
    [classes.sender]: !isAuthor,
    [classes.recipient]: isAuthor
  });

  return (
    <div className={wrapClass} onMouseEnter={updateFriendlyDate}>
      <Tooltip
        title={isAuthor ? friendlyDate : `${recipient} - ${friendlyDate}`}
        placement="left"
        classes={{ tooltip: classes.tooltip }}
        enterDelay={0}
        TransitionProps={{
          timeout: 50
        }}
      >
        <div style={{ display: 'flex' }}>
          {!isAuthor && (
            <ProfileImage size={25} userId={fromId} style={{ marginRight: 5 }} />
          )}
          <Typography className={messageClass}>{message}</Typography>
        </div>
      </Tooltip>
    </div>
  );
}

ChatMessage.propTypes = {
  fromId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.object.isRequired,
  recipient: PropTypes.string.isRequired
};
