import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as HelpIcon } from '../../static/images/icons/icon-help.svg';

const useStyles = makeStyles({
  container: {
    borderRadius: 14,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '0px 16px'
  },
  white: {
    backgroundColor: '#ffffff',
    color: '#223f63',
    '& svg': {
      color: '#223f63 !important',
      '& path': {
        fill: '#223f63 !important'
      }
    }
  },
  blue: {
    backgroundColor: '#223f63',
    color: '#ffffff',
    '& svg': {
      color: '#ffffff !important',
      '& path': {
        fill: '#ffffff !important'
      }
    }
  },
  icon: {
    marginRight: 5
  },
  typography: {
    fontSize: 14,
    userSelect: 'none',
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif'
  }
});

const InfoBar = ({ content, background, style }) => {
  const classes = useStyles();

  const wrapClass = clsx({
    [classes.container]: true,
    [classes.white]: background === 'white',
    [classes.blue]: background === 'blue'
  });

  return (
    <Box className={wrapClass} style={style}>
      <HelpIcon className={classes.icon} />
      <Typography noWrap className={classes.typography}>{content}</Typography>
    </Box>
  );
};

InfoBar.propTypes = {
  content: PropTypes.string.isRequired,
  background: PropTypes.oneOf(['blue', 'white']),
  style: PropTypes.object
};

InfoBar.defaultProps = {
  background: 'white',
  style: null
};

export default InfoBar;
