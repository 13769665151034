import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageHeader from '../components/PageHeader/PageHeader';
import MissionDetails from '../components/MissionTemplates/MissionDetails';
import Button from '../components/Button/Button';

import { validateMission } from '../constants/validation';
import AssignedLeaders from '../components/AssignedLeaders/AssignedLeaders';

import {
  selectCommunityMission,
  selectCommunityMissionSaving,
  selectCommunityMissionCreating,
  selectCommunityMissionLoading,
  selectCommunityMissionArchiving,
  selectCommunityMissionSaved
} from '../state/communityMission/reducers';

import * as communityMissionTypes from '../state/communityMission/types';
import * as communityTypes from '../state/community/types';
import * as modalTypes from '../state/modal/types';
// import useUpdateEffect from '../hooks/useUpdateEffect';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    margin: '25px 0px 10px 0px'
  },
  mr10: {
    marginRight: 10
  }
});

const CommunityMissionEdit = () => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { communityId, missionId } = useParams();
  const history = useHistory();

  // Local state
  const [details, setDetails] = useState({
    icon: 'circle',
    title: '',
    description: '',
    endDate: moment().add(30, 'days'),
    leaders: []
  });
  const [errors, setErrors] = useState(null);

  const isCreating = missionId === undefined;

  // Redux
  const selectedMission = useSelector(selectCommunityMission);
  const loading = useSelector(selectCommunityMissionLoading);
  const saving = useSelector(selectCommunityMissionSaving);
  const creating = useSelector(selectCommunityMissionCreating);
  const archiving = useSelector(selectCommunityMissionArchiving);
  const saved = useSelector(selectCommunityMissionSaved);

  const buttonDisabled = saving || creating;

  const mission = isCreating ? null : selectedMission;

  // check selectedMission.id -> missionID {

  // Redirect on success
  useEffect(() => {
    if (isCreating && saved) {
      history.push(`/c/${communityId}/m/${selectedMission._id}`);
    }
  }, [isCreating, saved, selectedMission]);

  const confirmArchiveMission = () => {
    dispatch({
      type: communityMissionTypes.ARCHIVE_COMMUNITY_MISSION,
      communityId,
      missionId
    });

    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: false
    });
  };

  // Helpers

  // Event handlers
  const handleSaveUpdates = () => {
    const errorMessages = validateMission(details);
    if (!errorMessages) {
      setErrors(null);

      const { icon, title, description, endDate, leader } = details;

      if (isCreating) {
        dispatch({
          type: communityMissionTypes.CREATE_COMMUNITY_MISSION,
          communityId,
          icon,
          title,
          description,
          endDate: endDate.toISOString(),
          leader: leader && leader?.length > 0 ? leader[0].id : null
        });
      } else {
        dispatch({
          type: communityMissionTypes.UPDATE_COMMUNITY_MISSION,
          communityId,
          missionId,
          icon,
          title,
          description,
          endDate: endDate.toISOString(),
          leader: leader && leader.length > 0 ? leader[0].id : null
        });
      }
    } else setErrors(errorMessages);
  };

  const handleTextChange = e => {
    const { name, value } = e.target;
    const updated = {
      ...details,
      [name]: value
    };

    if (errors) {
      const hasError = validateMission({ ...updated });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails({
      ...updated
    });
  };

  const handleIconChange = icon => {
    const updated = {
      ...details,
      icon
    };

    if (errors) {
      const hasError = validateMission(updated);
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails(updated);
  };

  const handleDateChange = value => {
    const updated = {
      ...details,
      endDate: value
    };

    if (errors) {
      const hasError = validateMission({ ...updated });
      if (!hasError) setErrors(null);
      else setErrors(hasError);
    }

    setDetails(updated);
  };

  const handleLeaderSelect = leader => {
    const updated = {
      ...details,
      leader: [leader]
    };

    setDetails(updated);
  };

  const handleRemoveLeader = leaderId => {
    const updated = {
      ...details,
      leader: details.leader.filter(leader => leader.id !== leaderId)
    };

    setDetails(updated);
  };

  const handleArchive = () => {
    // Set the modal component
    dispatch({
      type: modalTypes.MODAL_SET_COMPONENT,
      component: modalTypes.CONFIRMATION_MODAL,
      props: {
        title: 'Are you sure you want to archive this mission?',
        message: 'This will also archive all Objectives and Tasks associated with the Mission. This cannot be undone.',
        onSubmit: confirmArchiveMission,
        savingSelector: selectCommunityMissionSaving
      }
    });
    // Open the modal
    dispatch({
      type: modalTypes.MODAL_SET_OPEN_STATE,
      state: true
    });
  };

  // Effects
  useEffect(() => {
    dispatch({
      type: communityTypes.GET_COMMUNITY_LEADERS,
      communityId
    });

    if (missionId) {
      dispatch({
        type: communityMissionTypes.GET_COMMUNITY_MISSION,
        communityId,
        missionId
      });
    }
  }, []);

  useEffect(() => {
    if (mission?.id) {
      const { icon, title, description, endDate, leader } = mission;

      setDetails({
        icon,
        title,
        description,
        endDate: moment(endDate),
        leader: leader ? [leader] : []
      });
    }
  }, [mission]);

  const headerActions = (
    <>
      {!isCreating && (
        <Button
          loading={archiving}
          label="Archive"
          background="grey"
          className={classes.mr10}
          onClick={handleArchive}
        />
      )}

      <Button
        label={isCreating ? 'Create mission' : 'Save mission'}
        onClick={handleSaveUpdates}
        disabled={buttonDisabled}
        loading={buttonDisabled}
      />
    </>
  );

  const { icon, title, description, endDate, leader } = details;

  return (
    <>
      <PageHeader showBack routeTitle={`${missionId ? 'Edit' : 'Create'} Company Mission`} actions={headerActions} />

      <Box component="main">
        {loading ? (
          <Box display="flex" justifyContent="center" m={8}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <MissionDetails
              icon={icon}
              name={title}
              description={description}
              onTextChange={handleTextChange}
              onIconChange={handleIconChange}
              // onImageChange={}
              missionId={missionId}
              errors={errors}
              isTemplate={false}
              onDateChange={handleDateChange}
              endDate={endDate}
            />
            <AssignedLeaders
              readOnly={false}
              selectedLeaders={leader}
              assignedLeaders={[]}
              onSelect={handleLeaderSelect}
              onRemove={handleRemoveLeader}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default CommunityMissionEdit;
