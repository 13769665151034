import axios from 'axios';

export const createTeam = async (communityId, payload) => {
  try {
    const result = await axios.post(`/v1/communities/${communityId}/teams`, payload);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateTeam = async (communityId, teamId, payload) => {
  try {
    const result = await axios.put(
      `/v1/communities/${communityId}/teams/${teamId}`,
      payload
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTeam = async (communityId, teamId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/teams/${teamId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addMembersToTeam = async (communityId, teamId, users) => {
  try {
    const result = await axios.put(
      `/v1/communities/${communityId}/teams/${teamId}/users`,
      { users }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeMemberFromTeam = async (communityId, teamId, userId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/teams/${teamId}/users/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllTeams = async (communityId, leaderReq = false) => {
  try {
    const url = leaderReq
      ? `/v1/communities/${communityId}/teams?leaderReq=${leaderReq}`
      : `/v1/communities/${communityId}/teams`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
};
