import React from 'react';
import PropTypes from 'prop-types'

const Achiever = ({className}) => {
  return (
    <svg
      className={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 170.08 170.08"
      width="250"
      height="250"
    >
      <defs>
        <style>{'.achiever-cls-1{fill:none;}.achiever-cls-2{opacityxw:0.23;}.achiever-cls-3{clip - path:url(#clip-path);}'}</style>
        <clipPath id="clip-path">
          <rect className="achiever-cls-1" x="43.5" y="28.35" width="83.08" height="113.39" />
        </clipPath>
      </defs>
      <g className="achiever-cls-2">
        <g className="achiever-cls-3">
          <path
            d="M59.51,141.73v-3.27a139.42,139.42,0,0,1,1.37-15.13l2.68.39c0,.07-.88,6.2-1.21,11.33l30.13-30.13,1.92,1.92Z"
          />
        </g>
        <polygon points="61.35 132.71 52.33 125.19 62.4 107.91 64.75 109.28 55.83 124.57 63.09 130.63 61.35 132.71" />
        <polygon
          points="105.82 124.17 96.7 107.24 82.26 107.24 73.44 102.83 63.24 83.7 51.58 95.36 59.42 116.26 56.88 117.21 48.43 94.68 63.91 79.19 75.43 100.78 82.9 104.52 98.32 104.52 108.21 122.88 105.82 124.17"
        />
        <rect x="73.28" y="121.49" width="33.52" height="2.72" transform="translate(-61.8 134.77) rotate(-58.25)" />
        <rect x="100.23" y="114.02" width="2.71" height="24.43" />
        <polygon
          points="104.16 134.99 101.73 133.78 111.84 113.56 124.96 120.12 123.23 125.31 120.66 124.45 121.64 121.5 113.05 117.2 104.16 134.99"
        />
        <path d="M118.18,128.91l1.52-2.28,3,1.53L120.46,132Zm-3.33.09,5.86,7.81,5.87-9.77-7.81-3.91Z" />
        <rect x="113.48" y="134.38" width="6.07" height="2.71" transform="translate(-57 179.25) rotate(-63.43)" />
        <rect x="61.1" y="89.59" width="7.68" height="2.71" transform="translate(-45.29 72.55) rotate(-45)" />
        <rect x="52.04" y="59.33" width="2.71" height="29.3" transform="translate(-15.68 14.38) rotate(-13.39)" />
        <rect x="56.79" y="49.31" width="2.71" height="33.05" transform="translate(-18.4 22.76) rotate(-19.18)" />
        <polygon
          points="50 63.53 45.93 57.42 45.93 47.51 48.65 47.51 48.65 56.6 50 58.64 51.36 56.6 51.36 47.51 54.08 47.51 54.08 57.42 50 63.53"
        />
        <path d="M45.65,41.8h8.71v4.64H45.65ZM43.5,48.58h13V39.65h-13Z" />
        <path d="M46.72,34.66,50,31.38l3.28,3.28L50,37.94ZM50,28.35l-6.31,6.31L50,41l6.32-6.32Z" />
        <rect x="76.08" y="95.02" width="2.15" height="8.14" />
        <rect x="89.65" y="96.38" width="2.15" height="9.5" />
        <rect x="73.36" y="85.52" width="2.15" height="14.93" />
        <polygon
          points="88.37 98.81 80.85 98.81 73.77 93.14 75.11 91.47 81.6 96.66 87.65 96.66 92.37 93.13 92.37 85.52 94.51 85.52 94.51 94.2 88.37 98.81"
        />
        <g className="achiever-cls-3">
          <path
            d="M96.46,106.09,95,98.94,96.4,96.2l-1.32-9.33v-8a11.59,11.59,0,0,0-11.14-7.26c-7.89,0-10.66,4.93-11.14,5.94l0,6.71-1.35,9.5-2.13-.3,1.35-9.43V77.17l.08-.2c.12-.3,3.1-7.46,13.21-7.46a13.93,13.93,0,0,1,13.23,8.88l.06.34V86.8l1.39,9.76-1.34,2.68,1.28,6.43Z"
          />
        </g>
        <rect x="82.86" y="61.08" width="2.15" height="9.5" />
        <rect x="88.82" y="66.8" width="10.6" height="2.15" transform="translate(-18.28 96.7) rotate(-50.18)" />
        <rect x="72.68" y="62.57" width="2.15" height="10.6" transform="translate(-26.35 62.95) rotate(-39.81)" />
        <rect x="64.93" y="77.66" width="6.79" height="2.15" />
        <rect x="96.15" y="77.66" width="6.79" height="2.15" />
        <g className="achiever-cls-3">
          <path
            d="M96.15,86.59A15.71,15.71,0,0,1,84.1,80.76c-3.63,5.76-12,5.83-12.38,5.83V84.44c.09,0,8.81-.09,11.22-6.11l.8-2,1.12,1.85a13.81,13.81,0,0,0,11.29,6.26Z"
          />
        </g>
      </g>
    </svg>
  );
}

Achiever.propTypes = {
  className: PropTypes.string
}

Achiever.defaultProps = {
  className: ''
}

export default Achiever
