import axios from 'axios';

export const getAllCommunities = async () => {
  try {
    const result = await axios.get('/v1/communities?limit=999999');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCommunity = async communityId => {
  try {
    const result = await axios.get(`/v1/communities/${communityId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateCommunity = async (communityId, updates) => {
  try {
    const result = await axios.put(`/v1/communities/${communityId}`, updates);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCommunityUsers = async communityId => {
  try {
    const result = await axios.get(`/v1/communities/${communityId}/users`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCommunityLeaders = async communityId => {
  try {
    const result = await axios.get(
      `/v1/communities/${communityId}/users?type=leader`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaderUsers = async (leaderId, communityId = null) => {
  try {
    const url = communityId
      ? `/v1/users/${leaderId}/members?community=${communityId}`
      : `/v1/users/${leaderId}/members`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const batchUpload = async (communityId, formData) => {
  try {
    const result = await axios.post(
      `/v1/communities/${communityId}/users/batch`,
      formData,
      { contentType: 'multipart/form-data' }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaderActivity = async (communityId, limit = 10, skip = 0) => {
  try {
    const result = await axios.get(
      `/v1/communities/${communityId}/activity?limit=${limit}&skip=${skip}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAgnosticLeaderActivity = async (limit, skip) => {
  try {
    const result = await axios.get(`/v1/activity?limit=${limit}&skip=${skip}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updatePsychFramework = async (communityId, updates) => {
  try {
    const result = await axios.patch(
      `/v1/communities/${communityId}/psychFramework`,
      updates
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMemberStats = async (communityId, leaderId) => {
  const result = await axios.get(
    `/v1/stats?${communityId ? `community=${communityId}` : ''}${
      leaderId ? `&leader=${leaderId}` : ''
    }`
  );
  return result.data;
};

export const getLeaderMembersWithStats = async (communityId, leaderId) => {
  const result = await axios.get(
    `/v1/stats/members?${communityId ? `community=${communityId}` : ''}${
      leaderId ? `&leader=${leaderId}` : ''
    }`
  );
  return result.data;
};

export const getOfficerMembersWithStats = async leaderId => {
  const result = await axios.get(`/v1/stats/members?leader=${leaderId}`);
  return result.data;
};
