import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '../Button/Button';
import { ReactComponent as MinusCircleIcon } from '../../static/images/icons/minus-circle-solid.svg';

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  icon: {
    marginRight: 10
  },
  objective: {
    fontSize: 14,
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    flex: 1
  },
  btn: {
    minHeight: '30px !important',
    height: 30,
    width: 90,
    fontSize: 11
  }
});

const TemplateObjectiveCard = ({ objectiveStatement, onClick, readOnly }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      onClick={readOnly ? null : onClick}
      role="presentation"
    >
      <MinusCircleIcon className={classes.icon} />
      <Typography noWrap className={classes.objective}>
        {objectiveStatement}
      </Typography>
      {!readOnly && (
        <Button
          background="grey"
          label="Edit"
          onClick={onClick}
          className={classes.btn}
        />
      )}
    </Box>
  );
};

TemplateObjectiveCard.propTypes = {
  objectiveStatement: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool
};

TemplateObjectiveCard.defaultProps = {
  onClick: null,
  readOnly: false
};

export default TemplateObjectiveCard;
