import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { toBase64 } from '@/utils/helpers';
import * as missionActions from './actions';
import * as missionTypes from './types';
import * as missionTemplateTypes from '../templates/types';
import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

import { selectIsOfficer, selectLoggedInUserId } from '../auth/reducers';
import {
  selectSelectedObjective,
  selectAllOfficerMissions,
  selectMission,
  selectObjective,
  selectUserObjectives,
  selectSystemComments,
  selectAllUserMissions
} from './reducers';
import { selectSelectedCommunityId } from '../community/reducers';

import { consolidateArrayState, updateUserObjectives, updateOrAddMission } from '../../utils/stateHelpers';

// Get all missions for a user in the community (can be filtered by leader)
function* getAllUserCommunityMissions({ userId, communityId, role = 'communityAdmin' }) {
  try {
    // Check if the user/community mission data exists
    const currentMissions = yield select(selectAllUserMissions);
    const dataExists = currentMissions.some(m => m.userId === userId && m.communityId === communityId);

    if (!dataExists) {
      yield put({ type: missionTypes.MISSION_LOADING_STATE, state: true });
    }

    const {
      data: { results }
    } = yield call(missionActions.getAllMissions, userId, role, communityId);

    if (results.length > 0) {
      const newMissions = { userId, communityId, missions: results };
      const userMissions = consolidateArrayState(newMissions, currentMissions);

      yield put({
        type: missionTypes.GET_ALL_COMMUNITY_MISSIONS_SUCCESS,
        userMissions
      });
    }

    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* searchAllMissionsInCommunity({ userId, role, communityId, status, term, page = 1 }) {
  try {
    const limit = 25;
    const skip = page - 1 > 0 ? (page - 1) * limit : 0;

    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: true });

    const {
      data: { results, total }
    } = yield call(missionActions.searchAllMissionsInCommunity, userId, role, communityId, status, term, limit, skip);

    yield put({
      type: missionTypes.GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY_SUCCESS,
      total,
      page,
      results,
      totalPages: Math.ceil(total / limit)
    });

    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

// Get all the missions of the specified user (officer view)
function* getAllMissions({ userId }) {
  try {
    // Check if the user/community mission data exists
    const currentMissions = yield select(selectAllOfficerMissions);
    const dataExists = currentMissions.some(m => m.userId === userId);

    if (!dataExists) {
      yield put({ type: missionTypes.MISSION_LOADING_STATE, state: true });
    }

    const {
      data: { results }
    } = yield call(missionActions.getAllMissions, userId, 'communityAdmin');

    if (results.length > 0) {
      const userMissions = { userId, missions: results };
      const officerMissions = consolidateArrayState(userMissions, currentMissions, 'missionId');

      yield put({
        type: missionTypes.GET_ALL_OFFICER_MISSIONS_SUCCESS,
        officerMissions
      });
    }

    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getMission({ userId, missionId }) {
  try {
    // Check if we already have mission in state
    const previousData = yield select(selectMission, missionId);
    if (previousData) {
      yield put({ type: missionTypes.SET_SELECTED_MISSION, mission: previousData });
    } else {
      yield put({ type: missionTypes.MISSION_LOADING_STATE, state: true });
    }

    const result = yield call(missionActions.getMission, userId, missionId);
    const { data } = result;

    const userMissionsInState = yield select(selectAllUserMissions);

    yield put({
      type: missionTypes.GET_MISSION_SUCCESS,
      mission: data,
      userMissions: updateOrAddMission(userMissionsInState, data, userId, data?.community?._id)
    });

    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getMissionObjectives({ userId, missionId, page = 1, term, status, orderBy, order }) {
  try {
    const limit = 25;
    const skip = page - 1 > 0 ? (page - 1) * limit : 0;

    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: true });

    const {
      data: { results, total }
    } = yield call(missionActions.getMissionObjectives, userId, missionId, term, status, limit, skip, orderBy, order);

    const missionObjectives = { missionId, objectives: results };
    const objectives = consolidateArrayState(missionObjectives, [], false, 'missionId');

    yield put({
      type: missionTypes.GET_MISSION_OBJECTIVES_SUCCESS,
      objectives,
      total,
      page,
      results,
      limit,
      totalPages: Math.ceil(total / limit)
    });

    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getObjective({ userId, missionId, objectiveId }) {
  try {
    const previousData = yield select(selectObjective, missionId, objectiveId);
    if (previousData) {
      yield put({
        type: missionTypes.SET_SELECTED_OBJECTIVE,
        objective: previousData
      });
    } else {
      yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: true });
    }

    const { data } = yield call(missionActions.getObjective, userId, missionId, objectiveId);

    yield put({ type: missionTypes.GET_OBJECTIVE_SUCCESS, objective: data });

    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getObjectiveTasks({ userId, missionId, objectiveId }) {
  try {
    yield put({ type: missionTypes.TASK_LOADING_STATE, state: true });

    const result = yield call(missionActions.getTasks, userId, missionId, objectiveId);
    const {
      data: { results }
    } = result;

    yield put({ type: missionTypes.GET_OBJECTIVE_TASKS_SUCCESS, tasks: results });

    yield put({ type: missionTypes.TASK_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.TASK_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* postComment({ userId, missionId, objectiveId, comment }) {
  const createdOn = new Date().toISOString();

  try {
    yield put({ type: missionTypes.COMMENT_SAVING_STATE, state: true });

    const objective = yield select(selectSelectedObjective);
    const leaderId = yield select(selectLoggedInUserId);

    // Add comment to stack before success - remove on fail
    yield put({
      type: missionTypes.POST_COMMENT_SUCCESS,
      objective: {
        ...objective,
        comments: [...objective.comments, { comment, createdBy: leaderId, createdOn }]
      }
    });

    yield call(missionActions.postComment, userId, missionId, objectiveId, comment, leaderId);

    yield put({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS,
      userId,
      objectiveId
    });

    yield put({ type: missionTypes.COMMENT_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.COMMENT_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    // Remove the comment from the stack
    const objective = yield select(selectSelectedObjective);
    yield put({
      type: missionTypes.POST_COMMENT_ERROR,
      objective: {
        ...objective,
        comments: objective.comments.filter(c => c.createdOn !== createdOn)
      }
    });
  }
}

function* getAllObjectives({ userId }) {
  try {
    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: true });
    const result = yield call(missionActions.getAllObjectives, userId);
    const {
      data: { results }
    } = result;
    yield put({
      type: missionTypes.GET_ALL_OBJECTIVES_SUCCESS,
      objectives: results
    });

    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* getUserObjectives({ userId, leaderReq }) {
  try {
    const currentObjectives = yield select(selectUserObjectives);
    const dataExists = currentObjectives.some(o => o.userId === userId);

    if (!dataExists) {
      yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: true });
    }

    const leaderId = yield select(state => state.auth.userId);

    const {
      data: { results }
    } = yield call(missionActions.getUserObjectives, userId, leaderReq ? leaderId : null);

    const computedObjectives = updateUserObjectives(currentObjectives, userId, results);

    yield put({
      type: missionTypes.GET_USER_OBJECTIVES_SUCCESS,
      userObjectives: computedObjectives
    });

    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.OBJECTIVE_LOADING_STATE, state: false });
  }
}

function* getMissionTimeline({ missionId }) {
  try {
    yield put({ type: missionTypes.MISSION_TIMELINE_LOADING_STATE, state: true });

    const result = yield call(missionActions.getMissionTimeline, missionId);
    const {
      data: { lifeEvents, objectiveActivities, taskActivities }
    } = result;

    const unsorted = [...lifeEvents, ...objectiveActivities, ...taskActivities];

    // slice() to create a copy of the array before sorting
    const sorted = unsorted.slice().sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

    yield put({
      type: missionTypes.GET_MISSION_TIMELINE_SUCCESS,
      missionTimeline: sorted
    });

    yield put({ type: missionTypes.MISSION_TIMELINE_LOADING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.MISSION_TIMELINE_LOADING_STATE, state: false });
  }
}

function* updateMissionStatus({ userId, missionId, status, reflection = '' }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });

    const { data } = yield call(missionActions.updateMissionStatus, userId, missionId, status, reflection);

    const markedAsComplete = status === 'complete';

    if (markedAsComplete) {
      yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
    }

    const userMissionsInState = yield select(selectAllUserMissions);

    yield put({
      type: missionTypes.UPDATE_MISSION_STATUS_SUCCESS,
      mission: data,
      userMissions: updateOrAddMission(userMissionsInState, data, userId, data?.community?._id)
    });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission status updated successfully'
    });

    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  }
}

function* createMission({ userId, mission }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });

    const communityId = yield select(selectSelectedCommunityId);
    const { data } = yield call(missionActions.createNewMission, userId, mission, communityId);

    // Redirect to view mission page
    yield put(push(`/c/${communityId}/u/${userId}/m/${data.id}`));

    const userMissionsInState = yield select(selectAllUserMissions);

    yield put({
      type: missionTypes.CREATE_MISSION_SUCCESS,
      mission: data,
      userMissions: updateOrAddMission(userMissionsInState, data, userId, data?.community?._id)
    });

    const { imageFile } = mission
    if (imageFile !== null && imageFile !== undefined) {
      const formData = new FormData();
      formData.append('photo', imageFile);
      yield call(missionActions.uploadProfileImage, mission.id, formData);

      const image = yield call(toBase64, imageFile);

      yield put({ type: missionTypes.STORE_PROFILE_IMAGE, missionId: mission.id, image });
    }

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission created successfully'
    });

    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  }
}

function* archiveMission({ userId, missionId }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });

    // No data returned
    yield call(missionActions.archiveMission, userId, missionId);

    yield put({ type: missionTypes.ARCHIVE_MISSION_SUCCESS, missionId, userId });

    // Redirect to leader members
    const communityId = yield select(selectSelectedCommunityId);
    const isOfficer = yield select(selectIsOfficer);
    yield put(push(`/c/${communityId}/members/${isOfficer ? 'officer' : 'leader'}`));

    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission archived successfully'
    });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateObjectiveStatus({ userId, objectiveId, status }) {
  try {
    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: true });

    const { data } = yield call(missionActions.updateObjectiveStatus, userId, objectiveId, status);

    yield put({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS,
      userId,
      objectiveId
    });

    yield put({
      type: missionTypes.UPDATE_OBJECTIVE_STATUS_SUCCESS,
      objective: data
    });

    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: false });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective status updated successfully'
    });
  } catch (error) {
    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateTaskStatus({ userId, missionId, objectiveId, taskId, isComplete }) {
  try {
    yield put({ type: missionTypes.UPDATE_TASKS_CURRENTLY_SAVING, taskId });

    const { data } = yield call(missionActions.markTaskAsComplete, userId, missionId, objectiveId, taskId, isComplete);

    yield put({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS,
      userId,
      objectiveId
    });

    yield put({
      type: missionTypes.UPDATE_TASK_STATUS_SUCCESS,
      task: data
    });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Task successfully updated'
    });

    yield put({ type: missionTypes.UPDATE_TASKS_CURRENTLY_SAVING, taskId });
  } catch (error) {
    yield put({ type: missionTypes.UPDATE_TASKS_CURRENTLY_SAVING, taskId });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* deleteTask({ userId, missionId, objectiveId, taskId }) {
  try {
    // No data returned on successful post
    yield call(missionActions.deleteTask, userId, missionId, objectiveId, taskId);

    yield put({ type: missionTypes.DELETE_TASK_SUCCESS, objectiveId, taskId });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Action deleted successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* archiveObjective({ communityId, userId, missionId, objectiveId }) {
  try {
    // No data returned on successful post
    yield call(missionActions.archiveObjective, userId, objectiveId);

    // Redirect to mission page
    yield put(push(`/c/${communityId}/u/${userId}/m/${missionId}`));

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective successfully archived'
    });

    yield put({
      type: missionTypes.ARCHIVE_OBJECTIVE_SUCCESS,
      objectiveId,
      missionId
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateObjective({ userId, objectiveId, updates }) {
  try {
    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: true });
    const { data } = yield call(missionActions.updateObjective, userId, objectiveId, updates);

    yield put({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS,
      userId,
      objectiveId
    });

    yield put({
      type: missionTypes.UPDATE_OBJECTIVE_SUCCESS,
      objective: data
    });

    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: false });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective updated successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.OBJECTIVE_SAVING_STATE, state: false });
  }
}

function* getObjectiveSystemComments({ userId, objectiveId }) {
  try {
    const existingState = yield select(selectSystemComments);
    const dataExists = existingState.some(e => e.objectiveId === objectiveId);

    if (!dataExists) {
      yield put({
        type: missionTypes.OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
        state: true
      });
    }

    const { data } = yield call(missionActions.getObjectiveSystemComments, userId, objectiveId);

    const systemComments = data.filter(d => d.systemComment);

    const newElement = { objectiveId, comments: systemComments };

    yield put({
      type: missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS_SUCCESS,
      systemComments: consolidateArrayState(newElement, existingState, false, 'objectiveId')
    });

    yield put({
      type: missionTypes.OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
      state: false
    });
  } catch (error) {
    yield put({
      type: missionTypes.OBJECTIVE_SYSTEM_COMMENTS_LOADING_STATE,
      state: false
    });
  }
}

function* updateMission({ userId, missionId, updates }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });

    const { data } = yield call(missionActions.updateMission, userId, missionId, updates);

    const userMissionsInState = yield select(selectAllUserMissions);

    yield put({
      type: missionTypes.UPDATE_MISSION_SUCCESS,
      mission: data,
      userMissions: updateOrAddMission(userMissionsInState, data, userId, data?.community?._id)
    });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Mission updated successfully'
    });

    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  }
}

function* createNewObjective({ userId, objective, mission }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });
    const { data } = yield call(missionActions.createObjective, userId, objective);
    yield put({
      type: missionTypes.CREATE_OBJECTIVE_SUCCESS,
      objective: data,
      mission
    });
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Objective successfully created'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  }
}

function* createObjectivePractice({
  userId, title, description, richDescription, countPerWeek, reminderFrequency, objective
}) {
  try {
    yield put({ type: missionTypes.OBJECTIVE_PRACTICE_LOADING_STATE, state: true });

    const { data } = yield call(
      missionActions.createObjectivePractice,
      userId,
      title,
      description,
      richDescription,
      countPerWeek,
      reminderFrequency,
      objective
    );
    yield put({ type: missionTypes.CREATE_OBJECTIVE_PRACTICE_SUCCESS, practice: data });
    yield put({ type: snackTypes.SUCCESS_SNACK, content: 'Practice created'});
  } catch (error) {
    yield put({ type: modalTypes.ERROR_MODAL, error });
  } finally {
    yield put({ type: missionTypes.OBJECTIVE_PRACTICE_LOADING_STATE, state: false });
  }
}

function* updateObjectivePractice({
  userId, practiceId, title, description, richDescription, countPerWeek, reminderFrequency
}) {
  try {
    yield put({ type: missionTypes.OBJECTIVE_PRACTICE_LOADING_STATE, state: true });

    const { data } = yield call(
      missionActions.updateObjectivePractice,
      userId,
      practiceId,
      title,
      description,
      richDescription,
      countPerWeek,
      reminderFrequency
    );

    yield put({
      type: missionTypes.UPDATE_OBJECTIVE_PRACTICE_SUCCESS,
      practice: data
    });
    yield put({ type: snackTypes.SUCCESS_SNACK, content: 'Practice successfully updated'});
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  } finally {
    yield put({ type: missionTypes.OBJECTIVE_PRACTICE_LOADING_STATE, state: false });
  }
}

function* deleteObjectivePractice({ userId, practiceId }) {
  try {
    yield call(missionActions.deleteObjectivePractice, userId, practiceId);

    yield put({ type: missionTypes.DELETE_OBJECTIVE_PRACTICE_SUCCESS, practiceId });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Practice deleted successfully'
    });
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

// Tasks

function* createTask({ userId, missionId, objectiveId, task }) {
  try {
    const { data } = yield call(missionActions.createTask, userId, missionId, objectiveId, task);
    yield put({ type: missionTypes.CREATE_TASK_SUCCESS, task: data });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Action created'
    });
  } catch (error) {
    yield put({ type: missionTypes.CREATE_TASK_ERROR, error, task });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* updateTask({ userId, missionId, objectiveId, task }) {
  try {
    const { data } = yield call(missionActions.updateTask, userId, missionId, objectiveId, task);
    yield put({ type: missionTypes.UPDATE_TASK_SUCCESS, task: data });
    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Action updated'
    });
  } catch (error) {
    yield put({ type: missionTypes.UPDATE_TASK_ERROR, error, task });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}


function* archiveMissionByTemplate({ templateId, missionId }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });

    // No data returned
    yield call(missionActions.archiveMissionByTempate, missionId);

    yield put({ type: missionTemplateTypes.GET_MISSION_BY_TEMPLATE, templateId });


    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });

    yield put({
      type: snackTypes.SUCCESS_SNACK,
      content: 'Leader archived successfully'
    });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
  }
}

function* fetchMissionStats({userId, communityId}) {
  try {
    const state = yield call(missionActions.fetchMissionStats, userId, communityId)
    yield put({ type: missionTypes.GET_MISSIONS_STATS_SUCCESS, state, userId });
  } catch(error) {
    yield put({ type: missionTypes.GET_MISSIONS_STATS_ERROR, state: error });
  }
};

function* updateProfileImage({ missionId, imageFile }) {
  try {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: true });
    // Check the keys exist in the payload (they wont from the profile screen)

    if (imageFile !== null && imageFile !== undefined) {
      const formData = new FormData();
      formData.append('photo', imageFile);
      yield call(missionActions.uploadProfileImage, missionId, formData);

      const image = yield call(toBase64, imageFile);

      yield put({ type: missionTypes.STORE_PROFILE_IMAGE, missionId, image });
    }

    // yield put({ type: missionTypes.UPDATE_OWN_PROFILE_SUCCESS });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Mission image has been updated',
      open: true,
      props: { variant: 'success' }
    });

    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
  } catch (error) {
    yield put({ type: missionTypes.MISSION_SAVING_STATE, state: false });
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    // yield put({
    //   type: authTypes.UPDATE_OWN_PROFILE_ERROR,
    //   errors: {
    //     key: authTypes.UPDATE_OWN_PROFILE_ERROR,
    //     message: error.response.data.message,
    //     errors: error.response.data.errors
    //   }
    // });
  }
}


export default [
  takeLatest(missionTypes.GET_ALL_COMMUNITY_MISSIONS, getAllUserCommunityMissions),
  takeLatest(missionTypes.GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY, searchAllMissionsInCommunity),
  takeLatest(missionTypes.GET_MISSION, getMission),
  takeLatest(missionTypes.GET_MISSION_OBJECTIVES, getMissionObjectives),
  takeLatest(missionTypes.GET_OBJECTIVE, getObjective),
  takeLatest(missionTypes.GET_OBJECTIVE_TASKS, getObjectiveTasks),
  takeLatest(missionTypes.POST_COMMENT, postComment),
  takeLatest(missionTypes.GET_ALL_OBJECTIVES, getAllObjectives),
  takeLatest(missionTypes.GET_USER_OBJECTIVES, getUserObjectives),
  takeLatest(missionTypes.GET_MISSION_TIMELINE, getMissionTimeline),
  takeLatest(missionTypes.UPDATE_MISSION_STATUS, updateMissionStatus),
  takeLatest(missionTypes.CREATE_MISSION, createMission),
  takeLatest(missionTypes.ARCHIVE_MISSION, archiveMission),
  takeLatest(missionTypes.UPDATE_OBJECTIVE_STATUS, updateObjectiveStatus),
  takeLatest(missionTypes.UPDATE_TASK_STATUS, updateTaskStatus),
  takeLatest(missionTypes.DELETE_TASK, deleteTask),
  takeLatest(missionTypes.ARCHIVE_OBJECTIVE, archiveObjective),
  takeLatest(missionTypes.UPDATE_OBJECTIVE, updateObjective),
  takeLatest(missionTypes.GET_OBJECTIVE_SYSTEM_COMMENTS, getObjectiveSystemComments),
  takeLatest(missionTypes.UPDATE_MISSION, updateMission),
  takeLatest(missionTypes.CREATE_OBJECTIVE, createNewObjective),
  // takeLatest(missionTypes.GET_ALL_LEADER_MISSIONS, getAllLeaderMissions),
  takeLatest(missionTypes.GET_ALL_OFFICER_MISSIONS, getAllMissions),
  takeLatest(missionTypes.CREATE_OBJECTIVE_PRACTICE, createObjectivePractice),
  takeLatest(missionTypes.UPDATE_OBJECTIVE_PRACTICE, updateObjectivePractice),
  takeLatest(missionTypes.DELETE_OBJECTIVE_PRACTICE, deleteObjectivePractice),

  takeLatest(missionTypes.ARCHIVE_MISSION_BY_TEMPLATE, archiveMissionByTemplate),

  // Tasks
  takeLatest(missionTypes.CREATE_TASK, createTask),
  takeLatest(missionTypes.UPDATE_TASK, updateTask),

  // stats
  takeEvery(missionTypes.GET_MISSIONS_STATS_REQUEST, fetchMissionStats),
  takeLatest(missionTypes.UPDATE_PROFILE_IMAGE, updateProfileImage)
];
