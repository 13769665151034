import React from 'react';

import { ReactComponent as InactiveIcon } from '../static/images/icons/minus-circle-solid.svg';
import { ReactComponent as InProgressIcon } from '../static/images/icons/icon-progress-circle.svg';
import { ReactComponent as CompletedIcon } from '../static/images/icons/icon-check-green.svg';
import { ReactComponent as WarningIcon } from '../static/images/icons/icon-warning-exclamation.svg';
import { ReactComponent as NoLongerRelevantIcon } from '../static/images/icons/icon-error-eye.svg';

export default {
  inactive: <InactiveIcon />,
  inprogress: <InProgressIcon />,
  complete: <CompletedIcon />,
  needhelp: <WarningIcon />,
  nolongerrelevant: <NoLongerRelevantIcon />,
  taskComplete: <CompletedIcon />
};
