import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';

import {
  selectPsychFrameworkSaving,
  selectPsychFrameworkSaveKey
} from '../../state/community/reducers';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: 5
  },
  description: {
    maxWidth: 480,
    fontSize: 14,
    marginBottom: 10
  },
  paper: {
    borderRadius: 10
  },
  button: {
    width: 150
  },
  valueWrap: {
    padding: '20px 20px 36px 20px'
  },
  borderBottom: {
    borderBottom: '1px solid #c4c4c4'
  },
  inputMargin: {
    marginBottom: 10
  },
  input: {
    flex: 1
  },
  fakeDropdown: {
    width: 70,
    background: '#f4f4f4',
    borderRadius: 10,
    height: 38,
    marginTop: 16,
    marginLeft: 20
  },
  dropdown: {
    maxWidth: 70,
    marginLeft: 20
  },
  iconButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  icon: {
    height: 16,
    width: 16
  }
});

export default function ValueBuilder({
  comValues,
  onSubmit,
  readOnly,
  description
}) {
  // Hooks
  const classes = useStyles();

  // Initial objects/constants
  const initialObject = {
    value: '',
    rating: 10,
    standards: [
      {
        value: '',
        rating: 10
      }
    ]
  };
  const dropdownOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // Local state
  const [values, setValues] = useState(
    cloneDeep(comValues) || [{ ...initialObject }]
  );
  const [emptyValues, setEmptyValues] = useState([]);
  const [initialValues, setInitialValues] = useState(
    cloneDeep(comValues) || [{ ...initialObject }]
  );

  // Redux
  const loading = useSelector(state => selectPsychFrameworkSaving(state));
  const loadingKey = useSelector(state => selectPsychFrameworkSaveKey(state));

  // Event handlers
  function validateValues(valuesToValidate) {
    const clone = [...valuesToValidate];
    clone.pop();

    const empty = [];

    clone.forEach((v, valIdx) => {
      let standards = 0;

      v.standards.forEach(s => {
        if (s.value.trim().length > 0) standards += 1;
      });

      if (!v.value.trim().length > 0 && standards > 0) empty.push(valIdx);
    });

    setEmptyValues(empty);

    return empty;
  }

  function handleValueChange(valIdx, e) {
    const { value } = e.target;
    const updated = [...values];

    updated[valIdx] = {
      ...updated[valIdx],
      value
    };

    const atFinalIndex = valIdx === values.length - 1;

    if (atFinalIndex) {
      setValues([...updated, initialObject]);
    } else setValues(updated);
  }

  function handleStandardChange(valIdx, stdIdx, e) {
    const { value } = e.target;
    const clone = [...values];

    const updatedStandard = {
      ...clone[valIdx].standards[stdIdx],
      value
    };

    clone[valIdx].standards[stdIdx] = updatedStandard;

    const atFinalIndex = stdIdx === values[valIdx].standards.length - 1;
    if (atFinalIndex) {
      clone[valIdx].standards = [
        ...clone[valIdx].standards,
        { value: '', rating: 10 }
      ];
    }

    setValues(clone);
  }

  // Remove rating and hardedcoded 10 for now!
  function handleValueRating(valIdx, e) {
    const { value } = e.target;
    const updated = [...values];
    updated[valIdx].rating = value;
    setValues(updated);
  }

  // Remove rating and hardedcoded 10 for now!
  function handleStandardRating(valIdx, stdIdx, e) {
    const { value } = e.target;
    const updated = [...values];
    updated[valIdx].standards[stdIdx].rating = value;
    setValues(updated);
  }

  function handleSubmit() {
    const newEmptyValues = validateValues(values);

    if (!newEmptyValues.length > 0) {
      // Remove empty values
      const stripped = [...values]
        .map(v => {
          const updatedStandards = [...v.standards].filter(
            s => s.value.trim().length > 0
          );
          return { ...v, standards: updatedStandards };
        })
        .filter(v => v.value.trim().length > 0);

      if (emptyValues.length > 0) setEmptyValues([]);

      setInitialValues(cloneDeep(values));
      onSubmit(stripped);
    }
  }

  function removeValue(valIdx) {
    const updated = [...values].filter((val, idx) => idx !== valIdx);
    validateValues(updated);
    setValues(updated);
  }

  function removeStandard(valIdx, stdIdx) {
    const updatedStandards = [...values][valIdx].standards.filter(
      (std, idx) => idx !== stdIdx
    );
    let updated = [...values];
    updated[valIdx].standards = updatedStandards;

    // Check if all standards are done and if value is empty
    let noStandards = true;
    values[valIdx].standards.forEach(s => {
      if (s.value.trim().length > 0) noStandards = false;
    });

    const emptyValue = !values[valIdx].value.trim().length > 0;
    if (emptyValue && noStandards) {
      updated = [...updated].filter((val, idx) => idx !== valIdx);
      validateValues(updated);
    }

    setValues(updated);
  }

  // Helper constants
  const isLoading = loading && loadingKey === 'values';
  const updated = !isEqual(initialValues, values);

  return (
    <Box>
      <Typography className={classes.title}>Values & Standards</Typography>
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
      <Paper className={classes.paper}>
        {values.map(({ value, rating: vRating, standards }, valIdx) => {
          const atFinalValIndex = valIdx === values.length - 1;

          const valueWrapClass = clsx({
            [classes.valueWrap]: true,
            [classes.borderBottom]: !atFinalValIndex
          });

          const hasStandards = standards.some(s => s.value.trim().length > 0);
          const hasError = emptyValues.some(v => v === valIdx);

          return (
            <Box key={`value-${valIdx}`} className={valueWrapClass}>
              <Box display="flex">
                <TextInput
                  label={atFinalValIndex ? 'Write a new value...' : 'Value'}
                  value={value}
                  onChange={e => handleValueChange(valIdx, e)}
                  className={classes.inputMargin}
                  disabled={isLoading || readOnly}
                  errorMessage={
                    hasError ? 'You must enter a value if you have standards' : '' // Text input calculates error state by casting errorMessage to Boolean
                  }
                />
                {/* {atFinalValIndex ? (
                  <div className={classes.fakeDropdown} />
                ) : (
                  <Dropdown
                    options={dropdownOptions}
                    selected={vRating}
                    className={classes.dropdown}
                    shrink
                    centered
                    label="Rate this"
                    onChange={e => handleValueRating(valIdx, e)}
                    disabled={isLoading || readOnly}
                  />
                )} */}
                {!atFinalValIndex && (
                  <div className={classes.iconButtonWrap}>
                    <IconButton onClick={() => removeValue(valIdx)}>
                      <CloseRoundedIcon className={classes.icon} />
                    </IconButton>
                  </div>
                )}
              </Box>
              {(value.length > 0 || hasStandards) &&
                standards.map(({ value: sValue, rating: sRating }, stdIdx) => {
                  const atFinalStdIndex = stdIdx === standards.length - 1;
                  return (
                    <Box key={`value-${valIdx}-std-${stdIdx}`} paddingLeft={7.5}>
                      <Box display="flex">
                        <TextInput
                          label={
                            atFinalStdIndex ? 'Write a new standard' : 'Standard'
                          }
                          value={sValue}
                          onChange={e => handleStandardChange(valIdx, stdIdx, e)}
                          className={clsx({
                            [classes.inputMargin]: !atFinalStdIndex
                          })}
                          disabled={isLoading || readOnly}
                        />
                        {/* {atFinalStdIndex ? (
                          <div className={classes.fakeDropdown} />
                        ) : (
                          <Dropdown
                            options={dropdownOptions}
                            selected={sRating}
                            className={classes.dropdown}
                            shrink
                            centered
                            label="Rate this"
                            onChange={e => handleStandardRating(valIdx, stdIdx, e)}
                            disabled={isLoading || readOnly}
                          />
                        )} */}
                        {!atFinalStdIndex && (
                          <div className={classes.iconButtonWrap}>
                            <IconButton
                              onClick={() => removeStandard(valIdx, stdIdx)}
                            >
                              <CloseRoundedIcon className={classes.icon} />
                            </IconButton>
                          </div>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          );
        })}
      </Paper>
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
        marginTop={2.5}
        marginBottom={2.5}
      >
        {!readOnly && (
          <Button
            label="Save"
            onClick={handleSubmit}
            className={classes.button}
            disabled={loading || !updated}
            loading={isLoading}
          />
        )}
      </Box>
    </Box>
  );
}

ValueBuilder.propTypes = {
  comValues: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  description: PropTypes.string
};

ValueBuilder.defaultProps = {
  description: ''
};
