import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import ProfileImage from '../ProfileImage/ProfileImage';

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    margin: 5,
    padding: 8,
    top: 0,
    transition: 'all 0.1s ease',
    boxShadow: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    height: 27,
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontSize: 11,
    flex: 1
  },
  image: {
    marginRight: 5
  },
  iconButton: {
    height: 10,
    width: 10,
    padding: '0px !important',
    '& svg': {
      height: 10,
      width: 10
    }
  }
});

const SelectedUserSnack = ({ user, onRemove }) => {
  const classes = useStyles();

  const userName = `${user.firstName} ${user.lastName}`;
  return (
    <Paper className={classes.paper}>
      <ProfileImage
        userId={user.id}
        size={19}
        shadow={false}
        borderRadius={6}
        className={classes.image}
      />
      <Typography noWrap className={classes.name}>
        {userName}
      </Typography>
      <IconButton onClick={onRemove} className={classes.iconButton}>
        <CloseRoundedIcon />
      </IconButton>
    </Paper>
  );
};

SelectedUserSnack.propTypes = {
  user: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default SelectedUserSnack;
