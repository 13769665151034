import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import DrawerHeading from '../DrawerHeading/DrawerHeading';
import Button from '../Button/Button';
import ObjectivePracticeForm from '../ObjectivePractice/ObjectivePracticeForm';
import { validateObjectivePractice } from '../../constants/validation';
import { DANGER_SNACK } from '../../state/snack/types';

import * as communityMissionTypes from '../../state/communityMission/types';
import * as missionTypes from '../../state/mission/types';
import { selectObjectivePracticeLoading, selectObjectivePracticeSaved } from '../../state/mission/reducers';
import {
  selectCommunityMissionObjectivePracticeLoading,
  selectCommunityMissionObjectivePracticeSaved
} from '../../state/communityMission/reducers';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getStringDescFromSlate } from '@/utils/helpers';

const useStyles = makeStyles({
  drawer: {
    width: 720,
    marginLeft: 80
  },
  icon: {
    position: 'absolute'
  },
  button: {
    width: 150
  },
  delete: {
    marginRight: 10
  },
  infoWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 40px'
  }
});

const initialPractice = {
  title: '',
  description: '',
  countPerWeek: '',
  reminderFrequency: 'DAILY'
};

const ObjectivePracticeDrawer = ({
  userId,
  communityId,
  isCommunity,
  open,
  onClose,
  selectedObjective,
  selectedPractice
}) => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(null);
  const [practiceValues, setPracticeValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialPractice
  );

  const loading = useSelector(
    isCommunity ? selectCommunityMissionObjectivePracticeLoading : selectObjectivePracticeLoading
  );
  const saved = useSelector(isCommunity ? selectCommunityMissionObjectivePracticeSaved : selectObjectivePracticeSaved);

  // Helpers
  const showErrorSnack = () => {
    dispatch({
      type: DANGER_SNACK,
      content: 'Please check all of the required fields'
    });
  };

  // Event handlers
  const handleClose = () => {
    onClose();
    setPracticeValue(initialPractice);
  };

  const handlePracticeFormChange = e => {
    const { name, value } = e.target;
    setPracticeValue({ [name]: value });
  };

  const handleSubmit = () => {
    // Validate
    // submit null or '' reminder frequency as 'NEVER' give a reminder
    if(!practiceValues.reminderFrequency) practiceValues.reminderFrequency = null
    if(practiceValues.richDescription) 
      practiceValues.description = getStringDescFromSlate(practiceValues.richDescription)

    const errorMessages = validateObjectivePractice(practiceValues);
    if (!errorMessages) {
      setErrors(null);

      if (practiceValues.reminderFrequency === '') {
        delete practiceValues.reminderFrequency;
      }


      if (!selectedPractice) {
        if (isCommunity) {
          dispatch({
            type: communityMissionTypes.CREATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
            ...practiceValues,
            communityId,
            objective: selectedObjective.id
          });
        } else {
          dispatch({
            type: missionTypes.CREATE_OBJECTIVE_PRACTICE,
            ...practiceValues,
            userId,
            objective: selectedObjective.id
          });
        }
      } else if (isCommunity) {
        dispatch({
          type: communityMissionTypes.UPDATE_COMMUNITY_MISSION_OBJECTIVE_PRACTICE,
          ...practiceValues,
          communityId,
          practiceId: selectedPractice.id
        });
      } else {
        dispatch({
          type: missionTypes.UPDATE_OBJECTIVE_PRACTICE,
          ...practiceValues,
          userId,
          practiceId: selectedPractice.id
        });
      }
    } else {
      setErrors(errorMessages);
      showErrorSnack();
    }
  };

  // Constants
  const headerActions = (
    <Button
      className={classes.button}
      label={selectedPractice ? 'Save' : 'Create'}
      onClick={handleSubmit}
      loading={loading}
    />
  );

  useUpdateEffect(() => {
    if (saved) {
      handleClose();
    }
  }, [saved]);

  useEffect(() => {
    if (selectedPractice && open) {
      const { title, description, richDescription, countPerWeek, reminderFrequency } = selectedPractice;
      setPracticeValue({ title, description, richDescription, countPerWeek, reminderFrequency });
    }
  }, [selectedObjective, open]);

  return (
    <Drawer open={open} anchor="right" onClose={handleClose} PaperProps={{ classes: { root: classes.drawer } }}>
      <IconButton onClick={handleClose} className={classes.icon}>
        <CloseIcon />
      </IconButton>

      <DrawerHeading
        isActive
        heading={selectedPractice ? 'Edit Practice' : 'Create Practice'}
        actions={headerActions}
      />

      <ObjectivePracticeForm
        title={practiceValues.title}
        description={practiceValues.description}
        richDescription={practiceValues.richDescription}
        countPerWeek={practiceValues.countPerWeek}
        reminderFrequency={practiceValues.reminderFrequency}
        onChange={handlePracticeFormChange}
        errors={errors}
      />
    </Drawer>
  );
};

ObjectivePracticeDrawer.propTypes = {
  userId: PropTypes.string,
  communityId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isCommunity: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedObjective: PropTypes.object.isRequired,
  selectedPractice: PropTypes.object
};

ObjectivePracticeDrawer.defaultProps = {
  selectedPractice: null,
  userId: null,
  communityId: null,
  isCommunity: false
};

export default ObjectivePracticeDrawer;
