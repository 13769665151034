import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ProfileImage from '../ProfileImage/ProfileImage';
import PopUpMenu from '../PopUpMenu/PopUpMenu';

const styles = makeStyles({
  container: {
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 40,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  dateWrap: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 20,
    marginRight: 20,
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: 10,
      color: '#838b96'
    }
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 173,
    marginRight: 20
  },
  userName: {
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10
  },
  message: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    fontSize: 10,
    color: '#223f63',
    fontWeight: 600
  },
  entityTitle: {
    fontWeight: 400
  }
});

export default function LeaderActivityRowCard({
  createdOn,
  user,
  formattedString,
  formattedEntityTitle,
  menuActions,
  onClick
}) {
  // Hooks
  const classes = styles();

  // Helper constants
  const { firstName, lastName, _id } = user;
  const userName = `${firstName} ${lastName}`;

  return (
    <div className={classes.container} onClick={onClick} role="presentation">
      <div className={classes.dateWrap}>
        <div>
          <Typography>{moment(createdOn).format('hh:mma')}</Typography>
          <Typography className={classes.date}>
            {moment(createdOn).format('DD/MM/YYYY')}
          </Typography>
        </div>
      </div>
      <div className={classes.profileContainer}>
        <ProfileImage
          userId={_id}
          size={20}
          borderRadius={4}
          style={{ marginRight: 10 }}
        />
        <Typography className={classes.userName} noWrap>
          {userName}
        </Typography>
      </div>
      <Typography className={classes.message}>
        {`${formattedString.trim()}${formattedEntityTitle ? ':' : ''}`}
        {formattedEntityTitle && (
          <span className={classes.entityTitle}>
            &nbsp;{`"${formattedEntityTitle}"`}
          </span>
        )}
      </Typography>
      {menuActions && <PopUpMenu menuActions={menuActions} />}
    </div>
  );
}

LeaderActivityRowCard.propTypes = {
  menuActions: PropTypes.array,
  createdOn: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  formattedString: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  formattedEntityTitle: PropTypes.string
};

LeaderActivityRowCard.defaultProps = {
  menuActions: null,
  formattedEntityTitle: ''
};
