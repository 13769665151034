import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core';

import { getSlateValueFromTaskAndPractice, getStringDescFromSlate, initialSlateValue } from '@/utils/helpers';
import GenericDrawer from './DrawerGeneric';
import DrawerHeader from './DrawerHeader';

import Button from '../Button/Button';
import Input from '../TextInput/TextInput';
import Datepicker from '../Datepicker/Datepicker';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { selectSelectedMission, selectSelectedObjective } from '@/state/mission/reducers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  descriptionLabel: {
    fontFamily: 'SFUIText-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    color: '#838b96',
    paddingLeft: 12,
    fontSize: 10,
    marginBottom: 12,
  },
  richDescriptionContainer: {
    padding: '20px 20px 32px 20px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  overdueObjectiveMission: {
    fontSize: 10,
    color: 'red',
    marginTop: 16,
    marginBottom: 12
  }
}))

const ObjectiveTaskDrawer = ({ statement, task, open, onSave, onUpdate, ...props }) => {
  const classes = useStyles();
  const [taskValues, setTaskValues] = useState(task);
  const objective = useSelector(state => selectSelectedObjective(state));
  const mission = useSelector(state => selectSelectedMission(state));
  const isEditing = !!task._id;
  const handleChange = e => {
    const { name, value } = e.target;
    setTaskValues({
      ...taskValues,
      [name]: value
    });
  };

  const handleDueDateChange = date => {
    setTaskValues({
      ...taskValues,
      dueDate: date
    });
  };

  useEffect(() => {
    // Reset local tasks every time drawer is opened
    if (open) {
      setTaskValues(task);
    }
  }, [open]);

  const initialDescription = getSlateValueFromTaskAndPractice(taskValues.description, taskValues.richDescription)
  
  const isObjectiveOverdue = new Date(objective.statement.dueDate) < new Date()
  const isMissionOverdue = new Date(mission.endDate) < new Date()

  if (!taskValues.richDescription) taskValues.richDescription = initialDescription

  return (
    <GenericDrawer anchor="right" open={open} {...props}>
      <DrawerHeader
        title={isEditing ? 'Edit action' : 'New Action'}
        actions={[
          <Button
            style={{ minWidth: 120 }}
            label={isEditing ? 'Update action' : 'Create Action'}
            onClick={() => {
              if(taskValues.richDescription) taskValues.description = getStringDescFromSlate(taskValues.richDescription)
              if (isEditing) {
                onUpdate(taskValues);
              } else {
                onSave(taskValues);
              }
            }}
            disabled={
              taskValues.task === '' || 
              (
                !taskValues.richDescription ||
                JSON.stringify(taskValues.richDescription) === JSON.stringify(initialSlateValue())
              )
            }
          />
        ]}
      />

      <div className={classes.overdueObjectiveMission}>
        {
          isObjectiveOverdue || isMissionOverdue ? 
            'This task will be marked as overdue as its objective or mission is already overdue'
            : ''
        }
      </div>
      <Input label="Title" name="task" required value={taskValues.task} onChange={handleChange} />
      {/* <Input label="Description" name="description" value={taskValues.description} onChange={handleChange} /> */}
      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <MuiInputLabel
          shrink={false}
          label="Action Description"
          required
          classes={{
            root: classes.descriptionLabel,
          }}
        >
          Action Description
        </MuiInputLabel>
        <div className={classes.richDescriptionContainer}>
          <RichTextEditor
            defaultValue={initialDescription}
            name="content"
            RichTextEditor
            onlyLink
            minHeight={100}
            onChange={debounce(content => {
              handleChange({
                target: {
                  name: 'richDescription',
                  value: content
                }
              })
            }, 500)}
          />
        </div>
      </div>
      <Datepicker
        chevron
        label="Due date"
        name="dueDate"
        onChange={handleDueDateChange}
        value={taskValues.dueDate ? new Date(taskValues.dueDate) : null}
        whiteInput
      />
      {/* <ObjectiveDrawerTasks
        addTask={newTasks => setTasks([..._tasks, newTasks])}
        deleteTask={({ _id }) => setTasks(_tasks.filter(t => t._id !== _id))}
        tasks={_tasks}
        objectiveStartDate={moment(statement)}
        readOnly={false}
        objectiveStatement={statement}
        isTemplate={false}
      /> */}
    </GenericDrawer>
  );
};

ObjectiveTaskDrawer.propTypes = {
  statement: PropTypes.object.isRequired,
  task: PropTypes.object,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func
};

ObjectiveTaskDrawer.defaultProps = {
  open: false,
  task: {
    task: '',
    description: '',
    dueDate: null,
    _id: null
  },
  onSave: () => {},
  onUpdate: () => {}
};

export default ObjectiveTaskDrawer;
