import * as types from './types';

const initialState = {
  objectivePracticeReport: [],
  objectivePracticeReportLoading: false,
  teamsAccountabilityScores: [],
  teamsAccountabilityScoresLoading: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OBJECTIVE_PRACTICE_REPORTS_LOADING_STATE:
      return {
        ...state,
        objectivePracticeReportLoading: action.state
      };
    case types.GET_OBJECTIVE_PRACTICE_REPORTS_SUCCESS:
      return {
        ...state,
        objectivePracticeReport: action.results
      };

    case types.TEAMS_ACCOUNTABILITY_SCORE_LOADING_STATE:
      return {
        ...state,
        teamsAccountabilityScoresLoading: action.state
      };
    case types.GET_TEAMS_ACCOUNTABILITY_SCORE_SUCCESS:
      return {
        ...state,
        teamsAccountabilityScores: action.teamAccountabilityScores
      };
    default:
      return state;
  }
};

export default userReducer;

export const selectObjectivePracticeReportLoading = state => state.reports.objectivePracticeReportLoading;
export const selectObjectivePracticeReport = state => state.reports.objectivePracticeReport;

export const selectTeamsAccountabilityScoresLoading = state => state.reports.teamsAccountabilityScoresLoading;
export const selectTeamsAccountabilityScores = state => state.reports.teamsAccountabilityScores;
