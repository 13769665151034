export const MODAL_SET_COMPONENT = 'modals/MODAL_SET_COMPONENT';
export const MODAL_SET_OPEN_STATE = 'modals/MODAL_SET_OPEN_STATE';
export const ERROR_MODAL = 'modals/ERROR_MODAL';

export const API_ERROR_MODAL = 'API_ERROR_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const MISSION_REFLECTION_MODAL = 'MISSION_REFLECTION_MODAL';
export const TEMPLATE_ASSIGNMENT_ERRORS_MODAL = 'TEMPLATE_ASSIGNMENT_ERRORS_MODAL';
export const TOOLKIT_CATEGORIES_MODAL = 'TOOLKIT_CATEGORIES_MODAL';
export const PREVIEW_TOOLKIT_MODAL = 'PREVIEW_TOOLKIT_MODAL';
export const ADD_HYPERLINK = 'ADD_HYPERLINK';
