/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const getObjectivePracticeReports = async (objectiveId, startDate, endDate) => {
  const filters = [
    `objectiveUuid=${objectiveId}`,
    !!startDate && `startDate=${startDate}`,
    !!endDate && `endDate=${endDate}`
  ].filter(Boolean);

  const { data } = await axios.get(`/v1/reports/practice-records/weekly-count?${filters.join('&')}`);
  return data;
};

export const getTeamsAccountabilityScore = async (communityId, startDate, endDate) => {
  const filters = [!!startDate && `startDate=${startDate}`, !!endDate && `endDate=${endDate}`].filter(Boolean);

  let query = '';

  if (filters.length) {
    query = `?${filters.join('&')}`;
  }

  const { data } = await axios.get(`/v1/reports/communities/${communityId}/teams/accountability-score${query}`);
  return data;
};
