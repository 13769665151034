import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import DialogPaper from './DialogPaper';

import {
  selectModalState,
  selectModalComponent,
  selectModalProps
} from '../../state/modal/reducers';
import * as modalTypes from '../../state/modal/types';
import { getModalFromType } from './ModalLookup';

const useStyles = makeStyles({
  dialogRoot: {
    zIndex: '1400 !important'
  },
  paper: {
    padding: 20,
    '@media (min-width: 768px)': {
      minWidth: 450,
      padding: 40
    },
    minHeight: 200,
    backgroundColor: '#ffffff',
    borderRadius: 40,
    marginTop: 10
  },
  scrollPaper: {
    // paddingTop: 116,
    alignItems: 'start',
    justifyContent: 'center'
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '40px'
  }
});

const getModal = (component, componentProps, ref) => {
  return React.cloneElement(getModalFromType(component), {
    ...componentProps,
    fwdRef: ref
  });
};

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const Modal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(state => selectModalState(state));
  const component = useSelector(state => selectModalComponent(state));
  const modalProps = useSelector(state => selectModalProps(state));

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (prevOpen === true && !open) {
      dispatch({ type: modalTypes.MODAL_SET_COMPONENT, component: null });
    }
  }, [open]);

  const handleClose = () =>
    dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });

  const RefModal = React.forwardRef((_props, ref) => {
    if (component) {
      return getModal(component, _props, ref);
    }

    return <div />;
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={DialogPaper}
      classes={{
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper,
        paper: classes.paper
      }}
      className={classes.modalContainer}
      maxWidth="md"
    >
      <RefModal {...modalProps} />
    </Dialog>
  );
};

Modal.propTypes = {};

export default Modal;
