import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';

import * as userTypes from '../../state/users/types';
import { selectProfileImages } from '../../state/users/reducers';

import { getUserProfileImage } from '../../state/users/actions';

import usePrevious from '../../hooks/usePrevious';

export default function ProfileImageAvatar({ userId, ...props }) {
  const dispatch = useDispatch();
  const [src, setSrc] = useState('');

  // Redux
  const profileImages = useSelector(state => selectProfileImages(state));
  const userImage = profileImages.find(i => i.userId === userId);
  const prevImage = usePrevious(userImage);

  // Event handlers
  function storeInRedux(image) {
    dispatch({
      type: userTypes.STORE_PROFILE_IMAGE,
      userId,
      image
    });
  }

  // Get the profile given users profile image
  useEffect(() => {
    let isSubscribed = true;

    if (!userImage) {
      getUserProfileImage(userId)
        .then(val => {
          if (isSubscribed) {
            if (val) {
              const reader = new FileReader();

              reader.onload = event => {
                const { result } = event.target;
                setSrc(result);
                storeInRedux(result);
              };

              reader.readAsDataURL(val);
            }
          }
        })
        .catch(() => null); // Disabling error log until a fallback image is returned. Clogs up console.
    } else setSrc(userImage.image);

    return () => {
      isSubscribed = false;
    };
  }, [userId]);

  // Update if updated in redux
  useEffect(() => {
    if (userImage) {
      if (prevImage && prevImage.image !== userImage.image) setSrc(userImage.image);
    }
  }, [prevImage, userImage]);

  return <Avatar {...props} style={{ marginLeft: 5, height: 40, width: 40 }} src={src} alt="User image" />;
}

ProfileImageAvatar.propTypes = {
  userId: PropTypes.string.isRequired
};
