import PropTypes from 'prop-types'
import React from 'react'

const Sensor = ({className}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 170.08 170.08"
      className={className}
      width="250"
      height="250"
    >
      <defs>
        <style>{'.sensor-cls-1{fill:none;}.sensor-cls-2{opacityxs:0.23;}.sensor-cls-3{clip - path:url(#clip-path);}'}</style>
        <clipPath id="clip-path">
          <rect className="sensor-cls-1" x="8.23" y="28.35" width="153.62" height="113.39" />
        </clipPath>
      </defs>
      <g className="sensor-cls-2">
        <rect x="48.46" y="53.95" width="3.66" height="3.66" />
        <rect x="33.83" y="53.95" width="3.66" height="3.66" />
        <g className="sensor-cls-3">
          <path
            d="M43,64.92a3.66,3.66,0,0,1-3.66-3.65H35.66a7.32,7.32,0,0,0,14.63,0H46.63A3.66,3.66,0,0,1,43,64.92"
          />
        </g>
        <rect x="30.18" y="83.21" width="3.66" height="3.66" />
        <rect x="33.83" y="90.53" width="3.66" height="3.66" />
        <rect x="42.98" y="94.18" width="3.66" height="3.66" />
        <rect x="50.29" y="90.53" width="3.66" height="3.66" />
        <g className="sensor-cls-3">
          <path
            d="M77.72,77.72A14.67,14.67,0,0,0,70.25,65a14.76,14.76,0,0,0,2-7.37,29.26,29.26,0,0,0-58.52,0,14.65,14.65,0,0,0,2,7.37,14.59,14.59,0,0,0-4.94,21A14.59,14.59,0,0,0,18.2,108a5.47,5.47,0,0,0,4.66,2.62,5.55,5.55,0,0,0,1.83-.33v11.06l5.49-3.65v24h25.6v-24l5.49,3.65V110.31A5.39,5.39,0,0,0,67.75,108,14.59,14.59,0,0,0,75.18,86a14.57,14.57,0,0,0,2.54-8.23M22.86,107A1.82,1.82,0,0,1,21,105.16V86.57A10.61,10.61,0,0,1,24.16,79c.17-.17.35-.34.53-.49v26.64A1.82,1.82,0,0,1,22.86,107m12.9-31.9a18.18,18.18,0,0,0,5.39.82h3.66a18.06,18.06,0,0,0,5.38-.82,7.28,7.28,0,0,1-14.43,0m9.05-2.84H41.15A14.65,14.65,0,0,1,26.52,57.61V50.24a34.78,34.78,0,0,0,16.09-4.91l.37-.22.36.22a34.79,34.79,0,0,0,16.1,4.91v7.37A14.66,14.66,0,0,1,44.81,72.24M32.05,74.92a11,11,0,0,0,6.21,9l.44.36A20,20,0,0,0,51.16,88.7h2a4.42,4.42,0,0,1,4.42,4.41v6.56H28.35V76.4Zm1.78,63.15V117.72l3.66,2.44,3.66-2.44v20.35Zm11,0V117.72l3.65,2.44,3.66-2.44v20.35Zm12.8-23.53L54,112.1l-5.49,3.66L43,112.1l-5.49,3.66L32,112.1l-3.65,2.44V103.33H57.61v11.21Zm7.31-9.38a1.83,1.83,0,0,1-3.65,0v-12A8.07,8.07,0,0,0,53.19,85h-2a16.27,16.27,0,0,1-4.5-.65,11,11,0,0,0,7.25-9.47l4.29,1.72a10.7,10.7,0,0,1,6.72,9.93Zm6.57-18a10.91,10.91,0,0,1-2.91,16.51V86.57a14.27,14.27,0,0,0-4.2-10.15,14.51,14.51,0,0,0-4.82-3.18L56.19,71.9a18.26,18.26,0,0,0,6.9-14.29v-11H61.27a31.17,31.17,0,0,1-16.05-4.44L43,40.84l-2.25,1.35a31.13,31.13,0,0,1-16,4.44H22.86v11a18.26,18.26,0,0,0,6.9,14.29L26.4,73.24a14.37,14.37,0,0,0-9,13.33v17.07a10.92,10.92,0,0,1-2.91-16.51l1-1.18-1-1.17a11,11,0,0,1,4.62-17.34l2.41-.89-1.63-2a11,11,0,0,1-2.49-7,25.61,25.61,0,1,1,51.21,0,10.94,10.94,0,0,1-2.49,7l-1.62,2,2.41.89a10.95,10.95,0,0,1,4.61,17.34L70.5,86Z"
          />
        </g>
        <rect x="139.9" y="55.78" width="3.66" height="3.66" />
        <rect x="125.27" y="55.78" width="3.66" height="3.66" />
        <rect x="130.76" y="68.58" width="7.31" height="3.66" />
        <g className="sensor-cls-3">
          <path
            d="M155,76l-7.15-2.39a18.09,18.09,0,0,0,4-6.93,7.24,7.24,0,0,0,5.3-10.85A10.84,10.84,0,0,0,160,48.46a11,11,0,0,0-7.39-10.36,11,11,0,0,0-10.9-9.75,10.82,10.82,0,0,0-7.3,2.83A10.95,10.95,0,0,0,116.2,38.1a11,11,0,0,0-7.39,10.36,10.85,10.85,0,0,0,2.84,7.34,7.22,7.22,0,0,0,5.29,10.85,18.2,18.2,0,0,0,2.88,5.59H101.5V59.1A5.49,5.49,0,0,0,105.16,54v-6l-5.49-8.23-5.49,8.23v6a5.49,5.49,0,0,0,3.66,5.15V72.24a7.32,7.32,0,0,0,0,14.63v54.86h3.66V86.87h14.63V123.2l3.66-2.43v21h29.26v-21l3.66,2.43V112.47h1.82a7.32,7.32,0,0,0,7.32-7.31V85.41A9.94,9.94,0,0,0,155,76M97.84,49l1.83-2.75L101.5,49V54a1.83,1.83,0,0,1-3.66,0ZM134.42,75.9A14.6,14.6,0,0,1,124,71.53l.88-1.75a5.44,5.44,0,0,1,4.9-3H139a5.47,5.47,0,0,1,4.91,3l.87,1.75a14.56,14.56,0,0,1-10.4,4.37m18.22-13.28c0-.45.07-.9.07-1.36v-5a3.63,3.63,0,0,1-.07,6.33M119.79,41.15a7.26,7.26,0,0,1,5.85,2.92l2.92-2.2A10.88,10.88,0,0,0,120,37.51a7.3,7.3,0,0,1,14.38,1.81h3.66a10.84,10.84,0,0,0-1.35-5.25,7.17,7.17,0,0,1,5-2.07,7.34,7.34,0,0,1,7.1,5.51,10.82,10.82,0,0,0-6.37,2.18l2.2,2.92a7.22,7.22,0,0,1,4.39-1.46,7.32,7.32,0,0,1,5.58,12,7.39,7.39,0,0,0-1.92-.81V45.05l-7.32,4.87-5.49-3.66-5.48,3.66-5.49-3.66-5.49,3.66-7.31-4.87v7.33a7.11,7.11,0,0,0-1.92.81,7.22,7.22,0,0,1-1.74-4.73,7.32,7.32,0,0,1,7.32-7.31M114.3,59.44a3.64,3.64,0,0,1,1.83-3.15v5c0,.46,0,.91.07,1.36a3.63,3.63,0,0,1-1.9-3.18m5.49-7.56,3.65,2.44,5.49-3.66,5.49,3.66,5.48-3.66,5.49,3.66,3.66-2.44v9.38a14.6,14.6,0,0,1-1.78,7l-.05-.1A9.09,9.09,0,0,0,139,63.09h-9.25a9.09,9.09,0,0,0-8.17,5.06l-.05.1a14.49,14.49,0,0,1-1.78-7ZM94.18,79.55a3.67,3.67,0,0,1,3.66-3.65v7.31a3.67,3.67,0,0,1-3.66-3.66m29.26,38.78,5.49,3.65,3.66-2.43v18.52h-9.15Zm21.95,19.74h-9.14V119.55L139.9,122l5.49-3.65Zm3.66-21.7-3.66-2.44-5.49,3.66-5.48-3.66-5.49,3.66-5.49-3.66-3.65,2.44V105.16h29.26Zm9.14-11.21a3.66,3.66,0,0,1-3.66,3.65h-1.82V85h-3.66V101.5H119.79V83.21H101.5V75.9h21.94v0a18.19,18.19,0,0,0,21.26.51l9.19,3.07a6.27,6.27,0,0,1,4.3,6Z"
          />
        </g>
        <rect x="125.27" y="88.7" width="3.66" height="3.66" />
        <rect x="139.9" y="88.7" width="3.66" height="3.66" />
      </g>
    </svg>
  )
}

Sensor.propTypes = {
  className: PropTypes.string
}

Sensor.defaultProps = {
  className: ''
}

export default Sensor

