import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
// cspell:disable-next-line
import PhoneNumber from 'awesome-phonenumber';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProfileImageUpload from '../components/ProfileImageUpload/ProfileImageUpload';
import UserProfileForm from '../components/UserProfileForm/UserProfileForm';
import UserAccountSettingsForm from '../components/UserAccountSettingsForm/UserAccountSettingsForm';
import PageHeader from '../components/PageHeader/PageHeader';

import { selectLoggedInUser, selectAuthApiErrors } from '../state/auth/reducers';
import {
  selectUserLoadingState,
  selectSelectedUser,
  selectUserEmailChange,
  selectEmailChangeLoading
} from '../state/users/reducers';

import * as userTypes from '../state/users/types';
import * as authTypes from '../state/auth/types';

import { parseApiErrors } from '../constants/validation';

const styles = {
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mb40: {
    marginBottom: 40
  },
  title: {
    fontFamily: 'SFUIDisplay-SemiBold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 10
  }
};

class UserProfile extends Component {
  constructor(props) {
    super(props);

    // Check if user is viewing their own profile
    const { loggedInUser, getUser, getEmailChange } = this.props;
    const routeId = this.getUserIdFromRoute(props);
    const isOwnProfile = loggedInUser._id === routeId;

    getUser(routeId);
    getEmailChange(routeId);

    this.state = {
      readOnly: !isOwnProfile,
      firstName: '',
      lastName: '',
      birthday: null,
      phoneNumber: '',
      biography: '',
      password: '',
      passwordConfirm: '',
      oldPassword: '',
      imageFile: null
    };
  }

  componentDidUpdate(prevProps) {
    const { loadingState: prevLoading } = prevProps;
    const { loadingState, selectedUser } = this.props;
    if (prevLoading && !loadingState && selectedUser !== null) {
      this.initializeStateFromRedux();
    }
  }

  initializeStateFromRedux = () => {
    const { selectedUser } = this.props;
    this.setState({
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      biography: selectedUser.biography || '',
      phoneNumber: selectedUser.phoneNumber ? selectedUser.phoneNumber : '',
      birthday: selectedUser.birthday ? moment(selectedUser.birthday) : null
    });
  };

  getUserIdFromRoute = props => {
    const {
      match: { params }
    } = props;
    const { userId } = params;
    return userId;
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleDateChange = date => {
    this.setState({
      birthday: date
    });
  };

  handlePhoneChange = e => {
    const { value } = e.target;
    const parsedNumber = new PhoneNumber(value, 'AU');
    if (parsedNumber && parsedNumber.isValid()) {
      this.setState({
        phoneNumber: parsedNumber.getNumber()
      });
    } else this.setState({ phoneNumber: value });
  };

  onProfileSave = () => {
    // Validation
    const { updateUser, selectedUser } = this.props;
    const { readOnly, email, password, passwordConfirm, birthday, imageFile, ...rest } = this.state;
    const { _id: userId } = selectedUser;

    let updates = { ...rest };
    if (imageFile) updates = { ...updates, imageFile };
    if (birthday) updates = { ...updates, birthday: birthday.format() };

    updateUser(userId, updates);
  };

  onEmailSave = email => {
    const { requestEmailChange } = this.props;
    requestEmailChange(email);
  };

  onEmailResend = () => {
    const { selectedUserEmailChange, resendEmailChange } = this.props;
    const { _id: requestId } = selectedUserEmailChange;
    resendEmailChange(requestId);
  };

  onEmailCancel = () => {
    const { selectedUserEmailChange, cancelEmailChange } = this.props;
    const { _id: requestId } = selectedUserEmailChange;
    cancelEmailChange(requestId);
  };

  onPasswordSave = () => {
    const { changeOwnPassword } = this.props;
    const { password, passwordConfirm, oldPassword } = this.state;
    changeOwnPassword(oldPassword, password, passwordConfirm);
  };

  handleImageChange = file => {
    this.setState({ imageFile: file });
  };

  render() {
    const { classes, loadingState, emailLoading, apiErrors } = this.props;
    const {
      readOnly,
      firstName,
      lastName,
      birthday,
      phoneNumber,
      biography,
      email,
      password,
      passwordConfirm,
      oldPassword
    } = this.state;

    const computedErrors = parseApiErrors(apiErrors, authTypes.UPDATE_OWN_PROFILE_ERROR);

    const userId = this.getUserIdFromRoute(this.props);

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader routeTitle="Member profile" showBack />
        </Grid>

        <Grid item xs={12} className={clsx(classes.flex, classes.mb40)}>
          <ProfileImageUpload onImageLoad={this.handleImageChange} userId={userId} />
        </Grid>
        <Grid item xs={12} className={classes.mb40}>
          <UserProfileForm
            readOnly={readOnly}
            loading={loadingState}
            firstName={firstName}
            lastName={lastName}
            phone={phoneNumber}
            biography={biography}
            birthday={birthday}
            onChange={this.handleChange}
            onDateChange={this.handleDateChange}
            onPhoneChange={this.handlePhoneChange}
            onSave={this.onProfileSave}
            errors={computedErrors}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>Account settings</Typography>
            </Grid>
            <Grid item xs={12}>
              <UserAccountSettingsForm
                loading={loadingState || emailLoading}
                email={email}
                onChange={this.handleChange}
                onEmailSave={this.onEmailSave}
                onEmailCancel={this.onEmailCancel}
                onEmailResend={this.onEmailResend}
                password={password}
                passwordConfirm={passwordConfirm}
                oldPassword={oldPassword}
                onPasswordSave={this.onPasswordSave}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  loadingState: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  selectedUser: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  apiErrors: PropTypes.object,
  selectedUserEmailChange: PropTypes.object,
  getEmailChange: PropTypes.func.isRequired,
  emailLoading: PropTypes.bool.isRequired,
  requestEmailChange: PropTypes.func.isRequired,
  resendEmailChange: PropTypes.func.isRequired,
  cancelEmailChange: PropTypes.func.isRequired,
  changeOwnPassword: PropTypes.func.isRequired
};

UserProfile.defaultProps = {
  selectedUser: null,
  selectedUserEmailChange: null,
  apiErrors: []
};

const mapStateToProps = state => ({
  loadingState: selectUserLoadingState(state),
  loggedInUser: selectLoggedInUser(state),
  selectedUser: selectSelectedUser(state),
  selectedUserEmailChange: selectUserEmailChange(state),
  apiErrors: selectAuthApiErrors(state),
  emailLoading: selectEmailChangeLoading(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: userId => ({
        type: userTypes.GET_USER,
        userId
      }),
      updateUser: (userId, updates) => ({
        type: authTypes.UPDATE_OWN_PROFILE,
        userId,
        updates
      }),
      getEmailChange: () => ({
        type: authTypes.GET_OWN_EMAIL_CHANGE_REQUEST
      }),
      requestEmailChange: email => ({
        type: authTypes.REQUEST_CHANGE_OWN_EMAIL,
        email
      }),
      resendEmailChange: requestId => ({
        type: userTypes.RESEND_EMAIL_CHANGE,
        requestId
      }),
      cancelEmailChange: requestId => ({
        type: authTypes.CANCEL_OWN_EMAIL_CHANGE,
        requestId
      }),
      changeOwnPassword: (oldPassword, password, passwordConfirm) => ({
        type: authTypes.CHANGE_OWN_PASSWORD,
        oldPassword,
        password,
        passwordConfirm
      })
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfile)));
