import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    color: '#838b96',
    fontSize: 12,
    fontFamily: 'SFUIText-Bold, Roboto, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase'
  }
}));

const DrawerHeader = ({ title, actions }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>{typeof title === 'string' ? <Typography className={classes.title}>{title}</Typography> : { title }}</Box>
      <Box>
        <Grid container spacing={1}>
          {actions.map((a, i) => (
            <Grid key={`${i}-action`} item>
              {a}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

DrawerHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actions: PropTypes.arrayOf(PropTypes.node)
};

DrawerHeader.defaultProps = {
  actions: []
};

export default DrawerHeader;
