import PropTypes from 'prop-types'
import React, {useCallback, useMemo} from 'react'
import Dropzone from 'react-dropzone'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import { useDispatch } from 'react-redux'
import {Editable, withReact, useSlate, Slate, useSlateStatic, ReactEditor, useSelected, useFocused} from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Range,
} from 'slate'
import { Tooltip } from '@material-ui/core'
import { withHistory } from 'slate-history'

import {css} from '@emotion/css';
import { Button, Icon, Toolbar } from './components'
import * as modalTypes from '../../state/modal/types';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

/** LINK HELPERS */
const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url, text) => {
  const activeLink = isLinkActive(editor)
  if (!!activeLink) {
    // unwrapLink(editor)
    Transforms.removeNodes(editor, { at: activeLink[1] })
  }
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = [
    {
      type: 'link',
      url,
      children: isCollapsed ? [{ text }] : [],
    },
    { text: ' '}
  ]

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const insertLink = (editor, url, text, option) => {
  if (option) {
    Transforms.select(editor, option.at)
    wrapLink(editor, url, text)
  }
}

const withInlines = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element =>
    ['link', 'button'].includes(element.type) || isInline(element)

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      files.map(file => {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      })
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  if(format === 'numbered-list') newProperties.isNumbered = true
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const numberedListNumberStyling = css(`
  counter-reset: my-awesome-counter;
  list-style: none;
  clear: both;

  li {
    counter-increment: my-awesome-counter;
    position: relative;
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #252525;
    margin-left: 8px;
    font-weight: 600;
    color: #D4AF83;
    text-align: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    font-size: 14px;
    padding: 18px 14px;

    &::before {
      content: counter(my-awesome-counter) ".";
      font-family: "SFUIText-Reg", "Roboto", "Helvetica", "Arial", "sans-serif";
      background-color: #D4AF83;
      font-size: 30px;
      color: #000;
      font-weight: bold;
      width: 60px;
      height: 100%;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -16%;
      top: -0.01em;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`)

const bulletedListBulletStyling = css(`
  list-style: none;
  clear: both;

  li {
    position: relative;
    display: block;
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: transparent;
    font-weight: 600;
    color: #FFF;
    justify-content: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    font-size: 14px;

    &::before {
      content: "•";
      font-family: "SFUIText-Reg", "Roboto", "Helvetica", "Arial", "sans-serif";
      background-color: transparent;
      font-size: 24px;
      color: #D4AF83;
      font-weight: bold;
      width: 60px;
      height: fit-content;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -12%;
      top: -0.35em;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`)


const Element = (props) => {
  const { attributes, children, element } = props
  let style = {textAlign: element.align,}

  const blockQuoteStyle = {
    marginLeft: '0rem',
    color: '#FED180',
    fontWeight: 'bold',
    fontSize: 28,
    flex: 1,
    flexGrow: 1,
    fontStyle: 'italic',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 0,
    // textAlign: 'center',
    alignSelf: 'center',
    paddingHorizontal: 40,
    borderWidth: 1,
    borderLeftWidth: 1,
  }
  let listClass = {}
  switch (element.type) {
    case 'block-quote':

      if (props.readOnly) {
        style = {...style, ...blockQuoteStyle}
      }

      return (
        <q style={style} {...attributes}>
          <i>{children}</i>
        </q>
      )
    case 'bulleted-list':
      if (props.readOnly) listClass = bulletedListBulletStyling
      return (
        <ul style={style} {...attributes} className={listClass}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      if (props?.readOnly) listClass = numberedListNumberStyling
      return (
        <ol style={style} {...attributes} className={listClass}>
          {children}
        </ol>
      )
    case 'image':
      return <Image {...props} />
    case 'link':
      return (
        <Tooltip
          title={props.element?.url}
          arrow
          placement="top"
        >
          <a {...attributes} href={props.element?.url}>{children}</a>
        </Tooltip>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code 
      style={{
        color: '#172b4d',
        backgroundColor: '#f4f5f7',
        padding: 4,
        borderRadius: 4
      }}
    >
      {children}
    </code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon, onClick }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false

  return true
}

const insertImage = (editor, url) => {
  const text = { text: '' }
  const image = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
}

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
        `}
      >
        <img
          alt="toolkit-exp"
          src={element.url}
          className={css`
            display: block;
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
          `}
        />
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={css`
            display: ${selected && focused ? 'inline' : 'none'};
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
          `}
        >
          <Icon>delete</Icon>
        </Button>
      </div>
    </div>
  )
}

const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link'
  });
};

const InsertHyperlinkButton = () => {
  const editor = useSlate()
  const dispatch = useDispatch()

  return (
    <Button
      onClick={(event) => {
        event.preventDefault()
        const location = editor.selection?.focus || null
        let editUrl = ''
        let editText = ''
        const activeLinkNode = isLinkActive(editor)
        if(!!activeLinkNode) {
          editUrl = activeLinkNode[0].url
          editText = activeLinkNode[0].children[0].text
        }
        /* const elementSelected = editor.children[location.path[0]]
        let parent
        const children = location.path[1]
        if(children !== 0) {
          parent = elementSelected.children[children]
        } */
        const previousSelection = editor.selection
        /* const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url, url, {}) */
        dispatch({
          type: modalTypes.MODAL_SET_COMPONENT,
          component: modalTypes.ADD_HYPERLINK,
          props: {
            editUrl,
            editText,
            onSave: (link, text) => {
              const option = previousSelection ? {
                at: location,
                select: true
              }: null
              insertLink(editor, link, text, option)
            }
          }
        });
        dispatch({ type: modalTypes.MODAL_SET_OPEN_STATE, state: true });
      }}
    >
      <Icon>add_link</Icon>
    </Button>
  )
}

const InsertImageUrlButton = () => {
  const editor = useSlateStatic()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()

        const url = window.prompt('Enter the URL of the image:')

        if (url && !isImageUrl(url)) {
          alert('URL is not an image')
          return
        }

        if (url && isImageUrl(url)) {
          insertImage(editor, url)
        }
      }}
    >
      <Icon>attachment</Icon>
    </Button>
  )
}

const UploadImageButton = () => {
  const editor = useSlateStatic()

  const onDropImage = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        const url = reader.result
        insertImage(editor, url)
      })

      reader.readAsDataURL(file)
    });
  };

  return (
    <Dropzone
      onDrop={onDropImage}
      style={{ height: '100%', borderWidth: 0 }}
      accept="image/jpeg, image/png"
    >
      {({getRootProps, getInputProps}) =>
        (
          <div {...getRootProps()}>
            <Button>
              <Icon>image</Icon>
            </Button>
          </div>
        )}
    </Dropzone>
  )
}

const RemoveHyperlinkButton = () => {
  const editor = useSlate()

  return (
    <Button
      active={!!isLinkActive(editor)}
      onMouseDown={() => {
        if (!!isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <Icon>link_off</Icon>
    </Button>
  )
}

const RichTextEditor = ({onChange, defaultValue, readOnly, minHeight, onlyLink}) => {
  const renderElement = useCallback(props => <Element readOnly={readOnly} {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withInlines(withImages(withHistory(withReact(createEditor())))), [])

  return (
    <Slate
      editor={editor}
      value={defaultValue || initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          op => op.type !== 'set_selection'
        )

        if (isAstChange) {
          // const content = JSON.stringify(value)
          onChange(value)
        }
      }}
    >
      {!readOnly && (
        <Toolbar>
          {
            onlyLink ? '': (
              <>
                <MarkButton format="bold" icon="format_bold" />
                <MarkButton format="italic" icon="format_italic" />
                <MarkButton format="underline" icon="format_underlined" />
                <MarkButton format="code" icon="code" />
                <BlockButton format="heading-one" icon="looks_one" />
                <BlockButton format="heading-two" icon="looks_two" />
                <BlockButton format="block-quote" icon="format_quote" />
                <BlockButton format="numbered-list" icon="format_list_numbered" />
                <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                <BlockButton format="left" icon="format_align_left" />
                <BlockButton format="center" icon="format_align_center" />
                <BlockButton format="right" icon="format_align_right" />
                <BlockButton format="justify" icon="format_align_justify" />
              </>
            )
          }
          <InsertHyperlinkButton />
          <RemoveHyperlinkButton />
          {
            onlyLink ? '': (
              <>
                <InsertImageUrlButton />
                <UploadImageButton />
              </>
            )
          }
        </Toolbar>
      )}
      <div style={{minHeight: minHeight || 500}}>
        <Editable
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          onKeyDown={event => {
            Object.keys(HOTKEYS).map((hotkey) => {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
              return hotkey
            })
          }}
        />
      </div>
    </Slate>
  )
}


export default RichTextEditor

RichTextEditor.propTypes = {
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onlyLink: PropTypes.bool,
  minHeight: PropTypes.number,
}

RichTextEditor.defaultProps = {
  onChange: () => {},
  defaultValue: undefined,
  readOnly: false,
  onlyLink: false,
  minHeight: 500,
}

Element.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.any,
  readOnly: PropTypes.bool,
}

Element.defaultProps = {
  attributes: {},
  children: {},
  element: {},
  readOnly: false,
}

Leaf.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.any,
  leaf: PropTypes.any,
}

Leaf.defaultProps = {
  attributes: {},
  children: {},
  element: {},
  leaf: {},
}