import { call, put, takeLatest, select } from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as toolkitActions from './actions';
import * as toolkitTypes from './types';

import * as snackTypes from '../snack/types';
import * as modalTypes from '../modal/types';
import {selectUserToolkits} from './reducers';

function* createToolkit({ payload, communityId }) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: true });

    const toolkit = yield call(toolkitActions.createToolkit, payload);

    yield put({ type: toolkitTypes.CREATE_TOOLKIT_SUCCESS,toolkit });

    yield put({ type: toolkitTypes.GET_TOOLKITS});

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit successfully created.',
      open: true,
      props: { variant: 'success' }
    });

    yield put(
      push(
        `/c/${communityId}/toolkits`
      )
    );
  } catch (error) {
    // Display the error message modal
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });
    yield put({
      type: toolkitTypes.CREATE_TOOLKIT_ERROR,
      errors: {
        key: toolkitTypes.CREATE_TOOLKIT_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: false });
  }
}

function* getToolkits() {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: true });

    const results = yield call(toolkitActions.getToolkits);

    yield put({ type: toolkitTypes.GET_TOOLKITS_SUCCESS, toolkits: results });
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    yield put({
      type: toolkitTypes.GET_TOOLKITS_ERROR,
      errors: {
        key: toolkitTypes.GET_TOOLKITS_ERROR,
        message: err.response?.data?.message,
        errors: err.response?.data?.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: false });
  }
}

function* getUserToolkits({ showProgress = false, userId }) {
  try {
    if (showProgress) {
      yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: true });
    }

    const results = yield call(toolkitActions.getUserToolkits, userId);

    yield put({ type: toolkitTypes.GET_USER_TOOLKITS_SUCCESS, userToolkits: results });
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    yield put({
      type: toolkitTypes.GET_USER_TOOLKITS_ERROR,
      errors: {
        key: toolkitTypes.GET_USER_TOOLKITS_ERROR,
        message: err.response?.data?.message,
        errors: err.response?.data?.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: false });
  }
}

function* assignToolkits({ toolkits, users, showSnack = true}) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: true });

    yield call(toolkitActions.assignToolkits, toolkits, users);

    yield put({ type: toolkitTypes.ASSIGN_TOOLKITS_SUCCESS });

    if (showSnack) {
      yield put({
        type: snackTypes.SET_SNACK,
        content: 'Toolkits successfully assigned to users.',
        open: true,
        props: { variant: 'success' }
      });
    }
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    yield put({
      type: toolkitTypes.ASSIGN_TOOLKITS_ERROR,
      errors: {
        key: toolkitTypes.ASSIGN_TOOLKITS_ERROR,
        message: err.response?.data?.message,
        errors: err.response?.data?.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: false });
  }
}

function* removeUserFromToolkit({ toolkitId, userId}) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: true });

    const updatedToolkit = yield call(toolkitActions.removeToolkitFromUser, toolkitId, userId);

    const userToolkits = yield select(selectUserToolkits);

    yield put({
      type: toolkitTypes.REMOVE_TOOLKIT_FROM_USER_SUCCESS,
      userToolkits: userToolkits.filter(ut => ut.id !== updatedToolkit.id)
    });

    yield put({
      type: snackTypes.SET_SNACK,
      content: 'User successfully removed from toolkit.',
      open: true,
      props: { variant: 'success' }
    });
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    yield put({
      type: toolkitTypes.REMOVE_TOOLKIT_FROM_USER_ERROR,
      errors: {
        key: toolkitTypes.REMOVE_TOOLKIT_FROM_USER_ERROR,
        message: err.response?.data?.message,
        errors: err.response?.data?.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: false });
  }
}

function* getToolkit({ toolkitId }) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: true });

    const result = yield call(toolkitActions.getToolkit, toolkitId);

    yield put({ type: toolkitTypes.GET_TOOLKIT_SUCCESS, toolkit: result });
  } catch (err) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      err
    });
    yield put({
      type: toolkitTypes.GET_TOOLKIT_ERROR,
      errors: {
        key: toolkitTypes.GET_TOOLKIT_ERROR,
        message: err.response?.data?.message,
        errors: err.response?.data?.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_LOADING_STATE, state: false });
  }
}

function* editToolkit({ payload, communityId, toolkitId }) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: true });

    yield call(toolkitActions.editToolkit, toolkitId, payload);

    yield put({ type: toolkitTypes.EDIT_TOOLKIT_SUCCESS });

    yield put({ type: toolkitTypes.GET_TOOLKITS});

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit successfully updated.',
      open: true,
      props: { variant: 'success' }
    });

    yield put(
      push(
        `/c/${communityId}/toolkits`
      )
    );
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: toolkitTypes.CREATE_TOOLKIT_ERROR,
      errors: {
        key: toolkitTypes.CREATE_TOOLKIT_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: false });
  }
}

function* deleteToolkit({ toolkitId, communityId }) {
  try {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: true });

    yield call(toolkitActions.deleteToolkit, toolkitId);

    yield put({ type: toolkitTypes.DELETE_TOOLKIT_SUCCESS });

    yield put({ type: toolkitTypes.GET_TOOLKITS});

    // Display success snack
    yield put({
      type: snackTypes.SET_SNACK,
      content: 'Toolkit successfully deleted.',
      open: true,
      props: { variant: 'success' }
    });

    yield put(
      push(
        `/c/${communityId}/toolkits`
      )
    );
  } catch (error) {
    yield put({
      type: modalTypes.ERROR_MODAL,
      error
    });

    yield put({
      type: toolkitTypes.EDIT_TOOLKIT_ERROR,
      errors: {
        key: toolkitTypes.EDIT_TOOLKIT_ERROR,
        message: error.response.data.message,
        errors: error.response.data.errors
      }
    });
  } finally {
    yield put({ type: toolkitTypes.TOOLKIT_SAVING_STATE, state: false });
  }
}

export default [
  takeLatest(toolkitTypes.CREATE_TOOLKIT, createToolkit),
  takeLatest(toolkitTypes.GET_TOOLKITS, getToolkits),
  takeLatest(toolkitTypes.GET_USER_TOOLKITS, getUserToolkits),
  takeLatest(toolkitTypes.ASSIGN_TOOLKITS, assignToolkits),
  takeLatest(toolkitTypes.REMOVE_TOOLKIT_FROM_USER, removeUserFromToolkit),
  takeLatest(toolkitTypes.GET_TOOLKIT, getToolkit),
  takeLatest(toolkitTypes.EDIT_TOOLKIT, editToolkit),
  takeLatest(toolkitTypes.DELETE_TOOLKIT, deleteToolkit),
]