import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    minHeight: 60,
    marginBottom: 10,
    display: 'flex'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '2px solid #dadada',
    paddingRight: 10
  },
  statusCircle: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    backgroundColor: '#adb6c1'
  },
  date: {
    color: '#838b96',
    fontFamily: 'SFUIDisplay-Bold, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12
  },
  title: {
    fontSize: 14,
    color: '#223f63',
    fontFamily: 'SFUIDisplay-Light, Roboto, Helvetica, Arial, sans-serif'
  },
  comment: {
    fontSize: '0.625rem'
  }
}));

const ObjectiveCommentCard = ({ createdOn, title, comment }) => {
  // Hooks
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.dateContainer}>
        <Typography className={classes.date}>
          {moment(createdOn).format('D/MM/YY')}
        </Typography>
      </Box>
      <Box paddingLeft={2.5} display="flex" flex={1} alignItems="center">
        <Box flex={1} paddingRight={1}>
          {title && <Typography className={classes.title}>{title}</Typography>}
          <Typography className={classes.comment}>{comment}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

ObjectiveCommentCard.propTypes = {
  createdOn: PropTypes.string.isRequired,
  title: PropTypes.string,
  comment: PropTypes.string.isRequired
};

ObjectiveCommentCard.defaultProps = {
  title: null
};

export default ObjectiveCommentCard;
