import React, { useEffect } from 'react';

import { useSelector /* , useDispatch */ } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import ChatWindow from './ChatWindow';

// import * as chatTypes from '../../state/chat/types';

import { selectOpenChats, selectAllLeaderUsers, selectChatLoading } from '../../state/chat/reducers';
// import { selectLoggedInUserId } from '../../state/auth/reducers';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    right: 200,
    width: 'calc(100% - 400px)',
    height: 40,
    display: 'flex',
    flexDirection: 'row-reverse'
  }
});

export default function ChatBar() {
  // Hooks
  const classes = useStyles();


  // Redux
  const openChats = useSelector(state => selectOpenChats(state));
  const leaderUsers = useSelector(state => selectAllLeaderUsers(state));
  const loading = useSelector(state => selectChatLoading(state))

  // Filter open chats to the selected community
  const computedChats = openChats.filter(c =>
    leaderUsers.some(u => u._id === c._id)
  );

  return (
    <div className={classes.root}>
      {!loading &&
        computedChats.map(user => <ChatWindow key={user._id} user={user} />)}
    </div>
  );
}
