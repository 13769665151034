/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '../Button/Button';

import SearchInput from '../SearchInput/SearchInput';
import Dropdown from '../Dropdown/Dropdown';
import * as missionTypes from '../../state/mission/types';
import { selectMissionLoading, selectSearchMissionsInCommunity } from '../../state/mission/reducers';
import { selectIsOfficer, selectLoggedInUserId } from '../../state/auth/reducers';
import MissionOverviewAccordion from '../Mission/MissionOverviewAccordion';

const useStyles = makeStyles(theme => ({
  mb10: {
    marginBottom: 10
  },
  searchInput: {
    height: '100%'
  },
  tabs: {}
}));

const MemberMissions = ({ isOfficerView, userId, communityId, role, selectedMember }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [tabFilter, setTabFilter] = useState('active');
  const [missionFilterStatus, setMissionFilterstatus] = useState('');
  const [search, setSearch] = useState('');
  const [term, setTerm] = useState(null);

  const missions = useSelector(selectSearchMissionsInCommunity);
  const missionLoading = useSelector(selectMissionLoading);
  const loggedInUser = useSelector(selectLoggedInUserId);
  const isLoggedInUserOfficer = useSelector(selectIsOfficer);

  const delayedTerm = useCallback(
    debounce(q => setTerm(q), 500),
    []
  );

  const fetchData = () => {
    let status = null;

    if (tabFilter === 'active') {
      status = 'in progress';
    } else if (missionFilterStatus !== '') {
      status = missionFilterStatus;
    }

    dispatch({
      type: missionTypes.GET_SEARCH_ALL_MISSIONS_IN_COMMUNITY,
      userId,
      communityId,
      role,
      status,
      page,
      term
    });
  };

  const handleCreateMission = () => {
    dispatch({ type: missionTypes.CLEAR_SELECTED_MISSION });
    history.push(`/c/${communityId}/u/${userId}/create-mission`);
  };

  const handleMissionClick = mission => {
    if (mission.isCommunity) {
      history.push(`/c/${communityId}/m/${mission.id}`);
    } else {
      history.push(`/c/${communityId}/u/${userId}/m/${mission.id}`);
    }
  };

  const handleObjectiveClick = rowData => {
    const mission = missions.data.filter(m => m.id === rowData.row.mission).pop();
    if (mission.isCommunity) {
      history.push(`/c/${communityId}/m/${rowData.row.mission}/o/${rowData.id}`);
    } else {
      history.push(`/c/${communityId}/u/${userId}/m/${rowData.row.mission}/o/${rowData.id}`);
    }
  };

  // Check if the auth user is a leader of the selected user (check for community match if not officer)
  const officerCanCreateMission = selectedMember?.leaders?.some(e => e.leaders.some(l => {
    let id
    if (typeof l === 'string') id = l
    if (typeof l === 'object') id = l._id
    return id === loggedInUser
  }));
  const leaderCanCreateMission = selectedMember?.leaders?.some(
    e => e.communityId === communityId && e.leaders.some(l => {
      let id
      if (typeof l === 'string') id = l
      if (typeof l === 'object') id = l._id
      return id === loggedInUser
    })
  );
  let computedCanCreate = isOfficerView ? officerCanCreateMission : leaderCanCreateMission;
  if (computedCanCreate === undefined) {
    computedCanCreate = false;
  }

  const createMissionData = () => {
    // Three potential views - officer member stats, leader member stats, officer viewing all community members
    // const allMissions = missions.data.filter(mission => mission.leader !== null);
    const onlyLeaderMissions = missions.data;

    // Check if the auth user is an officer
    // const leaderViewMissions = isLoggedInUserOfficer ? allMissions : onlyLeaderMissions;

    // Check if the view is the officer member stats
    const filteredMissions = onlyLeaderMissions;

    const updatedMissions = filteredMissions.map(mission => ({
      title: mission.title,
      icon: mission.icon,
      leader: mission.leader,
      createdAt: mission.createdOn,
      endsAt: mission.endDate,
      type: 'mission',
      text: mission.title,
      user: mission.user,
      id: mission._id,
      status: mission.status,
      hasObjectives: mission.objectives.length > 0,
      countCompletedObjectives: mission.objectives.filter(objective => objective.status === 'complete').length,
      totalObjectives: mission.objectives.length,
      objectives: mission.objectives.filter(objetive => {
        if (tabFilter === 'active') {
          return objetive.status === 'in progress';
        }

        return true;
      }),
      isComplete: mission.isComplete || mission.status === 'complete', // TODO: Fix isComplete in API
      isCommunity: mission.isCommunity || false
    }));

    return updatedMissions;
  };

  const onTabChange = (event, newValue) => {
    setTabFilter(newValue);
  };

  const onPageChange = (event, value) => {
    setPage(value);
  };

  const renderFilters = () => {
    const missionStatus = [
      {
        label: 'complete',
        value: 'complete'
      },
      {
        label: 'in progress',
        value: 'in progress'
      }
    ];

    return (
      <Grid container justify="space-between" spacing={1} className={classes.mb10}>
        <Grid item xs={12} lg={5}>
          <SearchInput
            value={search}
            onChange={e => {
              setSearch(e);
              delayedTerm(e);
            }}
            showFocus={false}
            className={classes.searchInput}
            placeholder="Search objective and mission titles"
          />
        </Grid>

        <Grid item xs={12} lg={5} style={{ display: 'flex' }}>
          <Dropdown
            showNoneOption
            options={missionStatus}
            selected={missionFilterStatus}
            onChange={e => {
              setMissionFilterstatus(e.target.value);
            }}
            placeholder="Filter missions by"
          />
        </Grid>
      </Grid>
    );
  };

  const missionData = createMissionData();

  const renderMissions = () => {
    if (missionData.length > 0) {
      return (
        <>
          {missionData.map(mission => (
            <MissionOverviewAccordion
              loggedInUser={loggedInUser}
              key={mission.id}
              mission={mission}
              defaultExpanded={tabFilter === 'active'}
              canCreateObjective={computedCanCreate}
              onMissionClick={handleMissionClick}
              onObjectiveClick={handleObjectiveClick}
            />
          ))}

          {missions.totalPages > 1 && (
            <Box display="flex" justifyContent="center" m={3}>
              <Pagination count={missions.totalPages} page={page} onChange={onPageChange} />
            </Box>
          )}
        </>
      );
    }

    return (
      <Box display="flex" justifyContent="center" m={3}>
        No results found
      </Box>
    );
  };

  useEffect(() => {
    fetchData();
  }, [tabFilter, page, missionFilterStatus, term]);

  return (
    <div>
      <Tabs value={tabFilter} onChange={onTabChange} className={classes.tabs}>
        <Tab label="Show Active" value="active" />
        <Tab label="Show All" value="all" />

        {computedCanCreate && (
          <Box display="flex" justifyContent="flex-end" flex="1" alignItems="center">
            <Button label="CREATE MISSION" onClick={handleCreateMission} />
          </Box>
        )}
      </Tabs>

      {tabFilter === 'all' && renderFilters()}

      {missionLoading ? (
        <Box display="flex" justifyContent="center" m={8}>
          <CircularProgress />
        </Box>
      ) : (
        renderMissions()
      )}
    </div>
  );
};

MemberMissions.propTypes = {
  isOfficerView: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  communityId: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['communityAdmin', 'leader']).isRequired,
  selectedMember: PropTypes.object
};

MemberMissions.defaultProps = {
  selectedMember: null
};

export default MemberMissions;
