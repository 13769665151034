import PropTypes from 'prop-types'
import React from 'react'

const Expert = ({className}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 170.08 170.08"
      className={className}
      width="250"
      height="250"
    >
      <defs>
        <style>{'.expert-cls-1{fill:none;}.expert-cls-2{opacityxs:0.23;}.expert-cls-3{clip - path:url(#clip-path);}'}</style>
        <clipPath id="clip-path">
          <rect className="expert-cls-1" x="28.45" y="28.35" width="113.18" height="113.39" />
        </clipPath>
      </defs>
      <g className="expert-cls-2">
        <g className="expert-cls-3">
          <path
            d="M139.74,80.74H70.58a1.89,1.89,0,0,0-1.89,1.89v1.26H30.34a1.87,1.87,0,0,0-1.8,2.44c4.19,13.43,15.72,22.81,30.84,25.1a49.48,49.48,0,0,0,8.76.19l.56,0A4.65,4.65,0,0,0,73.33,116h2.91v9.67l-6.5.25c-9.87.58-14.88,4.63-14.88,12v2a1.88,1.88,0,0,0,1.88,1.88h69.17a1.89,1.89,0,0,0,1.89-1.88v-2c0-7.39-5-11.44-14.92-12l-6.46-.25V116h13.2a1.9,1.9,0,0,0,1.89-2c-.27-6.37,3.29-8.09,6.37-8.41H136a5.62,5.62,0,0,0,5.61-5.61V82.63a1.89,1.89,0,0,0-1.89-1.89M137.86,100a1.85,1.85,0,0,1-1.84,1.84H95.83a1.89,1.89,0,0,0,0,3.77H120.1a11.74,11.74,0,0,0-2.34,6.6H96.67a1.89,1.89,0,0,0,0,3.77h6v9.45h-1.43a1.89,1.89,0,0,0-.06,3.77l11.53.46c10,.59,11.33,4.5,11.33,8.25V138H58.63v-.07c0-3.75,1.35-7.66,11.3-8.25l11.57-.46a1.89,1.89,0,0,0-.06-3.77H80V116h5.55a1.89,1.89,0,1,0,0-3.77H73.33a.87.87,0,0,1-.87-.86V93.53a1.89,1.89,0,1,0-3.77,0v14.29l-.69,0a47,47,0,0,1-8.06-.15c-12.71-1.93-22.59-9.34-26.93-20H70.58a1.89,1.89,0,0,0,1.88-1.89V84.51h65.4Z"
          />
          <path
            d="M35.48,76.91a3.73,3.73,0,0,0,2,2.14A3.83,3.83,0,0,0,39,79.4a3.58,3.58,0,0,0,1.29-.23L96.86,58.66l3.65,10A1.89,1.89,0,0,0,102.28,70a2,2,0,0,0,.64-.11l14.19-5.15a1.88,1.88,0,0,0,1.13-2.41l-9.43-26a1.89,1.89,0,0,0-1.13-1.13L89.2,28.46a1.88,1.88,0,0,0-2.41,2.42l5.36,14.77L35.6,66.17a3.73,3.73,0,0,0-2.14,2A3.79,3.79,0,0,0,33.33,71Zm70.08-38.53,8.49,23.41-10.64,3.86L99.77,55.6h0l-1.13-3.13-6.92-19.1ZM93.43,49.2l2.15,5.91L39,75.63l-2.14-5.91Z"
          />
          <path d="M119.62,72.57a1.89,1.89,0,0,0,0,3.77H132.2a1.89,1.89,0,0,0,0-3.77Z" />
          <path
            d="M117.05,70.62a1.87,1.87,0,0,0,1.75,1.17,1.93,1.93,0,0,0,.7-.13l11.72-4.74a1.89,1.89,0,0,0-1.42-3.5l-11.71,4.74a1.88,1.88,0,0,0-1,2.46"
          />
        </g>
      </g>
    </svg>
  )
}

Expert.propTypes = {
  className: PropTypes.string
}

Expert.defaultProps = {
  className: ''
}

export default Expert

