import axios from 'axios';

export const getUser = async userId => {
  try {
    const result = await axios.get(`/v1/users/${userId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (communityId, userId, updates) => {
  try {
    const result = await axios.put(
      `/v1/communities/${communityId}/members/${userId}`,
      updates
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (communityId, user) => {
  try {
    const result = await axios.post(`/v1/communities/${communityId}/users`, user);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addCommunityAdmin = async (communityId, userId) => {
  try {
    const result = await axios.patch(
      `/v1/communities/${communityId}/communityAdmins/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeCommunityAdmin = async (communityId, userId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/communityAdmins/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addCommunityLeader = async (communityId, userId) => {
  try {
    const result = await axios.patch(
      `/v1/communities/${communityId}/leaders/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeCommunityLeader = async (communityId, userId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/leaders/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const resendInvitation = async (communityId, invitationId) => {
  try {
    const result = await axios.post(
      `/v1/communities/${communityId}/invites/${invitationId}/resend`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addLeaderToUser = async (communityId, userId, leaders) => {
  try {
    const result = await axios.put(
      `/v1/communities/${communityId}/users/${userId}/leaders`,
      { leaders }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeLeaderFromUser = async (communityId, userId, leaderId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/users/${userId}/leaders/${leaderId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestEmailChange = async (communityId, userId, email) => {
  try {
    const result = await axios.post(
      `/v1/communities/${communityId}/users/${userId}/email`,
      { email }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailChangeRequest = async (communityId, userId) => {
  try {
    const result = await axios.get(
      `/v1/communities/${communityId}/users/${userId}/email`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCommunityEmailChangeRequest = async (communityId, userId) => {
  try {
    const result = await axios.get(
      `/v1/communities/${communityId}/users/${userId}/email`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const resendEmailChange = async (communityId, userId, requestId) => {
  try {
    const result = await axios.post(
      `/v1/communities/${communityId}/users/${userId}/email/${requestId}/resend`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const cancelEmailChange = async (communityId, userId, requestId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/users/${userId}/email/${requestId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserActivity = async (communityId, userId) => {
  try {
    const result = await axios.get(
      `/v1/communities/${communityId}/users/${userId}/activity`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (userId, communityId, formData) => {
  try {
    const result = await axios.post(
      `/v1/users/${userId}/profilePicture?community=${communityId}`,
      formData
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfileImage = async userId => {
  try {
    const result = await axios.get(`/v1/users/${userId}/profilePicture`, {
      responseType: 'blob'
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeUserFromCommunity = async (communityId, userId) => {
  try {
    const result = await axios.delete(
      `/v1/communities/${communityId}/users/${userId}`
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getUserLifeEvents = async userId => {
  const { data } = await axios.get(`/v1/lifeEvents/${userId}`);
  return data;
};

export const getUserAccountabilityScore = async (
  communityId,
  userId,
  startDate,
  endDate
) => {
  const filters = [
    !!startDate && `startDate=${startDate}`,
    !!endDate && `endDate=${endDate}`
  ];

  let query = '';

  if (filters.length) {
    query = `?${filters.join('&')}`;
  }

  const { data } = await axios.get(
    `/v1/reports/communities/${communityId}/users/${userId}/accountability-score${query}`
  );
  return data;
};

export const getUserCurrentAccountabilityScore = async (communityId, userId) => {
  const { data } = await axios.get(
    `/v1/communities/${communityId}/users/${userId}/accountability-score`
  );
  return data;
};
