import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  VictoryLine,
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryScatter,
  VictoryLabel,
  VictoryTooltip,
  Rect
} from 'victory';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import * as userTypes from '../../state/users/types';
import Datepicker from '../Datepicker/Datepicker';
import useWindowSize from '../../hooks/useWindowSize';
import {
  selectSelectedUserAccountabilityScores,
  selectSelectedUserAccountabilityScoresLoading
} from '../../state/users/reducers';

const useStyles = makeStyles({
  header: {
    alignItems: 'center'
  },
  dateContainer: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  datePicker: {
    width: 'auto',
    marginTop: -12
  },
  mr10: {
    marginRight: 10
  }
});

const SegmentedChartBackground = props => {
  const x = props.width / props.segments;
  return (
    <>
      <Rect {...props} />
      {/* {[...new Array(props.segments + 1)].map((_, index) => (
        <Rect
          {...props}
          style={{ fill: index % 2 ? '#c1c1c1' : '#b9b9b9' }}
          height={props.height}
          x={props.x + x * index}
          width={props.width / props.segments}
        />
      ))} */}
    </>
  );
};

const AccountabilityScoreChart = ({ userId, communityId }) => {
  const dispatch = useDispatch();
  const { width = 800 } = useWindowSize();
  const classes = useStyles();
  const loading = useSelector(selectSelectedUserAccountabilityScoresLoading);
  const accountabilityScores = useSelector(selectSelectedUserAccountabilityScores);
  const theme = useTheme();

  const [startDate, setStartDate] = useState(moment().subtract(7, 'weeks'));
  const [endDate, setEndDate] = useState(moment());

  const fetchData = () => {
    dispatch({
      type: userTypes.GET_USER_ACCOUNTABILITY_SCORE,
      userId,
      communityId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    });
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const renderChart = () => {
    const chartData = accountabilityScores
      .reduce((all, curr, index) => {
        const week = moment(curr.fromDate).format('W');
        const date = `${week} \n ${moment(curr.fromDate).format('MMM D')} - ${moment(curr.toDate).format('MMM D')}`;

        all.push({
          ...curr,
          index,
          x: date,
          y: curr.score,
          week,
          date,
          label: `${curr.score}%`
        });
        return all;
      }, [])
      .reverse();

    // if (chartData.length > 0) {
    return (
      <VictoryChart
        padding={{ top: 20, bottom: 20, left: 30, right: 30 }}
        // domain={20}
        // domainPadding={{ x: [100, 10], y: 20 }}
        domainPadding={{ top: 20, bottom: 20, left: 0, right: 0 }}
        height={240}
        width={width / 2} // Note: This is the window size, not the width of the container. Adjustments will change the aspect ratio and font sizes
        // maxDomain={{ y: 100, x: 8 }}
        domain={{ y: [0, 100] }}
        style={{
          background: { fill: 'white' }
        }}
        // backgroundComponent={<SegmentedChartBackground segments={6} />}
      >
        {/** Y Axis */}
        <VictoryAxis
          // offsetX={-20}
          dependentAxis
          tickFormat={tick => `${tick}%`}
          tickLabelComponent={<VictoryLabel angle={-90} alignContent="center" />}
          style={{
            grid: {
              stroke: 'rgba(0, 0, 0, 0.05)',
              fill: 'red',
              strokeDasharray: '10,5'
            },
            tickLabels: {
              background: 'white',
              fontSize: 8,
              padding: 8,
              fontWeight: 'bold'
            }
          }}
        />

        {/** X Axis */}
        <VictoryAxis
          tickLabelComponent={<VictoryLabel />}
          tickFormat={(date, i) => {
            const m = moment(date);
            const to = moment(m).add(6, 'days');
            // 10  Jan
            return `Week of\n${m.format('MMM DD').toString()}`;
          }}
          style={{
            grid: {
              stroke: 'rgba(0, 0, 0, 0.1)'
            },
            tickLabels: {
              fill: '#676767',
              fontSize: 8,
              padding: 2,
              fontWeight: 'bold'
            }
          }}
        />

        <VictoryGroup>
          <VictoryArea
            // Don't think this is the official way to hide the labels but could not find any other option that worked
            labelComponent={<></>}
            interpolation="natural"
            animate={{
              duration: 600,
              onLoad: { duration: 0 }
            }}
            style={{
              data: {
                stroke: theme.palette.info.main,
                strokeWidth: 2,
                strokeLinecap: 'round',
                opacity: 0.9,
                fill: theme.palette.info.main,
                fillOpacity: 0.1
              }
            }}
            data={chartData}
            x="fromDate"
            y="score"
          />

          <VictoryScatter
            data={chartData}
            x="fromDate"
            y="score"
            tick={() => ''}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: '10px', color: 'white', fill: 'white' }}
                flyoutStyle={{
                  stroke: 'black',
                  fill: 'rgba(0,0,0,0.8)',
                  fontSize: 10,
                  color: 'white',
                  backgroundColor: 'black'
                }}
              />
            }
            style={{
              data: {
                fill: '#ffffff',
                stroke: theme.palette.info.main,
                strokeWidth: 2
              }
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    );
    // }

    // return <div>We couldn&apos;t find any records between the selected dates</div>;
  };

  return (
    <>
      <Grid container className={classes.header} alignContent="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Accountability Timeline</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.dateContainer}>
          <Datepicker
            label="Start Date"
            name="startDate"
            readOnly={loading}
            onChange={e => {
              if (e) setStartDate(e);
            }}
            value={startDate}
            className={clsx(classes.datePicker, classes.mr10)}
            maxDate={endDate}
          />
          <Datepicker
            label="End Date"
            name="endDate"
            readOnly={loading}
            onChange={e => {
              if (e) setEndDate(e);
            }}
            value={endDate}
            minDate={startDate}
            className={classes.datePicker}
          />
        </Grid>
      </Grid>

      <div>
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center" m={8} minHeight={300}>
            <CircularProgress />
          </Box>
        ) : (
          renderChart()
        )}
      </div>
    </>
  );
};

AccountabilityScoreChart.propTypes = {
  userId: PropTypes.string.isRequired,
  communityId: PropTypes.string.isRequired
};

export default AccountabilityScoreChart;
