import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PasswordInput from '../../PasswordInput/PasswordInput';
import Button from '../../Button/Button';

import { getErrorMessage } from '../../../constants/validation';

const styles = makeStyles({
  paper: {
    maxWidth: 480,
    borderRadius: 40,
    padding: '50px 21px',
    '@media (min-width: 768px)': {
      padding: '50px 68px'
    }
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10
  },
  copy: {
    maxWidth: 345,
    margin: '0px auto 20px auto',
    fontSize: 14,
    fontWeight: 300
  },
  mt35: {
    marginTop: 35
  },
  mb20: {
    marginBottom: 20
  },
  button: {
    width: 150
  }
});

export default function SetNewPasswordCard({
  password,
  confirm,
  onChange,
  onSubmit,
  errors,
  saving
}) {
  const classes = styles();

  const canSubmit = errors === null && !saving;

  function onPressEnter(e) {
    if (e.key === 'Enter' && canSubmit) {
      onSubmit();
    }
  }

  return (
    <Paper className={classes.paper} onKeyPress={onPressEnter}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            Set Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.copy}>
            You're on your way to a new password!
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mb20}>
          <PasswordInput
            name="password"
            label="Password"
            value={password}
            onChange={onChange}
            onSubmit={onSubmit}
            error={Boolean(getErrorMessage(errors, 'password'))}
            errorMessage={getErrorMessage(errors, 'password')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            name="passwordConfirm"
            label="Confirm Password"
            value={confirm}
            onChange={onChange}
            onSubmit={onSubmit}
            error={Boolean(getErrorMessage(errors, 'passwordConfirm'))}
            errorMessage={getErrorMessage(errors, 'passwordConfirm')}
          />
        </Grid>
        <Grid item xs={12} className={classes.mt35}>
          <Button
            label="Save"
            onClick={onSubmit}
            className={classes.button}
            loading={saving}
            disabled={saving}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

SetNewPasswordCard.propTypes = {
  password: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  saving: PropTypes.bool.isRequired
};

SetNewPasswordCard.defaultProps = {
  errors: null
};
