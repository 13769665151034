import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as chatTypes from '../../state/chat/types';
import { selectChatEnabled } from '../../state/chat/reducers';

import ChatDrawer from './ChatDrawer';
import ChatBar from './ChatBar';

export default function Chat() {
  // Hooks
  const dispatch = useDispatch();

  // Redux
  const chatEnabled = useSelector(selectChatEnabled);

  // Get leader users on mount
  // this isn't correct and should only be
  useEffect(() => {
    dispatch({ type: chatTypes.GET_ALL_LEADER_USERS });
  }, []);

  return chatEnabled ? (
    <>
      <ChatDrawer />
      <ChatBar />
    </>
  ) : null;
}
