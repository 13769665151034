import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    paddingTop: 8
  },
  wrap: {
    width: 54,
    height: 54,
    borderRadius: 12,
    backgroundColor: '#dfdfdf',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4 !important',
      boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)',
      '& *': {
        transition: 'all 0.2s ease-in-out',
        fill: 'url(#lgrad)'
      }
    }
  },
  active: {
    backgroundColor: '#f4f4f4 !important',
    boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.5)'
  },
  iconGrey: {
    '& *': {
      fill: '#d0d0d0'
    }
  },
  iconGold: {
    '& *': {
      fill: 'url(#lgrad)'
    }
  }
});

const Icon = ({ icon, selected, onClick }) => {
  const classes = useStyles();

  const wrapClass = clsx({
    [classes.wrap]: true,
    [classes.active]: selected
  });

  const iconClass = clsx({
    [classes.iconGold]: selected,
    [classes.iconGrey]: !selected
  });

  return (
    <div onClick={onClick} role="presentation" className={classes.flex}>
      <div className={wrapClass}>
        <FontAwesomeIcon icon={icon} className={iconClass} size="2x" />
      </div>
    </div>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Icon.defaultProps = {
  selected: false
};

export default Icon;
