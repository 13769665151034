import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';

import ListFilter from '../components/ListFilter/ListFilter';
import Table from '../components/Table/Table';
import UserCard from '../components/RowCards/UserCard';
import CreateUserDrawer from '../components/CreateUserDrawer/CreateUserDrawer';
import EditUserDrawer from '../components/EditUserDrawer/EditUserDrawer';

import {
  selectCommunityUsers,
  selectCommunityLoadingState,
  selectCommunityUsersLoadingState,
  selectSelectedCommunityId
} from '../state/community/reducers';
import { selectUserSavingState } from '../state/users/reducers';
import { selectTeams } from '../state/teams/reducers';

import * as communityTypes from '../state/community/types';
import * as userTypes from '../state/users/types';
import * as missionTypes from '../state/mission/types';
import * as modalTypes from '../state/modal/types';
import * as teamTypes from '../state/teams/types';

import { getUserCommunityLeaders } from '../utils/helpers';

const fuseOpts = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['firstName', 'lastName', 'email']
};

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filter: '',
      showEdit: false,
      showCreate: false
    };
    const { getCommunityUsers, getCommunityLeaders, communityId, getAllTeams } = this.props;
    getCommunityUsers(communityId);
    getCommunityLeaders(communityId);
    getAllTeams(communityId);
  }

  componentDidUpdate(prevProps) {
    // If the community is changed - refresh the data
    const { communityId: prevCommunityId } = prevProps;
    const { communityId, getCommunityUsers, getCommunityLeaders, getAllTeams } = this.props;
    if (communityId !== prevCommunityId) {
      getCommunityLeaders(communityId);
      getCommunityUsers(communityId);
      getAllTeams(communityId);
    }
  }

  searchUsers = users => {
    const { search } = this.state;
    const fuseInstance = new Fuse(users, fuseOpts);
    return fuseInstance.search(search);
  };

  onSearchChange = e => {
    this.setState({ search: e });
  };

  onFilterChange = e => {
    const { value } = e.target;
    this.setState({ filter: value });
  };

  openEditUser = user => {
    const { setSelectedUser, communityId } = this.props;
    const leaders = getUserCommunityLeaders(user, communityId);
    setSelectedUser(user, leaders);
    this.setState({ showEdit: true });
  };

  closeEditUser = () => {
    this.setState({ showEdit: false });
  };

  openCreateUser = () => {
    this.setState({ showCreate: true });
  };

  closeCreateUser = () => {
    this.setState({ showCreate: false });
  };

  openViewUser = user => {
    const {
      setSelectedUser,
      getUserActivity,
      getUserMissions,
      communityId,
      getUserObjectives,
      getUserLifeEvents,
      history
    } = this.props;
    const leaders = getUserCommunityLeaders(user, communityId);
    setSelectedUser(user, leaders);
    getUserMissions(user._id, communityId);
    getUserObjectives(user._id);
    getUserLifeEvents(user._id);
    // get the objectives for a given user

    getUserActivity(communityId, user._id);
    history.push(`/c/${communityId}/u/${user._id}/a/profile`);
  };

  resendInvitation = row => {
    const { communityId, resendInvitation } = this.props;
    const { invites } = row;
    const invite = invites.find(i => i.community === communityId);
    if (invite) resendInvitation(invite._id);
  };

  confirmRemove = userId => {
    const { removeUserFromCommunity, communityId } = this.props;
    removeUserFromCommunity(communityId, userId);
  };

  removeUserFromCommunity = row => {
    const { setModalComponent, openModal } = this.props;
    setModalComponent(modalTypes.CONFIRMATION_MODAL, {
      message: `${row.firstName} ${row.lastName} will be removed from the community.`,
      savingSelector: selectUserSavingState,
      onSubmit: () => this.confirmRemove(row._id)
    });
    openModal();
  };

  generateMenuActions = row => {
    const { readOnly } = this.props;
    const actions = [
      {
        onClick: () => this.openViewUser(row),
        label: "View user's profile"
      }
    ];

    if (!readOnly) {
      actions.push(
        {
          onClick: () => this.openEditUser(row),
          label: 'Edit user'
        },
        {
          onClick: () => this.removeUserFromCommunity(row),
          label: 'Remove from community'
        }
      );
    }

    if (!row.isVerified)
      actions.push({
        onClick: () => this.resendInvitation(row),
        label: 'Resend invite'
      });

    return actions;
  };

  render() {
    const { communityLoadingState, communityUsersLoadingState, communityUsers, readOnly, communityTeams } = this.props;
    const { search, filter, showEdit, showCreate } = this.state;

    const loadingState = communityUsersLoadingState || communityLoadingState;

    const filterOptions = communityTeams.map(team => ({
      label: team.title,
      value: team._id
    }));

    const hasSearch = search.trim().length > 0;
    const searchResults = hasSearch ? this.searchUsers(communityUsers) : communityUsers;

    const computedUsers = filter ? searchResults.filter(u => u.teams.some(t => t._id === filter)) : searchResults;

    return (
      <React.Fragment>
        <ListFilter
          readOnly={readOnly}
          search={search}
          filterOptions={filterOptions}
          filter={filter}
          onSearchChange={this.onSearchChange}
          onFilterChange={this.onFilterChange}
          onCreate={this.openCreateUser}
          createLabel="Create user"
          searchPlaceholder="Search your members..."
          showBatch
        />
        <Table
          onClick={row => (readOnly ? this.openViewUser(row) : this.openEditUser(row))}
          loading={loadingState}
          data={computedUsers}
          menuActions={this.generateMenuActions}
          rowCard={UserCard}
          readOnly={readOnly}
          noDataMessage={hasSearch ? 'No results found' : 'There are no users in this community!'}
        />
        <CreateUserDrawer open={showCreate} handleClose={this.closeCreateUser} />
        {!readOnly && <EditUserDrawer readOnly={readOnly} open={showEdit} handleClose={this.closeEditUser} />}
      </React.Fragment>
    );
  }
}

ManageUsers.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  communityLoadingState: PropTypes.bool.isRequired,
  communityUsersLoadingState: PropTypes.bool.isRequired,
  communityId: PropTypes.string.isRequired,
  communityUsers: PropTypes.array.isRequired,
  getCommunityUsers: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  getCommunityLeaders: PropTypes.func.isRequired,
  getUserMissions: PropTypes.func.isRequired,
  getUserActivity: PropTypes.func.isRequired,
  getUserObjectives: PropTypes.func.isRequired,
  setModalComponent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  removeUserFromCommunity: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  communityTeams: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  getUserLifeEvents: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  communityLoadingState: selectCommunityLoadingState(state),
  communityUsersLoadingState: selectCommunityUsersLoadingState(state),
  communityId: selectSelectedCommunityId(state),
  communityUsers: selectCommunityUsers(state),
  communityTeams: selectTeams(state),
  loggedInUser: state.auth.loggedInUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCommunityUsers: communityId => ({
        type: communityTypes.GET_COMMUNITY_USERS,
        communityId
      }),
      getCommunityLeaders: communityId => ({
        type: communityTypes.GET_COMMUNITY_LEADERS,
        communityId
      }),
      setSelectedUser: (user, leaders) => ({
        type: userTypes.SET_SELECTED_USER,
        user,
        leaders
      }),
      resendInvitation: invitationId => ({
        type: userTypes.RESEND_INVITATION,
        invitationId
      }),
      getUserMissions: (userId, communityId) => ({
        type: missionTypes.GET_ALL_COMMUNITY_MISSIONS,
        userId,
        role: 'communityAdmin',
        communityId
      }),
      getUserActivity: (communityId, userId) => ({
        type: userTypes.GET_USER_ACTIVITY,
        communityId,
        userId
      }),
      getUserLifeEvents: userId => ({
        type: userTypes.GET_USER_LIFE_EVENTS,
        userId
      }),
      getUserObjectives: userId => ({
        type: missionTypes.GET_USER_OBJECTIVES,
        userId,
        leaderReq: false
      }),
      setModalComponent: (component, props) => ({
        type: modalTypes.MODAL_SET_COMPONENT,
        component,
        props
      }),
      openModal: () => ({
        type: modalTypes.MODAL_SET_OPEN_STATE,
        state: true
      }),
      removeUserFromCommunity: (communityId, userId) => ({
        type: userTypes.REMOVE_USER_FROM_COMMUNITY,
        communityId,
        userId
      }),
      getAllTeams: communityId => ({
        type: teamTypes.GET_ALL_TEAMS,
        communityId
      })
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageUsers));
