import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as ArrowTurnIcon } from '../../static/images/icons/icon-arrow-turn.svg';
import SelectionPopup from './SelectionPopup';
import CustomIcon from '../CustomIcon/CustomIcon';
import customIcons from '../../constants/customIcons'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    position: 'relative',
    height: 100,
    width: 100,
    borderRadius: 25,
    boxShadow: '0px 15px 30px -7.5px rgba(0,0,0,0.4)',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  iconContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: 30,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 18
  },
  pointer: {
    cursor: 'pointer'
  },
  iconGold: {
    '& *': {
      fill: 'url(#lgrad)'
    }
  },
  error: {
    fontFamily: 'SFUIText-Light, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 10,
    marginTop: 10,
    color: `${theme.palette.error.main} !important`
  }
}));

const IconPicker = ({ icon, readOnly, onSubmit, handleOpen, error, fileOption=false,  }) => {
  // Hooks
  const classes = useStyles();

  // Local state
  const [open, setOpen] = useState(false);
  const [chosen, setChosen] = useState(icon);

  // Set chosen on icon update
  useEffect(() => {
    setChosen(icon);
  }, [icon]);

  // Event handlers
  const handleOpenPicker = () => {
    if(handleOpen) handleOpen()
    else setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = val => {
    setChosen(val);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      let chosenIcon = chosen

      if (typeof chosen !== 'string') {
        chosenIcon = chosen?.name
      }

      onSubmit(chosenIcon)
    }
    handleClose();
  };

  // Classes
  const buttonClass = clsx({
    [classes.button]: true,
    [classes.pointer]: !readOnly
  });

  // Constants
  const hasError = Boolean(error);

  return (
    <>
      <div className={classes.flex}>
        <div
          role="presentation"
          className={buttonClass}
          onClick={readOnly ? null : handleOpenPicker}
        >
          {icon && typeof icon === 'string' &&
              customIcons.filter(customIcon => customIcon.type === 'custom')
                .map(customIcon => customIcon.name).includes(icon) && 
                  <CustomIcon
                    icon={icon}
                    onClick={()=>{}}
                    viewOnly
                  /> || (<FontAwesomeIcon icon={icon} className={classes.iconGold} size="3x" />)
          }
          {icon && icon?.type === 'fa' && (
            <FontAwesomeIcon icon={icon?.name} className={classes.iconGold} size="3x" />
          )}
          {icon && icon?.type === 'custom' && (
            <CustomIcon
              icon={icon?.name}
              onClick={()=>{}}
              viewOnly
            />
          )}
          {!readOnly && (
            <div className={classes.iconContainer}>
              <ArrowTurnIcon />
            </div>
          )}
        </div>
        {hasError && <Typography className={classes.error}>{error}</Typography>}
      </div>
      <SelectionPopup
        fileOption={fileOption}
        open={open}
        onChange={handleChange}
        onSelect={handleSubmit}
        onCancel={handleClose}
        chosen={chosen}
      />
    </>
  );
};

IconPicker.propTypes = {
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  fileOption: PropTypes.bool,
  handleOpen: PropTypes.func,
};

IconPicker.defaultProps = {
  icon: 'smile',
  readOnly: false,
  onSubmit: null,
  error: '',
  fileOption: false,
  handleOpen: null
};

export default IconPicker;
