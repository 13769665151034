import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import AuthBackground from '../AuthBackground/AuthBackground';

export default function AppLoadingPage() {
  return (
    <AuthBackground>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress color="secondary" size={72} />
      </div>
    </AuthBackground>
  );
}
