import axios from 'axios';

export const getLeaderMissionTemplates = async userId => {
  const result = await axios.get(`/v1/users/${userId}/missionTemplates`);
  return result.data;
};

export const getCommunityMissionTemplates = async communityId => {
  const result = await axios.get(`/v1/community/${communityId}/missionTemplates`);
  return result.data;
};

export const getMissionTemplatesProfileImage = async (templateId) => {
  try {
    const result = await axios.get(`/v1/missionTemplates/${templateId}/profilePicture`, {
      responseType: 'blob'
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (templateId, formData) => {
  try {
    const result = await axios.post(
      `/v1/missionTemplates/${templateId}/profilePicture`,
      formData
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createMissionTemplate = async template => {
  const result = await axios.post('/v1/missionTemplates', template);
  return result.data;
};

export const updateMissionTemplate = async (templateId, template) => {
  const result = await axios.put(`/v1/missionTemplates/${templateId}`, template);
  return result.data;
};

export const assignMissionTemplates = async (templates, members, communityId) => {
  const result = await axios.post('/v1/missionTemplates/assign', {
    templates,
    members,
    community: communityId
  });
  return result.data;
};

export const deleteMissionTemplate = async (
  isCommunity,
  templateId,
  communityId,
  userId
) => {
  const url = isCommunity
    ? `/v1/community/${communityId}/missionTemplate/${templateId}`
    : `/v1/users/${userId}/missionTemplate/${templateId}`;
  const result = await axios.delete(url);
  return result.data;
};

export const getMissionTemplate = async templateId => {
  const result = await axios.get(`/v1/missionTemplates/${templateId}`);
  return result.data;
};
export const getMissionByTemplate = async templateId => {
  const result = await axios.get(`/v1/missionByTemplates/${templateId}`);
  return result.data;
};
